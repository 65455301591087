import React, { useEffect, useState } from 'react';
import { FaAngleDown , FaHeart, FaAngleUp, FaMinusCircle, FaCheckCircle, FaCircle, FaRegCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const MySelections = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedCategory, setExpandedCategory] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState({});
    const [customerId, setCustomerId] = useState('');
    const userDataString = localStorage.getItem('userData');
    const userData = JSON.parse(userDataString);
    const customer_id = userData._id;
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
    const [products,setProducts] = useState([])
    const [addedToSamples,setAdeedToSamples]=useState([])
    const [message,setMessage] = useState();
    useEffect(() => {
        const userDataString = localStorage.getItem('userData');
        const userData = JSON.parse(userDataString);
        const customer_id = userData._id;
        setCustomerId(customer_id);
        fetchProductCategories();
        fetchSelectedProducts(customer_id);
        fetchCustmerSamples(customer_id)
    }, []);

    const fetchProductCategories = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "/Admin/get-all-productCategorys",{
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'x-token':token
                },
                });
            if (!response.ok) {
                throw new Error('Failed to fetch product categories');
            }
            const data = await response.json();
            setCategories(data);
            setLoading(false);
            
        } catch (error) {
            setError(error.message);
            setLoading(false);
            clearStorageAndRedirectToHome()
        }
    };

    
    const fetchSelectedProducts = async (customer_id) => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `/customer/selections/get-customer-selections-data-by-customerId?customer_id=${customer_id}`,{
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'x-token':token
                },
                });
            if (!response.ok) {
                const responseData = await response.json();
          setMessage(responseData.message)
            }
            const data = await response.json();
           console.log(data)
            setProducts( data.SelectionProducts );
        } catch (error) {
            console.error('Error fetching selected products:', error);
           // clearStorageAndRedirectToHome()
        }
    }; 

    const handleDeleteProduct = async ( productId) => {
        try {
            const token = sessionStorage.getItem('token');

            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/selections/delete-customer-selections-data-by-customerandProductIds?customer_id=${customerId}&product_id=${productId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                },
            });
    
            if (!response.ok) {
                const responseData = await response.json();
          setMessage(responseData.message)
            }
    
            // Update products state after successful deletion
            const updatedProducts = products.filter(product => product._id !== productId);
            setProducts(updatedProducts);
        } catch (error) {
            console.error('Error deleting product:', error);
            clearStorageAndRedirectToHome()
        }
    };
    
    const handleAddedtoSample = async (product) => {
        const selectedProducts= addedToSamples.filter(p=>p.product_category_id===product.product_category_id)
        if(selectedProducts&&selectedProducts.length===2){
     alert('max limit of selections are 2')
        }else{
            const samplesData = {
                customer_id: customerId,
                product_id: product._id,
                product_category_id: product.product_category_id
            };
            try {
                const token = sessionStorage.getItem('token');

                const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/samples/save-customer-samples-data`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-token':token
                    },
                    body: JSON.stringify(samplesData)
                });
        
                if (response.ok) {
                    const data = await response.json();
                    
                    setAdeedToSamples(data.sampleData)
                    // You can perform additional actions here after successful response if needed
                } else {
                    // Handle non-OK response status
                    console.error('Failed to add sample:', response.statusText);
                    // You can also throw an error or show a notification to the user
                }
            } catch (error) {
                // Handle fetch error
                console.error('Error adding sample:', error.message);
                // You can also throw an error or show a notification to the user
                clearStorageAndRedirectToHome()
            }
        }

      
    };

    const handleDeleteCustomerSamples = async (sample) => {
        const id = sample._id;
        try {
            const token = sessionStorage.getItem('token');

            const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET+`/customer/samples/delete-customer-selections-data-by-sampleId?sample_id=${id}&customer_id=${customerId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                },
            });
            if(response.ok){
                const data = await response.json();
                   
                    setAdeedToSamples(data.sampleData)
            }  else {
                // Handle non-OK response status
                console.error('Failed to add sample:', response.statusText);
                // You can also throw an error or show a notification to the user
            }
            // You can handle the response here if needed
        } catch (error) {
            // Handle error appropriately, e.g., logging or rethrowing
            console.error("Error occurred while deleting customer samples:", error);
            clearStorageAndRedirectToHome()
        }
    }
    
    
    const fetchCustmerSamples = async (id) => {
        try {
            const token = sessionStorage.getItem('token');

            const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET+`/customer/samples/get-customer-samples-data-by-customerId?customer_id=${id}`,{
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'x-token':token
                },
                });
            if (response.ok) {
                const data = await response.json();
                
                setAdeedToSamples(data.samplesData);
            } else {
                console.error('Failed to fetch samples:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching samples:', error);
            clearStorageAndRedirectToHome()
        }
    };


    function clearStorageAndRedirectToHome() {
        navigate("/bb/ErrorPage");
      }

    return (
        <div className='flex justify-center'>
        <div className='w-3/4 md:w-2/3 sm:w-full flex justify-center flex-col'>
            <div>
                <h2 className='text-2xl font-semibold text-center p-2'>Whishlist</h2>
            </div>
            {/* <p className="text-center border mx-auto my-4 p-4">{message}</p> */}

            {categories.length === 0 || products.length === 0 ? (
                <div className="text-center border mx-auto my-4 p-4">
                    <p>No products selected. Go to the offers page and select products.</p>
                    {/* You can add a button or a link here to redirect the user to the offers page */}
                </div>
            ) : (
                <div className='flex flex-col space-y-6'>{console.log(products)}
                    {categories.map((category, index) => {
                        const filteredProducts = products.filter(product => product.product_category_id === category?._id);
                        if (filteredProducts.length !== 0) {
                            return (
                                <div key={category._id} className=' '>
                                    <div className='mb-2'>
                                    <span className="text-md font-bold flex justify-center underline">{category.product_category_name}</span>

                                    </div>
                                    <div className='rounded-b-md px-4 py-2 '>
                                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                            {filteredProducts.map(product => (
                                                <div key={product._id} className="border p-4 rounded-lg flex flex-col items-center justify-between border-gray-300">
                                                    <div className='flex items-center justify-end w-full'>
                                                        <div
                                                            className="flex justify-end text-red-500 focus:outline-none hover:text-black cursor-pointer"
                                                            onClick={() => handleDeleteProduct( product._id)}
                                                        >
                                                            <FaMinusCircle />
                                                        </div>
                                                    </div>
   <div className="w-40 h-40 overflow-hidden flex items-center justify-center">
    <img
      src={ImageUrl + (product.product_image || 'image unavailable')}
      alt="Product Image"
      className="max-w-full max-h-full object-contain"
      onError={(e) => console.log("Error loading image:", e)}
    />
  </div>
                                                    <h2 className="text-md font-bold text-center whitespace-normal break-all">
                                                        {product.product_name}
                                                    </h2>
                                                    <div className='flex items-center justify-center w-full mt-2'>
                                                        {addedToSamples && addedToSamples.find(p => p.product_id === product._id) ? (
                                                            <div className='flex flex-row justify-center items-center space-x-2 rounded-md px-2 py-1 mt-1 cursor-pointer' onClick={() => handleDeleteCustomerSamples(addedToSamples.find(p => p.product_id === product._id))}>
                                                                <FaCheckCircle className='text-green-500' />
                                                                <p className='text-green-600 text-[12px]'>Sample Requested</p>
                                                            </div>
                                                        ) : (
                                                            <div className='flex flex-row justify-center items-center space-x-2 rounded-md px-2 py-1 mt-1 cursor-pointer' onClick={() => handleAddedtoSample(product)}>
                                                                <FaRegCircle className='text-blue-600' />
                                                                <p className='text-blue-600 text-[12px]'>Arrange Sample</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        return null; // Return null if there are no products for this category
                    })}
                </div>
            )}
        </div>
    </div>
    
    
    );
};

export default MySelections;
