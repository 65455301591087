import { faCamera, faEdit, faTimes, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { FaFileUpload, FaRegTrashAlt } from "react-icons/fa";

const MaterialRequireMent = () =>{
    const [Popup, setPopup] = useState(false);
    const [image, setImage] = useState();
    const [displayedImage, setDisplayedImage] = useState(false);
    const [CreatedmaterialAlert, setCreatedmaterialAlert] = useState(false);
    const [customer, setCustomer] = useState ()
    const [MaterialRequirements, setMaterialRequirements] = useState ([])
    const [DeleteAlert, setDeleteAlert] = useState (false);
    const [projects, setProjects] = useState([]);
    const [selectedProjectIds, setSelectedProjectIds] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
        useEffect(()=>{
        const customerData= JSON.parse(localStorage.getItem('userData'))
        setCustomer(customerData)
        fetchCustomerMaterialRequireMents(customerData._id)
        },[])

            const fetchCustomerMaterialRequireMents = async (customer_id)=>{

            try {
            const token = sessionStorage.getItem('token');
                const GetMaterialRequirement = `${backendApiUrl}/customer/material-requirement/get-customer-material-requirement?customer_id=${customer_id}`;
                const response = await fetch(GetMaterialRequirement, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        
                        'x-token':token
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data)
                    
                  setMaterialRequirements(data.customerRequirements);
           
                } else {
                  setMaterialRequirements([])
                    console.error('Failed to fetch material Requirement:', response.statusText);
                }
            } catch (error) {
                console.error('Failed to fetch material Requirements :', error);
                //clearStorageAndRedirectToHome()
            }
}

useEffect(() => {
  const fetchProjects = async () => {
    const customerData= JSON.parse(localStorage.getItem('userData'))
      try {
          const token = sessionStorage.getItem('token');
          const GetProjects = `${backendApiUrl}/customer/get-projects-by-customer?customer_id=${customerData._id}`;
          const response = await fetch(GetProjects, {
              method: 'GET',
              headers: {
                  Accept: 'application/json',
                  
                  'x-token':token
              },
          });

          if (response.ok) {
              const projectsData = await response.json();
              setProjects(projectsData.customerProjects);
              console.log(projectsData.customerProjects);
          } else {
              console.error('Failed to fetch projects:', response.statusText);
          }
      } catch (error) {
          console.error('Failed to fetch projects:', error);
          //clearStorageAndRedirectToHome()
      }
  };
    fetchProjects();
}, [backendApiUrl]);


    const handleCardClick = () =>{
        setPopup(true);
    }
    const handleClose = () => {
        setPopup(false);
        //setCustomerId('')
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) {
        // No file selected, do something (e.g., show an error message or return early)
        return;
      }
        setImage(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setDisplayedImage(e.target.result);
        };
        reader.readAsDataURL(file);
      };

      const handleSubmitCreatedmatrialAlert = (selectedProjectIds) => {
        // setCreatedmaterialAlert(false);
         handleCreate(selectedProjectIds);
       }

      const handleCreate = async (selectedProjectIds) => {

        const customer_id = customer._id;
    
        try {
           
            const formData = new FormData();
            formData.append('customer_id', customer_id);
            formData.append('selectedProjectIds', JSON.stringify(selectedProjectIds)); // Add selected project IDs to formData

            // Check if an image is selected and add it to formData
            if (image) {
              formData.append('image', image);
              
            }
           
            const token = sessionStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/material-requirement/create-customer-material-requirement`, {
              method: 'POST',
              headers: {
                //'Content-Type': 'application/json',
                'x-token': token,
              },
              body: formData,
            });
        
            if (response.ok) {
              setCreatedmaterialAlert('material image has been created successfully!');
              setPopup(false);
              fetchCustomerMaterialRequireMents(customer._id)
              // You might want to navigate the user to another page or perform other actions here
            } else {
              const errorMessage = await response.text();
              console.error(`Failed to create material: ${errorMessage}`)
             // throw new Error(`Failed to create product: ${errorMessage}`);
            }
          } catch (error) {
            console.error('Error creating material:', error.message);
            clearStorageAndRedirectToHome()
            // Display an error message to the user or handle it in another appropriate way
            //setSubmitCreateproductAlert(`Error creating product: ${error.message}`);
          }
        
      }

      const handleCloseMaterialAlert = () => {
        setCreatedmaterialAlert(false);
        // Set Items to false after the alert is closed
        setPopup(false);
      }

      const handleCancel = () => {
        setPopup(false);
        setDisplayedImage(null); // Resetting the displayed image state
      }
    
      function clearStorageAndRedirectToHome() {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/bb/user/ErrorPage';
      }

      const DeleteMaterialRequirement =  (material_requirement_id, requirement_status) => {
        setDeleteAlert({message:'Are you sure want to delete',
          material_requirement_id:material_requirement_id,
          requirement_status:requirement_status
        }) 
    };

   const  handleDeleteData = async () =>{ 
    if(DeleteAlert.requirement_status=== 'mr placed'){
      const token = sessionStorage.getItem('token');
      try {
          const deleteMaterialRequirementUrl = `${backendApiUrl}/customer/material-requirement/delete-customer-material-requirement?_id=${DeleteAlert.material_requirement_id}`;
          const response = await fetch(deleteMaterialRequirementUrl, {
              method: 'DELETE',
              headers: {
                  Accept: 'application/json',
                 /*  'Content-Type': 'application/json', */
                  'x-token': token,
              },
          });
  
          if (response.ok) {
              const data = await response.json();
              setDeleteAlert(false)
              fetchCustomerMaterialRequireMents(customer._id);
          } else {
             setDeleteAlert(false)
              console.error('Failed to delete material requirement:', response.statusText);
          }
      } catch (error) {
          console.error('Failed to delete material requirement:', error);
          clearStorageAndRedirectToHome();
      }
    }else{
      setDeleteAlert(false)
      setCreatedmaterialAlert('You cannot delete this requirement because a quotation was created for it.')
    }
   }
  
   const handleNoDelete = ()=>{
    setDeleteAlert(false);
   }

   const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  const handleIconClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.onchange = handleFileChange;
    fileInput.click();
  };

    return(
          <div className="flex flex-col justify-center items-center h-full relative font-roboto">
      <div className="flex flex-col justify-center w-3/4 md:w-2/3 sm:w-6/7 overflow-y-auto">
      {CreatedmaterialAlert && (
 <div className="fixed z-50 inset-0 flex items-center justify-center">
 {/* Background overlay (blurred) */}
 <div className="absolute inset-0 bg-black opacity-30 "></div>

   <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
   <p className="text-lg font-semibold mb-2">{CreatedmaterialAlert}</p>
    <button  className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
     onClick={handleCloseMaterialAlert}>OK</button>
   </div>
 </div>
)}

{DeleteAlert && (
  <div className="fixed z-50 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{DeleteAlert.message}</p>
      <div className="flex">
        <button className="text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4 mr-2" onClick={handleDeleteData}>Yes</button>
        <button className="text-white bg-red-600 hover:bg-red-700 py-1 px-2 rounded-md mt-4" onClick={handleNoDelete}>No</button>
      </div>
    </div>
  </div>
)}


      {Popup && (
  <div className="fixed inset-0 z-50 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-10 rounded-md shadow-md relative z-50 flex flex-col items-center">
      <button className="absolute top-0 right-0 m-2" onClick={handleClose}>
      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
  </svg>
      </button>
      <h1 className="text-xl font-bold text-center mb-4">Upload Materials</h1>
      <div className="max-h-96 flex overflow-y-scroll no-scrollbar">
        {/* Upload Image section */}
        <div className="flex flex-col w-full items-center">
         {/*  <div className="border border-gray-300 w-full mb-4"></div> */}
          <div className="border border-gray-300 p-4 mb-4">
            {!displayedImage && (
              <div className="flex justify-center">
                <FontAwesomeIcon icon={faCamera} className="text-gray-400 text-6xl"  onClick={handleIconClick} />
              </div>
            )}
            {displayedImage && (
              <div className="flex justify-center">
                <img src={displayedImage} alt="Selected Image" className="max-w-full border border-gray-200 max-h-32"  onClick={handleIconClick} />
              </div>
            )}
          </div>
         {/*  <label htmlFor="fileInput" className="mt-3 cursor-pointer text-md font-medium text-gray-700 flex justify-center px-3 py-1 border border-1 border-gray-300 hover:border-[#EB001B] bg-white rounded-sm">
            <FontAwesomeIcon icon={faCamera} className="text-gray-600 mr-1 mt-1" />
            Upload Image
          </label>
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
          /> */}
        </div>
      </div>
    <div className="m-4 ">
      <h2 className="text-md font-semibold mb-1">Select the Project(s)</h2>
      <ul>
        {projects.map((project) => (
          <li key={project._id}>
            <input
              type="checkbox"
              id={project._id}
              value={project.projectName}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedProjectIds((prevIds) => [...prevIds, project._id]);
                } else {
                  setSelectedProjectIds((prevIds) => prevIds.filter((id) => id!== project._id));
                }
              }}
            />
            <label className="ml-2 " htmlFor={project._id}>{project.projectName}</label>
          </li>
        ))}
      </ul>
    </div>


      <div className='flex flex-row items-center justify-center space-x-8 mt-2'>
        <button className='px-6 py-1 border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white' onClick={() => handleCancel()}>
          Cancel
        </button>
        <button className='px-6 py-1 border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white' onClick={() => handleSubmitCreatedmatrialAlert(selectedProjectIds)}>
          Create
        </button>
      </div>
    </div>
  </div>
)}


        <div className='mb-6  flex justify-center w-full '>
        
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <div className="mt-2 border rounded-sm p-4">
  <div className="flex flex-col justify-between items-center h-full">
    <div onClick={handleCardClick} className="cursor-pointer overflow-y-hidden object-cover h-1/2 w-full">
      {/* <img src='Constructing Building.jpg' alt="Add Project" className="w-full h-full hover:opacity-75" /> */}
      <FaFileUpload className="text-[#EB001B] h-full w-full text-opacity-75 flex items-center justify-center" />
      {/* <i className="text-4xl text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hover:text-black fa fa-plus-circle" aria-hidden="true" /> */}
    </div>
    <div className="w-full md:mt-auto md:mb-10 sm:mt-auto sm:mb-2">
      <hr className="border border-gray-200 w-full" />
    </div>
    <div className="w-full">
      <div className="flex flex-col h-full">
        <div className="flex-grow">
          <h2 className="md:text-lg sm:text-sm font-bold text-center">
            Upload Material Requirements
          </h2>
        </div>
      </div>
    </div>
  </div>
</div>


            {MaterialRequirements.map((requirement, index) => (
              <div key={requirement._id} className="mt-2 border rounded-sm p-4">
                <div className="flex flex-col justify-between items-center h-full">
                  <div className="w-full h-1/2 overflow-y-hidden">
                    {requirement.requirement_image? (
                      <img className='w-full h-full border rounded-lg object-cover' 
                      src={ImageUrl+ requirement.requirement_image} alt={' Image Not Uploded'}
                      onClick={() => openImage(ImageUrl + requirement.requirement_image)}
                      />
                      
                    ) : (
                      <div className="border rounded-lg w-full h-full flex justify-center items-center">
                        <span className='text-xs'>No image</span>
                      </div>
                    )}
                  </div>
                  <div className="w-full ">
      <hr className="border border-gray-200 w-full" />
    </div>
                 <div className="w-full">
      <div className="flex flex-col h-full">
        <div className="flex-grow">
          {/* <h2 className="text-md font-bold">
            {requirement.requirement_status}
          </h2> */}
        </div>
        
        <div className="flex space-x-4 justify-center">
          {/* <button className="flex items-center space-x-1 text-sm text-blue-500 hover:text-blue-700 hover:underline">
            <span>Edit</span>
          </button> */}
          <button 
          className="flex items-center space-x-1  text-sm text-red-500 hover:text-red-700 " 
          onClick={()=>DeleteMaterialRequirement(requirement._id , requirement.requirement_status)}>
             <FontAwesomeIcon icon={faTrash} className="h-4 w-4" /> 
          </button>
        </div>
      </div>
    </div>

                </div>
              </div>
            ))}
            {/* Add the "Create Project" card to the grid */}
            {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="relative p-4 bg-white rounded-sm">
          <img src={selectedImage} alt="Expanded" className="w-80 h-80 object-contain" />
          <button
            onClick={closeImage}
            className="absolute top-0 right-0 mt-2 mr-2 text-black text-2xl"
          >
           <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
  </svg>
          </button>
        </div>
      </div>
      )}

          </div>
        </div>
      </div>
    </div>
    )
}
export default MaterialRequireMent;