import React, { useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faProjectDiagram,
    faUser,
    faSignOutAlt,
    faBell,
    faCamera,
    faImage,
    faUserPlus,
    faAngleLeft,faUserCircle, faTimes,
    faPlus,
    faChevronRight,
    faChevronLeft,
    faArrowUp,
    faArrowDown,
    faPlusCircle
  } from '@fortawesome/free-solid-svg-icons';
  let currentInfoWindow = null;
function MyProjects() {
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [images, setImages] = useState([]);
    const [uploadedImage, setUploadedImage] = useState();
    const [projectImage, setProjectImage] = useState();
    const [files, setFiles] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
    
    useEffect(() => {
        // Retrieve mobile number from local storage
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData && userData.mobile_number) {
            setCustomerId(userData._id);
        }
    }, []);
    useEffect(() => {
        const fetchProjects = async () => {
           
            try {
                const token = sessionStorage.getItem('token');
                const GetProjects = `${backendApiUrl}/customer/get-projects-by-customer?customer_id=${customerId}`;
                const response = await fetch(GetProjects, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        
                        'x-token':token
                    },
                });

                if (response.ok) {
                    const projectsData = await response.json();
                    const reversedData = projectsData.customerProjects.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                    const sortedProjects = reversedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    setProjects(sortedProjects);
                    // Extracting project IDs into an array
            const projectIds = sortedProjects.map(project => project._id);
                } else {
                    console.error('Failed to fetch projects:', response.statusText);
                }
            } catch (error) {
                console.error('Failed to fetch projects:', error);
                //clearStorageAndRedirectToHome()
            }
        };

        if (customerId) {
            fetchProjects();
        }

       
    }, [backendApiUrl, customerId]);

    function clearStorageAndRedirectToHome() {
        navigate("/bb/user/ErrorPage");
      }

      const handleView = (projectId) => {
        navigate('/bb/user/ViewProject', { state: { projectId } });
      }
      const handleCardClick = () => {
        navigate("/bb/user/AddProject2");
      };
   
      const handleCloseMap = (event) => {
        setShowMap(false);
      }


    const renderMap = (coordinates, location) => {
        if (!coordinates || !coordinates.lat || !coordinates.lng) {
            console.error('Coordinates not set:', coordinates);
            return; // Coordinates not set yet, do not render map
        }

        try {
            const map = new window.google.maps.Map(document.getElementById(`map-${location._id}`), {
                center: { lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng) },
                zoom: 8
            });

            const marker = new window.google.maps.Marker({
                position: { lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng) },
                map,
                title: location.projectName
            });

            const infoWindow = new window.google.maps.InfoWindow({
                content: `<div class="text-xs p-1 text-red-700"><strong>Project:</strong> ${location.projectName}<br><strong>Builder:</strong> ${location.builderName}<br><strong>Contact:</strong> ${location.builderContactNo}</div>`
            });

            marker.addListener('click', () => {
                if (currentInfoWindow) {
                    currentInfoWindow.close();
                }

                if (currentInfoWindow !== infoWindow) {
                    infoWindow.open(map, marker);
                    currentInfoWindow = infoWindow;
                } else {
                    currentInfoWindow = null;
                }
            });
        } catch (error) {
            console.error('Error rendering map:', error);
        }
    };
      
    return (
      <div className="flex flex-col justify-center items-center h-full relative font-roboto mt-4">
      <div className="flex flex-col justify-center w-3/4 md:w-2/3 sm:w-6/7 overflow-y-auto">
        <div className='mb-6  flex justify-center w-full '>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {projects.slice(0, 6).map((project, index) => (
              <div onClick={() => handleView(project._id)} key={project._id} className="cursor-pointer mt-2 border rounded-sm p-4">
                <div className="flex flex-col justify-between items-center h-full">
                  <div className="w-full h-1/2 overflow-y-hidden">
                    {project.projectImage[0]? (
                      <img className='w-full h-full m-2 border rounded-lg object-cover' src={`${process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL}/${project.projectImage[0]}`} alt={project.projectName} />
                    ) : (
                      <div className="border rounded-lg w-full h-full flex justify-center items-center">
                        <span className='text-xs'>No image</span>
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <div className="flex flex-col justify-center items-center h-full">
                      <div className="flex-grow flex justify-center items-center">
                        <h2 className="m-2 text-xl font-bold text-center ">
                          {project.projectName}
                        </h2>
                      </div>
                     {/*  <div className="flex justify-end">
                        <button onClick={() => handleView(project._id)} className="m-2 sm:text-[11px] md:text-sm text-red-400 hover:underline cursor-pointer">
                          View more
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* Add the "Create Project" card to the grid */}
            <div className="mt-2 border rounded-sm p-4">
              <div onClick={handleCardClick} className="cursor-pointer flex flex-col justify-between items-center h-full">
              <div className="w-full h-1/2 flex justify-center items-center">
  <button className="cursor-pointer ">
    {/* <img src={process.env.PUBLIC_URL + '/Constructing Building.jpg'} alt="Add Project" className="w-full h-full hover:opacity-75" /> */}
    <FontAwesomeIcon icon={faPlusCircle} size="2x" className="text-black" aria-hidden="true" />
  </button>
</div>
                <div className="w-full">
                  <div className="flex flex-col justify-center items-center h-full">
                    <div className="flex-grow">
                      <h2 className="m-2 text-xl font-bold text-center">
                        Create Project
                      </h2>
                    </div>
                   {/*  <div className="flex justify-end">
                      <button onClick={handleCardClick} className="m-2 sm:text-[11px] md:text-sm text-red-400 hover:underline cursor-pointer">
                        Create
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
}

export default MyProjects;
