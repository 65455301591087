import React, { useEffect, useState } from 'react';
import App1 from './measurit/app1';
import App3 from './BrickBucket/app3';
import App4 from './BrickBucket_user/App4';
import HtmlApp from './HtmlApp';
import './App.css';

const MainApp = () => {
  const [shouldRenderApp1, setShouldRenderApp1] = useState(false);
  const [shouldRenderApp3, setShouldRenderApp3] = useState(false);
  const [shouldRenderApp4, setShouldRenderApp4] = useState(false);
  const [shouldRenderHtmlApp, setShouldRenderHtmlApp] = useState(false); // Set to true to render HtmlApp by default


  useEffect(() => {
    const path = window.location.pathname;
    setShouldRenderApp1(path.startsWith('/measurit'));
    setShouldRenderApp3(path.startsWith('/bb/admin'));
    setShouldRenderApp4(path.startsWith('/bb/user'));
    setShouldRenderHtmlApp(path.startsWith('/'));
  }, []); // Corrected dependency array to ensure useEffect runs only once

  return (
    <>
    {(shouldRenderApp1 || shouldRenderApp3 || shouldRenderApp4) ? (
      <>
        {shouldRenderApp1 && <App1 />}
        {shouldRenderApp3 && <App3 />}
        {shouldRenderApp4 && <App4 />}
      </>
    ) : (
      <>
        {shouldRenderHtmlApp && <HtmlApp />}
      </>
    )}
  </>
  );
};

export default MainApp;
