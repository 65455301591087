import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DashBord = ()=>{
    const navigate= useNavigate()
    const [materialRequirementCount,setMaterialRequirementCount]=useState()
    const [Alert,setAlert]=useState(false);

    const handleClickImages = ()=>{
      if(materialRequirementCount > 0){
        navigate('/bb/admin/AdminHome/viewAllCustomersMaterialRequirements')
      }{
        setAlert('No Customer Requirements Available')
      }
    }

    useEffect(()=>{
      getMaterialRequirements()
    },[])

    const getMaterialRequirements = async () => {
      const getCountOfMaterialRequirementsUrl = process.env.REACT_APP_FORE_BRICKBUCKET+'/customer/material-requirement/get-all-customers-material-requirements-count'; // replace with your actual API URL
      try {
        const token = sessionStorage.getItem('token');

        const response = await fetch(getCountOfMaterialRequirementsUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // add any other required headers, such as authentication tokens
            'x-token':token
          }
        });
    
        const data = await response.json();
        // do something with the data, such as updating the state or rendering a component
        //console.log(data);
        setMaterialRequirementCount(data.count)
    
      } catch (error) {
        console.error(error);
        // handle the error, such as displaying an error message to the user
      }
    }

return(
    <div className="w-full h-full flex justify-left pl-4 pt-4">
        <div className="w-1/5 h-1/4 flex flex-col justify-center items-center border border-gray-300 border-2">
  <div className="w-1/2 h-1/2 flex justify-center">
    <img src="/report.png" className="" />
  </div>
  <div className="flex flex-col  justif-center items-center">
    <h1 className="text-lg font-bold">Material Requirements</h1>
    <p className="text-lg cursor-pointer text-[#EB001B] hover:underline" onClick={handleClickImages}>{materialRequirementCount}</p>
  </div>
</div>

    </div>
)
}

export default DashBord;