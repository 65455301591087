import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import LoginPage from './LOGIN/LoginPage';
import ResetPassword from './LOGIN/ResetPasswordPage';
import ForgotPassword from './LOGIN/ForgotPasswordPage';
import AdminHome from './Admin/AdminHome';
import CreateProjectContainer from './Admin/createprojectContainer';
import CreateUserContainer from './Admin/createuserContainer';
import ProfileContainer from './Admin/profileContainer';
import AdminHomeContainer from './Admin/adminHomeContainer';
import ProjectsContainer from './Admin/projectsContainer';
import UsersContainer from './Admin/usersContainer';
import UnAssignedUsersContainer from './Admin/unAssignedusersContainer';
import ViewContainer from './ProjectSummary/viewContainer';
import BlocksContainer from './ProjectSummary/blocksContainer';
import FlatsContent from './ProjectSummary/flatsContent';
import UserDetailsContainer from './Admin/userdetailsContainer';
import UserHomeContainer from './User/userhomeContainer';
import UserViewProjectContainer from './User/userviewprojectContainer';
import MeasurementsContainer from './ProjectSummary/measurementsContainer';
import UnAssignedProjectsContainer from './Admin/unAssignedprojectsContainer';
import ProjectReports from './ProjectSummary/ProjectReports';
import Reports from './ProjectSummary/Reports';
import AggregatedProjectReports from './ProjectSummary/AggregatedProjectReports';


function App() {
  const [appId, setappId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const measurit = () => {
    if (location.pathname.startsWith('/measurit')) {
      const storedLoginData = JSON.parse(localStorage.getItem('LoginData'));
      if (storedLoginData) {
        const appId = storedLoginData.appId;
        setappId(appId);
      } else {
        // Handle case where user data is not available
        // For example, navigate to login page
        if (location.pathname.startsWith('/measurit') && 
    !location.pathname.startsWith('/measurit/ResetPassword') &&
    !location.pathname.startsWith('/measurit/ForgotPassword') &&
    !location.pathname.startsWith('/measurit/ResetPassword/')
) {
    navigate('/measurit');
}

        
      }
    }
  };

  useEffect(() => {
    if (location.pathname.startsWith('/measurit')) {
      measurit();
    }
  }, [location.pathname]); // Add location.pathname as a dependency

  const AccessDenied = () => {
    return (
      <div
        className="fixed inset-0 bg-gray-500/50 flex justify-center items-center"
        style={{ backdropFilter: 'blur(100px)' }}
        role="dialog"
        aria-modal="true"
      >
<div className="bg-white rounded shadow-md p-8 max-w-md">
  <h2 className="text-lg font-bold text-center">Access Denied</h2>
  <p className="text-gray-600 text-center">You do not have permission to access this page.</p>
  <div className="flex justify-center mt-2">
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded"
      onClick={() => {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/measurit');
      }}
    >
      Go Back
    </button>
  </div>
</div>
      </div>
    );
  };

  return (
    <Routes>
      <Route path="/measurit" element={<LoginPage />} />
      <Route path="/measurit/ResetPassword/:resetToken" element={<ResetPassword />} />
      <Route path="/measurit/ResetPassword" element={<ResetPassword />} />
      <Route path="/measurit/ForgotPassword" element={<ForgotPassword />} /> {/* Fixed ForgotPassword route */}
      <Route path="/measurit" element={<AdminHome />}>
        {appId === 'MA' && (
          <>
            <Route path="CreateUser" element={<CreateUserContainer />} />
            <Route path="AdminHome" element={<AdminHomeContainer />} />
            <Route path="Projects" element={<ProjectsContainer />} />
            <Route path="Users" element={<UsersContainer />} />
            <Route path="UnAssignedProjects" element={<UnAssignedProjectsContainer />} />
            <Route path="UnAssignedUsers" element={<UnAssignedUsersContainer />} />
            <Route path="UserDetails" element={<UserDetailsContainer />} />
            <Route path="Reports" element={<Reports />} />
            <Route path="ProjectReports" element={<ProjectReports />} />
            <Route path="AggregatedProjectReports" element={<AggregatedProjectReports />} />
            <Route path="View" element={<ViewContainer />} />
          </>
         )}
        {(appId === 'MU' || appId === 'MM') && ( 
          <>
            <Route path="UserHome" element={<UserHomeContainer />} />
            <Route path="UserViewProject" element={<UserViewProjectContainer />} />
            <Route path="Reports" element={<Reports />} />
          </>
       )} 
        <Route path="CreateProject" element={<CreateProjectContainer />} />
        <Route path="Profile" element={<ProfileContainer />} />
        <Route path="flats" element={<FlatsContent />} />
        <Route path="Blocks" element={<BlocksContainer />} />
        <Route path="Measurements" element={<MeasurementsContainer />} />
        <Route path="*" element={<AccessDenied />} />
      </Route>
    </Routes>
  );
}

export default App;