import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

function AmmendQuotation() {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
    const { quotationid, ProductCategoryName } = location.state || '';
    const [totalPriceSum, setTotalPriceSum] = useState(0);
    const [discountPrice, setDiscountPrice] = useState(0);
    const [TotalGst, setTotalGst]= useState(0)
    const [customer, setCustomerData] = useState();
const [TotalDiscountPriceWithoutGst, setTotalDiscountPriceWithoutGst]= useState(0)
const [QuotationAlert, setQuotationAlert] = useState(false);

    useEffect(() => {
        fetchData();
    }, [quotationid, backendApiUrl]);

    const fetchData = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(`${backendApiUrl}/customer/quotation/get-quotation-offers-and-products-for-amendQuotation?quotation_id=${quotationid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token': token
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            console.log(result)
            setData(result);
            setDiscountPrice(result.quotation.amended_discount?result.quotation.amended_discount:0)
            setLoading(false);
        } catch (error) {
            setError(error);
            clearStorageAndRedirectToHome();
        }
    };

    useEffect(()=>{
        if(data&&data.quotation){
            fetchCustomer(data.quotation.customer_id)
        }  
      
      },[data])
  
      const fetchCustomer = async (customer_id) => {
        const token = sessionStorage.getItem('token');
        const getCustomerUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/get-customer-by-customerId?customer_id=${customer_id}`;
      
        try {
          const response = await fetch(getCustomerUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token': token
            }
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          const data = await response.json();
         setCustomerData(data.Customer)
        } catch (error) {
          console.error('Error fetching customer:', error);
        }
      };

    function clearStorageAndRedirectToHome() {
        navigate("/bb/ErrorPage");
    }

    useEffect(() => {
        if (data) {
            let TotalGstPrice=0
            let sum = 0;
            let TotalpriceWithoutGst=0
            data.quotation.quotationData.forEach(qd => {
                const offersData=data.offers 
                const offer= offersData.find(p=>p._id===qd.offer_id)
                const gst= parseFloat(offer.GST_rate_id)
                const discount= parseFloat(offer.discount)
               
                const product = data.products.find(p => p._id === qd.product_id);
                const price = calculatePrice(product, data);
                const discountPrice= parseFloat(price/(1+(gst/100)))
                const gstPrice= price-discountPrice
                
                const totalPrice = qd.count * parseFloat(price);
                const totalDiscountPrice=qd.count * parseFloat(discountPrice)
                const totalGstPrice= qd.count * parseFloat(gstPrice)
                TotalpriceWithoutGst +=totalDiscountPrice
                sum += totalPrice;
                TotalGstPrice += totalGstPrice
               
            });
            setTotalPriceSum(sum.toFixed(2));
            setTotalGst(TotalGstPrice)
            setTotalDiscountPriceWithoutGst(TotalpriceWithoutGst)
        }
    }, [data]);

    const calculatePrice = (product, quotation) => {
        const Quotationdata = quotation.quotation.quotationData;
        const quotationArray = Quotationdata.find(p => p.product_id === product._id);
        const netPrice = quotationArray.price || '';
        return netPrice;
    };

    const getDisplayUOM = (uom, count) => {
        const countNumber = Number(count);
        let sanitizedUOM = uom.toLowerCase().replace(/[^a-z]/g, '');
        if (countNumber === 1) {
            if (sanitizedUOM === 'nos') {
                return "No";
            } else if (sanitizedUOM === 'bags') {
                return "Bag";
            } else if (sanitizedUOM === 'coils') {
                return "Coil";
            } else if (sanitizedUOM === 'packets') {
                return "Packet";
            }
            return uom.replace(/'s$/, '');
        } else {
            if (uom.endsWith('s')) {
                return uom;
            }
            return `${uom}s`;
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleSubmitEmendedQuotation = async () => {
        const amendQuotationUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/update-quotation-amended-discount`;
        const data = {
            amended_discount: discountPrice,
            _id: quotationid,
        };

        try {
            const response = await fetch(amendQuotationUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token': sessionStorage.getItem('token')
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

             await response.json();
             fetchData()
            setQuotationAlert('Quotation Amended Successfully')
            //console.log('Amendment successful:', result);
        } catch (error) {
            console.error('Error amending quotation:', error);
        }
    };

   
    const  handleNotifyQuotation= async ()=>{
        try {
        // Check if the product category ID exists
        if (!data.quotation.product_category_id ) {
          console.error("Product category ID is missing.");
          return;
        }
    
        // Create a new jsPDF instance
        const doc = new jsPDF();
      
        const subtotal = data.quotation.quotationData.reduce((acc, product) => {
        
          const price = product.price
          return acc + (product.count * price);
        }, 0);
    
        // Define table data with headers
        let tableData = [
          ['Product Name', 'Quantity', 'Price'], // Header row
          ...data.quotation.quotationData.map(product => ([
            data.products.find(p => p._id === product.product_id).product_name,
              product.count,
              `Rs ${product.price.toFixed(2)} /-`
          ])),
          [ '',  'Total Price', `Rs ${subtotal.toFixed(2)} /-`]
      ];
      
      // Conditionally add the "Amended Price" row if amended_discount is not zero
      if (data.quotation.amended_discount && data.quotation.amended_discount !== 0 ) {
          tableData.push([ '', 'Discount Price',  `Rs ${parseFloat(data.quotation.amended_discount).toFixed(2)} /-`]);
          tableData.push([ '', 'Final Price', `Rs ${(subtotal-parseFloat(data.quotation.amended_discount)).toFixed(2)} /-`]);
      }
    
        // Add table to the document
        doc.autoTable({
          head: [tableData.shift()], // Extract and use the first row as the header
          body: tableData,
          startY: 20 // Adjust as needed
        });
    
        // Save the document
        doc.save('Quotation.pdf');
        const pdfBlob = doc.output('blob');
       
        
        // Save data to the server
        await sendQuotationToCustomer(pdfBlob,)
       // await sendQuotationToCustomer(pdfBlob);
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    
        //navigate('/bb/admin/AdminHome/OrderConfirmation', { state: { quotationid: [selectedQuotationId] } });
        }
    
    
        const sendQuotationToCustomer = async (pdfBlob) => {
          //const product_category_name = ProductCategories.find(p => p._id === quotation.product_category_id).product_category_name;
          console.log(customer)
          const formData = new FormData();
          formData.append('pdf', pdfBlob, 'Quotation.pdf');
          formData.append('email', customer.email);
          formData.append('product_category_name', ProductCategoryName);
        
          try {
            console.log('PDF Blob size:', pdfBlob.size);
            if (pdfBlob.size === 0) {
              console.error("PDF Blob is empty");
              return;
            }
            const token = sessionStorage.getItem('token');
            const response = await fetch(backendApiUrl + '/customer/quotation/notify-quotation-to-customer-by-email', {
              method: 'POST',
              headers:{
                'x-token':token
              },
              body: formData
            });
        
            if (response.ok) {
            //await RemoveProductCategoryCustomerAddedDataFromLocalStorage(quotation.product_category_id)
           
              /*  navigate('/bb/Admin/AdminHome/AdminViewQuotations', {
                state: {
                  selectedQuotationId: selectedQuotationId,
                  selectedProductCategoryId: selectedProductCategoryId,
                  selectedProductCategoryName: selectedProductCategoryName,
                  selectedCustomerId: quotation.customer_id,
                  selectedRFQId: quotation.RFQ_id
                }
              });  */

    
              navigate('/bb/admin/AdminHome/UserQuotations', {
                state: {
                  customer_id:customer._id,
                }
              })

            } else {
              console.error("Error sending email");
            }
          } catch (error) {
            console.error("Error:", error);
          }
        };

        const handleQuotationAlert= ()=>{
            setQuotationAlert(false)
        }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className='w-full h-full'>
             {QuotationAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
  {/* Background overlay (blurred) */}
  <div className="absolute inset-0 bg-black opacity-30 "></div>

    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-semibold mb-2">{QuotationAlert}</p>
     <button  className="items-center justify-center text-white bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleQuotationAlert}>OK</button>
    </div>
  </div>

)}
            <div className="p-2 ml-2 rounded-md justify-start items-start w-full mt-3">
                <div className="rounded-md flex flex-row justify-start items-start">
                    <div className="p-1 w-2/3 lg:w-2/3  md:w-1/2 sm:w-1/2 h-full flex flex-col justify-between items-center border overflow-y-auto" style={{ maxHeight: '85vh' }}>
                        {data && (
                            <div className='flex flex-col md:w-2/3 sm:w-full'>
                                {data.quotation.quotationData.map(qd => {
                                    const product = data.products.find(p => p._id === qd.product_id);
                                    const price = calculatePrice(product, data);
                                    const totalPrice = (qd.count * parseFloat(price)).toFixed(2);
                                    return (
                                        <div key={qd.product_id} className='flex flex-col sm:flex-row items-center p-2 border-b'>
                                            <div className='flex flex-row w-full'>
                                                <div className='md:w-1/3 sm:w-1/2 flex items-center justify-center'>
                                                    <div className='pt-2 md:w-20 md:h-20 sm:w-28 sm:h-28 overflow-hidden flex items-center justify-center'>
                                                        <img className='max-w-full max-h-full object-contain' src={`${ImageUrl}/${product.product_image}`} alt={product.product_name} />
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-center md:w-2/3 sm:w-1/2 flex-col">
                                                    <div className="flex justify-center">
                                                        <p className="md:text-md sm:text-lg font-semibold text-center whitespace-normal">{product.product_name}</p>
                                                    </div>
                                                    <div className="grid grid-cols-6 gap-1 mt-1">
                                                        {Object.entries(data.quotation.quantity_break_up && data.quotation.quantity_break_up.find(p => p.product_id === product._id)?.break_ups || {}).map(([key, value], index) => (
                                                            (value !== "" && value !== 0 && value !== undefined && value !== null) && (
                                                                <div key={index} className="flex flex-row">
                                                                    <p className='text-xs'>{key}=</p>
                                                                    <p className='text-xs'>{value}</p>
                                                                </div>
                                                            )))}
                                                    </div>
                                                    <div className='flex flex-col items-center mt-2'>
                                                        <p className='whitespace-normal'><span className='font-semibold'>Qty: </span> {qd.count} {getDisplayUOM(product.UOM, qd.count)}</p>
                                                        <p className='whitespace-nowrap'><span className='font-semibold'>Offer Price: </span> ₹{parseFloat(price).toFixed(2)}</p>
                                                        <p className='text-lg font-semibold mt-2'>
                                                            ₹{totalPrice}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className='font-semibold flex justify-end items-center w-full'>
                                    <p>Total : ₹{totalPriceSum}</p>
                                </div>
                            </div>
                        )}
                        <div className='flex justify-center mb-4 mt-6'>
                            <button className='rounded-sm text-black hover:text-white border border-[#EB001B] px-4 flex justify-center hover:bg-[#EB001B]' onClick={handleBack}>Back</button>
                        </div>
                    </div>

                    <div className="w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2  flex flex-col space-y-4 p-4 border">
                        <div className='flex flex-col space-y-4 border border-b'>
                            <div className="font-semibold flex items-center w-full mb-2">
                                <p>{ProductCategoryName} Total quotation : ₹{totalPriceSum}</p>
                            </div>
                            <div className="font-semibold flex items-center w-full mb-2">
                                <p>{ProductCategoryName} Total quotation Gst Price : ₹{(TotalGst).toFixed(2)}</p>
                            </div>
                            <div className="flex flex-col p-2">
                                <label className="mb-1 text-gray-700">Amended Discount Price</label>
                                <input 
                                    type="text" 
                                     className="focus:outline-none hover:border-[#EB001B] w-60 p-1 border border-gray-300 rounded-sm"
                                    value={discountPrice}
                                    onChange={(e) => setDiscountPrice(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='flex flex-col'>
                            <div className="font-semibold flex items-center w-full mb-2">
                                <p>Total quotation with gst : ₹{totalPriceSum}</p>
                            </div>
                            <div className="font-semibold flex items-center w-full mb-2">
                                <p>Total quotation without gst : ₹{TotalDiscountPriceWithoutGst}</p>
                            </div>
                            <div className="font-semibold flex items-center w-full mb-2">
                                <p>Amended Discount : ₹{discountPrice}</p>
                            </div>

                            <div className="font-semibold flex items-center w-full mb-2">
                                <p>Amended Quotation Price without Gst = ₹{(TotalDiscountPriceWithoutGst - discountPrice).toFixed(2)}</p>
                            </div>
                            <div className="font-semibold flex items-center w-full mb-2">
                                <p>Amended Quotation Price with GSt = ₹{((TotalDiscountPriceWithoutGst - discountPrice)+ TotalGst).toFixed(2)}</p>
                            </div>
                        </div>
                        <div className='flex flex-row space-x-2 justify-center'>
                            <button 
                                className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black"
                                onClick={handleSubmitEmendedQuotation}
                            >
                                Submit
                            </button>
                            <button className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black" onClick={() => handleNotifyQuotation()}>
                            Notify
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AmmendQuotation;
