import React from 'react';
import { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';


import AddNewProductAttributes from './AddNewProductAttributes';


function ProductAttributes() {
  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [product_category_id , set_Product_Category_Id ] = useState();
  const [SubmitAttributeAlert, setSubmitAttributeAlert] = useState(false);
  const [AttributesData, setAttributesData] = useState([{ attribute_name: '', enteredValues: '', attribute_values: [] }]);
  const navigate = useNavigate();
 
 
 
  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
        navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })
  

  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/bb/admin';
  }
  const handleOk = () => {
    setSubmitAttributeAlert(false);
    navigate('/bb/admin/AdminHome/CreateProduct'); // Change '/bb/AdminHome' to the appropriate path
  };

  const handleCancel = () => {
    // Add any additional cleanup logic if needed
    navigate('/bb/admin/AdminHome'); // Change '/bb/AdminHome' to the appropriate path
  };

 
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState([]);



  const fetchAttributesByProductCategory = async (selectedCategoryId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${backendApiUrl}/Admin/product-attributes/get-all-attributes-by-productCategory?product_category_id=${selectedCategoryId}`,
        {
          method: 'GET',
          headers:{
            //'Content-Type': 'application/json',
            'x-token' : token,
          },
         

        }
      );
      
      if (response.ok) {
        const attributesData = await response.json();
  
      // Set the state with the fetched filter data
      setAttributesData(attributesData);
  
      // Store the filters data in local storage
      localStorage.setItem('product_attributes', JSON.stringify(attributesData));
       
      }else{
        console.error('Failed to fetch attributes for the selected product category')
        // throw new Error('Failed to fetch attributes for the selected product category');
      }
  
      
      // You can perform additional actions if needed
  
    } catch (error) {
      clearStorageAndRedirectToHome()
    }
  };
  
  

  const handleDropdownChange = (event) => {
    const { value } = event.target;
    const selectedCategory = items.find(category => category.product_category_name === value);

    set_Product_Category_Id(selectedCategory?._id);
    setSelectedItems([value]);

    // Fetch filters for the selected product category
    fetchAttributesByProductCategory(selectedCategory?._id);
  };
  

 

  useEffect(() => {
    const fetchProductCategories = async () => {
      const token = sessionStorage.getItem('token');
      try {
        const response = await fetch(backendApiUrl + "/Admin/get-all-productCategorys",{ 
          method: 'GET',
        headers:{
          'Content-Type': 'application/json',
          'x-token' :token
        }});
        if (response.ok) {
            const data = await response.json();

        setItems(data);
         
        }else{
            console.error('Failed to fetch product categories')
            // throw new Error('Failed to fetch product categories');
        }
        
      } catch (error) {
        clearStorageAndRedirectToHome()
      }
    };
    
  
    fetchProductCategories();
  }, []);
  
  

 const handleSubmit = async () => {
    try {
      
      // Retrieve existing filters data from local storage
      const attributesData = JSON.parse(localStorage.getItem('product_attributes')) || [];
  

      // Extract properties from existing filters data, removing enteredValues
      const AttributesData = attributesData.map(({ enteredValues, ...rest }) => rest);

      // Prepare data to be sent to the server
      const attributeDataWithCategoryId = {
        product_category_id: product_category_id,
        attributesData: AttributesData, // Use the modified data without enteredValues
      };
  
  
      // Define the API endpoint for creating filters
      const createAttributesToProductCategoryUrl = backendApiUrl+"/Admin/product-attributes/create-attributes";
      const token = sessionStorage.getItem('token');
      // Send a POST request to the server with the filter data
      const response = await fetch(createAttributesToProductCategoryUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token' : token
        },
        body: JSON.stringify(attributeDataWithCategoryId),
      });
  
      // Check if the response is successful
      if (response.ok) {
        setSubmitAttributeAlert('Submitted attributes successfully!');
        const data = await response.json();
        // Update local storage with the modified filters data
        const updatedAttributesData = data.attributesData.map(p => ({ enteredValues: '', ...p }));
        localStorage.setItem('product_attributes', JSON.stringify(updatedAttributesData));
        setAttributesData(updatedAttributesData)
      } else {
       console.error( 'failed to submit attributes')
      }
    } catch (error) {
      clearStorageAndRedirectToHome()
    }
  };

  return (
 
    <div className="overflow-y-auto w-full  flex flex-col items-center ">
 
      {SubmitAttributeAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
  {/* Background overlay (blurred) */}
  <div className="absolute inset-0 bg-black opacity-30 "></div>

    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-bold mb-2">{SubmitAttributeAlert}</p>
     <button  className="items-center justify-center text-white bg-green-600 
     hover:bg-green-700 py-1 px-2 rounded-md mt-4"
     onClick={handleOk}>OK</button>
    </div>
  </div>
)}


<div className="flex flex-col items-center justify-center p-10">

      <div className='border border-1 border-gray-500 flex flex-col flex justify-center  items-center  p-10 '>
      
    {/* <div>
    <h1 className='text-xl flex justify-center font-semibold mb-5'>Product Group Attributes</h1>

      </div> */}
      <div className="flex flex-col  w-full">
      <div className="relative flex items-center justify-center">
          <p className="text-md text-center font-semibold mr-4">Product Category</p>
          <select
            value={selectedItems[0] || ""}
            onChange={handleDropdownChange}
            className="border border-gray-300 rounded-sm p-1 focus:outline-none hover:border-[#EB001B] cursor-pointer"
           // style={{ width: '200px' }}
          >
            <option value="" disabled>Select Product Category</option>
            {items.map((category) => (
              <option key={category?._id} value={category?.product_category_name}>
                {category?.product_category_name}
              </option>
            ))}
          </select>
        </div>
</div>

<div className='flex justify-center w-full mt-5 '>
        {/* Conditionally render "Please select Product Category" text */}
        {selectedItems.length === 0 && (
          <p className="text-md text-center">⚠️ Please select Product Category ⚠️</p>
        )}
        {/* Conditionally render AddNewFilter based on selected product category */}
        {selectedItems.length > 0 && <AddNewProductAttributes AttributesData={AttributesData} />}
      </div>
      
      {/* Conditionally render buttons based on selected product category */}
      {selectedItems.length > 0 && (
        <div className='flex flex-row items-center justify-between mt-6'>
          <div className='flex justify-center '>
          <button
      type='button' // Set the type to 'button' to prevent form submission
      className="mr-2 px-6 py-1 border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white"
      onClick={handleCancel}
    >
      Cancel
    </button>
            <button
              type='submit'
              className="ml-2 px-6 py-1 border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white"
              onClick={handleSubmit} 
            >
              Submit
            </button>
          </div>
          <div className='flex justify-center'>
         
          </div>
        </div>
      )}
    </div>
  </div>
</div>
 
  );
}

export default ProductAttributes;
