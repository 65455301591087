import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const ViewProductGroups = () => {
    const [productGroups, setProductGroups] = useState([]);
    const [products, setProducts] = useState([]);
    const [ProductGroup, setProductGroup] = useState();
    const navigate = useNavigate(); // Initialize navigate for navigation
    const [showAssignProducts, setshowAssignProducts] = useState(false);
    const [productGroupId,setProductGroupId]=useState('')
    const [productGroupIdToDelete,setProductGroupIdToDelete]=useState(null)
    const [DeleteProductGroupAlert, setDeleteProductGroupAlert] = useState(false);
    const [ContDeleteProductGroupAlert, setContDeleteProductGroupAlert] = useState(false);
    const [productCategories,setProductCategories] = useState([])
  const [ViewProductGroups,setViewProductGroups]= useState(true);
  const [vendors,setVendors]= useState([]);
  const [selectedProductCategory,setSelectedProductCategory]=useState('')
  const [SelectedVendor, setSelectedVendor]= useState('')
  const [allproductCategories,setAllproductCategories]= useState(false)
const [allVendors , setAllVendors]=useState(false)
const [productgroupAlert, setProductsAlert] = useState(false)

useEffect(()=>{
  const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
  const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
  if(storedLoginData&&Userdata){
    if(storedLoginData.token===Userdata.token){
    }else{
      navigate('/bb/admin')
     sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
    }
  }else{
    navigate('/bb/admin')
      sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
  }
})


useEffect(() => {
  //fetchProducts();
  fetchProductCategories();
}, []);

  const clearStorageAndRedirectToHome=()=> {
  sessionStorage.clear();
  localStorage.clear();
  window.location.href = '/bb/admin';
} 
 
useEffect(() => {
  if(selectedProductCategory && selectedProductCategory.length === productCategories.length){
      setAllproductCategories(true); 
  } else {
      setAllproductCategories(false);
  }
}, [productCategories, selectedProductCategory]);

useEffect(() => {
  if(SelectedVendor && SelectedVendor.length === vendors.length){
      setAllVendors(true); 
  } else {
      setAllVendors(false);
  }
}, [vendors, SelectedVendor]);


const fetchProductCategories = async () => {
  try {
    const token = sessionStorage.getItem('token');
    const urlOfproductCategories = process.env.REACT_APP_FORE_BRICKBUCKET + '/Admin/get-all-productCategorys';
    const response = await fetch(urlOfproductCategories, {
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        'x-token' : token 
      }
    });
    if (response.ok) {
      const data = await response.json();
     // console.log(data)
      setProductCategories(data);

    } else {
      setProductCategories([]);
     
      console.error('Failed to fetch product categories:');
    }
  } catch{
    // Handle fetch errors or any other errors that might occur
    console.error('Error fetching product categories :');
    clearStorageAndRedirectToHome()
  }
};


    const handleGetProductGroups= ()=>{
      getAllProductGroups()
    }

    const getAllProductGroups = async () => {
        try {
          const token = sessionStorage.getItem('token');
             const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-groups/get-productGroupsData-by-productCategoryIdAndVendorId?product_category_id=${selectedProductCategory}&vendor_id=${SelectedVendor}`;
            const response = await fetch(url , {
              method: 'GET',
              headers:{
                'Content-Type': 'application/json',
                'x-token' : token
              }
            });
            if (response.ok) {
                const data = await response.json();
                setViewProductGroups(false)
                setProductGroups(data);
                console.log(data)
            } else {
              setProductsAlert('No Product Groups Available') 
              setProductGroups([]);
                console.error('Failed to fetch productgroups:', response.statusText);
            }
        } catch {
            console.error('Error fetching productgroups:');
            clearStorageAndRedirectToHome()
        }
    }

    const handleDeleteProductGroupData = async () => {
      setDeleteProductGroupAlert(false);
      // Display confirmation modal
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-groups/delete-productGroup?product_group_id=${productGroupIdToDelete}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-token' : token
            // Add any other headers as needed
          },
          
        });
    
        if (response.ok) {
          getAllProductGroups()
          // Handle success, such as updating UI or showing a success message
        } else {
          // Handle failure
          setContDeleteProductGroupAlert('ProductGroup is associated with data and cannot be deleted')
          console.error('Failed to delete product group:', response.statusText);
        }
      } catch {
        console.error('Error deleting product group:');
        clearStorageAndRedirectToHome()
      }

    };

    const fetchVendorsByCategory = async (product_category_id) => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(
          `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/get-vendorsBy-productCategoryId?product_category_id=${product_category_id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token' : token 
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          //console.log(data.vendors);
          setVendors(data.vendors);
          setSelectedVendor('');
      
        } else {
  
          // Set vendors to initial state if fetching fails
          setVendors([]);
          setSelectedVendor('');
        }
      } catch {
        clearStorageAndRedirectToHome()
        // Set vendors to initial state if an error occurs
        setVendors([]);
        setSelectedVendor('');
      }
    };
    
    const handleChangeProductCategory = (selectedCategoryId) => {
      if (selectedCategoryId===selectedProductCategory) {
        setSelectedProductCategory('');
      } else {
        setSelectedProductCategory(selectedCategoryId);
        if(selectedCategoryId!==''){
          fetchVendorsByCategory(selectedCategoryId);
        }else{
          setVendors([])
        }
        
      }
    };
    
  
  
    const handleChangeVendor = (selectedVendorId) => {
      setProductsAlert(null);
    
      if (selectedVendorId===SelectedVendor) {
        // Selecting all vendors
        setSelectedVendor('');
        // Logic for handling all vendors selected
      } else {
        setSelectedVendor(selectedVendorId)  
      }
    };
  
   
    const handleNoDelete = () => {
      // Close confirmation modal
      setDeleteProductGroupAlert(false);
      // Reset productIdToDelete state
      setProductGroupIdToDelete(null);
    };

    const handleDeleteProductGroup = async (productGroupId) => {
      setDeleteProductGroupAlert('Are you sure want to Delete?');
      setProductGroupIdToDelete(productGroupId);
    };
    
  const fetchProducts = async (productGroup) => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product/get-products-by-groupIdAndProductCategoryId?product_category_id=${productGroup.productCategory._id}&product_group_id=${productGroup._id}&vendor_id=${productGroup.vendor._id}`,{
        method:'GET',
        headers:{
          'Content-Type': 'application/json',
          'x-token' : token
        }
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        const FilterProducts= data.filter(p=>p.vendor._id===productGroup.vendor._id)
        setProducts(FilterProducts);
       
      } else {
        // Handle other responses (non-2xx status codes)
        // For example, display an error message or perform appropriate actions
        setProducts([]);
        console.error('Failed to fetch products:', response.statusText);
      }
    } catch {
      // Handle fetch errors or any other errors that might occur
      clearStorageAndRedirectToHome()
      console.error('Error fetching products:');
    }
  };
  

/*   useEffect(() => {
    fetchProducts();
  }, []); */

  const handleShowAssignProducts = (productGroup) => {
    setshowAssignProducts(true);
    fetchProducts(productGroup);
    setProductGroup(productGroup)
    setProductGroupId(productGroup._id)
  } 

  const handlecloseAssignProducts = () => {
    setshowAssignProducts(false);
  }

  const handleCheckboxChange = async (product, product_group_id) => {
    if(product.productData. product_group_id!==product_group_id||product.productData. product_group_id===null){
      handleAssignTheProductToGroup(product._id,product_group_id,product.vendor._id)
    }else{
      handleUnAssineTheProduct(product._id,product_group_id, product.vendor._id)
      
    }

   
};


const handleAssignTheProductToGroup= async(product_id,product_group_id,vendor_id)=>{
  try {

    const token = sessionStorage.getItem('token');
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product/assign-product-to-group?product_group_id=${product_group_id}&product_id=${product_id}&vendor_id=${vendor_id}` , {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'x-token' : token 
      },
    });
   
    if (response.ok) {
        // Update products state with response data
        const data = await response.json();
        const FilterProducts= data.filter(p=>p.vendor._id===ProductGroup.vendor._id)
        setProducts(FilterProducts);
       
    } else {
        // Handle other responses (non-2xx status codes)
        // For example, display an error message or perform appropriate actions
        console.error('Failed to fetch products:', response.statusText);
    }
} catch {
    // Handle fetch errors or any other errors that might occur
    clearStorageAndRedirectToHome()
    console.error('Error fetching products:');
}
}
 
const handleUnAssineTheProduct=async(product_id,product_group_id,vendor_id)=>{
  try {
    const token = sessionStorage.getItem('token');

    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product/Unassign-product-to-group?product_group_id=${product_group_id}&product_id=${product_id}&vendor_id=${vendor_id}` , {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'x-token' : token 
      },
    });
   
    if (response.ok) {
        // Update products state with response data
        const data = await response.json();
        const FilterProducts= data.filter(p=>p.vendor._id===ProductGroup.vendor._id)
        setProducts(FilterProducts);
    } else {
        // Handle other responses (non-2xx status codes)
        // For example, display an error message or perform appropriate actions
        console.error('Failed to fetch products:', response.statusText);
    }
} catch{
    // Handle fetch errors or any other errors that might occur
    console.error('Error fetching products:');
    clearStorageAndRedirectToHome()
}
}
  const handlecloseAllert= ()=>{
    setContDeleteProductGroupAlert(null);
  }

    return (
        <>
               <div className="overflow-y-scroll no-scrollbar w-full h-full mx-auto flex flex-col items-center ">
   <div className="flex flex-col items-center justify-center p-2">
   <div className=' flex flex-col flex justify-center  items-center  p-2 '>
               
   <div className="border w-full flex justify-center items-center">
      <div className="flex justify-center items-center p-1 w-full">
  <div className="flex flex-row space-x-4 w-full">

  <div className="mb-3  flex items-center">
      <div className="">
        <p className="text-md font-medium text-gray-700 mb-3 px-2">Product categories</p>
  
  <select
    value={selectedProductCategory} // Set selected values
    onChange={(e) => handleChangeProductCategory(e.target.value)}
    className="block p-1 ml-2 border border-gray-300 hover:border-[#EB001B] rounded-sm"
            style={{ width: "350px" }} // Set fixed width here
            >
    <option value={''}>Select Product Category</option>
    {productCategories.map((category, index) => (
      <option key={category._id} value={category._id}>
        {category.product_category_name}
      </option>
    ))}
  </select>
  </div>
</div>          
<div className="flex flex-col  items-center">
      <div className="">
        <p className="text-md font-medium text-gray-700 mb-3 px-2">Vendors</p>
            <select
  value={SelectedVendor}
  onChange={(e) => handleChangeVendor(e.target.value)}
  className="p-1 ml-2 border border-gray-300 hover:border-[#EB001B] rounded-sm"
  style={{ width: "350px" }} // Set fixed width here
>

<option value=''>
    All Vendors
  </option>
  {vendors.map((vendor) => (
    <option key={vendor._id} value={vendor._id}>
      {vendor.name}
    </option>
  ))}
 
  
</select>

</div>
          </div>
          <div className="mt-9">
        <button className="px-6 py-1 mr-2 ml-2 border border-1 rounded-sm border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white" onClick={handleGetProductGroups}>Submit</button>
      </div>

      {/* <div className="flex justify-center py-2 px-1 items-center">
        <p className="text-red-500 ">{productgroupAlert}</p>
      </div> */}
   </div>
         </div>
         </div>

         
      
 
        
                {ContDeleteProductGroupAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{ContDeleteProductGroupAlert}</p>
      <div className="flex">
        <button className="text-white bg-red-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handlecloseAllert}>OK</button>
      </div>
    </div>
  </div>
)}

                {DeleteProductGroupAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{DeleteProductGroupAlert}</p>
      <div className="flex">
        <button className="text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4 mr-2" onClick={handleDeleteProductGroupData}>Yes</button>
        <button className="text-white bg-red-600 hover:bg-red-700 py-1 px-2 rounded-md mt-4" onClick={handleNoDelete}>No</button>
      </div>
    </div>
  </div>
)}
                    <div className="bg-white flex flex-col relative rounded-md p-2 md:ml-10 md:mr-10 mx-2 md:mx-6 overflow-x-auto">
                       {/*  <h1 className="text-xl font-semibold text-center">View Product Groups</h1> */}
                        <div className="overflow-x-auto">
                            {productGroups.length > 0 ? (
                                <table className="w-full border border-gray-300 mt-4">
                                    <thead className="border border-gray-300">
                                        <tr className="bg-[#D9D9DD] bg-opacity-[100%]">
                                            <th className="text-blue-950  font-medium py-2 px-4 text-sm text-center">Product Group</th>
                                            <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Vendor Name</th>
                                            <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Product Category</th>
                                            <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Product Subcategory</th>
                                            <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Assign Product </th>
                                            <th className="text-blue-950  font-medium py-2 px-4 text-sm text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {productGroups.map((productGroup, index) => (
                                            <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                                <td className="text-[#EB001B] text-opacity-80 py-2 text-xs px-4 text-center cursor-pointer" onClick={() => navigate('/bb/admin/AdminHome/UpdatedProductGroups', { state: { productGroup } })}>
                                                    {productGroup.productGroupData && productGroup.productGroupData.product_group_name ? productGroup.productGroupData.product_group_name : 'no group assigned'}
                                                </td>

                                                <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center">{productGroup.vendor.name}</td>
                                                <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center">{productGroup.productCategory.product_category_name}</td>
                                                <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center">
                                                      {productGroup.productSubCategory?.product_sub_category_name || '---'}
                                                    </td>

                                                <td className="text-[#EB001B] text-opacity-80 py-2 text-xs px-4 text-center cursor-pointer" onClick={() => handleShowAssignProducts(productGroup)}>
                                                    {productGroup.productGroupData.product_name || 'Assign Products'}
                                                </td>

                                                <td className="text-[#EB001B] text-opacity-80 py-2 text-xs px-4 text-center cursor-pointer">
                                                    <span className="text-center" onClick={() => handleDeleteProductGroup(productGroup._id)}>Delete</span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                            <>
                            {productgroupAlert ? (
                              <p className="text-center mt-10">No product groups available</p>
                          ) : (
                          <></>
                          )}

                            </>
                                
                            )}
                        </div>

                        {showAssignProducts && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center overflow-y-scroll no-scrollbar">
    <div className="flex flex-col space-y-2 bg-white p-4 rounded-md shadow-md relative max-h-full overflow-y-auto">
      <button className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-700" onClick={() => handlecloseAssignProducts()}>
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <h2 className="text-xl font-bold text-center">Assign Products</h2>
      <div className="overflow-x-auto">
        {products.length > 0 ? (
          <table className="w-full border border-gray-300">
            <thead className="border border-gray-300">
              <tr className="bg-[#D9D9DD] bg-opacity-[100%]">
                <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Product Name</th>
                <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Vendor Name</th>
                <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Product Category</th>
                <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Product Subcategory</th>
                <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Product Group</th>
                <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                  <td className="text-[#EB001B] text-opacity-80 py-2 text-xs px-4 text-center cursor-pointer" onClick={() => navigate('/bb/admin/AdminHome/UpdateProduct', { state: { product } })}>{product.productData.product_name || 'No Product Name'}</td>
                  <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center">{product.vendor.name}</td>
                  <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center">{product.productCategory.product_category_name}</td>
                  <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center">
                    {product.productSubCategory?.product_sub_category_name || '---'}
                    </td>
                  <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center">{product.productGroup && product.productGroup.product_group_name ? product.productGroup.product_group_name : 'no group assigned'}</td>
                  <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-blue-500"
                      checked={product.productData.product_group_id === productGroupId || false}
                      onChange={() => handleCheckboxChange(product, productGroupId)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center">No products available</p>
        )}
      </div>
    </div>
  </div>
)}

                    </div>
                </div>
            </div>
            </div>
        </>
    )
}
export default ViewProductGroups;
