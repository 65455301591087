import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ApplyFilters = ({ attributesdata, setAllAttributeIds, attributeValueIds }) => {
  const [attributeIds, setAttributeIds] = useState([]);
   const navigate = useNavigate()
  useEffect(() => {
    // Initialize filterIds based on filterValueIds
    if (attributeValueIds) {
      
      const initialAttributeIds = attributesdata.reduce((acc, attributeGroup) => {
        const groupAttributeIds = attributeGroup.attribute_values
          .filter((attributeValue) => attributeValueIds.includes(attributeValue.attribute_value_id))
          .map((attributeValue) => ({
            attribute_name_id: attributeGroup.attribute_name_id,
            attribute_value_id: attributeValue.attribute_value_id,
          }));
        return [...acc, ...groupAttributeIds];
      }, []);
      setAttributeIds(initialAttributeIds);
    }
    else {
      setAttributeIds([]);
    }
  }, [attributeValueIds, attributesdata, setAttributeIds ]);

  useEffect(() => {
    if (typeof setAllAttributeIds === 'function') {
      setAllAttributeIds(attributeIds);
    }
  }, [attributeIds, setAllAttributeIds]);

  const handleRadioChange = (attribute_name_id, attributeValueId) => {
    const findIndexOfAttributeId = attributeIds.findIndex((p) => p.attribute_name_id === attribute_name_id);

    if (findIndexOfAttributeId === -1) {
      // If filter ID doesn't exist, add it to the array
      setAttributeIds([...attributeIds, { attribute_name_id: attribute_name_id, attribute_value_id: attributeValueId }]);
    } else {
      // If filter ID exists, update its filter_value_id
      const updatedAttributeIds = [...attributeIds];
      updatedAttributeIds[findIndexOfAttributeId].attribute_value_id = attributeValueId;
      setAttributeIds(updatedAttributeIds);
    }
  };

  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
        navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })
  

  return (
    <div className='bg-[#f8f4ec] '>
      <h1 className='text-xl flex justify-center font-semibold '>Attributes</h1>

      <div className="flex justify-center flex-col items-center">
        <table>
          <tbody>
            {attributesdata.map((attribute, index) => (
              <tr key={index} className='border-2 border-2 border-[#B89535] border-opacity-50'>
                <td className='py-2 px-4 border-2 border-2 border-[#B89535] border-opacity-50'>
                  <h1 className='font-semibold'>{attribute.attribute_name}</h1>
                </td>
                <td>
                  <div className='flex flex-col items-left ml-10 px-4 py-2'>
                    {attribute.attribute_values.map((attributeValue, attributeValueIndex) => (
                      <div key={attributeValueIndex} className='flex flex-row items-center'>
                  <input
  type='radio'
  id={`attribute_${index}_${attributeValueIndex}`}
  name={`attribute_${index}`}
  onChange={() => handleRadioChange(attribute.attribute_name_id, attributeValue.attribute_value_id)}
  checked={attributeIds.some(id => id.attribute_value_id === attributeValue.attribute_value_id)}
  className="appearance-none w-3 h-3 border border-black rounded-full checked:bg-[#EB001B] checked:border-transparent focus:outline-none focus:ring-1 focus:ring-[#EB001B]" // Use Tailwind utility classes for styling
/>

                        <label htmlFor={`attribute_${index}_${attributeValueIndex}`} className='ml-5'>
                          {attributeValue.attribute_value}
                        </label>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ApplyFilters;
