import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DeliveryDetails from "./DeliveryDetails";

function OrderDetails() {
    const navigate = useNavigate();
    const location = useLocation(); // Import useLocation hook
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
    const { quotationid, address} = location.state || [];
    const [totalPriceSum, setTotalPriceSum] = useState(0); // State for total price sum
    const [customerOrderData,setCustomerOrderData]=useState(null)

    useEffect(() => {
        async function fetchData() {
            try {
                const token = sessionStorage.getItem('token');

                const response = await fetch(`${backendApiUrl}/customer/quotation/get-quotation-offers-and-products-for-order?quotation_ids=${encodeURIComponent(JSON.stringify(quotationid))}`,{
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'x-token':token
                    },
                    });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result);
                setLoading(false);
            } catch (error) {
                setError(error);
                clearStorageAndRedirectToHome()
            }/*  finally {
                setLoading(false);
            } */
        }

        fetchData();
    }, [quotationid, backendApiUrl]);

    function clearStorageAndRedirectToHome() {
        navigate("/bb/ErrorPage");
      }

      useEffect (() => {
        fetchConsumerOrders();
      },[])
    
      const fetchConsumerOrders = async () => {
        const customer = JSON.parse(localStorage.getItem('userData'))
        const consumer_id = customer._id;
        const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/Quotation-Order/get-all-customer-quotation-Orders-data?customer_id=${consumer_id}`;
      
        try {
          const token = sessionStorage.getItem('token');
    
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-token':token
            },
          });
      
          if (response.ok) {
            const data = await response.json();
            console.group(data);
           // setConsumerOrders(data.orders);  // Ensure setConsumerOrders is defined in your component or context
           //setCustomerOrderData(data.orders[0]) ;
           console.log(data.orders);
            console.log(data);
          } else {
            console.error(`Failed to fetch consumer order details: ${response.status} ${response.statusText}`);
          }
        } catch (error) {
          console.error('An error occurred while fetching consumer orders:', error);
          clearStorageAndRedirectToHome()
        }
      };
    
    

    useEffect(() => {
        if (data) {
            let sum = 0;
            data.forEach(item => {
                item.quotation.quotationData.forEach(qd => {
                    const product = item.products.find(p => p._id === qd.product_id);
                    const price = calculatePrice(product, item);
                    const totalPrice = qd.count * parseFloat(price);
                    sum += totalPrice;
                });
            });
            setTotalPriceSum(sum.toFixed(2)); // Set the total price sum
        }
    }, [data]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    

    const calculatePrice = (product, quotation) => {
        /* const Quotationdata = quotation.quotation.quotationData;
        const quotationArray = Quotationdata.find(p => p.product_id === product._id);
        const offerId = quotationArray.offer_id;

        const offer = quotation.offers.find(p => p._id === offerId);
        if (!product || !offer) {
            return 'Price Not Available';
        }
        const basePrice = product.product_price || 0;
        const discount = offer.discount || 0;
        const gst = offer.gst || 0;

        let netPrice = parseFloat(basePrice);

        // Apply discount
        netPrice -= netPrice * (discount / 100);

        // Apply GST
        netPrice *= 1 + gst / 100;
        return netPrice.toFixed(2); */

        const Quotationdata = quotation.quotation.quotationData;
        const quotationArray = Quotationdata.find(p => p.product_id === product._id);
        const netPrice = quotationArray.price||'';
        return netPrice
    };

    const getDisplayUOM = (uom, count) => {
        const countNumber = Number(count);
        // Convert UOM to lowercase and remove special characters for comparison
        let sanitizedUOM = uom.toLowerCase().replace(/[^a-z]/g, '');
      
        if (countNumber === 1) {
            // Singular form special cases handling
            if (sanitizedUOM === 'nos') {
                return "No";
            } else if (sanitizedUOM === 'bags') {
                return "Bag";
            } else if (sanitizedUOM === 'coils') {
                return "Coil";
            } else if (sanitizedUOM === 'packets') {
                return "Packet";
            }
            // Additional logic for other special cases can be added here
            return uom.replace(/'s$/, ''); // Assuming 's is for plural
        } else {
            // For plural form handling
            if (uom.endsWith('s')) {
                return uom;
            }
            return `${uom}s`;
        }
      };
  

  const  handleBack=()=>{
    navigate(-1)
  }

  
    return (
        <div className="p-2 rounded-md justify-start items-start w-full mt-3">
        <div className="rounded-md justify-start items-start overflow-y-auto">
        
        <div className="p-1 flex flex-col justify-between items-center">
        <div className='flex-grow md:w-2/3 sm:w-full border-gray-400 border-b flex justify-center'>
        <h1 className="font-semibold">Order Details</h1>
    </div>
                {data &&
                    data.map((item, index) => (
                        <div className='flex flex-col md:w-2/3 sm:w-full' key={index}>
                            {item.quotation.quotationData.map(qd => {
                                const product = item.products.find(p => p._id === qd.product_id);
                                const price = calculatePrice(product, item);
                                const totalPrice = (qd.count * parseFloat(price)).toFixed(2);
                                return (
                                    <div key={qd.product_id} className='flex flex-col sm:flex-row items-center p-2 border-b'>
                                    <div className='flex flex-row w-full'>
                                        <div className='md:w-1/3 sm:w-1/2 flex items-center justify-center'>
                                            <div className='pt-2 md:w-20 md:h-20 sm:w-28 sm:h-28 overflow-hidden flex items-center justify-center'>
                                                <img className='max-w-full max-h-full object-contain' src={`${ImageUrl}/${product.product_image}`} alt={product.product_name} />
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center md:w-2/3 sm:w-1/2 flex-col">
                                            <div className="flex justify-center">
                                                <p className="md:text-md sm:text-lg font-semibold text-center whitespace-normal">{product.product_name}</p>
                                            </div>
                                            <div className="grid grid-cols-3 gap-1 mt-1">
                                                {Object.entries(item.quotation.quantity_break_up && item.quotation.quantity_break_up.find(p => p.product_id === product._id)?.break_ups || {}).map(([key, value], index) => (
                                                    (value !== "" && value !== 0 && value !== undefined && value !== null) && (
                                                        <div key={index} className="flex flex-row ">
                                                            <p className='text-md font-semibold'>{key}=</p>
                                                            <p className='text-md font-semibold'>{value}</p>
                                                        </div>
                                                    )))}
                                                    </div>
                                             <div className='flex flex-col items-center mt-2'>
                                                        <p className='whitespace-normal'><span className='text-lg font-semibold'>Qty: </span> {qd.count} {getDisplayUOM(product.UOM, qd.count)}</p>
                                                        <p className='whitespace-nowrap'><span className='text-lg font-semibold'>Offer Price: </span> ₹{parseFloat(price).toFixed(2)}</p>
                                                        <p className='text-lg font-semibold mt-2'>
                                                            ₹{totalPrice}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className='font-semibold flex justify-end items-center w-full'>
                                    <p className='text-lg font-semibold'>Total : ₹{totalPriceSum}</p>
                                </div>
                            </div>
                        ))}

<div className='border-b md:w-2/3 sm:w-full border-gray-300 mt-10 ' />

<div>
           {address && <DeliveryDetails Deliveryaddress={address} />}
    </div>

                    <div className='flex justify-center mb-4 mt-6 '>
                        <button className='rounded-sm text-black hover:text-white border border-[#EB001B] px-4 flex justify-center hover:bg-[#EB001B]' onClick={handleBack}>Back</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderDetails;
