import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { UNSAFE_DataRouterStateContext, useLocation, useNavigate } from 'react-router-dom';


function UpdateProduct() {
    const navigate = useNavigate();
    const location = useLocation();
    const { product } = location.state;
    console.log(product);

    // State variables for dropdown selections
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedVendor, setSelectedVendor] = useState('');
    const [selectedProductGroup, setSelectedProductGroup] = useState('');

    // State variables for data fetching
    const [productCategories, setProductCategories] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [productGroups, setProductGroups] = useState([]);

    // State variables for product name and specifications
    const [productName, setProductName] = useState('');
    const [specifications, setSpecifications] = useState([]);
    const [updateProductAlert, setUpdateProductAlert] = useState(false);
    const [productImage, setProductImage] = useState('');
    const [productFile, setProductFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [productPrice,setProductPrice]= useState('')
    const [unitOfMeasurement, setUnitOfMeasurement] = useState('');
    const [selectedFolder, setSelectedFolder] = useState('');
    const [AttributesData, setAttributesData] = useState([]);
    const [attributeIds, setAttributeIds] = useState([]);
    const [productSubCategories, setProductSubCategories] = useState([]);
    const [selectedProductSubCategory, setSelectedProductSubCategory] = useState('');
    const [selecteProductCategoryError,setSelecteProductCategoryError]= useState(false)
    const [unitOfMeasurementOptions, setUnitOfMeasurementOptions] = useState([]); 
    const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;

 useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
        navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })
  
      
    // Fetch product categories
    useEffect(() => {
        setImageUrl(process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES+productImage)
    }, [productImage]);

    function clearStorageAndRedirectToHome() {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/bb/admin';
      }

    useEffect(() => {
        const fetchProductCategories = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/get-all-productCategorys`,
                    {
                        method:"GET",
                        headers:{
                            'Content-Type': 'application/json',
                            'x-token' : token
                        }
                    }
                );
                if (response.ok) {
                    const data = await response.json();
                    setProductCategories(data);
                } else {
                    console.error('Failed to fetch product categories:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching product categories:', error.message);
                clearStorageAndRedirectToHome()
            }
        };

        fetchProductCategories();
    }, []);

    useEffect(() => { 
        if(product){
        setAttributeIds(product.attributeValuesData)
        setSelectedCategory(product.productCategory ? product.productCategory._id : "");
        setSelectedVendor(product.vendor ? product.vendor._id : '');
        setSelectedProductGroup(product.productGroup ? product.productGroup._id : '');
        setProductName(product.productData ? product.productData.product_name : '');
         setSpecifications(
            product.productData.specifications
                ? JSON.parse(product.productData.specifications)
                : []
        ); 
        
        //setSpecificationNames(product.specificationData ? data.specificationData : []);
        setProductImage(product.productData ? product.productData.product_image : '');
        setProductPrice(product.productData ? product.productData.product_price : '');
        setUnitOfMeasurement(product.productData ? product.productData.UOM : '');
        setSelectedProductSubCategory(product.productSubCategory?._id||'')

        fetchProductSubCategories(product.productCategory._id )
    }
   
    }, [product]);


/*     useEffect(() => {
       if(selectedProductSubCategory===''&& selectedVendor){
        fetchProductGroups( product.productCategory._id, selectedVendor)
       }else{
      const  SubCategoryproductGroups= productGroups.filter(p=>p.product_sub_category_id===selectedProductSubCategory)
      setProductGroups(SubCategoryproductGroups||[])
       }
    }, [selectedProductSubCategory,selectedVendor,productGroups]); */


    useEffect(() => {
        if (selectedCategory) {
            fetchVendors();
        }
    }, [selectedCategory]);

    const fetchVendors = async () => {
        const token = sessionStorage.getItem('token');
        try {
            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/get-vendorsBy-productCategoryId?product_category_id=${selectedCategory}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-token' : token,

                    }
                }
            );
            if (response.ok) {
                const data = await response.json();
    
                setVendors(data.vendors);
            } else {
                console.error('Failed to fetch vendors:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching vendors:', error.message);
            clearStorageAndRedirectToHome()
        }
    };

    // Fetch product groups
    useEffect(() => {
        if(selectedCategory,selectedVendor){
            fetchProductGroups(selectedVendor, selectedCategory);
        }
          
        
    }, [ vendors, selectedCategory, selectedVendor]);

    const fetchProductGroups = async (VendorId, productCategoryId) => {
        try {
            const token = sessionStorage.getItem('token');
          // Construct the URL with query parameters
          const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-groups/get-all-productGroups-by-productCategoryIdAndVendorId`;
          const queryParams = new URLSearchParams({
            product_category_id: productCategoryId,
            vendor_id: VendorId,
          });
          const apiUrl = `${url}?${queryParams.toString()}`;
      
          const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token' : token
            },
          });
      
          if (response.ok) {
            const data = await response.json();
            console.log(data)
            setProductGroups(data.productGroups);
          } else {
            // Set product groups to initial state if fetching fails
            setProductGroups([]);
            setSelectedProductGroup('');
          }
        } catch (error) {
          // Set product groups to initial state if an error occurs
          clearStorageAndRedirectToHome()
          setProductGroups([]);
          setSelectedProductGroup('');
        }
      };
      

  /*   const fetchProductGroups = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-groups/get-all-productGroups?product_category_id=${selectedCategory}`);
            if (response.ok) {
                const data = await response.json();
                setProductGroups(data.productGroups);
            } else {
                console.error('Failed to fetch product groups:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching product groups:', error.message);
        }
    };
 */
 /*    useEffect(() => {
        if (product) {
            console.log(product)
            fetchProductByProductId(product._id);
        }
    }, [product]); */

    /* const fetchProductByProductId = async (productId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product/get-product-by-productId?product_id=${productId}`);
            if (response.ok) {
                const data = await response.json();
                setSelectedCategory(data.productCategory ? data.productCategory._id : "");
                setSelectedVendor(data.vendor ? data.vendor._id : '');
                setSelectedProductGroup(data.productGroup ? data.productGroup._id : '');
                setProductName(data.productData ? data.productData.product_name : '');
                setSpecifications(data.specificationValueData ? data.specificationValueData : []);
                setSpecificationNames(data.specificationData ? data.specificationData : []);
                setProductImage(data.productData ? data.productData.product_image : '');
                setProductPrice(data.productData ? data.productData.product_price : '')
            } else {
                console.error('Failed to fetch product by ID:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching product by ID:', error.message);
        }
    }; */

    const handleProductNameChange = (e) => {
        setProductName(e.target.value);
    };

    useEffect(() => {
        fetchUnitOfMeasurementOptions(); // Fetch UOM options on component mount
      }, []);
    
     // In your fetchUnitOfMeasurementOptions function:
     const fetchUnitOfMeasurementOptions = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${backendApiUrl}/Admin/UOMs/get-all-UOMs`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
               'x-token' : token
            },
          });
        if (response.ok) {
          const data = await response.json();
          setUnitOfMeasurementOptions(data.uoms); // This is the correct way to store the array of UOMs
          console.log(data); 
        } else {
          console.error('Failed to fetch UOM options');
        }
      } catch (error) {
        console.error('Error while fetching UOM options:', error);
      }
    };

    const handleUnitOfMeasurementChange = (e) => {
        setUnitOfMeasurement(e.target.value); // You might want to store the selected UOM id instead of just the value
      };

    const handleSubmit = async () => {
        const attributeIdsArray = attributeIds.map((attribute) => attribute._id);
        const formData = new FormData();
        formData.append('product_group_id', selectedProductGroup);
        formData.append('vendor_id', selectedVendor);
        formData.append('product_category_id', selectedCategory);
        formData.append('product_sub_category_id', selectedProductSubCategory);
        formData.append('product_name', productName);
        formData.append('specifications', JSON.stringify(specifications));
        formData.append('_id', product._id);
        formData.append('product_price', productPrice);
        formData.append('UOM', unitOfMeasurement);
        formData.append('folder_name', selectedFolder);
        attributeIdsArray.forEach((attributeId) => {
            formData.append('attribute_value_ids', attributeId);
        });
        if (productFile) {
            formData.append('image', productFile);
            
        } else {
            formData.append('product_image', productImage);

        }
    
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product/update-product`, {
                method: 'PUT',
                headers:{
                    //'Content-Type': 'application/json',
                    'x-token' : token
                },
                body: formData,
            });
            
            if (response.ok) {
                setUpdateProductAlert('Product updated successfully!');
            } else {
                console.error('Failed to update product:', response.statusText);
              const data = await response.json()
              setUpdateProductAlert(data.message)
            }
        } catch (error) {
            console.error('Error updating product:', error.message);
            clearStorageAndRedirectToHome()
        }
        
    };
    
    const fetchProductSubCategories = async (product_category_id) => {
        try {
            const token = sessionStorage.getItem('token');

            const getProductSubcategories = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-sub-category/get-productSubCategories-by-productCategoryId?product_category_id=${product_category_id}`;
            
            const response = await fetch(getProductSubcategories, {
                method: "GET",
                headers:{
                    //'Content-Type': 'application/json',
                    'x-token' : token
                },
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setProductSubCategories(data.data);
            } else {
              setSelectedProductSubCategory('')
              setProductSubCategories([])
                console.error('Failed to fetch product subcategories', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error while fetching product subcategories:', error);
        }
    };

    const handleUpdateProductAlert = () => {
        setUpdateProductAlert(false);
        navigate('/bb/admin/AdminHome/ViewProducts');
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // You can perform additional checks or operations here, such as validating file type or size.
            setProductFile(file); // Assuming you have a state variable to store the image file.
            const reader = new FileReader();
            reader.onload = () => {
                //setProductImage(file.name);
                setImageUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSpecificationNameChange = (index, name) => {
        // Create a copy of the specifications array
        const updatedSpecifications = [...specifications];
    
        // Update the name of the specification at the specified index
        if (updatedSpecifications[index]) {
            const spec = updatedSpecifications[index];
            const key = Object.keys(spec)[0]; // Get the current key (assuming one key per object)
    
            // Check if the key is different from the new name
            if (key !== name) {
                const value = spec[key]; // Get the current value
                delete spec[key]; // Delete the old key from the object
                spec[name] = value; // Add the new key-value pair
            }
        }
    
        // Update the state with the modified specifications array
        setSpecifications(updatedSpecifications);
    };
    const handleSpecificationValueChange = (index, value) => {
        // Create a copy of the specifications array
        const updatedSpecifications = [...specifications];
    
        // Update the value of the specification at the specified index
        if (updatedSpecifications[index]) {
            const spec = updatedSpecifications[index];
            const key = Object.keys(spec)[0]; // Get the current key (assuming one key per object)
    
            // Update the value of the key in the specification object
            spec[key] = value;
        }
    
        // Update the state with the modified specifications array
        setSpecifications(updatedSpecifications);
    };
    
    
  const openFolderPicker = () => {
    // Create a file input element (hidden)
    const input = document.createElement('input');
    input.type = 'file';
    input.setAttribute('webkitdirectory', ''); // This is for Chrome support
  
    // Trigger a click event on the file input
    input.click();
  
    // Event listener for when a folder is selected
    input.addEventListener('change', function(event) {
    
  
      if (input.files.length > 0) {
        // Get the selected folder path
        const folderPath = input.files[0].webkitRelativePath.split('/')[0]; // Get only the folder name
        //console.log(typeof folderPath)
        //console.log(folderPath)
        setSelectedFolder(folderPath); 
      }
    });
  };

  // Function to extract folder name from product_image path
function extractFolderName(productImagePath) {
    return productImagePath.split("/")[0]; // Assuming folder name is the first part of the path after "Images/"
}

// Sample product_image path
const productImagePath = productImage;

// Extract folder name using the function
const folderName = extractFolderName(productImagePath);

// Print the folder name
//console.log(folderName);

const fetchAttributesByProductCategory = async (productCategoryId) => {
    const token = sessionStorage.getItem('token');
      try {
        const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-attributes/get-all-attributes-by-productCategory?product_category_id=${productCategoryId}`,
          {
            method:'GET',
            headers:{
              'Content-Type': 'application/json',
              'x-token' : token
            }
          }
        );
  
        if (response.ok) {
          const attributesData = await response.json();
  
  
          // Set the state with the fetched attribute data
          setAttributesData(attributesData);
          
    
          // Store the attributes data in local storage
          localStorage.setItem('product_category_attributes', JSON.stringify(attributesData));
    
          // You can perform additional actions if needed
    
        }else{
          console.error('Failed to fetch attributes for the selected product category')
          //throw new Error('Failed to fetch attributes for the selected product category');
        }
  
        
      } catch (error) {
        clearStorageAndRedirectToHome()
      }
     
    };

    useEffect(() => {
       
        if (product && product.productCategory) {
        
        fetchAttributesByProductCategory(product.productCategory._id);
      
    }
}, [product.productCategory]);

const handleRadioChange = (attribute_name_id, attributeValueId) => {

    // Check if the attribute ID exists in attributeIds array
    const existingAttributeIndex = attributeIds.findIndex((p) => p.attribute_name_id === attribute_name_id);
  
    if (existingAttributeIndex === -1) {
      // If attribute ID doesn't exist, add it to the array
      setAttributeIds([...attributeIds, { attribute_name_id: attribute_name_id, _id: attributeValueId }]);
    } else {
      const updatedattributeIds = [...attributeIds];
          if (updatedattributeIds.find(p => p._id === attributeValueId)) {
              const index = updatedattributeIds.findIndex(p => p._id === attributeValueId);
              updatedattributeIds.splice(index, 1); // Remove the existing attribute with the same attribute_value_id
              setAttributeIds(updatedattributeIds);
          
          }else{
            updatedattributeIds[existingAttributeIndex]._id = attributeValueId;
            setAttributeIds(updatedattributeIds);
          ;
          }
    }
  };

  const handleProductSubCategoryDropdown = (value)=>{
    if(value===''){
      setSelectedProductSubCategory(value);
      if(selectedVendor){
        setSelectedProductGroup('')
        fetchProductGroups( selectedVendor, selectedCategory)
      }else{
        setProductGroups([])
      }
    }else{
      setSelectedProductSubCategory(value);
      fetchProductGroupsByCategoryAndVendorIdAndSubcategory(value)
      /* const GroupsUnderSubcategory= (productGroups.filter(p=>p.product_sub_category_id===value))||[];
      setProductGroups(GroupsUnderSubcategory); */
    }
  
  }
  
  const fetchProductGroupsByCategoryAndVendorIdAndSubcategory = async (product_sub_category_id) => {
    try {
      const token = sessionStorage.getItem('token');
      // Construct the URL with query parameters
      const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-groups/get-all-productGroups-by-productCategoryIdAndVendorId`;
      const queryParams = new URLSearchParams({
        product_category_id: selectedCategory,
        vendor_id: selectedVendor,
      });
      const apiUrl = `${url}?${queryParams.toString()}`;
  
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
           'x-token' : token
        },
      });
  
      if (response.ok) {
        const data = await response.json();
         const GroupsUnderSubcategory= (data.productGroups.filter(p=>p.product_sub_category_id===product_sub_category_id))||[];
      setProductGroups(GroupsUnderSubcategory); 
        
      } else {
        // Set product groups to initial state if fetching fails
        setProductGroups([]);
        setSelectedProductGroup('');
      }
    } catch (error) {
      // Set product groups to initial state if an error occurs
      clearStorageAndRedirectToHome()
      setProductGroups([]);
      setSelectedProductGroup('');
    }
  };
    return (
        <div className="overflow-y-auto w-full  flex flex-col items-center ">
            <div className="flex flex-col items-center justify-center p-10">
                <div className='border border-1 border-gray-500 flex flex-col flex justify-center  items-center  p-10 '>
                  
                    <div className='w-full flex flex-col flex justify-center  items-center '>
                    <div className="flex flex-col w-1/2 justify-center">
                            <label className="text-md font-medium text-gray-700 mb-3">Product Category</label>
                            <input
                                type="text"
                                className="block p-1 border border-gray-300 hover:border-[#EB001B] w-full rounded-sm"
                                value={product.productCategory.product_category_name}
                                disabled={true}
                            />
                        </div>
                   {/*  <h2 className="text-lg font-semibold mb-4 flex justify-center">Update Product</h2> */}
         <div className='mb-4 flex flex-row space-x-4 w-full mt-4'>

                       
                          <div className="flex flex-col w-1/2 ">
            <label className="text-md font-medium text-gray-700 mb-3">Product Sub Category</label>
                <select
                  className={` rounded-sm p-1 cursor-pointer  hover:border-[#EB001B] focus:outline-none ${selecteProductCategoryError?'border border-red-300':'border border-gray-300'}`}
                  value={selectedProductSubCategory}
                  onChange={(e)=>handleProductSubCategoryDropdown(e.target.value)}
                >
                   <option value="" >Select SubCategory</option>
                    {productSubCategories.map((SubCategory) => (
                      <option key={SubCategory?._id} value={SubCategory?._id}>
                        {SubCategory?.product_sub_category_name}
                      </option>
                    ))}
                </select>
            </div>
                   
                        <div className="flex flex-col w-1/2">
                            <label className="text-md font-medium text-gray-700 mb-3">Vendor</label>
                            <select
                                value={selectedVendor}
                                onChange={(e) => {setSelectedVendor(e.target.value);setSelectedProductSubCategory('')}}
                                className="p-1 cursor-pointer border border-gray-300 rounded-sm hover:border-[#EB001B] focus:outline-none"
                            >
                                <option value="">Select Vendor</option>
                                {vendors.map(vendor => (
                                    <option key={vendor._id} value={vendor._id}>{vendor.name}</option>
                                ))}
                            </select>
                        </div>

                    </div>

                    <div className="mb-4 flex flex-row space-x-4 w-full">

                        <div className="flex flex-col w-1/2">
                            <p className="text-md font-medium text-gray-700 mb-3">Product Group</p>
                           
                                <select
                                    value={selectedProductGroup}
                                    onChange={(e) => {
                                        const selectedProductGroupId = e.target.value;
                                        setSelectedProductGroup(selectedProductGroupId);
                                        
                                        const selectedProductGroup = productGroups.find(p => p._id === selectedProductGroupId);
                                        setSelectedProductSubCategory(selectedProductGroup && selectedProductGroup.product_sub_category_id?selectedProductGroup.product_sub_category_id : '');
                                    }}
                                    
                                    className="border border-gray-300 rounded-sm p-1 cursor-pointer hover:border-[#EB001B] focus:outline-none"
                                >
                                    <option value="">Select Product Group</option>
                                    {productGroups.map(group => (
                                        <option key={group._id} value={group._id}>{group.product_group_name}</option>
                                    ))}
                                </select>
                           
                        </div>

                       
                            <div className="flex flex-col w-1/2">
                                <label className="text-md font-medium text-gray-700 mb-3">Product Name</label>
                               
                                    <input
                                        type="text"
                                        placeholder='Enter Product Name'
                                        className=" border border-gray-300 w-full hover:border-[#EB001B] rounded-sm p-1 focus:outline-none"
                                        value={productName}
                                        onChange={handleProductNameChange}
                                    />
                               
                            </div>
                        
                    </div>

                    <div className='mb-4 flex flex-row space-x-4 w-full'>
                    
                   
                            <div className="flex flex-col w-1/2">
                                <label className="text-md font-medium text-gray-700 mb-3">Price</label>
                                <div className=" flex">
                                    <input
                                        type="text"
                                        placeholder='Enter Product price'
                                        className=" border border-gray-300 w-full hover:border-[#EB001B] rounded-sm p-1 focus:outline-none"
                                        value={productPrice}
                                        onChange={(e)=>setProductPrice(e.target.value)}
                                    />
                                </div>
                            </div>
                        
                            <div className="flex flex-col w-[70%]">
      <label className="text-md font-medium text-gray-700 mb-3 ">Unit Of Measurement</label>
      <select
        value={unitOfMeasurement}
        onChange={handleUnitOfMeasurementChange}
        className="border border-gray-300 hover:border-[#EB001B] rounded-sm p-1 focus:outline-none"
      >
        <option value="">Select UOM</option>
        {unitOfMeasurementOptions.map((uoms) => ( // Use unitOfMeasurementOptions instead of unitOfMeasurement
          <option key={uoms._id} value={uoms.UOM_type}>
            {uoms.UOM_type}
          </option>
        ))}
      </select>
    </div>
      
       
                    </div>


                    <div className='flex flex-row items-center space-x-4 w-full'>
                        <div className='flex flex-col w-full '>
                            <div className='w-40 h-40 relative'>
                                {imageUrl ? (
                                    <img src={imageUrl} alt="Product Image" className="w-full h-full object-cover rounded-lg" />
                                ) : (
                                    <div className="absolute inset-0 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M7 2a2 2 0 00-2 2v11a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zM5 4a1 1 0 011-1h8a1 1 0 011 1v11a1 1 0 01-1 1H6a1 1 0 01-1-1V4z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                )}
                                <label htmlFor="productImage" className="absolute bottom-0 right-0 p-2 cursor-pointer  ">
                                    <input
                                        type="file"
                                        id="productImage"
                                        accept="image/*"
                                        onChange={(e) => handleFileChange(e)}
                                        className="hidden"
                                    />
                                   <FontAwesomeIcon icon={faCamera} className="text-gray-800 opacity-80 mr-1 mt-1 hover:text-gray-600" />
                                </label>
                            </div>
                        </div>
                        <div className='flex flex-col w-full'>
      <input
  type="text"
  id="selectedFolder"
  placeholder="Click to Select Folder"
  onClick={openFolderPicker}
  readOnly
  value={selectedFolder || folderName}
  className="border border-gray-300 bg-gray-300 text-md font-medium text-gray-700 rounded-sm mt-3 p-1 cursor-pointer hover:border-[#EB001B] focus:outline-none"
/>

{/* <p className="text-md font-semibold flex justify-center mt-5">{folderName}</p> */}

</div> 
</div>
{AttributesData.map((attribute, index) => (
<div className='flex justify-center flex-col items-center'>
     <label className="text-md font-medium text-gray-700 mb-3">Attributes</label>
    <table>
        <tbody>
          
                <tr key={index} className='border-1 border border-[#EB001B] border-opacity-50'>
                    <td className='py-2 px-4 border-1 border border-[#EB001B] border-opacity-50'>
                        <h1 className='text-md font-medium text-gray-700'>{attribute.attribute_name}</h1>
                    </td>
                    <td>
                        <div className='flex flex-col items-left ml-10 px-4 py-2'>
                            {attribute.attribute_values.map((attributeValue, attributeValueIndex) => (
                                <div key={attributeValueIndex} className='flex flex-row items-center text-md text-gray-700'>
                                    <input
                                        type='radio'
                                        id={`attribute_${index}_${attributeValueIndex}`}
                                        name={`attribute_${index}`}
                                        onClick={() => handleRadioChange(attribute.attribute_name_id, attributeValue.attribute_value_id)}
                                        checked={attributeIds.some(p =>
                                            p.attribute_name_id === attribute.attribute_name_id &&
                                            p._id === attributeValue.attribute_value_id
                                        )}
                                        className="appearance-none w-3 h-3 border border-black rounded-full checked:bg-[#EB001B] checked:border-transparent focus:outline-none focus:ring-1 focus:ring-[#EB001B]"
                                    />
                                    <label htmlFor={`attribute_${index}_${attributeValueIndex}`} className='ml-5'>
                                        {attributeValue.attribute_value}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </td>
                </tr>
            
        </tbody>
    </table>
</div>
))}

                        <div className='flex flex-col space-y-2 justify-center items-center w-full'>
    <div className='text-md font-medium text-gray-700 pt-3'>
        <p>Specifications</p>
    </div>
    <div className='flex-col space-y-2'>
        {specifications.map((specification, index) => (
            <div key={index} className="mt-2 flex flex-row space-x-2">
                {/* Render input field for specification name */}
                <input
                    type="text"
                    className="p-2 border border-gray-300 hover:border-[#EB001B] rounded-sm focus:outline-none"
                    value={Object.keys(specification)[0]||''}
                    onChange={(e) => handleSpecificationNameChange(index, e.target.value)}
                />
                <p className='mt-2'>:</p>
                {/* Render input field for specification value */}
                <input
                    type="text"
                    className="p-2 border border-gray-300 hover:border-[#EB001B] rounded-sm focus:outline-none"
                    value={Object.values(specification)[0]||''}
                    onChange={(e) => handleSpecificationValueChange(index, e.target.value)}
                />
            </div>
        ))}
    </div>
</div>



                

                    <div className='flex flex-row items-center justify-center space-x-16 mt-8'>
                        <button
                            className="px-6 py-1 h-30 border border-1 rounded-sm border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white"
                            onClick={() => navigate('/bb/admin/AdminHome/ViewProducts')}
                        >
                            Cancel
                        </button>

                        <button
                            className="px-6 py-1 h-30 border border-1 rounded-sm  border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white"
                            onClick={() => handleSubmit()}
                            >
                                Update
                        </button>
                    </div>
                </div>
           
            </div>
            </div>


            {updateProductAlert && (
                <div className="fixed z-30 inset-0 flex items-center justify-center">
                    <div className="absolute inset-0 bg-black opacity-30 "></div>

                    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
                        <p className="text-lg font-semibold mb-2">{updateProductAlert}</p>
                        <button className="items-center justify-center text-white bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleUpdateProductAlert}>OK</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UpdateProduct;
