import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function CreateProjectContainer() {
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState({
    projectName: "",
    builderName: "",
    builderContactNo: "",
  });

  const [customerData, setCustomerData] = useState({
    customer_name: "",
    mobile_number: "",
  });

  const [errors, setErrors] = useState({});
  const [projectCreatedAlert, setProjectCreatedAlert] = useState(false);
  const [emptyUserData, setEmptyUserData] = useState(false);
  const [showCreateProjectForm, setShowCreateProjectForm] = useState(false);
  const [customer_id, setcustomer_id] = useState(null);

  useEffect(() => {
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData')) || null;
    const userData = JSON.parse(sessionStorage.getItem('userData')) || null;
    if (storedLoginData && userData) {
      if (storedLoginData.token === userData.token) {
        // User is logged in
      } else {
        navigate('/measurit');
        sessionStorage.clear();
      }
    } else {
      navigate('/measurit');
      sessionStorage.clear();
    }
  }, [navigate]);
  const CreateCustomer = process.env.REACT_APP_FORE_BRICKBUCKET + '/customer/create-customer';
  const CreateProject = process.env.REACT_APP_FORE_APILINK + '/projects/create-project';

  const handleInputChange = (form, field, value) => {
    if (form === "project") {
      setProjectData({
        ...projectData,
        [field]: field === 'builderContactNo' ? value.slice(0, 10) : value,
      });
    } else {
      setCustomerData({
        ...customerData,
        [field]: field === 'mobile_number' ? value.slice(0, 10) : value,
      });
    }

    setErrors({
      ...errors,
      [field]: "",
    });
  };

  const handleNext = async () => {
    if (!customerData.customer_name ||!customerData.mobile_number) {
      setEmptyUserData("Please fill all input fields.");
      return;
    }
  
    const validationErrors = {};
    const customer_nameValidation = /^[A-Za-z\s]{3,}$/;
    if (!customer_nameValidation.test(customerData.customer_name.trim())) {
      validationErrors.customer_name = "Enter a valid customer name";
    }
    const mobileNumberValidation = /^[0-9]{10}$/;
    if (!mobileNumberValidation.test(customerData.mobile_number.trim())) {
      validationErrors.mobile_number = "Enter a valid mobile number";
    }
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + `/customer/get-customer-data-by-mobileNumber?mobile_number=${customerData.mobile_number}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'x-token': token,
        },
      });
  
      if (response.ok) {
        const customerResponse = await response.json();
        if (customerResponse.customer) {
          // Customer exists, show project form
          setcustomer_id(customerResponse.customer._id);
          setProjectData(prevData => ({
           ...prevData,
            customer_id: customerResponse.customer._id,
            builderName: customerData.customer_name,
            builderContactNo: customerData.mobile_number
          }));
          setShowCreateProjectForm(true);
        } else {
          // Customer does not exist, create a new customer
          const newCustomerResponse = await fetch(CreateCustomer, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'x-token': token,
            },
            body: JSON.stringify(customerData),
          });
  
          if (newCustomerResponse.ok) {
            const newCustomer = await newCustomerResponse.json();
            setcustomer_id(newCustomer.customer._id);
            setProjectData(prevData => ({
             ...prevData,
              customer_id: newCustomer.customer._id,
              builderName: customerData.customer_name,
              builderContactNo: customerData.mobile_number
            }));
            setShowCreateProjectForm(true);
          } else {
            alert("Failed to create customer. Please try again.");
          }
        }
      } else {
        alert("Failed to fetch customer. Please try again.");
      }
    } catch (error) {
      alert("An error occurred. Please try again later.");
      clearStorageAndRedirectToHome();
    }
  };
  

  const handleCreate = async () => {
    if (!projectData.projectName || !projectData.builderName || !projectData.builderContactNo) {
      setEmptyUserData("Please fill all input fields.");
      return;
    }

    const userId = sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')).userId : null;
    const userRole = sessionStorage.getItem('userRole');
    const isAdmin = userRole === '1';
    const dataToSend = isAdmin ? { ...projectData, customer_id } : { ...projectData, customer_id, assignedUserId: userId };

    const validationErrors = {};
    const builderNameValidation = /^[A-Za-z]+(?:[\s.][A-Za-z]+)*$/;
    if (!builderNameValidation.test(projectData.builderName.trim())) {
      validationErrors.builderName = "Enter a valid builder name";
    }
    const mobileNumberValidation = /^[0-9]{10}$/;
    if (!mobileNumberValidation.test(projectData.builderContactNo.trim())) {
      validationErrors.builderContactNo = "Enter a valid mobile number";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(CreateProject, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'x-token': token,
        },
        body: JSON.stringify(dataToSend),
      });
      console.log(dataToSend)

      if (response.ok) {
        setProjectData({
          projectName: "",
          builderName: "",
          builderContactNo: "",
        });
        setProjectCreatedAlert(true);
      } else {
        alert("Failed to create project. Please try again.");
      }
    } catch (error) {
      alert("An error occurred. Please try again later.");
      clearStorageAndRedirectToHome();
    }
  };

  const handleCloseAlert = () => {
    setProjectCreatedAlert(false);
    const userRole = sessionStorage.getItem('userRole');
    if (userRole === '1') {
      navigate('/measurit/AdminHome');
    } else {
      navigate('/measurit/UserHome');
    }
  };

  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/measurit';
  }

  return (
    <div className="overflow-x-scroll no-scrollbar">
      {projectCreatedAlert && (
        <div className="fixed inset-0 z-10 flex items-center justify-center">
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
            <p className="text-lg font-bold mb-2">Project created successfully!!</p>
            <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleCloseAlert}>
              OK
            </button>
          </div>
        </div>
      )}

      {!showCreateProjectForm ? (
        <div className="ml-10 flex-grow">
          <div className="flex items-center justify-center h-screen mt-2 md:mt-0">
            <div className="bg-[#959BB0] bg-opacity-[15%] w-full max-w-[400px] flex-col px-4 py-4 rounded-sm max-md:w-full max-md:px-3">
              <div className="mb-4 relative">
                <label className="text-gray-600 text-sm mb-1 block">Customer Name</label>
                <input
                  type="text"
                  className={`border bg-white w-full rounded-sm ${errors.customer_name ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
                  placeholder="Enter your customer name"
                  value={customerData.customer_name}
                  onChange={(e) => handleInputChange("customer", "customer_name", e.target.value)}
                />
                {errors.customer_name && (
                  <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.customer_name}</p>
                )}
              </div>
              <div className="mb-4 relative">
                <label className="text-gray-600 text-sm mb-1 block">Customer Contact No</label>
                <input
                  type="text"
                  className={`border bg-white w-full rounded-sm ${errors.mobile_number ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
                  placeholder="Enter customer contact number"
                  value={customerData.mobile_number}
                  onChange={(e) => handleInputChange("customer", "mobile_number", e.target.value)}
                />
                {errors.mobile_number && (
                  <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.mobile_number}</p>
                )}
              </div>
              <p className="text-red-400 text-[14px] flex justify-center">{emptyUserData}</p>
              <div className="flex justify-center mt-2">
                <button className="text-white text-opacity-100 text-base whitespace-nowrap bg-green-600 hover:bg-green-700 justify-center items-center px-6 py-1 rounded-md self-center max-md:px-5 transition-colors duration-300" type="button" onClick={handleNext}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="ml-10 flex-grow">
          <div className="flex items-center justify-center h-screen mt-2 md:mt-0">
            <div className="bg-[#959BB0] bg-opacity-[15%] w-full max-w-[400px] flex-col px-4 py-4 rounded-sm max-md:w-full max-md:px-3">
              <div className="mb-4 relative">
                <label className="text-gray-600 text-sm mb-1 block">Project Name</label>
                <input
                  type="text"
                  className={`border bg-white w-full rounded-sm ${errors.projectName ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
                  placeholder="Enter your project Name"
                  value={projectData.projectName}
                  onChange={(e) => handleInputChange("project", "projectName", e.target.value)}
                />
                {errors.projectName && (
                  <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.projectName}</p>
                )}
              </div>
              <div className="mb-4 relative">
                <label className="text-gray-600 text-sm mb-1 block">Builder Name</label>
                <input
                  type="text"
                  className={`border bg-white w-full rounded-sm ${errors.builderName ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
                  placeholder="Enter builder Name"
                  value={projectData.builderName}
                  onChange={(e) => handleInputChange("project", "builderName", e.target.value)}
                />
                {errors.builderName && (
                  <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.builderName}</p>
                )}
              </div>
              <div className="mb-4 relative">
                <label className="text-gray-600 text-sm mb-1 block">Builder Contact No</label>
                <input
                  type="text"
                  className={`border bg-white w-full rounded-sm ${errors.builderContactNo ? "border-red-500" : ""} focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 text-gray-600 text-[16px] py-1 pl-2 pr-2 placeholder-text-xs placeholder-text-left`}
                  placeholder="Enter builder contact number"
                  value={projectData.builderContactNo}
                  onChange={(e) => handleInputChange("project", "builderContactNo", e.target.value)}
                />
                {errors.builderContactNo && (
                  <p className="absolute top-1 right-4 text-red-500 text-sm">{errors.builderContactNo}</p>
                )}
              </div>
              <p className="text-red-400 text-[14px] flex justify-center">{emptyUserData}</p>
              <div className="flex justify-center mt-2">
                <button className="text-white text-opacity-100 text-base whitespace-nowrap bg-green-600 hover:bg-green-700 justify-center items-center px-6 py-1 rounded-md self-center max-md:px-5 transition-colors duration-300" type="button" onClick={handleCreate}>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateProjectContainer;
