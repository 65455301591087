import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import Main_Page from './Main_Page';
import Create_Vendor from './Vendor/Create_Vendor';
import UpdateVendorBankDetails from './Vendor/Update_Bank_Details';

import Login from './Login';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import VendorList from './VendorList';
import ProductGroupFilters from './ProductGroupFilters';
import VendorProfile from './VendorProfile';
import CreateProduct from './CreateProduct';
import VendorContact from './VendorContact';
import ViewProduct from './ViewProduct';
import UpdateProduct from './UpdateProduct';
import CreateOffer from './CreateOffer';
import ViewOffer from './ViewOffers';
import ProductGroups from './Product_Groups';
import ViewConsumers from './Consumer/View_Consumers';
import CreateProductGroup from './createProductGroup';
import ViewProductGroups from './ViewProductGroups';
import UpdateProductGroups from './UpdatedProductGroups';
import ViewOfferProducts from './ViewOfferProducts';
import ProductAttribute from './productAttributes'
import Quotations from './Quotation';
import AdminViewQuotations from './Consumer/AdminViewQuotations';
//import ProductAttribute from './productAttributes';
import ConsumerOrders from './Consumer/ViewCustomerOrders'
import ConsumerOrderDetails from './Consumer/CustomerOrderDetails'
import AdminViewQuotations2 from './Consumer/AdminViewQuotations2';
import ViewAllOrders from './Consumer/ViewAllOrders';
import  ConsumerOrderDetails2 from './Consumer/customerOrderDetails2'
import DashBord from './DashBord';
import MaterialReQuiremets from './MaterialRequirements'
import CreateCustomerQuotation from './CreateCustomerQuotation';
import AmendQuotation from './Consumer/AmendQuotation';
import AmendQuotation2 from './Consumer/AmendQuotation2';
import ViewAllCustomerQuotations from './ViewAllCustomerQuotations'
function App() {
  const [appId, setappId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const BrickBucket = () => {
      if (location.pathname.startsWith('/bb/admin')) {
        const storedLoginData = JSON.parse(localStorage.getItem('LoginData'));
        if (storedLoginData) {
          const appId = storedLoginData.appId;
        setappId(appId);
        setLoading(false);
        } else {
          // Handle case where user data is not available
          // For example, navigate to login page
          if (location.pathname.startsWith('/bb/admin') && 
          !location.pathname.startsWith('/bb/ResetPassword') &&
          !location.pathname.startsWith('/bb/ForgotPassword') &&
          !location.pathname.startsWith('/bb/ResetPassword/')
      ) {
          navigate('/bb/admin');
          setLoading(false);
      }
          
        }
      }
    };

    BrickBucket(); // Call BrickBucket function directly here
  }, [navigate, location.pathname]); // Add navigate and location.pathname as dependencies


  if (loading) {
    return <div>Loading...</div>;
  }

  const AccessDenied = () => {
    return (
      <div
        className="fixed inset-0 bg-gray-500/50 flex justify-center items-center"
        style={{ backdropFilter: 'blur(100px)' }}
        role="dialog"
        aria-modal="true"
      >
<div className="bg-white rounded shadow-md p-8 max-w-md">
  <h2 className="text-lg font-bold text-center">Access Denied</h2>
  <p className="text-gray-600 text-center">You do not have permission to access this page.</p>
  <div className="flex justify-center mt-2">
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded"
      onClick={() => {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/bb/admin');
      }}
    >
      Go Back
    </button>
  </div>
</div>
      </div>
    );
  };

  return (
    <Routes>
      <Route path="/bb/admin" element={<Login />} />
      <Route path="/bb/admin/ForgotPassword" element={<ForgotPassword />} />
      <Route path="/bb/admin/ResetPassword/:resetToken" element={<ResetPassword />} />
      <Route path="*" element={<AccessDenied />} />

      <Route path="/bb/admin/AdminHome" element={<Main_Page />}>
        {appId === 'BBA' && (
          <>
       <Route path='dashbord' element= {<DashBord/>}/>
       <Route path='viewAllCustomersMaterialRequirements' element={<MaterialReQuiremets/>}/>
      <Route path="CreateVendor" element={<Create_Vendor />} />
      <Route path="UpdateBankDetails" element={<UpdateVendorBankDetails />} />
      <Route path="VendorList" element={<VendorList />} />
      <Route path="ProductGroups" element={<ProductGroups />} />
      <Route path="ProductGroupFilters" element={<ProductGroupFilters />} />
      <Route path="VendorProfile" element={<VendorProfile />} />
      <Route path="VendorContact" element={<VendorContact />} />
      <Route path='CreateProduct' element={<CreateProduct />} />
      <Route path='ViewProducts' element={<ViewProduct />} />
      <Route path='ViewProductGroups' element={<ViewProductGroups/>}/>
      <Route path='UpdateProduct' element={<UpdateProduct />} />
      <Route path='CreateOffer' element={<CreateOffer />} />
      <Route path='ViewOffers' element={<ViewOffer />} />
      <Route path='ViewConsumers' element={<ViewConsumers />} />
      <Route path='createProductGroup' element={<CreateProductGroup />} />
      <Route path='UpdatedProductGroups' element={<UpdateProductGroups/>} />
     <Route path='viewOfferProducts' element={<ViewOfferProducts/>}/>
     <Route path='productAttributes' element={<ProductAttribute/>}/>
     <Route path='UserQuotations' element={<Quotations/>} />
     <Route path='AdminViewQuotations' element={<AdminViewQuotations/>} />
     <Route path = 'customerOrders' element= {<ConsumerOrders/>}/>
     <Route path = 'customerOrderDetails' element= {<ConsumerOrderDetails/>}/>
     <Route path='AdminViewQuotations2' element={<AdminViewQuotations2/>} />
     <Route path='ViewAllOrders' element={<ViewAllOrders/>} />
     <Route path = 'customerOrderDetails2' element= {<ConsumerOrderDetails2/>}/>
     <Route path = 'CreateCustomerQuotation' element= {<CreateCustomerQuotation/>} />
     <Route path='amendQuotation' element= {<AmendQuotation/>}/>
     <Route path='amendQuotation2' element= {<AmendQuotation2/>}/>
     <Route path='viewAllQuotations' element= {<ViewAllCustomerQuotations/>}/>
    </>
        )}
      </Route>
    </Routes>
  );
}

export default App;
