import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faHome,  faProjectDiagram,  faUser,  faSignOutAlt,faBell,faUserPlus,faAngleLeft,faUserCircle } from '@fortawesome/free-solid-svg-icons';

function BlocksContainer() {

    const [blocksData, setBlocksData] = useState([]);
  const [blockNo, setBlockNo] = useState('');
  const [numFloors, setNumFloors] = useState('');
  const [numFlatsPerFloor, setNumFlatsPerFloor] = useState('');
  const [numFlats, setNumFlats] = useState('');
  const Navigate = useNavigate();

  const [editModeIndex, setEditModeIndex] = useState(null);
  const [saveAlert, setsaveAlert] = useState(false);
  const [NextsaveAlert, setNextsaveAlert] = useState(false);
  const [NextAlert, setNextAlert] = useState(false);

  const location = useLocation();
 // const { projectId } = location.state.projectId;
  //const (selectedValue) = location.state.numBlocks;
  const [projectId,setProjectId] = useState(location.state.projectId);
  console.log(location.state.numBlocks)
  const [numberOfBlocks,setNumberOfBlocks] = useState(location.state.numBlocks);
console.log(location.state.projectId);
  const [selectedValue, setSelectedValue] = useState(location.state.numBlocks);

  const [userProfileImage, setuserProfileImage] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [blockIdToDelete, setBlockIdToDelete] = useState();

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };
  
  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      //console.log('profile matched')
      }else{
        //console.log('logout')
       Navigate('/measurit')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      Navigate('/measurit')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })

    const handleEditClick = (index) => {
        // Set edit mode for the selected index
        setEditModeIndex(index);
      };
    
      const handleSaveBlock = async (blockIndex) => {
        const blockData = blocksData[blockIndex];
        const completeBlockData = {
          ...blockData,
          projectId: projectId,
        };
        //console.log(completeBlockData);
        try {
          const token = sessionStorage.getItem('token');
          const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/blocks/create-block`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-token':token
            },
            body: JSON.stringify(completeBlockData),
          });
    
          if (response.ok) {
            const data = await response.json();
            //console.log(data);
            fetchBlocksData();
            setEditModeIndex(null);
            setsaveAlert(true); // Reset edit mode after saving
          } else {
            //console.error('Failed to post data to the backend');
          }
        } catch (error) {
          clearStorageAndRedirectToHome()
          //console.error('Error during API call:', error);
        }
      };
    
      const handleNextClick = () => {
        if(!numberOfBlocks || !numFlats || !numFlatsPerFloor || !numFloors) {
          //alert('please fill' );
          setNextAlert(true);
          return;
        }
      
        // Check if any block is in edit mode
        const isAnyBlockInEditMode = editModeIndex !== null;
      
        if (isAnyBlockInEditMode) {
          // Show alert because some blocks are in edit mode
          setNextsaveAlert(true);
        } else {
          // Continue with the navigation logic
          Navigate('/measurit/flats', { state: { blocksData } });
        }
      };
      
    
      const handleBack = () => {
        // Define the action when the back button is clicked
        // For example, you can Navigate to the previous page
        Navigate(-1); // This Navigates back one step in the history
    };
    
    const fetchBlocksData = async () => {
      console.log(projectId);
      try {
    
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/blocks/get-all-blocks-of-project?projectId=${projectId}`, {
          method: 'GET',
          headers: {
            'x-token': token,
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          console.log(data)
          setNumberOfBlocks(data.length);
          console.log(numberOfBlocks)

          setBlocksData(data);
        } else {
          //console.error('Failed to fetch block data from the backend:', response.status, response.statusText);
          // Handle errors or display an error message to the user
        }
      } catch (error) {
        clearStorageAndRedirectToHome()
        //console.error('Error during API call:', error);
        // Handle errors or display an error message to the user
      }
    };
    
    
    function clearStorageAndRedirectToHome() {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/measurit';
    }

    const url = process.env.REACT_APP_FORE_APILINK + `/projects/update-project?projectId=${projectId}`;

    const handleSubmit = async () => {
      try {
          const token = sessionStorage.getItem('token');
          const formData = new FormData();
          formData.append('numBlocks', selectedValue);

  
          // Make an API call to the backend to update the project
          const response = await fetch(url, {
              method: 'PUT',
              headers: {
                  'x-token': token
              },
              body: formData
          });
  
          console.log(selectedValue)
          if (response.ok) {
              const projectDetails = await response.json();
              console.log(projectDetails)
              setNumberOfBlocks(projectDetails.numBlocks)
              setSelectedValue(selectedValue);
/*               if (projectDetails.numBlocks) {
                fetchBlocksData();
              } */
          } else {
              // Handle errors if the API call fails
              // You can display an error message to the user if needed
          }
      } catch (error) {
          clearStorageAndRedirectToHome()
          // Handle errors if the API call encounters an exception
          // You can display an error message to the user if needed
      }
  };

      useEffect(() => {
        fetchBlocksData();
      }, [projectId,numberOfBlocks]);
    
      useEffect(() => {
        if (blocksData.length > 0) {
          const firstBlockData = blocksData[0];
          setBlockNo(firstBlockData.blockNo || '');
          setNumFloors(firstBlockData.numFloors || '');
          setNumFlatsPerFloor(firstBlockData.numFlatsPerFloor || '');
          setNumFlats(firstBlockData.numFlats || ''); // Set the initial value for numFlats
        }
        setSelectedValue(blocksData.length);
      }, [blocksData]);
    
     /*  const numberOfBlocks = new URLSearchParams(location.search).get('numberOfBlocks'); */
    
      const updateBlockNo = (e, blockIndex) => {
        const newValue = e.target.value;
        setBlocksData((prevBlocksData) => {
          const updatedBlocksData = [...prevBlocksData];
          updatedBlocksData[blockIndex] = {
            ...updatedBlocksData[blockIndex],
            blockNo: newValue,
          };
          return updatedBlocksData;
        });
      };
    
      const updateNumFloors = (e, blockIndex) => {
        const newValue = e.target.value;
        setBlocksData((prevBlocksData) => {
          const updatedBlocksData = [...prevBlocksData];
          updatedBlocksData[blockIndex] = {
            ...updatedBlocksData[blockIndex],
            numFloors: newValue,
            numFlats: newValue * updatedBlocksData[blockIndex].numFlatsPerFloor,
          };
          return updatedBlocksData;
        });
      };
    
      const updateNumFlatsPerFloor = (e, blockIndex) => {
        const newValue = e.target.value;
        setBlocksData((prevBlocksData) => {
          const updatedBlocksData = [...prevBlocksData];
          const currentBlockData = updatedBlocksData[blockIndex];
          const numFloors = currentBlockData.numFloors;
          updatedBlocksData[blockIndex] = {
            ...currentBlockData,
            numFlatsPerFloor: newValue,
            numFlats: numFloors * newValue,
          };
          return updatedBlocksData;
        });
      };
      
      
    
      const updateNumFlats = (e, blockIndex) => {
        const newValue = e.target.value;
        setBlocksData((prevBlocksData) => {
          const updatedBlocksData = [...prevBlocksData];
          updatedBlocksData[blockIndex] = {
            ...updatedBlocksData[blockIndex],
            numFlats: newValue,
          };
          return updatedBlocksData;
        });
      };

      const handleDeleteConfirmation = async (blockId) => {
        // Display confirmation modal before deletion
        setBlockIdToDelete(blockId);
        setShowConfirmationModal(true);
      };



      const handleDeleteBlock = async (blockIdToDelete) => {
      console.log(blockIdToDelete);
        try {
            const token = sessionStorage.getItem('token');
  
    
            // Make an API call to the backend to update the project
            const response = await fetch(process.env.REACT_APP_FORE_APILINK + `/blocks/delete-block?projectId=${projectId}&&blockId=${blockIdToDelete}`, {
                method: 'DELETE',
                headers: {
                    'x-token': token
                },
            });
    
          
            if (response.ok) {
              setShowConfirmationModal(false);
              fetchBlocksData();
              setSelectedValue('');
            } else {
                // Handle errors if the API call fails
                // You can display an error message to the user if needed
            }
        } catch (error) {
            clearStorageAndRedirectToHome()
            // Handle errors if the API call encounters an exception
            // You can display an error message to the user if needed
        }
    };

    const formContainers = [];
    for (let i = 0; i < numberOfBlocks; i++) {
      {console.log(numberOfBlocks)}
      const currentBlockData = blocksData[i];
  
      formContainers.push(
        <div key={i} className="flex flex-row border bg-white self-stretch mt-5 pl-7 pr-12 pt-4 pb-4 rounded-md shadow-sm border-solid border-black border-opacity-20 max-md:max-w-full ml-6 mr-6 max-md:px-5 ">
          <div className="flex-grow flex flex-col items-stretch  sm:mb-0 sm:w-full md:w-1/4">
            <div className="text-black text-opacity-80 text-base whitespace-nowrap">
              Block
            </div>
            <input
              className={`w-2/3 border focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 bg-opacity-30 self-stretch flex-shrink-0 h-[40px] flex-col  pl-2 pr-2 rounded-sm border-solid border-black border-opacity-20 ${editModeIndex !== i?'bg-gray-200  ':'bg-white'}`}
              type="text"
              placeholder=""
              value={currentBlockData?.blockNo || ''}
              onChange={(e) => updateBlockNo(e, i)}
              readOnly={editModeIndex !==i }
            />
          </div>
  
          <div className="flex-grow flex flex-col items-stretch  sm:mb-0 sm:w-full md:w-1/4">
            <div className="text-black text-opacity-80 text-base whitespace-nowrap">
              No. of floors
            </div>
            <input
              className={`w-2/3 border focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 bg-opacity-30 self-stretch flex-shrink-0 h-[40px] flex-col  pl-2 pr-2 rounded-sm border-solid border-black border-opacity-20 ${editModeIndex !== i?'bg-gray-200  ':'bg-white'}`}
              type="text"
              placeholder=""
              value={currentBlockData?.numFloors || ''}
              onChange={(e) => updateNumFloors(e, i)}
              readOnly={editModeIndex !== i}
            />
          </div>
  
          <div className="flex-grow flex flex-col items-stretch  sm:mb-0 sm:w-full md:w-1/4">
            <div className="text-black text-opacity-80 text-base whitespace-nowrap">
              Flats per floor
            </div>
            <input
              className={`w-2/3 border focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 bg-opacity-30 self-stretch flex-shrink-0 h-[40px] flex-col  pl-2 pr-2 rounded-sm border-solid border-black border-opacity-20 ${editModeIndex !== i?'bg-gray-200  ':'bg-white'}`}
              type="text"
              placeholder=""
              value={currentBlockData?.numFlatsPerFloor || ''}
              onChange={(e) => updateNumFlatsPerFloor(e, i)}
              readOnly={editModeIndex !== i}
            />
          </div>
  
          <div className="flex-grow flex flex-col items-stretch  sm:mb-0 sm:w-full md:w-1/4">
            <div className="text-black text-opacity-80 text-base whitespace-nowrap">
              No. of flats
            </div>
            <input
    className={`w-2/3 border focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-30 bg-opacity-30 self-stretch flex-shrink-0 h-[40px] flex-col  pl-2 pr-2 rounded-sm border-solid border-black border-opacity-20 ${editModeIndex !== i?'bg-gray-200  ':'bg-white'}`}
    type="text"
    placeholder=""
    value={currentBlockData?.numFlats || ''}
    onChange={(e) => updateNumFlats(e, i)}
    readOnly={editModeIndex !== i}
  />
  
          </div>
          <div className='flex flex-row '>

            {editModeIndex === i ? (
              <div className="flex items-center p-2 mt-3 "> 
              <button
                className="text-opacity-100 text-bold text-md whitespace-nowrap text-[#0f9d58] hover:underline justify-center items-center  max-md:px-5 transition-colors duration-300"
                type="button"
                onClick={() => handleSaveBlock(i)}
              >
                Save
              </button>
              </div>
            ) : (
              <div className="flex items-center p-2 mt-3 "> 
              <button
                className="text-opacity-100 text-bold text-md whitespace-nowrap text-blue-500 hover:underline justify-center items-center    max-md:px-5 transition-colors duration-300"
                type="button"
                onClick={() => handleEditClick(i)}
              >
                Edit
              </button>
            </div>
            )}
          <div className="flex items-center p-2 mt-3"> 
              <button
                className="text-opacity-100 text-bold text-md whitespace-nowrap text-[#FF0000] hover:underline justify-center items-center  max-md:px-5 transition-colors duration-300"
                type="button"
                onClick={() => handleDeleteConfirmation(currentBlockData._id)}
              >
                Delete
              </button>
              </div>
          </div>
        </div>
        
      );
    }
return ( 
 <div className="overflow-x-scroll no-scrollbar">




 {/* Popup */}
 {saveAlert && (
    <div className="fixed inset-0 flex items-center justify-center">
    {/* Background overlay (blurred) */}
    <div className="absolute inset-0 bg-black opacity-30 "></div>

    {/* Popup content */}
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-bold mb-2">Saved Successful!</p>
      <button
        className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
        onClick={() => setsaveAlert(false)}
      >
        Ok
      </button>
    </div>
  </div>

  )}

{/* Popup */}
{NextsaveAlert && (
    <div className="fixed inset-0 flex items-center justify-center">
    {/* Background overlay (blurred) */}
    <div className="absolute inset-0 bg-black opacity-30 "></div>

    {/* Popup content */}
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-bold mb-2">Please save before clicking next!</p>
      <button
        className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
        onClick={() => setNextsaveAlert(false)}
      >
        Ok
      </button>
    </div>
  </div>

  )}

{NextAlert && (
    <div className="fixed inset-0 flex items-center justify-center">
    {/* Background overlay (blurred) */}
    <div className="absolute inset-0 bg-black opacity-30 "></div>

    {/* Popup content */}
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-bold mb-2">Please fill all required fields!</p>
      <button
        className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
        onClick={() => setNextAlert(false)}
      >
        Ok
      </button>
    </div>
  </div>

  )}

{showConfirmationModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white p-8 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">Are you sure you want to delete this block?</p>
            <div className="flex justify-between">
              <button className="px-4 py-2 bg-red-500 text-white rounded-md mr-4" onClick={() => handleDeleteBlock(blockIdToDelete)}>Delete</button>
              <button className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md" onClick={() => setShowConfirmationModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    
    <div className="flex flex-col items-stretch">

      {/* Back Button */}
      <div className="flex justify-start items-center mr-2 ml-2 mb-2 mt-8">
<button onClick={handleBack} className="relative flex items-center text-md text-black rounded-md px-5 py-1 mt-3">
<FontAwesomeIcon icon={faAngleLeft} className="text-lg cursor-pointer text-black mr-2" /> Back
</button>
</div>

<div>
      <div className="flex flex-row justify-between md:flex-row border border-gray-300 self-stretch m-8  p-2">
        <div className="flex justify-center text-black text-opacity-80 text-base">
          <p className="flex justify-center mt-3">Number Of Blocks</p>
        </div>
        <div className="flex items-center justify-center inline-block text-left">
          <select
            className="border border-solid border-black rounded-sm"
            value={selectedValue}
            onChange={handleSelectChange}
          >
            <option value="" className="focus:text bg-transparent">
              Select
            </option>
            {[...Array(10)].map((_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-center items-center p-2 ">
      <button
        className="text-white text-base font-medium self-stretch max-md:max-w-full px-6 py-1 rounded-md bg-[#0f9d58] hover:bg-green-700 transition-colors duration-300"
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
      </div>
    </div>
    
      {formContainers}
      <div className="flex flex-row space-x-5 justify-center mt-8">

<button
  className="text-white text-opacity-100 text-base whitespace-nowrap bg-[#0f9d58] hover:bg-green-600 justify-center items-center px-6 py-1 rounded-md self-center max-md:px-5 transition-colors duration-300"
  type="button"
  onClick={handleNextClick}
>
  Next
</button>
</div>
      
    </div>
</div>
)
}
export default BlocksContainer;