import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

const Quotations = () => {
  const location=useLocation()
  const navigate = useNavigate();
  const [projects, setProjects] = useState([ ]);
  const consumer_id= location.state.customer_id||''
  const [quotationloading, setQuotationLoading] = useState(true);

  useEffect(() => {
  

    const getQuotations = async () => {
  
     // Assuming _id is the customer id
      const GetQuotations = process.env.REACT_APP_FORE_BRICKBUCKET + `/customer/quotation/get-all-quotations-by-customer?customer_id=${consumer_id}`;
    
      try {
        const token = sessionStorage.getItem('token');

        const response = await fetch(GetQuotations, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'x-token':token
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
  
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Invalid content type received, expected JSON");
        }
  
        const data = await response.json();
        console.log(data.CustomerQuotations);
        setProjects(data.CustomerQuotations); // Assuming data is an array of projects
       
      } catch (error) {
        console.error("Error fetching quotations:", error);
      } finally {
        setQuotationLoading(false);
      }
    };
    
    getQuotations();
  }, []); // Adding setCustomerData to dependency array

   const handleViewQuotation = (
    quotationId, rfqId, customer_id, project_ids, product_category_id, product_category_name, quotation) => {
    

    // Check only for rfqId and customer_id, allow empty project_ids array
    if (!rfqId || !customer_id) {
      console.error("RFQ ID or Customer Id are undefined or empty.");
      return;
    }

    const quotationData = transformData(quotation);
    const QuationIds = {
      customer_id: customer_id,
      product_category_id: product_category_id,
      project_ids: project_ids
    };

    localStorage.setItem('selectedProductGroupOffer', JSON.stringify(quotationData));
    localStorage.setItem('QuotationIds', JSON.stringify(QuationIds));

    const navigateTo = (product_category_name === 'Tiles' || product_category_name === 'UPVC Windows' || product_category_name === 'Doors') ? 
      "/bb/admin/AdminHome/AdminViewQuotations2" : 
      "/bb/admin/AdminHome/AdminViewQuotations";
    navigate(navigateTo, {
      state: {
        selectedQuotationId:quotationId,
        selectedRFQId: rfqId,
        selectedCustomerId: customer_id,
        selectedProjectId: project_ids,
        selectedProductCategoryId: product_category_id,
        selectedProductCategoryName: product_category_name,
      }
    });
  }; 

const handleAmmendQuotation = (
   quotationId, product_category_name, product_category_id,)=>{
    if(product_category_name === 'Tiles' || product_category_name === 'UPVC Windows' || product_category_name === 'Doors'){
      navigate('/bb/admin/AdminHome/amendQuotation2', {state:{ quotationid:quotationId,ProductCategoryName:product_category_name}})
    }else{
      console.log(quotationId)
      navigate('/bb/admin/AdminHome/amendQuotation', {state:{ quotationid:quotationId,ProductCategoryName:product_category_name}})
    }

}

const transformData = (data) => {
  const transformedData = {};

  const transformObject = (obj) => {
      Object.entries(obj).forEach(([key, value]) => {
          if (typeof value === 'object' && value !== null) {
              // If the value is an object (including nested objects), recursively transform it
              transformedData[key] = value.offerid;
              transformObject(value);
          }
      });
  };

  transformObject(data);

  console.log(transformedData);
  return transformedData;
};



const  handleBack=()=>{
  navigate('/bb/admin/AdminHome/ViewConsumers')
}

const handleNavigateToOrderDetails = (quotationIds, productCategoryName) => {
  const navigateToOrderDetails = (productCategoryName === 'Tiles' || productCategoryName === 'UPVC Windows' || productCategoryName === 'Doors') ? 
    '/bb/admin/AdminHome/customerOrderDetails2' : 
    '/bb/admin/AdminHome/customerOrderDetails';
  
  navigate(navigateToOrderDetails, {
    state: {
      quotationid: quotationIds,
      ProductCategoryName: productCategoryName
    }
  });
};

  return (
    <div className="w-full h-full mx-auto">
    <div className="max-w-screen-xl mx-auto p-4">
        <div className="bg-white flex flex-col relative rounded-md p-2 md:ml-10 md:mr-10 mx-2 md:mx-6 overflow-x-auto">
            
            <div className="overflow-x-auto">
            {projects.length === 0 ? (
    <p className="flex justify-center items-center">No quotations available</p>
  ) : (
        <table className="w-full border-b border-gray-300 mt-4">
          <thead className="border-b border-gray-300">
            <tr className="bg-[#D9D9DD] bg-opacity-[100%]">
              <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-4 text-sm text-center">RFQ ID</th>
              <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-4 text-sm text-center">Projects</th>
              <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-4 text-sm text-center">Product</th>
              <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-4 text-sm text-center">Action</th>
              <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-4 text-sm text-center">Order Status</th>
            </tr>
          </thead>
          <tbody>
          {quotationloading ? (
       
          
       <div className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
       {/* Loader component */}
       <div className="animate-spin rounded-full h-18 w-18 border-t-2 border-b-2 border-gray-900">
       <img src={process.env.PUBLIC_URL + '/bb.png'} alt="Loading..." className="h-14 w-14" />
       </div>
     </div>
          ) : (
            projects.map((rfq, rfqIndex) => (
              <tr className="border-b" key={rfq.quotationData._id}>
                <td className=" text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center">{rfq.quotationData.RFQ_id}</td>
                <td className=" text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center whitespace-normal break-all">
  {rfq.projects && rfq.projects.length > 0 ? (
    rfq.projects.map((project, projIndex) => (
      <span key={project._id}>
        {project.projectName}
        {projIndex !== rfq.projects.length - 1 && ", "}
      </span>
    ))
  ) : (
    <span>----------------------</span>
  )}
</td>
                <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center">{rfq.ProductCategory.product_category_name}</td>
                { rfq.quotationData.quotation_in_order && rfq.quotationData.quotation_in_order=== 'No' ? (
                <td className=" text-blue-950 flex flex-row space-x-2 justify-center  items-center  text-opacity-80 py-2 text-xs px-4 text-center">
                <button
                     onClick={() => handleViewQuotation(
                      rfq.quotationData._id,
                      rfq.quotationData.RFQ_id,
                      rfq.quotationData.customer_id,
                      rfq.quotationData.project_ids,
                      rfq.quotationData.product_category_id,
                      rfq.ProductCategory.product_category_name,
                      rfq.quotationData.quotationData
                    )} 
                    className="text-red-400 hover:underline cursor-pointer"
                  >
                    View 
                  </button>
                  <button
                     onClick={() => handleAmmendQuotation(
                      rfq.quotationData._id,                 
                      rfq.ProductCategory.product_category_name,
                      rfq.quotationData.product_category_id,
                    )} 
                    className="text-red-400 hover:underline cursor-pointer"
                  >
                    Amend 
                  </button>

{/* <button
                    onClick={() => handleDeleteQuotation(rfq.quotationData._id)}
                    className="underline text-red-300 text-sm px-2 py-1 hover:text-red-600">
                    Delete
                  </button> */}

               </td>
                ):(
               <td className=" text-blue-950 flex flex-row space-x-2 justify-center  items-center  text-opacity-80 py-2 text-xs px-4 text-center">
                 <button
                    onClick={() => handleNavigateToOrderDetails(
                        [rfq.quotationData._id],
                        rfq.ProductCategory.product_category_name
                      )} 
                    className="text-red-400 hover:underline cursor-pointer"
                  >
                    View Order
                  </button>
               </td>
                )}

                <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center ">
                  { rfq.quotationData.quotation_in_order === 'No'
                ? 'Order not Placed'
                : rfq.quotationData.quotation_in_order === 'Yes'
                ? 'Order Placed'
                : ''}</td>
              </tr>
            ))
          )}
          </tbody>
        </table>
  )}
      </div>
         </div>
    </div>
    <div className='flex justify-center mb-4 mt-6'>
                <button className='rounded-sm text-black hover:text-white border border-[#EB001B] px-4 flex justify-center hover:bg-[#EB001B]' onClick={handleBack}>Back</button>
                </div>
    </div>
  );
  
};

export default Quotations;
