/* Electrical quotation */

import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaChevronLeft, FaChevronRight, FaHeart, FaMinusCircle, FaRegHeart } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

const WireColorPopup = ({ onClose, onSubmit,BreakUps }) => {
  const [colors, setColors] = useState({
    Red: '',
    Blue: '',
    Green: '',
    Yellow: '',
    Black: ''
  });

  useEffect(() => {
    console.log('BreakUps updated:', BreakUps);
    setColors(BreakUps);
  }, [BreakUps]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!isNaN(value)) {
      console.log(`Updating color ${name} to ${value}`);
      setColors((prevColors) => ({
        ...prevColors,
        [name]: value
      }));
    }
    //setIsQuotationSaved(false);
  };
  const handleSubmit = () => {
    onSubmit(colors);
    onClose();
  };

  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white p-6 rounded-md shadow-md max-w-md w-full sm:max-w-sm">
      <h3 className="text-lg font-semibold mb-4">Enter Wire Colors</h3>
      <div className="grid grid-cols-2 gap-4">
        {Object.keys(colors).map((color) => (
          <div key={color} className="flex flex-col">
            <label className="font-medium">{color}:</label>
            <input
              type="number"
              name={color}
              value={colors[color]}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded"
            />
          </div>
        ))}
      </div>
      <div className="mt-4 flex justify-end space-x-2">
        <button
          onClick={handleSubmit}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
        <button
          onClick={onClose}
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
        >
          Close
        </button>
      </div>
    </div>
  </div>
  );
};


const Summarypage2 = () => {
    const navigate= useNavigate();
    const location = useLocation();
    const { selectedQuotationId, selectedProductCategoryId, selectedProductCategoryName, selectedCustomerId, selectedRFQId, offer} = location.state;
    const [customer, setCustomerData] = useState();
    const [OffersData,setOffersData] = useState([])
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
    const [counts, setCounts] = useState([]);
    const [ProductCategory,setProductCategory]= useState()
    const [CustomerQuotationData,setCustomerQuotationData]=useState([])
    const [productAdded, setProductAdded] = useState([])
    const [showWirePopup, setShowWirePopup] = useState(false);
    const [ElecticalWire,setElecticalWire]= useState(null);
    const [QuantityBreakUp,setQuantityBreakUp]= useState([])
    const [OfferForElectrical,setOfferForElectrical]=useState();
    const [showPopup, setShowPopup] = useState(false);
    const [productIdToDelete, setProductIdToDelete] = useState(null);
    const [breakUp,setBreakUp]=useState({
      Red: '',
      Blue: '',
      Green: '',
      Yellow: '',
      Black: ''
    })
    const [isQuotationSaved, setIsQuotationSaved] = useState();

/*     useEffect(() => {
      setIsQuotationSaved(true);
    }, []); */
  
     useEffect(() => {
      if(OffersData.length > 0){ 
        const LocalStorageQuotationData = JSON.parse(localStorage.getItem('quotations'));
        const requiredQuotationData = LocalStorageQuotationData ? LocalStorageQuotationData.find(p => p._id=== selectedQuotationId):null ;
      const remainingQuotations= LocalStorageQuotationData ? LocalStorageQuotationData.filter(p=>p._id !== selectedQuotationId) : []
      if(requiredQuotationData){
        console.log('enterd')
        const storageProductsData= requiredQuotationData.quotationData ? requiredQuotationData.quotationData : []
        handleSetProductAdded(storageProductsData)
        setQuantityBreakUp(requiredQuotationData.quantity_break_up)
       setIsQuotationSaved(requiredQuotationData.status ? requiredQuotationData.status : false)
      }else{
        fetchQuotationData()
      }
    }
    }, [OffersData]); 

    const fetchQuotationData = async () => {
      const token = sessionStorage.getItem('token');

      const GetQuotation = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/get-customer-quotation-by-quotatioId?quotation_id=${selectedQuotationId}`;
    
      try {
        const response = await fetch(GetQuotation, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json', // Add necessary headers if needed
            // Add other headers if required, e.g., authorization
            'x-token':token
          },
        });
    
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
    
        const data = await response.json();
        data.QuotationData.status= true
        setIsQuotationSaved(true)
        const LocalStorageQuotationData = JSON.parse(localStorage.getItem('quotations'));
       // const requiredQuotationData = LocalStorageQuotationData ? LocalStorageQuotationData.find(p => p._id=== selectedQuotationId):null ;
      const remainingQuotations= LocalStorageQuotationData ? LocalStorageQuotationData.filter(p=>p._id !== selectedQuotationId) : []
      
        handleSetProductAdded(data.QuotationData.quotationData)

        const allQuotations = [...remainingQuotations, data.QuotationData]
        console.log(allQuotations)
        localStorage.setItem('quotations', JSON.stringify(allQuotations))
        setQuantityBreakUp(data.QuotationData.quantity_break_up)
 
        setCustomerQuotationData(data.QuotationData);
    

        /* const newProductsAdded = [];
        data.QuotationData.quotationData.forEach(({ product_id, count, offer_id }) => {
          const offer = OffersData.find(p => p.OfferData._id === offer_id);
          if (offer) {
            const product = offer.OfferProducts.find(p => p._id === product_id);
            if (product) {
              const productAdded = {
                offer_id: offer_id,
                count: count,
                product: product,
              };
              newProductsAdded.push(productAdded);
            }
          }
        });
        setProductAdded(newProductsAdded); */
        // return data; // Return the data if needed
      } catch (error) {
        console.error('Failed to fetch quotation data:', error);
       // clearStorageAndRedirectToHome()
      }
    };

   const  handleSetProductAdded = (QuotationProductsData)=>{
    //console.log(QuotationProductsData)
    const newCounts = {};
    QuotationProductsData.forEach(({ product_id, count }) => {
      newCounts[product_id] = count;
    });
    setCounts(newCounts);

    const newProductsAdded = [];
    QuotationProductsData.forEach(({ product_id, count, offer_id }) => {
          const offer = OffersData.find(p => p.OfferData._id === offer_id);
          if (offer) {
            const product = offer.OfferProducts.find(p => p._id === product_id);
            if (product) {
              const productAdded = {
                offer_id: offer_id,
                count: count,
                product: product,
              };
              newProductsAdded.push(productAdded);
            }
          }
        });
        setProductAdded(newProductsAdded);
   }
    
    


    useEffect(()=>{
      const userData = JSON.parse(localStorage.getItem("userData"));
      setCustomerData(userData);
    },[])

  

    useEffect(() => {
        const fetchProductData = async () => {
            try {
              const token = sessionStorage.getItem('token');

                const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/get-ofers-and-offerProducts-by-productCategoryId?product_category_id=${selectedProductCategoryId}`,{
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                  },
                  });
                if (!response.ok) {
                    throw new Error(`Error fetching data: ${response.statusText}`);
                }
                const data = await response.json();
            
                setProductCategory(data.productCategoryData)
                // setProductData(data);
                if(offer){
                  const remainingOffers = data.AllOffers.filter(p => p.OfferData._id !== offer._id) || [];
                  const selectedOffer = data.AllOffers.find(p => p.OfferData._id === offer._id);
                  const allOffers = selectedOffer ? [selectedOffer, ...remainingOffers] : remainingOffers;
                  
                    setOffersData(allOffers)
                }else{
                  setOffersData(data.AllOffers)
                }
                
            } catch (error) {
               // setError(error.message);
               clearStorageAndRedirectToHome()
            } finally {
               // setLoading(false);
            }
        };

        fetchProductData();
        //fetchQuotations();
    }, [selectedProductCategoryId, selectedRFQId, selectedCustomerId]);

    const calculatePrice = (product, offer) => {
       
        if (!product || !offer || !offer.OfferData) {
          return 'Price Not Available';}
        const basePrice = product.product_price || 0;
        const discount = parseFloat(offer.OfferData.discount) || 0;
        const gst = parseFloat(offer.OfferData.GST_rate_id) || 0;
      
        let netPrice = parseFloat(basePrice);
      
        // Apply discount
        netPrice -= (netPrice * (discount / 100));
      
        // Apply GST
        netPrice *= (1 + (gst / 100));
        return `${netPrice.toFixed(2)}`;
      };

     
  const handleChange = (productId, e) => {
    const newValue = parseInt(e.target.value) || 0;
    setCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: newValue,
    }));
    setIsQuotationSaved(false);
  };

  const handleIncrement = (productId) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: (prevCounts[productId] || 0) + 1, // If count doesn't exist yet, initialize it with 0
    }));

  };

  const handleIncrementInAddedProducts = (productId) => {
    handleIncrement(productId);
  };


      const handleDecrement = (productId) => {
        setCounts((prevCounts) => ({
          ...prevCounts,
          [productId]: Math.max((prevCounts[productId] || 0) - 1, 0), // If count doesn't exist yet, initialize it with 0
        }));
      };


      const handleWirePopupSubmit = (colors) => {
        let totalQuantity = 0
        for (const key in colors) {
            const quantity=  colors[key] || '0'
            totalQuantity +=parseInt(quantity)
        }
        counts[ElecticalWire._id]=totalQuantity    
         const user = JSON.parse(localStorage.getItem('userData'));
         
         if (!user) {
           console.error("User data not found in localStorage.");
           return;
         }
       
         const quantityBreakUp = {
           customer_id: user._id,
           product_category_id: ElecticalWire.product_category_id,
           product_id: ElecticalWire._id,
           break_ups: colors
         };
       
         let AllQuantityBreakup = [...QuantityBreakUp];
         
         const findQuantityBreakupIndex = AllQuantityBreakup.findIndex(p => 
           p.user_id === QuantityBreakUp.user_id && p.product_id === ElecticalWire._id
         );
    
         if (findQuantityBreakupIndex !== -1) {
           AllQuantityBreakup[findQuantityBreakupIndex] = quantityBreakUp;
           HandleAddItems(ElecticalWire,OfferForElectrical,AllQuantityBreakup)
           handleAddBreakUpsToLocalStorage(AllQuantityBreakup)
         } else {

           AllQuantityBreakup = [...AllQuantityBreakup, quantityBreakUp];
           HandleAddItems(ElecticalWire,OfferForElectrical,AllQuantityBreakup)
           handleAddBreakUpsToLocalStorage(AllQuantityBreakup)
         }
       
         setQuantityBreakUp(AllQuantityBreakup)
         setIsQuotationSaved(false);
       };

       const handleAddBreakUpsToLocalStorage = (AllQuantityBreakup) =>{
        const LocalStorageQuotationData = JSON.parse(localStorage.getItem('quotations')) || [];
        
        // Find the quotation data for the selected quotation and customer
        const requiredQuotationData = LocalStorageQuotationData.find(
            p => p._id === selectedQuotationId && p.customer_id === customer._id
        ) || {};
        
        // Filter out other quotation data
        const OtherQuotationData = LocalStorageQuotationData.filter(
            p => p._id !== selectedQuotationId
        ) || [];

        requiredQuotationData.quantity_break_up = AllQuantityBreakup
    
            // Combine updated quotation with other quotations
            const allQuotationData = [...OtherQuotationData, requiredQuotationData];
            localStorage.setItem('quotations', JSON.stringify(allQuotationData));
    
       }


       const HandleAddItems=(product, offer, BreakUps)=>{
        const count = counts[product._id] || 0;
        
        const offer_id = offer.OfferData._id;
    
        if (count > 0) {
            const productCategoryID = product.product_category_id; // Extract product category ID
            const existingProductIndex = productAdded.findIndex((item) => item.product._id === product._id);
    
            if (existingProductIndex !== -1) {
                // Product already exists, update the count
                const updatedProductAdded = [...productAdded];
                updatedProductAdded[existingProductIndex].count = count;
                setProductAdded(updatedProductAdded);
                handleUpdateLocalStorage(product, offer )
                //saveAddedAndDeletedQuotation(updatedProductAdded ,BreakUps)
            } else {
                // Product doesn't exist, add it to the list
                const updatedProduct = { product, count, productCategoryID, offer_id }; // Include product category ID
                const updatedProductAdded = [...productAdded];
                updatedProductAdded.push(updatedProduct); // Add the new product to the end of the array
                setProductAdded(updatedProductAdded);
                handleUpdateLocalStorage(product, offer  )
               // saveAddedAndDeletedQuotation(updatedProductAdded, BreakUps);
            }
        }
      }


      const handleUpdateLocalStorage = (product, offer) => {
        const count = counts[product._id] || 0;
        const offer_id = offer.OfferData._id;
        const LocalStorageQuotationData = JSON.parse(localStorage.getItem('quotations')) || [];
        
        // Find the quotation data for the selected quotation and customer
        const requiredQuotationData = LocalStorageQuotationData.find(
            p => p._id === selectedQuotationId && p.customer_id === customer._id
        ) || {};
        
        // Filter out other quotation data
        const OtherQuotationData = LocalStorageQuotationData.filter(
            p => p._id !== selectedQuotationId
        ) || [];
    
        // Get the products added in the required quotation data
        const storageProductsData = requiredQuotationData.quotationData|| [];
        
        // Find the index of the existing product
        const existingProductIndex = storageProductsData.findIndex((item) => item.product_id === product._id);
    
        if (existingProductIndex !== -1) {
            // Product already exists, update the count
            const updatedProductAdded = [...storageProductsData];
            updatedProductAdded[existingProductIndex].count = count;
    
            // Create a new quotation object with updated product data
            /* const updatedQuotation = {  
                _id: selectedQuotationId,
                customer_id: customer._id,
                product_category_id: selectedProductCategoryId,
                productsAdded: updatedProductAdded
            }; */
            
            requiredQuotationData.status= false
            requiredQuotationData.quotationData=updatedProductAdded
             setIsQuotationSaved(false)
            // Combine updated quotation with other quotations
            const allQuotationData = [...OtherQuotationData, requiredQuotationData];
            localStorage.setItem('quotations', JSON.stringify(allQuotationData));
        } else {
            // Product does not exist, create a new product entry
            const updatedProduct = {
                product_id: product._id,
                count: count,
                offer_id: offer_id,
                price: calculateOfferPrice(product, offer_id)
            };
            const updatedProductAdded = [...storageProductsData, updatedProduct];
    
            // Create a new quotation object with the new product data
            /* const updatedQuotation = {  
                quotation_id: selectedQuotationId,
                customer_id: customer._id,
                product_category_id: selectedProductCategoryId,
                productsAdded: updatedProductAdded
            }; */
            requiredQuotationData.status= false
            requiredQuotationData.quotationData=updatedProductAdded
            setIsQuotationSaved(false)
    
            // Combine updated quotation with other quotations
            const allQuotationData = [...OtherQuotationData, requiredQuotationData];
            localStorage.setItem('quotations', JSON.stringify(allQuotationData));
        }
    };
    
      const handleAddItem = (product,offer) => {
        if (offer.productSubCategory && offer.productSubCategory.product_sub_category_name === 'Electrical Wires') {
          setOfferForElectrical(offer)
          setElecticalWire(product)
          setShowWirePopup(true);
          const BreakUpData= QuantityBreakUp.find(p=>p.product_id===product._id)
          if(BreakUpData){
            setBreakUp(BreakUpData.break_ups)
          }  
        }else{
          HandleAddItems(product,offer)
        }
        setIsQuotationSaved(false);
      };
    
    
    
    const handleDeleteProduct = (productId) => {
      const index = productAdded.findIndex((product) => product.product._id === productId);
      if (index !== -1) {
        const updatedProductAdded = [...productAdded];
        updatedProductAdded.splice(index, 1);
        setProductAdded(updatedProductAdded);
        const RemainingBreakupQuantity = QuantityBreakUp.filter(p => p.product_id !== productId);
        handleDeleteProductInLocalStorage( productId , RemainingBreakupQuantity)
        //saveAddedAndDeletedQuotation(updatedProductAdded, RemainingBreakupQuantity);
      }
    };

    const handleDeleteProductInLocalStorage = (productId, RemainingBreakupQuantity) => {
      const LocalStorageQuotationData = JSON.parse(localStorage.getItem('quotations')) || [];
  
      // Find the required quotation data
      const requiredQuotationData = LocalStorageQuotationData.find(
          p => p._id === selectedQuotationId && p.customer_id === customer._id
      ) || {};
  
      // Filter out other quotation data
      const OtherQuotationData = LocalStorageQuotationData.filter(
          p => p._id !== selectedQuotationId
      ) || [];
  
      // Ensure requiredQuotationData is properly initialized
      if (!requiredQuotationData.quotationData) {
          requiredQuotationData.quotationData = [];
      }
  
      // Find the product to be deleted
      const productIndex = requiredQuotationData.quotationData.findIndex((product) => product.product_id === productId);
  
      if (productIndex !== -1) {
        console.log('hii')
          // Create a new array without the deleted product
          const updatedProductAdded = [...requiredQuotationData.quotationData];
          updatedProductAdded.splice(productIndex, 1);
  
          // Update the quotation data with the new product list and other changes
          requiredQuotationData.quotationData = updatedProductAdded;
          requiredQuotationData.quantity_break_up = RemainingBreakupQuantity;
          requiredQuotationData.status = false;
  
          setIsQuotationSaved(false);
      }
  
      // Combine updated quotation with other quotations
      const allQuotations = [...OtherQuotationData, requiredQuotationData];
      localStorage.setItem('quotations', JSON.stringify(allQuotations));
  };
  

  /*   const deleteProduct = (productId) => {
    if last product deleting should delete quotation also then ,
    this if condition should be written in the handleDeleteProduct 
    and inside the handleDelete product , the if condition and const index should be palced in this delete product 
      if (productAdded.length === 1) {
        setProductIdToDelete(productId);
        setShowPopup(true);
      } else {
        deleteProduct(productId);
      }
    }; */

    const handleConfirmDelete = async () => {
      try {
        const token = sessionStorage.getItem('token');
    
        const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/delete-customer-quotation-data?quotation_id=${selectedQuotationId}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            'x-token':token
          },
        });
       
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        setShowPopup(false);
        navigate('/bb/user/Myquotations')
      } catch (error) {
        console.error("Error deleting quotation:", error);
        clearStorageAndRedirectToHome()
      }
    };

     
       const ToTaladdedProductsPrice = () => {
  let totalCost = 0;
  productAdded.forEach((item) => {
    const count = item.count;
    const netPrice= calculateTotalOfferPrice(item)
    const totalPrice = netPrice * count;
    totalCost += totalPrice;
  })
  return totalCost.toFixed(2);
}

const calculateTotalOfferPrice=(item)=>{
 
  const SelecetdOffer= OffersData.find(p=>p.OfferData._id===item.offer_id)
  if(SelecetdOffer.OfferData.net_price===0){
    const basePrice = parseFloat(item.product.product_price);
    const gst = parseFloat(SelecetdOffer.OfferData.GST_rate_id) || 0;
    const discount = SelecetdOffer.OfferData.discount || 0;
    const NetPrice= (basePrice*(1-((discount)/100)))*(1+((gst)/100))  
    return NetPrice
  }else{
    return parseFloat(SelecetdOffer.OfferData.net_price)
  }
}

    
       const handleGeneratePDF = async () => {
        try {
          // Check if the product category ID exists
          if (!ProductCategory || !ProductCategory._id) {
            console.error("Product category ID is missing.");
            return;
          }
      
          // Retrieve and filter products from local storage
         
          //const filteredProducts = addedProducts.filter(product => product.product.product_category_id === ProductCategory._id);
      
          if (productAdded.length === 0) {
            console.error("No products found for the current product category.");
            return;
          }
      
          // Create a new jsPDF instance
          const doc = new jsPDF();
      
          const subtotal = productAdded.reduce((acc, product) => {
            const price = calculateOfferPrice(product.product, product.offer_id);
            return acc + (product.count * price);
          }, 0);
      
          // Define table data with headers
          let tableData = [
            ['Product Name', 'Quantity', 'Price'], // Header row
            ...productAdded.map(product => ([
              product.product.product_name,
              product.count,
              `Rs ${calculateOfferPrice(product.product, product.offer_id).toFixed(2)} /-`
            ])),
            ['Subtotal', '', `Rs ${subtotal.toFixed(2)} /-`]
          ];
      
          // Add table to the document
          doc.autoTable({
            head: [tableData.shift()], // Extract and use the first row as the header
            body: tableData,
            startY: 20 // Adjust as needed
          });
      
          // Save the document
          doc.save('Electricals_Quotation.pdf');
      
          // Save data to the server
          await saveQuotation(productAdded);
          setIsQuotationSaved(false);
        } catch (error) {
          console.error("Error generating PDF:", error);
          clearStorageAndRedirectToHome()
        }
      }; 

      const calculateOfferPrice=(product,offerId)=>{
        const SelecetdOffer= OffersData.find(p=>p.OfferData._id===offerId)
        if(SelecetdOffer.OfferData.net_price===0){
          const basePrice = parseFloat(product.product_price);
          const gst = parseFloat(OffersData.find(p=>p.OfferData._id===offerId).OfferData.GST_rate_id )|| 0;
          const discount = OffersData.find(p=>p.OfferData._id===offerId).OfferData.discount || 0;
          const NetPrice= (basePrice*(1-(discount/100)))*(1+(gst/100))
          return NetPrice
        }else{
          return SelecetdOffer.OfferData.net_price
        }
      
      }

      const saveQuotation = async (filteredProducts) => {
        const availOffers= GetAvailOffers(filteredProducts)
      
        try {
          const token = sessionStorage.getItem('token');

          const requestData = {
            customer_id: customer._id,
            product_category_id: ProductCategory._id,
            avail_offers:availOffers,
            quantity_break_up:QuantityBreakUp,
            created_by:"User",
            quotationData: filteredProducts.map(({ product, count ,offer_id}) => ({
              product_id: product._id,
              count,
              price : calculateOfferPrice (product , offer_id),
              offer_id: offer_id,
            }))
          };
          const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/save-customer-quotation-of-no-project-ids`;
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Add other headers if needed
              'x-token':token
            },
            body: JSON.stringify(requestData),
          });
      
          if (!response.ok) {
            throw new Error('Failed to save PDF data to the server');
          }
          handleSetLocalStorageQuotations()
         
        } catch (error) {
          console.error('Error saving PDF data:', error);
          clearStorageAndRedirectToHome()
        }
      };
   
     const  handleSetLocalStorageQuotations = () =>{
      const LocalStorageQuotationData = JSON.parse(localStorage.getItem('quotations')) || [];
 
        // Filter out other quotation data
        const OtherQuotationData = LocalStorageQuotationData.filter(
            p => p._id !== selectedQuotationId
        ) || [];

        localStorage.setItem('quotations', JSON.stringify(OtherQuotationData));
        navigate('/bb/user/myQuotations');
     }


      const GetAvailOffers =(filteredProducts)=>{
        const offerIdsSet = new Set(filteredProducts.map(({ offer_id }) => offer_id));
        const uniqueOfferIds = Array.from(offerIdsSet);
        return  uniqueOfferIds   
      }

      const saveAddedAndDeletedQuotation = async (filteredProducts, BreakUps) => {
       const availOffers= GetAvailOffers(filteredProducts)
        try {
          const token = sessionStorage.getItem('token');

          const requestData = {
            customer_id: customer._id,
            product_category_id: ProductCategory._id,
            avail_offers:availOffers,
            quantity_break_up:BreakUps,
            created_by : "User",
            quotationData: filteredProducts.map(({ product, count ,offer_id}) => ({
              product_id: product._id,
              count,
              price : calculatePrice (product ,OffersData.find(p => p.OfferData._id === offer_id)),
              offer_id: offer_id
            }))
          };
          const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/save-customer-quotation-of-no-project-ids`;
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Add other headers if needed
              'x-token':token
            },
            body: JSON.stringify(requestData),
          });
          if(response.ok){
            navigate('/bb/user/OrderConfirmation', { state: { quotationid: [selectedQuotationId] } });
          }
          else  {
            throw new Error('Failed to save PDF data to the server');
          }
          
         
        } catch (error) {
          console.error('Error saving PDF data:', error);
          clearStorageAndRedirectToHome()
        }
      };
    
   /*  const fetchQuotations = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/get-customer-quotation-data?rfq_id=${selectedRFQId}&customer_id=${selectedCustomerId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch quotations');
            }
            const data = await response.json();
            setQuotationData(data.QuotationData);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
 */

   const  handleGenerateOrder=()=>{
    if(isQuotationSaved){
      saveAddedAndDeletedQuotation(productAdded, QuantityBreakUp)
    }else{
      navigate('/bb/user/OrderConfirmation', { state: { quotationid: [selectedQuotationId] } });
    }
    
    }

    const getFilteredOffersData = () => {
      // Create a set of added product IDs for quick lookup
      const addedProductIds = new Set(productAdded.map((product) => product.product._id));
  
      return OffersData.map((offer) => ({
        ...offer,
        OfferProducts: offer.OfferProducts.filter((product) => !addedProductIds.has(product._id)),
      }));
    };

    const filteredOffersData = getFilteredOffersData();

    const getDisplayUOM = (uom, count) => {
      const countNumber = Number(count);
      // Convert UOM to lowercase and remove special characters for comparison
      let sanitizedUOM = uom.toLowerCase().replace(/[^a-z]/g, '');
    
      if (countNumber === 1) {
          // Singular form special cases handling
          if (sanitizedUOM === 'nos') {
              return "No";
          } else if (sanitizedUOM === 'bags') {
              return "Bag";
          } else if (sanitizedUOM === 'coils') {
              return "Coil";
          } else if (sanitizedUOM === 'packets') {
              return "Packet";
          }
          // Additional logic for other special cases can be added here
          return uom.replace(/'s$/, ''); // Assuming 's is for plural
      } else {
          // For plural form handling
          if (uom.endsWith('s')) {
              return uom;
          }
          return `${uom}s`;
      }
    };

    const  handleBack=()=>{
      navigate(-1)
    }

    function clearStorageAndRedirectToHome() {
      navigate("/bb/ErrorPage");
    }



    return (
      <div className="flex justify-center w-full p-2 flex-row">
      <div className="md:w-2/3 lg:w-2/3 sm:w-full">
      

{/* Quotation div for small screens */}
{productAdded && productAdded.length > 0 && (
<div className="p-2 rounded-md w-full border lg:hidden flex flex-col relative">
        <div className="rounded-md overflow-x-auto">
          <div className="p-1 border-b flex justify-between flex-row">
            <h1 className="text-lg font-semibold">Quotation</h1>
            {OffersData && OffersData.length > 0 && (
  <h1 className='text-lg'><span className='font-semibold'>Total: </span> ₹{Number(ToTaladdedProductsPrice()).toLocaleString('en-IN')}</h1>
)}

          </div>

          
            <div className='flex overflow-x-scroll'>
              {productAdded
                .slice()
                .reverse()
                .map((selectedProduct, index) => (
                  <div key={index}
                  className="flex-shrink-0 w-64 mr-4"
                >
                  <div className="border mt-2 mb-2 p-2 rounded-lg flex flex-col items-center bg-white">
                    <div className="flex w-full justify-end ">
                      <button className="text-red-500 focus:outline-none" onClick={() => handleDeleteProduct(selectedProduct?.product?._id)}>
                        <FaMinusCircle className="text-xs hover:text-red-500" />
                      </button>
                    </div>
                    <div key={index} className="flex items-center justify-between space-x-2 ">
                      <div className="w-16 h-16 overflow-hidden flex items-center justify-center">
                        <img
                          src={ImageUrl + (selectedProduct?.product?.product_image || 'image unavailable')}
                          alt="Product Image"
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <div className=" flex justify-center items-center flex-col flex-grow">
                        <div className="flex justify-start h-12 overflow-hidden text-ellipsis">
                          <p className="text-sm font-semibold text-left whitespace-normal">{selectedProduct?.product?.product_name}</p>
                        </div>
                        <div className="flex flex-row justify-between mt-1">
                          <p className="p-1 text-sm">
                          {/* <span className="font-semibold">Qty:</span> {selectedProduct?.count}</p> */}
                          <span className="font-semibold">Qty:</span> {selectedProduct.count} {getDisplayUOM(selectedProduct.product.UOM, selectedProduct.count)}
                          
                          </p>
                          {(() => {
    const selectedOffer = OffersData?.find(p => p?.OfferData?._id === selectedProduct?.offer_id);
    if (selectedOffer) {
      return (
        <div>
        {selectedOffer.OfferData.net_price === 0 ? (
          <p className="p-1 text-sm">
            <span className="font-semibold">Offer Price: </span> ₹ {calculatePrice(selectedProduct.product, selectedOffer)}</p>
        ) : (
          <p className="p-1 text-sm">
            <span className="font-semibold">Offer Price: </span> ₹ {selectedOffer.OfferData ? selectedOffer.OfferData.net_price : 'Price Not Available'}</p>
        )}
        </div>
       
      );
    } else {
      return (
        <p className="p-1 text-sm">
          Price: ₹{selectedOffer?.OfferData?.net_price ?? 'Price Not Available'}
        </p>
      );
    }
  })()}
                        </div>
                        <div className="grid grid-cols-3 gap-1 mt-1">
 {Object.entries(QuantityBreakUp.find(p => p.product_id === selectedProduct.product._id)?.break_ups || {}).map(([key, value], index) => (
(value!== "" && value!== 0 && value!== undefined && value!== null) && (
<div key={index} className="flex flex-row items-center space-x-1">
<p className='text-xs font-semibold'>{key}=</p>
<p className='text-xs'>{value}</p>
</div>
)
))}
</div>
                      </div>
                      
                    </div>
                   
                  </div>
                  </div>
                ))}
            </div>
          {/*  <div className="flex justify-center px-4 ">
          <button
            className={`absolute top-[110px] -left-0 transform -translate-y-1/2 ${currentIndex === 0 ? 'opacity-30 cursor-not-allowed' : ''}`}
            onClick={prevSlide}
            disabled={currentIndex === 0}
          >
            <FaChevronLeft />
          </button>
          <button
            className={`absolute top-[110px] -right-0 transform -translate-y-1/2 ${currentIndex === productAdded.length - 1 ? 'opacity-30 cursor-not-allowed' : ''}`}
            onClick={nextSlide}
            disabled={currentIndex === productAdded.length - 1}
          >
            <FaChevronRight />
          </button>
        </div>  */}
          <div className="flex justify-end px-4 flex flex-row space-x-2">
          {isQuotationSaved ? (
            <button className="rounded-sm border border-gray-500 px-2 text-gray-500" disabled>
            Saved
            </button>
) : (
  <button className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black" onClick={() => handleGeneratePDF()}>
    Save
  </button>
)}
            <button className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black" onClick={() => handleGenerateOrder()}>
              Place Order
            </button>
          </div>
        </div>
        </div>
    )}

        <p className="text-xl sm:text-md font-semibold flex justify-center items-center p-1 mt-1">{selectedProductCategoryName} Products</p>
        
        {productAdded && productAdded.length > 0 && (
  <div>
    <hr className="border-gray-400 my-1" />
    <div className='md:w-full sm:w-full'>
      <h2 className="text-lg sm:text-[20px] md:text-[24px] text-gray-700 font-semibold py-1 px-2 mt-2">Added Products</h2>
      <div className="flex overflow-x-scroll">
        {productAdded.map((selectedProduct, index) => (
          <div key={selectedProduct.product._id} className="flex-shrink-0 w-64 mr-4">
            <div className="border p-2 rounded-lg flex flex-col items-center">
              <div className="flex w-full justify-end">
                {productAdded.find(p => p.product._id === selectedProduct.product._id) && (
                  <FaCheckCircle className="text-green-500" />
                )}
              </div>
              <div className="w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-32 lg:h-32 overflow-hidden flex items-center justify-center">
                <img
                  src={ImageUrl + (selectedProduct.product.product_image || 'image unavailable')}
                  alt="Product Image"
                  className="max-w-full max-h-full object-contain"
                />
              </div>
              <div>
                <div className="h-12 overflow-hidden text-ellipsis">
                  <h2 className="text-xs sm:text-sm md:text-base font-bold text-center whitespace-normal">
                    {selectedProduct.product.product_name || 'Product Name Not Available'}
                  </h2>
                </div>
                <p className="text-xs sm:text-xs md:text-base font-semibold text-center mt-2">{selectedProduct.product.product_description}</p>
              </div>
              <div className="flex flex-row ">
                <div>
                  <div className="text-xs">
                    {(() => {
                      const selectedOffer = OffersData?.find(p => p?.OfferData?._id === selectedProduct?.offer_id);
                      if (selectedOffer) {
                        return (
                          <div>
                            <p className='whitespace-normal text-center font-semibold'>Offer Price:</p>
                            {selectedOffer.OfferData.net_price === 0? (
                              <p>₹{calculatePrice(selectedProduct.product, selectedOffer)}/{selectedProduct.product.UOM || ""}</p>
                            ) : (
                              <p>₹{selectedOffer.OfferData? selectedOffer.OfferData.net_price : 'Price Not Available'}/{selectedProduct.product.UOM || ""}</p>
                            )}
                          </div>
                        );
                      } else {
                        return (
                          <p className="p-1 text-sm">
                            Price: ₹{selectedOffer?.OfferData?.net_price?? 'Price Not Available'}
                          </p>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
              <div>
                <div className="md:text-sm lg:text-sm sm:text-xs p-2 flex flex-row space-x-2">
                  <div className="flex justify-center">
                    <button className="md:w-5 lg:w-5 sm:w-5 rounded-sm border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 sm:px-0 text-black focus:outline-none"
                      onClick={() => handleDecrement(selectedProduct.product._id)}>-</button>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-sm md:w-10 lg:w-10 sm:w-8 text-center focus:outline-none"
                      value={counts[selectedProduct.product._id] || 0}
                      onChange={(e) => handleChange(selectedProduct.product._id, e)}
                    />
                    <button className="md:w-5 lg:w-5 sm:w-5 rounded-sm border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 sm:px-0 text-black focus:outline-none"
                      onClick={() => handleIncrement(selectedProduct.product._id)}>+</button>
                  </div>
                  <div className="flex justify-center">
                    <button className="md:text-sm lg:text-sm rounded-sm md:w-10 lg:w-10 sm:w-12 sm:text-[14px] border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 text-black"
                      onClick={() => handleAddItem(selectedProduct.product, OffersData.find(p => p.OfferData._id === selectedProduct.offer_id))}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)}

        <div className="flex justify-center  p-2 flex-row">
        <div className="md:w-full sm:w-full">
        <div>
          <hr className="border-gray-400 my-1 mt-2" />
          {filteredOffersData.map((offer, Offerindex) => (
            <div key={Offerindex}>
              {offer.OfferProducts && offer.OfferProducts.length > 0 && (
  <>
              <div className="flex">
                {offer.productSubCategory && (
                  <p className="text-lg sm:text-[20px] md:text-[24px] text-gray-700 font-semibold px-2 mt-5">
                    {offer.productSubCategory.product_sub_category_name}
                  </p>
                )}
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-3 mt-4 w-full overflow-y-auto">
                {offer.OfferProducts && offer.OfferProducts.length > 0 ? (
                  offer.OfferProducts.map((product, productIndex) => (
                    <div key={product._id} className="w-full border p-2 rounded-lg flex flex-col items-center">
                      <div className="w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-40 lg:h-40 overflow-hidden flex items-center justify-center">
                        <img
                          src={ImageUrl + (product.product_image || 'image unavailable')}
                          alt="Product Image"
                          className="max-w-full max-h-full object-contain"
                        />
                      </div>
                      <div>
                        <h2 className="text-xs sm:text-sm md:text-base font-bold text-center whitespace-normal">
                          {product.product_name || 'Product Name Not Available'}
                        </h2>
                        <p className="text-xs sm:text-xs md:text-base font-semibold text-center mt-2">{product.product_description}</p>
                      </div>
                      <div className="flex flex-row">
                        <div>
                        <div className="text-xs">
                            <p className='whitespace-normal text-center font-semibold'>Offer Price:</p>
                            <div className="flex flex-row">
                            {offer.OfferData.net_price === 0 ? (
                              <p className="text-center">₹{calculatePrice(product, offer)}</p>
                            ) : (
                              <p>₹{offer.OfferData ? offer.OfferData.net_price : 'Price Not Available'}</p>
                            )}
                             <p className="text-center">/{product.UOM || ''}</p>
                             </div>
                          </div>
                          {/* <div className="text-xs line-through">
                            {product.product_price === null || product.product_price === "" ? (
                              <p>MRP: ₹{offer.OfferData.base_price !== 0 ? offer.OfferData.base_price : 'Price Not Available'}</p>
                            ) : (
                              <p>MRP: ₹{product.product_price}</p>
                            )}
                          </div> */}
                        </div>
                        </div>
                        <div>
                          <div className="md:text-sm lg:text-sm sm:text-xs p-2 flex flex-row space-x-2">
                            <div className="flex justify-center">
                              <button className="md:w-5 lg:w-5 sm:w-6 text-[18px] rounded-sm border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 sm:px-0 text-black focus:outline-none"
                                onClick={() => handleDecrement(product._id)}>-</button>
                              <input
                                type="text"
                                className="border border-gray-300 rounded-sm md:w-10 lg:w-10 sm:w-8 text-center focus:outline-none"
                                value={counts[product._id] || 0}
                                onChange={(e) => handleChange(product._id, e)}
                              />
                              <button className="md:w-5 lg:w-5 sm:w-6 text-[18px] rounded-sm border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 sm:px-0 text-black focus:outline-none"
                                onClick={() => handleIncrement(product._id)}>+</button>
                            </div>
                            <div className="flex justify-center">
                              <button className="md:text-sm lg:text-sm rounded-sm md:w-10 lg:w-10 sm:w-12 sm:text-[14px] border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 text-black"
                                onClick={() => handleAddItem(product, offer)}>
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      
                      {offer.productSubCategory && offer.productSubCategory.product_sub_category_name && offer.productSubCategory.product_sub_category_name === 'Electrical Wires' && (
      <div className="flex justify-start">
      <div>
      <p className="text-xs">Colours:</p>
      </div>
      <div className="flex flex-row space-x-1">
        <img src="https://via.placeholder.com/64/FF0000/FFFFFF?text=Red" alt="Red" className="w-4 h-4 rounded-lg" />
      <img src="https://via.placeholder.com/64/00FF00/FFFFFF?text=Green" alt="Green" className="w-4 h-4 rounded-lg" />
      <img src="https://via.placeholder.com/64/FFFF00/FFFFFF?text=Yellow" alt="Yellow" className="w-4 h-4 rounded-lg " />
      <img src="https://via.placeholder.com/64/0000FF/FFFFFF?text=Blue" alt="Blue" className="w-4 h-4 rounded-lg"/>
      <img src="https://via.placeholder.com/64/000000/FFFFFF?text=Black" alt="Black" className="w-4 h-4 rounded-lg"/>
      </div>
      
  </div>
      )}
                    </div>
                  ))
                ) : (
                  <div>No products found</div>
                )}
              </div>
              <hr className="border-gray-400 my-1 mt-5" />
              </>
              )}
            </div>
          ))}
        </div>
        <div className='flex justify-center mb-4 mt-6 '>
                <button className='rounded-sm text-black hover:text-white border border-[#EB001B] px-4 flex justify-center hover:bg-[#EB001B] ' onClick={handleBack} >Back</button>
                </div>
     </div>
     </div>
      </div>
 {/* Quotation div for large screens */}
 {productAdded && productAdded.length > 0 && (
        <div className="hidden lg:block p-2 lg:ml-2 rounded-md w-full border md:w-1/4 lg:w-1/4 flex flex-col lg:flex-row">
          <div className="rounded-md overflow-y-scroll h-screen">
            <div className="p-1 border-b flex justify-between flex-row">
              <h1 className="font-semibold">Quotation</h1>
              {OffersData && OffersData.length > 0 && (
                <h1>Total: ₹{ToTaladdedProductsPrice()}</h1>
              )}
            </div>
            <div>
              {productAdded
                .slice()
                .reverse()
                .map((selectedProduct, index) => (
                  <div className="hover:bg-red-100 flex flex-col border-b" key={index}>
                    <div className="flex justify-end mt-1">
                      <button
                        className="text-red-500 focus:outline-none"
                        onClick={() => handleDeleteProduct(selectedProduct?.product?._id)}
                      >
                        <FaMinusCircle className="text-xs hover:text-red-500" />
                      </button>
                    </div>
                    <div key={index} className="flex items-center justify-between p-2">
                      <div className="w-20 h-20 overflow-hidden flex items-center justify-center">
                        <img
                          src={ImageUrl + (selectedProduct?.product?.product_image || 'image unavailable')}
                          alt="Product Image"
                          className="max-w-full max-h-full object-contain"
                        />
                      </div>
                      <div className="ml-4 flex flex-col">
                        <div className="flex justify-start">
                          <p className="text-xs font-semibold text-left whitespace-normal">
                            {selectedProduct?.product?.product_name}
                          </p>
                        </div>
                        <div className="flex flex-row justify-between space-x-2">
                          <p>
                            <span className="font-semibold">Qty:</span> {selectedProduct.count}{' '}
                            {getDisplayUOM(selectedProduct.product.UOM, selectedProduct.count)}
                          </p>
                          {(() => {
                            const selectedOffer = OffersData?.find(
                              (p) => p?.OfferData?._id === selectedProduct?.offer_id
                            );
                            if (selectedOffer) {
                              return (
                                <div>
                                  {selectedOffer.OfferData.net_price === 0 ? (
                                    <p>Offer Price: ₹{calculatePrice(selectedProduct.product, selectedOffer)}</p>
                                  ) : (
                                    <p>
                                      Offer Price: ₹
                                      {selectedOffer.OfferData
                                        ? selectedOffer.OfferData.net_price
                                        : 'Price Not Available'}
                                    </p>
                                  )}
                                </div>
                              );
                            } else {
                              return (
                                <p className="p-1 text-sm">
                                  Price: ₹{selectedOffer?.OfferData?.net_price ?? 'Price Not Available'}
                                </p>
                              );
                            }
                          })()}
                        </div>
                        <div className="grid grid-cols-4 gap-1 mt-1">
                          {Object.entries(
                            QuantityBreakUp.find((p) => p.product_id === selectedProduct.product._id)?.break_ups || {}
                          ).map(
                            ([key, value], index) =>
                              value !== '' &&
                              value !== 0 &&
                              value !== undefined &&
                              value !== null && (
                                <div key={index} className="flex flex-row">
                                  <p className="text-xs">{key}=</p>
                                  <p className="text-xs">{value}</p>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex justify-center px-4 py-2 flex flex-row space-x-2">
              {isQuotationSaved ? (
                <button className="rounded-sm border border-gray-500 px-2 text-gray-500" disabled>
                  Saved
                </button>
              ) : (
                <button
                  className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black"
                  onClick={() => handleGeneratePDF()}
                >
                  Save
                </button>
              )}
              <button
                className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black"
                onClick={() => handleGenerateOrder()}
              >
                Place Order
              </button>
            </div>
          </div>
        </div>
      )}
      {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
              <div className="bg-white p-6 rounded-md shadow-lg">
              <p className="text-lg font-bold mb-2">Are you sure?</p>
              <p className="mb-4">Deleting the last product will also delete the quotation!</p>
            <div className="flex justify-end space-x-2">
              <button
                className=" bg-[#EB001B] hover:bg-red-800 text-white hover:text-white text-md rounded-sm px-2"
                onClick={handleConfirmDelete}
              >
                Confirm
              </button>
              <button
                className="border border-[#EB001B] hover:bg-[#EB001B] text-black hover:text-white text-md rounded-sm px-2 "
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {showWirePopup && (
        <WireColorPopup
          onClose={() => setShowWirePopup(false)}
          onSubmit={handleWirePopupSubmit}
          BreakUps={breakUp}
        />
      )}
    </div>

  
  
    );
};

export default Summarypage2;
