import React, { useState, useEffect } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

const Products = () => {
  const location = useLocation();
  const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
  const { state } = location;
  const {offer} = state;
  const [productData,setProductsData] = useState([]);
  const [customerData, setCustomerData]= useState()
 const navigate= useNavigate()
  /* useEffect(() => {
    
  
    if (offer) {
      let allProducts = [];
  
      // Check if productGroup and productGroupSubData are both null or empty
      if (!offer.productGroup && (!offer.productGroupSubData || 
          !offer.productGroupSubData.productsUnderGroupData ||
          !offer.productGroupSubData.productsUnderGroupData.ProductData ||
          offer.productGroupSubData.productsUnderGroupData.ProductData.length === 0)) {
        // No product group and no products under productGroupSubData
        // In this case, set productData to contain the single product from productData
        if (offer.productData.product) {
          // Check if product already exists in allProducts array
          if (!allProducts.some(product => product._id === offer.productData.product._id)) {
            allProducts.push(offer.productData.product);
          }
        }
      } else {
        // Add product from productData (if available)
        if (offer.productData.product) {
          // Check if product already exists in allProducts array
          if (!allProducts.some(product => product._id === offer.productData.product._id)) {
            allProducts.push(offer.productData.product);
          }
        }
        
        // Add products from productGroupSubData (if available)
        if (offer.productGroupSubData && offer.productGroupSubData.productsUnderGroupData &&
            offer.productGroupSubData.productsUnderGroupData.ProductData &&
            offer.productGroupSubData.productsUnderGroupData.ProductData.length > 0) {
          // Iterate over each group of products
          offer.productGroupSubData.productsUnderGroupData.ProductData.forEach(group => {
            // Iterate over each product in the group
            group.forEach(product => {
              // Check if product already exists in allProducts array
              if (!allProducts.some(existingProduct => existingProduct._id === product._id)) {
                allProducts.push(product);
              }
            });
          });
        }
      }
  
      if (allProducts.length > 0) {
        setProductsData(allProducts);
        
      } else {
        console.log("No products available.");
        // Handle case when no products are available
      }
    }
  }, [offer]); */

  
 useEffect(()=>{
  const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
  const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
  if(storedLoginData&&Userdata){
    if(storedLoginData.token===Userdata.token){
    }else{
      navigate('/bb/admin')
     sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
    }
  }else{
    navigate('/bb/admin')
      sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
  }
})


  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/bb/admin';
  }

  useEffect(() => {
    
if(offer){
  getProducts(offer._id)
}
  }, [offer]);

  const getProducts = async (offer_id) => {
    const OfferProductsUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/get-offerProducts-by-offerId?offer_id=${offer_id}`;
    const token = sessionStorage.getItem('token');
    try {
        const response = await fetch(OfferProductsUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'x-token' : token 
                // Add other headers if needed
            },
        });

        if (response.ok) {
            const data = await response.json(); // Parse the response JSON
            console.log(data);
            setProductsData(data); // Assuming setProductsData is a state update function
        } else {
          setProductsData([])
            // Handle non-OK response status (e.g., 404, 500)
            console.error("Failed to fetch products:", response.statusText);
            // Handle the error state or show a message to the user
        }
    } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error fetching products:", error.message);
        clearStorageAndRedirectToHome()
        // Handle the error state or show a message to the user
    }
};

  const calculatePrice = (product, offer) => {

    if (!product || !offer || !offer.OfferData) {
      return 'Price Not Available';
    }
  
    const basePrice =parseFloat( product.product_price || 0);
    const discount = parseFloat(offer.OfferData.discount || 0);
    const gst = parseFloat(offer.OfferData.GST_rate_id || 0);
  
    let netPrice = parseFloat(basePrice);
  
    // Apply discount
    netPrice -= (netPrice * (discount / 100));
  
    // Apply GST
    netPrice *= (1 + (gst / 100));
  
    return `Price: ₹${netPrice.toFixed(2)}`;
  };

  const  handleBack=()=>{
    navigate(-1)
  }

  return (
    <div className="overflow-y-scroll no-scrollbar w-full h-full  flex flex-col ">{console.log(offer)}
      <div className="w-full h-full">
         <h2 className="text-2xl font-bold mt-2 ml-2 mb-4">
          {offer.productGroup?offer.productGroup.product_group_name:offer.productData&&offer.productData.product.product_name}
          </h2> 

        {productData.length === 0 ? (
           <div className="w-full flex justify-center py-4 h-full">
          <p>No Products</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 px-4 py-4 gap-6">
            
            {/* Display products */}
            {productData.map((product) => (
              <div key={product._id} className=" border w-auto py-2 rounded-sm flex flex-col items-center">
                  
                {/* Product image */}
                {product && (
                   <div className="w-40 h-40 overflow-hidden flex items-center justify-center">
                  <img
                    src={ImageUrl + (product.product_image || 'image unavailable')} // Adjusted image source to handle unavailable case
                    alt="Product Image"
                    className="max-w-full max-h-full object-contain"
                    onError={(e) => console.log("Error loading image:", e)}
                  />
                  </div>
                )}
                {/* Product name */}
                <h2 className="text-md font-bold text-center whitespace-normal break-all">
                  {product.product_name || 'Product Name Not Available'}
                </h2>
                <p className="text-md font-semibold text-center ">{product.product_description}</p>
                {/* Price */}
                {offer.OfferData.net_price===0?(
                  <p>{calculatePrice(product, offer)}/{offer.productGroup&&offer.productGroup.UOM||offer.productData&&offer.productData.UOM||product.UOM}</p>
                ):(
                  <p>Price: ₹{offer.OfferData ? offer.OfferData.net_price : 'Price Not Available'}/{offer.productGroup&&offer.productGroup.UOM||offer.productData&&offer.productData.UOM||product.UOM}</p>
                )}
                
              </div>
            ))}
          </div>
        )}
        <div className='flex justify-center mt-6'>
                <button className='rounded-sm text-black hover:text-white border border-[#EB001B] px-4 flex justify-center hover:bg-[#EB001B]' onClick={handleBack}>Back</button>
                </div>
      </div>
      
    </div>
  );
  
};

export default Products;





