
import React from 'react'
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logoImage from "./images/BB.png";


function ForgotPassword() {

    const Navigate = useNavigate();
    const [email ,setemail]=useState();
    const [Emptyemail,setEmptyemail]=useState(false);
    const [InvalidEmail,setInvalidEmail]=useState(false);
    const [checkEmailAllert,setcheckEmailAlert]=useState(false);
    const [UnregisterdEmail,setUnregisteredEmail]=useState(false);
    const [loading, setLoading] = useState(false); // New loading state
    const HandlechangeEmail= async(event)=>{
        const emailEnterd= event.target.value
        setemail(emailEnterd);
        setInvalidEmail(false);
        setEmptyemail(false);
        setUnregisteredEmail(false);
       }; 


       
       //This function calls when submit button clicked 
        const handlesubmitEmail = async () => {
        const forgetPasswordUrl = process.env.REACT_APP_FORE_BRICKBUCKET + '/user/user-forgot-password';
        if (!email) {
            setEmptyemail('Please enter your email.');
            return;
        } else if (!validateEmail(email)) {
            setInvalidEmail('Enter a valid Email');
            return;
        } else {
            setLoading(true); // Start loading
            const emailData = { email };
            try {
                const res = await fetch(forgetPasswordUrl, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(emailData),
                });
                if (res.ok) {
                    setcheckEmailAlert(true);
                } else {
                    setUnregisteredEmail('Email you entered is not registered');
                }
            } catch (error) {
            } finally {
                setLoading(false); // Stop loading
            }
        }
    };

   
       const validateEmail = async (email) => {
         const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         return emailValidation.test(email);
       };
   
       const handleResetPasswordAlert= async()=>{
         setcheckEmailAlert(false);
         Navigate('/bb/admin');
       }


    return (
        <div className="w-full flex justify-center items-center h-screen font-roboto border border-gray-300">
             {/* Content for the left div */}

             {loading && ( // Conditionally render loader
    <div className="fixed inset-0 flex justify-center items-center z-50 bg-gray-200 bg-opacity-75">
        <div className="flex flex-col items-center">
            <div className="relative">
                <img src={logoImage} alt="Logo" className="h-20 w-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
            </div>
        </div>
    </div>
)}


      {checkEmailAllert && (
  <div className="fixed inset-0 flex justify-center items-center z-50 bg-gray-500 bg-opacity-50">
    <div className="bg-white w-2/4  p-4 rounded shadow-md flex flex-col items-center">
      <p className="text-center text-md">
        Reset password link has been sent to your registered Email.
        <br/> Kindly check your Email Inbox.
      </p>
      <button className="mt-4 bg-green-600 hover:bg-green-700 text-white px-4 py-1 rounded" onClick={handleResetPasswordAlert}>
        OK
      </button>
    </div>
  </div>
)}

            <div className="flex w-full justify-center items-center">
                <div className="flex flex-col space-y-4 justify-center w-full items-center">
                <div className='flex flex-row justify-center items-center' >
                <img src={logoImage} alt="Logo" className='h-20 w-20'/>
                <p className='text-4xl sm:text-2xl md:text-4xl lg:text-5xl'>BRICKBUCKET</p>
            </div>
                    <div className="flex-col w-1/2 sm:w-full md:w-2/3 lg:w-1/2 xl:w-1/3 flex self-center items-center border border-[#D9D9D9] border-2 bg-opacity-[15%] rounded-sm p-4">
                        <p className="text-2xl pt-4">Forgot Password?</p>
                        <div className="p-6 relative">
                            <div className="flex-col justify-center flex items-center space-y-6 mt-2">
                                <p className="text-md text-center">Enter the email address you used when you joined
                                                        and we’ll send you instructions to reset your password</p>
                                <div className="flex-row flex items-center space-y-4 pl-2 pr-2 relative">
                                    <input
                                        className="md:w-full rounded-sm focus:outline-none focus:ring-[1px] focus:ring-[#EB001B] text-gray-600 text-sm px-2 py-1 pr-2  text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                                        placeholder="Please enter your Email"
                                        type="text"
              value={email}
              onChange={HandlechangeEmail}
                                   />
                                </div>

                            </div>
                            <div className="flex justify-center items-center mt-6">
                                <button className="border border-[#EB001B]  rounded-sm px-4 py-1 hover:bg-[#EB001B] hover:text-white mb-4"
                                 onClick={handlesubmitEmail} >                                    
                                    Submit</button>
                                    <p className="text-[12px] text-red">{Emptyemail}{InvalidEmail} {UnregisterdEmail}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
