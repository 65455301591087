import { faClose, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';


function ViewProduct() {

  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [DeleteProductAlert, setDeleteProductAlert] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [ContDeleteProductAlert, setContDeleteProductAlert] = useState(false);
  const [productCategories,setProductCategories] = useState([])
  const [ViewProducts,setViewProducts]= useState(true);
  const [vendors,setVendors]= useState([]);
  const [selectedProductCategory,setSelectedProductCategory]=useState('')
  const [SelectedVendor, setSelectedVendor]= useState([])
  const [allproductCategories,setAllproductCategories]= useState(false)
const [allVendors , setAllVendors]=useState(false)
const [productsAlert, setProductsAlert] = useState(false)

useEffect(()=>{
  const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
  const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
  if(storedLoginData&&Userdata){
    if(storedLoginData.token===Userdata.token){
    }else{
      navigate('/bb/admin')
     sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
    }
  }else{
    navigate('/bb/admin')
      sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
  }
})


function clearStorageAndRedirectToHome() {
  sessionStorage.clear();
  localStorage.clear();
  window.location.href = '/bb/admin';
}

const fetchProducts = async () => {
  try {
    const token = sessionStorage.getItem('token');
const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product/get-all-products-by-ProductCategoryIdAndVendorId?product_category_id=${selectedProductCategory}&vendor_id=${SelectedVendor}`;

    const response = await fetch(url, {
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
        'x-token' : token
      }
    } );
    if (response.ok) {
      const data = await response.json();
      console.log(data)
      setProducts(data); // Assuming products is the key in your response data
      setViewProducts(false);
      setProductsAlert(null)
    } else {
      setProductsAlert('No Products Available')
      setProducts([]);

      console.error('Failed to fetch products:', response.statusText);
    }
  } catch (error) {
    console.error('Error fetching products:', error.message);
    clearStorageAndRedirectToHome()
  }
};



  useEffect(() => {
    //fetchProducts();
    fetchProductCategories();
  }, []);

  useEffect(() => {
    if(selectedProductCategory && selectedProductCategory.length === productCategories.length){
        setAllproductCategories(true); 
    } else {
        setAllproductCategories(false);
    }
}, [productCategories, selectedProductCategory]);

useEffect(() => {
    if(SelectedVendor && SelectedVendor.length === vendors.length){
        setAllVendors(true); 
    } else {
        setAllVendors(false);
    }
}, [vendors, SelectedVendor]);


  const fetchProductCategories = async () => {
    const token = sessionStorage.getItem('token');
    const urlOfproductCategories = process.env.REACT_APP_FORE_BRICKBUCKET + '/Admin/get-all-productCategorys';
    try {
      const response = await fetch(urlOfproductCategories,{
        method: 'GET',
        headers:{ 
          'Content-Type': 'application/json',
          'x-token' : token
        }
      });
      if (response.ok) {
        const data = await response.json();
       console.log(data)
        setProductCategories(data);

      } else {
        setProductCategories([]);
       
        console.error('Failed to fetch product categories:', response.statusText);
      }
    } catch (error) {
      // Handle fetch errors or any other errors that might occur
      console.error('Error fetching product categories :', error.message);
      clearStorageAndRedirectToHome()
    }
  };
  


  const handleDeleteProduct = async (productId) => {
    // Display confirmation modal
    setDeleteProductAlert('Are you sure want to Delete?')
    // Store the productId in state to use it when confirming deletion
    setProductIdToDelete(productId);
  };
  
  // Add state to store productId to delete
 
  
  const handleDeleteProductAlert = async () => {
    // Close confirmation modal
    setDeleteProductAlert(false);
  
    if (productIdToDelete) {
      const token = sessionStorage.getItem('token');
      try {
        const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product/delete-product/?product_id=${productIdToDelete}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-token' : token 
          },
        });
        if (response.ok) {
          fetchProducts();
        } else {
          setContDeleteProductAlert('Product is associated with data and cannot be deleted')
        }
      } catch (error) {
        clearStorageAndRedirectToHome()
      }
    }
  };
  
  const handleNoDelete = () => {
    // Close confirmation modal
    setDeleteProductAlert(false);
    // Reset productIdToDelete state
    setProductIdToDelete(null);
  }; 
  
  const handlecloseAllert= ()=>{
    setContDeleteProductAlert(null);
  }



  const fetchVendorsByCategory = async (product_category_id) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(
        `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/get-vendorsBy-productCategoryId?product_category_id=${product_category_id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token' : token 
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        //console.log(data.vendors);
        setVendors(data.vendors);
        setSelectedVendor('');
    
      } else {

        // Set vendors to initial state if fetching fails
        setVendors([]);
        setSelectedVendor('');
      }
    } catch (error) {
      clearStorageAndRedirectToHome()
      // Set vendors to initial state if an error occurs
      setVendors([]);
      setSelectedVendor('');
    }
  };
  
  const handleChangeProductCategory = (selectedCategoryId) => {
    if (selectedCategoryId===selectedProductCategory) {
      setSelectedProductCategory('');
    } else {
      setSelectedProductCategory(selectedCategoryId);
      if(selectedCategoryId!==''){
        fetchVendorsByCategory(selectedCategoryId);
      }else{
        setVendors([])
      }
      
    }
  };
  


  const handleChangeVendor = (selectedVendorId) => {
    setProductsAlert(null);
  
    if (selectedVendorId===SelectedVendor) {
      // Selecting all vendors
      setSelectedVendor('');
      // Logic for handling all vendors selected
    } else {
      setSelectedVendor(selectedVendorId)  
    }
  };
  
const handleGetProducts= ()=>{
  fetchProducts()
}

/* const handleChangeAllProductCategory=()=>{
  setProductsAlert(null)
if(allproductCategories===true){
  setAllproductCategories(false)
  setSelectedProductCategory([])
}else{
  setAllproductCategories(true)
  const productCategoryIds = productCategories.map(category => ({
    product_category_id: category._id
}));
setSelectedProductCategory(productCategoryIds)

}
}
  const handleChangeAllVendors =()=>{
    setProductsAlert(null)
    if(allVendors===true){
      setAllVendors(false)
      setSelectedVendor([])
    }else{
      setAllVendors(true)
      const VendorIds = vendors.map(vendor => ({
        vendor_id: vendor._id
    }));
    setSelectedVendor(VendorIds)
    
    }
  } */

  return (
    <div className="overflow-y-scroll no-scrollbar w-full h-full mx-auto flex flex-col items-center ">
   <div className="flex flex-col items-center justify-center p-2">
   <div className=' flex flex-col flex justify-center  items-center  p-2 '>

      
   <div className="border w-full flex justify-center items-center">
  <div className="flex  justify-center items-center p-1 w-full">
    <div className="flex flex-row space-x-4">

      <div className="mb-3  flex items-center">
        <div className="">
          <p className="text-md font-medium text-gray-700 mb-3 ml-2">Product categories</p>
          <select
            value={selectedProductCategory}
            onChange={(e) => handleChangeProductCategory(e.target.value)}
            className="block p-1 ml-2 border border-gray-300 hover:border-[#EB001B] rounded-sm"
            style={{ width: "350px" }} // Set fixed width here
          >
            <option value={''}>Select Product Category</option>
            {productCategories.map((category, index) => (
              <option key={category._id} value={category._id}>
                {category.product_category_name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex flex-col  items-center">
        <div className="">
          <p className="text-md font-medium text-gray-700 mb-3 ml-2">Vendors</p>
          <select
            value={SelectedVendor}
            onChange={(e) => handleChangeVendor(e.target.value)}
            className="p-1 ml-2 border border-gray-300 hover:border-[#EB001B] rounded-sm"
            style={{ width: "350px" }} // Set fixed width here
          >
            <option value=''>All Vendors</option>
            {vendors.map((vendor) => (
              <option key={vendor._id} value={vendor._id}>
                {vendor.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-9">
        <button className="px-3 py-1 ml-2 mr-2 h-30 border border-1 rounded-sm border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white" onClick={handleGetProducts}>Submit</button>
      </div>

      {/* <div className="flex justify-center py-2 px-1 items-center">
        <p className="text-red-500">{productsAlert}</p>
      </div> */}
    </div>
  </div>
</div>

     
 



        <div className="bg-white flex flex-col relative rounded-md p-2 md:ml-10 md:mr-10 mx-2 md:mx-6 overflow-x-auto">
       {/*  <h1 className="text-xl font-semibold text-center">View Products</h1> */}
          <div className="overflow-x-auto">
          {products.length > 0 ? (
            <table className="w-full border border-gray-300 mt-4">
              <thead className="border border-gray-300">
                <tr className="bg-[#D9D9DD] bg-opacity-[100%]">
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Product Name</th>
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Vendor Name</th>
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Product Category</th>
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Product Sub Category</th>
                  <th className="text-blue-950  font-medium py-2 px-4 text-sm text-center">Product Group</th>
                  <th className="text-blue-950  font-medium py-2 px-4 text-sm text-center">Action</th>
                </tr>
              </thead>
              <tbody className="">
  {products.map((product, index) => (
    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
      <td className="text-[#EB001B] text-opacity-80 py-2 text-xs px-4 text-center cursor-pointer" onClick={() => navigate('/bb/admin/AdminHome/UpdateProduct', { state: { product } })}>{product.productData.product_name}</td>
      <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center">{product.vendor.name}</td>
      <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center">{product.productCategory.product_category_name}</td>
      <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center">
      {product.productSubCategory && product.productSubCategory? product.productSubCategory.product_sub_category_name : '---'}
      </td>
      <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center">
      {product.productGroup && product.productGroup.product_group_name ? product.productGroup.product_group_name : 'no group assigned'}
      </td>
      <td className="text-[#EB001B] text-opacity-80 py-2 text-xs px-4 text-center cursor-pointer">
        <span className="text-center" onClick={() => handleDeleteProduct(product._id)}>Delete</span>
      </td>
    </tr>
  ))}
</tbody>

            </table>
              ) : (
                <>
                {productsAlert ? (
                     <p className="text-center mt-10">No products available</p>
                          ) : (
                          <></>
                          )}
                </>
              
            )}
          </div>
        </div>
      </div>
     
      
      {ContDeleteProductAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{ContDeleteProductAlert}</p>
      <div className="flex">
        <button className="text-white bg-red-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handlecloseAllert}>OK</button>
      </div>
    </div>
  </div>
)}

      {DeleteProductAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{DeleteProductAlert}</p>
      <div className="flex">
        <button className="text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4 mr-2" onClick={handleDeleteProductAlert}>Yes</button>
        <button className="text-white bg-red-600 hover:bg-red-700 py-1 px-2 rounded-md mt-4" onClick={handleNoDelete}>No</button>
      </div>
    </div>
  </div>
)}

    </div>
    </div>
  );
}

export default ViewProduct;
