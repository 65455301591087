import React, { useEffect, useState } from "react";
import OrderDataPopUp from "./OrderDataPopUp"
import { json, useLocation, useNavigate } from "react-router-dom";
function ViewAllOrders() {
    //const location= useLocation()
const [CustomerOrders,setConsumerOrders]=useState([]);
const [viewOrderPopUp,setViewOrderPopUp]=useState(false);
const [customerOrderData,setCustomerOrderData]=useState(null)
const [ApprovalAlert,setApprovalAlert]=useState(false)
const [OrderData,setOrderData]=useState(null)
const navigate = useNavigate()
//const consumer_id= location.state.customer_id||''


useEffect(()=>{
fetchConsumerOrders();
},[])

const fetchConsumerOrders=async ()=>{
   
try {
  const token = sessionStorage.getItem('token');

    const response = await fetch(
        process.env.REACT_APP_FORE_BRICKBUCKET + `/customer/Quotation-Order/get-all-customer-Orders-Data`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-token':token
            },
        }
    );

    if (response.ok) {

        const data= await  response.json();
        console.log(data)
        setConsumerOrders(data.orders)
    } else {
        console.error(`Failed to fetch consumer order details `)
       
    }
} catch (error) {
    console.error('An error occurred while fetching consumer orders:', error);   
}
}

const handleGetOrderData= async (orderData)=>{
    setViewOrderPopUp(true)
    setCustomerOrderData(orderData)
}

const handleNavigateToOrderDetails = (quotationIds, productCategoryName) => {
  const navigateToOrderDetails = (productCategoryName === 'Tiles' || productCategoryName === 'UPVC Windows' || productCategoryName === 'Doors') ? 
    '/bb/admin/AdminHome/customerOrderDetails2' : 
    '/bb/admin/AdminHome/customerOrderDetails';
  
  navigate(navigateToOrderDetails, {
    state: {
      quotationid: quotationIds,
      ProductCategoryName: productCategoryName
    }
  });
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

 /*  const  handleBack=()=>{
    navigate(-1)
  } */



  const handleApprovalAlert= (order)=>{
    setOrderData(order)

    if(order.orderData.order_approved === 'No'){
        setApprovalAlert('Are you sure you want to Approve the order?')
    }
  }

  const handleChangeApprove = (approval) => {
    if (approval === 'No') {
      return 'Yes';
    } 
  };
  
  const handleApproval = async () => {
    const order = {
      _id: OrderData.orderData._id,
      order_approved: handleChangeApprove(OrderData.orderData.order_approved) // Removed await, since handleChangeApprove is not an async function
    };
  
    const updateOrderApproval = process.env.REACT_APP_FORE_BRICKBUCKET + `/customer/Quotation-Order/admin-update-order-approval`;
  
    try {
      const token = sessionStorage.getItem('token');

      const response = await fetch(updateOrderApproval, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", // Added Content-Type header
          'x-token':token
        },
        body: JSON.stringify(order) // Pass the order object as JSON string
      });
  
      if (response.ok) {
        const data = await response.json();
        setConsumerOrders(data.orders)
        setOrderData(null)
        setApprovalAlert(false)
      } else {
        console.error('Failed to update order approval:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating order approval:', error);
    }
  };
  

  const handleCloseApproval=()=>{
    setOrderData(null)
    setApprovalAlert(false)
  };

return(
    <div className="w-full h-full mx-auto overflow-y-scroll no-scrollbar">
    <div className="max-w-screen-xl mx-auto p-4">
    {ApprovalAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{ApprovalAlert}</p>
      <div className="flex">
        <button className="text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4 mr-2" onClick={handleApproval}>Yes</button>
        <button className="text-white bg-red-600 hover:bg-red-700 py-1 px-2 rounded-md mt-4" onClick={handleCloseApproval}>No</button>
      </div>
    </div>
  </div>
)}

        <div className="bg-white flex flex-col relative rounded-md  overflow-x-auto">
            
            <div className="overflow-x-auto">
            {CustomerOrders&&CustomerOrders.length > 0 ? (
                <table className="w-full border-b border-gray-300 mt-4">
                    <thead className="border-b border-gray-300">
                        <tr className="bg-[#D9D9DD] bg-opacity-[100%]">
                            
                            <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center ">Order ID</th>
                            
                            <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center  ">Customer Name</th>
                            <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center">Contact</th> 
                            <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center">Order Created On</th>
                            <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center">Product Category</th>
                            <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center">Order Status</th>
                            <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center ">Order Status On</th>
                            <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center  ">Deliver Details</th>
                            <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                    {CustomerOrders.map((order, index) => (
<tr key={index} className="border-b">{console.log(order)}
<td className="text-red-400 text-opacity-80 py-2 text-xs px-2 text-center whitespace-nowrap">
<p className="hover:underline cursor-pointer" onClick={()=>handleNavigateToOrderDetails(order.orderData.quotation_ids, order.productCategory.product_category_name)}>{order.orderData.order_id}</p>  
</td>
 <td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">{order.customerData && order.customerData .customer_name||''}</td>
<td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">{order.customerData&&order.customerData.mobile_number||''}</td>
<td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">{formatDate(order.orderData.order_time)}</td>
    <td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">{order.productCategory.product_category_name}</td>
<td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center ">
  {order.orderData.order_status === 1
    ? 'Order Placed'
    : order.orderData.order_status === 2
    ? 'Order Approved'
    : order.orderData.order_status === 3
    ? 'Order In Process'
    : order.orderData.order_status === 4
    ? 'Order Dispatched'
    : ''}
</td>
<td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">{formatDate(order.orderData.order_status_time)}</td>


<td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">
    <div className="flex flex-row space-x-4  w-full justify-center items-center">
      <p className="text-red-400 hover:underline cursor-pointer" onClick={()=>handleGetOrderData(order)}>View</p> 
      {/* <p className="text-red-400 hover:underline cursor-pointer"> Orders</p>  */} 
    </div>
</td>
<td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">
<div 
  className={`px-2 py-1 rounded-md ${order.orderData.order_approved === 'No' ? 'bg-red-500 text-white cursor-pointer' : 'bg-green-500 text-white'}`}
onClick={()=>handleApprovalAlert(order)}
>
  {order.orderData.order_approved === 'No'
    ? 'Approve '
    : order.orderData.order_approved === 'Yes'
    ? 'Approved'
    : ''}
</div>

</td>
</tr>
))}
                    </tbody>
                </table>
                ) : (
                    <p className="text-center">No orders available to this customer</p>
                )}
            </div>
        </div>
     {viewOrderPopUp&&customerOrderData&&(
     <OrderDataPopUp 
     OrderData={customerOrderData} 
     setViewOrderPopUp={setViewOrderPopUp}/>
     )}
       
     

    </div>
</div>
)

}

export default ViewAllOrders;
