import React, { useState, useEffect } from "react";

const WireColorPopup = ({ onClose, onSubmit,BreakUps }) => {
    const [colors, setColors] = useState({
      Red: '0',
      Blue: '0',
      Green: '0',
      Yellow: '0',
      Black: '0'
    });
  
    useEffect(()=>{
      setColors(BreakUps)
       },[BreakUps])
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      if (!isNaN(value)) { // Ensure the input is a number
        setColors((prevColors) => ({
          ...prevColors,
          [name]: value || '0'
        }));
      }
    };
  
    const handleSubmit = () => {
      onSubmit(colors);
      onClose();
    };
  
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="bg-white p-6 rounded-md shadow-md max-w-md w-full">
          <h3 className="text-lg font-semibold mb-4">Enter Wire Colors</h3>
          <div className="grid grid-cols-2 gap-4">
            {Object.keys(colors).map((color) => (
              <div key={color} className="flex flex-col">
                <label className="font-medium">{color}:</label>
                <input
                  type="text"
                  name={color}
                  value={colors[color]}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded focus:outline-none"
                />
              </div>
            ))}
          </div>
          <div className="mt-4 flex justify-end space-x-2">
            <button
              onClick={handleSubmit}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Submit
            </button>
            <button
              onClick={onClose}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  export default WireColorPopup;