import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


const CreateProject=()=> {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate("/bb/user/AddProject");
  };
  
  const handleSkip = ()=>{
    navigate("/bb/user/NotApproved");
    console.log('skipped')
  }

return (
  <div className="w-full h-screen flex justify-center items-center">
  <div className="w-full max-w-md md:w-1/2 xl:w-1/3 p-4 md:p-8 xl:p-12 bg-gray-50 shadow-lg rounded-lg">
    <div className="flex flex-col justify-center items-center">
      <div className="mb-6">
        <h2 className="text-2xl md:text-3xl xl:text-4xl font-bold text-gray-900">Create Project</h2>
      </div>
      <div className="flex justify-center  w-full">
        <div
          className="w-1/2 h-1/2 bg-white hover:bg-gray-200 rounded-lg shadow-md p-4 md:p-6 xl:p-8 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
          onClick={handleCardClick}
        >
          <h3 className="text-lg md:text-xl xl:text-2xl font-semibold text-center text-gray-700">
          <i className ="fa fa-plus-circle" aria-hidden="true"></i>
          </h3>
        </div>
       
      </div>
      <div className="mt-6">  
      <button className="rounded-sm border border-[#EB001B] px-2 py-1 text-sm md:text-sm xl:text-sm text-black  hover:underline hover:text-white hover:bg-[#EB001B]" onClick={handleSkip}>skip</button>
      </div>
    </div>
  </div>
</div>
)
}

export default CreateProject;