// Navigation.js
import React, {useEffect} from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export function Sidebar() {
  const [consumersOpen, setConsumersOpen] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);
  const [vendorsOpen, setVendorsOpen] = useState(false);
  const [offersOpen, setOffersOpen] = useState(false);
  const navigate = useNavigate();
 

  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
        navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })
  

  const toggleConsumers = () => {
    setConsumersOpen((prev) => !prev);
    setProductsOpen(false);
    setVendorsOpen(false);
    setOffersOpen(false);
  };

  const toggleProducts = () => {
    setProductsOpen((prev) => !prev);
    setVendorsOpen(false);
    setConsumersOpen(false);
    setOffersOpen(false);
  };

  const toggleVendors = () => {
    setVendorsOpen((prev) => !prev);
    setProductsOpen(false);
    setConsumersOpen(false);
    setOffersOpen(false);
  };

  const toggleOffers = () => {
    setOffersOpen((prev) => !prev);
    setProductsOpen(false);
    setConsumersOpen(false);
    setVendorsOpen(false);
  };

 


  const HandleLogout = () => {
    // Clear session and local storage data
    sessionStorage.clear();
    localStorage.clear();
    const loginPageUrl = '/bb/admin';
      // Push multiple states to the history to prevent going back to the homepage
      if (window.history && window.history.pushState) {
        for (let i = 0; i < 140; i++) {
          window.history.pushState({}, document.title, loginPageUrl);
        }
        window.addEventListener('popstate', function (event) {
          if (event.state === null) {
            for (let i = 0; i < 140; i++) {
              window.history.pushState({}, document.title, loginPageUrl);
            }
          }
        });
      }
      // Redirect the user to the login page
      window.location.replace(loginPageUrl);
  };




  return (
    <div className='w-full h-full bg-[#fff7f4] flex flex-col'>
      <div className='h-2/9 py-3 mt-1 border-b border-gray-300 flex flex-row items-center justify-center'>
        <h1 className='text-xl xs:text-2xs sm:text-xs md:text-lg lg:text-lg xl:text-xl 2xl:text-2xl font-custom'>
          Welcome
        </h1>
      </div> 
      

      {/* Options in the Sidebar */}
      <div className='py-4 h-5/6.5'>
      <div className='border-b mb-2' onClick={toggleProducts}>
          <a  className='cursor-pointer block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 text-black-800'>
            Products
          </a>
          {productsOpen && (
            <div className="pl-6">
              <div><a className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer'
              href='/bb/admin/AdminHome/ProductGroupFilters'
               /* onClick={() => navigate('/bb/admin/AdminHome/ProductGroupFilters')} */>Product Groups Attributes</a></div>
              <div><a className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer' 
              href='/bb/admin/AdminHome/CreateProductGroup'
              /* onClick={() => navigate('/bb/admin/AdminHome/CreateProductGroup')} */ >Create Product Group</a></div>
              <div>
              <a
            className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer'
            href='/bb/admin/AdminHome/ViewProductGroups'
                /* onClick={() => navigate('/bb/admin/AdminHome/ViewProductGroups')} */ >View Product Groups</a></div>
             {/*  <div><a className='text-gray-600 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer' onClick={() => navigate('/bb/admin/AdminHome/ProductGroups')} >Product Group</a></div> */}
              
             <div><a className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer' 
             href='/bb/admin/AdminHome/productAttributes'
             /* onClick={() => navigate('/bb/admin/AdminHome/productAttributes')} */>Product Attributes</a></div>
              <div><a className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer'
              href='/bb/admin/AdminHome/CreateProduct'
               /* onClick={() => navigate('/bb/admin/AdminHome/CreateProduct')} */>Create Products</a></div>
              <div>
  <a
    className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer'
    href='/bb/admin/AdminHome/ViewProducts' /* onClick={() => navigate('/bb/admin/AdminHome/ViewProducts')} */
  >
    View Products
  </a>
</div>

            
           </div>
          )}
        </div>
        <div className='border-b mb-2' onClick={toggleVendors}>
          <a  className='block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 text-black-800 cursor-pointer'>
            Vendors
          </a>
          {vendorsOpen && (
            <div className="pl-6">
              <div><a className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer' 
               href='/bb/admin/AdminHome/CreateVendor'
              /* onClick={() => navigate('/bb/admin/AdminHome/CreateVendor')} */>Create Vendor</a></div>
              <div><a className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer'
              href='/bb/admin/AdminHome/VendorList'
               /* onClick={() => navigate('/bb/admin/AdminHome/VendorList')} */>View Vendors</a></div>
              
            </div>
          )}
        </div>
        <div className='border-b mb-2' onClick={toggleOffers}>
          <a  className='block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 text-black-800 cursor-pointer'>
            Offers
          </a>
          {offersOpen && (
            <div className="pl-6">
              <div><a className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer' 
              href='/bb/admin/AdminHome/CreateOffer'
              /* onClick={() => navigate('/bb/admin/AdminHome/CreateOffer')} */>Create Offer</a></div>
              <div><a className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer'
              href='/bb/admin/AdminHome/ViewOffers'
               /* onClick={() => navigate('/bb/admin/AdminHome/ViewOffers')} */>View Offers</a></div>
            </div>
          )}
        </div>

        <div className='border-b mb-2' onClick={toggleConsumers}>
          <a  className='block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 text-black-800 cursor-pointer'>
            Consumers
          </a>
          {consumersOpen && (
            <div className="pl-6">
              <div><a className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer'
                href='/bb/admin/AdminHome/ViewConsumers'
               /* onClick={() => navigate('/bb/admin/AdminHome/ViewConsumers')} */>View Consumers</a></div>
              <div><a href="#" className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer'>Pending Payments</a></div>
              <div><a className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer' 
               href='/bb/admin/AdminHome/viewAllQuotations'
              /* onClick={() => navigate('/bb/admin/AdminHome/viewAllQuotations')} */>View Quotations</a></div>
              <div><a className='text-gray-800 block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 cursor-pointer' 
                href='/bb/admin/AdminHome/ViewAllOrders'
              /* onClick={() => navigate('/bb/admin/AdminHome/ViewAllOrders')} */>View Orders</a></div>
            </div>
          )}
        </div>
        
        
      
        <div className='border-b mb-2'>
          <a href="#" 
          onClick={HandleLogout}
          className='block py-2 px-4 hover:bg-gray-200 rounded transition-all duration-300 text-black-800 cursor-pointer'>
            Logout
          </a>
        </div>
      </div>
    </div>
  );
}

export function Navbar({currentPage}) {
  const navigate = useNavigate();
  return (
    <div className="py-2 h-2/9 w-full border-b border-gray-300 flex flex-row justify-between items-center">
    {/* Logo and Brand Name */}
    <div className="h-2/9 flex flex-row items-center justify-center" onClick={() => navigate('/bb/admin/AdminHome/dashbord')}>
      <img className="h-10 w-10 cursor-pointer ml-5" src={process.env.PUBLIC_URL + '/BB.png'} alt="Brick Bucket Logo" />
      <h1 className="cursor-pointer text-xl xs:text-2xs sm:text-xs md:text-lg lg:text-lg xl:text-xl 2xl:text-2xl font-custom">BrickBucket</h1>
    </div>

    {/* Container for the current page */}
    <div className="w-full flex justify-center items-center">
      <h1 className="text-lg font-bold">{currentPage}</h1>
    </div>
  </div>
    
  );
}
