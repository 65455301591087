import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import React from "react";

function DeliveryDetails({ Deliveryaddress }) {
  const convertUTCToIST = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };
    return date.toLocaleString("en-IN", options);
  };

  console.log(Deliveryaddress)

  return (
    <div>
     
          <div className="flex flex-col items-center mt-2">
            
            <div className="border border-gray-400 p-4 relative mt-8">
              <p className="text-md font-bold text-gray-700 mb-2 absolute -top-4 bg-white px-2">
                Delivery Address
              </p>
              <div className="flex flex-col space-y-1">
                <p>{Deliveryaddress.street}</p>
                <p>{`${Deliveryaddress.city}, ${Deliveryaddress.state} ${Deliveryaddress.zip}`}</p>
                <p>{Deliveryaddress.land_mark}</p>
              </div>
            </div>
            {/* Uncomment to display CustomerData for each order */}
            {/* {order.customerData.map((customer, idx) => (
              <div key={idx} className="border border-gray-400 p-4 relative mt-8">
                <p className="text-md font-bold text-gray-700 mb-2 absolute -top-4 bg-white px-2">
                  Site Details for {customer.projectName}
                </p>
                <table className="w-full">
                  <tbody>
                    <tr>
                      <td className="font-semibold pr-4">Site Contact Name:</td>
                      <td className="pl-10">{customer.siteContactName}</td>
                    </tr>
                    <tr>
                      <td className="font-semibold pr-4">Site Contact Number:</td>
                      <td className="pl-10">{customer.siteContactNo}</td>
                    </tr>
                    <tr>
                      <td className="font-semibold pr-4">Site Address:</td>
                      <td className="pl-10">{customer.siteAddress}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))} */}
          </div>
      
      
     
    </div>
  );
}

export default DeliveryDetails;
