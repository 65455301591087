import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ApplyAttributes from './ApplyAttributes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faUpload } from '@fortawesome/free-solid-svg-icons';


function UpdateProductGroups() {
    const navigate = useNavigate();
    const location = useLocation();
    const { productGroup } = location.state;
  
    const [productGroupFile,setProductGroupFile] = useState(null);
    const [vendors, setVendors] = useState([]);
    const [productGroupName,setProductGroupName]= useState('');
    const [selectedProductCategory, setSelectedProductCategory] = useState('');
    //const [price,setPrice]=useState('');
    const [unitOfMeasurement, setUnitOfMeasurement] = useState('');
    const [image, setImage] = useState(null);
    const [attributeIds, setAttributeIds] = useState([]);
    const [AttributesData, setAttributesData] = useState([]);
    const [successMessage,setUpdateAlert ]= useState(null);
    const [selectedVendor, setSelectedVendor] = useState('');
    const [product_group_image, setProductGroupImage]= useState('');
    const [selectedProductSubCategory, setSelectedProductSubCategory] = useState('');
    const [productSubCategories, setProductSubCategories] = useState([]);
    const [unitOfMeasurementOptions, setUnitOfMeasurementOptions] = useState([]); 
    const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;

 useEffect(()=>{
  const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
  const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
  if(storedLoginData&&Userdata){
    if(storedLoginData.token===Userdata.token){
    }else{
      navigate('/bb/admin')
     sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
    }
  }else{
    navigate('/bb/admin')
      sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
  }
})


 
useEffect(() => {
  fetchUnitOfMeasurementOptions(); // Fetch UOM options on component mount
}, []);

// In your fetchUnitOfMeasurementOptions function:
const fetchUnitOfMeasurementOptions = async () => {
try {
  const token = sessionStorage.getItem('token');
  const response = await fetch(`${backendApiUrl}/Admin/UOMs/get-all-UOMs`, {
    method: "GET",
    headers:{
      'x-token':token
    }
});
  if (response.ok) {
    const data = await response.json();
    setUnitOfMeasurementOptions(data.uoms); // This is the correct way to store the array of UOMs
    console.log(data); 
  } else {
    console.error('Failed to fetch UOM options');
  }
} catch (error) {
  console.error('Error while fetching UOM options:', error);
}
};

    useEffect(() => {
        if(productGroup.attributeValuesData){
          setAttributeIds(productGroup.attributeValuesData)
        }
    }, [productGroup]);

    useEffect(() => {
      setProductGroupName(productGroup.productGroupData.product_group_name)
      setSelectedVendor(productGroup.vendor._id)
      setUnitOfMeasurement(productGroup.productGroupData.UOM)
      //setPrice(productGroup.productGroupData.product_group_price)
      setProductGroupImage(productGroup.productGroupData.product_group_image)
        setSelectedProductSubCategory(productGroup.productSubCategory?._id||'')
        setSelectedProductCategory(productGroup.productCategory._id)
        fetchProductSubCategories(productGroup.productCategory._id)
      
  }, [productGroup]);

  const fetchProductSubCategories = async (product_category_id) => {
    try {
      const token = sessionStorage.getItem('token');

        const getProductSubcategories = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-sub-category/get-productSubCategories-by-productCategoryId?product_category_id=${product_category_id}`;
        
        const response = await fetch(getProductSubcategories, {
            method: "GET",
            headers:{
              'x-token':token
            }
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setProductSubCategories(data.data);
        } else {
          setProductSubCategories([])
            console.error('Failed to fetch product subcategories', response.status, response.statusText);
        }
    } catch (error) {
        console.error('Error while fetching product subcategories:', error);
    }
};
    
    function clearStorageAndRedirectToHome() {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/bb/admin';
    }

    const handleSubmit = async () => {
      
      // Extract _id values from attributeIds array
      const attributeIdsArray = attributeIds.map((attribute) => attribute._id);
  
      const formData = new FormData();
      formData.append('_id', productGroup._id);
      formData.append('product_group_name', productGroupName);
      formData.append('product_category_id', selectedProductCategory);
      formData.append('product_sub_category_id', selectedProductSubCategory);
      formData.append('vendor_id', selectedVendor);
      //formData.append('product_group_price', price);
      formData.append('UOM', unitOfMeasurement);
      
      // Append each attribute value ID
      attributeIdsArray.forEach((attributeId) => {
          formData.append('attribute_value_ids', attributeId);
      });
      
      // Append image if available
      if (productGroupFile) {
          formData.append('image', image);
      } else {
          formData.append('product_group_image', productGroup.productGroupData.product_group_image);
      }
      
      try {
        const token = sessionStorage.getItem('token');
          const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-groups/create-productGroup`, {
              method: 'POST',
              headers:{
              //  'Content-Type': 'application/json',
              'x-token' : token
              },
              body: formData,

          });
             
          if (response.ok) {
              setUpdateAlert('Product group updated successfully!');
          } else {
              console.error('Failed to update product:', response.statusText);
              const data = await response.json()
              setUpdateAlert(data.message)
          }
      } catch (error) {
          console.error('Error updating product:', error.message);
          clearStorageAndRedirectToHome()
      }
  };
  
  
    const fetchAttributesByProductCategory = async (productCategoryId) => {
      const token = sessionStorage.getItem('token');
        try {
          const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-group-attributes/get-all-attributes-by-productCategory?product_category_id=${productCategoryId}`,
            {
              method:'GET',
              headers:{
                'Content-Type': 'application/json',
                'x-token' : token
              }
            }
          );
    
          if (response.ok) {
            const attributesData = await response.json();
    
    
            // Set the state with the fetched attribute data
            setAttributesData(attributesData);
            
      
            // Store the attributes data in local storage
            localStorage.setItem('product_category_attributes', JSON.stringify(attributesData));
      
            // You can perform additional actions if needed
      
          }else{
            console.error('Failed to fetch attributes for the selected product category')
            //throw new Error('Failed to fetch attributes for the selected product category');
          }
    
          
        } catch (error) {
          clearStorageAndRedirectToHome()
        }
       
      };
    
      const handleVendorDropdown=(e)=>{
        const selectedValue = e.target.value;
         console.log(selectedValue)
        if (selectedValue === selectedVendor) {
          // If it is selected, set it to empty string to deselect
          setSelectedVendor('');
        
        } else {
          // Otherwise, set the clicked value as selected
          setSelectedVendor(selectedValue);
        }
      }

      

      useEffect(() => {
       
        if (productGroup && productGroup.productCategory) {
        
        fetchVendors(productGroup.productCategory._id);
        fetchAttributesByProductCategory(productGroup.productCategory._id);
    }
}, [productGroup.productCategory]);


const handleUnitOfMeasurementChange = (e) => {
  setUnitOfMeasurement(e.target.value); // You might want to store the selected UOM id instead of just the value
};

const handleProductSubCategoryDropdown = (e)=>{
  const selectedValue = e.target.value; 

  if(selectedValue === selectedProductSubCategory){
    setSelectedProductSubCategory('')
  }else{
    setSelectedProductSubCategory(e.target.value);
  }

}

const fetchVendors = async (product_category_id) => {
  const token = sessionStorage.getItem('token');
    try {
        const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/get-vendorsBy-productCategoryId?product_category_id=${product_category_id}`,
          {
            method:'GET',
            headers:{
              'Content-Type': 'application/json',
              'x-token' : token 
            }
          }
        );
        if (response.ok) {
            const data = await response.json();
        
            setVendors(data.vendors);
        } else {
            console.error('Failed to fetch vendors:', response.statusText);
        }
    } catch (error) {
      clearStorageAndRedirectToHome()
        console.error('Error fetching vendors:', error.message);
    }
};

const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file)
        // You can perform additional checks or operations here, such as validating file type or size.
        setProductGroupFile(URL.createObjectURL(file)); // Create URL for the selected file and set it as the image source.
    }
};

const handleRadioChange = (attribute_name_id, attributeValueId) => {

  // Check if the attribute ID exists in attributeIds array
  const existingAttributeIndex = attributeIds.findIndex((p) => p.attribute_name_id === attribute_name_id);

  if (existingAttributeIndex === -1) {
    // If attribute ID doesn't exist, add it to the array
    setAttributeIds([...attributeIds, { attribute_name_id: attribute_name_id, _id: attributeValueId }]);
  } else {
    const updatedattributeIds = [...attributeIds];
        if (updatedattributeIds.find(p => p._id === attributeValueId)) {
            const index = updatedattributeIds.findIndex(p => p._id === attributeValueId);
            updatedattributeIds.splice(index, 1); // Remove the existing attribute with the same attribute_value_id
            setAttributeIds(updatedattributeIds);
        
        }else{
          updatedattributeIds[existingAttributeIndex]._id = attributeValueId;
          setAttributeIds(updatedattributeIds);
        ;
        }
  }
};

const handleSuccessPopupClose= ()=>{
  setUpdateAlert(false);
  navigate('/bb/admin/AdminHome/ViewProductGroups')
}

    return (
        <>
      <div className="overflow-y-auto w-full  flex flex-col items-center ">
       {successMessage && (
      <div className="fixed z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className=" bg-white p-4 rounded-md">
          <div>
          <p className="text-lg font-semibold mb-2">{successMessage}</p>
</div>
<div className='flex justify-center'>
          <button
            className="mt-2 px-2 py-1 bg-green-700 text-white rounded-md"
            onClick={handleSuccessPopupClose}
          >
            Ok
          </button>
          </div>
        </div>
      </div>
    )} 
    

        <div className="flex flex-col items-center justify-center p-10">
          <div className="border border-1 border-gray-500 flex flex-col space-y-4 flex justify-center  items-center  p-10 ">
         {/*  <h2 className="text-lg font-semibold mb-4 flex justify-center">Update Product Group</h2> */}

         <div className="flex flex-col w-1/2">
              
              <label className="text-md font-medium text-gray-700 mb-3">Product Category</label>
              <input
                  type="text"
                  className="block w-full p-1 border border-gray-300 hover:border-[#EB001B] rounded-sm"
                  value={productGroup.productCategory.product_category_name}
                  disabled={true}
              />
                        </div>

            <div className="flex flex-row space-x-4 w-full ">
              
              <div className="flex flex-col w-1/2">
                
                <p className='text-md font-medium text-gray-700 mb-3'>Product Subcategory</p>
              
              
                <select
                  value={selectedProductSubCategory}
                  onChange={handleProductSubCategoryDropdown}
                  className="border border-gray-300 focus:outline-none hover:border-[#EB001B] rounded-sm p-1 cursor-pointer"
                  //style={{ width: '200px' }}
                >
                  <option value="" >Select SubCategory</option>
                  {productSubCategories.map((SubCategory) => (
                    <option key={SubCategory?._id} value={SubCategory?._id}>
                      {SubCategory?.product_sub_category_name}
                    </option>
                  ))}
                </select>
              

            </div>

              <div className="flex flex-col w-1/2">
    <p className="text-md font-medium text-gray-700 mb-3">Vendor</p>
    <select
        value={selectedVendor} // Use selectedVendor instead of selecetdVendor
        onChange={handleVendorDropdown}
        className="border border-gray-300 hover:border-[#EB001B] focus:outline-none rounded-sm p-1 cursor-pointer"
  
    >
        <option value="" disabled>Select Vendor</option>
        {vendors.map((vendor) => (
            <option key={vendor?._id} value={vendor?._id}>
                {vendor?.name}
            </option>
        ))}
    </select>
</div>

            </div>
            <div className="flex flex-row space-x-4 w-full">
           <div className='flex flex-col w-full'>
            <div className="relative flex flex-col justify-center items-center" style={{ width: '150px', height: '150px' }}>
    {/* Display image */}
    {(productGroupFile || product_group_image) && (
        <img
            src={productGroupFile ? productGroupFile : `${process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES}${product_group_image}`}
            alt="Product Group Image"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
    )}

    {/* Button */}
    <label htmlFor="productImage" className="absolute bottom-0 right-0 p-2 cursor-pointer">
        <input type="file" id="productImage" accept="image/*" onChange={handleFileChange} className="hidden" />
        <FontAwesomeIcon icon={faCamera} className="text-gray-800 opacity-80 mr-1 mt-1 hover:text-gray-600" />
    </label>
</div>
</div>


    <div className="flex items-center justify-center w-full">
   
        <div className='flex flex-col '>
        <label className="text-md font-medium text-gray-700 mb-3">Product Group Name</label>
       
        <div className=' flex'>
          <input
          value={productGroupName}
          type="text"
          onChange={(event) => setProductGroupName(event.target.value)}
          className=" border w-full border-gray-300 rounded-sm p-1 focus:outline-none hover:border-[#EB001B] "
          
           // Disable input if no product group selected
        />
 </div>
      </div>

    </div>
     </div>
     <div className='flex justify-center flex-col items-center'>
     <label className="text-md font-medium text-gray-700 mb-3">Attributes</label>
    <table>
        <tbody>
            {AttributesData.map((attribute, index) => (
                <tr key={index} className='border-1 border border-[#EB001B] border-opacity-50'>
                    <td className='py-2 px-4 border-1 border border-[#EB001B] border-opacity-50'>
                        <h1 className='text-md font-medium text-gray-700'>{attribute.attribute_name}</h1>
                    </td>
                    <td>
                        <div className='flex flex-col items-left ml-10 px-4 py-2'>
                            {attribute.attribute_values.map((attributeValue, attributeValueIndex) => (
                                <div key={attributeValueIndex} className='flex flex-row items-center text-md text-gray-700'>
                                    <input
                                        type='radio'
                                        id={`attribute_${index}_${attributeValueIndex}`}
                                        name={`attribute_${index}`}
                                        onClick={() => handleRadioChange(attribute.attribute_name_id, attributeValue.attribute_value_id)}
                                        checked={attributeIds.some(p =>
                                            p.attribute_name_id === attribute.attribute_name_id &&
                                            p._id === attributeValue.attribute_value_id
                                        )}
                                        className="appearance-none w-3 h-3 border border-black rounded-full checked:bg-[#EB001B] checked:border-transparent focus:outline-none focus:ring-1 focus:ring-[#EB001B]"
                                    />
                                    <label htmlFor={`attribute_${index}_${attributeValueIndex}`} className='ml-5'>
                                        {attributeValue.attribute_value}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
</div>

      
<div className='flex flex-row space-x-4 w-full'>
{/* <div className="w-1/2 flex flex-col">
        <label className="text-md font-medium text-gray-700 mb-3">Price</label>
        <input
          type="text"
          value={price}
          onChange={(e) => setPrice(e.target.value)} // Allow only numeric input
          className="border border-gray-300 rounded-sm w-full p-1 hover:border-[#EB001B] focus:outline-none"
        />
      </div> */}
      <div className="flex flex-col w-[50%]">
      <label className="text-md font-medium text-gray-700 mb-3 ">Unit Of Measurement</label>
      <select
        value={unitOfMeasurement}
        onChange={handleUnitOfMeasurementChange}
        className="border border-gray-300 hover:border-[#EB001B] rounded-sm p-1 focus:outline-none"
      >
        <option value="">Select UOM</option>
        {unitOfMeasurementOptions.map((uoms) => ( // Use unitOfMeasurementOptions instead of unitOfMeasurement
          <option key={uoms._id} value={uoms.UOM_type}>
            {uoms.UOM_type}
          </option>
        ))}
      </select>
    </div>
      </div>

      <div className='flex flex-row items-center justify-center space-x-16 mt-10'>
        <button className=' px-6 py-1 h-30 border border-[#EB001B] hover:bg-[#EB001B] hover:text-white rounded-sm text-black' 
         onClick={() => navigate('/bb/admin/AdminHome/ViewProductGroups')}
         >
          Cancel
</button>
<button
className="px-6 py-1 h-30 border border-1 rounded-sm  border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white"
onClick={handleSubmit}>
Update
  </button>
</div>
          </div>
        </div>
      </div>
    </>

            
    );
}

export default UpdateProductGroups;
