import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import ContactModal from "./VendorContactModal";

function VendorContact() {
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState({ id: '', name: '' });
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [contactMode, setContactMode] = useState('add'); // Default mode is 'add'
  const [updateTrigger, setUpdateTrigger] = useState(false); // State variable to trigger useEffect
  const [DeleteVendorAlert, setDeleteVendorAlert] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState(null);

  const navigate = useNavigate();

  const location = useLocation();
  const { VendorData } = location.state;
  const vendor=VendorData
  
  
 useEffect(()=>{
  const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
  const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
  if(storedLoginData&&Userdata){
    if(storedLoginData.token===Userdata.token){
    }else{
      navigate('/bb/admin')
     sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
    }
  }else{
    navigate('/bb/admin')
      sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
  }
})

 
  const toggleContactModal = (vendor, mode) => {
    setSelectedVendor(vendor);
    setContactMode(mode);
    setIsContactOpen(prevState => !prevState); // Toggle the modal state
  };

  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/bb/admin';
  }


  useEffect(() => {
    const getAllVendors = async () => {
      const token = sessionStorage.getItem('token');
      try {
        const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/get-vendor-users?vendor_id=${vendor._id}`;

        const response = await fetch(url,{
          method: 'GET',
          headers:{
            'Content-Type': 'application/json',
            'x-token' : token
          }
        });
        if (!response.ok) {
          setVendors([])
          console.error("Failed to fetch vendors")
         // throw new Error("Failed to fetch vendors");
        }
        const responseData = await response.json();

        setVendors(responseData.reverse());
      } catch (error) {
        clearStorageAndRedirectToHome()
      }
    };
    getAllVendors();
  }, [isContactOpen, selectedVendor, contactMode, updateTrigger]); // Add dependencies

  const handleDelete = async (vendorId) => {
    const token = sessionStorage.getItem('token');
    try {
      const deleteVendorUser = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/delete-vendor-user?vendor_user_id=${vendorId}`;
      const requestOptions = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-token' : token
        },
      };
      const response = await fetch(deleteVendorUser, requestOptions);
      if (response.ok) {
        setUpdateTrigger(prevState => !prevState); // Trigger useEffect to update the vendor list
      setDeleteVendorAlert(false); // Hide the confirmation popup after successful deletion 
      }else{
        console.error('Failed to delete vendor')
        //throw new Error('Failed to delete vendor');
      }

     
    } catch (error) {
      clearStorageAndRedirectToHome()
      // Optionally, handle the error or show a notification to the user
    }
  };
  
  

  const handleNoDelete = () => {
    setDeleteVendorAlert(false);
  }
  const confirmDelete = (vendorId) => {
    setVendorToDelete(vendorId);
    setDeleteVendorAlert('Are you sure want to delete?');
  };

  return (
    <div className="w-full h-full mx-auto">
      <div className="max-w-screen-xl mx-auto p-4">
        <div className="bg-white flex flex-col relative rounded-md  p-2 md:ml-10 md:mr-10 mx-2 md:mx-6 overflow-x-auto">
         {/*  <div>
            <h1 className="text-2xl font-bold text-center">Vendor Contact Details</h1>
          </div> */}
          <div className="overflow-x-auto">
          {vendors.length === 0 ? (
          <div className="text-center text-gray-500 py-10">
            No contacts available.
          </div>
        ) : (
            <table className="w-full border border-gray-300 mt-4">
              <thead className="border-b border-gray-300">
                <tr className="bg-[#D9D9DD] bg-opacity-[100%]">
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Name</th>
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Email</th>
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Mobile Number</th>
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Designation</th>
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Actions</th>
                </tr>
              </thead>
              <tbody className="border-b border-gray-300">
                {vendors.map((vendor, index) => (
                  <tr key={vendor._id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                    <td className="text-blue-950 text-opacity-80 py-2 text-xs px-4 text-center" > {vendor.name} </td>
                    <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center"> {vendor.email} </td>
                    <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center"> {vendor.mobile_number}</td>
                    <td className="text-blue-950 text-opacity-60 py-2 text-xs px-4 text-center"> {vendor.role} </td>
                    <td className="flex flex-row justify-center text-[#EB001B]  py-2 text-xs px-4 text-center cursor-pointer">
                      <span className="py-2 text-sm px-4 text-center" onClick={() => toggleContactModal(vendor, 'edit')}>Edit</span>
                      <span className="py-2 text-sm px-4 text-center" onClick={() => confirmDelete(vendor._id)}>Delete</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            )}
          </div>
          {isContactOpen && selectedVendor&&contactMode&& <ContactModal onClose={()=>toggleContactModal()} selectedVendor={selectedVendor} mode={contactMode} />}
        </div>
      </div>
      <div className="flex justify-center">
        <button className="border border-[#EB001B] text-md px-4 py-1 hover:bg-[#EB001B] hover:text-white m-6" onClick={() => toggleContactModal(vendor, 'add')}>Add Contact</button>
        <button className="border border-[#EB001B] text-md px-4 py-1 hover:bg-[#EB001B] hover:text-white m-6" onClick={() => navigate(-1)}>Cancel</button>
      </div>

      
      {DeleteVendorAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{DeleteVendorAlert}</p>
      <div className="flex">
        {/* Pass function references without calling them */}
        <button className="text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4 mr-2" onClick={() => handleDelete(vendorToDelete)}>Yes</button>
        <button className="text-white bg-red-600 hover:bg-red-700 py-1 px-2 rounded-md mt-4" onClick={handleNoDelete}>No</button>
      </div>
    </div>
  </div>
)}

                          
    </div>
  );
}

export default VendorContact;
