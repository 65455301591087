import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import logoImage from "./images/BB.png";
import { useNavigate, useLocation } from 'react-router-dom';

function Profile() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [firmType, setFirmType] = useState('');
  const [gst, setGst] = useState('');
  const [email, setemail] = useState('');
  const [pan, setPan] = useState('');
  const [city, setCity] = useState('');
  const [association, setAssociation] = useState('');
  const [userData, setUserData] = useState({});
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // New state for popup
  const UpdateCustomerURL = process.env.REACT_APP_FORE_BRICKBUCKET + '/customer/update-customer-from-profilepage';
  const GetCustomerByMobileURL = process.env.REACT_APP_FORE_BRICKBUCKET + '/customer/get-customer-data-by-mobileNumber'; 
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const atIndex = email.indexOf('@');
    const dotIndex = email.lastIndexOf('.');
    return re.test(String(email).toLowerCase()) && atIndex!== -1 && dotIndex!== -1 && dotIndex > atIndex;
  };
  // Get user data from localStorage on component mount
  useEffect(() => {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      setUserData(JSON.parse(storedData));
      // Fetch customer data using mobile number from userData
      fetchCustomerData(JSON.parse(storedData).mobile_number);
    }
  }, []);

  // Fetch customer data by mobile number
  const fetchCustomerData = async (mobileNumber) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${GetCustomerByMobileURL}?mobile_number=${mobileNumber}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token':token
  
        },
      });
      if (response.ok) {
        const data = await response.json(); 
        console.log(data); 
        // Map the fetched data to the component state
        setName(data.customer.customer_name);
        setMobileNumber(data.customer.mobile_number);
        setCompanyName(data.customer.company_name);
        setGst(data.customer.gstin); 
        setAssociation(data.customer.association);
        setPan(data.customer.pan);
        setFirmType(data.customer.type_of_firm);
        setCity(data.customer.city);
        setemail(data.customer.email);
        return data.customer;
      } else {
        // Handle errors
        const errorData = await response.json(); 
        console.error(errorData.message);
        return null;
      }
    } catch (error) {
      console.error(error);
      clearStorageAndRedirectToHome()
      return null;
    }
  };
  
  const handleSubmit = async () => {
    // Add your update logic here
    const token = sessionStorage.getItem('token');
    const response = await fetch(UpdateCustomerURL , {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-token':token

      },
      body: JSON.stringify({
        _id : userData._id,
       customer_name: name,
       company_name: companyName,
       mobile_number : mobileNumber,
        gstin : gst,
        association :association,
        type_of_firm : firmType,
        pan : pan,
        city : city,
        email : email,
      }),
    });
    const data = await response.json();
    console.log(data);
    // Show the success popup on successful update
    setShowSuccessPopup(true);
  };

  const handleClosePopup = () => {
    setShowSuccessPopup(false);
navigate("/bb/user/offers");
  };

  const handleCancel = () => {
navigate("/bb/user/offers");
  };

  function clearStorageAndRedirectToHome() {
    navigate("/bb/ErrorPage");
  }

  return (

    <div className='mt-5 flex justify-center items-center '>
    <div className="flex justify-center items-center md:w-2/3 sm:w-full">
    <div className="w-1/2 sm:w-full md:w-2/3 md:border md:border-[#D9D9D9] md:border-2 bg-opacity-[15%] rounded-sm p-4">
          <h2 className="text-2xl font-bold text-center mb-4">Profile</h2>

          {/* Name Input */}
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              placeholder='Enter your Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
            />
          </div>

          {/* Mobile Number Input */}
          <div className="mb-4">
            <label htmlFor="mobileNumber" className="block text-gray-700 font-bold mb-2">
              Mobile Number
            </label>
            <input
    type="tel"
    id="mobileNumber"
    placeholder='Enter your Mobile Number'
    value={mobileNumber}
    onChange={(e) => {
      const mobileNumber = e.target.value.replace(/[^0-9]/g, ''); // Allow numbers
      if (mobileNumber.length <= 10) {
        setMobileNumber(mobileNumber);
      }
    }}
    className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
    maxLength={15}
  />
{mobileNumber && mobileNumber.length > 0 && mobileNumber.length < 10 ? (
  <p className="text-red-500 text-sm">Mobile Number must be exactly 10 digits.</p>
) : null}
          </div>
         <div className="mb-4">
  <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
    Email
  </label>
  <input
    type="email"
    id="email"
    placeholder='Enter your Email'
    value={email}
    onChange={(e) => setemail(e.target.value)}
    className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
  />
{email && email.length > 0 && !validateEmail(email) ? (
  <p className="text-red-500 text-sm">Please enter a valid email address.</p>
) : null}
</div>


          <div className="mb-4">
            <label htmlFor="companyName" className="block text-gray-700 font-bold mb-2">
              Company Name
            </label>
            <input
              type="text"
              id="companyName"
              placeholder='Enter your Company Name'
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
            />
          </div>

          {/* Firm Type Dropdown */}
          <div className="mb-4">
            <label htmlFor="firmType" className="block text-gray-700 font-bold mb-2">
              Type of Firm
            </label>
            <select
              id="firmType"
              value={firmType}
              onChange={(e) => setFirmType(e.target.value)}
              className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
            >
              <option className="text-gray-400" value="">Select Firm Type</option>
              {/* Add your firm type options here */}
              <option value="Sole Proprietorship">Sole Proprietorship</option>
              <option value="Partnership">Partnership</option>
              <option value="Limited Liability Partnership (LLP)">Limited Liability Partnership (LLP)</option>
              <option value="Private Limited Company">Private Limited Company</option>
              {/* Add more options as needed */}
            </select>
          </div>
  {/* Association Input */}
  <div className="mb-4">
            <label htmlFor="association" className="block text-gray-700 font-bold mb-2">
              Association
            </label>
            <select
              type="text"
              placeholder='Select your Association'
              id="association"
              value={association}
              onChange={(e) => setAssociation(e.target.value)}
              className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
            >
 <option className="text-gray-400" value="">Select Association</option>
              {/* Add your firm type options here */}
              <option value="NAREDCO">NAREDCO</option>
              <option value="CREDAI">CREDAI</option>
              <option value="BAI">BAI</option>
              </select>
          </div>
          {/* City Input */}
          <div className="mb-4">
            <label htmlFor="city" className="block text-gray-700 font-bold mb-2">
              City
            </label>
            <input
              type="text"
              id="city"
              placeholder='Enter your City'
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
            />
          </div>
          {/* GST Input (Optional) */}
<div className="mb-4">
  <div className='flex flex-row'>
  <label htmlFor="gst" className="block text-gray-700 font-bold mb-2">
    GST 
  </label>
  <label className="text-gray-400">(Optional)</label>
  </div>
  <input
    type="text"
    id="gst"
    placeholder='Enter your GST Number '
    value={gst}
    onChange={(e) => {
      const gstValue = e.target.value.replace(/[^0-9a-zA-Z]/g, ''); // Allow numbers and letters
      if (gstValue.length <= 15) {
        setGst(gstValue);
      }
    }}
    className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
    maxLength={15}
  />
{gst && gst.length > 0 && gst.length < 15 ? (
  <p className="text-red-500 text-sm">GST must be exactly 15 digits.</p>
) : null}
</div>

          {/* PAN Input */}
          <div className="mb-4">
          <div className='flex flex-row'>
            <label htmlFor="pan" className="block text-gray-700 font-bold mb-2">
              PAN
            </label>
  <label className="text-gray-400">(Optional)</label>
  </div>
             <input
    type="text"
    id="pan"
    placeholder='Enter your PAN '
    value={pan}
    onChange={(e) => {
      const panValue = e.target.value.replace(/[^0-9a-zA-Z]/g, ''); // Allow numbers and letters
      if (panValue.length <= 10) {
        setPan(panValue);
      }
    }}
    className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
    maxLength={15}
  />
{pan && pan.length > 0 && pan.length < 10 ? (
  <p className="text-red-500 text-sm">PAN must be exactly 10 digits.</p>
) : null}
          </div>
          {/* Submit Button */}
          <div className="focus:outline-none flex justify-center space-x-8 items-center mt-6">
          <button
              className="border border-[#EB001B] hover:bg-[#EB001B] text-black hover:text-white text-md rounded-sm px-4 py-1 "
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="border border-[#EB001B] hover:bg-[#EB001B] text-black hover:text-white text-md rounded-sm px-4 py-1 "
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>

          {/* Success Popup */}
          {showSuccessPopup && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
              <div className="bg-white p-6 rounded-md shadow-lg">
                <h2 className="text-lg font-bold mb-2">Success!</h2>
                <p className="mb-4">Your profile has been updated successfully.</p>
                <div className='flex justify-end'>
                <button className="border border-[#EB001B] hover:bg-[#EB001B] text-black hover:text-white text-md rounded-sm px-4 py-1"
                 onClick={handleClosePopup}>
                  Close
                </button>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
}

export default Profile;