import React, { useState, useEffect } from "react";
import AssignCategoryModal from "./AssignCategoryModal";
import { useNavigate } from 'react-router-dom';

function VendorList() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState({ id: '', name: '' });
  const [DeleteVendorAlert, setDeleteVendorAlert] = useState(false);
  const [ContDeleteVendorAlert, setContDeleteVendorAlert] = useState(false);
  const [VendorIDToDelete, setVendorIDToDelete]= useState('')
  const navigate = useNavigate();

 
  
 useEffect(()=>{
  const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
  const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
  if(storedLoginData&&Userdata){
    if(storedLoginData.token===Userdata.token){
    }else{
      navigate('/bb/admin')
     sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
    }
  }else{
    navigate('/bb/admin')
      sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
  }
})

 
  useEffect(() => {
    
    getAllVendors();
   // getAllProductCategoriesAssignedToVendor();
  }, []);

  const getAllVendors = async () => {
    try {
      const token = sessionStorage.getItem('token');
      console.log(token)
      const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/get-all-vendors`;
      const response = await fetch(url,
        {
          method: 'GET',
          headers:{
            "Content-Type": "application/json",
            'x-token' : token,
          },
        }
      );
      if (response.ok) {
        const responseData = await response.json();
      console.log(responseData)
      // Reverse the order of the vendors array to display the latest created vendor at the top
      setVendors(responseData.reverse());
      }else{
        setVendors([]);
        console.error("Failed to fetch vendors")
        //throw new Error("Failed to fetch vendors");
      }
     
    } catch (error) {
      console.error('failed to get vendors')
      clearStorageAndRedirectToHome()
    }
  };

  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/bb/admin';
  }  

  const handleAssignCategory = (vendorId, vendorName) => {
    setSelectedVendor({ id: vendorId, name: vendorName });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedVendor({ id: '', name: '' });
    setIsModalOpen(false);
  };

  const handleDeleteVendor = async (vendorId) => {
    setDeleteVendorAlert('Are you sure want to Delete?');
    setVendorIDToDelete(vendorId);
  };

  const handleDeleteVendorData = async () => {
    
    const deleteVendorUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/delete-vendor?vendor_id=${VendorIDToDelete}`;
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(deleteVendorUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-token' : token 
          // Add any other headers as needed
        },
        
      });
  
      if (response.ok) {
        setDeleteVendorAlert(false)
      getAllVendors();
  
      }else{
        setDeleteVendorAlert(false)
        setContDeleteVendorAlert('Vendor is associated with data and cannot be deleted')
        console.error('Network response was not ok')
       // throw new Error('Network response was not ok');
      }
      
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      clearStorageAndRedirectToHome()
      // Handle errors such as displaying an error message or logging
    }
  };
  
  const handleNoDelete = () => {
    // Close confirmation modal
    setDeleteVendorAlert(false);
    // Reset productIdToDelete state
    setVendorIDToDelete('');
  };

  const handlecloseAllert= ()=>{
    setContDeleteVendorAlert(null);
    setDeleteVendorAlert(false);
    
  }

  return (
    <div className="overflow-y-scroll no-scrollbar w-full h-full mx-auto">
      <div className="max-w-screen-xl mx-auto ">

      {DeleteVendorAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{DeleteVendorAlert}</p>
      <div className="flex">
        <button className="text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4 mr-2" onClick={handleDeleteVendorData}>Yes</button>
        <button className="text-white bg-red-600 hover:bg-red-700 py-1 px-2 rounded-md mt-4" onClick={handleNoDelete}>No</button>
      </div>
    </div>
  </div>
)}

{ContDeleteVendorAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{ContDeleteVendorAlert}</p>
      <div className="flex">
        <button className="text-white bg-red-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handlecloseAllert}>OK</button>
      </div>
    </div>
  </div>
)}

        <div className="bg-white flex flex-col relative rounded-md  p-2 md:ml-10 md:mr-10 mx-2 md:mx-6 overflow-x-auto">
          {/* <div>
            <h1 className="text-2xl font-bold text-center">Vendor List</h1>
          </div> */}
          <div className="overflow-x-auto">
          {vendors.length > 0 ? (
            <table className="w-full border border-gray-300 mt-4">
              <thead className="border-b border-gray-300">
                <tr className="bg-[#D9D9DD] bg-opacity-[100%]">
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Vendor Name</th>
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center"> Gstin Number </th>
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Pan Number</th>
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">City</th>
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">State</th>                
                  <th className="text-blue-950 font-medium py-2 px-4 text-sm text-center">Action</th>
                </tr>
              </thead>
              <tbody className="border border-gray-300">
                {vendors.map((vendor, index) => (
                  <tr key={vendor._id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                    <td className="text-[#EB001B]  py-2 text-xs text-center cursor-pointer" onClick={() => navigate('/bb/admin/AdminHome/VendorProfile', { state: { vendor } })}> {vendor.name} </td>
                    <td className="text-blue-950 text-opacity-80 py-2 text-xs  text-center"> {vendor.gstin} </td>
                    <td className="text-blue-950 text-opacity-80 py-2 text-xs  text-center"> {vendor.pan}</td>
                    <td className="text-blue-950 text-opacity-80 py-2 text-xs  text-center"> {vendor.city} </td>
                    <td className="text-blue-950 text-opacity-80 py-2 text-xs  text-center"> {vendor.state} </td>
                    <td className="flex flex-row justify-center text-[#EB001B]  py-2 text-xs  text-center cursor-pointer">
                      <span className="py-2 text-xs px-4 " onClick={() => handleAssignCategory(vendor._id, vendor.name)}>Assign Product Category</span>
                      <span className="py-2 text-xs px-4 " onClick={() => handleDeleteVendor(vendor._id)}>Delete</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
             ) : (
              <p className="text-center">No vendor list available</p>
          )}
          </div>
          {isModalOpen && (<AssignCategoryModal vendorId={selectedVendor.id} vendorName={selectedVendor.name} onClose={handleCloseModal} />)}
        </div>
      </div>
    </div>
  );
}

export default VendorList;
