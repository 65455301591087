import React, { useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function ViewCustomerOrders({OrderData,setViewOrderPopUp}) {
const [CustomerData,setCustomerData]= useState([]);
const [CustomerOrderData,setCustomerOrderData]= useState();

useEffect(()=>{
   // setCustomerData(OrderData.customerData)
    setCustomerOrderData(OrderData.orderData)
    console.log(OrderData)
},[OrderData])

const handleClosePopup = () => {
    setViewOrderPopUp(false)
}

const convertUTCToIST = (dateString) => {
    // Parse the ISO string into a Date object
    const date = new Date(dateString);

    // Format the IST date and time as a string
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };

    return date.toLocaleString("en-IN", options);
  };


return(
    <div>
        {CustomerOrderData&&(
    <div className="fixed inset-0 z-40 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-10 rounded-md shadow-md relative z-50 flex flex-col items-center">
        <button className="absolute top-0 right-0 m-2" onClick={handleClosePopup}><FontAwesomeIcon icon={faTimes} /></button>
        <h1 className="text-xl font-bold text-center mb-4">{/* {selectedDetails.projectName} */}</h1>
        <div className="max-h-96 flex flex-col overflow-y-scroll no-scrollbar ">
        <table className="w-full">
            <tbody>
            <div className='border border-gray-400 p-4 relative mt-8'> 
      <p className="text-md font-bold text-gray-700 mb-2 absolute -top-4 bg-white px-2">Delivery Details</p>   
                {/* <tr>
                    <td className="font-semibold pr-4">Order ID:</td>
                    <td className='pl-10'>{CustomerOrderData.order_id} </td>
                </tr> */}
                {/* <tr>
                    <td className="font-semibold pr-4">Order Created Time :</td>
                    <td className='pl-10'> {convertUTCToIST(CustomerOrderData.order_time)}</td>
                </tr> */}
                <tr>
                    <td className="font-semibold pr-4"> Delivery Address :</td>
                    <td className='pl-10'>
                    <div className=" flex flex-col space-y-1 ">{console.log(CustomerOrderData)}
                    
                        <p>{CustomerOrderData.address && CustomerOrderData.address.location && CustomerOrderData.address.location ? CustomerOrderData.address.location:''}</p>
                        {/* <p>{CustomerOrderData.address.city + ',' +  CustomerOrderData.address.zip}</p>
                        <p>{CustomerOrderData.address.land_mark}</p> */}
                       
                        
                        </div>  
                    </td>
                </tr>
                </div>
        
     
            </tbody>
        </table>
      {/*   <table className="w-full">
            <tbody>
            <div className='border border-gray-400 p-4 relative mt-8'> 
      <p className="text-md font-bold text-gray-700 mb-2 absolute -top-4 bg-white px-2">Site Details</p>   
      {CustomerData.map((customer, index) => (
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <td className="font-semibold pr-4">Site Contact Name:</td>
                                                    <td className='pl-10'>{customer.siteContactName}</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-semibold pr-4">Site Contact Number :</td>
                                                    <td className='pl-10'> {customer.siteContactNo}</td>
                                                </tr>
                                                <tr>
                                                    <td className="font-semibold pr-4">Site Address :</td>
                                                    <td className='pl-10'> {customer.siteAddress} </td>
                                                </tr>
                                               
                                            </React.Fragment>
                                        ))} 
               
               
               
                </div> 
            
               
     
             </tbody>
        </table> */} 
        </div>
    </div>
    </div>
        )}
    
    </div>
)
}

export default ViewCustomerOrders;