import { faImage, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, json } from 'react-router-dom';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { MdAddLocationAlt , MdMyLocation } from 'react-icons/md';
let currentInfoWindow = null;

function ConfirmOrder() {
    const navigate = useNavigate();
    const location = useLocation(); // Import useLocation hook
    const [data, setData] = useState(null);
    const [OffersData, setOffersData] = useState(null);
    console.log(OffersData);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
    const { quotationid , ProductCategoryName } = location.state || [];
    console.log(ProductCategoryName);
    const [totalPriceSum, setTotalPriceSum] = useState(0); // State for total price sum
    const [showPopup, setShowPopup] = useState(false);
   /*  const state = [ 'Select State','Telangana', 'Andhra Pradesh'];
    const city = [ 'Select City','Hyderabad', 'Vijayawada']; */
    const [projects, setProjects] = useState([]);
    const [displayedProjects, setDisplayedProjects] = useState([]);
    const [mobileNumber, setMobileNumber] = useState('');
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [streetAddress, setStreetAddress] = useState('');
    const [landMark,setlandMark]= useState('')
    const states = ['Select State', 'Telangana', 'Andhra Pradesh'];
    const cities = {
        Telangana: ['Select City', 'Hyderabad'],
        'Andhra Pradesh': ['Select City', 'Vijayawada']
    };
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedState, setSelectedState] = useState('Select State');
    const [selectedCity, setSelectedCity] = useState('Select city');
    const [availableCities, setAvailableCities] = useState(['Select City']);
    const [ savedProjectsIds , setSavedProjectsIds] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [selectedProject, setSelectedProject] = useState(null);
    const [pincode, setPincode] = useState('');
    const [changeAddress, setChangeAddress] = useState(false);
    const [address, setAddress] = useState('');
    const [coordinates, setCoordinates] = useState({lat: 16.7126 , lng: 71.8056});
    const [locations,setLocations]=useState([{ProjectName:'',BuilderName:''}])

    
    useEffect(() => {
        async function fetchData() {
            try {
              const token = sessionStorage.getItem('token');

                const response = await fetch(`${backendApiUrl}/customer/quotation/get-quotation-offers-groupData-and-productData-for-order?quotation_id=${quotationid}`,{
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                  },
                  });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                console.log(result.quotation.project_ids);
                setData(result.quotation);
                setOffersData(result.OffersData);
                setSavedProjectsIds(result.quotation.project_ids);
                fetchProjects(result.quotation.project_ids);
                setLoading(false);
            } catch (error) {
                setError(error);
                clearStorageAndRedirectToHome()
            } /* finally {
                setLoading(false);
            } */
        }

        fetchData();
    }, [quotationid, backendApiUrl]);

    function clearStorageAndRedirectToHome() {
      navigate("/bb/ErrorPage");
    }

    useEffect(() => {
        if (data && Array.isArray(data)) { // Add this conditional check
            let sum = 0;
            data.forEach(item => {
                item.quotation.quotationData.forEach(qd => {
                    const product = item.products.find(p => p._id === qd.product_id);
                    const price = calculatePrice(product, item);
                    const totalPrice = qd.count * parseFloat(price);
                    sum += totalPrice;
                });
            });
            setTotalPriceSum(sum.toFixed(2)); // Set the total price sum
        }
    }, [data]);
    

    useEffect(() => {
        // Retrieve mobile number from local storage
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData && userData.mobile_number) {
            setMobileNumber(userData.mobile_number);
        }
    }, []);

    useEffect(() => {
      if (showPopup && projects.length > 0) {
        setSelectedProject(projects[0]._id); // Select the first project by default
      }
    }, [showPopup, projects]);

        const fetchProjects = async (QuotationProjectIds) => {
          const userData = JSON.parse(localStorage.getItem('userData'));
            try {
              const token = sessionStorage.getItem('token');

                const GetProjects = `${backendApiUrl}/customer/get-projects-by-customer?customer_id=${userData._id}`;
                const response = await fetch(GetProjects, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'x-token':token
                    },
                });

                if (response.ok) {
                  const projectsData = await response.json();
                  const requiredprojects = [];
                  QuotationProjectIds.forEach((id) => {
                    const project = projectsData.customerProjects.filter((p) => p._id === id)[0];
                    requiredprojects.push(project);
                  });
                  setProjects(requiredprojects);
                  console.log(requiredprojects);
                  setDisplayedProjects(requiredprojects);
                  setLoading(false);
                } else {
                    console.error('Failed to fetch projects:', response.statusText);
                }
            } catch (error) {
                console.error('Failed to fetch projects:', error);
                clearStorageAndRedirectToHome()
            } /* finally {
                setLoading(false);
            } */
        };

    const calculatePrice = (product, quotation) => {
        const Quotationdata = quotation.quotation.quotationData;
        const quotationArray = Quotationdata.find(p => p.product_id === product._id);
        const offerId = quotationArray.offer_id;

        const offer = quotation.offers.find(p => p._id === offerId);
        if (!product || !offer) {
            return 'Price Not Available';
        }
        const basePrice = product.product_price || 0;
        const discount = offer.discount || 0;
        const gst = offer.gst || 0;

        let netPrice = parseFloat(basePrice);

        // Apply discount
        netPrice -= netPrice * (discount / 100);

        // Apply GST
        netPrice *= 1 + gst / 100;
        return netPrice.toFixed(2);
    };



    const handleClosePopup = () => {
      setShowPopup(false);
      setChangeAddress(false);
  }

  const handleConfirmOrder = () => {
      setShowPopup(true);
  }

  const handleChangeAddress = (newAddress) => {
      setAddress(newAddress);
    };
  
    const handleSelect = async (selectedAddress) => {
      try {
        const results = await geocodeByAddress(selectedAddress);
        const latLng = await getLatLng(results[0]);
        setCoordinates(latLng);
                 setAddress(selectedAddress);
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    const handleProjectRadioChangeWrapper = (project) => {
      setSelectedProject(project._id);
      setAddress(project.siteAddress || '');
      setCoordinates({ lat: Number(project.latitude) || '', lng: Number(project.longitude) || '' });
      handleProjectRadioChange(project);
    };

  const handleProjectRadioChange = (project) => {
      setSelectedProject(project._id);
      setStreetAddress(project.siteAddress || '');
      setSelectedState(project.state || '');
      setSelectedCity(project.city || '');
      setPincode(project.pincode || '');
      setChangeAddress(false); // Exit address mode when a new project is selected
  };

  const handleAddNewAddress = async (project) => {
    const results = await geocodeByAddress(project.siteAddress);
    const latLng = await getLatLng(results[0]);
      setSelectedProject(project._id);
      setChangeAddress(true);
     
      setCoordinates(latLng);
  };


  const handleSaveClick = async (ProjectId) => {
    const formData = new FormData();
    formData.append('siteAddress', address);
    formData.append('latitude', coordinates.lat);
    formData.append('longitude',coordinates.lng);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/projects/update-project?projectId=${ProjectId}`, {
        method: 'PUT',
        headers: {
          'x-token': token,
        },
        body: formData,
      });
  
      if (response.ok) {
      // Here you can save the updated address details to your state or backend
      setChangeAddress(false);
      fetchProjects(savedProjectsIds);
      } else {
        ////console.error('Failed to post data to the backend:', response.status, response.statusText);
        // Handle errors or display an error message to the user
      }
    } catch (error) {
      clearStorageAndRedirectToHome()
    }

  };

  const handleCancelClick = () => {
      // Here you can save the updated address details to your state or backend
      setChangeAddress(false);
  };
  

const handlePlaceOrder = async () => {
    const user = JSON.parse(localStorage.getItem('userData'));
    const OrderData = {
      quotation_ids: quotationid,  // Make sure `quotationid` is defined and in scope
      customer_id: user._id,
      address: {
        location: address,  // Make sure `streetAddress` is defined and in scope
       }
    };
  
    const saveOrderUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/Quotation-Order/save-customer-quotation-Order`;
  
    try {
      const token = sessionStorage.getItem('token');

      const response = await fetch(saveOrderUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token':token
        },
        body: JSON.stringify(OrderData),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Order placed successfully:', data);
        navigate('/bb/user/Myquotations')// Handle successful order placement, e.g., redirect or show a success message
      } else {
        console.error(`Failed to place order: ${response.status} ${response.statusText}`);
        // Handle the error response
      }
    } catch (error) {
      console.error('An error occurred while placing the order:', error);
      // Handle the error
      clearStorageAndRedirectToHome()
    }
  };

  const calculateTotalValue = (parentValue) => {
    if (typeof parentValue === 'object') {
      return Object.values(parentValue)
        .filter(value => value && value.value && !isNaN(value.value))
        .reduce((acc, val) => acc + parseFloat(val.value), 0).toFixed(2);
    }
    return parentValue;
  };

  const handleBack = () =>{
    navigate(-1);
  }

  const handleAddPriceAndUom = (quotation) => {
    console.log(quotation);
    let totalSum = 0; // Initialize the total sum
  
    const extractOfferIds = (data) => {
      for (const key in data) {
        if (data[key] && typeof data[key] === 'object') {
          if (data[key].offerid) {
            const Totalprice = calculateTotalPrice(data[key].netPrice, data[key].value, data[key].count, data[key].UOM);
            totalSum += Totalprice; // Add the Totalprice to the running total
          }
          extractOfferIds(data[key]);
        }
      }
    };
  
    // Start extracting offer ids
    extractOfferIds(quotation);
    
    // Return the final total sum
    return totalSum.toFixed(2);
  };
  
  const calculateTotalPrice = (netPrice, area, numbers, UOM) => {
    let totalprice = 0;
    
    if (UOM === 'No') {
      totalprice = parseFloat(netPrice) * parseFloat(numbers);
    } else if (UOM === 'Sq.ft') {
      totalprice = parseFloat(netPrice) * parseFloat(area);
    }
    
    return totalprice;
  };

  
  useEffect(()=>{  
    if (coordinates

    ) {
      renderMap()
    }             
  },[coordinates])

  const renderMap = async (Coordinates) =>  {
    let markersAdded = false; // Flag to check if any markers are added
    if (!coordinates || !coordinates.lat || !coordinates.lng) {
      return; // Coordinates not set yet, do not render map
    }
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: coordinates.lat, lng: coordinates.lng },
      zoom: 8
    });
    const results = await geocodeByAddress(`${coordinates.lat},${coordinates.lng}`);
    const address = results[0].formatted_address;

    // Set the address in the state to display in the search field
    setAddress(address);
  
    // Add click event listener to the map
    map.addListener('click', async (event) => {
      try {
        const latLng = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };

  
        // Reverse geocode to get location name
        const results = await geocodeByAddress(`${latLng.lat},${latLng.lng}`);
        const address = results[0].formatted_address;
        // Set the address in the state to display in the search field
        setAddress(address);
  
        // Update the coordinates state
        setCoordinates(latLng);
      } catch (error) {
        console.error('Error:', error);
      }
    });
  
    locations.forEach(location => {
      const marker = new window.google.maps.Marker({
        position: { lat: coordinates.lat, lng: coordinates.lng },
        map,
        title: location.ProjectName
      });
      markersAdded = true; // Markers are added
  
      // Create info window for each marker
      const infoWindow = new window.google.maps.InfoWindow({
        content: ` <div class="text-xs p-1 text-red-700"><strong>Project:</strong> ${location.ProjectName}<br><strong>Builder:</strong>${location.BuilderName}<br><strong>Contact:</strong></div>`
      });
  
      // Add click event listener to marker
      marker.addListener('click', () => {
        // If there is a currently open info window, close it
        if (currentInfoWindow) {
          currentInfoWindow.close();
        }
        
        // If the clicked marker's info window is not already open, open it
        if (currentInfoWindow !== infoWindow) {
          infoWindow.open(map, marker);
          currentInfoWindow = infoWindow;
        } else {
          // If the clicked marker's info window is already open, set currentInfoWindow to null
          currentInfoWindow = null;
        }
      });
    });
  
    // If no markers are added, center the map on default coordinates
    if (!markersAdded) {
      map.setCenter(new window.google.maps.LatLng(Coordinates.lat, Coordinates.lng));
    }
  };

  const fetchLocation = async () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by your browser');
      return;
    }
    try {
      // Options to improve accuracy
      const options = {
        enableHighAccuracy: true,  // Use GPS if available
        timeout: 10000,            // Maximum time to wait for a response
        maximumAge: 0              // Do not use cached position
      };
  
      // Attempt to get the user's current position
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
      });
  
      // If successful, update the coordinates state
      setCoordinates({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
     
      // Reverse geocode to get location name
      const results = await geocodeByAddress(`${position.coords.latitude},${position.coords.longitude}`);
      const address = results[0].formatted_address;
  
      // Set the address in the state to display in the search field
      setAddress(address);
    } catch (error) {
      if (error.code === error.PERMISSION_DENIED) {
        console.error('User denied the request for Geolocation.');
      } else if (error.code === error.POSITION_UNAVAILABLE) {
        console.error('Location information is unavailable.');
      } else if (error.code === error.TIMEOUT) {
        console.error('The request to get user location timed out.');
      } else {
        console.error('An unknown error occurred:', error);
      }
    }
  }; 


    return (
      <div className="p-2 rounded-md justify-start items-start w-full mt-5">
      <div className="rounded-md justify-start items-start overflow-y-auto">
        <div className="p-1 flex flex-col justify-between items-center">
          <div className='flex-grow md:w-2/3 sm:w-full border-gray-400 border-b flex justify-center'>
            <h1 className="text-lg font-semibold">Order Details</h1>
          </div>
          <div className='flex flex-col md:w-2/3 sm:w-full'>
            {data && data.quotationData && Object.entries(data.quotationData).map(([parentKey, parentValue]) => (
              <div key={parentKey} className="mb-4 w-full">
                {/* Check if parentValue exists and has any child with offerid */}
                {parentValue && Object.values(parentValue).some(childValue => childValue && childValue.offerid) && (
                  <div className='space-x-5 border-b font-semibold flex flex-row justify-center items-center mt-2'>
                    <div className='flex flex-row items-center w-full justify-center'>
                      <h2 className='md:text-xl lg:text-xl sm:text-lg font-bold mr-2'>{parentKey}</h2>
                      <p className="text-xl font-semibold">{calculateTotalValue(parentValue)}</p>
                      <p className='md:text-sm lg:text-sm sm:text-sm pt-1'>Sq.ft</p>
                    </div>
                  </div>
                )}
{parentValue && typeof parentValue === 'object' && Object.keys(parentValue).length >= 2 && parentValue.hasOwnProperty('value') && (
  <div className="">
    {OffersData && parentValue.offerid && (
      <div className='flex flex-col w-full'>
        <div className='flex flex-row justify-between mt-2'>
          <div className='flex flex-col'>
            <p className='md:text-lg lg:text-lg sm:text-xl font-semibold'>{parentKey}</p>
          </div>
        </div>

        <div className='flex flex-row mt-4 w-full space-x-2'>
          <div className='flex flex-row items-center mr-4'>
            <div className='w-24 h-24 overflow-hidden border border-gray-300 flex flex-col items-center justify-center'>
              <FontAwesomeIcon className='w-10 h-10 text-gray-700 opacity-80' icon={faImage} />
              <span className='text-xs text-center mt-2'>Design not selected</span>
            </div>
          </div>
          
          <div className='flex flex-col w-full'>
            <p className='text-lg font-semibold text-center whitespace-normal mb-2'>
              {OffersData.find(offer => offer.offerData._id === parentValue.offerid)?.productGroupData[0]?.product_group_name || 'Offer Not Found'}
            </p>
            {ProductCategoryName !== 'Tiles' && (
              <p className="text-lg mb-2 text-center">{ProductCategoryName}: {parentValue.count} No's</p>
            )}
            <p className="text-lg mb-2 text-center"><span className='font-semibold'>Area: </span> {parentValue.value} Sq.ft</p>
            <p className='text-lg whitespace-nowrap text-center mb-2'><span className='font-semibold'>Offer Price: </span> ₹{parentValue.netPrice}/<span className='text-xs'>{parentValue.UOM}</span></p>
            <div className=' '>
              {parentValue.UOM === 'No' ? (
                <p className='text-lg font-bold text-center'><span className='font-semibold'>Subtotal: </span> ₹{(parentValue.count * parentValue.netPrice).toFixed(2)}</p>
              ) : (
                <p className='text-lg font-bold text-center'><span className='font-semibold'>Subtotal: </span> ₹{(parentValue.value * parentValue.netPrice).toFixed(2)}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    )}
      </div>
    )}
    {parentValue && Object.entries(parentValue).map(([childKey, childValue]) => (
      <div key={childKey}  className=''>
          {childKey !== 'Dummy' && childValue && childValue.value && (
<div>
{OffersData && childValue.offerid && (
                  <div className={`flex flex-col w-full ${childValue ? 'flex flex-col border-b' : ''}`}>
                    <div className='flex flex-row justify-between mt-2'>
                      <div className="flex flex-col">
                        <p className='md:text-lg lg:text-lg sm:text-xl font-semibold'>{childKey}</p>
                      </div>
                    </div>
            <div className='flex flex-row mt-4 w-full space-x-2'>
          <div className='flex flex-row items-center mr-4'>
            <div className='w-24 h-24 overflow-hidden border border-gray-300 flex flex-col items-center justify-center'>
  <FontAwesomeIcon className='w-10 h-10 text-gray-700 opacity-80' icon={faImage} />
  <span className='text-xs text-center mt-2'>Design not selected</span>
</div>
</div>

<div className='flex flex-col w-full'>
<p className='text-lg font-semibold text-center whitespace-normal mb-2'>
  {OffersData.find(offer => offer.offerData._id === childValue.offerid)?.productGroupData[0]?.product_group_name || 'Offer Not Found'}
     </p>
   
     {ProductCategoryName !== 'Tiles' && (
    <p className="text-lg">{ProductCategoryName}: {childValue.count} No's</p>
)}
 <p className="text-lg mb-2 text-center"><span className='font-semibold'>Area: </span> {childValue.value} Sq.ft</p>
 <p className='text-lg whitespace-nowrap text-center mb-2'><span className='font-semibold'>Offer Price: </span> ₹{childValue.netPrice}/<span className="text-xs">{childValue.UOM}</span></p>
 <div className=''> 
            {childValue.UOM === 'No' ? ( 
            <p className='text-lg font-bold text-center'><span className='font-semibold'>Subtotal: </span> ₹{(childValue.count * childValue.netPrice).toFixed(2)}</p> 
            ) : (
               <p className='text-lg font-bold text-center'><span className='font-semibold'>Subtotal: </span> ₹{(childValue.value * childValue.netPrice).toFixed(2)}</p> )} 
               </div>
</div>

</div>
          
            
          
        
       {/*  <div className='flex justify-between flex-row'> */}
           {/* <div className=' w-20 h-20 overflow-hidden flex items-center justify-center'>
     <img className='max-w-full max-h-full object-contain'
  src={ImageUrl + OffersData.find(offer => offer.offerData._id === childValue.offerid)?.productGroupData[0]?.product_group_image || 'Offer Not Found'}
  alt="Offer Image"
/>      
</div>   */}

{/* <div className="flex items-center justify-center w-2/3 flex-col"> */}
{/* <div className="flex justify-center">
   <p className="text-lg font-semibold text-center whitespace-normal">
  {OffersData.find(offer => offer.offerData._id === childValue.offerid)?.productGroupData[0]?.product_group_name || 'Offer Not Found'}
     </p>
     </div> */}
     {/* </div> */}
                                       
       {/*  </div> */}
       
</div>
)}
</div>
        )}
      </div>
    ))}
    
  </div>
  
))}

 {/*  {data && data.quotationData && data.quotationData['Total Tiles Area'] && data.quotationData['Total Tiles Area'].Dummy && (
    <div className='font-semibold flex justify-end items-center w-full pr-16'>
      <p>Total: ₹{data.quotationData['Total Tiles Area'].Dummy.value}</p>
      <p className='text-xs pt-1 ml-1'>Sq.ft</p>
    </div>
  )} */}
</div>

<div className="border-t border-gray-400 py-1 md:w-2/3 sm:w-full" />

<div className='md:flex md:w-2/3 sm:w-full md:justify-between sm:flex sm:justify-center sm:space-x-10'>
  <div className=''>
    {data && data.quotationData && Object.entries(data.quotationData).map(([parentKey, parentValue]) => {
      return (
        <div key={parentKey}>
          {Object.entries(parentValue).map(([childKey, childValue]) => (
            <div key={childKey}>
              {childKey === 'Dummy' && (
                <div className="flex justify-center items-center ">
                   <p className='text-center flex flex-col'>
        <span className='text-lg font-semibold'>{parentKey} : </span>
        <span className="text-lg "> {childValue.value} Sq.ft</span>
      </p>
                </div>
              )}
            </div>
          ))}
        </div>
      )
    })}
  </div>
  <div className='Overall Total'>
  {data && data.quotationData &&
   <p className='text-lg text-center flex flex-col'>
   <span className="text-lg font-semibold">Total Price : </span>
   <span className="text-lg"> ₹
   {new Intl.NumberFormat('en-IN').format (handleAddPriceAndUom(data.quotationData))}
   </span>
 </p>
}
  </div>
</div>


                <div className='flex justify-center space-x-4 mb-4 mt-6 '>
                <button className='rounded-sm text-black hover:text-white border border-[#EB001B] px-2 flex justify-center hover:bg-[#EB001B] ' onClick={() => handleConfirmOrder()}>Confirm Order</button>
                <button className='rounded-sm text-black hover:text-white border border-[#EB001B] px-2 flex justify-center hover:bg-[#EB001B] ' onClick={handleBack}>Back</button>
                </div>
        </div>
           </div>
           {showPopup && (
            <div className="fixed z-50 inset-0 flex items-center justify-center">
            {/* Background overlay (blurred) */}
            <div className="absolute inset-0 bg-black opacity-30"></div>

            <div className="bg-white md:p-10 sm:p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
                <button className="absolute top-0 right-0 m-2" onClick={handleClosePopup}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <h1 className="md:text-xl sm:text-lg font-bold text-center mb-4">Delivery Address</h1>
                <div className="max-h-96 flex overflow-y-scroll no-scrollbar">
                    {/* Popup content goes here */}
                    <div className="flex flex-col">
                        <div className='mb-6'>
                        {projects.map(filteredProject => (
        <div key={filteredProject._id} className="flex flex-col items-start mb-4">
          <div className="flex items-center mb-2">
            <input
              type="radio"
              id={filteredProject._id}
              name="project"
              checked={selectedProject === filteredProject._id}
              onChange={() => handleProjectRadioChangeWrapper(filteredProject)}
            />
            <label htmlFor={filteredProject._id} className="ml-2 md:text-md sm:text-sm font-bold">{filteredProject.projectName}</label>
          </div>
          <div className='w-full flex flex-col'>
            <div className="ml-6 md:text-md sm:text-sm text-gray-700">
              <p>{filteredProject.siteAddress || "Address not available"}</p>
            </div>
            <div className='flex justify-end'>
              <button onClick={(e) => handleAddNewAddress(filteredProject)} className='text-blue-600 hover:underline text-xs'>
                Change Address
              </button>
            </div>
          </div>
          {changeAddress && selectedProject === filteredProject._id && (
            <div className='w-full border border-gray-400 md:p-4 sm:p-2 mt-4 relative'>
              <div className="flex flex-col space-y-4">
                {coordinates && (             
                <div className="w-full">
                  <p className="block text-gray-700 font-bold mb-2">Address</p>
                  <PlacesAutocomplete value={address} onChange={handleChangeAddress} onSelect={handleSelect}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <div className="flex">
                          <input
                            {...getInputProps({
                              placeholder: 'Search Places...',
                              className: 'location-search-input appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring'
                            })}
                          />
                         </div>
                        <div className="autocomplete-dropdown-container bg-white rounded shadow-lg">
                          {loading && <div className="p-2">Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active p-2 bg-blue-500 text-white'
                              : 'suggestion-item p-2';
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, { className })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>  
                  <div className='flex justify-center'>
            <button
          onClick={fetchLocation}
          className="flex items-center   text-blue-600 mt-2   rounded  hover:underline"
        >
          <MdMyLocation className="text-xl mr-2" />
          Use Current Location
        </button>
        </div>             
                  <div className="flex justify-center items-center mt-2 " style={{ height: '200px', width: '100%' }}>
                    <div id="map" className="w-full h-full border rounded-md"></div>
                  </div>
                </div>
                  )}
              </div>
              <div className="flex justify-end mt-2">
                <button className="mr-1 rounded-sm text-black border border-[#EB001B] px-1 py-1 hover:bg-[#EB001B] hover:text-white text-md sm:text-sm" 
                onClick={handleCancelClick}>Cancel</button>
                <button className="rounded-sm text-white  bg-[#EB001B] px-1 py-1 hover:bg-red-800 hover:text-white text-md sm:text-sm" 
                onClick={(e)=> handleSaveClick(filteredProject._id)}>Save</button>
              </div>
            </div>
          )}
        </div>
      ))}
                        </div>
                    </div>
                </div>
                <div className='flex justify-center mt-3'>
                    <button className='rounded-sm text-black border border-[#EB001B] px-2 py-1 flex justify-center hover:bg-[#EB001B] hover:text-white text-md sm:text-sm' onClick={handlePlaceOrder}>Place your order</button>
                </div>
            </div>
        </div>
           )}
        </div>
    );
}

export default ConfirmOrder;
