import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function AggregatedProjectReports() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [aggregatedProjectReport, setAggregatedProjectReport] = useState(null);
  const location = useLocation();
  const [projectIds, setProjectIds] = useState([]);
  const [projectsNames, setProjectNames] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (location.state && location.state.projectIds) {
      setProjectIds(location.state.projectIds);
      setProjectNames(location.state.projectsNames.map(name => name.toUpperCase()));
    }
  }, [location.state]);

  useEffect(() => {
    async function fetchProjectReport() {
      try {
        const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/computed_reports/generate-projects-report?projectIds=${projectIds}`);
        const data = await response.json();
        const cleanedData = removeDummyEntries(data); // Clean the data
        setAggregatedProjectReport(cleanedData);
        console.log(aggregatedProjectReport);
        setError(true);
      } catch (error) {
        setError(true);
        //clearStorageAndRedirectToHome();
      } finally {
        setLoading(false);
      }
    }

    if (projectIds.length > 0) {
      fetchProjectReport();
    }
  }, [projectIds]);

  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/measurit';
  }

  useEffect(() => {
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData')) || null;
    const Userdata = JSON.parse(sessionStorage.getItem('userData')) || null;
    if (storedLoginData && Userdata) {
      if (storedLoginData.token === Userdata.token) {
        //console.log('profile matched')
      } else {
        //console.log('logout')
        navigate('/measurit');
        sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
      }
    } else {
      navigate('/measurit');
      sessionStorage.removeItem('userRole');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('userData');
    }
  }, [navigate]);

  const removeDummyEntries = (data) => {
    const cleanedData = {
      project: {},
      doors: {},
      windows: {},
      tiles: {}
    };

    for (let key in data) {
      const value = data[key];

      if (key === 'Doors' || key === 'UPVC Windows' || key === 'Tiles') {
        cleanedData[key.toLowerCase()] = value;
      } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        const cleanedChildData = removeDummyEntries(value);
        if (Object.keys(cleanedChildData).length > 0) {
          cleanedData.project[key] = cleanedChildData;
        }
      } else if (key !== 'Dummy') {
        cleanedData.project[key] = value;
      }
    }

    return cleanedData;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen"><div className="w-full text-center" >Error occured while fetching reports.</div></div>;
  }

  const sumAreas = (data) => {
    let total = 0;
    for (let key in data) {
      const value = data[key];
      if (Array.isArray(value)) {
        total += parseFloat(value[0]) || 0;
      } else if (typeof value === 'object' && value !== null) {
        total += sumAreas(value);
      }
    }
    return total;
  };

  const sumCounts = (data) => {
    let total = 0;
    for (let key in data) {
      const value = data[key];
      if (Array.isArray(value) && value.length > 1) {
        total += parseFloat(value[1]) || 0;
      } else if (typeof value === 'object' && value !== null) {
        total += sumCounts(value);
      }
    }
    return total;
  };

  const filterData = (data) => {
    const cleanedData = {};
    for (let key in data) {
      if (key !== 'Total Doors Area' && key !== 'Dummy' && key !== 'Total Windows Area') {
        cleanedData[key] = data[key];
      }
    }
    return cleanedData;
  };

  const renderTableRows = (data, parentKey = '', includeCount = false) => {
    return Object.keys(data).map((key) => {
      const value = data[key];
      if (typeof value === 'object' && !Array.isArray(value)) {
        const totalArea = sumAreas(value).toFixed(2);
        const totalCount = includeCount ? sumCounts(value) : null;
        return (
          <React.Fragment key={`${parentKey}-${key}`}>
            <tr>
              <td className="px-4 py-2 border border-gray-400"><strong>{key}</strong></td>
              <td className="px-4 py-2 border border-gray-400"><strong>{totalArea}</strong></td>
              {includeCount && <td className="px-4 py-2 border border-gray-400"><strong>{totalCount}</strong></td>}
            </tr>
            {renderTableRows(value, `${parentKey}-${key}`, includeCount)}
          </React.Fragment>
        );
      } else if (Array.isArray(value)) {
        return (
          <tr key={`${parentKey}-${key}`}>
            <td className="px-4 py-2 border border-gray-400">{key}</td>
            <td className="px-4 py-2 border border-gray-400">{value[0]}</td>
            {includeCount && <td className="px-4 py-2 border border-gray-400">{value[1]}</td>}
          </tr>
        );
      } else {
        return (
          <tr key={`${parentKey}-${key}`}>
            <td className="px-4 py-2 border border-gray-400">{key}</td>
            <td className="px-4 py-2 border border-gray-400" colSpan={includeCount ? 2 : 1}>{value}</td>
          </tr>
        );
      }
    });
  };

  const renderProjectTable = () => {
    if (!aggregatedProjectReport.project || Object.keys(aggregatedProjectReport.project).length === 0) {
      return null; // If no data, return null to hide the table
    }

    return (
    <table className="table-auto border-collapse border border-gray-400">
      <thead>
        <tr>
          <th className="px-4 py-2 border border-gray-400">Projects</th>
          <th className="px-4 py-2 border border-gray-400">
            {projectsNames.join(', ')}
          </th>
        </tr>
      </thead>
      <tbody>
        {renderTableRows(aggregatedProjectReport.project, 'project')}
      </tbody>
    </table>
  );
};

const renderDoorsTable = () => {
  const filteredDoors = filterData(aggregatedProjectReport.doors);
  if (Object.keys(filteredDoors).length === 0) {
    return null; // If no data, return null to hide the table
  }
    return (
      <table className="table-auto border-collapse border border-gray-400">
        <thead>
          <tr>
            <th className="px-4 py-2 border border-gray-400">Doors</th>
            <th className="px-4 py-2 border border-gray-400">Area</th>
            <th className="px-4 py-2 border border-gray-400">Count</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows(filteredDoors, 'doors', true)}
        </tbody>
      </table>
    );
  };

  const renderWindowsTable = () => {
    const filteredWindows = filterData(aggregatedProjectReport['upvc windows']);
    if (Object.keys(filteredWindows).length === 0) {
      return null; // If no data, return null to hide the table
    }
    return (
      <table className="table-auto border-collapse border border-gray-400">
        <thead>
          <tr>
            <th className="px-4 py-2 border border-gray-400">Windows</th>
            <th className="px-4 py-2 border border-gray-400">Area</th>
            <th className="px-4 py-2 border border-gray-400">Count</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows(filteredWindows, 'windows', true)}
        </tbody>
      </table>
    );
  };

  const renderTilesTable = () => {
    const filterTilesData = (data) => {
      const cleanedData = {};
      for (let key in data) {
        if (key !== 'Total Tiles Area' && key !== 'Dummy') {
          cleanedData[key] = data[key];
        }
      }
      return cleanedData;
    };

    const filteredTiles = filterTilesData(aggregatedProjectReport.tiles);
    if (Object.keys(filteredTiles).length === 0) {
      return null; // If no data, return null to hide the table
    }

    return (
      <table className="table-auto border-collapse border border-gray-400">
        <thead>
          <tr>
            <th className="px-4 py-2 border border-gray-400">Tiles</th>
            <th className="px-4 py-2 border border-gray-400">Area</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows(filteredTiles, 'tiles')}
        </tbody>
      </table>
    );
  };

  return (
    <div className="p-10 overflow-x-scroll no-scrollbar">
      <div className="flex justify-center">
        {renderProjectTable()}
      </div>
      <div className="flex justify-center mt-10">
        {renderDoorsTable()}
      </div>
      <div className="flex justify-center mt-10">
        {renderWindowsTable()}
      </div>
      <div className="flex justify-center mt-10">
        {renderTilesTable()}
      </div>
    </div>
  );
}

export default AggregatedProjectReports;
