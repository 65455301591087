import { faCamera } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect , useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApplyProductAttributes from './applyProductAttributes'
function CreateProduct() {
  const navigate = useNavigate();

  const [productCategories, setProductCategories] = useState([]);
  const [selectedItems, setSelectedItems] = useState('');
  const [vendors, setVendors] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState('');
  const [productGroups, setProductGroups] = useState([]);
  const [selectedProductGroups, setSelectedProductGroups] = useState('');
  const [productName, setProductName] = useState('');
  const [specifications, setspecifications] = useState([]);
  const [specificationName, setspecificationName] = useState('');
  const [specificationValue, setspecificationValue] = useState('');
  const [allspecificationNames, setAllspecificationNames] = useState([]);
  const [filteredspecificationNames, setFilteredspecificationNames] = useState([]);
  const [SubmitCreateproductAlert, setSubmitCreateproductAlert] = useState(false);
  const [SubmitCreateproductForEmptyAlert, setSubmitCreateproductForEmptyAlert] = useState(false);
  const [dataOfspecification,setDataOfspecification] = useState('');
  const urlOfproductCategories = process.env.REACT_APP_FORE_BRICKBUCKET + '/Admin/get-all-productCategorys';
  const bulkUploadURL = process.env.REACT_APP_FORE_BRICKBUCKET + '/Admin/product/bulk-upload-of-products';
  const [description, setDescription] = useState('');
  const [unitOfMeasurement, setUnitOfMeasurement] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState(null);
  const [displayedImage, setDisplayedImage] = useState(null);
  const [SpecificationsAllert,setSpecificationsAllert] = useState(null)
  const [showUploadOptions, setShowUploadOptions] = useState(true);
  const [showSingleUpload, setShowSingleUpload] = useState(false);
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const zipInputRef = useRef(null);
  const [loading, setLoading] = useState(false); // New loading state variable
  const [selectedFolder, setSelectedFolder] = useState('');
  const [productNameError,setProductNameError]=useState(false)
  const [selecteVendorError,setselecteVendorError]=useState(false)
  const [selecteProductCategoryError,setSelecteProductCategoryError]= useState(false)
  const [ProductAttributesData, setProductAttributesData] = useState([]);
  const [attributeIds, setAttributeIds] = useState([]);
  const  [Attribute_Value_Ids, set_Attribute_Value_Ids] = useState();
  const [productSubCategories, setProductSubCategories] = useState([]);
  const [selectedProductSubCategory, setSelectedProductSubCategory] = useState('');
  const [unitOfMeasurementOptions, setUnitOfMeasurementOptions] = useState([]); 
  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;

  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
        navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })

  const handleProductSubCategoryDropdown = (value)=>{
    if(value===''){
      setSelectedProductSubCategory('');
      if(selectedItems&&selectedVendors){
        fetchProductGroupsByCategoryAndVendorId(selectedVendors)
      }else{
        setProductGroups([])
      }
    }else{
      setSelectedProductSubCategory(value);
      fetchProductGroupsByCategoryAndVendorIdAndSubcategory(value)
      
    }
  
  }

  const fetchProductGroupsByCategoryAndVendorIdAndSubcategory = async (product_sub_category_id) => {
    try {
      const token = sessionStorage.getItem('token');
      // Construct the URL with query parameters
      const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-groups/get-all-productGroups-by-productCategoryIdAndVendorId`;
      const queryParams = new URLSearchParams({
        product_category_id: selectedItems,
        vendor_id: selectedVendors,
      });
      const apiUrl = `${url}?${queryParams.toString()}`;
  
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
           'x-token' : token
        },
      });
  
      if (response.ok) {
        const data = await response.json();
         const GroupsUnderSubcategory= (data.productGroups.filter(p=>p.product_sub_category_id===product_sub_category_id))||[];
      setProductGroups(GroupsUnderSubcategory); 
        
      } else {
        // Set product groups to initial state if fetching fails
        setProductGroups([]);
        setSelectedProductGroups('');
      }
    } catch (error) {
      // Set product groups to initial state if an error occurs
      clearStorageAndRedirectToHome()
      setProductGroups([]);
      setSelectedProductGroups('');
    }
  };
  
  useEffect(() => {
    fetchUnitOfMeasurementOptions(); // Fetch UOM options on component mount
  }, []);

 // In your fetchUnitOfMeasurementOptions function:
 const fetchUnitOfMeasurementOptions = async () => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${backendApiUrl}/Admin/UOMs/get-all-UOMs`, {
      method: "GET",
      headers:{
        'x-token':token
      }
  });
    if (response.ok) {
      const data = await response.json();
      setUnitOfMeasurementOptions(data.uoms); // This is the correct way to store the array of UOMs
      console.log(data); 
    } else {
      console.error('Failed to fetch UOM options');
    }
  } catch (error) {
    console.error('Error while fetching UOM options:', error);
  }
};


  const fetchProductSubCategories = async (product_category_id) => {
    try {
        const getProductSubcategories = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-sub-category/get-productSubCategories-by-productCategoryId?product_category_id=${product_category_id}`;
        const token = sessionStorage.getItem('token');

        const response = await fetch(getProductSubcategories, {
            method: "GET",
            headers:{
              'x-token':token
            }
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setProductSubCategories(data.data);
        } else {
          setSelectedProductSubCategory('')
          setProductSubCategories([])
            console.error('Failed to fetch product subcategories', response.status, response.statusText);
        }
    } catch (error) {
        console.error('Error while fetching product subcategories:', error);
    }
};

  const handleattribute_values_dict = async () => {
    if (attributeIds) {
      // Create a new dictionary to store filter values
      let attribute_values_dict = {};

      // Populate the dictionary with filter values
      for (const selected_attribute of attributeIds) {
        const attribute_name_id = selected_attribute.attribute_name_id;
        const attribute_value_id = selected_attribute.attribute_value_id;

        for (const attribute_group of ProductAttributesData) {
          if (attribute_group.attribute_name_id === attribute_name_id) {
            for (const attribute_value of attribute_group.attribute_values) {
              if (attribute_value.attribute_value_id === attribute_value_id) {
                attribute_values_dict[attribute_name_id] = attribute_value.attribute_value;
              }
            }
          }
        }
      }

      // Create the product name
      const new_product_name = Object.values(attribute_values_dict).join('_');



      // Update the state with the new product name
    }
  };

useEffect(() => {
    handleattribute_values_dict(); // Call the function here
  }, [attributeIds]);

  const handleSingleUploadClick = () => {
    setShowUploadOptions(false); // Hide both upload options
    setShowSingleUpload(true); // Show single upload UI
  };

  const handleBulkUploadClick = () => {
    setShowUploadOptions(false); // Hide both upload options
    setShowBulkUpload (true);
  };

  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/bb/admin';
  }
  const handleBulkUpload = async () => {
    const zipFile = zipInputRef.current.files[0];

    if (!zipFile) {
       // console.error('No zip file selected');
        setSubmitCreateproductForEmptyAlert("Please Upload the zipfile")
        return;
    }

    if (!selectedProductGroups || !selectedVendors || !selectedItems) {
      // console.error('No zip file selected');
       setSubmitCreateproductForEmptyAlert("Please Upload the required fields.")
       return;
   }


    const formData = new FormData();
    formData.append('zipFile', zipFile);
    // Add other form data
    formData.append('product_group_id', selectedProductGroups);
    formData.append('vendor_id', selectedVendors);
    formData.append('product_category_id', selectedItems);

    setLoading(true); // Set loading to true when bulk upload begins

    try {
      const token = sessionStorage.getItem('token');
        const response = await fetch( bulkUploadURL, {
            method: 'POST',
            headers:{
              'x-token':token
            },
            body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          alert(data.message)
          console.log(data);
          navigate("/bb/admin/AdminHome/ViewProducts");
          // Handle success
        }else{
          console.error(`HTTP error! status: ${response.status}`)
          //throw new Error(`HTTP error! status: ${response.status}`);
        }

        
    } catch (error) {
      clearStorageAndRedirectToHome()
        console.error('There was a problem with the fetch operation:', error);
        // Handle error
    } finally {
        setLoading(false); // Set loading to false when bulk upload completes
    }
};


const handleUnitOfMeasurementChange = (e) => {
  setUnitOfMeasurement(e.target.value); // You might want to store the selected UOM id instead of just the value
};
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
    // No file selected, do something (e.g., show an error message or return early)
    return;
  }
    setImage(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setDisplayedImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const fetchProductCategories = async () => {
      
      try {
        const response = await fetch(urlOfproductCategories, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
             'x-token' : token
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProductCategories(data);
        } else {

        }
      } catch (error) {
        clearStorageAndRedirectToHome()
      }
    };

    fetchProductCategories();
    fetchspecifications();
  }, []);

  useEffect(() => {
    if (selectedItems) {
      fetchVendorsByCategory(selectedItems);
      
    } else {
      // Set vendors and product groups to initial state if no product category is selected
      setVendors([]);
      setSelectedVendors('');
      setProductGroups([]);
      setSelectedProductGroups('');
    }
  }, [selectedItems]);
  
  const fetchVendorsByCategory = async (categoryId) => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/get-vendorsBy-productCategoryId?product_category_id=${categoryId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
             'x-token' : token
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setVendors(data.vendors);
        setSelectedVendors('');
        setProductGroups([]);
        setSelectedProductGroups('');
      } else {

        // Set vendors to initial state if fetching fails
        setVendors([]);
        setSelectedVendors('');
      }
    } catch (error) {
      clearStorageAndRedirectToHome()
      // Set vendors to initial state if an error occurs
      setVendors([]);
      setSelectedVendors('');
    }
  };
  
  const handleChangeVendor=(value)=>{
    setselecteVendorError(false)
    setSelectedVendors(value)
    fetchProductGroupsByCategoryAndVendorId(value);
  }
  
  
  const fetchProductGroupsByCategoryAndVendorId = async (VendorId) => {
    try {
      const token = sessionStorage.getItem('token');
      // Construct the URL with query parameters
      const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-groups/get-all-productGroups-by-productCategoryIdAndVendorId`;
      const queryParams = new URLSearchParams({
        product_category_id: selectedItems,
        vendor_id: VendorId,
      });
      const apiUrl = `${url}?${queryParams.toString()}`;
  
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
           'x-token' : token
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setProductGroups(data.productGroups);
      } else {
        // Set product groups to initial state if fetching fails
        setProductGroups([]);
        setSelectedProductGroups('');
      }
    } catch (error) {
      // Set product groups to initial state if an error occurs
      clearStorageAndRedirectToHome()
      setProductGroups([]);
      setSelectedProductGroups('');
    }
  };
  

  const handleProductNameChange = (e) => {
    setProductNameError(false)
    const newValue = e.target.value;
    setProductName(newValue);
  };

  const handleAddSpecification = () => {
    if (specificationName && specificationValue) {
        setSpecificationsAllert(null);

        // Create a new specification object in the format { [specificationName]: specificationValue }
        const newSpecification = { [specificationName]: specificationValue };

        // Add the new specification to the existing specifications array
        setspecifications([...specifications, newSpecification]);

        // Clear input fields
        setspecificationName('');
        setspecificationValue('');
    }
};
  const handleCancel = () => {
    setShowSingleUpload(false);
    setShowUploadOptions(true);
    setShowBulkUpload(false);
  };

  const handleDeletespecification = (index) => {
    const updatedspecifications = specifications.filter((_, i) => i !== index);
    setspecifications(updatedspecifications);
  };

  const handlespecificationNameChange = (e) => {
    setSpecificationsAllert(null)
    const input = e.target.value;
    const filteredNames = allspecificationNames
      .filter((name) => name.toLowerCase().includes(input.toLowerCase()))
      .slice(0, 5);
    setFilteredspecificationNames(filteredNames);
    setspecificationName(input);
  };

  const handleSelectspecificationName = (name) => {
    setspecificationName(name);
    setFilteredspecificationNames([]);
  };

  const fetchspecifications = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(
        `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/specifications/get-specifications`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
             'x-token' : token
          },
        }
      );
      if (response.ok) {
        const dataOfspecification = await response.json();
        const specificationNames = dataOfspecification.map((specification) => specification.specification_name);
        setDataOfspecification(dataOfspecification);
        setAllspecificationNames(specificationNames);
        
      } else {

      }
    } catch (error) {
      clearStorageAndRedirectToHome()
    }
  };

  const handleSubmit = async () => {
    try {
      // Validate form data before submission
      if (!productName || !selectedVendors.length || !selectedItems.length) {
        console.log("Validation failed. Displaying alert...");
        setSubmitCreateproductForEmptyAlert('Please fill all the required fields.');
        return; // Stop execution if validation fails
    }
  
  
      if (!specifications.length) {
        setSpecificationsAllert('Add the specifications data.')
      //  throw new Error('Add the specifications data.');
      return;
      }

  
      const formData = new FormData();
      formData.append('product_group_id', selectedProductGroups);
      formData.append('vendor_id', selectedVendors);
      formData.append('product_category_id', selectedItems);
      formData.append('product_sub_category_id', selectedProductSubCategory);
      formData.append('product_name', productName);
      formData.append('product_description', description);
      formData.append('UOM', unitOfMeasurement);
      formData.append('product_price', price);
      formData.append('specifications', JSON.stringify(specifications));
      formData.append('folder_name', selectedFolder);
      // Check if an image is selected and add it to formData
      if (image) {
        formData.append('image', image);
        
        // Add the selectedFolder to formData only if it exists
        /* if (selectedFolder) {
          console.log(selectedFolder)
          formData.append('folder_name', selectedFolder);
        } else {
          alert('Please provide a valid folder name.');
          throw new Error('Invalid folder name.');
        } */
        
      }
      attributeIds.forEach((attribute) => {
        formData.append('attribute_value_ids', [attribute.attribute_value_id]);
      });
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product/create-product`, {
        method: 'POST',
        headers: {
          //'Content-Type': 'application/json',
          'x-token': token,
        },
        body: formData,
      });
  
      if (response.ok) {
        setSubmitCreateproductAlert('Product has been created successfully!');
        // You might want to navigate the user to another page or perform other actions here
      } else {
        const errorMessage = await response.text();
        console.error(`Failed to create product: ${errorMessage}`)
       // throw new Error(`Failed to create product: ${errorMessage}`);
      }
    } catch (error) {
      console.error('Error creating product:', error.message);
      clearStorageAndRedirectToHome()
      // Display an error message to the user or handle it in another appropriate way
      //setSubmitCreateproductAlert(`Error creating product: ${error.message}`);
    }
  };
  
  
  
  const handleChangePrice = (value) => {
    // Check if the value is a valid number
    if (!isNaN(value)) {
      // Value is a number, update state
      setPrice(value);
    } else {
      
    }
  };
  
  const handleCreateproductAlert = () => {
    // Close the confirmation alert
    setSubmitCreateproductAlert(false);
    setShowUploadOptions(true); // Hide both upload options
    setShowBulkUpload (false);
    setShowSingleUpload(false);
     setspecifications([]);
  setspecificationName('');
   setspecificationValue('');
   setAllspecificationNames([]);
   setFilteredspecificationNames([]);
 // setSubmitCreateproductAlert(false);
 setDataOfspecification('');
 setDescription('');
 setUnitOfMeasurement('');
  setPrice ('');
  setImage(null);
   setDisplayedImage(null);
 setSpecificationsAllert(null)
  //setShowUploadOptions(true);
  //setShowSingleUpload(false);
   //setShowBulkUpload(false);
   setSelectedVendors('')
   setProductGroups('')
   setProductName('')
   setProductCategories('')
    navigate('/bb/admin/AdminHome/ViewProducts');
  };
 

  const openFolderPicker = () => {
    // Create a file input element (hidden)
    const input = document.createElement('input');
    input.type = 'file';
    input.setAttribute('webkitdirectory', ''); // This is for Chrome support
  
    // Trigger a click event on the file input
    input.click();
  
    // Event listener for when a folder is selected
    input.addEventListener('change', function(event) {
    
  
      if (input.files.length > 0) {
        // Get the selected folder path
        const folderPath = input.files[0].webkitRelativePath.split('/')[0]; // Get only the folder name
        console.log(typeof folderPath)
        console.log(folderPath)
        setSelectedFolder(folderPath); 
      }
    });
  };
  
const handleSubmitCreateproductForEmptyAlert = () => {
  setSubmitCreateproductForEmptyAlert(false);
}

const handleProductCategory=(value)=>{
  setSelectedItems(value);
 setSelecteProductCategoryError(false)
 fetchAttributesByProductCategory(value)
 fetchProductSubCategories(value)
}

const fetchAttributesByProductCategory = async (productCategoryId) => {
  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${backendApiUrl}/Admin/product-attributes/get-all-attributes-by-productCategory?product_category_id=${productCategoryId}`,{
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
        'x-token' : token
      }
    });

    if (response.ok) {
      const attributesData = await response.json();

// console.log(attributesData)
    // Set the state with the fetched filter data
    setProductAttributesData(attributesData);

    }else{
      console.error('Failed to fetch filters for the selected product category')
      // throw new Error('Failed to fetch filters for the selected product category');
    }


  } catch (error) {
    clearStorageAndRedirectToHome()
  }
};


  return (

    <div className="overflow-y-scroll no-scrollbar w-full  flex flex-col items-center ">
    <div className="flex flex-col items-center justify-center p-10">
    <div className='border border-1 border-gray-500 flex flex-col flex justify-center  items-center  p-10 '>
    {showUploadOptions && (
      <>
        {/* Single Upload Card */}
        {/* <h2 className="text-lg font-semibold mb-4 flex justify-center">Create Product</h2> */}
        <div className='flex flex-row'>
      <div className="border border-gray-300 p-4 rounded-md cursor-pointer m-4 transition duration-300 ease-in-out transform hover:shadow-lg hover:bg-gray-50 hover:border-gray-400"
      onClick={handleSingleUploadClick}>
    <img src={process.env.PUBLIC_URL + '/singlefile.png'} alt="Single Upload" className="w-16 h-16 mb-2" />
    <h2 className="text-lg font-medium text-gray-700 mb-2">Single Upload</h2>
    <p className="text-gray-500">Upload a single file</p>
  </div>

  {/* Bulk Upload Card */}
  <div className="border border-gray-300 p-4 rounded-md cursor-pointer m-4 transition duration-300 ease-in-out transform hover:shadow-lg hover:bg-gray-50 hover:border-gray-400"
  onClick={handleBulkUploadClick}>
    <img src={process.env.PUBLIC_URL + '/multiplefiles.png'} alt="Bulk Upload" className="w-16 h-16 mb-2" />
    <h2 className="text-lg font-medium text-gray-700 mb-2">Bulk Upload</h2>
    <p className="text-gray-500">Upload multiple files</p>
  </div>
</div>
      </>
    )}

    {/* Display Single Upload UI if showSingleUpload is true */}
    {showSingleUpload && (
        <div >
      <div className='w-full flex-col '>
       {/*  <div>
        <h2 className="text-lg font-semibold mb-4 flex justify-center">Create Product</h2>
        </div> */}
        <div className='mb-6 flex justify-center  items-center  w-full'>
        <div className="flex flex-col w-1/2 justify-center">
            <label className="text-md font-medium text-gray-700 mb-3">Product Category</label>
                <select
                  className={` rounded-sm p-1 cursor-pointer  hover:border-[#EB001B] focus:outline-none ${selecteProductCategoryError?'border border-red-300':'border border-gray-300'}`}
                  value={selectedItems}
                  onChange={(e)=>handleProductCategory(e.target.value) }
                >
                  <option value="">Select Product Category</option>
                  {productCategories.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.product_category_name}
                    </option>
                  ))}
                </select>
            </div>
            </div>
        <div className='mb-4 flex flex-row space-x-4 w-full'>
        <div className="flex flex-col w-1/2">
            <label className="text-md font-medium text-gray-700 mb-3">Product Sub Category</label>
                <select
                  className={` rounded-sm p-1 cursor-pointer  hover:border-[#EB001B] focus:outline-none ${selecteProductCategoryError?'border border-red-300':'border border-gray-300'}`}
                  value={selectedProductSubCategory}
                  onChange={(e)=>handleProductSubCategoryDropdown(e.target.value)}
                >
                   <option value="" >Select SubCategory</option>
                    {productSubCategories.map((SubCategory) => (
                      <option key={SubCategory?._id} value={SubCategory?._id}>
                        {SubCategory?.product_sub_category_name}
                      </option>
                    ))}
                </select>
            </div>
            <div className="flex flex-col w-1/2">
              <p className="text-md font-medium text-gray-700 mb-3">Vendor</p>
                <select
                  className={` rounded-sm p-1 cursor-pointer  hover:border-[#EB001B] focus:outline-none ${selecteVendorError?'border border-red-300':'border border-gray-300'}`}
                  value={selectedVendors}
                  onChange={(e) => handleChangeVendor(e.target.value)}
                >
                  <option value="">Select Vendor</option>
                  {vendors.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
          </div>

          <div className="mb-4 flex flex-row space-x-4  w-full">
          <div className="flex flex-col w-1/2">
              <p className="text-md font-medium text-gray-700 mb-3">Product Group</p>
             
                <select
                  className="border border-gray-300 rounded-sm p-1 cursor-pointer  hover:border-[#EB001B] focus:outline-none"
                  value={selectedProductGroups}
                  onChange={(e) => {
                    const selectedProductGroupId = e.target.value;
                    setSelectedProductGroups(selectedProductGroupId);
                    
                    const selectedProductGroup = productGroups.find(p => p._id === selectedProductGroupId);
        
                    setSelectedProductSubCategory(selectedProductGroup && selectedProductGroup.product_sub_category_id?selectedProductGroup.product_sub_category_id : '');
                }}
                
                >
                  <option value="">Select Product Group</option>
                  {productGroups.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.product_group_name}
                    </option>
                  ))}
                </select>
             
            </div>
            
            <div className=" w-1/2">
                            <div className="flex flex-col  ">
                                <label className="text-md font-medium text-gray-700 mb-3">Product Name</label>
                                <div className=" flex">
                                    <input
                                        type="text"
                                        placeholder='Enter Product Name'
                                        className={` flex justify-center  hover:border-[#EB001B] rounded-sm w-full p-1 focus:outline-none ${productNameError?'border border-red-300':'border border-gray-300'}`}
                                        value={productName}
                                        onChange={handleProductNameChange}
                                    />
                                </div>
                            </div>
                        </div>

           
                       
                        </div>

                        <div className="mb-4 flex flex-row space-x-4 w-full">
  <div className="w-1/2 flex flex-col">
    <label className="text-md font-medium text-gray-700 mb-3">Price</label>
    <div className="flex">
      <input
        type="text"
        placeholder='Enter Price'
        value={price}
        onChange={(e) => handleChangePrice(e.target.value)}
        className="border border-gray-300 hover:border-[#EB001B] w-full rounded-sm p-1 focus:outline-none"
      />
    </div>
  </div>
  <div className="flex flex-col w-full">
      <label className="text-md font-medium text-gray-700 mb-3 ">Unit Of Measurement</label>
      <select
        value={unitOfMeasurement}
        onChange={handleUnitOfMeasurementChange}
        className="border border-gray-300 hover:border-[#EB001B] rounded-sm p-1 focus:outline-none"
      >
        <option value="">Select UOM</option>
        {unitOfMeasurementOptions.map((uoms) => ( // Use unitOfMeasurementOptions instead of unitOfMeasurement
          <option key={uoms._id} value={uoms.UOM_type}>
            {uoms.UOM_type}
          </option>
        ))}
      </select>
    </div>
</div>
                           
<div className="flex flex-row space-x-4 w-full ">
  {/* Upload Image section */}
  <div className="flex flex-col w-full ">
   
  {displayedImage && (
    <div className="mb-1 flex justify-center">
      <img src={displayedImage} alt="Selected Image" className="max-w-full border border-gray-200 max-h-32" />
    </div>
  )}
  <label htmlFor="fileInput" className="mt-3 cursor-pointer text-md font-medium text-gray-700 flex justify-center px-3 py-1 border border-1 border-gray-300 hover:border-[#EB001B] bg-white rounded-sm">
    <FontAwesomeIcon icon={faCamera} className="text-gray-600 mr-1 mt-1" />
    Upload Image
  </label>
  <input
    id="fileInput"
    type="file"
    accept="image/*"
    onChange={handleFileChange}
    className="hidden"
  />
  

    {/* Replace input with camera icon */}
    {/* <label htmlFor="fileInput" className="flex justify-center cursor-pointer">
      <FontAwesomeIcon icon={faCamera} className=" text-gray-600" />
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="hidden"
      />
    </label> */}
 
  </div>
  
  {/* Selected Folder input */}
  <div className='flex flex-col w-full'>
    
   
      <input
        type="text"
        id="selectedFolder"
        placeholder="Click to Select Folder"
        onClick={openFolderPicker}
        readOnly
        value={selectedFolder}
        className="mt-3 border border-1 border-gray-300 hover:border-[#EB001B] bg-gray-200 rounded-sm p-1 cursor-pointer focus:outline-none"
      />
    
  </div>
</div>

      <div className=" flex flex-row justify-around items-center mt-3">
      { ProductAttributesData  && (
     <ApplyProductAttributes
          attributesdata={ProductAttributesData}
            setAllAttributeIds={setAttributeIds}
            attributeValueIds={Attribute_Value_Ids}
             // Pass Filter_Value_Ids as a prop
          /> 
    )}
      </div>
          <div className='flex flex-col mt-4 space-y-2 items-center'>
            <div className=''>
              <div className='flex flex-col w-full justify-center items-center border border-1 border-[#000000] border-opacity-50 p-2 rounded-sm relative'>
                <div className='flex flex-row space-x-2 justify-center px-2'>
                  <h1 className='text-md font-medium text-gray-700'>Name</h1>
                  <input
                    type='text'
                    name='name'
                    placeholder='specification Name'
                    className='w-1/2 border border-gray-300 rounded-sm focus:outline-none'
                    value={specificationName}
                    onChange={handlespecificationNameChange}
                  />
                  {filteredspecificationNames.length > 0 && (
                    <div className='absolute bg-white w-auto mt-8 border border-gray-300 rounded-sm'>
                      {filteredspecificationNames.map((name, index) => (
                        <div
                          key={index}
                          className='px-2 py-1 cursor-pointer hover:bg-gray-100'
                          onClick={() => handleSelectspecificationName(name)}
                        >
                          {name}
                        </div>
                      ))}
                    </div>
                  )}
                  <h1 className='text-md font-medium text-gray-700'>Value</h1>
                  <input
                    type='text'
                    name='enteredValues'
                    placeholder='specification Value'
                    className='w-1/2 border border-gray-300 rounded-sm focus:outline-none'
                    value={specificationValue}
                    onChange={(e) => {setspecificationValue(e.target.value);setSpecificationsAllert(null)}}
                  />
                  <div className='flex flex-row'>
                    <button className='ml-2 text-blue-500 hover:text-[#EB001B]' onClick={handleAddSpecification}>
                      <svg
                        className='w-6 h-6'
                        fill='none'
                        stroke='currentColor'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 6v6m0 0v6m0-6h6m-6 0H6' />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className='flex flex-col space-y-1'>
                  <h1 className='flex text-md font-medium text-gray-700 justify-center mt-4'>Specifications</h1>
                  <div className='flex flex-wrap space-x-2 justify-center'>
                    {specifications.map((specification, index) => (
                      <div key={index} className='flex flex-row space-x-3 p-1 border border-1 border-[#EB001B] border-opacity-30 items-center rounded-md text-gray'>
                        <span>
                        {Object.keys(specification)[0]}: {Object.values(specification)[0]}
                        </span>
                        <button className='ml-2 text-red-600 hover:text-black' onClick={() => handleDeletespecification(index)}>
                          <svg
                            className='w-4 h-4 flex justify-center text-red-800'
                            fill='none'
                            stroke='currentColor'
                            viewBox='0 0 24 24'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {SpecificationsAllert&&(
           <div className='flex justify-center items-center text-red-500 text'>
         <p>{SpecificationsAllert}</p>
           </div>
          )}
          <div className="w-full mt-4 flex flex-col items-center">
        <label className="text-md font-medium text-gray-700 mb-3">Description</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder='Enter Product Description'
          className="w-full border border-gray-300 hover:border-[#EB001B] rounded-sm p-2 focus:outline-none"
        />
      </div>
          <div className='flex flex-row items-center justify-center space-x-8 mt-7 '>
            <div className='flex justify-center '>
              <button
                type='submit'
                className="px-6 py-1 border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button className='ml-4 px-6 py-1 border border-[#EB001B] hover:bg-[#EB001B] hover:text-white  text-black'
                onClick={handleSubmit}>
          Submit</button>
            </div>
          </div>
      
      
    </div>
    {SubmitCreateproductAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
  {/* Background overlay (blurred) */}
  <div className="absolute inset-0 bg-black opacity-30 "></div>

    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-semibold mb-2">{SubmitCreateproductAlert}</p>
     <button  className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
      onClick={handleCreateproductAlert}>OK</button>
    </div>
  </div>
)}
    </div>
    
    )}

{showBulkUpload && (
 <div className="flex flex-col items-center">
   <img src={process.env.PUBLIC_URL + '/multiplefiles.png'} alt="Bulk Upload" className="w-16 h-16 mb-2" />
  
   <h2 className="text-lg font-semibold mb-4 flex justify-center">Create Products</h2>
        <div className='mb-4 flex flex-row space-x-4 w-full'>
        <div className="flex flex-col w-1/2">
            <label className="text-md font-medium text-gray-700 mb-3">Product Category</label>
                <select
                  className="border border-gray-300 rounded-sm p-1 cursor-pointer  hover:border-[#EB001B] focus:outline-none"
                  value={selectedItems}
                  onChange={(e) => setSelectedItems(e.target.value)}
                >
                  <option value="">Select Product Category</option>
                  {productCategories.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.product_category_name}
                    </option>
                  ))}
                </select>
            </div>
            <div className="flex flex-col w-1/2">
              <p className="text-md font-medium text-gray-700 mb-3">Vendor</p>
                <select
                  className="border border-gray-300 rounded-sm p-1 cursor-pointer  hover:border-[#EB001B] focus:outline-none"
                  value={selectedVendors}
                  onChange={(e) => handleChangeVendor(e.target.value)}
                >
                  <option value="">Select Vendor</option>
                  {vendors.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
          </div>

          <div className="mb-4 flex flex-row space-x-5  w-full">
          <div className="flex flex-col w-1/2">
              <p className="text-md font-medium text-gray-700 mb-3">Product Group</p>
             
                <select
                  className="border border-gray-300 rounded-sm p-1 cursor-pointer  hover:border-[#EB001B] focus:outline-none"
                  value={selectedProductGroups}
                  onChange={(e) => setSelectedProductGroups(e.target.value)}
                >
                  <option value="">Select Product Group</option>
                  {productGroups.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.product_group_name}
                    </option>
                  ))}
                </select>
             
 
            </div>
            <div className='mt-8 w-1/2 flex justify-center items-center'>
            <input
              type="file"
              ref={zipInputRef}
              accept=".zip"
              className=" border border-gray-300 rounded-sm p-1 cursor-pointer  hover:border-[#EB001B] focus:outline-none"

              />
            </div>
            
</div>
      <div className='flex flex-row items-center justify-center space-x-8 mt-2 '>
     
      <button className=' px-6 py-1 border border-[#EB001B] hover:bg-[#EB001B] hover:text-white  text-black'
      onClick={handleCancel}>
        Cancel
      </button>
      <button className='px-6 py-1 border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white'
      onClick={handleBulkUpload}>
        Submit
      </button>
      </div>
      {SubmitCreateproductAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
  {/* Background overlay (blurred) */}
  <div className="absolute inset-0 bg-black opacity-30 "></div>

    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-semibold mb-2">{SubmitCreateproductAlert}</p>
     <button  className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
      onClick={handleCreateproductAlert}>OK</button>
    </div>
  </div>
)}


    </div>
)}
{loading && (
        <div className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50">
          {/* Loader component */}
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900">
          <img src={process.env.PUBLIC_URL + '/bb.png'} alt="Loading..." className="h-18 w-18" />
          </div>
        </div>
      )}

    </div>
    </div>
    {SubmitCreateproductForEmptyAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
  {/* Background overlay (blurred) */}
  <div className="absolute inset-0 bg-black opacity-30 "></div>

    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-semibold mb-2">{SubmitCreateproductForEmptyAlert}</p>
     <button  className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
      onClick={handleSubmitCreateproductForEmptyAlert}>OK</button>
    </div>
  </div>
)}
  </div>

   
  );
}

export default CreateProduct;
