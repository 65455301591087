
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import logoImage from "./images/BB.png";


function ResetPassword() {

    const navigate = useNavigate();
    const location = useLocation();
    const UserDetails = location.state;
    // const UserDetails= {_id:'21288n'}
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError,setConfirmPasswordError]= useState('');
    const [EmptyData,setEmptyData]=useState(false);
    const [showPassword, setShowPassword] = useState(false);
   /*  const [showPassword, setShowPassword] = useState(false);
    const [ShowConfimPassword, setShowConfimPassword] = useState(false); */
    const {resetToken}= useParams()
    const validatePassword = (password) => {
      const passwordValidation = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return passwordValidation.test(password);
    };
    
//This function calls when password inputfield changes 

const handlePasswordChange = (e) => {
const newPassword = e.target.value;
setPassword(newPassword);
setEmptyData(false);
setConfirmPasswordError(false);

// Validate password function called and check if password dose not satisfy the requiremnents it enters an error message shown.

if (!validatePassword(newPassword)) {
setPasswordError(true);
} else {
//
setPasswordError(false);
}
};
//This function calls when confimpassword inputfield changes 
const handleConfirmPasswordChange = (e) => {
const newConfirmPassword = e.target.value;
setConfirmPassword(newConfirmPassword);
setEmptyData(false);
// Validate password match
if (newConfirmPassword !== password) {
setConfirmPasswordError("Passwords do not match.");
} else {
setConfirmPasswordError(false);
}
};
//when submit button clicked this function called 
const handleSubmit = async() => {
const LoginData = JSON.parse(localStorage.getItem("LoginData"))
const ResetPasswordUrl=process.env.REACT_APP_FORE_BRICKBUCKET + '/User/user-change-password';
// For example, you can check if the passwords are valid and proceed accordingly
if (!password || !confirmPassword) {
setEmptyData("Please fill all password inputfields.");
return;
}else if (confirmPassword !== password) {
setConfirmPasswordError("Both Passwords you enterd do not match.");

}
else if (validatePassword(password) && password === confirmPassword) {

const resetData = UserDetails
? {
  newPassword: password,
  userId: LoginData.userId || '',
}
: {
  newPassword: password,
  resetToken: resetToken,
};



const res = await fetch(ResetPasswordUrl, {
method: 'POST',
headers: {
  Accept: 'application/json',
  'Content-Type': 'application/json',
},
body: JSON.stringify(resetData),
});
if(res.ok){
 navigate('/bb');

localStorage.removeItem('LoginData');
  const loginPageUrl = '/bb';

  // Push multiple states to the history to prevent going back to the homepage
  if (window.history && window.history.pushState) {
    for (let i = 0; i < 40; i++) {
      window.history.pushState({}, document.title, loginPageUrl);
    }

    window.addEventListener('popstate', function (event) {
      if (event.state === null) {
        for (let i = 0; i < 41; i++) {
          window.history.pushState({}, document.title, loginPageUrl);
        }
      }
    });
  }

  // Redirect the user to the login page
  window.location.replace(loginPageUrl);

}
} else {

}
};


const togglePasswordVisibility = () => {
setShowPassword((prev) => !prev);
};


    return (
        <div className="w-full flex justify-center items-center h-screen font-roboto border border-gray-300">
           
            <div className="flex w-full justify-center items-center">
                <div className="flex flex-col space-y-4 justify-center w-full items-center">
                <div className='flex flex-row justify-center items-center' >
                <img src={logoImage} alt="Logo" className='h-20 w-20'/>
                <p className='text-4xl sm:text-2xl md:text-4xl lg:text-5xl'>BRICKBUCKET</p>
            </div>
                    <div className="flex-col w-1/2 sm:w-full md:w-2/3 lg:w-1/2 xl:w-1/3 flex self-center items-center border border-[#D9D9D9] border-2 bg-opacity-[15%] rounded-sm p-4">
                        <p className="text-2xl pt-4">Reset Password</p>
                        <div className="p-6 relative">
                            <div className="flex-col justify-center flex items-center space-y-6 mt-2">
                                <div className='flex-col justify-center flex items-center space-y-2 '>
                                <p className="text-md">Enter Password</p>
                                <div className="flex-row flex items-center space-x-2 pl-2 pr-2 relative">
                                    <span className="flex justify-center items-center absolute left-5">
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="hover:text-[#EB001B] text-gray-400 cursor-pointer" onClick={togglePasswordVisibility} />
                                        <div className="ml-1 mr-4 flex-grow border-r border-gray-300 h-6"></div>
                                    </span>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none focus:ring-[1px]  focus:ring-[#EB001B] text-gray-600 text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                                        placeholder="Enter New Password"
                    value={password}
                    onChange={handlePasswordChange}
                                   />
                                 
                {passwordError && (
          <p className="text-[12px] text-red-600 text-center">Password must be  8 characters <br/>  and include at least one uppercase (A), <br/>   one digit(1), and one special character(@).</p>
        )}
                                </div>
                                </div>

                                <div className='flex-col justify-center flex items-center space-y-2 '>
                                <p className="text-md">Confirm Password</p>
                                <div className="flex-row flex items-center space-x-2 relative pl-2 pr-2">
                                    <span className="flex justify-center items-center absolute left-5">
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="hover:text-[#EB001B] text-gray-400 cursor-pointer" onClick={togglePasswordVisibility} />
                                        <div className="ml-1 mr-4 flex-grow border-r border-gray-300 h-6"></div>
                                    </span>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none focus:ring-[1px]  focus:ring-[#EB001B]  text-gray-600 text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                                       
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                    />
                                    <p className="text-[12px] text-red-500">{confirmPasswordError}</p>
                                </div>
                                </div>

                            </div>
                            <div className='flex justify-center mt-4'>
                            <p className="text-sm text-red-500">{EmptyData}</p>
                            </div>
                            <div className="flex justify-center items-center mt-2">
                                <button className="border border-[#EB001B] text-md rounded-sm hover:text-white px-4 py-1 hover:bg-[#EB001B] mb-4"
                                onClick={handleSubmit}>Submit</button>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
