import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function AssignCategoryModal({ vendorName, vendorId, onClose }) {
  const [isOpen, setIsOpen] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [assignedCategories, setAssignedCategories] = useState([]);

  const navigate = useNavigate();


  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
        navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(value)) {
        return prevSelectedItems.filter((item) => item !== value);
      } else {
        return [...prevSelectedItems, value];
      }
    });
  };

  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/bb/admin';
  }

  useEffect(() => {

    getAllProductCategories();
    getAllProductCategoriesAssignedToVendor();
  }, [vendorId]);

  const getAllProductCategories = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/get-all-productCategorys`;
      const response = await fetch(url,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
           'x-token' : token
        },
      });
      if (response.ok) {
        const responseData = await response.json();

      setProductCategories(responseData);
       
      }else{
        console.error('Failed to fetch product categories')
        //  throw new Error('Failed to fetch product categories');
      }
      
    } catch (error) {
      clearStorageAndRedirectToHome()
    }
  };

  const getAllProductCategoriesAssignedToVendor = async () => {
    try {
      const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/get-productCategoriesBy-vendor_id?vendor_id=${vendorId}`;
      const token = sessionStorage.getItem('token');
      const response = await fetch(url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
             'x-token'  : token
          },
    
        });
      
      if (response.ok) {
        const responseData = await response.json();

      setAssignedCategories(responseData);
      setSelectedItems(responseData.map((category) => category._id));
        
      }else{
        console.error('Failed to fetch product categories assigned to vendor')
        //throw new Error('Failed to fetch product categories assigned to vendor');
      }
      
    } catch {
     // clearStorageAndRedirectToHome()
    }
  };

  const assignProductCategoryToVendor = async () => {
    const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/assign-productCategory-to-vendor`;
    const data = {
      vendor_id: vendorId,
      product_category_ids: selectedItems,
    };
    const token = sessionStorage.getItem('token');
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
          'x-token' : token
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
         
        }else{
          console.error('Failed to assign product categories')
          //throw new Error('Failed to assign product categories');
        }
       
      })
      .then((data) => {

        onClose(); // Close the modal
        navigate('/bb/admin/AdminHome/VendorList');
      })
      .catch((error) => {
        clearStorageAndRedirectToHome()
      });
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="flex flex-col space-y-8 bg-white p-8 rounded-md shadow-md relative">
        <button className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-700" onClick={onClose}>
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-xl font-bold text-center">Assign Product Category</h2>
        <div className='flex flex-col space-y-4'>
          <div className='flex items-center justify-center'>
          <p className="text-md font-medium text-gray-700 text-center">Vendor Name</p>
          <p className='text-md ml-4 text-center'> - {vendorName}</p>
          </div>
          <div className="flex items-center justify-center">
            <p className="text-md font-medium text-gray-700 text-center">No of Product Categories</p>
            <p className='text-md ml-4 text-center'> -  {selectedItems.length}</p>
          </div>
        </div>
        <div className="mt-4 relative flex items-center">
          <p className="text-md font-medium text-gray-700 text-center mr-4">Product Category</p>
          <div className="flex justify-center items-center border border-gray-300 rounded-sm p-1 cursor-pointer" onClick={toggleDropdown}>
            <span className="text-md">Select Product Category</span>
            <svg className={`w-5 h-5 ${isOpen ? 'transform rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          {isOpen && (
            <div className="absolute top-full right-0 w-[200px] bg-white border border-gray-300 mt-2 rounded-sm shadow-md">
              {productCategories.map((item) => (
                <label key={item._id} className="block px-2 py-1 flex items-center justify-between">
                  <span>{item.product_category_name}</span>
                  <input type="checkbox" value={item._id} checked={selectedItems.includes(item._id)} onChange={handleCheckboxChange} />
                </label>
              ))}
            </div>
          )}
        </div>

        <div className='flex justify-center'>
        {assignedCategories.length > 0 && (
          <div>
            <h3 className="text-md font-medium text-gray-700 mt-4">Assigned Categories:</h3>
            <ul>
              {assignedCategories.map(category => (
                <li className='text-center' key={category._id}>{category.product_category_name}</li>
              ))}
            </ul>
          </div>
        )}
      </div>

        <div className="flex justify-center items-center mt-6">
          <button className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white rounded-sm text-black px-2 py-1 ml-2" onClick={assignProductCategoryToVendor}>Submit</button>
        </div>
      </div>
    </div>
  );
}

export default AssignCategoryModal;
