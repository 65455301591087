
import React, { useState ,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faEye,faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import logoImage from "./images/BB.png";
import { useNavigate } from 'react-router-dom';


function Login() {
    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const navigate = useNavigate();
    const [InvalideEmail,setInvalidEmail]=useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [EmptyLoginData, setEmptyLoginData]= useState(false);
    const [UserNotFound,setUserNotFound]=useState(false);
    const [showPassword, setShowPassword] = useState(false);
    
  
  
    const handleUsernameChange = (e) => {
      setUsername(e.target.value);
      setInvalidEmail(false); // Reset invalide password state when password changes 
        setEmptyLoginData(false); // Reset Empty login data state when password changes
        setUserNotFound(false);
    };
  
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
      setInvalidPassword(false); // Reset invalide password state when password changes 
        setEmptyLoginData(false); // Reset Empty login data state when password changes
        setUserNotFound(false);
    };
  
    const validateEmail = (email) => {
      const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailValidation.test(email);
    };
  
    // Password validation logic
    const validatePassword = (password) => {
      const passwordValidation = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return passwordValidation.test(password);
    };
  
    const handleLogin = async () => {
      const LoginUrl= process.env.REACT_APP_FORE_BRICKBUCKET + '/User/user-login';
      if (!Username || !Password) {
        setEmptyLoginData("Please enter Email and Password.");//Error meassage display when inputfield are in empty state
          return;
        }  
        //validation of email and password function are get called 
        else if (!validateEmail(Username) || !validatePassword(Password)) {
          // Set the appropriate error states
          setInvalidEmail(!validateEmail(Username));
          setInvalidPassword(!validatePassword(Password));
          return;
        }
        else{
          try {
           const LoginData= {
            username:Username,
            password:Password
           }
            const response = await fetch(LoginUrl, {
              method: 'POST',
              headers: {
            'Content-Type': 'application/json',
              },
              body: JSON.stringify(LoginData),
            });
        if(response.ok){
          const data = await response.json();
       
              localStorage.setItem('LoginData',JSON.stringify(data))
              sessionStorage.setItem('userData', JSON.stringify(data));
              sessionStorage.setItem('userId',data.userId);
              sessionStorage.setItem('token',data.token); 
              sessionStorage.setItem('appId',data.appId);      
  
              if (data.forcePasswordChange===true) {
                navigate('/ResetPassword', { state: { LoginData: data } });//Navigate to ResetPassword page 
              }
              //If he alredy reset the password it check the role of the use he is an admin are user
              else if (data.roleID === 1) {
    
                navigate('/bb/admin/AdminHome/dashbord', { state: { LoginData: data } }); // if he is admin navigate to AdminDashbordPage
              } else if(data.roleID === 2) {
                navigate('/bb/user', { state: { LoginData: data } });//if he is user navigate to UserHomePage
              }
            } else {
              setUserNotFound('User not found')
            }
          } catch (error) {
          }
        }
    };
    
    useEffect(() => {
      const loginData = JSON.parse(localStorage.getItem('LoginData'));
      if (loginData) {
        localStorage.setItem('LoginData', JSON.stringify(loginData));
        sessionStorage.setItem('userData', JSON.stringify(loginData));
        sessionStorage.setItem('userId', loginData.userId);
        sessionStorage.setItem('token', loginData.token);
     
    
        if (loginData.forcePasswordChange === true) {
       
          navigate('/ResetPassword', { state: { LoginData: loginData } }); // Navigate to ResetPassword page
        } else if (loginData.roleID === 1) {
          //setrole(loginData.roleID);
          navigate('/bb/admin/AdminHome/dashbord', { state: { LoginData: loginData } }); // Navigate to AdminDashboardPage for admin
        } else if (loginData.roleID === 2) {
          /* setrole(loginData.roleID); */
          navigate('/bb/user', { state: { LoginData: loginData } }); // Navigate to UserHomePage for user
        }
      } else {
        
       // setUserNotFound('User not found');
      }
    }, []);
  
    const handleForgotPassword = () => {
      navigate('/bb/admin/ForgotPassword')
    };
  
    const togglePasswordVisibility = () => {
      setShowPassword((prev) => !prev);
    };


    return (
        <div className="w-full flex justify-center items-center h-screen font-roboto border border-gray-300">
            <div className="flex w-full justify-center items-center">
                <div className="flex flex-col space-y-4 justify-center w-full items-center">
                <div className='flex flex-row justify-center items-center' >
                <img src={logoImage} alt="Logo" className='h-20 w-20'/>
                <p className='text-4xl sm:text-2xl md:text-4xl lg:text-5xl'>BRICKBUCKET</p>
            </div>
                    <div className="flex-col w-1/2 sm:w-full md:w-2/3 lg:w-1/2 xl:w-1/3 flex self-center items-center border border-[#D9D9D9] border-2 bg-opacity-[15%] rounded-sm p-4">
                        <p className="text-2xl pt-4">LOGIN</p>
                        <div className="p-6 relative">
                            <div className="flex-col p-2 ">
                            <div className='flex justify-center'>
                                <p className="text-md">User Name</p>
                                </div>
                                <div className="flex-row flex items-center space-x-2 pl-2 pr-2 relative">
                                    <span className="flex justify-center items-center absolute left-5">
                                        <FontAwesomeIcon icon={faUser} className="text-gray-400" />
                                        <div className="ml-1 mr-4 flex-grow border-r border-gray-300 h-6"></div>
                                    </span>
                                    <input
                                    type="text"
                                    placeholder="Username"
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none focus:ring-[1px]  focus:ring-[#EB001B] text-gray-600 text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                                        value={Username}
                                      onChange={handleUsernameChange}
                                   />
                                    {InvalideEmail && (
      <div className="text-red-500 text-[12px]">
        <p className="flex justify-center">Enter the correct Email</p>
      </div>
    )}
    </div>
                                </div>
                                <div className='flex flex-col p-2'>
                                    <div className='flex justify-center'>
                                <p className="text-md">Password</p>
                                </div>
                                <div className="flex-row flex items-center space-x-2 relative pl-2 pr-2">
                                    <span className="flex justify-center items-center absolute left-5">
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="text-gray-400 cursor-pointer" onClick={togglePasswordVisibility} />
                                        <div className="ml-1 mr-4 flex-grow border-r border-gray-300 h-6"></div>
                                    </span>
                                    <input
                                    onChange={handlePasswordChange}
                                     type={showPassword ? "text" : "password"}
                                     placeholder="Password"
                                     value={Password}
                                    className="w-5/6 md:w-full rounded-sm focus:outline-none focus:ring-[1px]  focus:ring-[#EB001B]  text-gray-600 text-sm px-2 py-1 pr-2 pl-8 text-[12px] placeholder-text-xs placeholder-text-center border border-gray-300"
                                    />
                                    {invalidPassword && (
      <div className="text-red-500 text-[12px]">
        <p>Password is incorrect</p>
      </div>
    )}
                                </div>
                                {EmptyLoginData || UserNotFound && (
    <div className="text-red-500 text-[12px] flex justify-center items-center">
      <p className="text-red-500">
        {EmptyLoginData}
        {UserNotFound}
      </p>
    </div>
  )}
  </div>
                            
                            <div className="flex justify-center items-center mt-3">
                                <p className="underline text-[#2158E5] hover:text-[#EB001B] cursor-pointer text-[14px]" onClick={handleForgotPassword}>Forgot Password?</p>
                            </div>
                            <div className="flex justify-center items-center mt-3">
                                <button className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1  mb-4"
                                onClick={handleLogin}>Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
