import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/bb/user");
  };

  return (
    <div className="w-full h-screen flex flex-col justify-start items-center">
      <div className="flex flex-col justify-center items-center flex-grow">
        <div className="border border-1 border-[#EB001B] bg-white rounded-full p-8 flex items-center justify-center mb-6">
          <svg
            className="h-12 w-12 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <circle cx="12" cy="12" /* r="10" className="text-white fill-current" */ />
            <text
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize="16"
              fontWeight="bold"
              className="text-white"
            >
           ⚠️
            </text>
          </svg>
        </div>
        <h1 className="text-lg font-bold mb-8">Something Went Wrong!!!</h1>
        <div className="space-x-4">
          <button
            className="border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] font-bold py-2 px-4 rounded"
            onClick={() => navigate(-1)}
          >
            Try Again
          </button>
          <button
            className="border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] font-bold py-2 px-4 rounded"
            onClick={handleClick}
          >
            Go Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
