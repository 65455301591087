// BankDetailsModal.js
import React , {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

function BankDetailsModal({ vendor, onClose, setVendor }) {


    const [bankName, setBankName] = useState('');
    const [branchName, setBranchName] = useState( '');
    const [IFSCCode, setIFSCCode] = useState( '');
    const [accountNumber, setAccountNumber] = useState( '');
    const [confirmAccountNumber, setConfirmAccountNumber] = useState( '');
    const [UpdateVendorBankDetailsAlert, setUpdateVendorBankDetailsAlert] = useState(false);
    const [checkingAcountNumberError,setCheckingAcountNumberError]= useState(false)
    const [UpdateVendorProfileAlert, setUpdateVendorProfileAlert] = useState(false);
    const navigate= useNavigate();
    
 useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
        navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })
  

    useEffect(()=>{
         setBankName(vendor.bank_name || '');
         setBranchName(vendor.bank_branch || '');
        setIFSCCode(vendor.bank_IFSC || '');
         setAccountNumber(vendor.account_number || '');
        setConfirmAccountNumber(vendor.account_number || '');
    },[vendor]);

    function clearStorageAndRedirectToHome() {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/bb/admin';
      }

    const url = process.env.REACT_APP_FORE_BRICKBUCKET + '/Admin/update-vendor';

    const handleSave = async () => {
        if(accountNumber===confirmAccountNumber){
            try {
                if (!bankName || !branchName || !IFSCCode|| !confirmAccountNumber ) {
                      setUpdateVendorProfileAlert('Please fill in all the required fields.');
                   return; // Exit function if any required field is empty
               }
                const updatedVendorData = {
                    bank_name: bankName,
                    bank_branch: branchName,
                    bank_IFSC: IFSCCode,
                    account_number: confirmAccountNumber,
                    _id:vendor._id
                };
                const token = sessionStorage.getItem('token');
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-token' : token
                    },
                    body: JSON.stringify(updatedVendorData),
                };
        
                // Make a PUT request to update vendor data using fetch
                const response = await fetch(url, requestOptions);
                if (response.ok) {
                    const responseData = await response.json();
                    console.log(responseData)
                    setVendor(responseData.vendor)
                    setUpdateVendorBankDetailsAlert('Bank details updated successfully!');
                   // closeBankDetailsModal();
                }else{
                    console.error('Failed to update vendor data');
                    //  throw new Error('Failed to update vendor data');
                }
        
                
            } catch (error) {
                clearStorageAndRedirectToHome()
            }
        }else{
            setCheckingAcountNumberError(true)
        }
       
    };

    const handleUpdateAlert = () => {
        setUpdateVendorProfileAlert(false);
    }

      const handleUpdateVendorAlert = () => {
        setUpdateVendorBankDetailsAlert(false);
        onClose(); 
      }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center z-20">
      <div className="bg-white p-8 rounded-md shadow-md relative">
        <button className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-700" onClick={onClose}>
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-xl font-bold mb-4 mt-4 text-center">Vendor Bank Details</h2>


                            <div className="flex flex-row space-x-4 mb-4">
                                <div className="flex flex-col">
                                <p className="text-md font-medium text-gray-700 mb-2">Bank Name</p>
                                    <input
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs border border-gray-300"
                                        placeholder="Other"
                                    />
                                </div>
                                
                                <div className="flex flex-col">
                                <p className="text-md font-medium text-gray-700 mb-2">Bank Name</p>
                                    <input
                                    value={bankName}
                                    onChange={(e) => setBankName(e.target.value)}
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs  border border-gray-300"
                                        placeholder=""
                                    />
                                </div>
                                </div>

                                <div className="flex flex-row space-x-4 mb-4">
                                <div className="flex flex-col">
                                <p className="text-md font-medium text-gray-700 mb-2">Branch Name</p>
                                    <input
                                    value={branchName}
                                    onChange={(e) => setBranchName(e.target.value)}
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs border border-gray-300"
                                        placeholder=""
                                    />
                                </div>
                                
                                <div className="flex flex-col">
                                <p className="text-md font-medium text-gray-700 mb-2">IFSC Code</p>
                                    <input
                                    value={IFSCCode}
                                    onChange={(e) => setIFSCCode(e.target.value)}
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs border border-gray-300"
                                        placeholder=""
                                    />
                                </div>
                                </div>

                                <div className="flex flex-row space-x-4 mb-4">
                                <div className="flex flex-col">
                                    <p className="text-md font-medium text-gray-700 mb-2">Account Number</p>
                                    <input
                                    value={accountNumber}
                                    onChange={(e) => setAccountNumber(e.target.value)}
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs border border-gray-300"
                                        placeholder=""
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <p className="text-md font-medium text-gray-700 mb-2">Confirm Account Number</p>
                                    <input
                                    value={confirmAccountNumber}
                                    onChange={(e) =>{ setConfirmAccountNumber(e.target.value);setCheckingAcountNumberError(false);}}
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs border border-gray-300"
                                        placeholder=""
                                    />
                                    {checkingAcountNumberError&&(
                              <p className='text-red-500 text-[10px]'>please check the confirm acount number</p>
                                )}
                                </div>
                                
                                
                            </div>



                            <div className="flex justify-center">
                            <button className="px-6 py-1  border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white" onClick={()=>handleSave()}>Save</button>
                            </div>

      </div>

      {UpdateVendorBankDetailsAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
  {/* Background overlay (blurred) */}
  <div className="absolute inset-0 bg-black opacity-30 "></div>

    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-semibold mb-2">{UpdateVendorBankDetailsAlert}</p>
     <button  className="items-center justify-center text-white bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleUpdateVendorAlert}>OK</button>
    </div>
  </div>
)}

{UpdateVendorProfileAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
  {/* Background overlay (blurred) */}
  <div className="absolute inset-0 bg-black opacity-30 "></div>

    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-semibold mb-2">{UpdateVendorProfileAlert}</p>
     <button  className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleUpdateAlert}>OK</button>
    </div>
  </div>
)}

    </div>
  );
}

export default BankDetailsModal;
