import { faPercentage, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { FaMinusCircle } from 'react-icons/fa';
import {  useNavigate } from 'react-router-dom';

const CreateOffer = () => {
  const Navigate = useNavigate();
  const [selectedVendor, setSelectedVendor] = useState();
  const [vendors, setVendors] = useState([]);
  const [selectedProductCategory, setselectedProductCategory] = useState();
  const [poductCategories, setPoductCategories] = useState([]);
  const [locationInputValue, setLocationInputValue] = useState('');
  const [SelectedState, setSelectedState] = useState('');
  const [basePriceInputValue, setBasePriceInputValue] = useState('');
  const [discountInputValue, setDiscountInputValue] = useState(0);
  const [netpriceInputValue, setnetpriceInputValue] = useState('');
  const [selectedGST, setSelectedGST] = useState('');
  const [transportInputValue, setTransportInputValue] = useState(false);
  const [includeGST, setIncludeGST] = useState(false);
  const [loadingInputValue, setloadingInputValue] = useState(false);
  const [UnloadingInputValue, setUnloadingInputValue] = useState(false);
  const [OfferexpiryInputValue, setOfferexpiryInputValue] = useState('');
  const [DeliveryTimeInputValue, setDeliveryTimeInputValue] = useState('');
  const [advancepaymentInputValue, setadvancepaymentInputValue] = useState('');
  const [finalpaymentInputValue, setfinalpaymentInputValue] = useState('');
  const [TCInputValue, setTCInputValue] = useState('');
  const [selectedProductGroup, setSelectedProductGroup] = useState([]);
  const [selectedProduct, setselectedProduct] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [products, setproducts] = useState([]);
  const [SubmitCreateOfferAlert, setSubmitCreateOfferAlert] = useState(false);
  const [errorCreateOfferAlert, setErrorCreateOfferAlert] = useState(false);
  const [SubmitCreateOfferForEmptyAlert, setSubmitCreateOfferForEmptyAlert] = useState(false);
  const [user,setUser]=useState('')
  const [group_ids,setGroupIds]=useState([]);
  const [allert,setAllert]=useState('')
  const [listeOffer, setListeOffer] = useState('yes');
  const state = [ 'Select State','Telangana', 'Andhra Pradesh'];
  //const productCategory = ['Tiles', 'Sanitary ware'];
  //const vendor = ['Jaguar', 'kajaria', 'Cera', 'Kohler'];
  const GSTOptions = ['0','5', '12', '18', '28'];
 /*  const productGroup = {
    'Tiles': ['600X600_Glossy', '800X1200_Matte'],
    'Sanitary ware': ['One Piece Closet', 'Table Top Basin'], // Change product group options for Sanitary ware
  };
 */
  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
        Navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      Navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })

  const handleVendorChange = async (vendor) => {

    setSelectedVendor(vendor);
    setselectedProductCategory(null);
    setProductGroups([]);
    setselectedProduct([])
    setproducts([]);
    setSelectedProductGroup([])
    await getProductCategoriesByvendorId(vendor); // Pass vendor ID instead of the whole object
  };
  
  

  const handleLocationInputChange = (e) => {
    setLocationInputValue(e.target.value);
  }

const handleStateInputChange = (e) => {
  setSelectedState(e.target.value);
}

const handleProductGroupChange = (productGroup) => {
 
  const isSelected = selectedProductGroup&& Array.isArray(selectedProductGroup)&&selectedProductGroup.includes(productGroup);

  if (isSelected) {
    setSelectedProductGroup(/* selectedProductGroup.filter(group => group !== productGroup) */[]);
  } else {
    setBasePriceInputValue(productGroup.product_group_price || '');

    setSelectedProductGroup(() => {
      // Ensure prevSelected is an array or default to an empty array
      const updatedProductGroup =[productGroup] /* Array.isArray(prevSelected) ? [...prevSelected, productGroup] : [productGroup]; */
      return updatedProductGroup;
    });
   
   // getProducts(productGroup._id); 
  }
};



const handleProductCheckboxChange = (product) => {
  const isSelected = selectedProduct && Array.isArray(selectedProduct) && selectedProduct.includes(product);

  if (isSelected) {
    // If product is already selected, remove it from the selectedProduct array
    ///const updatedSelectedProduct = selectedProduct.filter((selectedItem) => selectedItem !== product);
    setselectedProduct([]);
  } else {
    // If product is not selected, add it to the selectedProduct array
    setselectedProduct((/* prevSelected */) => {
      // Ensure prevSelected is an array or default to an empty array
      const updatedSelectedProduct = [product] // Array.isArray(prevSelected) ? [...prevSelected, product] : [product];
      return updatedSelectedProduct;
    });
    
    setBasePriceInputValue(product.product_price); // Assuming setBasePriceInputValue sets the input value somewhere
  }
};


 

 
  const handleBasePriceInputChange = (event) => {
    setBasePriceInputValue(event.target.value);
    calculateNetPrice(event.target.value, discountInputValue, selectedGST);
  };

  const handleDiscountInputChange = (event) => {
    setDiscountInputValue(event.target.value);
    calculateNetPrice(basePriceInputValue, event.target.value, selectedGST);
  };

  const handleGSTChange = (event) => {
  
      setSelectedGST(event.target.value);
      setIncludeGST(true)
      calculateNetPrice(basePriceInputValue, discountInputValue, event.target.value);
      if(event.target.value===GSTOptions[0]){
        setIncludeGST(false)
      }else{
        setIncludeGST(true)
      }
    }

  const calculateNetPrice = (basePrice, discount, gst) => {
    let netPrice = parseFloat(basePrice) || 0;
    let discountAmount = parseFloat(discount) || 0;
    let gstPercentage = parseFloat(gst) || 0;

    // Apply discount
    netPrice -= (netPrice * (discountAmount / 100));

    // Apply GST
    if (gstPercentage !== 0) {
      netPrice *= (1 + (gstPercentage / 100));
    }

    setnetpriceInputValue(netPrice.toFixed(2));
  };
 /*  const handleNetPriceInputChange = (e) => {
    setnetpriceInputValue(e.target.value);
  } */

  const handleTransportInputChange = (e) => {
    setTransportInputValue(e.target.checked);
  };

  const handleIncludeGSTChange = (e) => {
    if(selectedGST!==GSTOptions[0]){
      setIncludeGST(e.target.checked);
      if(e.target.checked){

      }else{
        setSelectedGST(GSTOptions[0]);
        calculateNetPrice(basePriceInputValue, discountInputValue, GSTOptions[0]);
      }
    }
   
   
  };

  const handleLoadingInputChange = (e) => {
    setloadingInputValue(e.target.checked);
  }

  const handleUnLoadingInputChange = (e) => {
    setUnloadingInputValue(e.target.checked);
  }

  const handleOfferexpiryInputChange = (e) => {
    setOfferexpiryInputValue(e.target.value);
  }
  
  const handleDeliveryTimeInputChange = (e) => {
    setDeliveryTimeInputValue(e.target.value);
  }

  const handleAdvancepaymentInputChange = (event) => {
    const value = event.target.value.replace(/\D/, ''); // Remove non-digit characters
    setadvancepaymentInputValue(value);
    
    // Calculate final payment
    const finalPayment = 100 - parseInt(value || 0);
    setfinalpaymentInputValue(finalPayment.toString());
  };

  const handleFinalpaymentInputChange = (event) => {
    const value = event.target.value.replace(/\D/, ''); // Remove non-digit characters
    setfinalpaymentInputValue(value);
    
    // Calculate advance payment
    const advancePayment = 100 - parseInt(value || 0);
    setadvancepaymentInputValue(advancePayment.toString());
  };
  
  const handleTCInputChange = (e) => {
    setTCInputValue(e.target.value);
  }

  const handleCategoryChange = (id) => {

    setSelectedProductGroup([]);
    setselectedProductCategory(id);
    // Here you can fetch product groups based on the selected category from your productGroup object

  };
  const handleGroups=()=>{
    setproducts([])
    const productCategory = poductCategories.find(p => p._id === selectedProductCategory);
    getProductGroupsbyProductCategoryAndvendorId(productCategory._id)
  }
 
  
  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/bb/admin';
  }

  const Getallvendors = process.env.REACT_APP_FORE_BRICKBUCKET + '/Admin/get-all-vendors';
  
 useEffect(() => {
  const getAllVendors = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(Getallvendors, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // Specify Content-Type as application/json
           'x-token'    : token
          // You can add other headers if needed
        },
      });

      if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          console.log("Invalid content type received, expected JSON")
         // throw new Error("Invalid content type received, expected JSON");
        }
  
        const data = await response.json();
  
        setVendors(data);
      }else{
        console.error(`HTTP error! Status: ${response.status}`)
        //throw new Error(`HTTP error! Status: ${response.status}`);
      }

     
    } catch (error) {
      clearStorageAndRedirectToHome()
    }
  };
  
  getAllVendors();
  getUser()
}, []);

 const getUser =async()=>{
  const UserData= JSON.parse(localStorage.getItem('LoginData'))
  setUser(UserData)
 }



const getProductCategoriesByvendorId = async (vendorId) => {

  const token = sessionStorage.getItem('token');
  const Getproductcategories = process.env.REACT_APP_FORE_BRICKBUCKET + `/Admin/get-productCategoriesBy-vendor_id?vendor_id=${vendorId}`;
  try {
    const response = await fetch(Getproductcategories, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'x-token'  : token
      },
    });

    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        console.error("Invalid content type received, expected JSON")
       // throw new Error("Invalid content type received, expected JSON");
      }
  
      const data = await response.json();
        setPoductCategories(data);
    }else{
      setProductGroups([]);
      setselectedProduct([])
      console.error(`HTTP error! Status: ${response.status}`)
      //throw new Error(`HTTP error! Status: ${response.status}`);
    }

  } catch (error) {
    clearStorageAndRedirectToHome()
  }
};

const getProductGroupsbyProductCategoryAndvendorId = async (id) => {

 
  const getproductgroupsByProductCategoryAndVendorId = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-groups/get-all-productGroups-by-productCategoryIdAndVendorId?vendor_id=${selectedVendor}&product_category_id=${id}`;
  const token = sessionStorage.getItem('token');
  try {
    const response = await fetch(getproductgroupsByProductCategoryAndVendorId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
         "x-token" : token
      },
    });

    if (response.ok) {

      const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
       console.error("Invalid content type received, expected JSON")
     // throw new Error("Invalid content type received, expected JSON");
    }

    const data = await response.json();
    setselectedProduct([])
    setSelectedProductGroup([])
    setProductGroups(data.productGroups);

    }else{
      console.error(`HTTP error! Status: ${response.status}`)
      //throw new Error(`HTTP error! Status: ${response.status}`);
    } 
    
  } catch (error) {
    clearStorageAndRedirectToHome()
  }
};


//If product group is null and product will be fetched by using product category id.
const handleProduct = async () => {
setProductGroups([])
setproducts([])
setSelectedProductGroup([])
HandleProductsData()
 
};
const HandleProductsData=async()=>{
  const apiUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product/get-products-by-vendorAndProductCategoryIds?product_category_id=${selectedProductCategory}&vendor_id=${selectedVendor}`;
  const token = sessionStorage.getItem('token');
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
         'x-token' : token
      },
    });

    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        console.error("Invalid content type received, expected JSON")
        //throw new Error("Invalid content type received, expected JSON");
      }
  
      const data = await response.json();
      const ProductsData= data.filter(p=>p.product_group_id===null);
      console.log(ProductsData);
      setproducts(ProductsData); 
     
    }else{
      setproducts([]);

      setAllert('There is no individual  products are in the vendor and product category')
      console.error(`HTTP error! Status: ${response}`)
      //throw new Error(`HTTP error! Status: ${response}`);
    }

    
    
  } catch (error) {
    console.error('Error fetching products:', error);
    clearStorageAndRedirectToHome()
    // Handle the error, e.g., show an error message to the user
  }
}

/* const getProducts = async (productGroupId) => {

 
  const getproducts = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product/get-products-by-vendorAndProductGroupIds?vendor_id=${selectedVendor}&product_group_id=${productGroupId}`;

  try {
    const response = await fetch(getproducts, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      setproducts([]);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      throw new Error("Invalid content type received, expected JSON");
    }

    const data = await response.json();

  
    setproducts(data);
    
  } catch (error) {

  }
}; */

const handleSubmit = async () => {

  if(!selectedVendor || !selectedProductGroup) {
    setSubmitCreateOfferForEmptyAlert("Please fill all the required fields.");
    return;
  }

  const CreateOfferURL = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/create-offer`;

  const CreateOfferData = {
    vendor_id: selectedVendor,
    base_price: basePriceInputValue,
    discount: discountInputValue,
    GST_rate_id: selectedGST,
    GST_included: includeGST,
    transport_included: transportInputValue,
    loading_included: loadingInputValue,
    unloading_included: UnloadingInputValue,
    net_price: netpriceInputValue,
    offer_terms: TCInputValue,
    offer_expiry_In_days: OfferexpiryInputValue,
    offer_delivery_In_days: DeliveryTimeInputValue,
    advance_payment_percentage: advancepaymentInputValue,
    final_payment_percentage: finalpaymentInputValue,
    offer_created_person: user.username,
    location: locationInputValue,
    state: SelectedState,
    list_offer:listeOffer,
  };
  // Conditionally add product_group_ids and product_ids based on array existence and non-empty check
  if (selectedProductGroup && selectedProductGroup.length !== 0) {
    CreateOfferData.product_group_ids = selectedProductGroup.map((productGroup) => productGroup._id);
} else {
    CreateOfferData.product_group_ids = '';
}

if (selectedProduct && selectedProduct.length !== 0) {
    CreateOfferData.product_ids = selectedProduct.map((product) => product._id);
} else {
    CreateOfferData.product_ids = '';
}

  
  CreateOfferData.product_category_id = selectedProductCategory;
  

  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(CreateOfferURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
          'x-token' : token
      },
      body: JSON.stringify(CreateOfferData),
    });

    if (response.ok) {
      setSubmitCreateOfferAlert('Offer has been created successfully!');
    } else {
      const data =await  response.json()
      setErrorCreateOfferAlert(data.message)
      // Handle other response statuses if needed
    }
  } catch (error) {
    console.error('Error creating offer:', error);
    clearStorageAndRedirectToHome()
    // Handle error (e.g., show error message to the user)
  }
};

const handleCreateOfferAlert = () => {
  setSubmitCreateOfferAlert(false);
  Navigate("/bb/admin/AdminHome/ViewOffers");
}

useEffect(() => {
  
  setselectedProduct(null) // Reset product groups when selected product category changes
}, [selectedProductCategory]);

const handleOfferAlert=()=>{
 
  setAllert('')
}

const handleSubmitCreateOfferForEmptyAlert = () => {
  setSubmitCreateOfferForEmptyAlert(false);
}

const handleCloseErrorCreateOfferAlert=()=>{
  setErrorCreateOfferAlert(false);
}

const handleChangelistOffer=(liste_offer)=>{
setListeOffer(liste_offer)
}

  return (
    <div className="overflow-y-scroll no-scrollbar w-full  flex flex-col items-center ">
    {SubmitCreateOfferAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
  {/* Background overlay (blurred) */}
  <div className="absolute inset-0 bg-black opacity-30 "></div>

    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-semibold mb-2">{SubmitCreateOfferAlert}</p>
     <button  className="items-center justify-center text-white bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleCreateOfferAlert}>OK</button>
    </div>
  </div>
)}
 {errorCreateOfferAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
  {/* Background overlay (blurred) */}
  <div className="absolute inset-0 bg-black opacity-30 "></div>

    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-semibold mb-2">{errorCreateOfferAlert}</p>
     <button  className="items-center justify-center text-white bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleCloseErrorCreateOfferAlert}>OK</button>
    </div>
  </div>
)}

{SubmitCreateOfferForEmptyAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
  {/* Background overlay (blurred) */}
  <div className="absolute inset-0 bg-black opacity-30 "></div>

    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-semibold mb-2">{SubmitCreateOfferForEmptyAlert}</p>
     <button  className="items-center justify-center text-white bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleSubmitCreateOfferForEmptyAlert}>OK</button>
    </div>
  </div>
)}

{allert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
  {/* Background overlay (blurred) */}
  <div className="absolute inset-0 bg-black opacity-30 "></div>

    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-semibold mb-2">{allert}</p>
     <button  className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleOfferAlert}>OK</button>
    </div>
  </div>
)}
   <div className="flex flex-col items-center justify-center p-10">
   <div className='border border-1 border-gray-500 flex flex-col flex justify-center  items-center  p-10 '>
    {/* <h1 className="text-xl font-bold text-center mb-4">Create Offer</h1> */}
      <div className="flex flex-col">
        <div className='border border-gray-400 p-4'>
      <div className="flex mb-4 space-x-40">
      <div className='flex flex-col'>
              <label htmlFor="locationInput" className="text-md font-medium text-gray-700 mb-2">Location</label>
              <input
                id="locationInput"
                name="locationInput"
                value={locationInputValue}
                onChange={handleLocationInputChange}
                className="focus:outline-none hover:border-[#EB001B] w-60 p-1 border border-gray-300 rounded-sm"
                placeholder="Enter Location"
                />
            </div>
            <div className='flex flex-col'>
              <label htmlFor="state" className="text-md font-medium text-gray-700 mb-2">State</label>
              <select
                id="state"
                name="state"
                value={SelectedState}
                onChange={handleStateInputChange}
                className="w-60 p-1 border border-gray-300 rounded-sm focus:outline-none hover:border-[#EB001B]"
               >
  
    {state.map((state, index) => (
      <option key={index} value={state}>{state}</option>
    ))}
    </select>
            </div>
            </div>
            <div className="flex mb-4 space-x-40">
            <div className="flex flex-col">
  <label htmlFor="vendor" className="text-md font-medium text-gray-700 mb-1">Vendor</label>
  <select
    id="vendor"
    name="vendor"
    className="w-60 p-1 border border-gray-300 rounded-sm focus:outline-none hover:border-[#EB001B]"
    value={selectedVendor || ""}
    onChange={(e) => handleVendorChange(e.target.value)} // Update selected vendor when selection changes
  >
    <option value="" disabled>Select vendor</option>
    {vendors.map((vendor) => (
      <option key={vendor._id} value={vendor._id}> {/* Use vendor ID as the value */}
        {vendor.name}
      </option>
    ))}
  </select>
</div>


<div className='flex flex-col'>
                <label htmlFor="category" className="text-md font-medium text-gray-700 mb-2">Product Category</label>
                <select
          id="category"
          name="category"
          value={selectedProductCategory || ""}
          className="w-60 p-1 border border-gray-300 rounded-sm focus:outline-none hover:border-[#EB001B]"
          onChange={(e) => handleCategoryChange(e.target.value)}
        >
          <option value="" disabled>Select product category</option>
          {poductCategories.length > 0 ? (
            poductCategories.map((poductCategory) => (
              <option key={poductCategory._id} value={poductCategory._id}>{poductCategory.product_category_name}</option>
            ))
          ) : (
            <option>No product categories are assigned</option>
          )}
        </select>
              </div>
            </div>
          </div>
          {selectedProductCategory && selectedProductCategory.length !== 0 && (

          <div className='flex flex-row space-x-4 justify-center items-center mt-4'>
          
            <p className='font-md font-medium text-gray-700'>Select to create offer for :</p>
          
          <div className='flex flex-row space-x-10 '>
          <div className="flex justify-center items-center">
    <div className='px-2 py-1 bg-white border border-1 border-[#EB001B] bg-white rounded-sm cursor-pointer' onClick={()=>handleGroups()}>
        <p className='font-md font-medium text-gray-700'>Group</p>
    </div>
    <p className="mx-2">(or)</p>
    <div className='px-2 py-1 border border-1 border-[#EB001B] bg-white rounded-sm cursor-pointer' onClick={()=> handleProduct()}>
        <p className='font-md font-medium text-gray-700'>Product</p>
    </div>
</div>

          </div>
        </div>
          )}
         

          {productGroups && productGroups.length > 0 && (
        <div className='border border-gray-400 p-4 mt-4 relative'>
          <p className="text-md font-medium text-gray-700 mb-2 absolute -top-4 bg-white px-2">Product Groups</p>   
          <table className="w-full border-collapse border border-gray-300">
            <tbody>
              {productGroups.map((productGroup) => (
                <tr key={productGroup._id}>
                  <td className="border border-gray-300 p-2">{productGroup.product_group_name}</td>
                  <td className="border border-gray-300 p-2 ">
                        <input
                            type="checkbox"
                            className='flex justify-center items-center'
                            value={productGroup}
                            checked={(selectedProductGroup && Array.isArray(selectedProductGroup) && selectedProductGroup.includes(productGroup)) || false}
                            onChange={() => handleProductGroupChange(productGroup)}
                        />
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
{/* 
{(!productGroups || productGroups.length === 0) && selectedProductCategory && (
  <div className="text-gray-500 mt-2">No product groups available for the selected category</div>
)} */}
{productGroups && productGroups.length !== 0 ? (
  <>
    {products && products.length > 0 && (
      <div className='border border-gray-400 p-4 mt-4 relative'>
        <p className="text-md font-medium text-gray-700 mb-2 absolute -top-4 bg-white px-2">Products Under selected Group</p>   
        <table className="w-full border-collapse border border-gray-300">
          <tbody>
            {products.map((product) => (
              <tr key={product._id}>
                <td className="border border-gray-300 p-2">{product.product_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}
  </>
) : (
  <div className=''>
    {products && products.length !== 0 ? (
      <div>
        {products.map((product) => (
          <div key={product._id} className='border border-gray-400 p-4 mt-4 relative'>
            <table className="w-full border-collapse border border-gray-300">
              <tbody>
                <tr>
                  <td className="border border-gray-300 p-2">{product.product_name}</td>
                  <td className="border border-gray-300 p-2">
                    <input
                      type="checkbox"
                      value={product}
                      checked={(selectedProduct && Array.isArray(selectedProduct) && selectedProduct.includes(product)) || false}
                      onChange={() => handleProductCheckboxChange(product)}
                      className="flex justify-center items-center ml-5"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    ) : (
     <></>
    )}
  </div>
)}



          <div className='border border-gray-400 p-4 relative mt-8'> 
          <p className="text-md font-medium text-gray-700 mb-2 absolute -top-4 bg-white px-2">Billing</p>   
          <div className="flex mb-4 space-x-40">
            <div className='flex flex-col'>
              <label htmlFor="basePriceInput" className="px-1 text-md font-medium text-gray-700 mb-2">Base Price</label>
              <input
                id="basePriceInput"
                name="basePriceInput"
                type="text"
                value={basePriceInputValue}
                onChange={handleBasePriceInputChange}
                className="rounded-sm focus:outline-none hover:border-[#EB001B] w-60 p-1 border border-gray-300 rounded-md"
                placeholder="Enter base price"
              />
            </div>
            <div className='flex flex-col relative'>
            <label htmlFor="discountInput" className="text-md font-medium text-gray-700 mb-2">Discount</label>
            <div className='flex items-center'>
    <input
      id="discountInput"
      name="discountInput"
      type="text"
      value={discountInputValue}
      onChange={handleDiscountInputChange}
      className="rounded-sm focus:outline-none hover:border-[#EB001B] w-60 p-1 border border-gray-300 rounded-md" // Adjusted padding to accommodate the icon
      placeholder="Enter discount"
    />
    <FontAwesomeIcon icon={faPercentage} className="absolute left-52 top-12 transform -translate-y-1/2 text-gray-700" />
            </div>
            </div>
          </div>
              
          <div className="flex mb-4 space-x-40">
           
            <div className='flex flex-col'>
              <label htmlFor="categoryGST" className="text-md font-medium text-gray-700 mb-2">GST</label>
              <select
                id="categoryGST"
                name="categoryGST"
                value={selectedGST}
                onChange={handleGSTChange}
                className="rounded-sm focus:outline-none hover:border-[#EB001B] w-60 p-1 border border-gray-300 rounded-md"
              >
                {/* <option value="" disabled>Select GST</option> */}
                {GSTOptions.map((gst, index) => (
                  <option key={index} value={gst}>{gst==='0'?'select Gst':gst} %</option>
                ))}
              </select>
            </div>

            <div className='flex flex-col'>
              <label htmlFor="netpriceInput" className="text-md font-medium text-gray-700 mb-2">Net Price</label>
              <input
                id="netpriceInput"
                name="netpriceInput"
                type="text"
                value={netpriceInputValue}
                onChange={() => {}}
                className="rounded-sm focus:outline-none hover:border-[#EB001B] w-60 p-1 border border-gray-300 rounded-md"
                placeholder="Enter Net Price"
              />
            </div>
          </div>

          <div className="flex justify-between mb-4">
          <div className="flex items-center flex-col">
          <label htmlFor="transport" className="text-md font-medium text-gray-700 mb-2">GST</label>
            <input
              id="includeGST"
              name="includeGST"
              type="checkbox"
              checked={includeGST}
              onChange={handleIncludeGSTChange}
              className='rounded-sm focus:outline-none hover:border-[#EB001B] mb-2'
            />
             <label htmlFor="includeGST" className="text-md font-medium text-gray-700 mb-2">Included</label>
          </div>
          <div className='flex items-center flex-col'>
              <label htmlFor="transport" className="text-md font-medium text-gray-700 mb-2">Transport</label>
              <input
                id="transport"
                name="transport"
                type="checkbox"
                checked={transportInputValue}
                onChange={handleTransportInputChange} 
                className='mb-2'
              />
              <label htmlFor="transport" className="text-md font-medium text-gray-700 mb-2">Included</label>
            </div>
          <div className='flex items-center flex-col'>
            <label htmlFor="loading" className="text-md font-medium text-gray-700 mb-2">Loading</label>
            <input
              id="loading"
              name="loading"
              type="checkbox"
              checked={loadingInputValue}
              onChange={handleLoadingInputChange}
              className='mb-2'
            />
            <label htmlFor="transport" className="text-md font-medium text-gray-700 mb-2">Included</label>
          </div>
          <div className='flex items-center flex-col'>
            <label htmlFor="Unloading" className="text-md font-medium text-gray-700 mb-2">Unloading</label>
            <input
              id="Unloading"
              name="Unloading"
              type="checkbox"
              checked={UnloadingInputValue}
              onChange={handleUnLoadingInputChange}
              className='mb-2'
            />
            <label htmlFor="transport" className="text-md font-medium text-gray-700 mb-2">Included</label>
          </div>
        </div>
        </div>

         
            
        <div className='border border-gray-400 p-4 mt-8 relative'>    
  <p className="text-md font-medium text-gray-700 mb-2 absolute -top-4 bg-white px-2">Payment</p>
  <div className="flex mb-4 space-x-40">
    <div className='flex flex-col'>
      <label htmlFor="offerexpiryInput" className="text-md font-medium text-gray-700 mb-2">Offer Expiry Days</label>
      <input
        id="offerexpiryInput"
        name="offerexpiryInput"
        type="text"
        value={OfferexpiryInputValue}
        onChange={handleOfferexpiryInputChange}
        className="rounded-sm focus:outline-none hover:border-[#EB001B] w-60 p-1 border border-gray-300 rounded-md"
        placeholder="Enter offer expiry time"
      />
    </div>
    <div className='flex flex-col'>
      <label htmlFor="deliverytimeInput" className="text-md font-medium text-gray-700 mb-2">Delivery In Days</label>
      <input
        id="deliverytimeInput"
        name="deliverytimeInput"
        type="text"
        value={DeliveryTimeInputValue}
        onChange={handleDeliveryTimeInputChange}
        className="rounded-sm focus:outline-none hover:border-[#EB001B] w-60 p-1 border border-gray-300 rounded-md"
        placeholder="Enter delivery time"
      />
    </div>
  </div>
 
  <div className="flex mb-4 space-x-40">
    <div className='flex flex-col relative'>
      <label htmlFor="advancepaymentInput" className="text-md font-medium text-gray-700 mb-2">Advance Payment %</label>
      <div className='flex items-center'>
      <input
        id="advancepaymentInput"
        name="advancepaymentInput"
        type="text"
        value={advancepaymentInputValue}
        onChange={handleAdvancepaymentInputChange}
        className="rounded-sm focus:outline-none hover:border-[#EB001B] w-60 p-1 border border-gray-300 rounded-md"
        placeholder="Enter advance payment"
      />
     <FontAwesomeIcon icon={faPercentage} className="absolute left-52 top-12 transform -translate-y-1/2 text-gray-700" />
    </div>
    </div>
    <div className='flex flex-col relative'>
      <label htmlFor="finalpaymentInput" className="text-md font-medium text-gray-700 mb-2">Final Payment %</label>
      <div className='flex items-center'>
      <input
        id="finalpaymentInput"
        name="finalpaymentInput"
        type="text"
        value={finalpaymentInputValue}
        onChange={handleFinalpaymentInputChange}
        className="rounded-sm focus:outline-none hover:border-[#EB001B] w-60 p-1 border border-gray-300 rounded-md"
        placeholder="Enter final payment"
      />
      <FontAwesomeIcon icon={faPercentage} className="absolute left-52 top-12 transform -translate-y-1/2 text-gray-700" />
    </div>
    </div>
  </div>
</div>
<div className='border border-gray-400 p-4 mt-8 relative'>
          <div className="flex mb-4  ">
          <p className="text-md font-medium text-gray-700 mb-2 absolute -top-4 bg-white px-2">List Offer ?</p>
            <div className='flex flex-row space-x-4 justify-center '>
              <div className=''>
              <span className='pt-4 mr-4 '>
              Want to list the OFFER :
             </span>
              </div>
            <div className='flex flex-row space-x-2 justify-center items-center'>
              <label>YES</label>
            <input
                    type="checkbox"
                    checked={listeOffer==='yes'}
                    onClick={() => handleChangelistOffer('yes')}
                    className="form-checkbox text-blue-500 "
                  />
            </div>
            <div className='flex flex-row space-x-2 justify-center items-center'>
              <label>NO</label>
            <input
                    type="checkbox"
                    checked={listeOffer==='no'}
                    onClick={() => handleChangelistOffer('no')}
                    className="form-checkbox text-blue-500 "
                  />
            </div>
             
            </div>
          </div>
          </div>

                  <div className='border border-gray-400 p-4 mt-8 relative'>
          <div className="flex mb-4  ">
          <p className="text-md font-medium text-gray-700 mb-2 absolute -top-4 bg-white px-2">Terms & Conditions</p>
            <div className='flex flex-col'>
             
              <textarea
                id="TCInput"
                name="TCInput"
                type="text"
                value={TCInputValue}
                onChange={handleTCInputChange}
                className="rounded-sm focus:outline-none hover:border-[#EB001B] w-[600px] border border-gray-300 rounded-md"
                
              />
            </div>
          </div>
          </div>
          <div className='flex flex-row items-center justify-center space-x-28 mt-6'>
        <div className='flex justify-center '>
          <button
            className="px-6 py-1 h-30 border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white"
            onClick={() => Navigate("/bb/admin/AdminHome")}
          >
            Cancel
          </button>
        </div>
        <div className='flex justify-center'>
          <button
             type='submit'
            className="px-6 py-1 h-30 border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white"
            onClick={() => handleSubmit()}
          >
             Submit
          </button>
        </div>
        </div>
        </div>
      </div>
      </div>
</div>
  );
};


export default CreateOffer;
