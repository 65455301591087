import React, { useState } from "react";
import NavBar from "./bbUserNavBar";
import { Outlet } from 'react-router-dom';

const MainPage2 = () => {
    return (
        <div>
           
            <div className="fixed z-50 top-0 left-0 w-full bg-white ">
                {/* Your Navbar component */}
                <NavBar />
            </div>
            <div className="mt-16 overflow-y-auto pt-4">
                {/* Outlet for displaying nested routes */}
                <Outlet />
            </div>
        </div>
    );
};

export default MainPage2;
