import React, { useState, useRef , useEffect } from 'react';
import logoImage from "./images/BB.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';


function AddUserDetails() {
    const navigate = useNavigate();
  const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [gst, setGst] = useState('');
    const [association, setAssociation] = useState('');
    const [projectName, setProjectName] = useState('');
    const [address, setAddress] = useState('');
    const [userData, setUserData] = useState({});
    const UpdateCustomerURL = process.env.REACT_APP_FORE_BRICKBUCKET + '/customer/update-customer';
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setemail] = useState('');
    const [firmType, setFirmType] = useState('');
    const [pan, setPan] = useState('');
    const [city, setCity] = useState('');

    const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const atIndex = email.indexOf('@');
      const dotIndex = email.lastIndexOf('.');
      return re.test(String(email).toLowerCase()) && atIndex!== -1 && dotIndex!== -1 && dotIndex > atIndex;
    };

    useEffect(() => {
        const storedData = localStorage.getItem('userData');
        if (storedData) {
          setUserData(JSON.parse(storedData));
        }
      }, []);

  const handleSubmit = async () => {
    const token = sessionStorage.getItem('token');
    // Add your update logic here
    const response = await fetch(UpdateCustomerURL , {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-token':token
      },
      body: JSON.stringify({
        _id : userData._id,
        customer_name: name,
        email : email,
       company_name: companyName,
        gstin : gst,
        association :association,
        type_of_firm : firmType,
        pan : pan,
        city : city,
      }),
    });
    const data = await response.json();
    console.log(data);
    navigate("/bb/user/createproject");
  };

  
        return (
          <div className='mt-5 flex justify-center items-center '>
       <div className="flex justify-center items-center md:w-2/3 sm:w-full">
       <div className="w-1/2 sm:w-full border md:w-2/3 bg-opacity-[15%] rounded-sm p-4">

             <h2 className="text-2xl font-bold text-center mb-4">Enter your Details</h2>
          
             {/* Name Input */}
             <div className="mb-4">
               <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                 Name
               </label>
               <input
                 type="text"
                 id="name"
                 placeholder='Enter your Name'
                 value={name}
                 onChange={(e) => setName(e.target.value)}
                 className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
               />
             </div>   
            {/*  <div className="mb-4">
  <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
    Email
  </label>
  <input
    type="email"
    id="email"
    placeholder='Enter your Email'
    value={email}
    onChange={(e) => setemail(e.target.value)}
    className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
  />
  {email.length > 0 &&!validateEmail(email)? (
    <p className="text-red-500 text-sm">Please enter a valid email address.</p>
  ) : null}
</div>  */}
             {/* Company Name Input */}
             <div className="mb-4">
               <label htmlFor="companyName" className="block text-gray-700 font-bold mb-2">
                 Company Name
               </label>
               <input
                 type="text"
                 id="companyName"
                 placeholder='Enter your Company Name'
                 value={companyName}
                 onChange={(e) => setCompanyName(e.target.value)}
                 className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
               />
             </div>
   
             {/* Firm Type Dropdown */}
             <div className="mb-4">
               <label htmlFor="firmType" className="block text-gray-700 font-bold mb-2">
                 Type of Firm
               </label>
               <select
                 id="firmType"
                 value={firmType}
                 onChange={(e) => setFirmType(e.target.value)}
                 className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
               >
                 <option className="text-gray-400" value="">Select Firm Type</option>
                 {/* Add your firm type options here */}
                 <option value="Sole Proprietorship">Sole Proprietorship</option>
                 <option value="Partnership">Partnership</option>
                 <option value="Limited Liability Partnership (LLP)">Limited Liability Partnership (LLP)</option>
                 <option value="Private Limited Company">Private Limited Company</option>
                 {/* Add more options as needed */}
               </select>
             </div>
             {/* Association Input */}
             <div className="mb-4">
               <label htmlFor="association" className="block text-gray-700 font-bold mb-2">
                 Association
               </label>
               <select
                 type="text"
                 placeholder='Select your Association'
                 id="association"
                 value={association}
                 onChange={(e) => setAssociation(e.target.value)}
                 className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline">
               <option className="text-gray-400" value="">Select Association</option>
                 <option value="NAREDCOp">NAREDCO</option>
                 <option value="CREDAI">CREDAI</option>
                 <option value="BAI">BAI</option>
                 </select>
             </div>
               {/* City Input */}
               {/* <div className="mb-4">
               <label htmlFor="city" className="block text-gray-700 font-bold mb-2">
                 City
               </label>
               <input
                 type="text"
                 id="city"
                 placeholder='Enter your City'
                 value={city}
                 onChange={(e) => setCity(e.target.value)}
                 className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
               />
             </div> */}
             {/* GST Input (Optional) */}
  {/*  <div className="mb-4">
     <div className='flex flex-row'>
     <label htmlFor="gst" className="block text-gray-700 font-bold mb-2">
       GST 
     </label>
     <label className="text-gray-400">(Optional)</label>
     </div>
     <input
       type="text"
       id="gst"
       placeholder='Enter your GST Number '
       value={gst}
       onChange={(e) => {
         const gstValue = e.target.value.replace(/[^0-9a-zA-Z]/g, ''); // Allow numbers and letters
         if (gstValue.length <= 15) {
           setGst(gstValue);
         }
       }}
       className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
       maxLength={15}
     />
     {gst.length > 0 && gst.length < 15? (
       <p className="text-red-500 text-sm">GST must be exactly 15 digits.</p>
     ) : null}
   </div> */}
   
             {/* PAN Input */}
             {/* <div className="mb-4">
             <div className='flex flex-row'>
               <label htmlFor="pan" className="block text-gray-700 font-bold mb-2">
                 PAN
               </label>
               <label className="text-gray-400">(Optional)</label>
               </div>
                <input
       type="text"
       id="pan"
       placeholder='Enter your PAN '
       value={pan}
       onChange={(e) => {
         const panValue = e.target.value.replace(/[^0-9a-zA-Z]/g, ''); // Allow numbers and letters
         if (panValue.length <= 10) {
           setPan(panValue);
         }
       }}
       className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
       maxLength={15}
     />
     {pan.length > 0 && pan.length < 10? (
       <p className="text-red-500 text-sm">PAN must be exactly 10 digits.</p>
     ) : null}
             </div>  */}  
             {/* Submit Button */}
             <div className="focus:outline-none flex justify-center items-center mt-6">
               <button
                 className="border border-[#EB001B] hover:bg-[#EB001B] text-black hover:text-white text-md rounded-sm px-4 py-1 mb-4"
                 onClick={handleSubmit}
               >
                 Submit
               </button>
             </div>
           </div>
         </div>
       </div>
        );
    }

  export default AddUserDetails;