import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';

// Define the Modal component
const Modal = ({ product, onClose }) => {
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
    return (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-sm z-50">
        <div className="bg-white p-4 rounded-lg">
          <img src={`${ImageUrl}${product.product_image}`} alt={product.product_name} className="h-40 w-auto mx-auto" />
          
          <p className="text-center mt-2">{product.product_name}</p>
          <button className="block mx-auto mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg" onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };

const SavedQuotation = () => {
    const [offers, setOffers] = useState([]);
        const [products, setProducts] = useState([]);
        const [error, setError] = useState(null);
        const [selectedProductGroupOffer, setSelectedProductGroupOffer] = useState([]);
        const [categoryData, setCategoryData] = useState({});
        const [projectName, setProjectName] = useState('');
        const [quotations, setQuotations] = useState([]);
        const navigate = useNavigate();
        const [selectedProducts,setselectedProductsData] = useState([]);
        const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
        const location = useLocation();
const { selectedProjectIds, selectedProductCategoryId, selectedProductCategoryName, selectedRFQId , selectedCustomerId } = location.state;
    console.log(selectedCustomerId , selectedRFQId ,selectedProjectIds, selectedProductCategoryId, selectedProductCategoryName );
    

    const [selectedProductForModal, setSelectedProductForModal] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);


    useEffect(() => {
       const userData=JSON.parse (localStorage.getItem('userData'))
        //fetchProducts(); // Fetch products when the component mounts
        fetchOffers();
        fetchSavedQuotation();
        //fetchSelectedProducts(userData._id);
    }, [selectedProjectIds]);

    const fetchSavedQuotation = async () => {
        try{
            const token = sessionStorage.getItem('token');

            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/get-customer-quotation-data-by-RFQAndCustomerIds?RFQ_id=${selectedRFQId}&customer_id=${selectedCustomerId}`,{
                method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token':token
            },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch quotations');
            }
            const data = await response.json();
            setQuotations(data.QuotationData);
            console.log(data);
            calculateTotalPrice();
        } catch (error) {
            console.error('Error fetching quotations:', error.message);
            setError(error.message);
            clearStorageAndRedirectToHome()
        }
    };

/* fetching of a selected products must be done by using customer id and projects id along with product group id as well*/ 

/*   const fetchSelectedProducts = async (customer_id) => {
        console.log(customer_id);
    try {
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/Quotation-selections/get-customer-selections-for-quotation-by-customerAndProductCategoryIds?product_category_id=${selectedProductCategoryId}&customer_id=${customer_id}`);
      if (!response.ok) {
        setselectedProductsData([])
        throw new Error('Failed to fetch selected products');
      }
      const data = await response.json();
      
      const projectSelectionData = data.customerSelectionData.filter(item =>
        item.project_ids.some(projectId => selectedProjectIds.includes(projectId))
      );
      console.log(projectSelectionData);

      
      setselectedProductsData(projectSelectionData)
    } catch (error) {
      console.error('Error fetching selected products:', error);
      setError(error.message);
    }
  };  */


   // Fetch product data from the API using customer ID from local storage
/* const fetchProducts = async () => {
    console.log( selectedProductCategoryId)
    try {
        // Retrieve customer ID from local storage
        const userData = JSON.parse(localStorage.getItem('userData'));
        const customerId = userData?._id;
     
        if (!customerId) {
            throw new Error('Customer ID not found in local storage');
        }

        // Make GET request to API endpoint with customer ID
        const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/Quotation-selections/get-customer-products-by-customerAndProductCategoryIds?customer_id=${customerId}&product_category_id=${selectedProductCategoryId}`);

        if (!response.ok) {
            throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        console.log(data)
        setProducts(data.products);

    } catch (error) {
        console.error('Error fetching products:', error.message);
        setError(error.message);

    }
};  */



/*     useEffect(() => {
        // Calculate and set price and estimated price for the default selection
        Object.entries(categoryData).forEach(([key, value]) => {
            const defaultSelection = offers[0]?.productData.product?.product_name || offers[0]?.productGroup?.product_group_name;
            const selectedOffer = offers.find(offer =>
                (offer.productData.product && offer.productData.product.product_name === defaultSelection) ||
                (offer.productGroup && offer.productGroup.product_group_name === defaultSelection)
            );
            if (selectedOffer) {
                setSelectedProductGroupOffer(prevState => ({
                    ...prevState,
                    [key]: defaultSelection
                }));
            }
        });
    }, [categoryData, offers]); */



    const fetchOffers = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/get-offers-by-productCategoryId?product_category_id=${selectedProductCategoryId}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token':token
            },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch offers');
            }

            const data = await response.json();
            
            setOffers(data.filter(offer => (offer.productGroup && offer.productGroup.product_group_name) || (offer.productData.product && offer.productData.product.product_name))); // Filter out offers with null product_group_name and null product_name

        } catch (error) {
            console.error('Error fetching offers:', error.message);
            setError(error.message);
            clearStorageAndRedirectToHome()
        }
    };



    // Function to save or update quotation data
    const saveQuotationData = async (quotationData) => {
        try {
            const token = sessionStorage.getItem('token');
            const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/save-customer-quotation-data`;

            // Map over quotationData array to include _id in each element
            const requestBody = quotationData.map(item => ({
                ...item,
                _id: item._id || undefined // Include _id if it exists, otherwise exclude it
            }));

            const response = await fetch(url, {
                method: 'POST', // Always use POST method
                headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error('Failed to save or update quotation data.');
            }

            const data = await response.json();

            // Clear local storage of selected items
        localStorage.removeItem('selectedProductGroupOffer');

        // Navigate to "My Quotations" page
        navigate('/bb/user/MyQuotations');
          
        } catch (error) {
            console.error('Error  updating quotation data:', error.message);
            // Handle error (e.g., display error message to the user)
            clearStorageAndRedirectToHome()
        }
    };

    const handleUpdateQuotation = async () => {
        try {
            // Retrieve customer ID from local storage
            const userData = JSON.parse(localStorage.getItem('userData'));
            const customerId = userData?._id; // Retrieve customerId from userData
           
            // Check if required data is available
            if (!Object.keys(categoryData).length || !Object.keys(selectedProductGroupOffer).length || !offers.length || !customerId) {
                console.error('Data required for PDF generation is missing.');
                return;
            }
    
            // Initialize total amount
            let totalAmount = 0;
    

            // Assuming you have access to customer_id, offer_id, project_id, and room_area_type
            const quotationData = [];
            Object.entries(categoryData).forEach(([key, value]) => {
                const selectedItem = selectedProductGroupOffer[key];
                const selectedOffer = offers.find(offer =>
                    (offer.productData.product && offer.productData.product.product_name === selectedItem) ||
                    (offer.productGroup && offer.productGroup.product_group_name === selectedItem)
                );
                if (selectedOffer) {
                    const quotationItem = {
                        customer_id: customerId, // Use customerId here
                        offer_id: selectedOffer._id,
                        project_ids: selectedProjectIds,
                        room_area_type: key,
                        product_category_id: selectedProductCategoryId
                    };
                    quotationData.push(quotationItem);
                    // Calculate price for this item and add to total amount
                    const price = ((selectedOffer.OfferData.base_price || selectedOffer.OfferData.base_price) * value);
                    totalAmount += price;
                }
            });
            // Save quotationData or perform further actions as needed
          
    
            // Create a new jsPDF instance
            const doc = new jsPDF();
            let y = 20; // Initial y-coordinate for placing text
    
            // Add project name as heading
            doc.setFontSize(16);
            doc.text(projectName, 10, y);
            y += 10;
    
            // Create data array for table
            const tableData = [['Category', 'Area (Sq.ft)', 'Selected Product', 'Estimated Price']];
            Object.entries(categoryData).forEach(([key, value]) => {
                const selectedItem = selectedProductGroupOffer[key];
                const selectedOffer = offers.find(offer =>
                    (offer.productData.product && offer.productData.product.product_name === selectedItem) ||
                    (offer.productGroup && offer.productGroup.product_group_name === selectedItem)
                );
                if (selectedOffer) {
                    const price = ((selectedOffer.OfferData.base_price || selectedOffer.OfferData.base_price) * value).toFixed(2);
                    tableData.push([key, `${value} Sq.ft`, selectedItem, `₹${price}`]);
                }
            });
    
            // Create table
            doc.autoTable({
                startY: y,
                head: [['Category', 'Area (Sq.ft)', 'Selected Product', 'Estimated Price']],
                body: tableData,
                theme: 'grid', // Apply grid theme for borders
                margin: { top: 20 },
                columnStyles: {
                    0: { cellWidth: 50 },
                    1: { cellWidth: 25 },
                    2: { cellWidth: 50 },
                    3: { cellWidth: 25 },
                },
            });
    
            // Add total amount below the table
            doc.setFontSize(14);
            doc.text(`Total Amount: ₹${totalAmount.toFixed(2)}`, 10, doc.autoTable.previous.finalY + 10);
    
            // Save PDF
            const filename = 'quotation.pdf';
            doc.save(filename);
    
         
    
            // Save or update quotation data
            await saveQuotationData(quotationData);
            
        } catch (error) {
            console.error('Error generating PDF:', error);
            // Handle error (e.g., display error message to the user)
            clearStorageAndRedirectToHome()
        }
    };
    useEffect(() => {
        // Retrieve selected items from local storage when component mounts
        const storedselectedProductGroupOffer = JSON.parse(localStorage.getItem('selectedProductGroupOffer'));
        if (storedselectedProductGroupOffer) {
            setSelectedProductGroupOffer(storedselectedProductGroupOffer);
        }
    }, []);

    // Function to handle selection of product in the quotation data
    const handleQuotationItemSelected = (roomAreaType, value) => {
        setSelectedProductGroupOffer(prevState => ({
            ...prevState,
            [roomAreaType]: value
        }));
          // Calculate and update the total price
    calculateTotalPrice();
    };


useEffect (() => {
    calculateTotalPrice();
} ,[offers, selectedProductGroupOffer ,categoryData]);

const handleItemSelected = (roomAreaType, value) => {
    setSelectedProductGroupOffer(prevState => ({
        ...prevState,
        [roomAreaType]: value
    }));
    // Calculate and update the total price
    calculateTotalPrice();

    // Store selected items in local storage
    localStorage.setItem('selectedProductGroupOffer', JSON.stringify({
        ...selectedProductGroupOffer,
        [roomAreaType]: value
    }));
};



const navigateToProductsPage = (selectedOffer,key) => {
    
    navigate('/bb/user/Quotationproducts', {
        state: {
            
            selectedProductGroupId: selectedOffer.productGroup ? selectedOffer.productGroup._id : null,
            selectedProductId: selectedOffer.productData.product ? selectedOffer.productData.product._id : null,
            selectedRoomAreaType:key,
            projectIds:selectedProjectIds,
            offer:selectedOffer,
            
            
        }
        
    });
    
};




const calculateTotalPrice = () => {
    let totalPrice = 0;

    // Iterate over the selected items and calculate the total price
    Object.entries(selectedProductGroupOffer).forEach(([roomAreaType, selectedProductName]) => {
        const selectedOffer = offers.find(offer =>
            (offer.productData.product && offer.productData.product.product_name === selectedProductName) ||
            (offer.productGroup && offer.productGroup.product_group_name === selectedProductName)
        );

        if (selectedOffer) {
            // Calculate the estimated price based on the selected offer's base price and area
            const estimatedPrice = (selectedOffer.OfferData.base_price * categoryData[roomAreaType]);
            totalPrice += estimatedPrice;
        }
    });

    // Update the state with the new total price
    setTotalPrice(totalPrice.toFixed(2));
};


function clearStorageAndRedirectToHome() {
    navigate("/bb/ErrorPage");
  }

const handleImageClick = (p) => {
    setSelectedProductForModal(p);
  };

  const handleCloseModal = () => {
    setSelectedProductForModal(null);
  };

  const renderModal = () => {
    if (selectedProductForModal) {
      
      return <Modal product={selectedProductForModal} onClose={handleCloseModal} />;
    }
    return null;
  };

    return (
        <div className='flex justify-center items-center p-2 mt-2'>
        <div className="md:w-2/3 sm:w-full">
            <p className="text-xl font-semibold flex justify-center items-center p-1">{projectName}</p>
            <p className="text-md flex justify-center items-center">({selectedProductCategoryName} Quotation)</p>
            <hr className="border-gray-400 my-1" />
            <div className="bg-[#FFFFFF] p-2">

            {Object.entries(categoryData)
            .map(([key, value], index) => (
    <div key={key}>
        <div className='flex flex-row justify-around '>
            <div className="flex flex-col ">
                <div className=' flex flex-col space-y-2 justify-around items-start'>
                    <div className='flex justify-start mt-2'>
                        <p className='text-xl font-semibold '>{key}</p>
                    </div>
                    <div className=''>
                        <select
                            className="border border-gray-300 rounded-md p-2 cursor-pointer bg-[#FFFFFF] hover:border-[#000000] focus:outline-none"
                            value={selectedProductGroupOffer[key] || ''}
                            onChange={(e) => handleItemSelected(key, e.target.value)}
                        >
                            <option value="">Select {selectedProductCategoryName} </option> {/* Add this empty option */}
                            {/* Render other options */}
                            {offers.map((offer) => (
                                offer.productData.product && offer.productData.product.product_name &&
                                <option key={offer.productData.product._id} value={offer.productData.product.product_name}>
                                    {offer.productData.product.product_name}
                                </option>
                            ))}
                            {offers.map((offer) => (
                                (!offer.productData.product || !offer.productData.product.product_name) &&
                                offer.productGroup && offer.productGroup.product_group_name &&
                                <option key={offer.productGroup._id} value={offer.productGroup.product_group_name}>
                                    {offer.productGroup.product_group_name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className='space-y-2 flex flex-col'>
                <div className='flex flex-row justify-center'>
                    <div className='space-x-1 justify-center flex flex-row mt-2'>
                    {key ? (
                        <p className="text-lg ">{value}</p>
                    ) : (
                        <p className="text-lg ">0.00</p>
                    )}

                        <p className='text-xs  pt-1'>Sq.ft</p>
                    </div>
                    <div className=''>
                    {selectedProductGroupOffer[key] ? (
    <div className="space-x-1 flex flex-row mt-2">
        <p className="text-lg ml-10">₹{(((offers.find(offer => offer.productData.product && offer.productData.product.product_name === selectedProductGroupOffer[key])?.OfferData.base_price || offers.find(offer => offer.productGroup && offer.productGroup.product_group_name === selectedProductGroupOffer[key])?.OfferData.base_price)).toFixed(2))}</p>
        <p className='text-xs pt-1 '>per Sq.ft</p>
    </div>
) : (
    <div className="space-x-1 flex flex-row mt-2">
        <p className="text-lg ml-10">₹0.00</p>
        <p className='text-xs pt-1 '>per Sq.ft</p>
    </div>
)}

                    </div>
                </div>
{/*  My selections has been commented */}
                <div className="flex justify-center items-center">
    <div className="flex flex-col md:w-2/3 sm:w-full">
        {selectedProductGroupOffer[key] && (
            <div>
                <div className='w-full flex justify-center items-center mb-3'>
                {/* <p className='text-[14px] text-gray-500'>My selections</p> */}</div>
                <div className="flex justify-center flex-row">
                {selectedProducts.filter(p => p.room_area_type === key).map((product, index) => (
    <div key={index}>
        {/* Use find() to get the matched product */}
        {products.find(p => p._id === product.product_id ) && (
            <div key={product.product_id} className="flex justify-center">
                {/* <img
                    src={`${ImageUrl}${products.find(p => p._id === product.product_id ).product_image}`}
                    alt={products.find(p => p._id === product.product_id ).product_name}
                    className="mr-1 h-6 cursor-pointer"
                    onClick={() => handleImageClick(products.find(p => p._id === product.product_id ))}
                /> */}
            </div>
        )}
    </div>
))}
                </div>
                {renderModal()}
            </div>
        )}
    </div>
</div>
{/*  My selections has been commented */}
 </div>

            <div className=' mt-2'>
            {selectedProductGroupOffer[key] ? (
    <p className="text-xl flex justify-end font-bold">₹{(((offers.find(offer => offer.productData.product && offer.productData.product.product_name === selectedProductGroupOffer[key])?.OfferData.base_price || offers.find(offer => offer.productGroup && offer.productGroup.product_group_name === selectedProductGroupOffer[key])?.OfferData.base_price) * value)).toFixed(2)}</p>
) : (
    <p className="text-xl flex justify-end font-bold">₹0.00</p>
)}

                <div className='flex justify-center mt-12'>
                    <button
                        type='submit'
                        className={` text-md text-blue-500 ${!selectedProductGroupOffer[key] ? 'text-md text-gray-400' : ''}`}
                        disabled={!selectedProductGroupOffer[key]}
                        onClick={() => {
                            const selectedOffer = offers.find(offer =>
                                (offer.productData.product && offer.productData.product.product_name === selectedProductGroupOffer[key]) ||
                                (offer.productGroup && offer.productGroup.product_group_name === selectedProductGroupOffer[key])
                            );
                            navigateToProductsPage(selectedOffer, key); // Pass the selected offer
                            
                        }}
                    >
                        View Collections
                    </button>
                </div>
            </div>
        </div>
        {index !== Object.entries(categoryData).length - 1 && <hr className="border-gray-400 my-1 mt-4" />} {/* Line separator */}
    </div>
))}

    
            </div>
 <hr className="border-gray-400 my-1 mt-2" />
            <div className='pr-10  flex justify-end'>
                <p className='text-lg font-normal' >Total:</p>
               <p className='text-xl font-semibold'> ₹{totalPrice}
                </p>
            </div>
           
            <div className='flex justify-center items-center p-3'>
                <button
                    type='button'
                    className="px-6 py-1 h-30 border border-1 hover:border-[#000000] bg-[#FFFFFF] rounded-lg"
                    onClick={handleUpdateQuotation}
                >
                   Update & Generate PDF
                </button>
            </div>
        </div>
    </div>
);

};

export default SavedQuotation;
