import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function CreateVendor() {
    const Navigate = useNavigate();
  const [vendorDetails, setVendorDetails] = useState({
    name: '',
    company_name:'',
    gstin: '',
    pan: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
  });
  const [RequiredAlert, setRequiredAlert] = useState(false);
  const [errorAlert,SeterrorAlert]=useState(false);

  /* useEffect(() => {
    // Retrieve vendor details from session storage if available
    const storedVendorDetails = sessionStorage.getItem('vendorDetails');
    if (storedVendorDetails) {
      setVendorDetails(JSON.parse(storedVendorDetails));
    }
    //console.log(storedVendorDetails);
  }, []); */

  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
       Navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
       Navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })

  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/bb/admin';
  }

  const handleVendorDetailsChange = (field, value) => {
    setVendorDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const url = process.env.REACT_APP_FORE_BRICKBUCKET + '/Admin/create-vendor';

  function validateGSTIN(gstin) {
    const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    
    if (gstinRegex.test(gstin)) {
        return true;
    } else {
        return false;
    }
}

function validatePAN(pan) {
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  
  if (panRegex.test(pan)) {
      return true;
  } else {
      return false;
  }
}



function validatePINCode(pinCode) {
  const pinCodeRegex = /^[1-9][0-9]{5}$/;
  
  if (pinCodeRegex.test(pinCode)) {
      return true;
  } else {
      return false;
  }
}

  const handleFormSubmit = async () => {


    if (!vendorDetails.name || !vendorDetails.company_name || !vendorDetails.address || !vendorDetails.pan ||
       !vendorDetails.gstin || !vendorDetails.city || !vendorDetails.state || !vendorDetails.pincode) {
        setRequiredAlert('Please fill in all the required fields.');
      return; // Exit function if any required field is empty
  }

   if(!validateGSTIN(vendorDetails.gstin)){
    setRequiredAlert('give a valid gst number')
    return;
   }

if(!validatePAN(vendorDetails.pan)){
  setRequiredAlert('give a valid pan number')
    return;
}

if(!validatePINCode(vendorDetails.pincode)){
  setRequiredAlert('give a valid pin number')
    return;
}


    //sessionStorage.setItem('vendorDetails', JSON.stringify(vendorDetails));
    const token = sessionStorage.getItem('token');
    try {
                const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                 'x-token' : token
            },
            body: JSON.stringify(vendorDetails),
        };
        const response = await fetch(url, options);
        if (response.ok) {
          const responseData = await response.json();
          Navigate('/bb/admin/AdminHome/UpdateBankDetails', { state: { responseData } });
         
        }else{
          const body = await response.json();
          console.error(body.message||'')
          SeterrorAlert(body.message||'')
           // throw new Error('Failed to submit form. Server returned status ' + response.status);
        }
        
    } catch (error) {
      clearStorageAndRedirectToHome();
        // Handle error if necessary
    }
};

const handlecloseAlert = () => {
  setRequiredAlert(false);
}

const handlecloseErrorAlert =()=>{
  SeterrorAlert(false)
}

  return (
    <div className="w-full flex flex-col items-center justify-center">
    <div className="flex flex-col items-center justify-center border border-gray-400  p-10 ">
        {/* <div>
      <h2 className="flex justify-center text-xl font-semibold mb-4">Create Vendor</h2>
      </div> */}
      {/* Vendor Name row outside the table */}
      

      {/* GSTIN and PAN Number row */}
      <div className="flex space-x-4">
  {/* First Column */}
  <div className="flex flex-col">
    <label htmlFor="vendorName" className="text-md font-medium text-gray-700 mb-2">Vendor Name</label>
    <input
      type="text"
      id="name"
      name="name"
      className="border border-gray-300 p-1 hover:border-[#EB001B] focus:outline-none"
      placeholder="Enter Vendor Name"
      value={vendorDetails.name || ''}
      onChange={(e) => handleVendorDetailsChange('name', e.target.value)}
    />
  </div>

  {/* Second Column */}
  <div className="flex flex-col">
    <label htmlFor="company_name" className="text-md font-medium text-gray-700 mb-2">Company Name</label>
    <input
      type="text"
      id="company_name"
      name="company_name"
      className="border border-gray-300 p-1 hover:border-[#EB001B] focus:outline-none"
      placeholder="Enter Company Name"
      value={vendorDetails.company_name || ''}
      onChange={(e) => handleVendorDetailsChange('company_name', e.target.value)}
    />
  </div>
</div>

<div className="flex mt-4 space-x-4">
  {/* First Column */}
  <div className="flex flex-col">
    <label htmlFor="gstiNumber" className="text-md font-medium text-gray-700 mb-2">GSTI Number</label>
    <input
      type="text"
      id="gstin"
      name="gstin"
      className="border border-gray-300 p-1 hover:border-[#EB001B] focus:outline-none"
      placeholder="Enter GSTI Number"
      value={vendorDetails.gstin || ''}
      onChange={(e) => handleVendorDetailsChange('gstin', e.target.value)}
    />
  </div>

  {/* Second Column */}
  <div className="flex flex-col ">
    <label htmlFor="pan" className="text-md font-medium text-gray-700 mb-2">PAN</label>
    <input
      type="text"
      id="pan"
      name="pan"
      className="border border-gray-300 p-1 hover:border-[#EB001B] focus:outline-none"
      placeholder="Enter PAN"
      value={vendorDetails.pan || ''}
      onChange={(e) => handleVendorDetailsChange('pan', e.target.value)}
    />
  </div>
</div>

{/* Address and City row */}
<div className="flex mt-4 space-x-4">
  {/* First Column */}
  <div className="flex flex-col">
    <label htmlFor="address" className="text-md font-medium text-gray-700 mb-2">Address</label>
    <input
      type="text"
      id="address"
      name="address"
      className="border border-gray-300 p-1 hover:border-[#EB001B] focus:outline-none"
      placeholder="Enter Address"
      value={vendorDetails.address || ''}
      onChange={(e) => handleVendorDetailsChange('address', e.target.value)}
    />
  </div>

  {/* Second Column */}
  <div className="flex flex-col">
    <label htmlFor="city" className="text-md font-medium text-gray-700 mb-2">City</label>
    <input
      type="text"
      id="city"
      name="city"
      className="border border-gray-300 p-1 hover:border-[#EB001B] focus:outline-none"
      placeholder="Enter City"
      value={vendorDetails.city || ''}
      onChange={(e) => handleVendorDetailsChange('city', e.target.value)}
    />
  </div>
</div>

{/* State and Pincode row */}
<div className="flex mt-4 space-x-4">
  {/* First Column */}
  <div className="flex flex-col">
    <label htmlFor="state" className="text-md font-medium text-gray-700 mb-2">State</label>
    <input
      type="text"
      id="state"
      name="state"
      className="border border-gray-300 p-1 hover:border-[#EB001B] focus:outline-none"
      placeholder="Enter State"
      value={vendorDetails.state || ''}
      onChange={(e) => handleVendorDetailsChange('state', e.target.value)}
    />
  </div>

  {/* Second Column */}
  <div className="flex flex-col ">
    <label htmlFor="pincode" className="text-md font-medium text-gray-700 mb-2">Pincode</label>
    <input
      type="text"
      id="pincode"
      name="pincode"
      className="border border-gray-300 p-1 hover:border-[#EB001B] focus:outline-none"
      placeholder="Enter Pincode"
      value={vendorDetails.pincode || ''}
      onChange={(e) => handleVendorDetailsChange('pincode', e.target.value)}
    />
  </div>
</div>

      {/* Next button row outside the table */}
      <div className="flex justify-center mt-4">
        <button
          type="button"
          onClick={handleFormSubmit}
          className="px-6 py-1  border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white"
        >
          Next
        </button>
      </div>
    </div>
    {RequiredAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{RequiredAlert}</p>
      <div className="flex">
        <button className="text-white bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handlecloseAlert}>OK</button>
      </div>
    </div>
  </div>
)}
  
  {errorAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{errorAlert}</p>
      <div className="flex">
        <button className="text-white bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handlecloseErrorAlert}>OK</button>
      </div>
    </div>
  </div>
)}

    </div>
  );
}

export default CreateVendor;
