import { faImage, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, json } from 'react-router-dom';

function ViewQuotation() {
    const navigate = useNavigate();
    const location = useLocation(); // Import useLocation hook
    const [data, setData] = useState(null);
    const [OffersData, setOffersData] = useState(null);
    console.log(OffersData);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
    const { selectedQuotationId , selectedProductCategoryName } = location.state || [];
    console.log(selectedQuotationId , selectedProductCategoryName)
    const [totalPriceSum, setTotalPriceSum] = useState(0); // State for total price sum
    const [showPopup, setShowPopup] = useState(false);
   /*  const state = [ 'Select State','Telangana', 'Andhra Pradesh'];
    const city = [ 'Select City','Hyderabad', 'Vijayawada']; */
    const [projects, setProjects] = useState([]);
    const [displayedProjects, setDisplayedProjects] = useState([]);
    const [mobileNumber, setMobileNumber] = useState('');
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [streetAddress, setStreetAddress] = useState('');
    const [landMark,setlandMark]= useState('')
    const states = ['Select State', 'Telangana', 'Andhra Pradesh'];
    const cities = {
        Telangana: ['Select City', 'Hyderabad'],
        'Andhra Pradesh': ['Select City', 'Vijayawada']
    };
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedState, setSelectedState] = useState('Select State');
    const [selectedCity, setSelectedCity] = useState('Select city');
    const [availableCities, setAvailableCities] = useState(['Select City']);

    const handleStateChange = (event) => {
        const state = event.target.value;
        setSelectedState(state);
        setAvailableCities(cities[state] || ['Select City']);
    };
 
    const handleCityChange = (event )=>{
        const city = event.target.value;
        setSelectedCity(city);
     
    }
    
    useEffect(() => {
        async function fetchData() {
            try {
              const token = sessionStorage.getItem('token');

                const getData = await fetch(`${backendApiUrl}/customer/quotation/get-quotation-offers-groupData-and-productData-for-order?quotation_id=${selectedQuotationId}`);
                const response = await fetch(getData, {
                  method: 'GET',
                  headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      'x-token':token
                  },
              });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                console.log(result);
                setData(result.quotation);
                setOffersData(result.OffersData);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [selectedQuotationId, backendApiUrl]);

    useEffect(() => {
        if (data && Array.isArray(data)) { // Add this conditional check
            let sum = 0;
            data.forEach(item => {
                item.quotation.quotationData.forEach(qd => {
                    const product = item.products.find(p => p._id === qd.product_id);
                    const price = calculatePrice(product, item);
                    const totalPrice = qd.count * parseFloat(price);
                    sum += totalPrice;
                });
            });
            setTotalPriceSum(sum.toFixed(2)); // Set the total price sum
        }
    }, [data]);
    

    useEffect(() => {
        // Retrieve mobile number from local storage
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData && userData.mobile_number) {
            setMobileNumber(userData.mobile_number);
        }
    }, []);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
              const token = sessionStorage.getItem('token');

                const GetProjects = `${backendApiUrl}/customer/get-customer-projects?mobile_number=${mobileNumber}`;
                const response = await fetch(GetProjects, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'x-token':token
                    },
                });

                if (response.ok) {
                    const projectsData = await response.json();
                    const reversedData = projectsData.customerProjects.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                    const sortedProjects = reversedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                    setProjects(sortedProjects);
                    setDisplayedProjects(sortedProjects);
                    console.log(sortedProjects);
                } else {
                    console.error('Failed to fetch projects:', response.statusText);
                }
            } catch (error) {
                console.error('Failed to fetch projects:', error);
            } finally {
                setLoading(false);
            }
        };

        if (mobileNumber) {
            fetchProjects();
        }
    }, [backendApiUrl, mobileNumber]);

    const calculatePrice = (product, quotation) => {
        const Quotationdata = quotation.quotation.quotationData;
        const quotationArray = Quotationdata.find(p => p.product_id === product._id);
        const offerId = quotationArray.offer_id;

        const offer = quotation.offers.find(p => p._id === offerId);
        if (!product || !offer) {
            return 'Price Not Available';
        }
        const basePrice = parseFloat(product.product_price || 0);
        const discount = parseFloat(offer.discount || 0);
        const gst = parseFloat(offer.GST_rate_id || 0);

        let netPrice = parseFloat(basePrice);

        // Apply discount
        netPrice -= netPrice * (discount / 100);

        // Apply GST
        netPrice *= 1 + gst / 100;
        return netPrice.toFixed(2);
    };

    const handleViewQuotation = () => {
        setShowPopup(true);
    }




  const calculateTotalValue = (parentValue) => {
    if (typeof parentValue === 'object') {
      return Object.values(parentValue)
        .filter(value => value && value.value && !isNaN(value.value))
        .reduce((acc, val) => acc + parseFloat(val.value), 0).toFixed(2);
    }
    return parentValue;
  };

  const handleBack = () =>{
    navigate(-1);
  }

  const handleAddPriceAndUom = (quotation) => {
    console.log(quotation);
    let totalSum = 0; // Initialize the total sum
  
    const extractOfferIds = (data) => {
      for (const key in data) {
        if (data[key] && typeof data[key] === 'object') {
          if (data[key].offerid) {
            const Totalprice = calculateTotalPrice(data[key].netPrice, data[key].value, data[key].count, data[key].UOM);
            totalSum += Totalprice; // Add the Totalprice to the running total
          }
          extractOfferIds(data[key]);
        }
      }
    };
  
    // Start extracting offer ids
    extractOfferIds(quotation);
    
    // Return the final total sum
    return totalSum.toFixed(2);
  };
  
  const calculateTotalPrice = (netPrice, area, numbers, UOM) => {
    let totalprice = 0;
    
    if (UOM === 'No') {
      totalprice = parseFloat(netPrice) * parseFloat(numbers);
    } else if (UOM === 'Sq.ft') {
      totalprice = parseFloat(netPrice) * parseFloat(area);
    }
    
    return totalprice;
  };


    return (
        <div className="w-full h-full mx-auto overflow-y-scroll no-scrollbar">
        <div className="rounded-md justify-start items-start overflow-y-auto">
        
        <div className="p-1 flex flex-col justify-between items-center">
        <div className='flex-grow w-2/3  flex justify-center'>
        <h1 className="font-semibold">{selectedProductCategoryName + "  " +'quotation'}</h1>
    </div>
    <div className='flex flex-col w-2/3'>
    {data && data.quotationData && Object.entries(data.quotationData).map(([parentKey, parentValue]) => (
  <div key={parentKey} className="mb-4  ">
    <div className='space-x-5    font-semibold flex flex-row justify-center items-center mt-2 '>
    {!Object.keys(parentValue).some(childKey => childKey === '0' || childKey === '1') && (
    Object.entries(parentValue).some(([childKey]) => childKey !== 'Dummy') && (
    <div className='flex flex-row border-gray-400 items-center w-full justify-center items-center '>
  {(parentValue && typeof parentValue === 'object' && Object.keys(parentValue).length >= 2 && parentValue.hasOwnProperty('value'))? null : (
    <h2 className='text-xl font-bold mr-2'>{parentKey}</h2>
  )}
  {(parentValue && typeof parentValue === 'object' && Object.keys(parentValue).length >= 2 && parentValue.hasOwnProperty('value'))? null : (
    <p className="text-xl font-semibold">{calculateTotalValue(parentValue)}</p>
  )}
  {(parentValue && typeof parentValue === 'object' && Object.keys(parentValue).length >= 2 && parentValue.hasOwnProperty('value'))? null : (
    <p className='text-sm pt-1'>Sq.ft</p>
  )}
</div>
  )
)}
    </div>
    {parentValue && typeof parentValue === 'object' && Object.keys(parentValue).length >= 2 && parentValue.hasOwnProperty('value') && (
      <div className="pl-4">
        {OffersData && parentValue.offerid && (
        <div className='flex flex-col'>
        <div className='flex flex-row justify-between mt-2'>
          <div className="flex flex-col">
            <p className='text-lg font-semibold'>{parentKey}</p>
          </div>
          {selectedProductCategoryName !== 'Tiles' && (
          <div className='flex flex-row items-center'>
            <p className="text-lg">{parentValue.count}</p>
            <p className='text-xs pt-1 ml-1'>No's</p>
          </div>
          )}
          <div className='flex flex-row items-center'>
            <p className="text-lg">{parentValue.value}</p>
            <p className='text-xs pt-1 ml-1'>Sq.ft</p>
          </div>
          <div className='Each Total flex flex-row items-center'> 
            {parentValue.UOM === 'No' ? ( 
            <p className='text-lg ml-2 font-bold'>₹{(parentValue.count * parentValue.netPrice).toFixed(2)}</p> 
            ) : (
               <p className='text-lg ml-2 font-bold'>₹{(parentValue.value * parentValue.netPrice).toFixed(2)}</p> )} 
               </div>
        </div>
        
        <div className='flex justify-between flex-row'>
           {/* <div className=' w-20 h-20 overflow-hidden flex items-center justify-center'>
     <img className='max-w-full max-h-full object-contain'
  src={ImageUrl + OffersData.find(offer => offer.offerData._id === parentValue.offerid)?.productGroupData[0]?.product_group_image || 'Offer Not Found'}
  alt="Offer Image"
/>      
</div>   */}
<div className='mt-4 mb-2 w-24 h-24 overflow-hidden border border-gray-300 flex flex-col items-center justify-center'>
  <FontAwesomeIcon className='w-10 h-10 text-gray-700 opacity-80' icon={faImage} />
  <span className='text-xs text-center mt-2'>Design not selected</span>
</div>
<div className="flex items-center justify-center w-2/3 flex-col">
<div className="flex justify-center">
   <p className="text-lg font-semibold text-center whitespace-normal">
  {OffersData.find(offer => offer.offerData._id === parentValue.offerid)?.productGroupData[0]?.product_group_name || 'Offer Not Found'}
     </p>
     </div>
     </div>
<div className='flex flex-row items-center'>
<p className='text-md font-semibold mr-1 whitespace-nowrap'>Offer Price:</p>
<p className="text-md">₹{parentValue.netPrice}/<span className='text-xs'>{parentValue.UOM}</span></p>
            </div>                                             
        </div>
        
        </div>
         )}
      </div>
    )}
    {parentValue && Object.entries(parentValue).map(([childKey, childValue]) => (
      <div key={childKey}  className=''>
          {childKey !== 'Dummy' && childValue && childValue.value && (
<div>
{OffersData && childValue.offerid && (
                  <div className={childValue ? 'flex flex-col border-b' : ''}>
          <div className='flex flex-row justify-between mt-2'>
            <div className="flex flex-col">
              <p className='text-lg font-semibold'>{childKey}</p>
            </div>
            {selectedProductCategoryName !== 'Tiles' && (
  <div className='flex flex-row items-center'>
    <p className="text-lg">{childValue.count}</p>
    <p className='text-xs pt-1 ml-1'>No's</p>
  </div>
)}
            <div className='flex flex-row items-center'>
              <p className="text-lg">{childValue.value}</p>
              <p className='text-xs pt-1 ml-1'>Sq.ft</p>
            </div>
            <div className='Each Total flex flex-row items-center'> 
            {childValue.UOM === 'No' ? ( 
              <div className='flex flex-row'>
            <p className='text-lg ml-2 font-bold'>₹{(childValue.count * childValue.netPrice).toFixed(2)}</p> 
            </div>
            ) : (
               <p className='text-lg ml-2 font-bold'>₹{(childValue.value * childValue.netPrice).toFixed(2)}</p> )} 
               </div>
          </div>
        
        <div className='flex justify-between flex-row'>
           {/* <div className=' w-20 h-20 overflow-hidden flex items-center justify-center'>
     <img className='max-w-full max-h-full object-contain'
  src={ImageUrl + OffersData.find(offer => offer.offerData._id === childValue.offerid)?.productGroupData[0]?.product_group_image || 'Offer Not Found'}
  alt="Offer Image"
/>      
</div>   */}
<div className='mt-2 mb-4 w-24 h-24 overflow-hidden border border-gray-300 flex flex-col items-center justify-center'>
  <FontAwesomeIcon className='w-10 h-10 text-gray-700 opacity-80' icon={faImage} />
  <span className='text-xs text-center mt-2'>Design not selected</span>
</div>
<div className="flex items-center justify-center w-2/3 flex-col">
<div className="flex justify-center">
   <p className="text-lg font-semibold text-center whitespace-normal">
  {OffersData.find(offer => offer.offerData._id === childValue.offerid)?.productGroupData[0]?.product_group_name || 'Offer Not Found'}
     </p>
     </div>
     </div>
     <div className='flex flex-row items-center'>
     <p className='text-md font-semibold mr-1 whitespace-nowrap'>Offer Price:</p>
              <p className="text-md"> ₹{childValue.netPrice}/<span className="text-xs">{childValue.UOM}</span></p>
            </div>                                     
        </div>
       
</div>
)}
</div>
        )}
      </div>
    ))}
    
  </div>
  
))}

 {/*  {data && data.quotationData && data.quotationData['Total Tiles Area'] && data.quotationData['Total Tiles Area'].Dummy && (
    <div className='font-semibold flex justify-end items-center w-full pr-16'>
      <p>Total: ₹{data.quotationData['Total Tiles Area'].Dummy.value}</p>
      <p className='text-xs pt-1 ml-1'>Sq.ft</p>
    </div>
  )} */}
</div>
<div className='flex w-[60%] justify-between px-4'>
  <div className=''>
    {data && data.quotationData && Object.entries(data.quotationData).map(([parentKey, parentValue]) => {
      return (
        <div key={parentKey}>
          {Object.entries(parentValue).map(([childKey, childValue]) => (
            <div key={childKey}>
              {childKey === 'Dummy' && (
                <div className="flex justify-center items-center ">
                   <p>
        <span className='text-md'>{parentKey} = </span>
        <span className="text-lg font-semibold"> {childValue.value} Sq.ft</span>
      </p>
                </div>
              )}
            </div>
          ))}
        </div>
      )
    })}
  </div>
  <div className='Overall Total'>
  {data && data.quotationData &&
   <p className='text-md'>
   <span className="text-md">Total Price:</span>
   <span className="text-lg font-semibold"> ₹
   {new Intl.NumberFormat('en-IN').format (handleAddPriceAndUom(data.quotationData))}
   </span>
 </p>
}
  </div>
</div>

                <div className='flex justify-center space-x-4 mb-4 mt-6 '>
               {/*  <button className='rounded-sm text-black hover:text-white border border-[#EB001B] px-2 flex justify-center hover:bg-[#EB001B] ' onClick={() => handleViewQuotation()}>Download Quotation</button> */}
                <button className='rounded-sm text-black hover:text-white border border-[#EB001B] px-2 flex justify-center hover:bg-[#EB001B] ' onClick={handleBack}>Back</button>
                </div>
        </div>
           </div>
         
        </div>
    );
}

export default ViewQuotation;
