/* Electrical quotation */

import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaChevronLeft, FaChevronRight, FaHeart, FaMinusCircle, FaRegHeart } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
const WireColorPopup = ({ onClose, onSubmit,BreakUps }) => {
  const [colors, setColors] = useState({
    Red: '',
    Blue: '',
    Green: '',
    Yellow: '',
    Black: ''
  });

  useEffect(()=>{
 setColors(BreakUps)
  },[BreakUps])

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!isNaN(value)) { // Ensure the input is a number
      setColors((prevColors) => ({
        ...prevColors,
        [name]: value
      }));
    }
    //setIsQuotationSaved(false);
  };

  const handleSubmit = () => {
    onSubmit(colors);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-md max-w-md w-full">
        <h3 className="text-lg font-semibold mb-4">Enter Wire Colors</h3>
        <div className="grid grid-cols-2 gap-4">
          {Object.keys(colors).map((color) => (
            <div key={color} className="flex flex-col">
              <label className="font-medium">{color}:</label>
              <input
                type="number"
                name={color}
                value={colors[color]}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded"
              />
            </div>
          ))}
        </div>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};


const AdminViewQuotation = () => {
    const navigate= useNavigate();
    const location = useLocation();
    const { selectedQuotationId, selectedProductCategoryId, selectedProductCategoryName, selectedCustomerId, selectedRFQId, offer} = location.state;
    const [customer, setCustomerData] = useState();
    const [OffersData,setOffersData] = useState([])
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
    const url=process.env.REACT_APP_FORE_BRICKBUCKET
    const [counts, setCounts] = useState([]);
    const [ProductCategory,setProductCategory]= useState()
    const [CustomerQuotationData,setCustomerQuotationData]=useState([])
    const [productAdded, setProductAdded] = useState([])
    const [showWirePopup, setShowWirePopup] = useState(false);
    const [ElecticalWire,setElecticalWire]= useState(null);
    const [QuantityBreakUp,setQuantityBreakUp]= useState([])
    const [OfferForElectrical,setOfferForElectrical]=useState()
    const [breakUp,setBreakUp]=useState({
      Red: '',
      Blue: '',
      Green: '',
      Yellow: '',
      Black: ''
    })
    const [isQuotationSaved, setIsQuotationSaved] = useState(true);
    const [RequirementImage,setRequirementImage]= useState('');
    const [ProductSubCategories,setProductSubCategories]= useState([])
    const [selectedSubCategory,setSelectedSubCategory]=useState('')
    const [filteredOffersData,setFilteredOffers]= useState([])
/*     useEffect(() => {
      setIsQuotationSaved(true);
    }, []); */
  
     useEffect(() => {
      if(OffersData.length > 0){ 
        fetchQuotationData()
      }
    }, [OffersData]); 
    console.log(OffersData);

    const fetchQuotationData = async () => {
      const token = sessionStorage.getItem('token');

      const GetQuotation = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/get-customer-quotation-by-quotatioId?quotation_id=${selectedQuotationId}`;
    
      try {
        const response = await fetch(GetQuotation, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json', // Add necessary headers if needed
            // Add other headers if required, e.g., authorization
            'x-token':token
          },
        });
    
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
    
        const data = await response.json();
        setCustomerQuotationData(data.QuotationData);
    
        const newCounts = {};
        data.QuotationData.quotationData.forEach(({ product_id, count }) => {
          newCounts[product_id] = count;
        });
        setCounts(newCounts);
       setQuantityBreakUp(data.QuotationData.quantity_break_up)
        const newProductsAdded = [];
        data.QuotationData.quotationData.forEach(({ product_id, count, offer_id }) => {
          const offer = OffersData.find(p => p.OfferData._id === offer_id);
          if (offer) {
            const product = offer.OfferProducts.find(p => p._id === product_id);
            if (product) {
              const productAdded = {
                offer_id: offer_id,
                count: count,
                product: product,
              };
              newProductsAdded.push(productAdded);
            }
          }
        });
        setProductAdded(newProductsAdded);
        // return data; // Return the data if needed
      } catch (error) {
        console.error('Failed to fetch quotation data:', error);
        clearStorageAndRedirectToHome()
      }
    };
    
    useEffect(()=>{
      fetchProductSubCategories(selectedProductCategoryId)
    },[selectedProductCategoryId])

    const  fetchProductSubCategories = async (productCategoryId)=>{
      console.log(productCategoryId)
     const GetAllProductSubCategories= url+`/Admin/product-sub-category/get-productSubCategories-by-productCategoryId?product_category_id=${productCategoryId}`
    try {
      const token = sessionStorage.getItem('token');
       const response = await fetch(GetAllProductSubCategories, {
        method: 'GET',
        headers: {
          // Add any required headers here
          'x-token':token
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        
        setProductSubCategories(data.data);
      } else {
        // Handle non-ok response
        setProductSubCategories([])
        setSelectedSubCategory('')
        
        console.error('Failed to fetch product sub categories:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching product sub categories:', error);
    }
    }

    const handleChangeSubCategory =(event)=>{
      setSelectedSubCategory(event.target.value);
      if(event.target.value===''){
  
      setFilteredOffers(OffersData)
      }else{
        const filteredOffers= OffersData.filter(p=>p.productSubCategory&&p.productSubCategory._id===event.target.value)
        setFilteredOffers(filteredOffers) 
      }
    }

    useEffect(()=>{
      fetchCustomer(selectedCustomerId)
    
    },[selectedCustomerId])

    const fetchCustomer = async (customer_id) => {
      const token = sessionStorage.getItem('token');
      const getCustomerUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/get-customer-by-customerId?customer_id=${customer_id}`;
    
      try {
        const response = await fetch(getCustomerUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
       setCustomerData(data.Customer)
      } catch (error) {
        console.error('Error fetching customer:', error);
      }
    };
    

    useEffect(()=>{
      getMaterialRequirement(selectedQuotationId)
    },[selectedQuotationId])

    const getMaterialRequirement = async (quotation_id) => {
      const token = sessionStorage.getItem('token');
      const materialRequirementUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/material-requirement/get-material-requirement-by-quotationId?quotation_id=${quotation_id}`;
    
      try {
        const response = await fetch(materialRequirementUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token': token
          }
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log(data)
        setRequirementImage(data. requirement_image)
      } catch (error) {
        console.error('Error fetching material requirement:', error);
      }
    };
    

    
    useEffect(() => {
      if(CustomerQuotationData.length > 0 && OffersData.length > 0){
        
      }
     
    }, [CustomerQuotationData, OffersData]);

    useEffect(() => {
        fetchOffersAndTheirAppliedproducts(selectedProductCategoryId);
        //fetchQuotations();
    }, [selectedProductCategoryId,  selectedCustomerId]);

    const fetchOffersAndTheirAppliedproducts= async(productCategoryId)=>{
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/get-ofers-and-offerProducts-by-productCategoryId?product_category_id=${productCategoryId}`, {
          method: 'GET',
          headers: {
            // Add any required headers here
            'x-token':token
          }
        });
        if (!response.ok) {
          setFilteredOffers([])
          setOffersData([])
            throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const data = await response.json();
       
        //const remainingOffers = data.AllOffers.filter(p => p.OfferData._id !== offer._id) || [];
      
      //  const selectedOffer = data.AllOffers.find(p => p.OfferData._id === offer._id);
      //const allOffers = selectedOffer ? [selectedOffer, ...remainingOffers] : remainingOffers;
      
        setOffersData(data.AllOffers)
        setFilteredOffers(data.AllOffers)
      
        //setProductCategory(data.productCategoryData)
       // setProductData(data);
    } catch (error) {
       // setError(error.message);
    } 
    }

    const calculatePrice = (product, offer) => {

        if (!product || !offer || !offer.OfferData) {
          return 'Price Not Available';}
        const basePrice = parseFloat(product.product_price || 0);
        const discount =parseFloat( offer.OfferData.discount || 0);
        const gst = parseFloat(offer.OfferData.GST_rate_id || 0);
      
        let netPrice = parseFloat(basePrice);
      
        // Apply discount
        netPrice -= (netPrice * (discount / 100));
      
        // Apply GST
        netPrice *= (1 + (gst / 100));
        return `${netPrice.toFixed(2)}`;
      };

     
  const handleChange = (productId, e) => {
    const newValue = parseInt(e.target.value) || 0;
    setCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: newValue,
    }));
    setIsQuotationSaved(false);
  };

  const handleIncrement = (productId) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: (prevCounts[productId] || 0) + 1, // If count doesn't exist yet, initialize it with 0
    }));
    setIsQuotationSaved(false);
  };

  const handleIncrementInAddedProducts = (productId) => {
    handleIncrement(productId);
  };



      const handleDecrement = (productId) => {
        setCounts((prevCounts) => ({
          ...prevCounts,
          [productId]: Math.max((prevCounts[productId] || 0) - 1, 0), // If count doesn't exist yet, initialize it with 0
        }));
        setIsQuotationSaved(false);
      };


      const handleWirePopupSubmit = (colors) => {
        console.log(colors)
        let totalQuantity = 0
        for (const key in colors) {
            const quantity=  colors[key] || '0'
            totalQuantity +=parseInt(quantity)
        }
        counts[ElecticalWire._id]=totalQuantity
        console.log(counts)
     
       
       
         const quantityBreakUp = {
           customer_id: selectedCustomerId,
           product_category_id: ElecticalWire.product_category_id,
           product_id: ElecticalWire._id,
           break_ups: colors
         };
       
         let AllQuantityBreakup = [...QuantityBreakUp];
         
         const findQuantityBreakupIndex = AllQuantityBreakup.findIndex(p => 
           p.user_id === QuantityBreakUp.user_id && p.product_id === ElecticalWire._id
         );
    
         if (findQuantityBreakupIndex !== -1) {
           AllQuantityBreakup[findQuantityBreakupIndex] = quantityBreakUp;
           HandleAddItems(ElecticalWire,OfferForElectrical,AllQuantityBreakup)
         } else {
           AllQuantityBreakup = [...AllQuantityBreakup, quantityBreakUp];
           HandleAddItems(ElecticalWire,OfferForElectrical,AllQuantityBreakup)
         }
       
         setQuantityBreakUp(AllQuantityBreakup)
         setIsQuotationSaved(false);
       };

       const HandleAddItems=(product,offer, BreakUps)=>{
        const count = counts[product._id] || 0;
        
        const offer_id = offer.OfferData._id;
    
        if (count > 0) {
            const productCategoryID = product.product_category_id; // Extract product category ID
            const existingProductIndex = productAdded.findIndex((item) => item.product._id === product._id);
    
            if (existingProductIndex !== -1) {
                // Product already exists, update the count
                const updatedProductAdded = [...productAdded];
                updatedProductAdded[existingProductIndex].count = count;
                setProductAdded(updatedProductAdded);
                saveAddedAndDeletedQuotation(updatedProductAdded ,BreakUps)
            } else {
                // Product doesn't exist, add it to the list
                const updatedProduct = { product, count, productCategoryID, offer_id }; // Include product category ID
                const updatedProductAdded = [...productAdded];
                updatedProductAdded.push(updatedProduct); // Add the new product to the end of the array
                setProductAdded(updatedProductAdded);
                saveAddedAndDeletedQuotation(updatedProductAdded, BreakUps);
            }
        }
      }

      const handleAddItem = (product,offer) => {

        if (offer.productSubCategory && offer.productSubCategory.product_sub_category_name === 'Electrical Wires') {
          setOfferForElectrical(offer)
          setElecticalWire(product)
          setShowWirePopup(true);
          const BreakUpData= QuantityBreakUp.find(p=>p.product_id===product._id)
          if(BreakUpData){
            setBreakUp(BreakUpData.break_ups)
          }  
        }else{
          HandleAddItems(product,offer)
        }
        setIsQuotationSaved(false);
      };


    /*   const handleAddItem = (product, offer) => {
        const count = counts[product._id] || 0;
        const offer_id = offer.OfferData._id;
    
        if (count > 0) {
            const productCategoryID = product.product_category_id; // Extract product category ID
            const existingProductIndex = productAdded.findIndex((item) => item.product._id === product._id);
    
            if (existingProductIndex !== -1) {
                // Product already exists, update the count
                const updatedProductAdded = [...productAdded];
                updatedProductAdded[existingProductIndex].count = count;
                setProductAdded(updatedProductAdded);
                saveAddedAndDeletedQuotation(updatedProductAdded)
            } else {
                // Product doesn't exist, add it to the list
                const updatedProduct = { product, count, productCategoryID, offer_id }; // Include product category ID
                const updatedProductAdded = [...productAdded];
                updatedProductAdded.push(updatedProduct); // Add the new product to the end of the array
                setProductAdded(updatedProductAdded);
                saveAddedAndDeletedQuotation(updatedProductAdded);
            }
        }
    }; */
    
    
    
      const handleDeleteProduct = (productId) => {
        // Find the index of the product with the matching ID
        const index = productAdded.findIndex((product) => product.product._id === productId);
        if (index !== -1) {
          // Remove the product from the UI
          const updatedProductAdded = [...productAdded];
          updatedProductAdded.splice(index, 1);
          setProductAdded(updatedProductAdded);
          const RemainingBreakupQuantity=QuantityBreakUp.filter(p=>p.product_id!==productId)
          saveAddedAndDeletedQuotation(updatedProductAdded,RemainingBreakupQuantity);
          
          
        }
      };

     /*  const ToTaladdedProductsPrice = () => {
       // console.log(OffersData)
        let totalCost = 0;
      
        // Filter products by the current product category ID
       // const filteredProducts = productAdded.filter(item => item.product.product_category_id === OffersData.find(p=>p._id===item.offer_id).productCategory._id);
      
       productAdded.forEach((item) => {
        
          const product = item.product;
          const offerId=item.offer_id
          const count = item.count;
          
          
          const basePrice = parseFloat(product.product_price);
          const discount = OffersData.find(p=>p.OfferData._id===offerId).OfferData.discount || 0;
          const gst =OffersData.find(p=>p.OfferData._id===offerId).OfferData.gst || 0;
      
          let netPrice = basePrice;
          // Apply discount
          netPrice -= (netPrice * (discount / 100));
          // Apply GST
          netPrice *= (1 + (gst / 100));
      
          // Calculate total price for the product
          const totalPrice = netPrice * count;
      
          // Add total price to the total cost
          totalCost += totalPrice;
        });
      
        // Return the total cost
        return totalCost.toFixed(2);
      };
       */

       const ToTaladdedProductsPrice = () => {
  let totalCost = 0;
  productAdded.forEach((item) => {
    const count = item.count;
    const netPrice= calculateTotalOfferPrice(item)
    const totalPrice = netPrice * count;
    totalCost += totalPrice;
  })
  return totalCost.toFixed(2);
}

const calculateTotalOfferPrice=(item)=>{
 
  const SelecetdOffer= OffersData.find(p=>p.OfferData._id===item.offer_id)
  if(SelecetdOffer.OfferData.net_price===0){
    const basePrice = parseFloat(item.product.product_price);
    const gst = parseFloat(SelecetdOffer.OfferData.GST_rate_id || 0);
    const discount = parseFloat(SelecetdOffer.OfferData.discount || 0);
    const NetPrice= (basePrice*(1-((discount)/100)))*(1+((gst)/100))  
    return NetPrice
  }else{
    return parseFloat(SelecetdOffer.OfferData.net_price)
  }


}

    

       const handleGeneratePDF = async () => {
        try {
          // Check if the product category ID exists
          if (!selectedProductCategoryId) {
            console.error("Product category ID is missing.");
            return;
          }
      
          // Retrieve and filter products from local storage
         
          //const filteredProducts = addedProducts.filter(product => product.product.product_category_id === ProductCategory._id);
      
          if (productAdded.length === 0) {
            console.error("No products found for the current product category.");
            return;
          }
      
          // Create a new jsPDF instance
          const doc = new jsPDF();
      
          // Define the function to calculate the price
       /*    const calculatePrice = (product, offer) => {
            if (!product || !offer || !offer.OfferData) {
              return 'Price Not Available';
            }
            const basePrice = product.product_price || 0;
            const discount = offer.OfferData.discount || 0;
            const gst = offer.OfferData.gst || 0;
      
            let netPrice = parseFloat(basePrice);
            netPrice -= (netPrice * (discount / 100));
            netPrice *= (1 + (gst / 100));
            return netPrice;
          }; */
      
          // Calculate subtotal
          const subtotal = productAdded.reduce((acc, product) => {
            const price = calculateOfferPrice(product.product, product.offer_id);
            return acc + (product.count * price);
          }, 0);
      
          // Define table data with headers
          let tableData = [
            ['Product Name', 'Quantity', 'Price'], // Header row
            ...productAdded.map(product => ([
                product.product.product_name,
                product.count,
                `Rs ${calculateOfferPrice(product.product, product.offer_id).toFixed(2)} /-`
            ])),
            [ '', 'Total Price',  `Rs ${subtotal.toFixed(2)} /-`]
        ];
        
        // Conditionally add the "Amended Price" row if amended_discount is not zero
        if (CustomerQuotationData.amended_discount !== '0') {
          tableData.push([ '', 'Discount Price',  `Rs ${parseFloat(CustomerQuotationData.amended_discount).toFixed(2)} /-`]);
          tableData.push([ '', 'Final Price', `Rs ${(subtotal-parseFloat(CustomerQuotationData.amended_discount)).toFixed(2)} /-`]);
        }
        
      
          // Add table to the document
          doc.autoTable({
            head: [tableData.shift()], // Extract and use the first row as the header
            body: tableData,
            startY: 20 // Adjust as needed
          });
      
          // Save the document
          doc.save('Electricals_Quotation.pdf');
      
          // Save data to the server
          await saveQuotation(productAdded);
          setIsQuotationSaved(false);
        } catch (error) {
          console.error("Error generating PDF:", error);
          clearStorageAndRedirectToHome()
        }
      }; 

      const calculateOfferPrice=(product,offerId)=>{
        const SelecetdOffer= OffersData.find(p=>p.OfferData._id===offerId)
        if(SelecetdOffer.OfferData.net_price===0){
          const basePrice = parseFloat(product.product_price);
          const gst = parseFloat(OffersData.find(p=>p.OfferData._id===offerId).OfferData.GST_rate_id || 0);
          const discount = OffersData.find(p=>p.OfferData._id===offerId).OfferData.discount || 0;
          const NetPrice= (basePrice*(1-(discount/100)))*(1+(gst/100))
          return NetPrice
        }else{
          return SelecetdOffer.OfferData.net_price
        }
      
      }

      const saveQuotation = async (filteredProducts) => {
        const availOffers= GetAvailOffers(filteredProducts)
      
        try {
          const token = sessionStorage.getItem('token');

          const requestData = {
            customer_id: selectedCustomerId,
            product_category_id: selectedProductCategoryId,
            avail_offers:availOffers,
            quantity_break_up:QuantityBreakUp,
            created_by:"User",
            quotationData: filteredProducts.map(({ product, count ,offer_id}) => ({
              product_id: product._id,
              count,
              price : calculateOfferPrice (product , offer_id),
              offer_id: offer_id,
            }))
          };
          //console.log(requestData);
          const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/save-customer-quotation-of-no-project-ids`;
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Add other headers if needed
              'x-token':token
            },
            body: JSON.stringify(requestData),
          });
      
          if (!response.ok) {
            throw new Error('Failed to save PDF data to the server');
          }
      
          navigate('/bb/admin/AdminHome/UserQuotations', {
            state: {
              customer_id:selectedCustomerId,
            }
          })
        } catch (error) {
          console.error('Error saving PDF data:', error);
          clearStorageAndRedirectToHome()
        }
      };

      const GetAvailOffers =(filteredProducts)=>{
        const offerIdsSet = new Set(filteredProducts.map(({ offer_id }) => offer_id));
        const uniqueOfferIds = Array.from(offerIdsSet);
        return  uniqueOfferIds   
      }

      const saveAddedAndDeletedQuotation = async (filteredProducts, BreakUps) => {
       const availOffers= GetAvailOffers(filteredProducts)
       console.log(BreakUps)
        try {
          const token = sessionStorage.getItem('token');

          const requestData = {
            customer_id: selectedCustomerId,
            product_category_id: selectedProductCategoryId,
            avail_offers:availOffers,
            quantity_break_up:BreakUps,
            quotationData: filteredProducts.map(({ product, count ,offer_id}) => ({
              product_id: product._id,
              count,
              price : calculatePrice (product ,OffersData.find(p => p.OfferData._id === offer_id)),
              offer_id: offer_id
            }))
          };
          console.log(requestData);
          const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/save-customer-quotation-of-no-project-ids`;
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Add other headers if needed
              'x-token':token
            },
            body: JSON.stringify(requestData),
          });
          if(response.ok){
            
         fetchQuotationData()
          }
          else  {
            throw new Error('Failed to save PDF data to the server');
          }
          
         
        } catch (error) {
          console.error('Error saving PDF data:', error);
          clearStorageAndRedirectToHome()
        }
      };
    
   /*  const fetchQuotations = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/get-customer-quotation-data?rfq_id=${selectedRFQId}&customer_id=${selectedCustomerId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch quotations');
            }
            const data = await response.json();
            setQuotationData(data.QuotationData);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
 */

   const  handleNotifyQuotation= async ()=>{
    try {
    // Check if the product category ID exists
    if (!selectedProductCategoryId ) {
      console.error("Product category ID is missing.");
      return;
    }

    // Create a new jsPDF instance
    const doc = new jsPDF();
  
    const subtotal = productAdded.reduce((acc, product) => {
    
      const price = calculateOfferPrice(product.product, product.offer_id);;
      return acc + (product.count * price);
    }, 0);

    // Define table data with headers
    let tableData = [
      ['Product Name', 'Quantity', 'Price'], // Header row
      ...productAdded.map(product => ([
          product.product.product_name,
          product.count,
          `Rs ${calculateOfferPrice(product.product, product.offer_id).toFixed(2)} /-`
      ])),
      [ '',  'Total Price', `Rs ${subtotal.toFixed(2)} /-`]
  ];
  
  // Conditionally add the "Amended Price" row if amended_discount is not zero
  if (CustomerQuotationData.amended_discount !== 0 ) {
      tableData.push([ '', 'Discount Price',  `Rs ${parseFloat(CustomerQuotationData.amended_discount).toFixed(2)} /-`]);
      tableData.push([ '', 'Final Price', `Rs ${(subtotal-parseFloat(CustomerQuotationData.amended_discount)).toFixed(2)} /-`]);
  }

    // Add table to the document
    doc.autoTable({
      head: [tableData.shift()], // Extract and use the first row as the header
      body: tableData,
      startY: 20 // Adjust as needed
    });

    // Save the document
    doc.save('Quotation.pdf');
    const pdfBlob = doc.output('blob');
   
    
    // Save data to the server
    await sendQuotationToCustomer(pdfBlob,)
   // await sendQuotationToCustomer(pdfBlob);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }

    //navigate('/bb/admin/AdminHome/OrderConfirmation', { state: { quotationid: [selectedQuotationId] } });
    }


    const sendQuotationToCustomer = async (pdfBlob) => {
      //const product_category_name = ProductCategories.find(p => p._id === quotation.product_category_id).product_category_name;
      console.log(customer)
      const formData = new FormData();
      formData.append('pdf', pdfBlob, 'Quotation.pdf');
      formData.append('email', customer.email);
      formData.append('product_category_name', selectedProductCategoryName);
    
      try {
        console.log('PDF Blob size:', pdfBlob.size);
        if (pdfBlob.size === 0) {
          console.error("PDF Blob is empty");
          return;
        }
        const token = sessionStorage.getItem('token');
        const response = await fetch(url + '/customer/quotation/notify-quotation-to-customer-by-email', {
          method: 'POST',
          headers:{
            'x-token':token
          },
          body: formData
        });
    
        if (response.ok) {
        //await RemoveProductCategoryCustomerAddedDataFromLocalStorage(quotation.product_category_id)
       
          /*  navigate('/bb/Admin/AdminHome/AdminViewQuotations', {
            state: {
              selectedQuotationId: selectedQuotationId,
              selectedProductCategoryId: selectedProductCategoryId,
              selectedProductCategoryName: selectedProductCategoryName,
              selectedCustomerId: quotation.customer_id,
              selectedRFQId: quotation.RFQ_id
            }
          });  */

          navigate('/bb/admin/AdminHome/UserQuotations', {
            state: {
              customer_id:selectedCustomerId,
            }
          })
        } else {
          console.error("Error sending email");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    


    const getDisplayUOM = (uom, count) => {
      const countNumber = Number(count);
      // Convert UOM to lowercase and remove special characters for comparison
      let sanitizedUOM = uom.toLowerCase().replace(/[^a-z]/g, '');
    
      if (countNumber === 1) {
          // Singular form special cases handling
          if (sanitizedUOM === 'nos') {
              return "No";
          } else if (sanitizedUOM === 'bags') {
              return "Bag";
          } else if (sanitizedUOM === 'coils') {
              return "Coil";
          } else if (sanitizedUOM === 'packets') {
              return "Packet";
          }
          // Additional logic for other special cases can be added here
          return uom.replace(/'s$/, ''); // Assuming 's is for plural
      } else {
          // For plural form handling
          if (uom.endsWith('s')) {
              return uom;
          }
          return `${uom}s`;
      }
    };

    const  handleBack=()=>{
      navigate(-1)
    }

    function clearStorageAndRedirectToHome() {
      navigate("/bb/ErrorPage");
    }

   

    return (
      <div className="w-full h-full ">
      <div className="flex justify-center  w-full h-full p-2 flex-row ">

         <div className="border-r w-1/3  md:w-1/3 sm:w-0 xs:w-0 h-full flex items-center justify-center py-2 px-2">
      <div className="w-full h-full flex items-center justify-center ">
        <Zoom>
          {RequirementImage?(
          <img
          src={`${ImageUrl}${RequirementImage}`}
          alt="Customer Image"
          className="w-full h-full object-contain"
        />
          ):(
            <p>image not available

            </p>
          )}
          
        </Zoom>
      </div>
    </div>

      <div className="md:w-1/2 lg:w-1/2 sm:w-full">
      

{/* Quotation div for small screens */}
<div className="p-2 rounded-md w-full border lg:hidden flex flex-col relative">
        <div className="rounded-md overflow-x-auto">
          <div className="p-1 border-b flex justify-between flex-row">
            <h1 className="font-semibold">Quotation</h1>
            <div className='flex flex-col space-y-2 items-end '>
            {OffersData && OffersData.length > 0 && (
              <h1>Total: ₹{ToTaladdedProductsPrice()}</h1>
            )}
            {CustomerQuotationData.amended_discount !== 0 && (
              <>
              <h1 className="text-sm">
       discount Price: ₹{ CustomerQuotationData.amended_discount}
    </h1>
              
    <h1 className="text-sm">
       Final Price: ₹{ToTaladdedProductsPrice() - CustomerQuotationData.amended_discount}
    </h1>
    </>
)}
            </div>
           
          </div>

          {productAdded && productAdded.length > 0 && (
            <div className='flex overflow-x-scroll'>
              {productAdded
                .slice()
                .reverse()
                .map((selectedProduct, index) => (
                  <div key={index}
                  className="flex-shrink-0 w-64 mr-4"
                >
                  <div className="border mt-2 mb-2 p-2 rounded-lg flex flex-col items-center bg-white">
                    <div className="flex w-full justify-end ">
                      <button className="text-red-500 focus:outline-none" onClick={() => handleDeleteProduct(selectedProduct?.product?._id)}>
                        <FaMinusCircle className="text-xs hover:text-red-500" />
                      </button>
                    </div>
                    <div key={index} className="flex items-center justify-between space-x-2 ">
                      <div className="w-16 h-16 overflow-hidden flex items-center justify-center">
                        <img
                          src={ImageUrl + (selectedProduct?.product?.product_image || 'image unavailable')}
                          alt="Product Image"
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <div className=" flex justify-center items-center flex-col flex-grow">
                        <div className="flex justify-start h-12 overflow-hidden text-ellipsis">
                          <p className="text-sm font-semibold text-left whitespace-normal">{selectedProduct?.product?.product_name}</p>
                        </div>
                        <div className="flex flex-row justify-between mt-1">
                          <p className="p-1 text-sm">
                          {/* <span className="font-semibold">Qty:</span> {selectedProduct?.count}</p> */}
                          <span className="font-semibold">Qty:</span> {selectedProduct.count} {getDisplayUOM(selectedProduct.product.UOM, selectedProduct.count)}
                          
                          </p>
                          {(() => {
    const selectedOffer = OffersData?.find(p => p?.OfferData?._id === selectedProduct?.offer_id);
    if (selectedOffer) {
      return (
        <div>
        {selectedOffer.OfferData.net_price === 0 ? (
          <p className="p-1 text-sm">
            <span className="font-semibold">Offer Price: </span> ₹ {calculatePrice(selectedProduct.product, selectedOffer)}</p>
        ) : (
          <p className="p-1 text-sm">
            <span className="font-semibold">Offer Price: </span> ₹ {selectedOffer.OfferData ? selectedOffer.OfferData.net_price : 'Price Not Available'}</p>
        )}
        </div>
       
      );
    } else {
      return (
        <p className="p-1 text-sm">
          Price: ₹{selectedOffer?.OfferData?.net_price ?? 'Price Not Available'}
        </p>
      );
    }
  })()}
                        </div>
                        <div className="grid grid-cols-3 gap-1 mt-1">
 {Object.entries(QuantityBreakUp.find(p => p.product_id === selectedProduct.product._id)?.break_ups || {}).map(([key, value], index) => (
(value!== "" && value!== 0 && value!== undefined && value!== null) && (
<div key={index} className="flex flex-row items-center space-x-1">
<p className='text-xs font-semibold'>{key}=</p>
<p className='text-xs'>{value}</p>
</div>
)
))}
</div>
                      </div>
                      
                    </div>
                   
                  </div>
                  </div>
                ))}
            </div>
          )}
        
          <div className="flex justify-end px-4 flex flex-row space-x-2">
          {isQuotationSaved ? (
            <button className="rounded-sm border border-gray-500 px-2 text-gray-500" disabled>
            Saved
            </button>
          ) : (
            <button className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black" onClick={() => handleGeneratePDF()}>
              Save
            </button>
          )}
            <button className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black" onClick={() => handleNotifyQuotation()}>
             Notify
            </button>
          </div>
        </div>
        </div>

      <div className='flex flex-row justify-center items-center  space-x-2 py-2'>
        <div >
        <p className="text-xl sm:text-md font-semibold flex">{selectedProductCategoryName}</p>
        </div>

        <div>
        <select 
          className="block p-1 w-full text-sm text-gray-700 bg-white border border-gray-300 focus:outline-none focus:ring-gray-300 focus:border-gray-300"
          value={selectedSubCategory}
          onChange={handleChangeSubCategory}
        >

        <option value="">Select Product Sub Category</option>
        { 
      ProductSubCategories.map((subcategory, index) => (
        <option value={subcategory._id}>{subcategory.product_sub_category_name}</option>
      ))}
        
      </select>
        </div>
     
      </div>  
       
       <div className="p-1 w-full h-full flex flex-col justify-between items-center  overflow-y-auto" style={{ maxHeight: '80vh' }}>
        <div className="md:w-full sm:w-full">
        <div>
          <hr className="border-gray-400 my-1 mt-5" />
          {filteredOffersData.map((offer, Offerindex) => (
            <div key={Offerindex}>
              {offer.OfferProducts && offer.OfferProducts.length > 0 && (
  <>
              <div className="flex">
                {offer.productSubCategory && (
                  <p className="text-lg sm:text-[20px] md:text-[24px] text-gray-700 font-semibold px-2 mt-5">
                    {offer.productSubCategory.product_sub_category_name}
                  </p>
                )}
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-3 mt-4 w-full overflow-y-auto">
                {offer.OfferProducts && offer.OfferProducts.length > 0 ? (
                  offer.OfferProducts.map((product, productIndex) => (
                    <div key={product._id} className="w-full border p-2 rounded-lg flex flex-col items-center">
                      <div className="w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-40 lg:h-40 overflow-hidden flex items-center justify-center">
                        <img
                          src={ImageUrl + (product.product_image || 'image unavailable')}
                          alt="Product Image"
                          className="max-w-full max-h-full object-contain"
                        />
                      </div>
                      <div>
                        <h2 className="text-xs sm:text-sm md:text-base font-bold text-center whitespace-normal">
                          {product.product_name || 'Product Name Not Available'}
                        </h2>
                        <p className="text-xs sm:text-xs md:text-base font-semibold text-center mt-2">{product.product_description}</p>
                      </div>
                      <div className="flex flex-row">
                        <div>
                        <div className="text-xs">
                            <p className='whitespace-normal text-center font-semibold'>Offer Price:</p>
                            <div className="flex flex-row">
                            {offer.OfferData.net_price === 0 ? (
                              <p className="text-center">₹{calculatePrice(product, offer)}</p>
                            ) : (
                              <p>₹{offer.OfferData ? offer.OfferData.net_price : 'Price Not Available'}</p>
                            )}
                             <p className="text-center">/{product.UOM || ''}</p>
                             </div>
                          </div>
                        </div>
                        </div>
                        <div>
                          <div className="md:text-sm lg:text-sm sm:text-xs p-2 flex flex-row space-x-2">
                            <div className="flex justify-center">
                              <button className="md:w-5 lg:w-5 sm:w-3 rounded-sm border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 sm:px-0 text-black focus:outline-none"
                                onClick={() => handleDecrement(product._id)}>-</button>
                              <input
                                type="text"
                                className="border border-gray-300 rounded-sm md:w-10 lg:w-10 sm:w-5 text-center focus:outline-none"
                                value={counts[product._id] || 0}
                                onChange={(e) => handleChange(product._id, e)}
                              />
                              <button className="md:w-5 lg:w-5 sm:w-3 rounded-sm border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 sm:px-0 text-black focus:outline-none"
                                onClick={() => handleIncrement(product._id)}>+</button>
                            </div>
                            <div className="flex justify-center">
                              <button className="md:text-sm lg:text-sm rounded-sm md:w-10 lg:w-10 sm:w-8 sm:text-[10px] border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 text-black"
                                onClick={() => handleAddItem(product, offer)}>
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      
                      {offer.productSubCategory && offer.productSubCategory.product_sub_category_name && offer.productSubCategory.product_sub_category_name === 'Electrical Wires' && (
      <div className="flex justify-start">
      <div>
      <p className="text-xs">Colours:</p>
      </div>
      <div className="flex flex-row space-x-1">
        <img src="https://via.placeholder.com/64/FF0000/FFFFFF?text=Red" alt="Red" className="w-4 h-4 rounded-lg" />
      <img src="https://via.placeholder.com/64/00FF00/FFFFFF?text=Green" alt="Green" className="w-4 h-4 rounded-lg" />
      <img src="https://via.placeholder.com/64/FFFF00/FFFFFF?text=Yellow" alt="Yellow" className="w-4 h-4 rounded-lg " />
      <img src="https://via.placeholder.com/64/0000FF/FFFFFF?text=Blue" alt="Blue" className="w-4 h-4 rounded-lg"/>
      <img src="https://via.placeholder.com/64/000000/FFFFFF?text=Black" alt="Black" className="w-4 h-4 rounded-lg"/>
      </div>
  </div>
      )}
                    </div>
                  ))
                ) : (
                  <div>No products found</div>
                )}
              </div>
              <hr className="border-gray-400 my-1 mt-5" />
              </>
              )}
            </div>
          ))}
        </div>
        <div className='flex justify-center mb-4 mt-6 '>
                <button className='rounded-sm text-black hover:text-white border border-[#EB001B] px-4 flex justify-center hover:bg-[#EB001B] ' onClick={handleBack} >Back</button>
                </div>
     </div>
     </div>
      </div>
 {/* Quotation div for large screens */}
      <div className="hidden lg:block p-2 lg:ml-2 rounded-md w-full h-full  border md:w-1/4 lg:w-1/4 flex flex-col lg:flex-row">
        <div className="rounded-md overflow-y-scroll h-full">
        <div className="p-1 border-b w-full flex flex-col">
  <div className="flex justify-center">
    <h1 className="font-semibold">Quotation</h1>
  </div>
  {OffersData && OffersData.length > 0 && (
    <div className="flex justify-end mt-2">
      <div className="flex flex-col items-end">
        <h1 className="text-sm">Total Price: ₹{ToTaladdedProductsPrice()}</h1>
        {CustomerQuotationData.amended_discount !== 0 && (
          <>
         <h1 className="text-sm">
        Discount Price: ₹{ CustomerQuotationData.amended_discount}
    </h1>
    <h1 className="text-sm">
       Final Price: ₹{ToTaladdedProductsPrice() - CustomerQuotationData.amended_discount}
    </h1>
    </>
)}

       
        
        
      </div>
    </div>
  )}
</div>


          {productAdded && productAdded.length > 0 && (
            <div>
              {productAdded
                .slice()
                .reverse()
                .map((selectedProduct, index) => (
                  <div className="hover:bg-red-100 flex flex-col border-b" key={index}>
                    <div className="flex justify-end mt-1">
                      <button className="text-red-500 focus:outline-none" onClick={() => handleDeleteProduct(selectedProduct?.product?._id)}>
                        <FaMinusCircle className="text-xs hover:text-red-500" />
                      </button>
                    </div>
                    <div key={index} className="flex items-center justify-between p-2 ">
                      <div className="w-20 h-20 overflow-hidden flex items-center justify-center">
                        <img
                          src={ImageUrl + (selectedProduct?.product?.product_image || 'image unavailable')}
                          alt="Product Image"
                          className="max-w-full max-h-full object-contain"
                        />
                      </div>
                      <div className="ml-4 flex flex-col">
                        <div className="flex justify-start">
                          <p className="text-xs font-semibold text-left whitespace-normal">{selectedProduct?.product?.product_name}</p>
                        </div>
                        <div className="flex flex-row justify-between space-x-2 ">
                          {/* <p className="p-1 text-sm">Qty: {selectedProduct?.count}</p> */}
                          <p>
                          <span className="font-semibold">Qty:</span> {selectedProduct.count} {getDisplayUOM(selectedProduct.product.UOM, selectedProduct.count)}
                          </p>
                          {(() => {
    const selectedOffer = OffersData?.find(p => p?.OfferData?._id === selectedProduct?.offer_id);
    if (selectedOffer) {
      return (
        <div>
        {selectedOffer.OfferData.net_price === 0 ? (
          <p>Offer Price: ₹{calculatePrice(selectedProduct.product, selectedOffer)}</p>
        ) : (
          <p>Offer Price: ₹{selectedOffer.OfferData ? selectedOffer.OfferData.net_price : 'Price Not Available'}</p>
        )}
        </div>
       
      );
    } else {
      return (
        <p className="p-1 text-sm">
          Price: ₹{selectedOffer?.OfferData?.net_price ?? 'Price Not Available'}
        </p>
      );
    }
  })()}
                        </div>
                        <div className="grid grid-cols-4 gap-1 mt-1">
 {Object.entries(QuantityBreakUp.find(p => p.product_id === selectedProduct.product._id)?.break_ups || {}).map(([key, value], index) => (
(value!== "" && value!== 0 && value!== undefined && value!== null) && (
<div key={index} className=" flex flex-row ">
<p className='text-xs'>{key}=</p>
<p className='text-xs'>{value}</p>
</div>
)
))}
</div>
                      </div>
                      
                    </div>
                   
                  </div>
                ))}
            </div>
          )}
          <div className="flex justify-center px-4 py-2 flex flex-row space-x-2">
          {isQuotationSaved ? (
            <button className="rounded-sm border border-gray-500 px-2 text-gray-500" disabled>
            Saved
            </button>
) : (
  <button className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black" onClick={() => handleGeneratePDF()}>
    Save
  </button>
)}
            <button className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black" onClick={() => handleNotifyQuotation()}>
            Notify
            </button>

          </div>
        </div>
        {showWirePopup && (
        <WireColorPopup
          onClose={() => setShowWirePopup(false)}
          onSubmit={handleWirePopupSubmit}
          BreakUps={breakUp}
        />
      )}

      </div>
    </div>
    </div>
  
  
    );
};

export default AdminViewQuotation;
