
import React, { useState, useRef , useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import logoImage from "./images/BB.png";
import { useNavigate, useLocation } from 'react-router-dom';

function NotApproved() {
    const navigate = useNavigate();

    const HandleOK = async () => {
        navigate("/bb/user");
      }

    return(
        <div className="w-full flex justify-center items-center h-screen font-roboto border border-gray-300">
            <div className="flex w-full justify-center items-center">
                <div className="flex flex-col space-y-4 justify-center w-full items-center">
<div className="flex-col w-1/2 sm:w-full md:w-2/3 lg:w-1/2 xl:w-1/3 flex self-center items-center border border-[#D9D9D9] border-2 bg-opacity-[15%] rounded-sm p-4">
            <p className="text-2xl pt-4">
              Thank You for registering. We will let you know after your account
              approval.
            </p>
            <div className="focus:outline-none flex justify-center items-center mt-6">
              <button
                className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-md rounded-sm px-4 py-1 mb-4"
                onClick={HandleOK}>
                OK
              </button>
            </div>
          </div>
          </div>
          </div>
          </div>

    )}
    export default NotApproved;