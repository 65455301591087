import { faIndianRupee, faIndianRupeeSign, faPercentage, faRupeeSign, faT, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { FaRupeeSign } from 'react-icons/fa';
import { Navigate, useNavigate } from 'react-router-dom';

const ViewOffer = () => {
  const navigate = useNavigate();
    const [offers, setOffers] = useState([]);
    const [selectedOffer, setSelectedOffer] = useState(null); // State to manage selected offer
    const [SelectedVendor, setSelectedVendor] = useState(null);
    const [SelectedproductCategory, setSelectedproductCategory] = useState(null);
    const [SelectedproductGroup, setSelectedproductGroup] = useState(null);
    const [Selectedproduct, setSelectedproduct] = useState(null);
    const [offerStatusAlert,setOfferStatusAlert]=useState(null);
    const [UpdateOfferAlert, setUpdateOfferAlert] = useState(false);
    const [showPopup, setShowPopup] = useState(false); // State to manage the visibility of the popup
    const [OfferData,setOfferData]=useState()
    const [ContChangeStatusAlert,setContChangeStatusAlert]=useState('')
    const GSTOptions = ['0','5', '12', '18', '28'];
    //const [offerListedAlert,setOfferListedAlert]=useState(false);
  
 useEffect(()=>{
  const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
  const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
  if(storedLoginData&&Userdata){
    if(storedLoginData.token===Userdata.token){
    }else{
      navigate('/bb/admin')
     sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
    }
  }else{
    navigate('/bb/admin')
      sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
  }
})


    useEffect(() => {
      
      getAllOffers();
    }, []); 

    function clearStorageAndRedirectToHome() {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/bb/admin';
    }

    const getAllOffers = async () => {
      const Getalloffers = process.env.REACT_APP_FORE_BRICKBUCKET + '/Admin/offer/get-offers';
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(Getalloffers, {
          method: "GET",
          headers: {
            "Content-Type": "application/json", // Specify Content-Type as application/json
            'x-token' : token 
            // You can add other headers if needed
          },
        });
  
        if (response.ok) {
          const contentType = response.headers.get("content-type");
          if (!contentType || !contentType.includes("application/json")) {
            console.error("Invalid content type received, expected JSON")
           // throw new Error("Invalid content type received, expected JSON");
          }
    
          const data = await response.json();
          console.log(data)
          setOffers(data);
        }else{
          setOffers([])
          console.error(`HTTP error! Status: ${response.status}`)
          //throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        
      } catch (error) {
        clearStorageAndRedirectToHome()
      }
    };
    

    // Function to handle show popup
    const handleShowPopup = (OfferData, vendor, productCategory, productGroup, product, OfferId) => {
      const Offer={...OfferData, _id:OfferId}
      setSelectedOffer(Offer);
      setSelectedVendor(vendor);
      setSelectedproductCategory(productCategory);
      setSelectedproductGroup(productGroup);
      setSelectedproduct(product)
      setShowPopup(true);
  }

  // Function to close the popup
  const handleClosePopup = () => {
      setShowPopup(false);
  }
 
  const DeleteOffer = async (offerId) => {
    const deleteViewOffer = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/delete-offer-by-offerId?offer_id=${offerId}`;
    const token = sessionStorage.getItem('token');
    try {
        const response = await fetch(deleteViewOffer, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'x-token ' : token 
            },
        });
        if (response.ok) {
            const data = await response.json();
            console.log(data.message); // Log success message
            // Handle success (e.g., show success message to the user)
            getAllOffers(); // Assuming this function fetches all offers after deletion
        } else {
            // Handle non-success status (e.g., show error message to the user)
            console.error('Error deleting offer:', response.statusText);
        }
    } catch (error) {
        console.error('Error deleting offer:', error);
        clearStorageAndRedirectToHome()
        // Handle network errors or other exceptions (e.g., show error message to the user)
    }
};

const handleClickUpdateproductgroup = (offer) => {
  console.log(offer.productData);
  navigate('/bb/admin/AdminHome/UpdatedProductGroups' ,{ 
    state: { 
      productGroup: offer.productData, 
      SelectedproductCategory: SelectedproductCategory 
    } 
  }
  );
}
const handleProducts= (offer)=> {
navigate('/bb/admin/AdminHome/ViewofferProducts',  {state: { 
  offer: offer
} });
}

const handleTransportChange = () => {
  setSelectedOffer(prevOffer => ({
    ...prevOffer,
    transport_included: !prevOffer.transport_included // Toggle the value
  }));
};
const handleLoadingChange= () => {
  setSelectedOffer(prevOffer => ({
    ...prevOffer,
    loading_included: !prevOffer.loading_included // Toggle the value
  }));
};
const handleUnLoadingChange= () => {
  setSelectedOffer(prevOffer => ({
    ...prevOffer,
    unloading_included: !prevOffer.unloading_included // Toggle the value
  }));
};

const handleAdvancePaymentChange = (value) => {
  const parsedValue = (value);
  if (!isNaN(parsedValue)) {
    // Calculate final payment percentage
    const finalPayment = 100 - parsedValue;

    // Update state only if final payment is valid
    if (finalPayment >= 0) {
      setSelectedOffer(prevOffer => ({
        ...prevOffer,
        advance_payment_percentage: parsedValue,
        final_payment_percentage: finalPayment,
      }));
    }
  }
};
const handleExpiryDaysChange = (value) => {
  const parsedValue = value; // Parse the input value as an integer
  if (!isNaN(parsedValue)) {
    setSelectedOffer(prevOffer => ({
      ...prevOffer,
      offer_expiry_In_days: parsedValue,
    }));
  }
};

const handleDeliveryDaysChange = (value) => {
  const parsedValue = (value); // Parse the input value as an integer
  if (!isNaN(parsedValue)) {
    setSelectedOffer(prevOffer => ({
      ...prevOffer,
      offer_delivery_In_days: parsedValue,
    }));
  }
};
 const handleGSTChange = (isChecked ,gstRate) => {
  if(gstRate===GSTOptions[0]||gstRate===0){

  }else{
    const netPrice= calculateNetPrice(selectedOffer.base_price, selectedOffer.discount, '0');
    setSelectedOffer(prevOffer => ({
      ...prevOffer,
      GST_included: isChecked,
    }));
    if(!isChecked){
      setSelectedOffer(prevOffer => ({
        ...prevOffer,
        GST_rate_id: 0,
        net_price:netPrice
      }));
    }
  }
 
};
 
const ChangeOfferStatus = (offer) => {
  if (offer.OfferData.offer_status === 'active') {
    return 'deactive';
  } else {
    return 'active';
  }
};

const handleYes =async()=>{
  const status = ChangeOfferStatus(OfferData);
  try {
    const token = sessionStorage.getItem('token');
    const updateUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/update-offer-status`;
    const response = await fetch(updateUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-token' : token 
      },
      body: JSON.stringify({
        _id: OfferData._id,
        offer_status: status,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      setOffers(data);
      setOfferStatusAlert(null)
    } else {
      setOfferStatusAlert(null)
      setContChangeStatusAlert('offer is asociated with customer quatation')
     // throw new Error('Failed to update offer status');
    }

    // Handle response here if needed
  } catch (error) {
    // Handle error here
    clearStorageAndRedirectToHome()
    console.error('Error updating offer status:', error);
    // Display error message to the user or perform fallback behavior
    // Example: setError('Failed to update offer status');
  }
}

/* const ChangeOfferListed = (offer) => {
  if (offer.OfferData.list_offer === 'yes') {
    return 'no';
  } else {
    return 'yes';
  }
}; */

/* const SetOfferListedEllert = (offer) => {
  if (offer.list_offer=== 'yes') {
    return 'no';
  } else {
    return 'no';
  }
}; */

/* const handleChangeOfferListed =async()=>{
  const offerListed = ChangeOfferListed(OfferData);
  try {
    const token = sessionStorage.getItem('token');
    const updateUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/update-offer-listed`;
    const response = await fetch(updateUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-token' : token 
      },
      body: JSON.stringify({
        _id: OfferData._id,
        list_offer: offerListed,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      setOffers(data);
      setOfferListedAlert(null)
    } else {
      setOfferStatusAlert(null)
      setContChangeStatusAlert('offer is asociated with customer quatation')
     // throw new Error('Failed to update offer status');
    }

    // Handle response here if needed
  } catch (error) {
    // Handle error here
    clearStorageAndRedirectToHome()
    console.error('Error updating offer status:', error);
    // Display error message to the user or perform fallback behavior
    // Example: setError('Failed to update offer status');
  }
} */



const handleNo=()=>{
  setOfferData()
  setOfferStatusAlert(null)  
}
 const handleStatusChange =(offer) => {
  setOfferData(offer)
  setOfferStatusAlert(`Are you sure want to  ${ChangeOfferStatus(offer)} the offer?`)  
}; 

const handlelistOffer = () => {
  setSelectedOffer({
    ...selectedOffer,
    list_offer: 'yes'
  });
};

const handleUnlistOffer = () => {
  setSelectedOffer({
    ...selectedOffer,
    list_offer: 'no'
  });
};

const handleGSTRateChange = (event) => {

 const netPrice= calculateNetPrice(selectedOffer.base_price, selectedOffer.discount, event);
  if(event===GSTOptions[0]){
    setSelectedOffer(prevOffer => ({
      ...prevOffer,
      GST_included: false,
      GST_rate_id: event,
      net_price:netPrice
    }));
  }else{
    setSelectedOffer(prevOffer => ({
      ...prevOffer,
      GST_rate_id: event,
      GST_included: true,
      net_price:netPrice
    }));
  }
}

const calculateNetPrice = (basePrice, discount, gst) => {
  let netPrice = parseFloat(basePrice) || 0;
  let discountAmount = parseFloat(discount) || 0;
  let gstPercentage = parseFloat(gst) || 0;

  // Apply discount
  netPrice -= (netPrice * (discountAmount / 100));

  // Apply GST
  if (gstPercentage !== 0) {
    netPrice *= (1 + (gstPercentage / 100));
  }
  return (netPrice.toFixed(2))
};

const calculateBasePrice = (NetPrice, discount, gst) => {
  let netPrice = parseFloat(NetPrice) || 0;
  let discountAmount = parseFloat(discount) || 0;
  let gstPercentage = parseFloat(gst) || 0;
  const basePrice= (netPrice/(1+gstPercentage/100))/(1-discountAmount/100)
  return basePrice.toFixed(3);
};

 const handleNetPriceChange = (value) => {
  const BasePrice= calculateBasePrice(value, selectedOffer.discount, selectedOffer.GST_rate_id);
setSelectedOffer(prevOffer => ({
  ...prevOffer,
  net_price:value,
  base_price:BasePrice
}));
 
};
 

const handleBasePriceInputChange = (event) => {
  const NetPrice= calculateNetPrice(event.target.value, selectedOffer.discount, selectedOffer.GST_rate_id);
setSelectedOffer(prevOffer => ({
  ...prevOffer,
  net_price:NetPrice,
  base_price:event.target.value
}));
};

const handleDiscountInputChange = (event) => {
const NetPrice= calculateNetPrice(selectedOffer.base_price, event.target.value, selectedOffer.GST_rate_id);
setSelectedOffer(prevOffer => ({
  ...prevOffer,
  net_price:NetPrice,
  discount:event.target.value
}));

};

/* const handleGSTChange = (event) => {

    setSelectedGST(event.target.value);
    setIncludeGST(true)
    calculateNetPrice(basePriceInputValue, discountInputValue, event.target.value);
    if(event.target.value===GSTOptions[0]){
      setIncludeGST(false)
    }else{
      setIncludeGST(true)
    }
  } */

  const calculateGstPrice = () => {
    // Validate selectedOffer.GST_rate_id
    const gstRate = parseFloat(selectedOffer.GST_rate_id);
    if (isNaN(gstRate)) {
      // Handle invalid GST rate id (e.g., 'nogst')
      return '0';
    }
  
    const gstPrice = (selectedOffer.base_price - selectedOffer.base_price * selectedOffer.discount / 100) * (gstRate / 100);
    return gstPrice.toFixed(3);
  };
  

const updateOffer = async () => {
  try {
    const token = sessionStorage.getItem('token');
      const updateUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/update-offer`;
      const response = await fetch(updateUrl, {
          method: 'PUT', // Use PUT method for updating data
          headers: {
              'Content-Type': 'application/json',
              'x-token' : token
          },
          body: JSON.stringify(selectedOffer), // Convert selectedOffer to JSON string
      });

      if (response.ok) {
          const data = await response.json();
          setUpdateOfferAlert(data.message)
          
          getAllOffers();
          // You can handle success here (e.g., show success message)
      } else {
        const data = await response.json();
        setUpdateOfferAlert(data.message)
          console.error('Update failed:', response.statusText);
          // Handle error (e.g., show error message)
      }
  } catch (error) {
    clearStorageAndRedirectToHome()
      console.error('Update error:', error);
      // Handle network errors or other exceptions
  }
};

const handleContChangeStatusAlert= ()=>{
  setContChangeStatusAlert('')
  setOfferStatusAlert(null)
}

const handleUpdateOfferAlert = () => {
  setUpdateOfferAlert(false);
  setShowPopup(false)
}

 
/* const handleNoOfferListed = () =>{
  setOfferListedAlert(false);
} */

    return (
      <div className="w-full h-full mx-auto overflow-y-scroll no-scrollbar">
      <div className="max-w-screen-xl mx-auto p-4">
    
   
   
    {ContChangeStatusAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{ContChangeStatusAlert}</p>
      <div className="flex">
        <button className="text-white bg-red-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleContChangeStatusAlert}>OK</button>
      </div>
    </div>
  </div>
)}

    {offerStatusAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{offerStatusAlert}</p>
      <div className="flex">
        <button className="text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4 mr-2" onClick={handleYes}>Yes</button>
        <button className="text-white bg-red-600 hover:bg-red-700 py-1 px-2 rounded-md mt-4" onClick={handleNo}>No</button>
      </div>
    </div>
  </div>
)}



{UpdateOfferAlert && (
  <div className="fixed z-50 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{UpdateOfferAlert}</p>
      <div className="flex">
        <button className="text-white bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleUpdateOfferAlert}>OK</button>
      </div>
    </div>
  </div>
)}

<div className="bg-white flex flex-col relative rounded-sm   overflow-x-auto">
          {/* <div>
            <h1 className="text-xl font-semibold text-center">View Offer</h1>
          </div> */}
          <div className="overflow-x-scroll no-scrollbar">
          {offers.length > 0 ? (
            <table className="w-full border border-gray-300 ">
              <thead className="border-b border-gray-300">
                <tr className="bg-[#D9D9DD] bg-opacity-[100%]">
                
                <th className="text-blue-950 font-medium py-2 px-2 text-sm text-center whitespace-nowrap">Offer-No</th>
                  <th className="text-blue-950 font-medium py-2 px-2 text-sm text-center ">Vendor Name</th>
                  <th className="text-blue-950 font-medium py-2 px-2 text-sm text-center "> Product Category </th>
                  <th className="text-blue-950 font-medium py-2 px-2 text-sm text-center ">Product Group</th>
                  <th className="text-blue-950 font-medium py-2 px-2 text-sm text-center ">Products</th>
                  <th className="text-blue-950 font-medium py-2 px-2 text-sm text-center ">Base Price</th>
                  <th className="text-blue-950 font-medium py-2 px-2 text-sm text-center ">Discount</th>
                  {/* <th className="text-blue-950 font-medium py-2 px-2 text-sm text-center whitespace-nowrap">GST</th> */}
                  <th className="text-blue-950 font-medium py-2 px-2 text-sm text-center ">Net Price</th>
                  {/* <th className="text-blue-950 font-medium py-2 px-2 text-sm text-center ">Advance Payment %</th>
                  <th className="text-blue-950 font-medium py-2 px-2 text-sm text-center ">Final Payment %</th> */}
                 
                {/*   <th className="text-blue-950 text-opacity-90 py-2 px-2 text-sm text-center">Offer Expiry Days</th> */}
                 {/*  <th className="text-blue-950 text-opacity-90 py-2 px-2 text-sm text-center">State</th> */}
                  <th className="text-blue-950 font-medium py-2 px-2 text-sm text-center ">Actions</th>
                </tr>
              </thead>
              <tbody className="border-b border-gray-300">
              {Array.isArray(offers) && offers.map((offer, index) => (
    <tr key={`${offer._id}`} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-200'}>
   
        <td className="text-[#EB001B] text-opacity-80 py-2 text-xs px-2 text-center" >
          <p className='cursor-pointer'
          onClick={()=>handleShowPopup(offer.OfferData, offer.vendor, offer.productCategory, offer.productGroup, offer.product, offer._id)}>
            {offer.OfferData.offer_no}
            </p>
          </td>
        <td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">{offer.vendor.name}</td>
        <td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">{offer.productCategory?.product_category_name}</td>
        <td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center " /* onClick={() => handleClickUpdateproductgroup(offer)} */>
  {offer.productGroup?.product_group_name ? offer.productGroup.product_group_name : "No product group"}
</td>
        
      <td className="text-[#EB001B] text-opacity-80 py-2 text-xs px-2 text-center cursor-pointer"onClick={()=>handleProducts(offer)}>{/* {offer.productData.product ? offer.productData.product.product_name : 'Product not available'} */}View</td>
      {offer.OfferData.base_price === 0 ? (
    <td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">-</td>
) : (
    <td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">₹ {offer.OfferData.base_price}</td>
)}
    
        <td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">{offer.OfferData.discount}%</td>
       {/*  <td className="text-blue-950 text-opacity-80 py-2 text-xs
         px-2 text-center">{offer.OfferData.GST_rate_id}</td> */}

        {offer.OfferData.base_price === 0 ? (
        <td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">-</td>
      ) : (
        <td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">₹ {offer.OfferData.net_price}</td>
      )}
        {/* <td className="text-blue-950 text-opacity-80 py-2 text-sm px-2 text-center">{offer.OfferData.advance_payment_percentage}%</td>
        <td className="text-blue-950 text-opacity-80 py-2 text-sm px-2 text-center">{offer.OfferData.final_payment_percentage}%</td> */}
        
        <td className="flex text-[#F4815C] text-opacity-80 py-2 text-xs px-2 items-center cursor-pointer mt-2">
  <div className='flex flex-row space-x-2 justify-center items-center'>
    {/* <div className="text-gray-700 font-medium"> 
      {offer.OfferData.offer_status === 'active' ? 'Activated' : 'Deactivated'}
    </div> */}
   <div className="w-full flex justify-center items-center">
  <div className="flex justify-center items-center ">
    <button
      className={`px-2 py-1 rounded-xs text-white ${
        offer.OfferData.offer_status === 'active' ? 'bg-red-500' : 'bg-green-500'
      }`}
      onClick={() => handleStatusChange(offer)}
    >
      {offer.OfferData.offer_status === 'active' ? 'Deactivate' : 'Activate'}
    </button>
  </div>
  {/* Other content of the Tile */}
</div>

  </div>
</td>

    </tr>
))}


</tbody>

            </table>
             ) : (
              <p className="text-center">No offers available</p>
          )}
          </div>
          </div>
          </div>
          
        
          {showPopup && selectedOffer && (
    <div className="fixed inset-0 z-30 flex items-center justify-center p-2">
        <div className="absolute inset-0 bg-black opacity-30"></div>
        <div className="bg-white py-5 px-10 rounded-md shadow-md relative z-50 flex flex-col items-center overflow-auto max-h-full">
            <button className="absolute top-0 right-0 m-2 " onClick={handleClosePopup}>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <h1 className="text-lg font-semibold text-center mb-4">Offer Details</h1>
            <div className="overflow-auto">
            <table className="w-full">
                <tbody>
                    <tr className='border-b border-t h-10'>
                        <td className=" font-medium text-align-left">Location </td>
                        <td className="pl-6 font-medium text-align-left"> :</td>
                        <td className=' text-align-left pl-6'>{selectedOffer.location}</td>
                    </tr>
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">State </td>
                    <td className="pl-6 text-align-left"> :</td>
                    <td className="  text-align-left pl-6">{selectedOffer.state}</td>
                    </tr>
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Vendor Name</td>
                    <td className="pl-6 font-medium text-align-left"> :</td>
                        <td className=' text-align-left pl-6'>{SelectedVendor.name}</td>
                    </tr>
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Product Category </td>
                    <td className="pl-6 font-medium text-align-left">:</td>
                    <td className=" pl-6 text-align-left">{SelectedproductCategory.product_category_name}</td>
                    </tr>
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Product Group</td>
                    <td className="pl-6 font-medium text-align-left">:</td>
                    <td className=" pl-6 text-align-left"> {SelectedproductGroup?.product_group_name || "No product group"}</td>
                    </tr>
                    {/* <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Products</td>
                    <td className="pl-6 font-medium text-align-left">:</td>
                    <td className=" pl-6 text-align-left">{Selectedproduct ? Selectedproduct.product_name : 'Product not available'}Products</td>
                    </tr> */}
        
                    <tr className='border-b h-10'>
    <td className="font-medium text-align-left">Base Price</td>
    <td className="pl-6 font-medium text-align-left">:</td>
    {selectedOffer.base_price === 0 ? (
        <td className="pl-6 text-align-left">-</td>
    ) : (
        <td className="pl-6 text-align-left">
            <div className="flex items-center relative">
    <span style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", left: "5px" }}>
        <FontAwesomeIcon icon={faIndianRupeeSign} className='w-3 h-3 flex justify-center items-center' />
    </span>
    <input
        id="basePriceInput"
        name="basePriceInput"
        type="text"
        value={selectedOffer.base_price}
        onChange={handleBasePriceInputChange}
        className="pl-5 rounded-sm focus:outline-none hover:border-[#EB001B] w-1/3 px-1 border border-gray-300 rounded-md"
        //style={{ paddingRight: "25px" }} // Add right padding to make space for the "/-" symbol
        placeholder="Enter base price"
    />
</div>

        </td>
    )}
</tr>



                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Discount</td>
                        <td className="pl-6 font-medium text-align-left">:</td>

                        <td className=" pl-6 text-align-left">
                        <div className='flex items-center'>
                  <input
                    id="discountInput"
                    name="discountInput"
                    type="text"
                    value={selectedOffer.discount}
                    onChange={handleDiscountInputChange}
                    className="rounded-sm focus:outline-none hover:border-[#EB001B] w-1/3 px-1 border border-gray-300 rounded-md" // Adjusted padding to accommodate the icon
                    placeholder="Enter discount"
                  />
                 <span style={{ position: "relative", right: "20px" }}>%</span>
            </div>
                        </td>
                    </tr>
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">GST</td>
                      <td className="pl-6 font-medium text-align-left">:</td>
                      <td className=" pl-6 text-align-left">
                      <label htmlFor="gstCheckbox" className="">
                  {calculateGstPrice()}
                </label>

                  <select
                id="categoryGST"
                name="categoryGST"
                value={selectedOffer.GST_rate_id}
                onChange={(e)=>handleGSTRateChange(e.target.value)}
                className="rounded-sm focus:outline-none hover:border-[#EB001B] w-30 ml-2 border border-gray-300 rounded-md"
              >
                {/* <option value="" disabled>Select GST</option> */}
                {GSTOptions.map((gst, index) => (
                  <option key={index} value={gst}>{gst==='0'?'No Gst':gst} %</option>
                ))}
              </select>
              
                  
                  </td>
                    </tr>
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">GST Included</td>
                      <td className="pl-6 font-medium text-align-left">:</td>
                      <td className=" pl-6 text-align-left">
                      <input
                    type="checkbox"
                    checked={selectedOffer.GST_included}
                    onChange={(e) => handleGSTChange(e.target.checked, selectedOffer.GST_rate_id)}
                    className="form-checkbox text-blue-500"
                  />
                  <label htmlFor="gstCheckbox" className="ml-2"> {selectedOffer.GST_included ? 'Yes' : 'No'}</label>
                  
                  </td>
                    </tr>

                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Net Price</td>
                        <td className="pl-6 font-medium text-align-left">:</td>
                        {selectedOffer.net_price===0?(
                             <td className=" pl-6 text-align-left">-</td>
                        ):(
                          <td className="pl-6 text-align-left relative">
    <div style={{ position: "relative", width: "100%" }}>
        <span style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", left: "5px", pointerEvents: "none" }}>
            <FontAwesomeIcon icon={faIndianRupeeSign} className='w-3 h-3 flex justify-center items-center' />
        </span>
        <input
            type="text"
            value={selectedOffer.net_price}
            onChange={(e) => handleNetPriceChange(e.target.value)}
            className="pl-5 rounded-sm focus:outline-none hover:border-[#EB001B] w-1/3 px-1 border border-gray-300 rounded-md"
            disabled={false} // Initial enabled state
        />
    </div>
</td>

                      

                        )}
                      
                    </tr>
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Transport</td>
                      <td className="pl-6 font-medium text-align-left">:</td>
                      <td className=" pl-6 text-align-left">
                    <input
                      type="checkbox"
                      checked={selectedOffer.transport_included}
                      onChange={(e) => handleTransportChange(e.target.checked)}
                    />
                    <label htmlFor="transportCheckbox" className="ml-2">
                      {selectedOffer.transport_included ? 'Included' : 'Not Included'}
                    </label>
                  </td>

                    </tr>
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Loading</td>
                      <td className="pl-6 font-medium text-align-left">:</td>
                      <td className=" pl-6 text-align-left">
                      <input
                    type="checkbox"
                    checked={selectedOffer.loading_included}
                    onChange={(e) => handleLoadingChange(e.target.checked)}
                  />
                  <label htmlFor="loadingCheckbox" className="ml-2">
                    {selectedOffer.loading_included ? 'Included' : 'Not Included'}
                  </label>
                        </td>   
                    </tr>
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Unloading</td>
                      <td className="pl-6 font-medium text-align-left">:</td>
                      <td className="pl-6 text-align-left">
                      <input
                    type="checkbox"
                    checked={selectedOffer.unloading_included}
                    onChange={(e) => handleUnLoadingChange(e.target.checked)}
                  />
                  <label htmlFor="unloadingCheckbox" className="ml-2">
                    {selectedOffer.unloading_included ? 'Included' : 'Not Included'}
                  </label>
                  </td>
                    </tr>
                    
                   
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Advance Payment </td>
                        <td className=" pl-6 font-medium text-align-left">:</td>
                        <td className=" pl-6 text-align-left">
                        <input
                  type="text"
                  value={selectedOffer.advance_payment_percentage}
                  onChange={(e) => handleAdvancePaymentChange(e.target.value)}
                  className="border border-gray-300 bg-white rounded-sm px-1 w-1/3 outline-none focus:border-blue-500"
                  disabled={false} // Initial enabled state
                />
               <span style={{ position: "relative", right: "20px" }}>%</span>

                </td>

                    </tr>
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Final Payment </td>
                        <td className=" pl-6 font-medium text-align-left">:</td>
                        <td className=" pl-6 text-align-left">{selectedOffer.final_payment_percentage}  %</td>
                    </tr>
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Offer Expiry Days</td>
                        <td className=" pl-6 font-medium text-align-left">:</td>
                        <td className=" pl-6 text-align-left">
                        <input
                      type="text"
                      value={selectedOffer.offer_expiry_In_days}
                      onChange={(e) => handleExpiryDaysChange(e.target.value)}
                      className="border border-gray-300 bg-white rounded-sm px-1 w-1/3  outline-none focus:border-blue-500"
                      disabled={false} // Initial enabled state
                    />
                    </td>
                    </tr>
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Delivery In Days</td>
                        <td className="  pl-6 font-medium text-align-left">:</td>
                        <td className=" pl-6 text-align-left">
                        <input
                      type=""
                      value={selectedOffer.offer_delivery_In_days}
                      onChange={(e) => handleDeliveryDaysChange(e.target.value)}
                      className="border border-gray-300 bg-white rounded-sm px-1 w-1/3  outline-none focus:border-blue-500"
                      disabled={false} // Initial enabled state
                    />
                    </td>

                    </tr>
                    <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">List Offer</td>
                        <td className="  pl-6 font-medium text-align-left">:</td>
                        <td className=" pl-6 text-align-left  ">
         <div className='flex flex-row space-x-4 items-center'>
           <div className='flex flex-row space-x-2 justify-center items-center '>
           <label>YES</label>               
              <input
                    type="checkbox"
                    checked={selectedOffer.list_offer==='yes'}
                    onClick={() => handlelistOffer(selectedOffer)}
                    className="form-checkbox text-blue-500"
                  />
                  </div>
                  <div className='flex flex-row space-x-2 justify-center items-center '>
                    <label>NO</label>    
               <input
                    type="checkbox"
                    checked={selectedOffer.list_offer==='no'}
                    onClick={() => handleUnlistOffer(selectedOffer)}
                    className="form-checkbox text-blue-500"
                  />
                  </div>
                  </div>
                  {/* <label htmlFor="gstCheckbox" className="ml-2"> {offer.OfferData.list_offer==='Yes' ?'listed':'NotListd'}</label> */}
                    </td>

                    </tr>


        {/*             <tr className='border-b h-10'>
                    <td className=" font-medium text-align-left">Offer Status</td>
  <td className="  pl-6 font-medium text-align-left">:</td>
  <td className=" pl-6 text-align-left">
    <label htmlFor="toggleStatus" className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          id="toggleStatus"
          className="sr-only"
          checked={selectedOffer.offer_status === 'active'}
          onChange={(e) => handleStatusChange(e.target.checked)}
        />
        <div
          className={`block w-8 h-4 rounded-full ${selectedOffer.offer_status === 'active' ? 'bg-green-500' : 'bg-red-500'}`}
        ></div>
        <div
          className={`dot absolute left-0 top-0 bg-white w-4 h-4 rounded-full transition ${selectedOffer.offer_status === 'active' ? 'translate-x-full' : 'translate-x-0'}`}
        ></div>
      </div>
      <div className="ml-3 text-gray-700 font-medium"> 
        {selectedOffer.offer_status === 'active' ? 'Activated' : 'Deactivated'}
      </div>
    </label>
  </td>
</tr>
 */}
                    <tr className='border-b h-10'>
                    <td className="  font-medium text-align-left">Terms & Conditions</td>
                        <td className="  pl-6 font-medium text-align-left">:</td>
                        <td className=" pl-6 text-align-left">{selectedOffer.offer_terms}</td>
                    </tr>




                </tbody>
            </table>
            </div>
            <button className=" flex justify-center px-3 rounded-sm py-1 mt-2 border border-1 border-red-500 hover:bg-[#EB001B] hover:text-white " onClick={updateOffer}>Update</button>
        </div>
    </div>
)}

</div>
       
    )
}


export default ViewOffer;