import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera,} from '@fortawesome/free-solid-svg-icons';
//import ApplyFilters from './ApplyFilters'; // Replace with the actual path
import { useNavigate } from 'react-router-dom';
import ApplyAttributes from './ApplyAttributes';
const CreateProductGroup = () => {
  const [productCategories, setProductCategories] = useState([]);
  const [productSubCategories, setProductSubCategories] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState('');
  const [selectedProductSubCategory, setSelectedProductSubCategory] = useState('');
  const [vendors,setVendors]= useState([])
  const [selecetdVendor,setSelecetdVendor]=useState('')
  const [productGroupName,setProductGroupName]= useState('')
  const [image, setImage] = useState(null);
 const [productGroupFile,setProductGroupFile] = useState(null);
 const [AttributesData, setAttributesData] = useState([]);
 const [attributeIds, setAttributeIds] = useState([]);
 const  [Attribute_Value_Ids, set_Attribute_Value_Ids] = useState();
 //const [price,setPrice]=useState('')
 const [unitOfMeasurement, setUnitOfMeasurement] = useState('');
 const  [successMessage, setSuccessMessage] = useState(null);
 const [RequiredAlert, setRequiredAlert] = useState(false);
 const [unitOfMeasurementOptions, setUnitOfMeasurementOptions] = useState([]); 
 const navigate = useNavigate();

 const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  useEffect(() => {
    fetchProductCategories();
  }, []);

  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/bb/admin';
  }

  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
        navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })

  const fetchProductCategories = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await fetch(`${backendApiUrl}/Admin/get-all-productCategorys`,{
        method:'GET',
        headers:{
          'Content-Type': 'application/json',
          'x-token': token
        }
      });
      if (response.ok) {
        const data = await response.json();
      setProductCategories(data);
      }else{
        console.error('Failed to fetch product categories')
        // throw new Error('Failed to fetch product categories');
      }
     
    } catch (error) {
      console.error(error);
      clearStorageAndRedirectToHome()
    }
  };

   useEffect(() => {
    fetchUnitOfMeasurementOptions(); // Fetch UOM options on component mount
  }, []);

 // In your fetchUnitOfMeasurementOptions function:
 const fetchUnitOfMeasurementOptions = async () => {
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${backendApiUrl}/Admin/UOMs/get-all-UOMs`,{
      method: "GET",
      headers:{
        'x-token':token
      }
  });
    if (response.ok) {
      const data = await response.json();
      setUnitOfMeasurementOptions(data.uoms); // This is the correct way to store the array of UOMs
      console.log(data); 
    } else {
      console.error('Failed to fetch UOM options');
    }
  } catch (error) {
    console.error('Error while fetching UOM options:', error);
  }
};

  const handleProductCategoryDropdown = (e) => {
    const selectedValue = e.target.value;
    // Check if the clicked value is already selected
    if (selectedValue === selectedProductCategory) {
      // If it is selected, set it to empty string to deselect
      setSelectedProductCategory('');
    } else {
      // Otherwise, set the clicked value as selected
      setSelecetdVendor('');
      setSelectedProductCategory(selectedValue);
      fetchProductSubCategories(selectedValue)
      fetchVendorsSelectedProductCategoryAssined(selectedValue);
      fetchAttributesByProductCategory(selectedValue);
    }
  };

  const handleProductSubCategoryDropdown = (e)=>{
    const selectedValue = e.target.value; 

    if(selectedValue === selectedProductSubCategory){
      setSelectedProductSubCategory('')
    }else{
      setSelectedProductSubCategory(e.target.value);
    }
  }

  const fetchProductSubCategories = async (product_category_id) => {
    try {
      const token = sessionStorage.getItem('token');

        const getProductSubcategories = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/product-sub-category/get-productSubCategories-by-productCategoryId?product_category_id=${product_category_id}`;
        
        const response = await fetch(getProductSubcategories, {
            method: "GET",
            headers:{
              'x-token':token
            }
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setProductSubCategories(data.data);
        } else {
          setProductSubCategories([])
            console.error('Failed to fetch product subcategories', response.status, response.statusText);
        }
    } catch (error) {
        console.error('Error while fetching product subcategories:', error);
    }
};

  const fetchVendorsSelectedProductCategoryAssined=async(productCategoryId)=>{
    const token = sessionStorage.getItem('token');
    try {
        const response = await fetch(`${backendApiUrl}/Admin/get-vendorsBy-productCategoryId?product_category_id=${productCategoryId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-token'  : token
            // Add any other headers you may need
          },
        });
        if (response.ok) {
          const vendorsData = await response.json();
      
          if (!vendorsData || !vendorsData.vendors) {
            // Handle empty or invalid response
            console.error('Invalid response from the server')
            //throw new Error('Invalid response from the server');
          }
          // Update state with the fetched vendors data
          setVendors(vendorsData.vendors);
        }else{
 // Reset the state if the response is not okay
 console.error('Failed to fetch vendors for the selected product category')
 //throw new Error('Failed to fetch vendors for the selected product category');
        }      
      } catch (error) {
        // Handle errors, e.g., show an error message
        console.error('Error fetching vendors:', error.message);
        clearStorageAndRedirectToHome()
        // Optionally, you can set a state to indicate an error occurred
      } 
  }

  const fetchAttributesByProductCategory = async (productCategoryId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${backendApiUrl}/Admin/product-group-attributes/get-all-attributes-by-productCategory?product_category_id=${productCategoryId}`,{
        method: 'GET',
        headers:{
          'Content-Type': 'application/json',
          'x-token' : token
        }
      });

      if (response.ok) {
        const attributesData = await response.json();

 // console.log(attributesData)
      // Set the state with the fetched filter data
      setAttributesData(attributesData);

      }else{
        console.error('Failed to fetch filters for the selected product category')
        // throw new Error('Failed to fetch filters for the selected product category');
      }
    } catch (error) {
      clearStorageAndRedirectToHome()
    }
  };

  
  const handleVendorDropdown=(e)=>{
    const selectedValue = e.target.value;
    // Check if the clicked value is already selected
    console.log(selectedValue)
    if (selectedValue === selecetdVendor) {
      // If it is selected, set it to empty string to deselect
      setSelecetdVendor('');
    } else {
      // Otherwise, set the clicked value as selected
      setSelecetdVendor(selectedValue);
    }
  }

  const handleUnitOfMeasurementChange = (e) => {
    setUnitOfMeasurement(e.target.value); // You might want to store the selected UOM id instead of just the value
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file)
        // You can perform additional checks or operations here, such as validating file type or size.
        setProductGroupFile(URL.createObjectURL(file)); // Create URL for the selected file and set it as the image source.
    }
};

const handleattribute_values_dict = async () => {
    if (attributeIds) {
      // Create a new dictionary to store filter values
      let attribute_values_dict = {};

      // Populate the dictionary with filter values
      for (const selected_attribute of attributeIds) {
        const attribute_name_id = selected_attribute.attribute_name_id;
        const attribute_value_id = selected_attribute.attribute_value_id;

        for (const attribute_group of AttributesData) {
          if (attribute_group.attribute_name_id === attribute_name_id) {
            for (const attribute_value of attribute_group.attribute_values) {
              if (attribute_value.attribute_value_id === attribute_value_id) {
                attribute_values_dict[attribute_name_id] = attribute_value.attribute_value;
              }
            }
          }
        }
      }

      // Create the product name
      const new_product_name = Object.values(attribute_values_dict).join('_');
      // Update the state with the new product name
    }
  };

useEffect(() => {
    handleattribute_values_dict(); // Call the function here
  }, [attributeIds]);
  

  const handleSubmit = async () => {
   
    if (!productGroupName || !selectedProductCategory || !selecetdVendor ) {
      setRequiredAlert('Please fill all the required fields.');
     return; // Exit function if any required field is empty
 }
    
    const formData = new FormData();
  
    formData.append('product_group_name', productGroupName);
    formData.append('product_category_id', selectedProductCategory);
    formData.append('product_sub_category_id', selectedProductSubCategory);
    formData.append('vendor_id', selecetdVendor);
   // formData.append('product_group_price', price);
    formData.append('UOM', unitOfMeasurement);
  
    // Append each attribute value ID
    attributeIds.forEach((attribute) => {
      formData.append('attribute_value_ids', [attribute.attribute_value_id]);
    });
  
    // Append image if available
    if (image) {
      formData.append('image', image);
    }
  
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${backendApiUrl}/Admin/product-groups/create-productGroup`, {
        method: 'POST',
        headers:{
         // 'Content-Type': 'application/json',
          'x-token':token
        },
        body: formData,
      });
  
      if (response.ok) {
        setSuccessMessage('New Product group has been saved successfully');
        // Handle success, e.g., show a success messag
      }else{
        console.error('Failed to update data submit data')
        // throw new Error( 'Failed to update data submit data');
      }
      
    } catch (error) {
      // Handle errors, e.g., show an error message
      clearStorageAndRedirectToHome()
      console.error('Error:', error.message);
    }
  };

  const handleSuccessPopupClose = async () => {
    setSuccessMessage(false);
    navigate('/bb/admin/AdminHome/ViewProductGroups'); 
  };
  
  const handleCancel = () => {
    navigate('/bb/admin/AdminHome');
  }

  const handlecloseAlert = () => {
    setRequiredAlert(false)
  }
  
  return (
    <>
      <div className="overflow-y-scroll no-scrollbar w-full  flex flex-col items-center ">
      {successMessage && (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className=" bg-white p-4 rounded-md">
          <div>
          <p className="text-md font-semibold mb-2">{successMessage}</p>
</div>
<div className='flex justify-center'>
          <button
            className="mt-2 px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600"
            onClick={handleSuccessPopupClose}
          >
            Ok
          </button>
          </div>
        </div>
      </div>
    )}
    
<div>
<div>
        <div className="flex flex-col items-center justify-center p-10">
            
      <div className="border border-1 border-gray-500 flex flex-col flex justify-center  items-center  p-10  ">
      {/* <div className='flex text-lg font-semibold mb-4 justfy-center items-center '>
             <p>Create Product Group</p> 
          </div> */}

<div className="flex flex-col w-1/2 ">
                
                <p className='text-md font-medium text-gray-700 mb-3'>Product Category</p>
              
              
                <select
                  value={selectedProductCategory}
                  onChange={handleProductCategoryDropdown}
                  className="border border-gray-300 focus:outline-none hover:border-[#EB001B] rounded-sm p-1 cursor-pointer"
                  //style={{ width: '200px' }}
                >
                  <option value="" disabled>Select Category</option>
                  {productCategories.map((productCategory) => (
                    <option key={productCategory?._id} value={productCategory?._id}>
                      {productCategory?.product_category_name}
                    </option>
                  ))}
                </select>
              
            </div>

            <div className="flex flex-row space-x-4 w-full mt-2">
              <div className="flex flex-col w-1/2">
                
                  <p className='text-md font-medium text-gray-700 mb-3'>Product Subcategory</p>
                
                
                  <select
                    value={selectedProductSubCategory}
                    onChange={handleProductSubCategoryDropdown}
                    className="border border-gray-300 focus:outline-none hover:border-[#EB001B] rounded-sm p-1 cursor-pointer"
                    //style={{ width: '200px' }}
                  >
                    <option value="" disabled>Select SubCategory</option>
                    {productSubCategories.map((SubCategory) => (
                      <option key={SubCategory?._id} value={SubCategory?._id}>
                        {SubCategory?.product_sub_category_name}
                      </option>
                    ))}
                  </select>
                

              </div>
              <div className="flex flex-col w-1/2">
        <p className="text-md font-medium text-gray-700 mb-3">Vendor</p>
        <select
  value={selecetdVendor} // Use VendorId instead of selectedItems[0]
  onChange={handleVendorDropdown}
  className="border border-gray-300 hover:border-[#EB001B] focus:outline-none rounded-sm p-1 cursor-pointer"
 // style={{ width: '200px' }}
>
  <option value="" disabled>Select Vendor</option>
  {vendors.map((vendor) => (
    <option key={vendor?._id} value={vendor?._id}>
      {vendor?.name}
    </option>
  ))}
</select>


      </div>
            </div>
            <div className="flex flex-row space-x-4 w-full mb-4">
            <div className="flex flex-col w-1/2 mt-12">
    {/* Display image */}
    {productGroupFile && <img src={productGroupFile} alt="Uploaded Image" className="mb-2 max-w-full w-[150px] h-[150px] max-h-32" /* style={{ width: '150px', height: '150px' }} */ />}

    {/* top */}
    <label htmlFor="productImage" className="border flex justify-center items-center text-md font-medium text-gray-700 border-gray-300 focus:outline-none hover:border-[#EB001B] rounded-sm py-1 px-1 cursor-pointer">
        <input type="file" id="productImage" accept="image/*" onChange={(e) => handleFileChange(e)} className="hidden" />
        <FontAwesomeIcon icon={faCamera} className=" text-gray-600 mr-1 mt-1" />
         Upload Image
    </label>
</div>

    <div className="flex flex-col w-1/2 mt-3">
   
       
        <label className="text-md font-medium text-gray-700 mb-3">Product Group Name</label>
        
        <div className=' flex'>
          <input
          placeholder='Enter Product Group Name'
          value={productGroupName}
          type="text"
          onChange={(event) => setProductGroupName(event.target.value)}
          className=" border border-gray-300 w-full rounded-sm py-1 px-2 focus:outline-none hover:border-[#EB001B] "
         // style={{ width: '150px' }}
           // Disable input if no product group selected
        />

      </div>

    </div>
     </div>
    { AttributesData  && (
     <ApplyAttributes
          attributesdata={AttributesData}
            setAllAttributeIds={setAttributeIds}
            attributeValueIds={Attribute_Value_Ids}
             // Pass Filter_Value_Ids as a prop
          /> 
    )}
<div className='flex flex-row space-x-4 w-full mt-4'>
{/* <div className="flex flex-col w-1/2 ">
        <label className="text-md font-medium text-gray-700 mb-3">Price</label>
        <input
          type="text"
          value={price}
          onChange={(e) => setPrice(e.target.value)} // Allow only numeric input
          className="border border-gray-300 hover:border-[#EB001B] rounded-sm p-1 focus:outline-none"
        />
      </div> */}
      <div className="flex flex-col w-1/2">
      <label className="text-md font-medium text-gray-700 mb-3 ">Unit Of Measurement</label>
      <select
        value={unitOfMeasurement}
        onChange={handleUnitOfMeasurementChange}
        className="border border-gray-300 hover:border-[#EB001B] rounded-sm p-1 focus:outline-none"
      >
        <option value="">Select UOM</option>
        {unitOfMeasurementOptions.map((uoms) => ( // Use unitOfMeasurementOptions instead of unitOfMeasurement
          <option key={uoms._id} value={uoms.UOM_type}>
            {uoms.UOM_type}
          </option>
        ))}
      </select>
    </div>
      </div>

      <div className='mt-6  '>
      <button className='mr-4 px-6 py-1 border border-[#EB001B] hover:bg-[#EB001B] hover:text-white rounded-sm text-black'
                onClick={handleCancel}>
          Cancel</button>
        <button className='ml-4 px-6 py-1 border border-[#EB001B] hover:bg-[#EB001B] hover:text-white rounded-sm text-black' onClick={handleSubmit}>
  Submit
</button>
</div>
          </div>
        </div>
        </div>
        </div>
      {RequiredAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{RequiredAlert}</p>
      <div className="flex">
        <button className="text-white bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handlecloseAlert}>OK</button>
      </div>
    </div>
  </div>
)}
      </div>
    </>
  );
};

export default CreateProductGroup;
