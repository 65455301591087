import React,{ useState, useEffect } from 'react';
import { Routes, Route, RouterProvider ,useNavigate, useLocation } from 'react-router-dom';

import BBMainPage from "./bbMainPage";
import Offers from './Offers';
import AllProducts from './AllProducts'
import MySelections from './MySelections'
import ConsumerLogin from './ConsumerLogin';
import OTPpage from './OTPpage';
import AddUserDetails from './AddUserDetails';
import QuotationProducts from './QuotationProducts'
import Summary from './Summarypage';
import MyQuotations from './MyQuotations';
import SavedQuotation from './SavedQuotationpage';
import SummaryPage2 from './summaryPage_2'
import ConfirmOrder from './ConfirmOrder';
import ViewCustomerOrders from './ViewCustomerOrders';
import OrderDetails from './orderDetails';
import ConfirmOrder2 from './ConfirmOrder2';
import OrderDetails2 from './OrderDetails2';
import Profile from './Profile';
import NotApproved from './NotApproved';
import CreateProject from './CreateProject';
import ErrorPage from './ErrorPage';
import AddProject from './AddProject'
import MyProjects from './MyProjects';
import ViewProject from './ViewProject';
import AddProject2 from './AddProject2';
import MaterialRequirement from './MaterialRequirement';
import './App.css'
const App = () => {
    const [appId, setappId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const BrickBucket = () => {
        if (location.pathname.startsWith('/bb/user')) {
          const storedLoginData = JSON.parse(localStorage.getItem('userData'));
          if (storedLoginData) {
            const appId = storedLoginData.appId;
          setappId(appId);
          setLoading(false);
          } else {
            // Handle case where user data is not available
            // For example, navigate to login page
            if (location.pathname.startsWith('/bb/user')
        ) {
            navigate('/bb/user');
            setLoading(false);
        }

          }
        }
      };

      BrickBucket(); // Call BrickBucket function directly here
    }, [navigate, location.pathname]); // Add navigate and location.pathname as dependencies

    if (loading) {
      return <div>Loading...</div>;
    }

    const AccessDenied = () => {
        return (
          <div
            className="fixed inset-0 bg-gray-500/50 flex justify-center items-center"
            style={{ backdropFilter: 'blur(100px)' }}
            role="dialog"
            aria-modal="true"
          >
    <div className="bg-white rounded shadow-md p-8 max-w-md">
      <h2 className="text-lg font-bold text-center">Access Denied</h2>
      <p className="text-gray-600 text-center">You do not have permission to access this page.</p>
      <div className="flex justify-center mt-2">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded"
          onClick={() => {
            sessionStorage.clear();
            localStorage.clear();
            navigate('/bb/user');
          }}
        >
          Go Back
        </button>
      </div>
    </div>
          </div>
        );
      };

    return (
        <Routes>
       <Route path='/bb/user' element={<ConsumerLogin />} />
       <Route path='/bb/user/OTPpage' element={<OTPpage />} />
       <Route path='/bb/user/UserDetails' element={<AddUserDetails/>}/>
       <Route path='/bb/user/NotApproved' element={<NotApproved/>}/>
       <Route path='/bb/user/createproject' element={<CreateProject/>}/>
       <Route path='/bb/user/AddProject' element={<AddProject/>}/>
       <Route path='/bb/user/ErrorPage' element={<ErrorPage/>}/>
       <Route path="*" element={<AccessDenied />} />

            <Route path="/bb/user" element={<BBMainPage/>}>
            {appId === 'BBU' && (
          <>
                <Route path="offers" element={<Offers />} />
                <Route path='Profile' element={<Profile/>}/>
                <Route path="products" element={<AllProducts/>} />
                <Route path="Quotationproducts" element={<QuotationProducts/>} />
                <Route path="MySelections" element={<MySelections/>} />
                <Route path="MyProjects" element={<MyProjects/>}/>
                <Route path="Summarypage" element={<Summary/>} />
                <Route path='myQuotations' element={<MyQuotations/>}/>
                <Route path='SavedQuotation' element={<SavedQuotation/>}/>
                <Route path='summaryPage2' element= {<SummaryPage2/>}/>
                <Route path='OrderConfirmation' element= {<ConfirmOrder/>}/>
                <Route path='OrderConfirmation2' element={<ConfirmOrder2/>} />
                <Route path='viewOrders' element= {<ViewCustomerOrders/>}/>
                <Route path='orderDetails' element={<OrderDetails/>}/>
                <Route path='OrderDetails2' element={<OrderDetails2/>} />
                <Route path='ViewProject' element={<ViewProject/>}/>
                <Route path='AddProject2' element={<AddProject2/>}/>
                <Route path='MaterialRequireMent' element = {<MaterialRequirement/>}/>
                </>
        )}
            </Route>
        </Routes>
    );
};

export default App;