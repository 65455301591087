import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus, faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function AddNewFilter({ AttributesData }) {
  const [attributes, setAttributes] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState({ show: false, attributeIndex: null, valueIndex: null, message: '' });
  const [deleteAttributeConfirmation, setDeleteAttributesConfirmation] = useState({ show: false, attributeIndex: null, valueIndex: null, message: '' });
  const [editMode, setEditMode] = useState({});
  const [selectedAttributeValue, setSelectedAttributesValue] = useState('');
  const [attributeCannotdelete, setAttributesCannotdelete] = useState(false);
  const [enteredValues, setEnteredValues] = useState('');
  const [enteredValuesArray, setEnteredValuesArray] = useState([]);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [selectedAttributesIndex, setSelectedAttributesIndex] = useState(null); // Added this line
  const [showUpdateWarning, setShowUpdateWarning] = useState(false);
  const [AttributeValueIndex,setAttributeValueIndex]=useState('')
  const navigate= useNavigate();
  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
        navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })  

  useEffect(() => {
    setAttributes(AttributesData);
    setEnteredValuesArray(AttributesData.map(() => ''));
  }, [AttributesData]);

  /* const handleSelectFilterValue = (value, filterIndex) => {
    setSelectedFilterValue(value);
    setEnteredValuesArray((prev) => {
      const newArray = [...prev];
      newArray[filterIndex] = value; // Set entered values to the selected filter value
      return newArray;
    });
    setIsUpdateMode(true); // Enable update mode
  }; */

  const handleSelectAttributeValue = (value, attributeIndex, attributeValueIndex) => {
    setAttributeValueIndex(attributeValueIndex)
    const selectedAttribute = attributes[attributeIndex];
    const selectedAttributeValue = selectedAttribute.attribute_values.find((val) => val.attribute_value === value);

    if (selectedAttributeValue && selectedAttributeValue.attribute_value_id) {
      setSelectedAttributesValue(value);
      setEnteredValuesArray((prev) => {
        const newArray = [...prev];
        newArray[attributeIndex] = value;
        return newArray;
      });
      setIsUpdateMode(true);
      setSelectedAttributesIndex(attributeIndex);
    } else {
      // Filter value doesn't have an id, show the warning pop-up
      setShowUpdateWarning(true);
    }
  };

  const handleHideUpdateWarning = () => {
    setShowUpdateWarning(false);
  };
  

  const handleAttributeNameEdit = (index) => {
    setEditMode((prev) => ({ ...prev, [index]: true }));
  };

  const handleAttributeNameSave = async (index) => {
    try {
      
      const updatedAttribute = attributes[index];
      const updateAttributerUrl = `${backendApiUrl}/Admin/product-group-attributes/update-attribute`;
      const token = sessionStorage.getItem('token');
      const response = await fetch(updateAttributerUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-token' :token,
        },
        body: JSON.stringify({
          _id: updatedAttribute.attribute_name_id,
          attribute_name: updatedAttribute.attribute_name,
        }),
      });
  
      if (response.ok) {
        // Update the local state after a successful update in the backend
        setAttributes((prevAttributes) =>
          prevAttributes.map((attribute, i) => (i === index ? { ...attribute, attribute_name: updatedAttribute.attribute_name } : attribute))
        );
  
        // Exit edit mode
        setEditMode((prev) => ({ ...prev, [index]: false }));
      } else {

      }
    } catch (error) {

    }
  };
  
  

 

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const capitalizedInput = value.charAt(0).toUpperCase() + value.slice(1);
    const newAttributes = [...attributes];
    newAttributes[index][name] = capitalizedInput;
    setAttributes(newAttributes);
  };

  const handleAddAttributeValues = (index) => {
    const newAttributes = [...attributes];
    const newValues = enteredValuesArray[index]
      .split(',')
      .map((value) => ({ attribute_value: value.trim() }));
    newAttributes[index].attribute_values = [...newAttributes[index].attribute_values, ...newValues];
    setAttributes(newAttributes);
    setEnteredValuesArray((prev) => {
      const newArray = [...prev];
      newArray[index] = ''; // Clear entered values for the current filter
      return newArray;
    });
    localStorage.setItem('product_category_attributes', JSON.stringify(newAttributes));
    setEnteredValuesArray((prev) => {
      const newArray = [...prev];
      newArray[index] = ''; // Clear entered values for the current filter
      return newArray;
    });
    setIsUpdateMode(false); // Disable update mode after saving
    setSelectedAttributesIndex(null); // Added this line to reset selected filter index
  };

  
  
  const handleUpdateAttributeValue = async (attributeIndex) => {
    try {
     const AllAttributes=[...attributes]
     AllAttributes[attributeIndex].attribute_values[AttributeValueIndex].attribute_value=enteredValuesArray[attributeIndex];
    
      localStorage.setItem('product_category_attributes', JSON.stringify(AllAttributes));
      const attribute = AllAttributes[attributeIndex];
      const attributeValueToUpdate = attribute.attribute_values[AttributeValueIndex]/* find((value) => value.attribute_value === selectedAttributeValue) */;
      const attributeNameId = attribute.attribute_name_id;
      
      // Check if the filter value has an ID (assuming it's stored in filter_value_id)
      if (attributeValueToUpdate && attributeValueToUpdate.attribute_value_id) {
        const updateAttributeValueUrl = `${backendApiUrl}/Admin/product-group-attributes/attribute-value-update`;
  
        const updatedAttributeValue = {
          attribute_name_id: attributeNameId,
          _id: attributeValueToUpdate.attribute_value_id,
          attribute_value: enteredValuesArray[attributeIndex], // Use the updated value
        };
  
        const token = sessionStorage.getItem('token');
        const response = await fetch(updateAttributeValueUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
             'x-token':token,
          },
          body: JSON.stringify(updatedAttributeValue),
        });
  
        if (response.ok) {
          // Update the filter value in the local state
          /* const updatedAttributes = [...attributes];
          updatedAttributes[attributeIndex].attribute_values = updatedAttributes[attributeIndex].attribute_values.map((value) =>
            value.attribute_value === selectedAttributeValue ? { ...value, attribute_value: enteredValuesArray[attributeIndex] } : value
          ); */
  
          setAttributes(AllAttributes);
  
          // Clear entered values and disable update mode
          setEnteredValuesArray((prev) => {
            const newArray = [...prev];
            newArray[attributeIndex] = '';
            return newArray;
          });
          setIsUpdateMode(false);
          setSelectedAttributesIndex(null); // Added this line to reset selected filter index

        } else {

        }
      } else {

      }
    } catch (error) {

    }
  };
  

 

  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;

  const handleCancelDelete = () => {
    setDeleteConfirmation({ show: false, attributeIndex: null, valueIndex: null, message: '' });
    setDeleteAttributesConfirmation({ show: false, attributeIndex: null, valueIndex: null, message: '' });
  };

 
  const handleAttributedeleteAlert = () => {
    setAttributesCannotdelete(false);
  };

  const handleDeleteValue = (attributeIndex, valueIndex) => {
    setDeleteConfirmation({ show: true, attributeIndex, valueIndex, message: 'Are you sure you want to delete the attribute?' });
  };

  const handleConfirmDelete = () => {
    const { attributeIndex, valueIndex } = deleteConfirmation;
    const newAttributes = [...attributes];
    const attribute_value = newAttributes[attributeIndex].attribute_values[valueIndex];
    const attribute_name_id = newAttributes[attributeIndex]?.attribute_name_id ?? null;

    if (attribute_value) {
      if (attribute_value.attribute_value_delete !== undefined && attribute_value.attribute_value_delete === false) {
        setAttributesCannotdelete('Attribute has already been applied to products');
      } else {
        DeleteAttributeValue(attribute_value, attribute_name_id);
        newAttributes[attributeIndex].attribute_values = newAttributes[attributeIndex].attribute_values.filter((_, i) => i !== valueIndex);

        if (newAttributes[attributeIndex].attribute_values.length) {
          localStorage.setItem('product_category_attributes', JSON.stringify(newAttributes));
        } else {
          newAttributes.splice(attributeIndex, 1);
          localStorage.setItem('product_category_attributes', JSON.stringify(newAttributes));
        }

        setAttributes(newAttributes);
      }
    }

    setDeleteConfirmation({ show: false, attributeIndex: null, valueIndex: null, message: '' });
  };

  
  const DeleteAttributeValue = async (Attribute_value, Attribute_name_id) => {
    const deleteAttributeVlueUrl = backendApiUrl + '/Admin/product-group-attributes/attributeAndAttributeValueDelete';

    if (Attribute_value.attribute_value_id) {
      const NameAndValueIds = {
        attribute_name_id: Attribute_name_id,
        attribute_value_id: Attribute_value.attribute_value_id,
      };

      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(deleteAttributeVlueUrl, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-token':token
          },
          body: JSON.stringify(NameAndValueIds),
        });

    if (response.ok) {

      
    }
  } catch (error) {

    
  }
  
    }
  };

 

  const handleDeleteAttribute = async (attributeIndex) => {
    const attribute = attributes[attributeIndex];
    
    const attributeValuesApplied = attribute.attribute_values.some((value) => value.attribute_value_delete !== undefined && value.attribute_value_delete === false);
  
    if (attributeValuesApplied) {
      // Filter values are applied to products, show a message or alert
      setAttributesCannotdelete('Attribute values are applied to products. Cannot delete attribute.');
    } else {
      // No filter values applied, proceed with the deletion of the filter
      setDeleteAttributesConfirmation({ show: true, attributeIndex, message: 'Are you sure you want to delete the attribute?' });
    }
  };
  

  const handleConfirmDeleteAttribute = async () => {
    const attributeIndex = deleteAttributeConfirmation.attributeIndex;
    const attribute = attributes[attributeIndex]; // Assuming you have a filter_id property for each filter
  const AttributeNameId = attribute.attribute_name_id
  
    if (AttributeNameId) {
      try {
        
        const deleteAttributeUrl = `${backendApiUrl}/attribute/deleteattribute?attribute_name_id=${AttributeNameId}`;
        const token = sessionStorage.getItem('token');
        const response = await fetch(deleteAttributeUrl, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
             'x-token' :token,
          },
        });

        if (response.ok) {

          const newAttributes = [...attributes];
          newAttributes.splice(attributeIndex, 1);
          setAttributes(newAttributes);
          localStorage.setItem('product_category_attributes', JSON.stringify(newAttributes));
          setDeleteAttributesConfirmation({ show: false, attributeIndex: null, valueIndex: null, message: '' });
        } else {

        }
      } catch (error) {

      
      }
    }else{
      const UpdatedAttributes = [...attributes];
      UpdatedAttributes.splice(attributeIndex, 1);
      setAttributes(UpdatedAttributes)
      localStorage.setItem('product_category_attributes', JSON.stringify(UpdatedAttributes))
    }

    // Close the delete confirmation modal
    setDeleteAttributesConfirmation({ show: false, attributeIndex: null, valueIndex: null, message: '' });
  };


  return (


    <div className='flex flex-col  items-center justify-center '>
     {deleteConfirmation.show && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{deleteConfirmation.message}</p>
      <div className="flex">
        <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4 mr-2" onClick={handleConfirmDelete}>Yes</button>
        <button className="items-center justify-center text-white bg-red-600 hover:bg-red-700 py-1 px-2 rounded-md mt-4" onClick={handleCancelDelete}>No</button>
      </div>
    </div>
  </div>
)}

{showUpdateWarning && (
        <div className="fixed z-30 inset-0 flex items-center justify-center">
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
            <p className="text-lg font-semibold mb-2">
              Newly added attribute values cannot be selected to update. Please submit and try again to update.
            </p>
            <button
              className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
              onClick={handleHideUpdateWarning}
            >
              OK
            </button>
          </div>
        </div>
      )}

     {deleteAttributeConfirmation.show && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{deleteAttributeConfirmation.message}</p>
      <div className="flex">
        <button className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4 mr-2" onClick={handleConfirmDeleteAttribute}>Yes</button>
        <button className="items-center justify-center text-white bg-red-600 hover:bg-red-700 py-1 px-2 rounded-md mt-4" onClick={handleCancelDelete}>No</button>
      </div>
    </div>
  </div>
)}


{attributeCannotdelete && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
      <p className="text-lg font-semibold mb-2">{attributeCannotdelete}</p>
      <button  className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleAttributedeleteAlert}>OK</button>
    </div>
  </div>
)}
<div className=' flex flex-col space-y-2 items-center '>
        {attributes.map((attribute, index) => (
          <div key={index} className=''>
            <div className='flex flex-col w-full justify-center items-center border border-1 border-[#000000] border-opacity-50 p-2 rounded-sm'>
              <div className='flex flex-row space-x-2 justify-center px-2'>
                <h1 className='text-md font-semibold'>Attribute</h1>
                <input
  type='text'
  name='attribute_name'
  value={attribute.attribute_name}
  onChange={(e) => handleInputChange(index, e)}
  placeholder='Attribute Name'
  className={`w-1/2 border border-gray-300 rounded-sm focus:outline-none ${
    attribute.attribute_name_id ? (editMode[index] ? '' : 'bg-gray-200') : '' // Conditionally apply background color
  }`}
  readOnly={attribute.attribute_name_id ? !editMode[index] : false} // Conditionally set readOnly
/>

{attribute.attribute_name_id && (
  <>
    {editMode[index] && (
      <button className='ml-2 text-sm text-blue-500 hover:text-[#EB001B]' onClick={() => handleAttributeNameSave(index)}>
        Save
      </button>
    )}
    {!editMode[index] && (
      <button className='ml-2 text-sm text-blue-500 hover:text-[#EB001B]' onClick={() => handleAttributeNameEdit(index)}>
        Edit
      </button>
    )}
  </>
)}
          <h1 className='text-md font-semibold'>Value</h1>
                <input
                  type='text'
                  name='enteredValues'
                  value={enteredValuesArray[index]}
                  onChange={(e) => {
                    const newValues = [...enteredValuesArray];
                    newValues[index] = e.target.value;
                    setEnteredValuesArray(newValues);
                  }}
                  placeholder='Attribute Value'
                  className='w-1/2 border border-gray-300 rounded-sm focus:outline-none'
                />
<div className='flex flex-row'>
{selectedAttributesIndex === index && (
  <button className='ml-2 text-sm text-[#000000] hover:text-[#EB001B]' onClick={() => handleUpdateAttributeValue(index)}>
    Update
  </button>
)}

{selectedAttributesIndex !== index && (
  <button className='ml-2 text-sm text-blue-500  hover:text-[#EB001B]' onClick={() => handleAddAttributeValues(index)}>
    Save
  </button>
)}
          <button className='ml-2 text-sm text-red-500 hover:text-black' onClick={() => handleDeleteAttribute(index)}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>

      <div className='flex flex-col space-y-1'>
                <h1 className='flex justify-center'>{attribute.attribute_name}</h1>
                {attribute.attribute_values.length > 0 && (
                  <table className='w-full'>
                    <tbody>
                      {Array.from({ length: Math.ceil(attribute.attribute_values.length / 3) }, (_, rowIndex) => (
                        <tr key={rowIndex}>
                          {Array.from({ length: 3 }, (_, colIndex) => {
                            const valueIndex = rowIndex * 3 + colIndex;
                            if (valueIndex < attribute.attribute_values.length) {
                              return (
                                <td key={valueIndex}>
                                  <div
  className={`flex flex-row space-x-3 p-1 hover:border-[#EB001B] border border-1 border-[#EB001B] border-opacity-30 flex justify-center items-center rounded-md ${attribute.attribute_values[valueIndex].attribute_value_delete !== undefined && attribute.attribute_values[valueIndex].attribute_value_delete === false ? 'text-gray-500' : 'text-gray'}`}
  
>
  <span className='cursor-pointer'
  onClick={() => handleSelectAttributeValue(attribute.attribute_values[valueIndex].attribute_value, index, valueIndex)}>{attribute.attribute_values[valueIndex].attribute_value}</span>
  <button className='ml-2 text-red-600 hover:text-[#EB001B]' onClick={() => handleDeleteValue(index, valueIndex, attribute)}>
    <FontAwesomeIcon className={`w-2 h-2 flex justify-center ${attribute.attribute_values[valueIndex].attribute_value_delete !== undefined && attribute.attribute_values[valueIndex].attribute_value_delete === false ? 'text-gray-500' : 'text-red-800'}`} icon={faX} />
  </button>
</div>


                                </td>
                              );
                            } else {
                              return <td key={colIndex}></td>;
                            }
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className='mt-4 flex justify-center'>
        <button
          className='border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white px-2 py-0'
          onClick={() => setAttributes([...attributes, { attribute_name: '', enteredValues: '', attribute_values: [] }])}
        >
          Add Attribute
        </button>
      </div>
    </div>
  );
}

export default AddNewFilter;
