import React, { useEffect, useState } from 'react';
import { json, useNavigate} from 'react-router-dom';
import ContactUs from './ContactUs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


const Offers = () => {
     const [offers, setOffers] = useState([]);
    const [showPopup, setShowPopup] = useState(false); 
    const [projects, setProjects] = useState([]);
    const [items, setItems] = useState([]); // Define items state
    const [productSubCategory, setproductSubCategory] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState([]);
    const [selectedProductCategoryId, setSelectedProductCategoryId] = useState();
    const [displayedProjects, setDisplayedProjects] = useState([]);
    const [showContactUs, setShowContactUs] = useState(false);
    const [showspecificationPopup, setspecificationShowPopup] = useState(false);
    const [specificationData, setSpecificationData] = useState([]); // State for specifications data
    const [specificationValueData, setSpecificationValueData] = useState([]);
    const [attributeData, setAttributeData] = useState([]);
    const [attributeValueData, setAttributeValueData] = useState([]);
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
    const navigate = useNavigate();
    const [customerId, setCustomerId] = useState('');
    const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
    const backendApiUrl2 = process.env.REACT_APP_FORE_APILINK;
    const [reports,setReports] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [showQuotePopup, setShowQuotePopup] = useState(false);
    const [iterationCount, setIterationCount] = useState(0);
    const [loading, setLoading] = useState(true); // New loading state variable
    const [productsloading, setProductsLoading] = useState(true);
   

    function clearStorageAndRedirectToHome() {
      navigate("/bb/user/ErrorPage");
    }

    // Function to calculate iteration count based on screen size
    const calculateIterationCount = () => {
      if (window.innerWidth < 768) { // small screens
        setIterationCount(7);
      } else if (window.innerWidth < 1024) { // medium screens
        setIterationCount(9);
      } else { // large to extra large screens
        setIterationCount(17);
      }
    };
  
    // Call calculateIterationCount initially and add event listener to handle screen size changes
    useEffect(() => {
      calculateIterationCount();
      window.addEventListener('resize', calculateIterationCount);
      return () => {
        window.removeEventListener('resize', calculateIterationCount);
      };
    }, []);

    useEffect(() => {
        // Retrieve mobile number from local storage
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData && userData.mobile_number) {
            setCustomerId(userData._id);
        }
    }, []);

   /*  useEffect(() => {
                    // Clear local storage of selected items
                    localStorage.removeItem('selectedProductGroupOffer');
    }, []); */


    useEffect(() => {
        const fetchProjects = async () => {
           
            try {
                const token = sessionStorage.getItem('token');
                const GetProjects = `${backendApiUrl}/customer/get-projects-by-customer?customer_id=${customerId}`;
                const response = await fetch(GetProjects, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        
                        'x-token':token
                    },
                });

                if (response.ok) {
                    const projectsData = await response.json();
                    const reversedData = projectsData.customerProjects.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                    const sortedProjects = reversedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                
                    setProjects(sortedProjects);
                    setDisplayedProjects(sortedProjects);
                    
                    // Extracting project IDs into an array
            const projectIds = sortedProjects.map(project => project._id);
           
            fetchProjectsReports(projectIds)
            setLoading(false);
                } else {
                    console.error('Failed to fetch projects:', response.statusText);
                }
            } catch (error) {
                console.error('Failed to fetch projects:', error);
                clearStorageAndRedirectToHome()
            } /* finally {
               setLoading(false);
            } */
        };

        if (customerId) {
            fetchProjects();
        }

       
    }, [backendApiUrl, customerId]);

   

    const fetchProjectsReports = async (projectIds) => {
        try {
            const token = sessionStorage.getItem('token');
            const projectIdsQueryParam = projectIds.join(',');
           
            const GetProjects = `${backendApiUrl2}/computed_reports/generate-consolidated-project-report?projectIds=${projectIdsQueryParam}`;

            const response = await fetch(GetProjects, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    
                    'x-token':token
                },
            });
    
            if (response.ok) {
                const data = await response.json();
        
                setReports(data);
            } else {
                console.error('Failed to fetch projects:', response.statusText);
                
            }
        } catch (error) {
            console.error('Failed to fetch projects:', error);
            clearStorageAndRedirectToHome()
        }
    };

 // Move useEffect outside of JSX
 // Inside the useEffect for fetching product categories
useEffect(() => {
    const fetchProductCategories = async () => {
        try {
            // Check if there are projects available
            const token = sessionStorage.getItem('token');
                const response = await fetch(backendApiUrl + "/Admin/get-all-productCategorys",{
                    method: 'GET',
                    headers: {
                      
                      'x-token':token
                    },
                    });
                if (!response.ok) {
                    throw new Error('Failed to fetch product categories');
                }
                const data = await response.json();
                setItems(data);   
                setProductsLoading(false);     
        }
         catch (error) {
            // Handle error
            clearStorageAndRedirectToHome()
        } /* finally {
            setProductsLoading(false);
        } */
    };

    fetchProductCategories();
}, [backendApiUrl]); // Add projects as a dependency



   
useEffect(() => {
  const fetchProductSubCategories = async () => {
      try {
        const token = sessionStorage.getItem('token');
          // Check if there are projects available
          if (projects.length > 0) {
              const response = await fetch(backendApiUrl + "/Admin/product-sub-category/get-all-productSubCategorys",{
                method: 'GET',
                headers: {
                  
                  'x-token':token
                },
                });
              if (!response.ok) {
                  throw new Error('Failed to fetch product sub categories');
              }
              const data = await response.json();
              setproductSubCategory(data);
              setProductsLoading(false);
          } else {
              console.log('No projects available. Stopping fetching product sub categories.');
          }
      }
       catch (error) {
          // Handle error
          clearStorageAndRedirectToHome()
      } /* finally {
          setProductsLoading(false);
      } */
  };

  fetchProductSubCategories();
}, [backendApiUrl, projects]); // Add projects as a dependency


      useEffect(() => {
        const getAllOffers = async () => {
            const token = sessionStorage.getItem('token');
          const Getalloffers = process.env.REACT_APP_FORE_BRICKBUCKET + '/Admin/offer/get-active-offers';
          try {
            const response = await fetch(Getalloffers, {
              method: "GET",
              headers: {
                // Specify Content-Type as application/json
                // You can add other headers if needed
                'x-token':token
              },
            });
      
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
      
            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) {
              throw new Error("Invalid content type received, expected JSON");
            }
      
            const data = await response.json();
           const filterListOffers= data.filter(p=>p.OfferData.list_offer==="yes");
            setOffers(filterListOffers);
            setProductsLoading(false);
          } catch (error) {
            clearStorageAndRedirectToHome()
          }/*  finally {
            setProductsLoading(false);
        } */
        };
        
        getAllOffers();
      }, []); 
       
      

    const handleCategoryChange = (categoryId) => {
        setSelectedProductCategoryId(categoryId); // Update the selectedProductCategoryId state with the received category ID
    };
    
  
   const handleProjectChange = (event) => {
    const selectedProjectId = event.target.value; // Retrieve the selected project ID from the event
    if (selectedProjectId) {
        setSelectedProjectId(selectedProjectId); // Update the selectedProjectId state with the selected project ID
    } else {
        console.error('No project selected.');
    }
};



const handleGetQuotation = async () => {
    // Find the selected product category radio input
    const selectedCategoryInput = document.querySelector('input[name="productCategory"]:checked');

    if (selectedCategoryInput) {
        // Retrieve the value (product category ID) from the selected radio input
        const selectedProductCategoryId = selectedCategoryInput.value;

        // Find the selected project
        const selectedProject = projects.find(project => project._id === selectedProjectId);

        if (selectedProject) {
            // Retrieve the selected product category name using the selectedProductCategoryId
            const selectedProductCategory = items.find(item => item._id === selectedProductCategoryId);
            const selectedProductCategoryName = selectedProductCategory ? selectedProductCategory.product_category_name : '';

            // Log the selected projectId, productCategoryId, and productCategoryName
           

            // Navigate to the summary page after setting state
            navigate('/bb/user/summarypage', {
                state: {
                    selectedProjectId,
                    selectedProductCategoryId,
                    selectedProductCategoryName // Include the selected product category name
                }
            });
        } else {
            console.error('Invalid project selected.');
        }
    } else {
        console.error('No product category selected.');
    }
};


    
    // Function to handle opening the Contact Us modal
    const handleContact = () => {
        setShowContactUs(true);
    };
    
    const toggleContactUs = () => {
        setShowContactUs(!showContactUs);
    };

    const navigateToProductsPage = (filteredOffers, productGroup, product, offer) => {
      const CategoryName=offer.productCategory.product_category_name
      if(CategoryName==='Tiles'|| CategoryName==="UPVC Windows" || CategoryName==="Doors"){
        navigate('/bb/user/products', {
          state: {
              offer:offer
          }
      });
      }else{
      fetchQuotationByProductCategoryIdAndCustomerId(offer)
      }
       /*  navigate('/bb/user/loading', {
            state: {
                filteredOffers,
                selectedProductGroupId: productGroup ? productGroup._id : null,
                selectedProductId: product ? product._id : null,
                offer:offer
            }
        }); */
    };

    const fetchQuotationByProductCategoryIdAndCustomerId = async (offer) => {
      const user = JSON.parse(localStorage.getItem('userData'));
      const GetQuotationUrl = process.env.REACT_APP_FORE_BRICKBUCKET + `/customer/quotation/get-quotation-by-productCategoryAndCustomerIds?product_category_id=${offer.productCategory._id}&customer_id=${user._id}`; 
  
      try {
        const token = sessionStorage.getItem('token');

          const response = await fetch(GetQuotationUrl, {
              method: 'GET',
              headers: {
                  
                  'x-token':token
              }
          });
  
          if (response.ok) {
              const data = await response.json();
              navigate('/bb/user/Summarypage2', { state: {
                  selectedQuotationId:data.QuotationData._id,
                  selectedRFQId: data.QuotationData.RFQ_id,
                  selectedCustomerId: user._id,
                  selectedProductCategoryId: offer.productCategory._id,
                  selectedProductCategoryName: offer.productCategory.product_category_name,
                  offer:offer
                }}); // Replace with your target route
          } else {
              navigate('/bb/user/products', {
                  state: {
                      offer:offer
                  }
              });
          }
      } catch (error) {
          navigate(-1)
          console.error('Error fetching quotation:', error);
          clearStorageAndRedirectToHome()
      }
  };
    
   /*  const handleSpecificationClick = (offer) => {
        // Check if the offer is for a product group or a product
        if (offer.productGroup) {
            // Display only attributes for product groups
            setAttributeData(offer.productGroupSubData.attribuetData);
            setAttributeValueData(offer.productGroupSubData.attribuetValueData);
            setSpecificationData([]); // Clear specification data
            setSpecificationValueData([]); // Clear specification value data
        } else if (offer.productData && offer.productData.specificationData) {
            // Display only specifications for products
            setSpecificationData(offer.productData.specificationData);
            setSpecificationValueData(offer.productData.specificationValueData);
            setAttributeData([]); // Clear attribute data
            setAttributeValueData([]); // Clear attribute value data
        }
        setspecificationShowPopup(true);
    }; */

   


    const handleGetQuote = (productCategoryName, productCategoryId) => {
      setShowQuotePopup(true);
      setSelectedCategory(productCategoryName);
      setSelectedProductCategoryId(productCategoryId);
  
      // Define the area keys for each product category
      const areaKeys = {
          "UPVC Windows": "Total Windows Area",
          "Tiles": "Total Tiles Area",
          "Doors": "Total Doors Area"
      };
  
      const filteredProjects = projects.filter((project) => {
          const report = reports.find(
              (report) => report["Project Name"] === project.projectName
          );
  
          if (report) {
              // Get the appropriate area key based on the selected category
              const areaKey = areaKeys[productCategoryName];
  
              if (areaKey) {
                  // Check if the total area is not "Measurements not available" for the selected category
                  if (report[areaKey] !== "Measurements not available") {
                      return true; // Include project if area is available
                  }
              }
          }
  
          return false; // Exclude project if total area is "Measurements not available" or report not found
      });
  
      setSelectedProjects([]); // Reset selected projects
      setDisplayedProjects(filteredProjects); // Update displayed projects for the popup
  };
  

const handleProjectCheckboxChange = (projectId) => {
    const index = selectedProjects.indexOf(projectId);
    if (index === -1) {
        setSelectedProjects([...selectedProjects, projectId]);
    } else {
        const updatedProjects = [...selectedProjects];
        updatedProjects.splice(index, 1);
        setSelectedProjects(updatedProjects);
    }
};

const handleQuoteSubmission = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const ids = JSON.parse(localStorage.getItem('QuotationIds'));
    if (
        ids &&
        ids.customer_id === userData._id &&
        ids.product_category_id === selectedProductCategoryId &&
        ids.project_ids.length === selectedProjects.length &&
        ids.project_ids.every(id => selectedProjects.includes(id)) &&
        ids.project_ids.filter(id => selectedProjects.includes(id)).length === ids.project_ids.length
    ) {
        
        navigate('/bb/user/summarypage', {
            state: {
                selectedProjectId: selectedProjects,
                selectedProductCategoryId: selectedProductCategoryId,
                selectedProductCategoryName: selectedCategory,
            }
        });
    } else {
        const QuationIds = {
            customer_id: userData._id,
            product_category_id: selectedProductCategoryId,
            project_ids: selectedProjects
        };
        localStorage.setItem('QuotationIds', JSON.stringify(QuationIds));
        //localStorage.removeItem('selectedProductGroupOffer')
        fetchQuotationData(userData._id);
    }
};




const fetchQuotationData = async (customerId) => {
    
const formattedProjectIds = selectedProjects.join(',');
    try {
        const token = sessionStorage.getItem('token');

        const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/get-customer-quotation-data-by-CustomerAndprojectIdAndproductCategoryIds?customer_id=${customerId}&project_ids=${formattedProjectIds}&product_category_id=${selectedProductCategoryId}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                
                'x-token':token
            }
        });

        if (response.ok) {
            const data = await response.json();
            const selectedOffersQuotationData = await transformData(data.existingQuotation. quotationData);
            
            if (selectedOffersQuotationData) {
                localStorage.setItem('selectedProductGroupOffer', JSON.stringify(selectedOffersQuotationData));
                navigate('/bb/user/summarypage', {
                    state: {
                        selectedProjectId: selectedProjects,
                        selectedProductCategoryId: selectedProductCategoryId,
                        selectedProductCategoryName: selectedCategory,
                    }
                });
            }
        } else {
             localStorage.removeItem('selectedProductGroupOffer');
            console.error('Quotation data not found');
            navigate('/bb/user/summarypage', {
                state: {
                    selectedProjectId: selectedProjects,
                    selectedProductCategoryId: selectedProductCategoryId,
                    selectedProductCategoryName: selectedCategory,
                }
            }); 
        }
    } catch (error) {
        console.error('Error fetching quotation data:', error);
        clearStorageAndRedirectToHome()
    }
};



const transformData = (data) => {
    const transformedData = {};
  
    const transformObject = (obj) => {
        Object.entries(obj).forEach(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
                // If the value is an object (including nested objects), recursively transform it
                transformedData[key] = value.offerid;
                transformObject(value);
            }
        });
    };
  
    transformObject(data);
  
    return transformedData;
  };
  



const priceMapin = (offer) => {
    if (offer) {
        let allProducts = [];

        // Add product from productData (if available)
        if (offer.productData && offer.productData.product) {
            const productData = offer.productData.product;
            if (!allProducts.some(product => product._id === productData._id)) {
                allProducts.push(productData);
            }
        }

        // Add products from productGroupSubData (if available)
        if (
            offer.productGroupSubData &&
            offer.productGroupSubData.productsUnderGroupData &&
            offer.productGroupSubData.productsUnderGroupData.ProductData &&
            offer.productGroupSubData.productsUnderGroupData.ProductData.length > 0
        ) {
            const productGroups = offer.productGroupSubData.productsUnderGroupData.ProductData;
            productGroups.forEach(group => {
                group.forEach(product => {
                    if (!allProducts.some(existingProduct => existingProduct._id === product._id)) {
                        allProducts.push(product);
                    }
                });
            });
        }

        // Ensure allProducts array is not empty
        if (allProducts.length > 0) {
            // Convert product prices from string to numbers (floats)
            const productPrices = allProducts.map(product => parseFloat(product.product_price));
       
            // Find the minimum price
            const minPrice = Math.min(...productPrices);
        
            // Return the minimum price
            return calculatePrice(minPrice, offer);
        } else {
            console.log('No valid products available');
        }
    }

    return null; // Return null if offer is null or undefined, or if no valid products are available
};

const calculatePrice = (minPrice, offer) => {

    if (!minPrice || !offer || !offer.OfferData) {
      return 'Price Not Available';
    }
  
    const basePrice = minPrice || 0;
    const discount = offer.OfferData.discount || 0;
    const gst = offer.OfferData.gst || 0;
  
    let netPrice = parseFloat(basePrice);
  
    // Apply discount
    netPrice -= (netPrice * (discount / 100));
  
    // Apply GST
    netPrice *= (1 + (gst / 100));
  
    return `: ₹${netPrice.toFixed(2)}`;
  };

  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }


return (
    <div>
        <div className='flex justify-center items-center '>
        <div className="md:w-2/3 sm:w-full">
        {projects && projects.length > 0 && (
        <div className='border border-gray-300 flex justify-center mt-5'> 
  <table>
    <thead>
      <tr className='border-b border-gray-300'>
        <th className='text-gray-700'>Projects</th>
        {/* Display only specific product category names */}
        {items.filter(item => ["Tiles", "UPVC Windows", "Doors"].includes(item.product_category_name)).map((item) => (
          <th className='text-gray-700' key={item._id}>{item.product_category_name}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {/* Display loading spinner while waiting for data */}
      {loading ? (
        <tr>
          <td colSpan={items.filter(item => ["Tiles", "UPVC Windows", "Doors"].includes(item.product_category_name)).length + 1} className="flex justify-center py-4">
          <div className="animate-spin rounded-full h-15 w-15 border-t-2 border-b-2 border-gray-900">
            <img src={process.env.PUBLIC_URL + '/bb.png'} alt="Loading..." className="h-10 w-10" />
            </div>
          </td>
        </tr>
      ) : (
        /* Display projects and related data */
        projects.slice(0, 6).map((project, rowIndex) => (
          <tr key={rowIndex} className="border-b border-gray-300">
            <td className="px-4 text-gray-700 py-1">{project.projectName}</td> {/* Display project name in the first cell */}
            {items.map((item) => (
              <td key={`${project._id}-${item._id}`} className="px-4 text-gray-700 py-1">
                {/* Filter reports for the current project and item */}
                {reports && Array.isArray(reports) && reports.map((report) => {
                  if (report["Project Name"] === project.projectName) {
                    
                    return (
                      <div key={report["Project Name"]} className='pb-1 pt-1'>
                      {item.product_category_name === "Tiles" && (
                        <div className='flex justify-center text-gray-700'>
                          {report["Total Tiles Area"] === "Measurements not available"? (
                            <span className='text-xs'>{report["Total Tiles Area"]}</span>
                          ) : (
                            <span>{report["Total Tiles Area"]} sq.ft</span>
                          )}
                        </div>
                      )}
                      {item.product_category_name === "UPVC Windows" && (
                        <div className='flex justify-center text-gray-700'>
                          {report["Total Windows Area"] === "Measurements not available"? (
                            <span className='text-xs'>{report["Total Windows Area"]}</span>
                          ) : (
                            <span>{report["Total Windows Area"]} sq.ft</span>
                          )}
                        </div>
                      )}
                      {item.product_category_name === "Doors" && (
                        <div className='flex justify-center text-gray-700'>
                          {report["Total Doors Area"] === "Measurements not available"? (
                            <span className='text-xs'>{report["Total Doors Area"]}</span>
                          ) : (
                            <span>{report["Total Doors Area"]} sq.ft</span>
                          )}
                        </div>
                      )}
                    </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </td>
            ))}
          </tr>
        ))
      )}

      <tr /* className="border-b" */>
        <td className="px-4 py-2"></td> {/* Empty cell for alignment */}
        {/* Display button only for specific product categories */}
        {items.filter(item => ["Tiles", "UPVC Windows", "Doors"].includes(item.product_category_name)).map((item) => (
          <td key={item._id} className="px-4 py-2">
            <button 
              className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-gray-700"
              onClick={() => handleGetQuote(item.product_category_name, item._id)}
            >
              Get Quote
            </button>
          </td>
        ))}
      </tr>
    </tbody>
  </table>
</div>
)}
{/* Conditionally render the loading image */}


            {/* Display offer.brand above the offers */}
            {/* <div className='border border-1 border-gray-500 p-5 mt-5 relative'> */}
           {productsloading ? (
       <div className="fixed z-50 inset-0 flex items-center justify-center ">
       {/* Loader component */}
       <div className="animate-spin rounded-full h-18 w-18 border-t-2 border-b-2 border-gray-900">
       <img src={process.env.PUBLIC_URL + '/bb.png'} alt="Loading..." className="h-14 w-14" />
       </div>
     </div>
      ) : (
            
        items.map(item => {
          const relevantOffers = offers.filter(
            offer => offer.productCategory.product_category_name === item.product_category_name
          );
    if (relevantOffers.length === 0) return null;
    return (
        <div>
        <div key={item._id} className="border border-1 border-gray-500 p-5 mt-10 mb-8 relative">
           
            <p className="text-xl font-medium text-[#EB001B] mb-2 absolute -top-4 bg-white px-2">{item.product_category_name}</p>
            {/* Conditionally render offers based on product category */}
          
            <div className="mb-4">
           
                <h2 className="text-xl font-semibold">{offers.length > 0 ? offers[0].brand : ''}</h2>
            </div>
            {/* Iterate over offers */}
            {relevantOffers.map((offer, index) => {
                
                    if (item.product_category_name === offer.productCategory.product_category_name) {
                        return (
    <React.Fragment key={offer._id}>
       <div className="border border-1 border-gray-300 rounded-md overflow-hidden mb-4 " >
           
            <div className="flex flex-row">
                {/* Left Section (Image) */}
                <div className="ml-5 mt-5 mb-5 w-28 h-24 overflow-hidden flex items-center justify-center">
  {offer.productGroup && offer.productGroup.product_group_image ? (
    <img 
      src={ImageUrl + offer.productGroup.product_group_image} 
      alt="Image" 
      className="max-w-full max-h-full object-contain"
      />
  ) : (
    offer.productData.product && offer.productData.product.product_image ? (
      <img 
        src={ImageUrl + offer.productData.product.product_image} 
        alt="Image" 
        className="max-w-full max-h-full object-contain"
        />
    ) : (
      <p>No image available</p>
    )
  )}
</div>

                <div className="w-2/3 flex flex-col justify-center items-center">
                <h2 className="text-xl sm:text-[14px] md:text-[20px] text-gray-700 py-1 px-2 font-bold mb-2">{offer.vendor.name}</h2>
                <h2 className="text-lg sm:text-[14px] md:text-[20px] py-1 text-gray-700 text-center px-12 font-semibold mb-2 whitespace-wrap break-words">
    {/* Conditionally render product group name or product name */}
    {offer.productGroup ? offer.productGroup.product_group_name : offer.productData.product.product_name}
</h2>  
                      


{/* {offer.productData && offer.productData.specificationData && offer.productData.specificationData.length > 0 && (
    <div>
        {offer.productData.specificationData.length > 0 && (
            <button 
            className="text-sm flex justify-end underline text-blue-500 cursor-pointer"
            onClick={() => handleSpecificationClick(offer)}
        >
            ⓘ Specifications  
        </button>
        
        )}
       {showspecificationPopup && (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-25">
        <div className="bg-white rounded-lg p-8 max-w-md w-full relative">
            <button className="absolute top-2 right-2 text-gray-600" onClick={() => setspecificationShowPopup(false)}>
                <FontAwesomeIcon icon={faTimes} /> 
            </button>
            <p className="flex justify-center font-bold mb-4 text-lg">Specifications</p>
            <div>
    {attributeData && attributeData.length > 0 && (
        <table className="border-collapse w-full mb-4">
            
            <tbody>
                {attributeData.map((attribute, index) => (
                    <tr key={index}>
                        <td className="border border-gray-400 px-4 py-2 font-semibold">{attribute.attribute_name}</td>
                        <td className="border border-gray-400 px-4 py-2">
                            {attributeValueData.map((value) => {
                                if (value.attribute_name_id === attribute._id) {
                                    return value.attribute_value;
                                }
                                return null;
                            })}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )}

    {specificationData && specificationData.length > 0 && (
        <table className="border-collapse w-full">
            <thead>
            </thead>
            <tbody>
                {specificationData.map((spec, index) => (
                    <tr key={index}>
                        <td className="border border-gray-400 px-4 py-2 font-semibold">{spec.specification_name}</td>
                        <td className="border border-gray-400 px-4 py-2">
                            {specificationValueData.map((value) => {
                                if (value.specification_name_id === spec._id) {
                                    return value.specification_value;
                                }
                                return null;
                            })}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )}
</div>
        </div>
    </div>
)}
    </div>
)} */}
                </div>
                <div className=" flex w-1/3 flex-col justify-center items-center ">
                          {/* Display sub-category name */}
                          {offer.productSubCategory && (
                            <p className="text-lg sm:text-[14px] md:text-[20px] text-gray-700 font-semibold py-1 px-2 text-center whitespace-wrap break-words">
                             {offer.productSubCategory.product_sub_category_name}
                            </p>
                          )}
                        </div>
                {/* Right Section */}
                <div className=" w-1/3 flex flex-col justify-center items-center ">
                    {offer.OfferData.net_price===0?(
                       <div className='flex flex-col justify-center items-center'>
                        <div className='flex flex-row'>
                         <p className="text-green-600  font-semibold items-center whitespace-nowrap"> Discount upto</p>
                         <p className="ml-1 text-green-600  font-semibold items-center whitespace-nowrap">{offer.OfferData.discount}%</p>
                         </div>
                         <p className="text-green-600  font-semibold items-center whitespace-nowrap">+</p>
                         <p className="text-green-600  font-semibold items-center whitespace-nowrap">Cashback*</p>
                         {/* <p className="text-green-600 font-bold mb-2"> starting from {priceMapin(offer)}</p> */}

                         </div>
                    ):(
                        <div className='flex flex-row '>
                        <p className="text-green-600 font-bold mb-2 whitespace-nowrap" >Price : ₹{offer.OfferData.net_price.toFixed(2)} </p>
                        <p className="text-gray-600 text-xs mt-1 ml-1 whitespace-nowrap">
  / {offer.productGroup?.UOM || offer.productData.product?.UOM || ''} 
</p>

                        </div>
                    )}
                   {offer.OfferData.base_price===0?(
                     <div className='flex flex-row items-center'>
                  
                     <p className="text-gray-600 text-sm line-through ml-1">{/* {offer.OfferData.base_price} */}</p>
                 </div>
                   ):(
                    <div className='flex flex-row items-center'>
                    <p className="text-gray-600 text-xs">GST:</p>
<p className={`text-gray-600 text-sm ml-1 ${offer.OfferData.GST_included ? 'text-black-500' : 'text-black-500'}`}>
    {offer.OfferData.GST_included ? 'Included' : 'Not Included'}
</p>

                </div>
                   )}
                   
                    <button 
    className="rounded-sm w-[50%] border border-[#EB001B] hover:bg-[#EB001B] hover:text-white text-gray-700 mt-2"
    onClick={() => {
        const filteredOffers = offers.filter(offer => offer.productData && offer.productData.product);
        navigateToProductsPage(filteredOffers, offer.productGroup, offer.productData.product, offer);
    }}
>
    View
</button>

                </div>
            </div>
        </div>

    </React.Fragment>
                    )}
                    return null;
})}

</div>

  {/* Measurit Div starts */}
{/* {index % 10 === 9  && ( */}
 {item.product_category_name === "Tiles" && ( 
<div /* key={`measurit-${index}`} */ className='flex flex-col gap-4 md:gap-4 sm:gap-4 rounded-md bg-amber-100 mt-4 mb-4'>
{/* Lines Div in Top */}
<div className="pl-4 flex justify-start flex-row">
  {[...Array(iterationCount)].map((_, i) => (
    <div key={`lines-${i}`} className="pl-2 flex justify-start items-start">
      <div className="space-x-2 h-full flex flex-row justify-between">
        {[...Array(4)].map((_, index) => (
          <div key={`small-line-${i}-${index}`} className="shrink-0 w-px bg-black border border-black border-solid h-[20px]"></div>
        ))}
        <div className="shrink-0 self-stretch w-px bg-black border border-black border-solid h-[35px]"></div> {/* Big line */}
      </div>
    </div>
  ))}
</div>
<div className="flex md:flex-row sm:flex-row justify-around items-center">
  <h2 className=" text-xl font-semibold">Measurit</h2>
  <p className='text-md sm:text-[10px] md:text-[16px]'>Want to get measurements of <br /> your entire project for FREE?</p>
  <button className='rounded-sm w-[50%] sm:w-[30%] md:w-[15%] border border-[#EB001B] bg-[#EB001B] text-white' onClick={() => handleContact()}>Contact Us</button>
</div>
{/* Repeat Lines Div multiple times */}
<div className="pl-4 flex justify-start  flex-row">
  {[...Array(iterationCount)].map((_, i) => (
    <div key={`lines-${i}`} className="pl-2 flex justify-start items-start">
      <div className="space-x-2 h-full flex flex-row justify-between">
        {[...Array(4)].map((_, index) => (
          <div key={`small-line-${i}-${index}`} className="mt-4 shrink-0 w-px bg-black border border-black border-solid h-[20px]"></div>
        ))}
        <div className="mt-2 shrink-0 self-stretch w-px bg-black border border-black border-solid h-[30px]"></div> {/* Big line */}
      </div>
    </div>
  ))}
</div>
</div>
 )} 
{/*  )} */}
{/* Measurit Div Ends */} 
</div>
    );
    
        })
    )}



{/* Conditionally render the ContactUs component */}
{showContactUs && <ContactUs onClose={toggleContactUs} />}

            {showPopup && (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75">
        <div className="bg-white rounded-lg p-8 max-w-md w-full">
            <div className='flex justify-end'>
            <button className="hover:text-black text-gray-600" onClick={() => setShowPopup(false)}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
                </button>
            </div>
        </div>
    </div>
)}

{/* Render the quote popup */}
 {showQuotePopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75">
            <div className="bg-white rounded-lg p-4 max-w-md w-full">
                <div className="flex justify-end">
                    <button
                        className="hover:text-black text-gray-600"
                        onClick={() => setShowQuotePopup(false)}
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            ></path>
                        </svg>
                    </button>
                </div>
                <div className="text-lg font-semibold pb-4 flex justify-center">
                    <p>Get Quote for selected Project(s)</p>
                </div>
                <div>
                    {/* Display filtered projects in the popup */}
                    {displayedProjects.map((project) => (
                        <div key={project._id} className="flex items-center">
                            <input
                                type="checkbox"
                                id={project._id}
                                /*  checked={selectedProjects.includes(project._id)} */
                                onChange={() =>
                                    handleProjectCheckboxChange(project._id)
                                }
                            />
                            <label htmlFor={project._id} className="ml-2">
                                {project.projectName}
                            </label>
                        </div>
                    ))}
                </div>
                <div className="pt-4 flex justify-center">
                    <button
                        className="rounded-sm w-[35%] border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-2 py-1 text-black mt-2"
                        onClick={handleQuoteSubmission}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )}
    </div>
    
    </div>

    {/* footer starts */}

    <div>
    <footer className="border-t border-1 border-gray-400 py-4">
      <div className="flex justify-center items-center text-gray-500">
        <div className="flex space-x-2">
          <a href="#" className="text-[#1a54a9]">Privacy Policy</a>
          <span>|</span>
          <a href="#" className="text-[#1a54a9]">Terms of Use</a>
        </div>
        
        <p className='ml-4'>&copy; 2024 BrickBucket. All rights reserved.</p>
        
      </div>
    </footer>
    </div>

{/* footer ends */}

    <div className='flex justify-end items-center right-10 bottom-3 fixed '>
        <FontAwesomeIcon className='w-7 h-7 cursor-pointer text-red-600'  onClick={scrollToTop} icon={faArrowCircleUp} />
    </div>    

 </div>
    );
};

export default Offers;

