import React, { useEffect, useState } from "react";
import WireColorPopup from './WireColorPopUp'
const AllProducts = ({AllOffersData ,product_category_id,product_sub_category_id ,addedProducts, 
    setAddedProducts, customerId, filteredProducts, searchQuery})=>{
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
    const [OffersData,setOffersData]=useState([])
    const [counts, setCounts] = useState([]);
    const [ElecticalWire,setElecticalWire]= useState(null);
    const [showWirePopup, setShowWirePopup] = useState(false);
    const [ElectricalOffer,setElectricalOffer]=useState()
    const [productAdded, setProductAdded] = useState([]);
     const [filteredOffersData, setFilteredOffersData] = useState([]);
    
    const [breakUp,setBreakUp]=useState({
        Red: '0',
        Blue: '0',
        Green: '0',
        Yellow: '0',
        Black: '0'
      })
      const [quantityBreakUp,setQuantityBreakUp]=useState([])
    
    
    useEffect(()=>{
  setOffersData(AllOffersData);
    },[AllOffersData])

    useEffect(()=>{
        setProductAdded(addedProducts);
          },[addedProducts])
    
    const calculatePrice = (product, offer) => {

        if (!product || !offer || !offer.OfferData) {
          return 'Price Not Available';}
        const basePrice = parseFloat( product.product_price || 0);
        const discount = parseFloat(offer.OfferData.discount || 0);
        const gst = parseFloat(offer.OfferData.GST_rate_id || 0);
      
        let netPrice = parseFloat(basePrice);
      
        // Apply discount
        netPrice -= (netPrice * (discount / 100));
      
        // Apply GST
        netPrice *= (1 + (gst / 100));
        return `${netPrice.toFixed(2)}`;
      };

      const handleIncrement = (productId) => {
        setCounts((prevCounts) => ({
          ...prevCounts,
          [productId]: (prevCounts[productId] || 0) + 1, // If count doesn't exist yet, initialize it with 0
        }));
    
        // Update local storage with the new count
        localStorage.setItem(
          "ProductCounts",
          JSON.stringify({
            ...counts,
            [productId]: (counts[productId] || 0) + 1,
          })
        );
      };
    
      const handleDecrement = (productId) => {
        setCounts((prevCounts) => ({
          ...prevCounts,
          [productId]: Math.max((prevCounts[productId] || 0) - 1, 0), // If count doesn't exist yet, initialize it with 0
        }));
    
        // Update local storage with the new count
        localStorage.setItem(
          "ProductCounts",
          JSON.stringify({
            ...counts,
            [productId]: Math.max((counts[productId] || 0) - 1, 0),
          })
        );
      };

      const handleAddItem = (product, offerData) => {
        //console.log(offerData)
        if (offerData.productSubCategory && offerData.productSubCategory.product_sub_category_name === 'Electrical Wires') {
          setElecticalWire(product);
          setShowWirePopup(true);
      
          const BreakUpQuantity = JSON.parse(localStorage.getItem('quantityBreakUp')) || [];
          const BreakUpData = BreakUpQuantity.find(p => p.product_id === product._id) || {
            break_ups: {
              Red: '',
              Blue: '',
              Green: '',
              Yellow: '',
              Black: ''
            }
          };
          setElectricalOffer(offerData)
          setBreakUp(BreakUpData.break_ups);
        } else {
          HandleAddItems(product,offerData);
        }
      };
      
    
      const HandleAddItems = (product, offerData) => {
        const count = counts[product._id] || 0;
        const customer_id=customerId
        if (count > 0) {
          // Ensure productAdded is an array
          if (!Array.isArray(productAdded)) {
            console.error('productAdded is not an array:', productAdded);
            return;
          }
      
          const existingProductIndex = productAdded.findIndex((item) => item.product._id === product._id);
          const productCategoryID = product.product_category_id; // Extract product category ID
          const updatedProduct = { product, customer_id, count, productCategoryID, offer_id: offerData.OfferData._id }; // Include product category ID
      
          if (existingProductIndex !== -1) {
            // Product already exists, update the count
            const updatedProductAdded = [...productAdded];
            updatedProductAdded[existingProductIndex] = updatedProduct;
            setProductAdded(updatedProductAdded);
            setAddedProducts(updatedProductAdded)
            localStorage.setItem('addedProducts', JSON.stringify(updatedProductAdded));
          } else {
            // Product doesn't exist, add it to the list
            const updatedProductAdded = [...productAdded, updatedProduct];
            setProductAdded(updatedProductAdded);
            setAddedProducts(updatedProductAdded)
            localStorage.setItem('addedProducts', JSON.stringify(updatedProductAdded));
          }
        }
      };
      

      const handleWirePopupSubmit = (colors) => {
   
        let totalQuantity = 0
        for (const key in colors) {
            const quantity=  colors[key] || '0';
            totalQuantity +=parseInt(quantity)
        }
        counts[ElecticalWire._id]=totalQuantity
        localStorage.setItem(
         "ProductCounts",
         JSON.stringify(counts)
       ); 
     
         const QuantityBreakUp = {
           customer_id: customerId,
           product_category_id: ElecticalWire.product_category_id,
           product_id: ElecticalWire._id,
           break_ups: colors
         };
       
         let AllQuantityBreakup = JSON.parse(localStorage.getItem("quantityBreakUp")) || [];
         
         const findQuantityBreakupIndex = AllQuantityBreakup.findIndex(p => 
           p.user_id === QuantityBreakUp.user_id && p.product_id === QuantityBreakUp.product_id
         );
     
         HandleAddItems(ElecticalWire,ElectricalOffer)
       
         if (findQuantityBreakupIndex !== -1) {
           AllQuantityBreakup[findQuantityBreakupIndex] = QuantityBreakUp;
         } else {
           AllQuantityBreakup = [...AllQuantityBreakup, QuantityBreakUp];
         }
       
         localStorage.setItem("quantityBreakUp", JSON.stringify(AllQuantityBreakup));
       };

      

       useEffect(() => {
        if (searchQuery && searchQuery.length >= 2) {
            const searchWords = searchQuery.toLowerCase().split(" ").filter(Boolean);
    
            const filteredData = OffersData.flatMap((offer) => {
                const filteredProducts = offer.OfferProducts.filter((product) => {
                    const productWords = product.product_name.toLowerCase().split(" ").filter(Boolean);
                    return searchWords.every(word =>
                        productWords.some(productWord => productWord.includes(word))
                    );
                });
                return filteredProducts.length > 0 ? [{ ...offer, OfferProducts: filteredProducts }] : [];
            });
            setFilteredOffersData(filteredData);
        } else {
            setFilteredOffersData(OffersData);
        }
    }, [searchQuery, OffersData]);
    

       const handleChange = (productId, e) => {
        const newValue = parseInt(e.target.value) || 0;
        setCounts((prevCounts) => ({
          ...prevCounts,
          [productId]: newValue,
        }));
    
        // Update local storage with the new count
        localStorage.setItem(
          "ProductCounts",
          JSON.stringify({
            ...counts,
            [productId]: newValue,
          })
        );
      };

return(
    <div className="w-full h-full ">
    {showWirePopup && (
        <WireColorPopup
          onClose={() => setShowWirePopup(false)}
          onSubmit={handleWirePopupSubmit}
          BreakUps={breakUp}
          
        />
      )}

      {filteredOffersData.map((offer, Offerindex) => (
            <div key={Offerindex}>
              {offer.OfferProducts && offer.OfferProducts.length > 0 && (
  <>
              <div className="flex">
                {offer.productSubCategory && (
                  <p className="text-lg sm:text-[14px] md:text-[20px] text-gray-700 font-semibold py-1 px-2 mt-5">
                    {offer.productSubCategory.product_sub_category_name}
                  </p>
                )}
                {offer.productGroup&&(
                    <div>
                      <p className="text-lg sm:text-[14px] md:text-[20px] text-gray-700 font-semibold py-1 px-2 mt-5">
                    {offer.productGroup.product_group_name}
                  </p>  
                    </div>
                )}
              </div>
              <div className="grid grid-cols-3 gap-1">
                {offer.OfferProducts && offer.OfferProducts.length > 0 ? (
                  offer.OfferProducts.map((product, productIndex) => (
                    <div key={product._id} className="border px-2 py-1 rounded-lg flex flex-col items-center">

                      <div className="bg-white shadow-sm rounded p-1">
                        <img
                          src={ImageUrl + (product.product_image || 'image unavailable')}
                          alt="Product Image"
                          className="w-full h-24 object-cover mb-2" 
                        />
                      </div>
                      <div>
                        <h2 className="text-xs font-bold text-center whitespace-normal mt-1">
                          {product.product_name || 'Product Name Not Available'}
                        </h2>
                        {/* <p className="text-xs font-semibold text-center mt-2">{product.product_description}</p> */}
                      </div>
                      <div className="pt-2 flex flex-col ">
                     
                        <div className="text-xs flex justify-center ">
                            <p>Offer Price:</p>
                            <div className="flex flex-row">
                            {offer.OfferData.net_price === 0 ? (
                              <p>₹{calculatePrice(product, offer)}</p>
                            ) : (
                              <p>₹{offer.OfferData ? offer.OfferData.net_price : 'Price Not Available'}</p>
                            )}
                             <p>/{product.UOM || ''}</p>
                             </div>
                          </div>
                          {/* <div className="text-xs line-through">
                            {product.product_price === null || product.product_price === "" ? (
                              <p>MRP: ₹{offer.OfferData.base_price !== 0 ? offer.OfferData.base_price : 'Price Not Available'}</p>
                            ) : (
                              <p>MRP: ₹{product.product_price}</p>
                            )}
                          </div> */}
                     
                        <div>
                          <div className="text-xs p-2 flex flex-col">
                            <div className="flex justify-center">
                              <button className="w-5 rounded-sm border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 text-black focus:outline-none"
                                onClick={ () => handleDecrement(product._id) }>-</button>
                              <input
                                type="text"
                                className="border border-gray-300 rounded-sm w-10 text-center focus:outline-none"
                                value={counts[product._id] || 0}
                                onChange={ (e) => handleChange(product._id, e) }
                              />
                              <button className="w-5 rounded-sm border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 text-black focus:outline-none"
                                onClick={ () => handleIncrement(product._id) } >+</button>
                            </div>
                            <div className="flex justify-center">
                              <button className="text-xs rounded-sm w-10 border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 text-black mt-2"
                                onClick={ () => handleAddItem(product, offer)}>
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {offer.productSubCategory && offer.productSubCategory.product_sub_category_name && offer.productSubCategory.product_sub_category_name === 'Electrical Wires' && (
      <div className="flex justify-start">
      <div>
      <p className="text-xs">Colours:</p>
      </div>
      <div className="flex flex-row space-x-1">
        <img src="https://via.placeholder.com/64/FF0000/FFFFFF?text=Red" alt="Red" className="w-4 h-4 rounded-lg" />
      <img src="https://via.placeholder.com/64/00FF00/FFFFFF?text=Green" alt="Green" className="w-4 h-4 rounded-lg" />
      <img src="https://via.placeholder.com/64/FFFF00/FFFFFF?text=Yellow" alt="Yellow" className="w-4 h-4 rounded-lg " />
      <img src="https://via.placeholder.com/64/0000FF/FFFFFF?text=Blue" alt="Blue" className="w-4 h-4 rounded-lg"/>
      <img src="https://via.placeholder.com/64/000000/FFFFFF?text=Black" alt="Black" className="w-4 h-4 rounded-lg"/>
      </div>
  </div>
      )}
                    </div>
                  ))
                ) : (
                  <div>No products found</div>
                )}
              </div>
              <hr className="border-gray-400 my-1 mt-10" />
              </>
              )}
            </div>
          ))}
    </div>
)
}
export default AllProducts;