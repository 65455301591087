import React, { useState, useEffect } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const QuotationProducts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
  const [customer, setCustomerData] = useState();
  const { state } = location;
  
 const [selectedProductsData, setselectedProductsData]= useState([])
  const { projectIds ,selectedRoomAreaType,offer} = state;
 
  const [productData,setProductsData] = useState([]);
/*   // Assuming filteredProducts is the array you provided
  const filteredProducts = filteredOffers.filter(offer => {
    if (selectedProductGroupId) {
        return offer.productGroup && offer.productGroup._id === selectedProductGroupId;
    } else if (selectedProductId) {
        return offer.productData && offer.productData.product && offer.productData.product._id === selectedProductId;
    }
    return false;
  });

  // Initialize offer_id to null
  let offer_id = null; 

  // Check if filteredProducts is an array of objects
  if (filteredProducts.length > 0) {
    // Access the first object in the array
    const firstProduct = filteredProducts[0];
    // Access the _id property of the first object
    offer_id = firstProduct._id;
  } else {
    console.log('No products found.');
  } */

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    setCustomerData(userData);
    fetchSelectedProducts(userData._id, offer._id);
    if (offer) {
      getProducts(offer._id)
     
    }
  }, [offer]);
  
  const getProducts = async (offer_id) => {
    const OfferProductsUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/get-offerProducts-by-offerId?offer_id=${offer_id}`;

    try {
      const token = sessionStorage.getItem('token');
        const response = await fetch(OfferProductsUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                // Add other headers if needed
                'x-token':token
            },
        });

        if (response.ok) {
            const data = await response.json(); // Parse the response JSON
            console.log(data);
            setProductsData(data); // Assuming setProductsData is a state update function
        } else {
            // Handle non-OK response status (e.g., 404, 500)
            console.error("Failed to fetch products:", response.statusText);
            // Handle the error state or show a message to the user
        }
    } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error fetching products:", error.message);
        // Handle the error state or show a message to the user
        clearStorageAndRedirectToHome()
    }
};

  
function clearStorageAndRedirectToHome() {
  navigate("/bb/ErrorPage");
}


  const handleAddToselectionsForQuotation = async (productCategoryId, productId, offerId) => {
    try {
      const token = sessionStorage.getItem('token');
      const collectionData = {
        customer_id: customer._id,
        product_category_id: productCategoryId,
        product_id: productId,
        offer_id: offerId,
        project_ids:projectIds,
        room_area_type:selectedRoomAreaType||''
      };
     // console.log(collectionData);
     
  
     /*  const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + '/customer/Quotation-selections/save-customer-selections-for-quotation', { */
        const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + '/customer/selections/save-customer-selections-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token':token
        },
        body: JSON.stringify(collectionData),
      });
  
      if (!response.ok) {
        console.log('error')
        throw new Error('Failed to add to wishlist');
      }
      //fetchSelectedProducts(customer._id, productCategoryId)
      fetchSelectedProducts(customer._id, offerId)
      // Handle successful response, such as showing a success message or updating state
    } catch (error) {
      console.error('Error adding to wishlist:', error);
      clearStorageAndRedirectToHome()
    }
  };

  const fetchSelectedProducts = async (customer_id,offer_id) => {
 
    try {
      const token = sessionStorage.getItem('token');
     /*  const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/Quotation-selections/get-customer-selections-for-quotation-by-customerAndProductCategoryIds?product_category_id=${offer.productCategory._id}&customer_id=${customer_id}`); */
     const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/selections/get-customer-selections-data-by-customerAndofferIds?offer_id=${offer_id}&customer_id=${customer_id}`,{
      method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token':token
            },
            });
     if (!response.ok) {
        setselectedProductsData([])
        throw new Error('Failed to fetch selected products');
      }
      const data = await response.json();
      console.log(data);
      setselectedProductsData(data.SelectionData)
      //setselectedProductsData(data.customerSelectionData)
    } catch (error) {
      console.error('Error fetching selected products:', error);
      clearStorageAndRedirectToHome()
    }
  }; 
  
  const handleClickRemoveSelection = async (selection) => {
    console.log(selection)
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/selections/delete-customer-selections-data-by-selectionId?selection_id=${selection._id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-token':token
        },
      });
  
      if (!response.ok) {
        console.error('Failed to delete selection');
        throw new Error('Failed to delete selection');
      }
  
      const data = await response.json();
      console.log(data)
      fetchSelectedProducts(selection.customer_id,selection.offer_id)
    } catch (error) {
      console.error('Error deleting selection:', error);
      clearStorageAndRedirectToHome()
    }
  };
  
  return (
    <div className="flex justify-center items-center p-2">
      <div className="md:w-2/3 sm:w-full">
        <h1 className="text-2xl font-bold mb-4">All Products</h1>
        {productData.length === 0 ? (
          <p>Loading...</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
            {/* Display products */}
            {productData.map((product) => (
              <div key={product._id} className="relative border p-4 rounded-lg flex flex-col items-center">
                {/* Heart icon */}
                {/* <button
                  className="absolute top-0 right-0 mt-2 mr-2 text-red-500 focus:outline-none transition-colors duration-300 ease-in-out hover:text-black"
                  // Pass product category ID here
                >{console.log(selectedProductsData)}
                  {selectedProductsData.find(p => p.product_id === product._id && p.room_area_type === selectedRoomAreaType)  ? (
                    <FaHeart className="text-red-500" onClick={()=>handleClickRemoveSelection(selectedProductsData.find(p => p.product_id === product._id&& p.room_area_type === selectedRoomAreaType))}/>
                  ) : (
                    <FaRegHeart className="text-red-500" onClick={() => handleAddToselectionsForQuotation(product.product_category_id, product._id, offer._id)} />
                  )}
                </button> */}
                <button
                  className="absolute top-0 right-0 mt-2 mr-2 text-red-500 focus:outline-none transition-colors duration-300 ease-in-out hover:text-black"
                  // Pass product category ID here
                >
                  {selectedProductsData.find(p => p.product_id === product._id) ? (
                    <FaHeart className="text-red-500" onClick={()=>handleClickRemoveSelection(selectedProductsData.find(p => p.product_id === product._id))}/>
                  ) : (
                    <FaRegHeart className="text-red-500" onClick={() => handleAddToselectionsForQuotation(product.product_category_id, product._id, offer._id)} />
                  )}
                </button>
                {/* Product image */}
                {product && (
  <div className="w-40 h-40 overflow-hidden flex items-center justify-center">
    <img
      src={ImageUrl + (product.product_image || 'image unavailable')}
      alt="Product Image"
      className="max-w-full max-h-full object-contain"
      onError={(e) => console.log("Error loading image:", e)}
    />
  </div>
)}
                {/* Product name */}
                <h2 className="text-lg font-bold">
                  {product.product_name || 'Product Name Not Available'}
                </h2>
                {/* Price */}
                <p>Price: ₹{offer.OfferData ? offer.OfferData.net_price : 'Price Not Available'}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
  
};

export default QuotationProducts;





