import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
const MaterialRequirement= ()=>{
  const navigate= useNavigate()
    const [CustomersData,setCustomersData]= useState([])

    useEffect(()=>{
    fetchAllCustomersAndTheirMaterialRequirements()
    },[])

    const fetchAllCustomersAndTheirMaterialRequirements = async () => {
        const CustomersAndTheirMaterialRequirementsUrl = process.env.REACT_APP_FORE_BRICKBUCKET+'/customer/material-requirement/get-all-customers-and-their-material-requirements'; // make sure to add the actual URL
        try {
          const token = sessionStorage.getItem('token');

          const response = await fetch(CustomersAndTheirMaterialRequirementsUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json', // add this header
              'x-token':token
            },
          });
          if (response.ok) {
            const data = await response.json();
            console.log(data);
            setCustomersData(data.AllCustomersAndTheirMaterialRequirements);
          } else {
            console.error('Error:', response.status, response.statusText);
          }
        } catch (error) {
          console.error('Error:', error.message);
        }
      };

      function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
      }

      const handleClickOnCreateQuotstion=(customer,materialRequired)=>{
      console.log(customer,materialRequired)
      navigate('/bb/admin/AdminHome/createCustomerQuotation', {state:{customer,materialRequired}})
      }

return(
   <div className="w-full h-full px-2 py-2">
   <div className="overflow-x-auto">
  {CustomersData.length > 0? (
    <table className="w-full border-b border-gray-300 mt-4">
      <thead className="border-b border-gray-300">
        <tr className="bg-[#D9D9DD] bg-opacity-[100%]">
          <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-4 text-sm text-center">Customer Mobile</th>
          <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-4 text-sm text-center">Date</th>
          <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-4 text-sm text-center">Material Requirement</th>
          <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-4 text-sm text-center">Create Quotation</th>
        </tr>
      </thead>
      <tbody>
         {CustomersData.map((data, index) => (
          data.materialRequirementOfCustomer.map((materialReq, materialReqIndex) => (
            <tr key={materialReq._id} className="bg-white border-b">
              <td className="text-blue-950 text-opacity-80 py-2 text-sm px-4 text-center">{data.EachCustomer.mobile_number}</td>
              <td className="text-blue-950 text-opacity-80 py-2 text-sm px-4 text-center">{formatDate(materialReq.requirement_posted_date)}</td>
              <td className="text-blue-950 text-opacity-80 py-2 text-sm px-4 text-center">
                <img src={`${process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES}${materialReq.requirement_image}`} alt="Material Image" className="h-10 w-10 rounded-full mx-auto" />
              </td>
              <td className="text-red-600 text-opacity-80 py-2 text-sm px-4 text-center cursor-pointer">
                <p className="hover:underline" onClick={() => handleClickOnCreateQuotstion(data.EachCustomer, materialReq)}>Create Quotation</p>
              </td>
            </tr>
          ))
        ))}
      </tbody>
    </table>
  ) : (
    <p className="text-center">No consumers available</p>
  )}
</div>
    
   </div>
)
}
export default MaterialRequirement