import React, { useState, useEffect } from "react";
import {FaArrowLeft, FaArrowRight, FaCheckCircle, FaChevronLeft, FaChevronRight, FaHeart, FaMinusCircle, FaRegHeart } from "react-icons/fa";
import { useLocation ,useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';


// Define the popup component
const WireColorPopup = ({ onClose, onSubmit,BreakUps }) => {
  const [colors, setColors] = useState({
    Red: '0',
    Blue: '0',
    Green: '0',
    Yellow: '0',
    Black: '0'
  });

  useEffect(()=>{
    setColors(BreakUps)
     },[BreakUps])

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!isNaN(value)) { // Ensure the input is a number
      setColors((prevColors) => ({
        ...prevColors,
        [name]: value || '0'
      }));
    }
  };

  const handleSubmit = () => {
    onSubmit(colors);
    onClose();
  };

  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-md max-w-md w-full">
        <h3 className="text-lg font-semibold mb-4">Enter Wire Colors</h3>
        <div className="grid grid-cols-2 gap-4">
          {Object.keys(colors).map((color) => (
            <div key={color} className="flex flex-col">
              <label className="font-medium">{color}:</label>
              <input
                type="text"
                name={color}
                value={colors[color]}
                onChange={handleChange}
                className="mt-1 p-2 border border-gray-300 rounded focus:outline-none"
              />
            </div>
          ))}
        </div>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const Products = () => {
  const location = useLocation();
  const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
  const [customer, setCustomerData] = useState();
  const { state } = location;
  const navigate = useNavigate();
  const [selectedProductsData, setselectedProductsData]= useState([])
  const { filteredOffers, selectedProductGroupId, selectedProductId, selectedRoomAreaType, offer } = state;
  const [showWirePopup, setShowWirePopup] = useState(false);
  const [productData,setProductsData] = useState([]);
  const productCategoryName = offer.productCategory.product_category_name;
  const productCategoryId = offer.productCategory._id;
  const shouldDisplayAddItems = ["Tiles", "UPVC Windows", "Doors"].includes(productCategoryName);
  const [addItems, setAddItems] = useState(!shouldDisplayAddItems);
  const [productAdded, setProductAdded] = useState([]);
  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [projects, setProjects] = useState([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  const [saveClicked, setSaveClicked] = useState(false);
  const [Popup, setPopup] = useState(false); // New state for popup
  // Initialize count state for each product
  const [counts, setCounts] = useState([]);
  const [ElecticalWire,setElecticalWire]= useState(null);
  const [OffersData,setOffersData] = useState([])
  const [ProductCategory,setProductCategory]= useState()
  const [ElectricalOffer,setElectricalOffer]=useState()
  const [breakUp,setBreakUp]=useState({
    Red: '0',
    Blue: '0',
    Green: '0',
    Yellow: '0',
    Black: '0'
  })
  const [quantityBreakUp,setQuantityBreakUp]=useState([])
  const [isQuotationSaved, setIsQuotationSaved] = useState(true);

  useEffect(()=>{
  const quantityBreakUp= JSON.parse(localStorage.getItem('quantityBreakUp'))||[]
  setQuantityBreakUp(quantityBreakUp)
  },[productAdded])

  

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setCustomerData(userData._id);
    fetchSelectedProducts(userData._id, offer._id);
    if (offer) {
      getProducts(offer._id);
    }
  }, [offer]);
  

  useEffect(() => {
    const fetchProjects = async () => {
       
        try {
            const token = sessionStorage.getItem('token');
            const GetProjects = `${backendApiUrl}/customer/get-projects-by-customer?customer_id=${customer}`;
            const response = await fetch(GetProjects, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    
                    'x-token':token
                },
            });

            if (response.ok) {
                const projectsData = await response.json();
                const reversedData = projectsData.customerProjects.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                const sortedProjects = reversedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setProjects(sortedProjects);
                // Extracting project IDs into an array
        const projectIds = sortedProjects.map(project => project._id);
            } else {
                console.error('Failed to fetch projects:', response.statusText);
            }
        } catch (error) {
            console.error('Failed to fetch projects:', error);
            //clearStorageAndRedirectToHome()
        }
    };

    if (saveClicked && customer) {
      fetchProjects();
    }

   
}, [backendApiUrl, customer, saveClicked]);

const HandleSaveClick = () => {
  setSaveClicked(true);
  setPopup(true);
  //...
};

const handleSubmitPopup = async (selectedProjectIds) => {
    if (!offer.productCategory || !offer.productCategory._id) {
      console.error("Product category ID is missing.");
      return;
    }
  
    // Retrieve and filter products from local storage
    const addedProducts = JSON.parse(localStorage.getItem('addedProducts')) || [];
    const filteredProducts = addedProducts.filter(product => product.product.product_category_id === offer.productCategory._id);
  
    if (filteredProducts.length === 0) {
      console.error("No products found for the current product category.");
      return;
    }
    const availOffers= GetAvailOffers(filteredProducts);
    const quantityBreakUp = JSON.parse(localStorage.getItem('quantityBreakUp')) || [];
  const quantity_break_up = quantityBreakUp.filter(p => p.product_category_id === offer.productCategory._id && p.customer_id === customer);
    try {
      const token = sessionStorage.getItem('token');
  
      const requestData = {
        customer_id: customer,
        product_category_id: offer.productCategory._id,
        created_by:"User",
        avail_offers:availOffers,
        quantity_break_up:quantity_break_up,
        project_ids: selectedProjectIds, // Add the selected project IDs to the request data
        quotationData: filteredProducts.map(({ product, count, offer_id }) => ({
          product_id: product._id,
          count,
          offer_id: offer_id,
          price: calculateOfferPrice(product, offer_id)
        }))
      };
      const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/save-customer-quotation-of-no-project-ids`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add other headers if needed
          'x-token':token
        },
        body: JSON.stringify(requestData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to save PDF data to the server');
      }
      setPopup(false);
      clearLocalStorage()
    } catch (error) {
      console.error('Error saving PDF data:', error);
      clearStorageAndRedirectToHome()
    }
  };


const handleClosePopup = () => {
  setPopup(false);

};

 useEffect(() => {
  // Initialize counts state for each product when product data changes
  const storedCounts = JSON.parse(localStorage.getItem("ProductCounts")) || {};
  if (Object.keys(storedCounts).length > 0) {
    setCounts(storedCounts);
  } else {
    const initialCounts = {};
    productData.forEach((product) => {
      initialCounts[product._id] = 0;
    });
    setCounts(initialCounts);
  }
}, [productData]);

useEffect(() => {
  // Initialize counts state for each product when product data changes
  const storedData = JSON.parse(localStorage.getItem("addedProducts")) || {};
  if (storedData.length > 0) {
    setProductAdded(storedData);
  } else {
    setProductAdded([])
  }
}, [productData]);
  
/* const getFilteredOffersData = () => {
  // Create a set of added product IDs for quick lookup
  const addedProductIds = new Set(productAdded.map((product) => product.product._id));

  return OffersData.map((offer) => ({
    ...offer,
    OfferProducts: offer.OfferProducts.filter((product) => !addedProductIds.has(product._id)),
  }));
};

const filteredOffersData = getFilteredOffersData(); */

useEffect(() => {
  const fetchProductData = async () => {
      try {
        const token = sessionStorage.getItem('token');

          const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/get-ofers-and-offerProducts-by-productCategoryId?product_category_id=${productCategoryId}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token':token
            },
            });
          if (!response.ok) {
              throw new Error(`Error fetching data: ${response.statusText}`);
          }
          const data = await response.json();
         
          const remainingOffers = data.AllOffers.filter(p => p.OfferData._id !== offer._id) || [];
        const selectedOffer = data.AllOffers.find(p => p.OfferData._id === offer._id);
        const allOffers = selectedOffer ? [selectedOffer, ...remainingOffers] : remainingOffers;
        
          setOffersData(allOffers)
         
          setProductCategory(data.productCategoryData)
         // setProductData(data);
      } catch (error) {
         // setError(error.message);
         clearStorageAndRedirectToHome()
      } /* finally {
         // setLoading(false);
      } */
  };

  fetchProductData();
  //fetchQuotations();
}, [productCategoryId,offer]);


  const handleChange = (productId, e) => {
    const newValue = parseInt(e.target.value) || 0;
    setCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: newValue,
    }));

    // Update local storage with the new count
    localStorage.setItem(
      "ProductCounts",
      JSON.stringify({
        ...counts,
        [productId]: newValue,
      })
    );
    setIsQuotationSaved(false);
  };

  const handleIncrement = (productId) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: (prevCounts[productId] || 0) + 1, // If count doesn't exist yet, initialize it with 0
    }));

    // Update local storage with the new count
    localStorage.setItem(
      "ProductCounts",
      JSON.stringify({
        ...counts,
        [productId]: (counts[productId] || 0) + 1,
      })
    );
    setIsQuotationSaved(false);
  };

  const handleDecrement = (productId) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: Math.max((prevCounts[productId] || 0) - 1, 0), // If count doesn't exist yet, initialize it with 0
    }));

    // Update local storage with the new count
    localStorage.setItem(
      "ProductCounts",
      JSON.stringify({
        ...counts,
        [productId]: Math.max((counts[productId] || 0) - 1, 0),
      })
    );
    setIsQuotationSaved(false);
  };

  const getProducts = async (offer_id) => {
    const OfferProductsUrl = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/get-offerProducts-by-offerId?offer_id=${offer_id}`;

    try {
      const token = sessionStorage.getItem('token');

        const response = await fetch(OfferProductsUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                // Add other headers if needed
                'x-token':token
            },
        });

        if (response.ok) {
            const data = await response.json(); // Parse the response JSON
            setProductsData(data); // Assuming setProductsData is a state update function
        } else {
            // Handle non-OK response status (e.g., 404, 500)
            console.error("Failed to fetch products:", response.statusText);
            // Handle the error state or show a message to the user
        }
    } catch (error) {
        // Handle network errors or other exceptions
        console.error("Error fetching products:", error.message);
        // Handle the error state or show a message to the user
        clearStorageAndRedirectToHome()
    }
};

  const handleAddToWishlist = async (productCategoryId, productId, offerId) => {
    try {
      const collectionData = {
        customer_id: customer,
        product_category_id: productCategoryId,
        product_id: productId,
        offer_id: offerId,
        roomarea_type:selectedRoomAreaType||''
      };  
      const token = sessionStorage.getItem('token');

      const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + '/customer/selections/save-customer-selections-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token':token
        },
        body: JSON.stringify(collectionData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to add to wishlist');
      }
      fetchSelectedProducts(customer, offerId)
      // Handle successful response, such as showing a success message or updating state
    } catch (error) {
      console.error('Error adding to wishlist:', error);
     clearStorageAndRedirectToHome()
    }
  };

  const fetchSelectedProducts = async (customer_id, offer_id) => {

    try {
      const token = sessionStorage.getItem('token');

      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/selections/get-customer-selections-data-by-customerAndofferIds?offer_id=${offer_id}&customer_id=${customer_id}`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-token':token
        },
  
      });
      if (!response.ok) {
        throw new Error('Failed to fetch selected products');
      }
      const data = await response.json();
      setselectedProductsData(data.SelectionData)
    } catch (error) {
      console.error('Error fetching selected products:', error);
      clearStorageAndRedirectToHome()
    }
  }; 
  
  const handleClickRemoveSelection = async (selection) => {
    
    try {
      const token = sessionStorage.getItem('token');

      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/selections/delete-customer-selections-data-by-selectionId?selection_id=${selection._id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-token':token
        },
      });
  
      if (!response.ok) {
        console.error('Failed to delete selection');
        throw new Error('Failed to delete selection');
      }
  
      const data = await response.json();
     
      fetchSelectedProducts(selection.customer_id, offer._id)
    } catch (error) {
      console.error('Error deleting selection:', error);
      clearStorageAndRedirectToHome()
    }
  };

  const calculatePrice = (product, offerData) => {

    if (!product || !offerData || !offerData.OfferData) {
      return 'Price Not Available';}
    const basePrice = product.product_price || 0;
    const discount = offerData.OfferData.discount || 0;
    const gst = parseFloat(offerData.OfferData.GST_rate_id) || 0;
  
    let netPrice = parseFloat(basePrice);
  
    // Apply discount
    netPrice -= (netPrice * (discount / 100));
  
    // Apply GST
    netPrice *= (1 + (gst / 100));
    return `${netPrice.toFixed(2)}`;
  };

  const handleAddItem = (product, offerData) => {
    if (offerData.productSubCategory && offerData.productSubCategory.product_sub_category_name === 'Electrical Wires') {
      setElecticalWire(product);
      setShowWirePopup(true);
  
      const BreakUpQuantity = JSON.parse(localStorage.getItem('quantityBreakUp')) || [];
      const BreakUpData = BreakUpQuantity.find(p => p.product_id === product._id) || {
        break_ups: {
          Red: '',
          Blue: '',
          Green: '',
          Yellow: '',
          Black: ''
        }
      };
      setElectricalOffer(offerData)
      setBreakUp(BreakUpData.break_ups);
    } else {
      HandleAddItems(product,offerData);
    }
  };
  

  const HandleAddItems=(product,offerData)=>{
  
    const count = counts[product._id] || 0;
    // Check if the count is greater than 0
    if (count > 0) {
      const existingProductIndex = productAdded.findIndex((item) => item.product._id === product._id);
      const productCategoryID = product.product_category_id; // Extract product category ID
      const updatedProduct = { product, count, productCategoryID, offer_id:offerData.OfferData._id }; // Include product category ID
      
      if (existingProductIndex !== -1) {
        // Product already exists, update the count
        const updatedProductAdded = [...productAdded];
        updatedProductAdded[existingProductIndex] = updatedProduct;
        setProductAdded(updatedProductAdded);
        localStorage.setItem('addedProducts', JSON.stringify(updatedProductAdded));
      } else {
        // Product doesn't exist, add it to the list
        const updatedProductAdded = [...productAdded, updatedProduct];
        setProductAdded(updatedProductAdded);
        localStorage.setItem('addedProducts', JSON.stringify(updatedProductAdded));
      }
      
    }
  }

  const handleWirePopupSubmit = (colors) => {
   
   let totalQuantity = 0
   for (const key in colors) {
       const quantity=  colors[key] || '0';
       totalQuantity +=parseInt(quantity)
   }
   counts[ElecticalWire._id]=totalQuantity
   localStorage.setItem(
    "ProductCounts",
    JSON.stringify(counts)
  ); 


    const user = JSON.parse(localStorage.getItem('userData'));
    
    if (!user) {
      console.error("User data not found in localStorage.");
      return;
    }
  
  
    const QuantityBreakUp = {
      customer_id: user._id,
      product_category_id: ElecticalWire.product_category_id,
      product_id: ElecticalWire._id,
      break_ups: colors
    };
  
    let AllQuantityBreakup = JSON.parse(localStorage.getItem("quantityBreakUp")) || [];
    
    const findQuantityBreakupIndex = AllQuantityBreakup.findIndex(p => 
      p.user_id === QuantityBreakUp.user_id && p.product_id === QuantityBreakUp.product_id
    );

    HandleAddItems(ElecticalWire,ElectricalOffer)
  
    if (findQuantityBreakupIndex !== -1) {
      AllQuantityBreakup[findQuantityBreakupIndex] = QuantityBreakUp;
    } else {
      AllQuantityBreakup = [...AllQuantityBreakup, QuantityBreakUp];
    }
  
    localStorage.setItem("quantityBreakUp", JSON.stringify(AllQuantityBreakup));
  };
  

 //Adding product Category to the local storage to differentiate the added products in the local storage.
/* const handleAddItem = (product) => {
  const count = counts[product._id] || 0;
  // Check if the count is greater than 0
  if (count > 0) {
    const existingProductIndex = productAdded.findIndex((item) => item.product._id === product._id);
    if (existingProductIndex !== -1) {
      // Product already exists, update the count
      const updatedProductAdded = [...productAdded];
      updatedProductAdded[existingProductIndex].count = count;
      setProductAdded(updatedProductAdded);
      localStorage.setItem('addedProducts', JSON.stringify(updatedProductAdded));
    } else {
      // Product doesn't exist, add it to the list
      const updatedProductAdded = [
        ...productAdded,
        {
          product,
          count
        }
      ];
      setProductAdded(updatedProductAdded);
      localStorage.setItem('addedProducts', JSON.stringify(updatedProductAdded));
    }
  }
}; */
  
/* const ToTaladdedProductsPrice = () => {
  let totalCost = 0;

  // Filter products by the current product category ID
  
  productAdded.forEach((item) => {
    const product = item.product;
    const offerId= item.offer_id
    const count = item.count;
    const basePrice = parseFloat(product.product_price);
    const discount = OffersData.find(p=>p.OfferData._id===offerId).OfferData.discount || 0;
          const gst =OffersData.find(p=>p.OfferData._id===offerId).OfferData.gst || 0;
    let netPrice = basePrice;
    // Apply discount
    netPrice -= (netPrice * (discount / 100));
    // Apply GST
    netPrice *= (1 + (gst / 100));

    // Calculate total price for the product
    const totalPrice = netPrice * count;

    // Add total price to the total cost
    totalCost += totalPrice;
  });

  // Return the total cost
  return totalCost.toFixed(2);
}; */


const ToTaladdedProductsPrice = () => {
  let totalCost = 0;
  productAdded.forEach((item) => {
    const count = item.count;
    const netPrice= calculateTotalOfferPrice(item)
    const totalPrice = netPrice * count;
    totalCost += totalPrice;
  })
  return totalCost.toFixed(2);
}

const calculateTotalOfferPrice = (item) => {
  const SelecetdOffer = OffersData.find((p) => p.OfferData._id === item.offer_id);
  if (!SelecetdOffer) {
    console.error(`No offer found for item ${item.offer_id}`);
    return 0; // or some default value
  }

  if (SelecetdOffer.OfferData.net_price === 0) {
    const basePrice = parseFloat(item.product.product_price);
    const gst = parseFloat(SelecetdOffer.OfferData.GST_rate_id) || 0;
    const discount = SelecetdOffer.OfferData.discount || 0;
    const NetPrice = (basePrice * (1 - ((discount) / 100))) * (1 + ((gst) / 100));
    return NetPrice;
  } else {
    return parseFloat(SelecetdOffer.OfferData.net_price);
  }
};



const GetAvailOffers =(filteredProducts)=>{
  const offerIdsSet = new Set(filteredProducts.map(({ offer_id }) => offer_id));
  const uniqueOfferIds = Array.from(offerIdsSet);
  return  uniqueOfferIds   
}

const calculateOfferPrice=(product,offerId)=>{
  const SelecetdOffer= OffersData.find(p=>p.OfferData._id===offerId)
  if(SelecetdOffer.OfferData.net_price===0){
    const basePrice = parseFloat(product.product_price);
    const gst = parseFloat( OffersData.find(p=>p.OfferData._id===offerId).OfferData.GST_rate_id) || 0;
    const discount = OffersData.find(p=>p.OfferData._id===offerId).OfferData.discount || 0;
    const NetPrice= (basePrice*(1-(discount/100)))*(1+(gst/100))
    return NetPrice
  }else{
    return SelecetdOffer.OfferData.net_price
  }

}


const saveQuotation = async () => {
  if (!offer.productCategory || !offer.productCategory._id) {
    console.error("Product category ID is missing.");
    return;
  }

  // Retrieve and filter products from local storage
  const addedProducts = JSON.parse(localStorage.getItem('addedProducts')) || [];
  const filteredProducts = addedProducts.filter(product => product.product.product_category_id === offer.productCategory._id);

  if (filteredProducts.length === 0) {
    console.error("No products found for the current product category.");
    return;
  }
  const availOffers= GetAvailOffers(filteredProducts);
  const quantityBreakUp = JSON.parse(localStorage.getItem('quantityBreakUp')) || [];
const quantity_break_up = quantityBreakUp.filter(p => p.product_category_id === offer.productCategory._id && p.customer_id === customer);
  try {
    const token = sessionStorage.getItem('token');

    const requestData = {
      customer_id: customer,
      product_category_id: offer.productCategory._id,
      created_by:"User",
      avail_offers:availOffers,
      quantity_break_up:quantity_break_up,
      quotationData: filteredProducts.map(({ product, count, offer_id }) => ({
        product_id: product._id,
        count,
        offer_id: offer_id,
        price: calculateOfferPrice(product, offer_id)
      }))
    };
    const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/save-customer-quotation-of-no-project-ids`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add other headers if needed
        'x-token':token
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error('Failed to save PDF data to the server');
    }

 clearLocalStorage()
   
  } catch (error) {
    console.error('Error saving PDF data:', error);
    clearStorageAndRedirectToHome()
  }
};

const clearLocalStorage = () =>{
  localStorage.removeItem('quantityBreakUp')
  localStorage.removeItem('addedProducts')
  localStorage.removeItem('ProductCounts')
  navigate('/bb/user/myQuotations');
}

const handleGeneratePDF = async () => {
  try {
    // Check if the product category ID exists
    if (!offer.productCategory || !offer.productCategory._id) {
      console.error("Product category ID is missing.");
      return;
    }

    // Retrieve and filter products from local storage
    const addedProducts = JSON.parse(localStorage.getItem('addedProducts')) || [];
    const filteredProducts = addedProducts.filter(product => product.product.product_category_id === offer.productCategory._id);

    if (filteredProducts.length === 0) {
      console.error("No products found for the current product category.");
      return;
    }

    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Define the function to calculate the price
    /* const calculatePrice = (product, offer) => {
      if (!product || !offer || !offer.OfferData) {
        return 'Price Not Available';
      }
      const basePrice = product.product_price || 0;
      const discount = offer.OfferData.discount || 0;
      const gst = offer.OfferData.gst || 0;

      let netPrice = parseFloat(basePrice);
      netPrice -= (netPrice * (discount / 100));
      netPrice *= (1 + (gst / 100));
      return netPrice;
    };
 */
    // Calculate subtotal
    const subtotal = filteredProducts.reduce((acc, product) => {
      
      const price = calculateOfferPrice(product.product, product.offer_id);;
      return acc + (product.count * price);
    }, 0);

    // Define table data with headers
    let tableData = [
      ['Product Name', 'Quantity', 'Price'], // Header row
      ...filteredProducts.map(product => ([
        product.product.product_name,
        product.count,
        `Rs ${calculateOfferPrice(product.product, product.offer_id).toFixed(2)} /-`
      ])),
      ['Subtotal', '', `Rs ${subtotal.toFixed(2)} /-`]
    ];

    // Add table to the document
    doc.autoTable({
      head: [tableData.shift()], // Extract and use the first row as the header
      body: tableData,
      startY: 20 // Adjust as needed
    });

    // Save the document
    doc.save('Quotation.pdf');

    // Save data to the server
    await saveQuotation(filteredProducts, offer);
  } catch (error) {
    console.error("Error generating PDF:", error);
    clearStorageAndRedirectToHome()
  }
};



const handleDeleteProduct = (productId) => {
  const user =JSON.parse(localStorage.getItem("userData")) 
  // Find the index of the product with the matching ID
  const index = productAdded.findIndex((product) => product.product._id === productId);
  if (index !== -1) {
    // Remove the product from the UI
    const updatedProductAdded = [...productAdded];
    updatedProductAdded.splice(index, 1);
    setProductAdded(updatedProductAdded);
    // Update local storage
    localStorage.setItem('addedProducts', JSON.stringify(updatedProductAdded));
    const  AllQuantityBreakup = JSON.parse(localStorage.getItem("quantityBreakUp")) || [];
   const RemainingBreakupQuantity=AllQuantityBreakup.filter(p=>p.product_id!==productId && p.customer_id!==user._id )
   localStorage.setItem('quantityBreakUp', JSON.stringify(RemainingBreakupQuantity));
  
  }
};

const hasProductsInCurrentCategory = () => {
  return productAdded.some(product => product.productCategoryID === productCategoryId);
};

const getDisplayUOM = (uom, count) => {
  const countNumber = Number(count);
  // Convert UOM to lowercase and remove special characters for comparison
  let sanitizedUOM = uom.toLowerCase().replace(/[^a-z]/g, '');

  if (countNumber === 1) {
      // Singular form special cases handling
      if (sanitizedUOM === 'nos') {
          return "No";
      } else if (sanitizedUOM === 'bags') {
          return "Bag";
      } else if (sanitizedUOM === 'coils') {
          return "Coil";
      } else if (sanitizedUOM === 'packets') {
          return "Packet";
      }
      // Additional logic for other special cases can be added here
      return uom.replace(/'s$/, ''); // Assuming 's is for plural
  } else {
      // For plural form handling
      if (uom.endsWith('s')) {
          return uom;
      }
      return `${uom}s`;
  }
};


const  handleBack=()=>{
  navigate("/bb/user/offers")
}

const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    if (currentIndex < productAdded.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
function clearStorageAndRedirectToHome() {
  navigate("/bb/ErrorPage");
}

  return (
    <div className="flex justify-center p-2 flex-row">
      <div className="md:w-full lg:w-5/6 sm:w-full">
     {/*  {(productCategoryName === 'Tiles' || productCategoryName === 'UPVC Windows' || productCategoryName === 'Doors')? null : (
      <div className=''>
          <h2 className="text-lg sm:text-[14px] md:text-[20px] text-gray-700 font-semibold py-1 px-2 mt-5">Added Products</h2>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-3 mt-4 overflow-y-auto">
            {productAdded && productAdded.length > 0 ? (
              productAdded.map((selectedProduct, index) => (
                <div key={selectedProduct.product._id} className="border p-4 rounded-lg flex flex-col items-center">
                  <div className="flex w-full justify-end">
                    {productAdded.find(p => p.product._id === selectedProduct.product._id) && (
                      <FaCheckCircle className="text-green-500" />
                    )}
                  </div>
                  <div className="w-40 h-40 overflow-hidden flex items-center justify-center">
                    <img
                      src={ImageUrl + (selectedProduct.product.product_image || 'image unavailable')}
                      alt="Product Image"
                      className="max-w-full max-h-full object-contain"
                    />
                  </div>
                  <div>
                    <h2 className="text-xs font-bold text-center whitespace-normal mt-1">
                      {selectedProduct.product.product_name || 'Product Name Not Available'}
                    </h2>
                    <p className="text-xs font-semibold text-center mt-2">{selectedProduct.product.product_description}</p>
                  </div>
                  <div className="pt-2 flex flex-row space-x-10">
                    <div>
                      <div className="text-xs">
                        <p>Offer Price: ₹{calculatePrice(selectedProduct.product, OffersData.find(p => p.OfferData._id === selectedProduct.offer_id))}</p>
                      </div>
                   
                    </div>
                    <div>
                      <div className="text-xs p-2 flex flex-col">
                        <div className="flex justify-center">
                          <button className="w-5 rounded-sm border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 text-black focus:outline-none"
                            onClick={() => handleDecrement(selectedProduct.product._id)}>-</button>
                          <input
                            type="text"
                            className="border border-gray-300 rounded-sm w-10 text-center focus:outline-none"
                            value={counts[selectedProduct.product._id] || 0}
                            onChange={(e) => handleChange(selectedProduct.product._id, e)}
                          />
                          <button className="w-5 rounded-sm border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 text-black focus:outline-none"
                            onClick={() => handleIncrement(selectedProduct.product._id)}>+</button>
                        </div>
                        <div className="flex justify-center">
                          <button className="text-xs rounded-sm w-10 border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 text-black mt-2"
                            onClick={() => handleAddItem(selectedProduct.product, OffersData.find(p => p.OfferData._id === selectedProduct.offer_id))}>
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No added products</p>
            )}
          </div>
        </div>)} */}
      <div className="flex flex-col lg:flex-row justify-center p-1">
        
  {/* Quotation div for small screens */}
{OffersData.length > 0 && (
  <>
  
    {productAdded.length > 0 && !["Tiles", "UPVC Windows", "Doors"].includes(productCategoryName) && hasProductsInCurrentCategory() && (
      <div className="p-2 rounded-md w-full h-full border lg:hidden flex flex-col relative">
        <div className="rounded-md overflow-x-auto">
          <div className="p-1 border-b flex justify-between flex-row">
            <h1 className="font-semibold">Quotation</h1>
            {OffersData && OffersData.length > 0 && (
              <h1>Total: ₹{ToTaladdedProductsPrice()}</h1>
            )}
          </div>
          <div className="flex flex-wrap justify-center">
            <div className="flex overflow-x-scroll">
  {productAdded
    .filter(selectedProduct => selectedProduct.productCategoryID === productCategoryId)
    .slice()
    .reverse()
    .map((selectedProduct, index) => (
      <div
        key={index}
        className="flex-shrink-0 w-64 mr-4"
      >
        <div className="border mt-2 mb-2 p-2 rounded-lg flex flex-col items-center bg-white">
          <div className="flex w-full justify-end ">
                    <button className="text-red-500 focus:outline-none" onClick={() => handleDeleteProduct(selectedProduct.product._id)}>
                      <FaMinusCircle className="text-xs hover:text-red-500" />
                    </button>
                  </div>
                  <div key={index} className="flex items-center justify-between space-x-2">
                    <div className="w-16 h-16 overflow-hidden flex items-center justify-center">
                      <img
                        src={ImageUrl + (selectedProduct.product.product_image || 'image unavailable')}
                        alt="Product Image"
                        className="max-w-full max-h-full object-contain"
                        onError={(e) => console.log("Error loading image:", e)}
                      />
                    </div>
                    <div className=" flex justify-center items-center flex-col flex-grow">
                      <div className="flex justify-start">
                        <p className="text-sm font-semibold text-left whitespace-normal">{selectedProduct.product.product_name}</p>
                      </div>
                      <div className="flex flex-row justify-between mt-1">
                      <p className="p-1 text-sm">
  <span className="font-semibold">Qty:</span> {selectedProduct.count} {getDisplayUOM(selectedProduct.product.UOM, selectedProduct.count)}
</p>

                        {(() => {
                          const selectedOffer = OffersData?.find(p => p?.OfferData?._id === selectedProduct?.offer_id);
                          if (selectedOffer) {
                            return (
                              <div>
                                {selectedOffer.OfferData.net_price === 0 ? (
                                  <p className="p-1 text-sm">
                                   <span className="font-semibold"> Offer Price: </span> ₹ {calculatePrice(selectedProduct.product, selectedOffer)}</p>
                                ) : (
                                  <p className="p-1 text-sm">
                                    <span className="font-semibold">Offer Price: </span> ₹ {selectedOffer.OfferData ? selectedOffer.OfferData.net_price : 'Price Not Available'}</p>
                                )}
                              </div>
                            );
                          } else {
                            return (
                              <p className="p-1 text-sm">
                                Price: ₹{selectedOffer?.OfferData?.net_price ?? 'Price Not Available'}
                              </p>
                            );
                          }
                        })()}
                      </div>
                      <div className="grid grid-cols-3 gap-1 mt-1">
  {Object.entries(quantityBreakUp.find(p => p.product_id === selectedProduct.product._id)?.break_ups || {}).map(([key, value], index) => (
    (value !== "" && value !== 0 && value !== undefined && value !== null) && (
      <div key={index} className="flex flex-row items-center space-x-1">
        <p className="text-xs font-semibold">{key}:</p>
        <p className="text-xs">{value}</p>
      </div>
    )
  ))}
</div>


                    </div>
                  </div>
                </div>
                </div>
              ))}
              </div>
          </div> {/* Closing grid container */}
         {/*   <div className="flex justify-center px-4 ">
          <button
            className={`absolute top-[110px] -left-0 transform -translate-y-1/2 ${currentIndex === 0 ? 'opacity-30 cursor-not-allowed' : ''}`}
            onClick={prevSlide}
            disabled={currentIndex === 0}
          >
            <FaChevronLeft />
          </button>
          <button
            className={`absolute top-[110px] -right-0 transform -translate-y-1/2 ${currentIndex === productAdded.length - 1 ? 'opacity-30 cursor-not-allowed' : ''}`}
            onClick={nextSlide}
            disabled={currentIndex === productAdded.length - 1}
          >
            <FaChevronRight />
          </button>
        </div>  */}
          
          <div className="flex justify-end space-x-2 px-4">
          {isQuotationSaved ? (
            <button className="rounded-sm border border-gray-500 px-2 text-gray-500" disabled>
            Saved
            </button>
          ) : (
            <button className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black" onClick={HandleSaveClick}>
              Save
            </button>
          )}
            <button className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black" onClick={handleGeneratePDF}>
              Download Quote
            </button>
          </div>
          
        </div>
       
      </div>
      
    )}

  </>
  
)}


<div className="flex justify-center  p-2 flex-row">
<div className="md:w-full sm:w-full">
    <div>
      <hr className="border-gray-400 my-1 mt-5" />
      {OffersData.map((offer, Offerindex) => (
        <div key={Offerindex}>
          <div className="flex">
            {offer.productSubCategory ? (
              <p className="text-lg sm:text-[20px] md:text-[24px] text-gray-700 font-semibold py-1 px-2 mt-2">
                {offer.productSubCategory.product_sub_category_name}
              </p>
            ) : offer.productGroup ? (
              <p className="text-lg sm:text-[20px] md:text-[24px] text-gray-700 font-semibold py-1 px-2 mt-2">
                {offer.productGroup.product_group_name}
              </p>
            ) : null}
          </div>
          
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-3 mt-4 w-full overflow-y-auto">
            {offer.OfferProducts && offer.OfferProducts.length > 0 ? (
              offer.OfferProducts.map((product, productIndex) => (
                <div key={product._id} className="w-full border p-4 rounded-lg flex flex-col items-center">
                  <div className="flex items-center justify-end w-full">
                    <button
                      className="flex justify-end mr-2 text-red-500 focus:outline-none transition-colors duration-300 ease-in-out hover:text-black"
                    >
                      {selectedProductsData.find(p => p.product_id === product._id) ? (
                        <FaHeart className="text-red-500" onClick={() => handleClickRemoveSelection(selectedProductsData.find(p => p.product_id === product._id))} />
                      ) : (
                        <FaRegHeart className="text-red-500" onClick={() => handleAddToWishlist(product.product_category_id, product._id, offer.OfferData._id)} />
                      )}
                    </button>
                  </div>
                  <div className="w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-40 lg:h-40 overflow-hidden flex items-center justify-center">
                    <img
                      src={ImageUrl + (product.product_image || 'image unavailable')}
                      alt="Product Image"
                      className="max-w-full max-h-full object-contain"
                    />
                  </div>
                  <div>
                    <h2 className="text-xs sm:text-sm md:text-base font-bold text-center whitespace-normal ">
                      {product.product_name || 'Product Name Not Available'}
                    </h2>
                    <p className="text-xs sm:text-xs md:text-base font-semibold text-center mt-2">
                      {product.product_description}
                    </p>
                  </div>
                  <div className=" flex flex-col justify-between sm:flex-row w-full">
                    <div className="text-xs sm:text-sm md:text-base text-center sm:text-left w-full">
                      <p className="whitespace-normal text-center font-semibold">Offer Price:</p>
                      <div className="flex flex-col items-center flex justify-center sm:flex-row sm:items-center sm:space-x-1">
                        {offer.OfferData.net_price === 0 ? (
                          <p className="text-center">₹{calculatePrice(product, offer)}</p>
                        ) : (
                          <p>₹{offer.OfferData ? offer.OfferData.net_price : 'Price Not Available'}</p>
                        )}
                        <p className="text-center">/{product.UOM || offer.productGroup.UOM || ''}</p>
                      </div>
                    </div>
                    </div>
                    {productCategoryName === 'Tiles' || productCategoryName === 'UPVC Windows' || productCategoryName === 'Doors' ? null : (
                      <div>
                      <div className="md:text-sm lg:text-sm sm:text-xs p-2 flex flex-row space-x-2">
                        <div className="flex justify-center">
                          <button className="md:w-5 lg:w-5 sm:w-6 rounded-sm border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 sm:px-0 text-black focus:outline-none"
                            onClick={() => handleDecrement(product._id)}>-</button>
                          <input
                            type="text"
                            className="border border-gray-300 rounded-sm md:w-10 lg:w-10 sm:w-8 text-center focus:outline-none"
                            value={counts[product._id] || 0}
                            onChange={(e) => handleChange(product._id, e)}
                          />
                          <button className="md:w-5 lg:w-5 sm:w-6 rounded-sm border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 sm:px-0 text-black focus:outline-none"
                            onClick={() => handleIncrement(product._id)}>+</button>
                        </div>
                        <div className="flex justify-center">
                          <button className="md:text-sm lg:text-sm rounded-sm md:w-10 lg:w-10 sm:w-12 sm:text-[12px] border border-[#EB001B] hover:bg-[#EB001B] hover:text-white px-1 text-black "
                            onClick={() => handleAddItem(product, offer)}>
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                
                </div>
              ))
            ) : (
              <p>No products available</p>
            )}
          </div>
        </div>
      ))}
    </div>
    <div className='flex justify-center mb-4 mt-6 '>
                <button className='rounded-sm text-black hover:text-white border border-[#EB001B] px-4 flex justify-center hover:bg-[#EB001B] ' onClick={handleBack} >Back</button>
                </div>
     
  </div>
  
 </div>

  {/* Quotation div for large screens */}
  {OffersData.length > 0 && (
    <>
      {productAdded.length > 0 && !["Tiles", "UPVC Windows", "Doors"].includes(productCategoryName) && hasProductsInCurrentCategory() && (
         <div className="hidden lg:block p-2 lg:ml-2 rounded-md w-full border md:w-2/5 lg:w-2/5 flex flex-col lg:flex-row">
         <div className="rounded-md overflow-y-auto h-screen">
           <div className="p-1 border-b flex justify-between flex-row">
            <h1 className="font-semibold">Quotation</h1>
            {OffersData && OffersData.length > 0 && (
              <h1>Total: ₹{ToTaladdedProductsPrice()}</h1>
            )}
          </div>
          {productAdded
            .filter(selectedProduct => selectedProduct.productCategoryID === productCategoryId)
            .slice()
            .reverse()
            .map((selectedProduct, index) => (
              <div className="hover:bg-red-100 flex flex-col" key={index}>
                <div className="flex justify-end mt-1">
                  <button className="text-red-500 focus:outline-none" onClick={() => handleDeleteProduct(selectedProduct.product._id)}>
                    <FaMinusCircle className="text-xs hover:text-red-500" />
                  </button>
                </div>
                <div key={index} className="flex items-center justify-between p-2 border-b">
                  <div className="w-20 h-20 overflow-hidden flex items-center justify-center">
                    <img
                      src={ImageUrl + (selectedProduct.product.product_image || 'image unavailable')}
                      alt="Product Image"
                      className="max-w-full max-h-full object-contain"
                      onError={(e) => console.log("Error loading image:", e)}
                    />
                  </div>
                  <div className="ml-4 flex flex-col">
                    <div className="flex justify-start">
                      <p className="text-xs font-semibold text-left whitespace-normal">{selectedProduct.product.product_name}</p>
                    </div>
                    <div className="flex flex-row justify-between">
                      <p className="p-1 text-sm">
                        Qty: {selectedProduct.count} {getDisplayUOM(selectedProduct.product.UOM, selectedProduct.count)}
                      </p>
                      {(() => {
                        const selectedOffer = OffersData?.find(p => p?.OfferData?._id === selectedProduct?.offer_id);
                        if (selectedOffer) {
                          return (
                            <div>
                              {selectedOffer.OfferData.net_price === 0 ? (
                                <p>Offer Price: ₹{calculatePrice(selectedProduct.product, selectedOffer)}</p>
                              ) : (
                                <p>Offer Price: ₹{selectedOffer.OfferData ? selectedOffer.OfferData.net_price : 'Price Not Available'}</p>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <p className="p-1 text-sm">
                              Price: ₹{selectedOffer?.OfferData?.net_price ?? 'Price Not Available'}
                            </p>
                          );
                        }
                      })()}
                    </div>
                    <div className="grid grid-cols-4 gap-1 mt-1">
                      {Object.entries(quantityBreakUp.find(p => p.product_id === selectedProduct.product._id)?.break_ups || {}).map(([key, value], index) => (
                        (value !== "" && value !== 0 && value !== undefined && value !== null) && (
                          <div key={index} className="flex flex-row">
                            <p className="text-xs">{key}=</p>
                            <p className="text-xs">{value}</p>
                          </div>
                        )
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div className="flex justify-between px-4 py-2">

                      {isQuotationSaved ? (
            <button className="rounded-sm border border-gray-500 px-2 text-gray-500" disabled>
            Saved
            </button>
          ) : (
            <button className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black" onClick={HandleSaveClick}>
              Save
            </button>
          )}
            <button className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-xs" onClick={handleGeneratePDF}>
              Download Quote
            </button>
          </div>
        </div>
        </div>
      )}
    </>
  )}
</div>

 {showWirePopup && (
        <WireColorPopup
          onClose={() => setShowWirePopup(false)}
          onSubmit={handleWirePopupSubmit}
          BreakUps={breakUp}    
        />
      )}

       {/* Projects Popup */}
       {Popup && (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
    <div className="bg-white p-6 rounded-md shadow-lg">
      <h2 className="text-lg font-bold mb-2">Select the Project(s)</h2>
      <ul>
        {projects.map((project) => (
          <li key={project._id}>
            <input
              type="checkbox"
              id={project._id}
              value={project.projectName}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedProjectIds((prevIds) => [...prevIds, project._id]);
                } else {
                  setSelectedProjectIds((prevIds) => prevIds.filter((id) => id!== project._id));
                }
              }}
            />
            <label htmlFor={project._id}>{project.projectName}</label>
          </li>
        ))}
      </ul>
      <div className='flex justify-end'>
        <button
          className="mr-2 border border-[#EB001B] hover:bg-[#EB001B] text-black hover:text-white text-md rounded-sm px-2 py-1"
          onClick={() => handleSubmitPopup(selectedProjectIds)}
        >
          Submit
        </button>
        <button
          className="border border-[#EB001B] hover:bg-[#EB001B] text-black hover:text-white text-md rounded-sm px-2 py-1"
          onClick={handleClosePopup}
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}

    </div>
    </div>
    
  );
};
export default Products;
