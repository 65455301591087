import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Paper,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { Visibility, FilterList } from "@mui/icons-material";

const MyOrders = () => {
  const navigate = useNavigate();
  const [CustomerOrders, setCustomerOrders] = useState([]);
  const [customer, setCustomerData] = useState(null);
  const [orderLoading, setOrderLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState([
    { id: "order_id", label: "Order ID", checked: true },
    { id: "order_time", label: "Order Date", checked: true },
    { id: "product_category_name", label: "Product Category", checked: true },
    { id: "order_status", label: "Order Status", checked: true },
    { id: "actions", label: "Actions", checked: true },
  ]);

  // State for sorting
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setCustomerData(userData);

    const fetchCustomerOrders = async () => {
      const consumer_id = userData._id; // Assuming _id is the customer id
      const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/Quotation-Order/get-all-customer-quotation-Orders-data?customer_id=${consumer_id}`;

      try {
        const token = sessionStorage.getItem("token");
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-token": token,
          },
        });

        if (!response.ok) {
          const responseData = await response.json();
          setMessage(responseData.message);
          console.log("Message:", responseData.message);
          // Handle error scenario
          return;
        }

        const data = await response.json();
        setOrderLoading(false);
        setCustomerOrders(data.orders);
        console.log(data.orders);
      } catch (error) {
        console.error("Error fetching orders:", error);
        // Handle error scenario
      }
    };

    fetchCustomerOrders();
  }, []);

  const handleNavigateToOrderDetails = (quotationid, productCategoryName) => {
    const navigateToOrderDetails =
      productCategoryName === "Tiles" ||
      productCategoryName === "UPVC Windows" ||
      productCategoryName === "Doors"
        ? "/bb/user/OrderDetails2"
        : "/bb/user/orderDetails";

    navigate(navigateToOrderDetails, {
      state: {
      quotationid,
      productCategoryName,
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };
  

  const handleColumnToggle = (columnId) => {
    const updatedColumns = visibleColumns.map((column) => {
      if (column.id === columnId) {
        return { ...column, checked: !column.checked };
      }
      return column;
    });
    setVisibleColumns(updatedColumns);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Sorting functions
  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const sortedOrders = stableSort(CustomerOrders, getComparator(sortOrder, sortBy));

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      return order !== 0 ? order : a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, sortBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, sortBy)
      : (a, b) => -descendingComparator(a, b, sortBy);
  }

  function descendingComparator(a, b, sortBy) {
    if (b[sortBy] < a[sortBy]) {
      return -1;
    }
    if (b[sortBy] > a[sortBy]) {
      return 1;
    }
    return 0;
  }

  // Filtered orders based on search term
  const filteredOrders = sortedOrders.filter((order) => {
    const { orderData, productCategory } = order;
    if (!orderData || !productCategory) return false; // Safety check
  
    const searchLower = searchTerm.toLowerCase();
    const orderDateStr = new Date(orderData.order_time).toLocaleDateString();
  
    return (
      (orderData.order_id && orderData.order_id.toLowerCase().includes(searchLower)) ||
      (productCategory.product_category_name && productCategory.product_category_name.toLowerCase().includes(searchLower)) ||
      (orderData.order_status && orderData.order_status.toString().toLowerCase().includes(searchLower)) ||
      (orderDateStr && orderDateStr.includes(searchLower))
    );
  });
  

  // Function to map order status to display text
  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Order Placed";
      case 2:
        return "Order Approved";
      case 3:
        return "Order In Process";
      case 4:
        return "Order Dispatched";
      default:
        return "Unknown Status";
    }
  };

  return (
    <div className="flex flex-col items-center h-full lg:ml-40 lg:mt-10 lg:mr-40 mx-4 mt-4 relative">
  <div className="mb-6">
        <h2 className="text-2xl md:text-3xl xl:text-4xl font-bold text-gray-900">Orders</h2>
      </div>
  <Box className="w-full mb-4 md:flex md:flex-wrap lg:flex-nowrap md:justify-between md:items-center sm:flex sm:justify-center sm:space-x-2">
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          size="small"
          className="mr-2 mb-2 lg:mb-0 flex-1 max-w-md"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleMenuOpen}
          startIcon={<FilterList />}
          sx={{ backgroundColor: '#EB001B', color: '#ffffff' }} // Example hex colors
           className="mb-2 lg:mb-0 lg:ml-2"
        >
          Columns
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <FormGroup>
            {visibleColumns.map((column) => (
              <MenuItem key={column.id}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={column.checked}
                      onChange={() => handleColumnToggle(column.id)}
                    />
                  }
                  label={column.label}
                />
              </MenuItem>
            ))}
          </FormGroup>
        </Menu>
      </Box>
      <TableContainer component={Paper} sx={{ padding: "20px" }} className="w-full overflow-x-auto mt-4">
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
              {visibleColumns.map((column) => {
                if (column.checked) {
                  return (
                    <TableCell key={column.id}>
                      <TableSortLabel
                        active={sortBy === column.id}
                        direction={sortBy === column.id ? sortOrder : "asc"}
                        onClick={() => handleRequestSort(column.id)}
                        style={{ color: '#EB001B' }} // Replace with your hex code for text color
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  );
                }
                return null;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOrders
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((order) => (
                <TableRow key={order.orderData._id} hover>
                  {visibleColumns.map((column) => {
                    if (column.checked) {
                      switch (column.id) {
                        case "actions":
                          return (
                            <TableCell key={column.id}>
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  handleNavigateToOrderDetails(order.orderData.quotation_ids, order.productCategory.product_category_name)
                                }
                                style={{
                                  color: '#EB001B',  // Replace with your hex code for text color
                                  borderColor: '#EB001B'  // Replace with your hex code for border color
                                }}
                              >
                                View Details
                              </Button>
                            </TableCell>
                          );
                        case "order_status":
                          return (
                            <TableCell key={column.id}>
                              {getStatusText(order.orderData.order_status)}
                            </TableCell>
                          );
                        default:
                          return (
                            <TableCell key={column.id}>
                              {column.id === "order_time"
                                ? new Date(order.orderData.order_time).toLocaleDateString()
                                : column.id === "product_category_name"
                                ? order.productCategory.product_category_name
                                : order.orderData[column.id]}
                            </TableCell>
                          );
                      }
                    }
                    return null;
                  })}
                </TableRow>
              ))}
            {filteredOrders.length === 0 && (
              <TableRow>
                <TableCell colSpan={visibleColumns.length}>
                  <Typography variant="body1" align="center">
                    No orders found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {filteredOrders.length > rowsPerPage && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredOrders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {orderLoading && (
        <Box className="absolute inset-0 flex items-center justify-center">
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default MyOrders;
