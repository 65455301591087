import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ContactUs = ({ onClose }) => {

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose(); // Close the popup when clicking outside of it
        }
    };
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75" onClick={handleOutsideClick}>
            <div className="bg-white rounded-lg px-8 py-8 max-w-xl w-full relative">
            <button className="absolute top-2 right-2 text-gray-600" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <h2 className="text-xl font-semibold mb-4">Contact Us</h2>
                {/* Add your contact form or content here */}
                
                <div className="flex justify-center items-center h-full">
      <div className="border border-gray-300 rounded p-4 bg-white">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label htmlFor="projectName" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Project Name:</label>
            <input type="text" id="projectName" name="projectName" className="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-1 px-2 mb-3 leading-tight focus:outline-none focus:border-blue-500" />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label htmlFor="builderName" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Builder Name:</label>
            <input type="text" id="builderName" name="builderName" className="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-1 px-2 leading-tight focus:outline-none focus:border-blue-500" />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label htmlFor="builderContact" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Builder Contact No:</label>
            <input type="text" id="builderContact" name="builderContact" className="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-1 px-2 mb-3 leading-tight focus:outline-none focus:border-blue-500" />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label htmlFor="dateTime" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Time and Date:</label>
            <input type="datetime-local" id="dateTime" name="dateTime" className="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-1 px-2 leading-tight focus:outline-none focus:border-blue-500" />
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <button type="submit" className="bg-white text-gray-700 font-bold py-1 px-2 rounded focus:outline-none border border-gray-400">Submit</button>
        </div>
      </div>
    </div>
            </div>
        </div>
   
  );
};

export default ContactUs;
