import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faTimes } from "@fortawesome/free-solid-svg-icons";

function View_Consumers() {
    const [projects, setProjects] = useState([]);
    const [selectedDetails, setselectedDetails] = useState(null);
    const [allFlatDetails, setallFlatDetails] = useState(null);
    const [selectedBlockDetails, setselectedBlockDetails] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [projectId, setProjectId] = useState(null); 
    const [blocksData, setBlocksData] = useState([]);
    const [blockIds, setBlockIds] = useState([]);
    const [CustomersData,setCustomersData]= useState({});
    const [Items, setItems] = useState(false);
    const [displayedImage, setDisplayedImage] = useState(false);
    const [customer, setCustomer] = useState();
    const [image, setImage] = useState();
    const [CreatedmaterialAlert, setCreatedmaterialAlert] = useState(false);
    const [AllCustomers,setAllCustomers] = useState([]);
    const [AllProjects,setAllProjects]= useState([])
    const [CustomerStatus , setCustomerStatus] = useState(false);
    const [customers, setCustomers] = useState([]); // State to store fetched customers
    const [isActive, setIsActive] = useState(false);
    const [isDeactive , setIsDeactive] = useState(false);
    const navigate = useNavigate();
    const [CustomerId,setCustomerId] =useState('');
const ActivateCustomer = process.env.REACT_APP_FORE_BRICKBUCKET + "/customer/activate-customer";
const DeactivateCustomer = process.env.REACT_APP_FORE_BRICKBUCKET + "/customer/deactivate-customer";


    // Add functions for handling activate and deactivate
const handleActivate = async (customerid) => {
    try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(ActivateCustomer ,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                },
                body: JSON.stringify({
                    _id : customerid,
                  }),
                }
                );

        if (response.ok) {
            setCustomerStatus(true);
            setCustomerStatus("Customer Activated Successfully");
            const updatedCustomers = await fetchUpdatedCustomerData(); // Replace with your actual function
            setCustomers(updatedCustomers);
            setIsActive(false);
            setIsDeactive(true);
        } else {
            // Handle error
            console.error('Failed to activate customer:', response.status, response.statusText);
        }
    } catch (error) {
        // Handle error
        console.error('Error during API call:', error);
    }

};

const handleDeactivate = async (customerid) => {
    try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(DeactivateCustomer,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                },
                body: JSON.stringify({
                    _id : customerid,
                  }),
            }
        );
        if (response.ok) {
            setCustomerStatus(true);
            setCustomerStatus("Customer Deactivated Successfully");
            const updatedCustomers = await fetchUpdatedCustomerData(); // Replace with your actual function
            setCustomers(updatedCustomers);
            setIsActive(true);
            setIsDeactive(false);
        } else {
            // Handle error
            console.error('Failed to deactivate customer:', response.status, response.statusText);
        }
    } catch (error) {
        // Handle error
        console.error('Error during API call:', error);
    }
};

const fetchUpdatedCustomerData = async () => {
    try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "/customer/get-all-customers", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-token':token

            }
        });
        const data = await response.json();
        if (response.ok) {
            return data.allCustomers; 
        } else {
            // Handle error
            console.error(data.message);
            return []; // Return an empty array if there's an error
        }
    } catch (error) {
        console.error(error);
        return []; // Return an empty array if there's an error
    }
};

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const response = await fetch(process.env.REACT_APP_FORE_BRICKBUCKET + "/customer/get-all-customers", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-token':token
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    setCustomers(data.allCustomers); // Update customers state
                } else {
                    console.error(data.message);
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchCustomers();
    }, []);

    // Helper function to display customer status based on the value
function getCustomerStatus(status) {
    switch (status) {
        case 1:
            return 'Pending';
        case 2:
            return 'Deactivated';
        case 3:
            return 'Activated';
        default:
            return 'Unknown';
    }
}
    useEffect(() => {
        const fetchProjectDetails = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const response = await fetch(
                    process.env.REACT_APP_FORE_APILINK + "/projects/get-all-projects",
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'x-token': token
                        },
                    }
                );
                if (response.ok) {
                    const projectDetails = await response.json();
                    setProjects(projectDetails);
                } else {
                    console.error('Failed to fetch project details');
                }
            } catch (error) {
                clearStorageAndRedirectToHome();
                console.error('Error during API call:', error);
            }
        };

        fetchProjectDetails();
    }, []);

      
  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/bb/admin';
  }
/*  useEffect(()=>{
    GetAllCustomersAndTheirProjects()
 },[]) */
 
  // Group projects by builder name and contact number
  useEffect(() => {
    const grouped = projects.reduce((groups, project) => {
      const key = `${project.customer_id}`;
      if (!groups[key]) {
          groups[key] = [];
      }
      groups[key].push(project);
      return groups;
  }, {});
  setCustomersData(grouped); 
}, [projects]);

    const handleImageClick = async (projectDetails) => {
        setselectedDetails(projectDetails);
        setShowPopup(true);
        // Set the projectId here
        setProjectId(projectDetails._id);
        
        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch(
                `${process.env.REACT_APP_FORE_APILINK}/blocks/get-all-blocks-of-project?projectId=${projectDetails._id}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'x-token':token,
                    },
                }
            );
            if (response.ok) {
                const blockData = await response.json();
                setselectedBlockDetails(blockData);
                
                // Here you can access the block IDs and store them in a state
                if (blockData.length > 0) {
                    const blockIds = blockData.map(block => block._id);
                    setBlockIds(blockIds);
                    
                }
            } else {
                console.error('Failed to fetch block data from the backend:', response.status, response.statusText);
                // Handle errors or display an error message to the user
            }
        } catch (error) {
            clearStorageAndRedirectToHome();
            console.error('Error during API call:', error);
        }
        fetchFlatDetails();
    };
    
    

    const handleClosePopup = () => {
        setShowPopup(false);
    }

  
    
    
    useEffect(() => {
        fetchFlatDetails();
    }, [blockIds]);
    
    const fetchFlatDetails = async () => {
        try {
            
            const token = sessionStorage.getItem('token');
            const promises = blockIds.map(async (blockId) => {
                const response = await fetch(
                    process.env.REACT_APP_FORE_APILINK + `/flats/get-all-flats-of-block?blockId=${blockId}`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'x-token' : token
                        },
                    }
                );
    
                if (response.ok) {
                    return response.json();
                } else {
                    console.error(`Failed to fetch flat details for block ${blockId}: ${response.status} - ${response.statusText}`)
                    //throw new Error(`Failed to fetch flat details for block ${blockId}: ${response.status} - ${response.statusText}`);
                }
            });
            const results = await Promise.all(promises);
            const allFlatDetails = results.reduce((acc, flatDetails) => acc.concat(flatDetails), []);
            setallFlatDetails(allFlatDetails);
        } catch (error) {
            clearStorageAndRedirectToHome();
            console.error('Error during API call:', error.message);
            // Handle error
        }
    };
    
    
  
const handleQuotations = (customer_id) => {
    //fetchCustomerOfTheProjectForQuotations(data[0].builderContactNo)
    console.log(customer_id)
    navigate('/bb/admin/AdminHome/UserQuotations',{ state: { customer_id:customer_id } });
}

/* const fetchCustomerOfTheProjectForQuotations = async (mobileNumber) => {
    
    const CustomerUrl = process.env.REACT_APP_FORE_BRICKBUCKET + `/customer/get-customer-data-by-mobileNumber?mobile_number=${mobileNumber}`;
    const token = sessionStorage.getItem('token');
    try {
        const response = await fetch(CustomerUrl, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'x-token' : token
            }
        });

        if (response.ok) {
            const data = await response.json();
          
            navigate('/bb/admin/AdminHome/UserQuotations',{ state: { customer_id:data.customer._id } });
          
        } else {
            // Handle non-OK response
            console.error('Failed to fetch customer data:', response.status, response.statusText);
        }
    } catch (error) {
        // Handle fetch error
        console.error('Error fetching customer data:', error);
    }
}; */

const handleOrders=(customer_id)=>{
    navigate('/bb/admin/AdminHome/customerOrders', { state: { customer_id:customer_id } });
    //fetchCustomerOfTheProject(data[0].builderContactNo)
    
}


const handleOpenItems = (customer_id) => {
setItems(true);
setCustomerId(customer_id)
}

const handleClose = () => {
    setItems(false);
    setCustomerId('')
}

/* const fetchCustomerOfTheProject = async (mobileNumber) => {
    
    const CustomerUrl = process.env.REACT_APP_FORE_BRICKBUCKET + `/customer/get-customer-data-by-mobileNumber?mobile_number=${mobileNumber}`;
    const token = sessionStorage.getItem('token');
    try {
        const response = await fetch(CustomerUrl, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'x-token' : token
            }
        });

        if (response.ok) {
            const data = await response.json();
            navigate('/bb/admin/AdminHome/customerOrders', { state: { customer_id:data.customer._id } });
        } else {
            // Handle non-OK response
            console.error('Failed to fetch customer data:', response.status, response.statusText);
        }
    } catch (error) {
        // Handle fetch error
        console.error('Error fetching customer data:', error);
    }
}; */

 
const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
    // No file selected, do something (e.g., show an error message or return early)
    return;
  }
    setImage(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setDisplayedImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleCreate = async () => {

    const customer_id = CustomerId;

    try {
       
        const formData = new FormData();
        formData.append('customer_id', customer_id);

        // Check if an image is selected and add it to formData
        if (image) {
          formData.append('image', image);
          
        }
       
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/material-requirement/create-customer-material-requirement`, {
          method: 'POST',
          headers: {
            //'Content-Type': 'application/json',
            'x-token': token,
          },
          body: formData,
        });
    
        if (response.ok) {
          setCreatedmaterialAlert('material image has been created successfully!');
          setItems(false);
          // You might want to navigate the user to another page or perform other actions here
        } else {
          const errorMessage = await response.text();
          console.error(`Failed to create material: ${errorMessage}`)
         // throw new Error(`Failed to create product: ${errorMessage}`);
        }
      } catch (error) {
        console.error('Error creating material:', error.message);
        clearStorageAndRedirectToHome()
        // Display an error message to the user or handle it in another appropriate way
        //setSubmitCreateproductAlert(`Error creating product: ${error.message}`);
      }
    
  }

  const handleCancel = () => {
    setItems(false);
    setDisplayedImage(null); // Resetting the displayed image state
  }

  const handleSubmitCreatedmatrialAlert = () => {
   // setCreatedmaterialAlert(false);
    handleCreate();
  }

  const handleCloseMaterialAlert = () => {
    setCreatedmaterialAlert(false);
    // Set Items to false after the alert is closed
    setItems(false);
  }


  /*   const GetAllCustomersAndTheirProjects = async ()=>{
        const CustomerUrl = process.env.REACT_APP_FORE_BRICKBUCKET + `/customer/ get-all-customers-and-their-projects`;
       // const token = sessionStorage.getItem('token');
        try {
            const response = await fetch(CustomerUrl, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                   // 'x-token' : token
                }
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log(data.customersAndProjects);
                // navigate('/bb/admin/AdminHome/customerOrders', { state: { customer_id:data.customer._id } }); 
                setAllCustomers(data.customersAndProjects.allCustomers)
                setAllProjects(data.allProjects)
            } else {
                
                console.error('Failed to fetch customer data:', response.status, response.statusText);
            }
        } catch (error) {
            
            console.error('Error fetching customer data:', error);
        }
    }
 */
    
    const handleCloseCustomerstatuspopup = () => {
        setCustomerStatus(false);
      }
      

    return (
        <div className="w-full h-full mx-auto overflow-y-scroll no-scrollbar">
            <div className=" max-w-screen-xl mx-auto p-4">
                <div className="bg-white flex flex-col relative rounded-md  overflow-x-auto">
                    {/* <div>
                        <h1 className="text-2xl font-bold text-center">View Consumers</h1>
                    </div> */}
                       <div className="overflow-x-auto">
                       {customers.length > 0 ? (
                            <table className="w-full border-b border-gray-300 mt-4">
                                <thead className="border-b border-gray-300">
                                    <tr className="bg-[#D9D9DD] bg-opacity-[100%]">
                                        <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center">Customer Name</th>
                                        <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center">Company Name</th>
                                        <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center">Mobile</th>
                                        <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center">Projects</th>
                                        <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center">View</th>
                                        <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center">Status</th>
                                        <th className="text-blue-950 text-opacity-90 font-semibold py-2 px-2 text-sm text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
        {customers.map((customer, customerIndex) => {
          const customerProjects = CustomersData[customer._id] || [];
         
          return (
            <tr key={customerIndex} className="bg-white border-b">
              <td className="text-blue-950 text-opacity-80 py-2 text-sm px-2 text-center">{customer.customer_name}</td>
              <td className="text-blue-950 text-opacity-80 py-2 text-sm px-2 text-center">{customer.company_name}</td>
              <td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">{customer.mobile_number}</td>
              <td className="text-blue-950 text-opacity-80 py-2 text-sm px-2 text-center flex items-center justify-center cursor-pointer">
                {customer._id === CustomersData[customer._id]?.[0]?.customer_id && customerProjects.map((project, projectIndex) => (
                  <img 
                    key={projectIndex}
                    src={process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL + project.projectImage[project.projectImage.length - 1]} 
                    alt="Project" 
                    className="m-1 h-8 w-8 rounded-full" 
                    onClick={() => handleImageClick(project)} 
                  />
                ))}
              </td>
              <td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">
                {customer._id === CustomersData[customer._id]?.[0]?.customer_id && (
                  <div className="flex flex-row space-x-2 w-full justify-center items-center">
                    <p className="text-red-400 hover:underline cursor-pointer" onClick={() => handleQuotations(customer._id)}> Quotations</p>
                    <p className="text-red-400 hover:underline cursor-pointer" onClick={() => handleOrders(customer._id)}> Orders</p>
                    <p className="text-red-400 hover:underline cursor-pointer break-words hyphens-auto" onClick={() => handleOpenItems(customer._id)}> Upload Materials Reqs</p>
                  </div>
                )}
              </td>
              <td className="text-blue-950 text-opacity-80 py-2 text-xs px-2 text-center">{getCustomerStatus(customer.customer_status)}</td>
              <td className="text-blue-950 text-opacity-90 py-2 text-sm px-2 text-center">
                <div className="flex flex-row space-x-4 w-full justify-center items-center">
                  {customer.customer_status === 1 || customer.customer_status === 3 ? (
                    <button
                      className="px-2 py-1 text-xs rounded-lg text-white bg-red-500 hover:bg-red-600"
                      onClick={() => handleDeactivate(customer._id)}
                    >
                      Deactivate
                    </button>
                  ) : null}
                  {customer.customer_status === 2 ? (
                    <button
                      className="px-2 py-1 rounded-lg text-xs text-white bg-green-500 hover:bg-green-600"
                      onClick={() => handleActivate(customer._id)}
                    >
                      Activate
                    </button>
                  ) : null}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
                            </table>
                        ) : (
                            <p className="text-center">No consumers available</p>
                        )}
                    </div>
                </div>
                {Items && (
  <div className="fixed inset-0 z-40 flex items-center justify-center">
    <div className="absolute inset-0 bg-black opacity-30"></div>
    <div className="bg-white p-10 rounded-md shadow-md relative z-50 flex flex-col items-center">
      <button className="absolute top-0 right-0 m-2" onClick={handleClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <h1 className="text-xl font-bold text-center mb-4">Upload Materials</h1>
      <div className="max-h-96 flex overflow-y-scroll no-scrollbar">
        {/* Upload Image section */}
        <div className="flex flex-col w-full items-center">
         {/*  <div className="border border-gray-300 w-full mb-4"></div> */}
          <div className="border border-gray-300 p-4 mb-4">
            {!displayedImage && (
              <div className="flex justify-center">
                <FontAwesomeIcon icon={faCamera} className="text-gray-400 text-6xl" />
              </div>
            )}
            {displayedImage && (
              <div className="flex justify-center">
                <img src={displayedImage} alt="Selected Image" className="max-w-full border border-gray-200 max-h-32" />
              </div>
            )}
          </div>
          <label htmlFor="fileInput" className="mt-3 cursor-pointer text-md font-medium text-gray-700 flex justify-center px-3 py-1 border border-1 border-gray-300 hover:border-[#EB001B] bg-white rounded-sm">
            <FontAwesomeIcon icon={faCamera} className="text-gray-600 mr-1 mt-1" />
            Upload Image
          </label>
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
          />
        </div>
      </div>
      <div className='flex flex-row items-center justify-center space-x-8 mt-2'>
        <button className='px-6 py-1 border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white' onClick={() => handleSubmitCreatedmatrialAlert()}>
          Create
        </button>
        <button className='px-6 py-1 border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white' onClick={() => handleCancel()}>
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

{CreatedmaterialAlert && (
 <div className="fixed z-30 inset-0 flex items-center justify-center">
 {/* Background overlay (blurred) */}
 <div className="absolute inset-0 bg-black opacity-30 "></div>

   <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
   <p className="text-lg font-semibold mb-2">{CreatedmaterialAlert}</p>
    <button  className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
     onClick={handleCloseMaterialAlert}>OK</button>
   </div>
 </div>
)}

{CustomerStatus && (
 <div className="fixed z-30 inset-0 flex items-center justify-center">
 {/* Background overlay (blurred) */}
 <div className="absolute inset-0 bg-black opacity-30 "></div>

   <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
   <p className="text-lg font-semibold mb-2">{CustomerStatus}</p>
    <button  className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4"
     onClick={handleCloseCustomerstatuspopup}>OK</button>
   </div>
 </div>
)}




                {showPopup && selectedDetails && (
                   
   <div className="fixed inset-0 z-40 flex items-center justify-center">
        <div className="absolute inset-0 bg-black opacity-30"></div>
        <div className="bg-white p-10 rounded-md shadow-md relative z-50 flex flex-col items-center">
            <button className="absolute top-0 right-0 m-2" onClick={handleClosePopup}><FontAwesomeIcon icon={faTimes} /></button>
            <h1 className="text-xl font-bold text-center mb-4">{selectedDetails.projectName}</h1>
            <div className="max-h-96 flex overflow-y-scroll no-scrollbar ">
                
            {/* Popup content goes here */}
        
            <table className="w-full">
                <tbody>
                <div className='border border-gray-400 p-4 relative mt-8'> 
          <p className="text-md font-bold text-gray-700 mb-2 absolute -top-4 bg-white px-2">Site Details</p>   
                    <tr>
                        <td className="font-semibold pr-4">Site Contact Name:</td>
                        <td className='pl-10'>{selectedDetails.siteContactName}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold pr-4">Site Contact Number :</td>
                        <td className='pl-10'>{selectedDetails.siteContactNo}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold pr-4">Site Address :</td>
                        <td className='pl-10'>{selectedDetails.siteAddress}</td>
                    </tr>
                    </div>
                    {selectedBlockDetails && selectedBlockDetails.map((block, index) => (
                        <React.Fragment key={index}>
                            <div className='border border-gray-400 p-4 relative mt-8'> 
          <p className="text-md text-gray-700 mb-2 font-bold absolute -top-4 bg-white px-2" key={`block_${index}`}>Block {block.blockNo}</p>   
                            {/* <tr>
                                <td colSpan="2" className="text-center font-bold py-2" key={`block_${index}`}>
                                    Block {block.blockNo}
                                </td>
                            </tr> */}
                            <tr>
                                <td className="font-semibold pr-4">Number of Floors :</td>
                                <td className='pl-10'>{block.numFloors}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold pr-4">Number of flats per floor :</td>
                                <td className='pl-10'>{block.numFlatsPerFloor}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold pr-4">Total number of flats :</td>
                                <td className='pl-10'>{block.numFlats}</td>
                            </tr>
                            {selectedDetails && allFlatDetails && selectedDetails._id === block.projectId && (
    <tbody >
        {allFlatDetails
            .filter(flat => flat.blockId === block._id) // Filter flats for the current block
            .map(flat => (
                <React.Fragment key={flat._id}>
                    <tr >
                        <td className="font-bold py-2">Flat Number : {flat.flatNo}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold pr-4">Facing :</td>
                        <td className='pl-10'>{flat.facing}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold pr-4">Plinth Area :</td>
                        <td className='pl-10'>{flat.plinthArea} sq.ft</td>
                    </tr>
                    <tr>
                        <td className="font-semibold pr-4">Type :</td>
                        <td className='pl-10'>{flat.type}</td>
                    </tr>
                </React.Fragment>
            ))}
    </tbody>
)}

</div>

                        </React.Fragment>
                    ))}

                   
         
                </tbody>
            </table>
            </div>
        </div>
        </div>
    
)}

            </div>
        </div>
    );
}

export default View_Consumers;


/*  `${process.env.REACT_APP_FORE_APILINK}/flats/get-all-flats-of-block?blockId=${blockId}` */