
import React, { useState, useEffect } from 'react';
import BankDetailsModal from './VendorBankDetailsModal';
import { useLocation,useNavigate } from 'react-router-dom';


function VendorProfile() {

    const navigate = useNavigate();

    const location = useLocation();
    const { vendor } = location.state;
    const vendorId = vendor._id;
    const [vendorName, setVendorName] = useState( '');
    const [gstinNumber, setGstinNumber] = useState( '');
    const [panNumber, setPanNumber] = useState( '');
    const [address, setAddress] = useState( '');
    const [state, setState] = useState( '');
    const [city, setCity] = useState( '');
    const [pincode, setPincode] = useState( '');
    const [companyName,setCompanyName]=useState( '')

    const [isBankDetailsOpen, setIsBankDetailsOpen] = useState(false);
    const [UpdateVendorProfileAlert, setUpdateVendorProfileAlert] = useState(false);
    const [VendorData,setVendorData]= useState();
   
   
 useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
        navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })
  
    const toggleBankDetailsModal = () => {
      setIsBankDetailsOpen(!isBankDetailsOpen);
    };

    function clearStorageAndRedirectToHome() {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/bb/admin';
      }


    const [assignedCategories, setAssignedCategories] = useState([]);
    useEffect(() => {

        const getAllProductCategoriesAssignedToVendor = async () => {
          try {
            const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/get-productCategoriesBy-vendor_id?vendor_id=${vendorId}`;
            const token = sessionStorage.getItem('token');
            const response = await fetch(url,{
                method:'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token' : token
                },
            });
            if (response.ok) {
                const responseData = await response.json();

                setAssignedCategories(responseData);
            }else{
                console.error('Failed to fetch product categories assigned to vendor')
                //throw new Error('Failed to fetch product categories assigned to vendor');
            }
            
          } catch (error) {
            clearStorageAndRedirectToHome()
          }
        };
    
      
        getAllProductCategoriesAssignedToVendor();
      }, []);

      useEffect(() => {
     getVendorProfile(vendor._id);

      }, [vendor]);

      const getVendorProfile = async (vendor_id) => {
        const getVendor = process.env.REACT_APP_FORE_BRICKBUCKET + `/Admin/get-vendor?vendor_id=${vendor_id}`;
        try {
            const token = sessionStorage.getItem('token');
          const response = await fetch(getVendor, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
               'x-token' : token
            },
          });
      
          if (response.ok) {
            const data = await response.json();
            setVendorData(data)
            // Process the fetched data here
           setVendorName(data.name||'');
           setCompanyName(data.company_name||'');
           setAddress(data.address||'')        
            setGstinNumber(data.gstin || '');
            setPanNumber(data.pan || '');
            
            setState(data.state || '');
            setCity(data.city || '');
            setPincode(data.pincode || '');
    
          } else {
            console.error('Failed to fetch vendor data')
           // throw new Error('Failed to fetch vendor data');
          }
        } catch (error) {
            clearStorageAndRedirectToHome()
          console.error('Error fetching vendor data:', error);
          // Handle error
          return null;
        }
      }

      function validateGSTIN(gstin) {
        const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        
        if (gstinRegex.test(gstin)) {
            return true;
        } else {
            return false;
        }
    }
    
    function validatePAN(pan) {
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      
      if (panRegex.test(pan)) {
          return true;
      } else {
          return false;
      }
    }
    
    
    
    function validatePINCode(pinCode) {
      const pinCodeRegex = /^[1-9][0-9]{5}$/;
      
      if (pinCodeRegex.test(pinCode)) {
          return true;
      } else {
          return false;
      }
    }
      

    const url = process.env.REACT_APP_FORE_BRICKBUCKET + '/Admin/update-vendor';

    const handleSave = async () => {
        try {
          if (!vendorName || !companyName || !address|| !panNumber ||
            !gstinNumber|| !city || !state || !pincode) {
              setUpdateVendorProfileAlert('Please fill in all the required fields.');
           return; // Exit function if any required field is empty
       }
     
        if(!validateGSTIN(gstinNumber)){
          setUpdateVendorProfileAlert('give a valid gst number')
         return;
        }
     
     if(!validatePAN(panNumber)){
      setUpdateVendorProfileAlert('give a valid pan number')
         return;
     }
     
     if(!validatePINCode(pincode)){
      setUpdateVendorProfileAlert('give a valid pin number')
         return;
     }

            const updatedVendorData = {
                name: vendorName,
                company_name:companyName,
                gstin: gstinNumber,
                pan: panNumber,
                address: address,
                state: state,
                city: city,
                pincode: pincode,
                _id:vendor._id
                // Add other fields as needed
            };
    
            const token = sessionStorage.getItem('token');
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token' : token
                },
                body: JSON.stringify(updatedVendorData),
            };
    
            // Make a PUT request to update vendor data using fetch
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                const responseData = await response.json();

                setUpdateVendorProfileAlert('Vendor updated successfully!')
                //navigate('/bb/AdminHome/VendorList')   
            }else{
                console.error('Failed to update vendor data')
                //throw new Error('Failed to update vendor data');
            }
    
          
        } catch (error) {
            // Handle error
            clearStorageAndRedirectToHome()
        }
    };

    const handleUpdateVendorAlert = () => {
        setUpdateVendorProfileAlert(false);
    }

    return (

        <div className="overflow-y-auto w-full  flex flex-col items-center ">
     <div className="flex flex-col items-center justify-center p-10">
     <div className='border border-1 border-gray-500 flex flex-col flex justify-center  items-center  p-10 '>
        {/* <div>
                        <h2 className="text-lg font-semibold mb-4 flex justify-center">Vendor Profile</h2>
                        </div> */}
                        <div className=" relative">
                            <div className="flex-col justify-center flex items-center ">
                            <div className="flex flex-row space-x-4">   
                            <div className="flex flex-col mb-4">
                                <p className="text-md font-medium text-gray-700 mb-2">Vendor Name</p>
                           
                              
                                    <input
                                        value={vendorName}
                                        onChange={(e) => setVendorName(e.target.value)}
                                        className="w-5/6 mb-4 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs border border-gray-300"
                                        placeholder="Vendor Name"
                                   />
                
                                </div>
                                <div className="flex flex-col mb-4">
                                <p className="text-md font-medium text-gray-700 mb-2">company Name</p>
                           
                              
                                    <input
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        className="w-5/6 mb-4 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs border border-gray-300"
                                        placeholder="Vendor Name"
                                   />
                
                                </div>

                                </div>

                                <div className="flex flex-row space-x-4">
                                <div className="flex flex-col mb-4">
                                <p className="text-md font-medium text-gray-700 mb-2">Gstin Number</p>
                                    <input
                                    value={gstinNumber}
                                    onChange={(e) => setGstinNumber(e.target.value)}
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs border border-gray-300"
                                        placeholder="xxxxxxxxxxxx"
                                    />
                                </div>
                                
                                <div className="flex flex-col mb-4">
                                <p className="text-md font-medium text-gray-700 mb-2">Pan Number</p>
                                    <input
                                    value={panNumber}
                                    onChange={(e) => setPanNumber(e.target.value)}
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs border border-gray-300"
                                        placeholder="xxxxxxxxxxxx"
                                    />
                                </div>
                                </div>

                                <div className="flex flex-row space-x-4">
                                <div className="flex flex-col mb-4">
                                <p className="text-md font-medium text-gray-700 mb-2">Address</p>
                                    <input
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs border border-gray-300"
                                        placeholder="xxxxxxxxxxxx"
                                    />
                                </div>
                                
                                <div className="flex flex-col mb-4">
                                <p className="text-md font-medium text-gray-700 mb-2">State</p>
                                    <input
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs border border-gray-300"
                                        placeholder="Andhra Pradesh"
                                    />
                                </div>
                                </div>

                                <div className="flex flex-row space-x-4">
                                <div className="flex flex-col mb-4">
                                    <p className="text-md font-medium text-gray-700 mb-2">City</p>
                                    <input
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs border border-gray-300"
                                        placeholder="City"
                                    />
                                </div>
                                <div className="flex flex-col mb-4">
                                    <p className="text-md font-medium text-gray-700 mb-2">Pincode</p>
                                    <input
                                    value={pincode}
                                    onChange={(e) => setPincode(e.target.value)}
                                        className="w-5/6 md:w-full rounded-sm focus:outline-none hover:border-[#EB001B] text-gray-600 text-sm p-1 text-[12px] placeholder-text-xs border border-gray-300"
                                        placeholder="Pincode"
                                    />
                                </div>
                            </div>


                            </div>

                                                <div className='flex justify-center'>
                            {assignedCategories.length > 0 && (
                            <div>
                                <h3 className="text-md font-semibold mt-4">Assigned Categories:</h3>
                                <ul>
                                {assignedCategories.map(category => (
                                    <li className='text-center' key={category._id}>{category.product_category_name}</li>
                                ))}
                                </ul>
                            </div>
                            )}
                        </div>
                        
                           <div className="flex justify-between">
                            <button className="px-6 py-1  border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white" onClick={toggleBankDetailsModal}>Bank Details</button>
                            {/* Bank Details Modal */}
                            {isBankDetailsOpen && <BankDetailsModal vendor={VendorData} onClose={toggleBankDetailsModal} setVendor={setVendorData}/>}

                            <button className="px-6 py-1  border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white" 
                            onClick={() => navigate('/bb/admin/AdminHome/VendorContact', { state: { VendorData } })}>
                                Contact
                                </button>
                                                        </div>

                            <div className="flex justify-center space-x-4 mt-2">
                            <button className="px-6 py-1 border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white " onClick={() => navigate('/bb/admin/AdminHome/VendorList')}>Cancel</button>

                            <button className="px-6 py-1  border border-1 border-[#EB001B] bg-white hover:bg-[#EB001B] hover:text-white" onClick={handleSave}>Save</button>
                          </div>
                        </div>
                    </div>
                </div>
                {UpdateVendorProfileAlert && (
  <div className="fixed z-30 inset-0 flex items-center justify-center">
  {/* Background overlay (blurred) */}
  <div className="absolute inset-0 bg-black opacity-30 "></div>

    <div className="bg-white p-4 rounded-md shadow-md relative z-10 flex flex-col items-center justify-center">
    <p className="text-lg font-semibold mb-2">{UpdateVendorProfileAlert}</p>
     <button  className="items-center justify-center text-white bg-green-600 hover:bg-green-700 py-1 px-2 rounded-md mt-4" onClick={handleUpdateVendorAlert}>OK</button>
    </div>
  </div>
)}
            </div>
    
    
    );
}

export default VendorProfile;
