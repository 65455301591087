import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function ContactModal({ selectedVendor, onClose, mode }) {
  const [contact, setContact] = useState({ 
    name: selectedVendor.name || '', 
    email: selectedVendor.email || '', 
    mobile: selectedVendor.mobile_number || '', 
    designation: selectedVendor.role || '' 
  });
  const [RequiredAlert, setRequiredAlert] = useState(false);
  const navigate= useNavigate();
 useEffect(()=>{
  const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
  const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
  if(storedLoginData&&Userdata){
    if(storedLoginData.token===Userdata.token){
    }else{
      navigate('/bb/admin')
     sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
    }
  }else{
    navigate('/bb/admin')
      sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
  }
})


const handleInputChange = (event) => {
  const { name, value } = event.target;
  if (name === 'mobile') {
      // Check if the value is a number and its length is less than or equal to 10
      const isNumber = /^\d*$/.test(value);
      if (isNumber && value.length <= 10) {
          setContact({ ...contact, [name]: value });
          setRequiredAlert('');
      } else if (!isNumber) {
          setRequiredAlert('Mobile number must be numeric');
      } else if (value.length > 10) {
          setRequiredAlert('Mobile number must be 10 digits or less');
      }
  } else {
      setContact({ ...contact, [name]: value });
      setRequiredAlert('');
  }
};


   
  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/bb/admin';
  }

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (emailRegex.test(email)) {
      return true;
    } else {
      return false;
    }
  }
  

  const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/update-vendor-user`;

  const handleSave = async () => {

    if (!contact.name || !contact.email|| !contact.mobile || !contact.designation  ) {
      setRequiredAlert('Please fill in all the required fields.');
    return; // Exit function if any required field is empty
}

if(!validateEmail(contact.email)){
  setRequiredAlert('give a valid Email id ')
  return;
 }


    try {
      const updatedVendorData = {
        name: contact.name, 
        email: contact.email, 
        mobile_number: contact.mobile, 
        role: contact.designation,
        _id: selectedVendor._id
      };
      const token = sessionStorage.getItem('token');
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-token' : token 
        },
        body: JSON.stringify(updatedVendorData),
      };

      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const responseData = await response.json();

      onClose(); // Close the modal after successful update
      }else{
        console.error('Failed to update vendor data')
        //throw new Error('Failed to update vendor data');
      }

     
    } catch (error) {
      clearStorageAndRedirectToHome()
    }
  };

  const url2 = `${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/create-vendor-user`;
  
  const handleCreate = async () => {
    if (!contact.name || !contact.email|| !contact.mobile || !contact.designation  ) {
       setRequiredAlert('Please fill in all the required fields.');
     return; // Exit function if any required field is empty
 }
 
if(!validateEmail(contact.email)){
  setRequiredAlert('give a valid Email id ')
  return;
 }

    try {
      const newVendorData = {
        name: contact.name, 
        email: contact.email, 
        mobile_number: contact.mobile, 
        role: contact.designation,
        vendor_id:selectedVendor._id
      };


      const token = sessionStorage.getItem('token');
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-token' : token
        },
        body: JSON.stringify(newVendorData),
      };
  
      const response = await fetch(url2, requestOptions);
      if (response.ok) {
        const responseData = await response.json();

        onClose(); // Close the modal after successful creation
      }else{
        console.error('Failed to create vendor data')
        //throw new Error('Failed to create vendor data');
      }
  
    
    } catch (error) {
      clearStorageAndRedirectToHome()
    }
  };
  
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-20">
      <div className="bg-gray-800 bg-opacity-50 absolute top-0 left-0 w-full h-full"></div>
      <div className="bg-white p-8 rounded-md shadow-md relative z-30">
        <button className="absolute top-0 right-0 m-4 text-black hover:text-[#EB001B]" onClick={onClose}>
          <svg className="w-6 h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-xl font-bold mb-4 mt-4 text-center">Vendor Contact Details</h2>
        <div className="flex justify-center mb-4">
          <div className="flex flex-col">
            <input className="border mb-2 w-64 p-2 hover:border-[#EB001B] focus:outline-none" name="name" value={contact.name} onChange={handleInputChange} placeholder="Name" />
            <input className="border mb-2 w-64 p-2 hover:border-[#EB001B]  focus:outline-none" name="email" value={contact.email} onChange={handleInputChange} placeholder="Email" />
            <input className="border mb-2 w-64 p-2 hover:border-[#EB001B]  focus:outline-none" name="mobile" value={contact.mobile} onChange={handleInputChange} placeholder="Mobile Number" />
            <input className="border mb-2 w-64 p-2 hover:border-[#EB001B]   focus:outline-none" name="designation" value={contact.designation} onChange={handleInputChange} placeholder="Designation" />
            {mode === 'edit' ? (
              <div className='flex flex-col justify-center items-center space-y-2'>
            <button className="border border-[#EB001B] text-md px-4 py-1 hover:bg-[#EB001B] hover:text-white m-1" onClick={handleSave}>Update</button>
            <p>{RequiredAlert}</p>
            </div>
          ) : (
              <div className='flex flex-col justify-center items-center space-y-2'>
              <button className="border border-[#EB001B] text-md px-4 py-1 hover:bg-[#EB001B] hover:text-white m-1" onClick={handleCreate}>Save</button>
              <p>{RequiredAlert}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactModal;
