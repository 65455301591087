import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AllProductsUnderProductCategory from './AllProductsUnderProductCategory'
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { FaMinusCircle, FaSearch } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 import { jsPDF } from "jspdf";
import 'jspdf-autotable'; 
import { faSearch } from '@fortawesome/free-solid-svg-icons';
const CreateCustomerQuotation = () => {
  const navigate=useNavigate()
  const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
  const url=process.env.REACT_APP_FORE_BRICKBUCKET
  const location = useLocation();
  const [ProductCategories,setProductCategories]=useState([])
  const [ProductSubCategories,setProductSubCategories]= useState([])
 const {customer, materialRequired}=location.state
 console.log(materialRequired.project_ids);
 const [selectedCategory,setSelectedCategory]=useState('')
 const [selectedSubCategory,setSelectedSubCategory]=useState('')
 const [OffersData,setOffersData]=useState([])
 const [filteredOffers,setFilteredOffers]=useState([])
 const [productAdded, setProductAdded] = useState([]);

 const [quantityBreakUp,setQuantityBreakUp]=useState([])
const [QuotationPdf,setQuotationPdf]=useState()

const [searchQuery, setSearchQuery] = useState('');
const [filteredProducts, setFilteredProducts] = useState([]);

// Function to handle search query change
const handleSearch = (event) => {
  setSearchQuery(event.target.value);
};

// Filter products based on search query
useEffect(() => {
  if (searchQuery.trim() === '') {
    // Reset filtered products if search query is empty
    setFilteredProducts([]);
  } else {
    // Filter products based on search query
    const filteredProductsByCategory = productAdded.filter(product => 
      product.product.product_category_id === selectedCategory &&
      product.product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const filteredProductsBySubCategory = productAdded.filter(product => 
      product.product.product_sub_category_id === selectedSubCategory &&
      product.product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Combine both filtered arrays and remove duplicates
    const filteredProducts = [...filteredProductsByCategory, ...filteredProductsBySubCategory]
      .filter((product, index, self) => 
        index === self.findIndex(p => 
          p.product._id === product.product._id
        )
      );

    // Check if OffersData is defined and not empty
    let filteredProductsFromOffers = [];
    if (OffersData && OffersData.length > 0) {
      filteredProductsFromOffers = filteredProducts.filter(product => 
        OffersData.some(offer => 
          offer.offerProducts && // Check if offer.offerProducts is defined
          offer.offerProducts.some(offerProduct => 
            offerProduct.product_id === product.product._id
          )
        )
      );
    }

    setFilteredProducts(filteredProductsFromOffers);
  }
}, [searchQuery, OffersData, productAdded, selectedCategory, selectedSubCategory]); // Add missing dependencies



 useEffect(()=>{
 const quantityBreakUp= JSON.parse(localStorage.getItem('quantityBreakUp'))||[]
 setQuantityBreakUp(quantityBreakUp)
 },[productAdded])

  useEffect(()=>{
    fetchProductCategories();
  },[])

  useEffect(() => {
    // Initialize counts state for each product when product data changes
    const storedData = JSON.parse(localStorage.getItem("addedProducts")) || {};
    if (storedData.length > 0) {
     const filterdData= storedData.filter(p=>p.customer_id===customer._id)||[]
    /*  console.log(filterdData) */
      setProductAdded(filterdData);
    } else {
      setProductAdded([])
    }
  }, []);

  const fetchProductCategories = async () => {
    const GetAllProductCategories = url + '/Admin/get-all-productCategorys';
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(GetAllProductCategories, {
        method: 'GET',
        headers: {
          // Add any required headers here
          'x-token':token
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setProductCategories(data);
      } else {
        // Handle non-ok response
        console.error('Failed to fetch product categories:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching product categories:', error);
    }
  }


  
  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory('')
    if(event.target.value===''){
      setSelectedSubCategory('')
      
    }else{
      fetchProductSubCategories(event.target.value)
      fetchOffersAndTheirAppliedproducts(event.target.value)
      
    }
   
  };

  const handleChangeSubCategory =(event)=>{
    setSelectedSubCategory(event.target.value);
    if(event.target.value===''){

    setFilteredOffers(OffersData)
    }else{
      const filteredOffers= OffersData.filter(p=>p.productSubCategory&&p.productSubCategory._id===event.target.value)
      setFilteredOffers(filteredOffers) 
    }
  }

const  fetchProductSubCategories = async (productCategoryId)=>{
  console.log(productCategoryId)
 const GetAllProductSubCategories= url+`/Admin/product-sub-category/get-productSubCategories-by-productCategoryId?product_category_id=${productCategoryId}`
try {
  const token = sessionStorage.getItem('token');
   const response = await fetch(GetAllProductSubCategories, {
    method: 'GET',
    headers: {
      // Add any required headers here
      'x-token':token
    }
  });
  
  if (response.ok) {
    const data = await response.json();
    
    setProductSubCategories(data.data);
  } else {
    // Handle non-ok response
    setProductSubCategories([])
    setSelectedSubCategory('')
    
    console.error('Failed to fetch product sub categories:', response.status, response.statusText);
  }
} catch (error) {
  console.error('Error fetching product sub categories:', error);
}
}

const fetchOffersAndTheirAppliedproducts= async(productCategoryId)=>{
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/get-ofers-and-offerProducts-by-productCategoryId?product_category_id=${productCategoryId}`, {
      method: 'GET',
      headers: {
        // Add any required headers here
        'x-token':token
      }
    });
    if (!response.ok) {
      setFilteredOffers([])
      setOffersData([])
        throw new Error(`Error fetching data: ${response.statusText}`);
    }
    const data = await response.json();
   
    //const remainingOffers = data.AllOffers.filter(p => p.OfferData._id !== offer._id) || [];
  
  //  const selectedOffer = data.AllOffers.find(p => p.OfferData._id === offer._id);
  //const allOffers = selectedOffer ? [selectedOffer, ...remainingOffers] : remainingOffers;
  
    setOffersData(data.AllOffers)
    setFilteredOffers(data.AllOffers)
  
    //setProductCategory(data.productCategoryData)
   // setProductData(data);
} catch (error) {
   // setError(error.message);
} 
}


const ToTaladdedProductsPrice = () => {
  let totalCost = 0;
  productAdded.forEach((item) => {
    const count = item.count;
    const netPrice= calculateTotalOfferPrice(item)
    const totalPrice = netPrice * count;
    totalCost += totalPrice;
  })
  return totalCost.toFixed(2);
}

const calculateTotalOfferPrice = (item) => {
  const SelecetdOffer = OffersData.find((p) => p.OfferData._id === item.offer_id);
  if (!SelecetdOffer) {
    console.error(`No offer found for item ${item.offer_id}`);
    return 0; // or some default value
  }

  if (SelecetdOffer.OfferData.net_price === 0) {
    const basePrice = parseFloat(item.product.product_price);
    const gst = parseFloat(SelecetdOffer.OfferData.GST_rate_id?SelecetdOffer.OfferData.GST_rate_id: 0);
    const discount = SelecetdOffer.OfferData.discount || 0;
    const NetPrice = (basePrice * (1 - ((discount) / 100))) * (1 + ((gst) / 100));
    return NetPrice;
  } else {
    return parseFloat(SelecetdOffer.OfferData.net_price);
  }
};



const GetAvailOffers =(filteredProducts)=>{
  const offerIdsSet = new Set(filteredProducts.map(({ offer_id }) => offer_id));
  const uniqueOfferIds = Array.from(offerIdsSet);
  return  uniqueOfferIds   
}

const calculateOfferPrice=(product,offerId)=>{
  const SelecetdOffer= OffersData.find(p=>p.OfferData._id===offerId)
  if(SelecetdOffer.OfferData.net_price===0){
    const basePrice = parseFloat(product.product_price);
    const gst = parseFloat(OffersData.find(p=>p.OfferData._id===offerId).OfferData.GST_rate_id || 0);
    const discount = OffersData.find(p=>p.OfferData._id===offerId).OfferData.discount || 0;
    const NetPrice= (basePrice*(1-(discount/100)))*(1+(gst/100))
    return NetPrice
  }else{
    return SelecetdOffer.OfferData.net_price
  }

}

const saveQuotation = async () => {
  // Retrieve and filter products from local storage
  if (!selectedCategory ) {
    console.error("Product category ID is missing.");
    return;
  }

  const addedProducts = JSON.parse(localStorage.getItem('addedProducts')) || [];
  const filteredProducts = addedProducts.filter(product =>product.customer_id===customer._id && product.product.product_category_id === selectedCategory);

  if (filteredProducts.length === 0) {
    console.error("No products found for the current product category.");
    return;
  }
  const availOffers= GetAvailOffers(filteredProducts);
  const quantityBreakUp = JSON.parse(localStorage.getItem('quantityBreakUp')) || [];
const quantity_break_up = quantityBreakUp.filter(p => p.product_category_id === selectedCategory && p.customer_id === customer._id);
  try {
    const requestData = {
      customer_id: customer._id,
      product_category_id: selectedCategory,
      avail_offers:availOffers,
      project_ids: materialRequired.project_ids,
      quantity_break_up:quantity_break_up,
      created_by:'Admin',
      quotationData: filteredProducts.map(({ product, count, offer_id }) => ({
        product_id: product._id,
        count,
        offer_id: offer_id,
        price: calculateOfferPrice(product, offer_id)
      }))
    };
    const token = sessionStorage.getItem('token');
    const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/save-customer-quotation-of-no-project-ids`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add other headers if needed
        'x-token':token
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error('Failed to save PDF data to the server');
    }
    const data = await response.json()
    await saveQuotationImage(data.Quotation._id, materialRequired._id)
    await RemoveProductCategoryCustomerAddedDataFromLocalStorage(selectedCategory)
    navigate('/bb/admin/AdminHome/UserQuotations', {
      state: {
        customer_id:customer._id,
      }
    })
    // Handle successful response
    console.log('PDF data saved successfully');
    //navigate('/bb/user/myQuotations');
  } catch (error) {
    console.error('Error saving PDF data:', error);
  }
};

const handleGeneratePDF = async () => {
  try {
    // Check if the product category ID exists
    if (!selectedCategory ) {
      console.error("Product category ID is missing.");
      return;
    }

    // Retrieve and filter products from local storage
    const addedProducts = JSON.parse(localStorage.getItem('addedProducts')) || [];
    const filteredProducts = addedProducts.filter(product => product.product.product_category_id === selectedCategory);

    if (filteredProducts.length === 0) {
      console.error("No products found for the current product category.");
      return;
    }

    // Create a new jsPDF instance
    const doc = new jsPDF();
   
   
    const subtotal = filteredProducts.reduce((acc, product) => {
      
      const price = calculateOfferPrice(product.product, product.offer_id);;
      return acc + (product.count * price);
    }, 0);

    // Define table data with headers
    let tableData = [
      ['Product Name', 'Quantity', 'Price'], // Header row
      ...filteredProducts.map(product => ([
        product.product.product_name,
        product.count,
        `Rs ${calculateOfferPrice(product.product, product.offer_id).toFixed(2)} /-`
      ])),
      ['Subtotal', '', `Rs ${subtotal.toFixed(2)} /-`]
    ];

    // Add table to the document
    doc.autoTable({
      head: [tableData.shift()], // Extract and use the first row as the header
      body: tableData,
      startY: 20 // Adjust as needed
    });

    // Save the document
    doc.save('Quotation.pdf');
    const pdfBlob = doc.output('blob');
    setQuotationPdf(pdfBlob);
    
    // Save data to the server
    await saveQuotationNotify(pdfBlob)
   // await sendQuotationToCustomer(pdfBlob);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

const saveQuotationNotify = async(pdfBlob)=>{
  if (!selectedCategory ) {
    console.error("Product category ID is missing.");
    return;
  }

  const addedProducts = JSON.parse(localStorage.getItem('addedProducts')) || [];
  const filteredProducts = addedProducts.filter(product =>product.customer_id===customer._id && product.product.product_category_id === selectedCategory);

  if (filteredProducts.length === 0) {
    console.error("No products found for the current product category.");
    return;
  }
  const availOffers= GetAvailOffers(filteredProducts);
  const quantityBreakUp = JSON.parse(localStorage.getItem('quantityBreakUp')) || [];
const quantity_break_up = quantityBreakUp.filter(p => p.product_category_id === selectedCategory && p.customer_id === customer._id);
  try {
    const requestData = {
      customer_id: customer._id,
      product_category_id: selectedCategory,
      avail_offers:availOffers,
      quantity_break_up:quantity_break_up,
      created_by:'Admin',
      quotationData: filteredProducts.map(({ product, count, offer_id }) => ({
        product_id: product._id,
        count,
        offer_id: offer_id,
        price: calculateOfferPrice(product, offer_id)
      }))
    };
    const token = sessionStorage.getItem('token');
    const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/save-customer-quotation-of-no-project-ids`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add other headers if needed
        'x-token':token
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error('Failed to save PDF data to the server');
    }
  const data = await response.json()
  console.log(data)
  await saveQuotationImage(data.Quotation._id, materialRequired._id)
  await sendQuotationToCustomer(pdfBlob, data.Quotation)
    
  } catch (error) {
    console.error('Error saving PDF data:', error);
  }
}

const saveQuotationImage = async (quotation_id, materialRequired_id) => {
  const token = sessionStorage.getItem('token');
 const QuotationForMaterailRequired = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/material-requirement/create-customer-material-requirement-quotation`;; // Replace with the actual URL
  const data = { 
    quotation_id: quotation_id,
    material_requirement_id: materialRequired_id
  };

  try {
    const response = await fetch(QuotationForMaterailRequired, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log('Success:', result);
    return result; // Optional: return the result if needed
  } catch (error) {
    console.error('Error:', error);
  }
};



const sendQuotationToCustomer = async (pdfBlob, quotation) => {
  const product_category_name = ProductCategories.find(p => p._id === quotation.product_category_id).product_category_name;
  const formData = new FormData();
  formData.append('pdf', pdfBlob, 'Quotation.pdf');
  formData.append('email', customer.email);
  formData.append('product_category_name', product_category_name);

  try {
    console.log('PDF Blob size:', pdfBlob.size);
    if (pdfBlob.size === 0) {
      console.error("PDF Blob is empty");
      return;
    }
    const token = sessionStorage.getItem('token');
    const response = await fetch(url + '/customer/quotation/notify-quotation-to-customer-by-email', {
      method: 'POST',
      headers:{
        'x-token':token
      },
      body: formData
    });

    if (response.ok) {
    await RemoveProductCategoryCustomerAddedDataFromLocalStorage(quotation.product_category_id)
   
       navigate('/bb/Admin/AdminHome/AdminViewQuotations', {
        state: {
          selectedQuotationId: quotation._id,
          selectedProductCategoryId: quotation.product_category_id,
          selectedProductCategoryName: product_category_name,
          selectedCustomerId: quotation.customer_id,
          selectedRFQId: quotation.RFQ_id
        }
      }); 
    } else {
      console.error("Error sending email");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

const RemoveProductCategoryCustomerAddedDataFromLocalStorage = ( product_category_id) => {
  console.log(product_category_id)
  const localStorageProductCounts = JSON.parse(localStorage.getItem('ProductCounts')) || {};
  const localStorageAddedProducts = JSON.parse(localStorage.getItem('addedProducts')) || [];
  const localStorageQuantityBreakUp = JSON.parse(localStorage.getItem('quantityBreakUp')) || [];

  const remainingAddedProducts = localStorageAddedProducts.filter(p => p.productCategoryID !== product_category_id);
  const remainingQuantityBreakUp = localStorageQuantityBreakUp.filter(p => p.product_category_id !== product_category_id);
  const remainingProductCounts = {};

  remainingAddedProducts.forEach(({ product }) => {
    const productId = product._id;
    if (localStorageProductCounts[productId]) {
      remainingProductCounts[productId] = localStorageProductCounts[productId];
    }
  });

  // Update localStorage with the remaining data
  localStorage.setItem('addedProducts', JSON.stringify(remainingAddedProducts));
  localStorage.setItem('quantityBreakUp', JSON.stringify(remainingQuantityBreakUp));
  localStorage.setItem('ProductCounts', JSON.stringify(remainingProductCounts)); 

  /* console.log('RemainingBreakupQuantity', remainingQuantityBreakUp);
    console.log('remainingProductCategoryAddedProducts', remainingAddedProducts);
    console.log('remainingProductCounts', remainingProductCounts); */
};




const handleDeleteProduct = (productId) => {
  const user =JSON.parse(localStorage.getItem("userData")) 
  // Find the index of the product with the matching ID
  const index = productAdded.findIndex((product) => product.product._id === productId);
  if (index !== -1) {
    // Remove the product from the UI
    const updatedProductAdded = [...productAdded];
    updatedProductAdded.splice(index, 1);
    setProductAdded(updatedProductAdded);
    // Update local storage
    localStorage.setItem('addedProducts', JSON.stringify(updatedProductAdded));
    const  AllQuantityBreakup = JSON.parse(localStorage.getItem("quantityBreakUp")) || [];
   const RemainingBreakupQuantity=AllQuantityBreakup.filter(p=>p.product_id!==productId && p.customer_id!==user._id )
   localStorage.setItem('quantityBreakUp', JSON.stringify(RemainingBreakupQuantity));
  
  }
};

const hasProductsInCurrentCategory = () => {
  return productAdded.some(product => product.productCategoryID === selectedCategory);
};

const getDisplayUOM = (uom, count) => {
  const countNumber = Number(count);
  // Convert UOM to lowercase and remove special characters for comparison
  let sanitizedUOM = uom.toLowerCase().replace(/[^a-z]/g, '');

  if (countNumber === 1) {
      // Singular form special cases handling
      if (sanitizedUOM === 'nos') {
          return "No";
      } else if (sanitizedUOM === 'bags') {
          return "Bag";
      } else if (sanitizedUOM === 'coils') {
          return "Coil";
      } else if (sanitizedUOM === 'packets') {
          return "Packet";
      }
      // Additional logic for other special cases can be added here
      return uom.replace(/'s$/, ''); // Assuming 's is for plural
  } else {
      // For plural form handling
      if (uom.endsWith('s')) {
          return uom;
      }
      return `${uom}s`;
  }
};

const calculatePrice = (product, offer) => {

  if (!product || !offer || !offer.OfferData) {
    return 'Price Not Available';}
  const basePrice = parseFloat(product.product_price || 0);
  const discount = parseFloat(offer.OfferData.discount || 0);
  const gst = parseFloat( offer.OfferData.GST_rate_id || 0);

  let netPrice = parseFloat(basePrice);

  // Apply discount
  netPrice -= (netPrice * (discount / 100));

  // Apply GST
  netPrice *= (1 + (gst / 100));
  return `${netPrice.toFixed(2)}`;
};

const handleNotify = async ()=>{
  handleGeneratePDF();
}

  return (
    <div className=" flex justify-between h-full overflow-hidden">
      {/* Left Side Image */}
      
    
      <div className="border-r w-1/3 h-full flex items-center justify-center py-2 px-2">
      <div className="w-full h-[110%] flex items-center justify-center overflow-hidden relative">
        <Zoom>
          <img
            src={`${ImageUrl}${materialRequired.requirement_image}`}
            alt="Customer Image"
            className="w-full h-full object-contain"
          />
        </Zoom>
      </div>
    </div>
   


      {/* Middle Section with Dropdowns */}
      <div className="mt-6 flex flex-col w-1/2 h-full p-2 overflow-auto">
  <div className="flex justify-between space-x-2 mb-4">
  <div className="">
       <select 
          className="block w-full p-1 text-sm text-gray-700 bg-white border border-gray-300 focus:outline-none focus:ring-gray-300 focus:border-gray-300"
          value={selectedCategory}
          onChange={handleChange}
        >
          <option value="">Select Product Category</option>
          {
  ProductCategories.map((category, index) => {
    // Check if the category name is not in the excluded list
    if (category.product_category_name !== "Tiles" &&
        category.product_category_name !== "UPVC Windows" &&
        category.product_category_name !== "Doors") {
      return <option key={index} value={category._id}>{category.product_category_name}</option>;
    } else {
      return null; // Skip rendering this option
    }
  })
}
        </select>
    </div>

    <div className="">
    <select 
          className="block p-1 w-full text-sm text-gray-700 bg-white border border-gray-300 focus:outline-none focus:ring-gray-300 focus:border-gray-300"
          value={selectedSubCategory}
          onChange={handleChangeSubCategory}
        >

        <option value="">Select Product Sub Category</option>
        { 
      ProductSubCategories.map((subcategory, index) => (
        <option value={subcategory._id}>{subcategory.product_sub_category_name}</option>
      ))}
        
      </select>
    </div>
    {/* <div className="block pl-1 pr-1  text-sm text-gray-700 bg-white border border-gray-300 focus:outline-none focus:ring-gray-300 focus:border-gray-300">
      <button>Submit</button>
    </div> */}
   <div className="relative">
    <input
        type="text"
        value={searchQuery}
        onChange={handleSearch}
        placeholder="Search..."
        className="block w-full pl-8 text-sm text-gray-700 bg-white border border-gray-300 p-1 focus:outline-none focus:ring-gray-300 focus:border-gray-300"
    />
    <FontAwesomeIcon icon={faSearch} className="text-gray-600 absolute top-2 left-2" />
</div>

  </div>

  {filteredOffers&&selectedCategory&&ProductCategories&&(
    <div className='w-full '>
     <AllProductsUnderProductCategory
    AllOffersData={filteredOffers}
    addedProducts={productAdded}
    setAddedProducts={setProductAdded}
    customerId={customer._id}
    filteredProducts={filteredProducts}
    searchQuery={searchQuery}
    /> 
  </div>
  )}
  

</div>

      {/* Right Side Product Images with Scroll */}
      <div className="border-l w-1/5 h-full pr-4 pt-2 overflow-y-auto"> 
      {OffersData.length > 0 &&(
<>

      {productAdded.length > 0 && /* !["Tiles", "UPVC Windows", "Doors"].includes((ProductCategories.find(p=>p._id===selectedCategory).product_category_name)) && hasProductsInCurrentCategory() && */ (
  <div className="p-2 ml-2 rounded-md justify-start items-start w-full h-full border">
    <div className="rounded-md justify-start items-start h-[82vh] overflow-y-auto">
      <div className="p-1 border-b flex justify-between flex-row">
        <h1 className="font-semibold">Quotation</h1>
        {OffersData && OffersData.length > 0 && (
      <h1>Total: ₹{ToTaladdedProductsPrice()}</h1>
        )}
       
      </div>
      {/* Reverse the productAdded array to display newly added products at the top */}
      {productAdded
        .filter(selectedProduct => selectedProduct.productCategoryID === selectedCategory) // Filter products by product category ID
        .slice()
        .reverse()
        .map((selectedProduct, index) => (
          <div className="hover:bg-red-100 flex flex-col" key={index}>
            <div className="flex justify-end mt-1">
              <button className="text-red-500 focus:outline-none" onClick={() => handleDeleteProduct(selectedProduct.product._id)}>
                <FaMinusCircle className="text-xs hover:text-red-500" />
              </button>
            </div>
            <div key={index} className="flex items-center justify-between p-2 border-b">
              <div className="w-20 h-20 overflow-hidden flex items-center justify-center">
                <img
                  src={ImageUrl + (selectedProduct.product.product_image || 'image unavailable')}
                  alt="Product Image"
                  className="max-w-full max-h-full object-contain"
                  onError={(e) => console.log("Error loading image:", e)}
                />
              </div>
              <div className="ml-4 flex flex-col">
                <div className="flex justify-start">
                  <p className="text-xs font-semibold text-left whitespace-normal">{selectedProduct.product.product_name}</p>
                </div>
                <div className="flex flex-row justify-between">
  <p className="p-1 text-sm">
    Qty: {selectedProduct.count} {getDisplayUOM(selectedProduct.product.UOM, selectedProduct.count)}
  </p>
  {/* Price */}
  {(() => {
    const selectedOffer = OffersData?.find(p => p?.OfferData?._id === selectedProduct?.offer_id);
    if (selectedOffer) {
      return (
        <div>
        {selectedOffer.OfferData.net_price === 0 ? (
          <p>Offer Price: ₹{calculatePrice(selectedProduct.product, selectedOffer)}</p>
        ) : (
          <p>Offer Price: ₹{selectedOffer.OfferData ? selectedOffer.OfferData.net_price : 'Price Not Available'}</p>
        )}
        </div>
       
      );
    } else {
      return (
        <p className="p-1 text-sm">
          Price: ₹{selectedOffer?.OfferData?.net_price ?? 'Price Not Available'}
        </p>
      );
    }
  })()}
</div>
<div className="grid grid-cols-2 gap-1 mt-1">
    {Object.entries(quantityBreakUp.find(p => p.product_id === selectedProduct.product._id)?.break_ups || {}).map(([key, value], index) => (
    (value!== "" && value!== 0 && value!== undefined && value!== null) && (
    <div key={index} className="flex flex-row ">
      <p className="text-xs">{key}=</p>
      <p className="text-xs">{value}</p>
    </div>
    )
  ))}
</div>

              </div>
              
            </div>
          </div>
        ))}
      <div className="flex justify-between px-4 py-2">
      <button className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black" onClick={saveQuotation}>
Save
        </button>
        
        <button className="rounded-sm border border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black" onClick={handleNotify}>
          Notify
        </button>
      </div>
    </div>
  </div>
)}

</>
)}
      </div>
    </div>
  );
};

export default CreateCustomerQuotation;