// Sidebar.js
import React ,{useEffect, useState} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Sidebar, Navbar } from './Side_Nav_bar';
import { useLocation } from 'react-router-dom';

function Main_Page() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate= useNavigate()
  
  const pageNames = {
    "/bb/admin/AdminHome": "Home",
    "/bb/admin/AdminHome/ProductGroupFilters": "Product Group Attributes",
    "/bb/admin/AdminHome/CreateProductGroup": "Create Product Group",
    "/bb/admin/AdminHome/CreateProduct": "Create Products",
    "/bb/admin/AdminHome/ViewProducts": "View Products",
    "/bb/admin/AdminHome/ViewProductGroups": "View Product Groups",
    "/bb/admin/AdminHome/UpdateProduct": "Update Product",
    "/bb/admin/AdminHome/UpdatedProductGroups": "Update Product Groups",
    "/bb/admin/AdminHome/CreateVendor": "Create Vendor",
    "/bb/admin/AdminHome/VendorList": "Vendor List",
    "/bb/admin/AdminHome/CreateOffer": "Create Offer",
    "/bb/admin/AdminHome/ViewOffers": "View Offers",
    "/bb/admin/AdminHome/ViewConsumers": "View Consumers",
    "/bb/admin/AdminHome/ViewofferProducts": "All Products",
    "/bb/admin/AdminHome/VendorProfile": "Vendor Profile",
    "/bb/admin/AdminHome/UpdateBankDetails": "Vendor Bank Details",
    "/bb/admin/AdminHome/productAttributes": "Product Attributes",
    "/bb/admin/AdminHome/UserQuotations": "Quotations",
    "/bb/admin/AdminHome/AdminViewQuotations": "View Quotations",
    "/bb/admin/AdminHome/ConsumerOrders": "Consumer Orders",
    "/bb/admin/AdminHome/AdminViewQuotations2": "View Quotations",
    "/bb/admin/AdminHome/customerOrders" : "Orders",
    "/bb/admin/AdminHome/customerOrderDetails" : "Order Details",
    "/bb/admin/AdminHome/ViewAllOrders" : "All Orders",
    "/bb/admin/AdminHome/customerOrderDetails2": "Order Details",
    "/bb/admin/AdminHome/VendorContact": "Vendor Contact Details",
    "/bb/admin/AdminHome/dashbord": "Admin Dashbord",
    "/bb/admin/AdminHome/viewAllCustomersMaterialRequirements": "Material Required Customers",
    "/bb/admin/AdminHome/createCustomerQuotation": "Customer Quotations",
    '/bb/admin/AdminHome/amendQuotation' : 'Amend Quotation',
    '/bb/admin/AdminHome/viewAllQuotations': 'All Quotations'
   /*  "/krafto/admin/CreateProductFilters": "Create Product Filters",
    "/krafto/admin/ProductModels": "Product Models",
    "/krafto/admin/RoomModels": "Room Models", */
  
    

   
  };
  const currentPage = pageNames[location.pathname] || "Unknown Page";

  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
        navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
      navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })
  
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex h-screen relative">
    {/* Background overlay */}
    {isOpen && (
      <div
        className="fixed inset-0 bg-black opacity-10 z-20"
        onClick={handleToggle} // Close sidebar when clicking on the background overlay
      />
    )}

    <div
       className={`w-1/5 h-full ${isOpen ? 'bg-transparent' : 'bg-white hidden md:hidden'} transition-all duration-300 absolute right-0 z-30`}
      id="sidebar"
      // Add conditional class for transparency
    >
      {/* Left Sidebar (Hidden on Small Screens) */}
      <div className={`w-full h-full ${isOpen ? '' : 'hidden md:hidden'}`}>
        <Sidebar />
      </div>
    </div>

   {/* Toggle Button */}
   <button
        className={`absolute top-8 right-4 md:right-8 transform -translate-y-1/2 transition-all duration-300 z-40`}
        style={{
          cursor: 'pointer',
        }}
        onClick={handleToggle}
      >
        {/* Conditional rendering of hamburger or X icon */}
        {isOpen ? (
          <svg
            className="h-6 w-6 fill-current text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.71 6.71a1 1 0 0 1 1.42 0L12 10.59l3.88-3.88a1 1 0 1 1 1.42 1.42L13.41 12l3.89 3.88a1 1 0 0 1-1.42 1.42L12 13.41l-3.88 3.89a1 1 0 0 1-1.42-1.42L10.59 12 6.7 8.11a1 1 0 0 1 0-1.42z"
            />
          </svg>
        ) : (
          <svg
            className="h-6 w-6 fill-current text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2z"
            />
          </svg>
        )}
      </button>
    {/* Right Section */}
    {/* Top Navbar */}
    <div
      className={`flex flex-col h-full w-full ${
        isOpen ? 'md:w-full' : 'w-full'
      }`}
    >
      <div
        className={`flex flex-col h-2/9 justify-center items-center ${
          isOpen ? '' : 'w-full'
        }`}
      >
        <Navbar currentPage={currentPage} />
      </div>

      {/* Main Content (React Outlet) */}
      <div
        className={`flex flex-col h-8/9 mt-15 justify-center items-center ${
          isOpen ? '' : 'w-full'
        }`}
      >
        <Outlet />
      </div>
    </div>
  </div>
  );
}

export default Main_Page;
