import React, { useState ,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ApplyFilters from './ApplyFilters'; // Replace with the actual path
import { useNavigate } from 'react-router-dom';
import {
  faCamera,
  faImage,
} from '@fortawesome/free-solid-svg-icons';

function ProductGroups() {
  const [Product_category_id, set_Product_Category_Id] = useState();
  const [product_group_id, set_Product_Group_Id] = useState();
  const [Product_group_name, setProductGroupName] = useState("");
  const [enteredName , setEnteredName] = useState();
  const [items, setItems] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const backendApiUrl = process.env.REACT_APP_FORE_BRICKBUCKET;
  const [isProductGroupSelected, setProductGroupSelected] = useState(false);
  const [isNewProductGroupSelected, setNewProductGroupSelected] = useState(false);
  const [attributeIds, setAttributeIds] = useState([]);
  const [AttributesData, setAttributesData] = useState([{ attribute_name: '', enteredValues: '', attribute_values: [] }]);
  const [selectedItems, setSelectedItems] = useState([]);
 const  [Attribute_Value_Ids, set_Attribute_Value_Ids] = useState();
 const  [showSuccessPopup, setShowSuccessPopup] = useState(false);
 const  [successMessage, setSuccessMessage] = useState('');
 const [VendorId,setVendorId]=useState('')
 const navigate = useNavigate();
 const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
 const [image, setImage] = useState(null);
 const [displayedImage, setDisplayedImage] = useState(null);
 const [unitOfMeasurement, setUnitOfMeasurement] = useState('');
 const [price, setPrice] = useState('');

 useEffect(()=>{
  const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
  const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
  if(storedLoginData&&Userdata){
    if(storedLoginData.token===Userdata.token){
    }else{
      navigate('/bb/admin')
     sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
    }
  }else{
    navigate('/bb/admin')
      sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userData');
  }
})

 const handleFileChange = (event) => {
  const file = event.target.files[0];
  setImage(file);
  const reader = new FileReader();
  reader.onload = (e) => {
    setDisplayedImage(e.target.result);
  };
  reader.readAsDataURL(file);
};

function clearStorageAndRedirectToHome() {
  sessionStorage.clear();
  localStorage.clear();
  window.location.href = '/bb/admin';
}
 
 const handleDeleteProductGroup = () => {
  setShowDeleteConfirmation(true);
};

const handleCancelDelete = () => {
  setShowDeleteConfirmation(false);
};

const handleConfirmDelete = async () => {
  setShowDeleteConfirmation(false);

  if (!product_group_id) {
    return;
  }

  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${backendApiUrl}/Admin/product-groups/delete-productGroup?product_group_id=${product_group_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token,
        // Add any other headers you may need
      },
    });

    const selectedCategory = items.find(category => category.product_category_name === selectedItems[0]);
    await fetchProductGroups(selectedCategory?._id);
    set_Attribute_Value_Ids("");
    setEnteredName("");
    setVendorId('');
    if (response.ok) {
     console.log('product group deleted sucessfuly ') 
    }else{
      console.error('Failed to delete product group')
      //throw new Error('Failed to delete product group');
    }

  } catch (error) {
   clearStorageAndRedirectToHome()
    // Handle errors, e.g., show an error message

  }
};

 const handleSuccessPopupClose = async () => {
  setShowSuccessPopup(false);

  // Fetch updated data after closing the success popup
  const selectedCategory = items.find(category => category.product_category_name === selectedItems[0]);
  await fetchProductGroups(selectedCategory?._id);
  set_Attribute_Value_Ids("");
  navigate('/bb/admin/AdminHome/CreateProduct'); 
};

const handleCancel = () => {
  // Add any additional cleanup logic if needed
  navigate('/bb/admin/AdminHome'); // Change '/bb/AdminHome' to the appropriate path
};

const showSuccessMessage = (isNewProductGroup) => {
  if (isNewProductGroup) {
    setSuccessMessage('New Product group has been saved successfully');
  } else {
    setSuccessMessage('Product group has been updated successfully');
  }
  setShowSuccessPopup(true);
};

  
const handleProductCategoryDropdown = async (event) => {
  setProductGroups([]);
  const { value } = event.target;
  const selectedCategory = items.find(category => category._id === value);

  set_Product_Category_Id(selectedCategory?._id);

  setSelectedItems([value]);
  setVendorId('');
  setEnteredName('');
  // Fetch filters for the selected product category
  fetchAttributesByProductCategory(selectedCategory?._id);

  // Fetch product groups for the selected product category
  // Clear selected product group and attributes when changing product category
  setProductGroupSelected(false);
  set_Product_Group_Id(false);
  setProductGroupName("");
  // Fetch vendors for the selected product category
  try {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${backendApiUrl}/Admin/get-vendorsBy-productCategoryId?product_category_id=${selectedCategory?._id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': token
        // Add any other headers you may need
      },
    });

    if (response.ok) {
      const vendorsData = await response.json();

    // Update state with the fetched vendors data
    setVendors(vendorsData.vendors);
    }else{
      setProductGroups('');
      setVendors([]);
      console.error('Failed to fetch vendors for the selected product category')
      //throw new Error('Failed to fetch vendors for the selected product category');
    }

   

  } catch (error) {
   clearStorageAndRedirectToHome()
    // Handle errors, e.g., show an error message
  }
  
};

  const handleVendorDropdown = async (event) => {
    const { value } = event.target;
    const selectedvendor= vendors.find(vendor => vendor._id === value);

   // set_Product_Category_Id(selectedCategory?._id);
 setVendorId(selectedvendor._id)
 set_Attribute_Value_Ids("");
 setEnteredName("");
    await fetchProductGroups(Product_category_id, selectedvendor._id);
  };
  

  const handleProductGroupChange = async (event) => {
    const { value } = event.target;
  
    if (value === "create_new") {
        // If "Create New Product Group" is selected
        setNewProductGroupSelected(true);
        set_Attribute_Value_Ids("");
        setEnteredName("");
        
      // If "Create New Product Group" is selected, fetch filters for the selected product category
      const selectedCategory = items.find(category => category.product_category_name === selectedItems[0]);
     
      setProductGroupSelected(true);
      // Fetch filters for the selected product category
      await fetchAttributesByProductCategory(selectedCategory?._id);

      // Set the flag to indicate that no product group is selected
      set_Product_Group_Id(null);
      setProductGroupName("");
    } else {
      // If an existing product group is selected
    const selectedGroup = productGroups.find((group) => group._id === value);
   setEnteredName( selectedGroup.product_group_name)

    set_Attribute_Value_Ids(selectedGroup.attribute_value_ids);
    setProductGroupSelected(true);
    setNewProductGroupSelected(false); // Set to false for existing product group
    setProductGroupName(selectedGroup?.product_group_name || "");

    // Map filter_value_ids to an array of objects
    const attributeValueObjects = selectedGroup?.attribute_value_ids?.map((id) => ({
      attribute_value_id: id,
    })) || [];
    

    // Update the state with the new product group data
    set_Product_Group_Id(value);
    setProductGroupSelected(true);
  }
  };
   
  useEffect(() => {
  
    fetchProductCategories();
  }, []);

  const fetchProductCategories = async () => {
    const token = sessionStorage.getItem('token');
    try {
      
      const response = await fetch(backendApiUrl + "/Admin/get-all-productCategorys",{
        method: 'GET',
        headers:{
          'Content-Type': 'application/json',
          'x-token' :token
        }
      });
      if (response.ok) {
        const data = await response.json();
        setItems(data);
      }else{
        console.error('Failed to fetch product categories')
        // throw new Error('Failed to fetch product categories');
      }
      
    } catch (error) {
     clearStorageAndRedirectToHome()
    }
  };

  const fetchProductGroups = async (productCategoryId, vendorId) => {
    const token = sessionStorage.getItem('token');
    try {
      
      const url = `${backendApiUrl}/Admin/product-groups/get-all-productGroups-by-productCategoryIdAndVendorId?product_category_id=${productCategoryId}&vendor_id=${vendorId}`;
      const response = await fetch(url, {
        method:'GET',
        headers:{
          'Content-Type': 'application/json',
          'x-token' :token
        }
      });
  
      if (response.ok) {
        const groupsData = await response.json();

  
        // Set the state with the fetched product groups data
        setProductGroups(groupsData.productGroups); 
      }else{
        setProductGroups([]);
        console.error('Failed to fetch product groups for the selected product category and vendor')
        //throw new Error('Failed to fetch product groups for the selected product category and vendor');
      }
  
      
    } catch (error) {
     clearStorageAndRedirectToHome()
    }
  };
  

  const fetchAttributesByProductCategory = async (selectedCategoryId) => {
    const token = sessionStorage.getItem('token');
    try {
      
      const response = await fetch(`${backendApiUrl}/Admin/product-group-attributes/get-all-attributes-by-productCategory?product_category_id=${selectedCategoryId}`,{
        method:'GET',
        headers:{
          'Content-Type': 'application/json',
          'x-token': token
        }
      });

      if (response.ok) {
        const attributesData = await response.json();


        // Set the state with the fetched filter data
        setAttributesData(attributesData);
  
        // Store the filters data in local storage
        localStorage.setItem('product_category_attributes', JSON.stringify(attributesData));
  
        // You can perform additional actions if needed
  
      }else{
        console.error('Failed to fetch filters for the selected product category')
        //throw new Error('Failed to fetch filters for the selected product category');
      }

    
    } catch (error) {
     clearStorageAndRedirectToHome()
    }
  };


  const handleattribute_values_dict = async () => {
    if (attributeIds) {
      // Create a new dictionary to store filter values
      let attribute_values_dict = {};

      // Populate the dictionary with filter values
      for (const selected_attribute of attributeIds) {
        const attribute_name_id = selected_attribute.attribute_name_id;
        const attribute_value_id = selected_attribute.attribute_value_id;

        for (const attribute_group of AttributesData) {
          if (attribute_group.attribute_name_id === attribute_name_id) {
            for (const attribute_value of attribute_group.attribute_values) {
              if (attribute_value.attribute_value_id === attribute_value_id) {
                attribute_values_dict[attribute_name_id] = attribute_value.attribute_value;
              }
            }
          }
        }
      }

      // Create the product name
      const new_product_name = Object.values(attribute_values_dict).join('_');



      // Update the state with the new product name
      setProductGroupName(new_product_name);
    }
  };

  useEffect(() => {
    handleattribute_values_dict(); // Call the function here
  }, [attributeIds]);



  const handleSubmit = async () => {
    const isUpdate = !!product_group_id;
    const ProductGroupName = `${enteredName}_${Product_group_name}`;
    const formData = new FormData();
  
    formData.append('product_group_name', ProductGroupName);
    formData.append('product_category_id', Product_category_id);
    formData.append('vendor_id', VendorId);
    formData.append('product_group_price', price);
    formData.append('UOM', unitOfMeasurement);
  
    // Append each attribute value ID
    attributeIds.forEach((attribute) => {
      formData.append('attribute_value_ids', attribute.attribute_value_id);
    });
  
    // Append image if available
    if (image) {
      formData.append('image', image);
    }
  
    // Conditionally append _id if it's an update
    isUpdate && formData.append('_id', product_group_id);
    const token = sessionStorage.getItem('token');
    try {
      
      const response = await fetch(`${backendApiUrl}/Admin/product-groups/create-productGroup`, {
        method: 'POST',
        headers:{
          //'Content-Type': 'application/json',
          'x-token': token
        },
        body: formData,
      });
  
      if (response.ok) {
       // Handle success, e.g., show a success message
      const isNewProductGroup = !isUpdate;
      showSuccessMessage(isNewProductGroup);
      }else{
        console.error(isUpdate ? 'Failed to update data' : 'Failed to submit data')
        // throw new Error(isUpdate ? 'Failed to update data' : 'Failed to submit data');
      }
  
      
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error('Error:', error.message);
     clearStorageAndRedirectToHome()
    }
  };
  
 
  
  
  

  return (
    <div className="overflow-y-auto w-full  flex flex-col items-center ">
<div className="flex flex-col items-center justify-center p-10">
<div className='border border-1 border-gray-500 flex flex-col flex justify-center  items-center  p-10 '>

        <div>
          <h2 className="text-2xl font-bold mb-4">Product Group</h2>
        </div>
        {/* Product Details Form */}
        <div className="flex flex-col">
        <div className="mt-4 relative flex justify-between items-center">
          <div className="relative flex items-center">
            <p className="text-md text-center mr-4">Select Product Category</p>
            <select
              value={selectedItems[0] || ""}
              onChange={handleProductCategoryDropdown}
              className="border border-gray-300 focus:outline-none hover:border-[#EB001B] rounded-md p-2 cursor-pointer"
              style={{ width: '200px' }}
            >
              <option value="" disabled>Select Product Category</option>
              {items.map((category) => (
                <option key={category?._id} value={category?._id}>
                  {category?.product_category_name}
                </option>
              ))}
            </select>
          </div>
          </div>
          
          <div className="mt-4 relative flex justify-between items-center">
          <div className="relative flex items-center">
            <p className="text-md text-center mr-4">Select Product Sub Category</p>
            <select
              value={selectedItems[0] || ""}
              onChange={handleProductCategoryDropdown}
              className="border border-gray-300 focus:outline-none hover:border-[#EB001B] rounded-md p-2 cursor-pointer"
              style={{ width: '200px' }}
            >
              <option value="" disabled>Select Product Sub Category</option>
              {items.map((category) => (
                <option key={category?._id} value={category?._id}>
                  {category?.product_category_name}
                </option>
              ))}
            </select>
          </div>
          </div>

          <div className="mt-4 relative flex justify-between items-center">
        <p className="text-md text-center mr-4">Select Vendor</p>
        <select
  value={VendorId || ""} // Use VendorId instead of selectedItems[0]
  onChange={handleVendorDropdown}
  className="border border-gray-300 hover:border-[#EB001B] focus:outline-none rounded-md p-2 cursor-pointer"
  style={{ width: '200px' }}
>
  <option value="" disabled>Select Vendor</option>
  {vendors.map((vendor) => (
    <option key={vendor?._id} value={vendor?._id}>
      {vendor?.name}
    </option>
  ))}
</select>


      </div>
         
{/* Select Product Group */}
<div className="relative flex justify-between items-center mt-4">
  <p className="text-md text-center mr-4">Select Product Group</p>
  <select
    value={isNewProductGroupSelected ? "create_new" : product_group_id || ""}
    onChange={handleProductGroupChange}
    className="border border-gray-300 focus:outline-none hover:border-[#EB001B] rounded-md p-2 cursor-pointer"
    style={{ width: '200px' }}
  >
    <option value="" disabled>Select Product Group</option>
    <option value="create_new">Create New Product Group</option>
    {productGroups&&productGroups.length > 0 ? (
      productGroups.map((group) => (
        <option key={group?._id} value={group?._id}>
          {group.product_group_name}
        </option>
      ))
    ) : null}
  </select>
</div>

        </div>
        {displayedImage && (
        <div className="mt-4 flex justify-center">
          <img src={displayedImage} alt="Selected Image" className="max-w-full max-h-32" />
        </div>
      )}
           <div className="mt-4 flex flex-col items-center">
        <label className="text-md mb-2">Image</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="border border-gray-300 rounded-md p-2 focus:outline-none"
        />
      </div>


       
            {/* Product Group Price */}
            <div className=" flex flex-row justify-around items-center">
      <div className="mt-2  flex flex-col items-center">
        <label className="text-md mb-2">Price</label>
        <input
          type="text"
          value={price}
          onChange={(e) => setPrice(e.target.value.replace(/\D/, ''))} // Allow only numeric input
          className="border border-gray-300 rounded-md p-2 focus:outline-none"
        />
      </div>
      <div className="mt-2 flex flex-col items-center">
        <label className="text-md mb-2 ">Unit Of Measurement</label>
        <select
          value={unitOfMeasurement}
          onChange={(e) => setUnitOfMeasurement(e.target.value)}
          className="border border-gray-300 rounded-md p-2 focus:outline-none"
        >
          <option value="">Select Unit of Measurement</option>
          <option value="Sq.ft">Sq.ft</option>
          <option value="No's">No's</option>
          {/* Add more options as needed */}
        </select>
        </div>
      </div>
   
      {/* Product Group Name Input */}
      <div className="relative flex flex-col justify-between items-center ml-4 mt-4">
        <div>
        <label className="text-md text-center mr-4">Product Group Name</label>
        </div>
        <div className='m-2 flex flex-row'>
          <input
          value={enteredName}
          type="text"
          onChange={(event) => setEnteredName(event.target.value)}
          className=" mr-2 border border-gray-300 rounded-sm p-2 focus:outline-none hover:border-[#EB001B] "
          style={{ width: '150px' }}
           // Disable input if no product group selected
        />
<button className='ml-2 hover:text-[#EB001B] rounded-sm text-black' onClick={handleDeleteProductGroup}>
  <FontAwesomeIcon icon={faTrash} />
</button>
        </div>
      </div>






 {/* Render ApplyFilters component only if a product group is selected */}
 {isProductGroupSelected && AttributesData  && (
          <ApplyFilters
          attributesdata={AttributesData}
            setAllAttributeIds={setAttributeIds}
            attributeValueIds={Attribute_Value_Ids}
             // Pass Filter_Value_Ids as a prop
          />
      )}   
  
        <div className='mt-6  '>
        <button className='mr-4 px-4 py-2 border border-[#EB001B] hover:bg-[#EB001B] hover:text-white rounded-sm text-black' onClick={handleSubmit}>
  Submit
</button>

        <button className='ml-4 px-4 py-2 border border-[#EB001B] hover:bg-[#EB001B] hover:text-white rounded-sm text-black'
                onClick={handleCancel}>
          Cancel</button>
        </div>
    </div>

     {/* Confirm Delete Popup */}
     {showDeleteConfirmation && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-4 rounded-md">
            <p className="text-lg font-bold mb-2">Are you sure you want to delete this product group?</p>
            <div className="flex justify-center">
              <button
                className="mr-2 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={handleConfirmDelete}
              >
                Continue
              </button>
              <button
                className="px-2 py-1 border rounded hover:bg-gray-300"
                onClick={handleCancelDelete}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
     {/* Success Popup */}
     {showSuccessPopup && (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className=" bg-white p-4 rounded-md">
          <div>
          <p className="text-lg font-bold mb-2">{successMessage}</p>
</div>
<div className='flex justify-center'>
          <button
            className="mt-2 px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600"
            onClick={handleSuccessPopupClose}
          >
            Okay
          </button>
          </div>
        </div>
      </div>
    )}
    
    </div>

    </div>
  );
}

export default ProductGroups;
