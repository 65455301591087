import React, { useState, useEffect } from 'react';
import { useNavigate , useLocation } from 'react-router-dom';

function UpdateVendorBankDetails() {
    const Navigate = useNavigate();
  const [selectedBank, setSelectedBank] = useState('');
  const [bank_name, set_Bank_Name] = useState('');
  const [account_number, set_Account_Number] = useState('');
  const [bank_branch, set_Bank_Branch] = useState('');
  const [bank_IFSC, setBank_IFSC] = useState('');

  const location = useLocation();
  const vendor = location.state.responseData.vendor;

  useEffect(()=>{
    const storedLoginData = JSON.parse(localStorage.getItem('LoginData'))||null;
    const Userdata =JSON.parse(sessionStorage.getItem('userData'))||null;
    if(storedLoginData&&Userdata){
      if(storedLoginData.token===Userdata.token){
      }else{
       Navigate('/bb/admin')
       sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
      }
    }else{
       Navigate('/bb/admin')
        sessionStorage.removeItem('userRole');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userData');
    }
  })

  const handleBankSelectChange = (e) => {
    setSelectedBank(e.target.value);
  };

  const handleBankNameChange = (e) => {
    if (selectedBank === 'others') {
      set_Bank_Name(e.target.value);
    }
  };
  

  const handleIFSCChange = (e) => {
      setBank_IFSC(e.target.value);
  };

 const handleAccountNumberChange = (e) => {
  set_Account_Number(e.target.value);
  };


  const handleBranchNameChange = (e) => {
    set_Bank_Branch(e.target.value);
  };

  function clearStorageAndRedirectToHome() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/bb/admin';
  }

  const handleFormSubmit = async () => {


    const formData = {
      account_number: account_number||'',
      address: vendor.address||'',
      bank_IFSC: bank_IFSC||'',
      bank_branch: bank_branch||'',
      bank_name: selectedBank === 'others' ? bank_name : selectedBank,
      city: vendor.city||'',
      gstin:vendor.gstin||'',
      name: vendor.name||'',
      pan: vendor.pan||'',
      pincode: vendor.pincode||'',
      state: vendor.state||'',
      _id:vendor._id,
   
  };



    try {
        const url = process.env.REACT_APP_FORE_BRICKBUCKET + '/Admin/update-vendor';
        const token = sessionStorage.getItem('token');
        const options = {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
               'x-token'  : token
            },
            body: JSON.stringify(formData),
        };

        const response = await fetch(url, options);
        if (response.ok) {
          const responseData = await response.json();


          Navigate('/bb/admin/AdminHome/VendorList');
          
        }else{
          console.error('Failed to submit form')
          // throw new Error('Failed to submit form');
        }

       
    } catch (error) {
      clearStorageAndRedirectToHome()
        // Handle error if necessary
    }
};



       const handlePreviousClick=() => {
Navigate('/bb/admin/AdminHome/CreateVendor');
       };

  return (
    <div className="flex flex-col items-center justify-center">
    <div className="border border-gray-400 p-12 ">
      {/* Bank details form elements go here */}
      {/* <h2 className="flex justify-center text-xl font-semibold mb-4">Vendor Bank Details</h2> */}
     
      <div className="gap-2 flex flex-row justify-center items-center mb-4">
       <label htmlFor="BankName" className='text-md font-medium text-gray-700 '>Bank Name</label> 
          <select
          id="selectBank"
          name="selectBank"
          className="w-72 border border-gray-300 p-1 rounded-sm hover:border-[#EB001B] focus:outline-none"
          value={selectedBank}
          onChange={handleBankSelectChange}
        >
             <option value="">Select Bank</option>
        <option value="icici">ICICI Bank</option>
        <option value="hdfc">HDFC Bank</option>
        <option value="sbi">State Bank of India (SBI)</option>
        <option value="axis">Axis Bank</option>
        <option value="kotak">Kotak Mahindra Bank</option>
        <option value="idbi">IDBI Bank</option>
        <option value="punjab">Punjab National Bank (PNB)</option>
        <option value="canara">Canara Bank</option>
        <option value="boi">Bank of India (BOI)</option>
        <option value="union">Union Bank of India</option>
        <option value="boi">Bank of Baroda (BOB)</option>
        <option value="yes">Yes Bank</option>
        <option value="indus">IndusInd Bank</option>
        <option value="uco">UCO Bank</option>
        <option value="central">Central Bank of India</option>
        <option value="allahabad">Allahabad Bank</option>
        <option value="andhra">Andhra Bank</option>
        <option value="dena">Dena Bank</option>
        <option value="vijaya">Vijaya Bank</option>
        <option value="idfc">IDFC FIRST Bank</option>
        {/* Add more options for other banks */}
        <option value="others">Others</option>
      </select>
      </div>

      <table className="">
        <tbody>
          <tr className=" space-x-4 flex">
            <td className="flex flex-col">
              <label htmlFor="BankName" className='text-md font-medium text-gray-700 mb-2'>Bank Name</label>
              <input
                type="text"
                id="BankName"
                name="BankName"
                className={`border border-gray-300 p-1 rounded-sm hover:border-[#EB001B] focus:outline-none ${selectedBank !== 'others' ? 'bg-gray-100 text-gray-500' : ''}`}
                placeholder="Enter Bank Name"
                value={bank_name}
                onChange={handleBankNameChange}
                readOnly={selectedBank !== 'others'} // Add this line
            />
            </td>
            <td className="flex flex-col ">
              <label htmlFor="Branch Name" className='text-md font-medium text-gray-700 mb-2'>Branch Name </label>
              <input
                type="text"
                id="Branch Name"
                name="Branch Name"
                className="border border-gray-300 p-1 hover:border-[#EB001B] rounded-sm focus:outline-none"
                placeholder="Enter Branch Name "
                onChange={handleBranchNameChange}
                value={bank_branch}
              />
            </td>
          </tr>

          {/* Address and City row */}
          <tr className="mt-4 space-x-4 flex">
            <td className="flex flex-col">
            <label htmlFor="Account Number" className='text-md font-medium text-gray-700 mb-2'>Account Number</label>
              <input
                type="text"
                id="Account Number"
                name="Account Number"
                className="border border-gray-300 p-1 hover:border-[#EB001B] rounded-sm focus:outline-none"
                placeholder="Enter Account Number"
                onChange={handleAccountNumberChange}
                value={account_number}
              />
            </td>

            <td className="flex flex-col ">  
          <label htmlFor="address" className='text-md font-medium text-gray-700 mb-2'>IFSC</label>
              <input
                type="text"
                id="IFSC"
                name="IFSC"
                className="border border-gray-300 p-1 rounded-sm hover:border-[#EB001B] focus:outline-none"
                placeholder="Enter IFSC"
                onChange={handleIFSCChange}
                value={bank_IFSC}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="space-x-10 flex justify-between mt-4">
      <button
  type="button"
  onClick={() => {
   
  }}
  className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white rounded-sm text-black px-2 py-1 ml-2"
>
  Preview
</button>
<button
      type="button"
      onClick={() => {
        handleFormSubmit(); // Add this line to submit the form data
        
      }}
      className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white rounded-sm text-black px-2 py-1"
    >
      Submit
    </button>
        <button
        type="button"
        onClick={() => {
          handlePreviousClick(); // Add this line to submit the form data
          
        }}
          className="border border-[#EB001B] hover:bg-[#EB001B] hover:text-white rounded-sm text-black px-2 py-1"
        >
          Previous
        </button>
           </div>
           </div>
    </div>
  );
}

export default UpdateVendorBankDetails;
