import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Checkbox, 
  Button, 
  Typography, 
  Box, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  CircularProgress, 
  TextField, 
  IconButton, 
  Menu, 
  MenuItem, 
  FormControlLabel, 
  Switch, 
  FormGroup, 
  FormControl, 
  InputLabel, 
  Select, 
  Paper, 
  TablePagination,
  TableSortLabel // Added TableSortLabel for sorting indicators
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { Visibility, Delete, Sort, FilterList } from '@mui/icons-material';

const MyQuotations = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [customer, setCustomerData] = useState(null);
  const [selectedQuotations, setSelectedQuotations] = useState([]);
  const [quotationloading, setQuotationLoading] = useState(true);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [quotationToDelete, setQuotationToDelete] = useState(null);
  const [message, setMessage] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState([
    { id: 'RFQ_id', label: 'RFQ ID', checked: true },
    { id: 'projects', label: 'Projects', checked: true },
    { id: 'product_category_name', label: 'Product', checked: true },
    { id: 'quotation_in_order', label: 'Order Placed', checked: true },
    { id: 'actions', label: 'Actions', checked: true }
  ]);

  // State for sorting
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    setCustomerData(userData);

    const getQuotations = async () => {
      const customer_id = userData._id; // Assuming _id is the customer id
      const GetQuotations = process.env.REACT_APP_FORE_BRICKBUCKET + `/customer/quotation/get-all-quotations-by-customer?customer_id=${customer_id}`;

      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(GetQuotations, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'x-token': token
          },
        });

        if (!response.ok) {
          const responseData = await response.json();
          setMessage(responseData.message);
          console.log("Message:", responseData.message);
          // Handle error scenario
          return;
        }

        const data = await response.json();
        setQuotationLoading(false);
        setProjects(data.CustomerQuotations);
        console.log(data.CustomerQuotations)
      } catch (error) {
        console.error("Error fetching quotations:", error);
        // Handle error scenario
      }
    };

    getQuotations();
  }, []);

  const handleViewQuotation = (
    quotationId, rfqId, customer_id, project_ids, product_category_id, product_category_name, quotation) => {
    // Log the parameters to debug
    // Check only for rfqId and customer_id, allow empty project_ids array
    if (!rfqId || !customer_id) {
      console.error("RFQ ID or Customer Id are undefined or empty.");
      return;
    }

    const quotationData = transformData(quotation);
   /*  const QuationIds = {
      customer_id: customer_id,
      product_category_id: product_category_id,
      project_ids: project_ids
    }; */

    handleSetLocalStorageData(quotationData ,product_category_name ,)
    //localStorage.setItem('QuotationIds', JSON.stringify(QuationIds));

    const navigateTo = (product_category_name === 'Tiles' || product_category_name === 'UPVC Windows' || product_category_name === 'Doors') ? 
      "/bb/user/Summarypage" : 
      "/bb/user/Summarypage2";
    navigate(navigateTo, {
      state: {
        selectedQuotationId:quotationId,
        selectedRFQId: rfqId,
        selectedCustomerId: customer_id,
        selectedProjectId: project_ids,
        selectedProductCategoryId: product_category_id,
        selectedProductCategoryName: product_category_name,
      }
    });
  };

  const handleSetLocalStorageData = (quotationData, product_category_name) =>{
    if(product_category_name === 'Tiles' || product_category_name === 'UPVC Windows' || product_category_name === 'Doors'){
      localStorage.setItem('selectedProductGroupOffer', JSON.stringify(quotationData));
    }
    
  }

  const transformData = (data) => {
    const transformedData = {};

    const transformObject = (obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
          if ('offerid' in value) {
            // If the value contains an 'offerid' property, extract it
            transformedData[key] = value.offerid;
          } else {
            // Otherwise, recursively transform the nested object
            transformedData[key] = {};
            transformObject(value);
          }
        }
      });
    };

    transformObject(data);

    console.log(transformedData);
    return transformedData;
  };

  const handleNavigateToOrderDetails = (quotationid, ProductCategoryName) => {
    const navigateToOrderDetails = (ProductCategoryName === 'Tiles' || ProductCategoryName === 'UPVC Windows' || ProductCategoryName === 'Doors') ? 
      '/bb/user/OrderDetails2' : 
      '/bb/user/orderDetails';
    
    navigate(navigateToOrderDetails, {
      state: {
        quotationid,
         ProductCategoryName
      }
    });
  };


  const handleDelete = (quotationId) => {
    setDeleteAlert(true);
    setQuotationToDelete(quotationId);
  };

  const handleDeleteQuotation = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/delete-customer-quotation-data?quotation_id=${quotationToDelete}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          'x-token': token
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setProjects(prevProjects => prevProjects.filter(project => project.quotationData._id !== quotationToDelete));
      setDeleteAlert(false);
    } catch (error) {
      console.error("Error deleting quotation:", error);
      // Handle error scenario
    }
  };

  const handleNo = () => {
    setDeleteAlert(false);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allQuotationIds = projects.map(project => project.quotationData._id);
      setSelectedQuotations(allQuotationIds);
    } else {
      setSelectedQuotations([]);
    }
  };

  const handleSelectQuotation = (event, quotationId) => {
    if (event.target.checked) {
      setSelectedQuotations([...selectedQuotations, quotationId]);
    } else {
      setSelectedQuotations(selectedQuotations.filter(id => id !== quotationId));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleColumnToggle = (columnId) => {
    const updatedColumns = visibleColumns.map(column => {
      if (column.id === columnId) {
        return { ...column, checked: !column.checked };
      }
      return column;
    });
    setVisibleColumns(updatedColumns);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isSelected = (quotationId) => selectedQuotations.indexOf(quotationId) !== -1;

  // Sorting functions
  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const sortedProjects = stableSort(projects, getComparator(sortOrder, sortBy));

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      return order !== 0 ? order : a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, sortBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, sortBy)
      : (a, b) => -descendingComparator(a, b, sortBy);
  }

  function descendingComparator(a, b, sortBy) {
    if (b.quotationData[sortBy] < a.quotationData[sortBy]) {
      return -1;
    }
    if (b.quotationData[sortBy] > a.quotationData[sortBy]) {
      return 1;
    }
    return 0;
  }

  // Filtered projects based on search term
  const filteredProjects = sortedProjects.filter(project => {
    // Check if project.quotationData and project.ProductCategory are defined
    if (!project.quotationData || !project.ProductCategory) {
      return false;
    }
  
    // Check if projects array exists and has at least one element
    if (project.projects && project.projects.length > 0) {
      return (
        project.quotationData.RFQ_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.ProductCategory.product_category_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.quotationData.quotation_in_order.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.projects[0].projectName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      // Handle case where projects array is empty or undefined
      return (
        project.quotationData.RFQ_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.ProductCategory.product_category_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.quotationData.quotation_in_order.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  });
  

  return (
   <div className="flex flex-col items-center h-full lg:ml-40 lg:mt-10 lg:mr-40 mx-4 mt-4 relative">
    <div className="mb-6">
        <h2 className="text-2xl md:text-3xl xl:text-4xl font-bold text-gray-900">Quotations</h2>
      </div>
  <Box className="w-full mb-4 md:flex md:flex-wrap lg:flex-nowrap md:justify-between md:items-center sm:flex sm:justify-center sm:space-x-2">
    <TextField
      label="Search"
      variant="outlined"
      value={searchTerm}
      onChange={handleSearch}
      size="small"
      className="mr-2 mb-2 lg:mb-0 flex-1 max-w-md"
    />
    <Button
      variant="contained"
      onClick={handleMenuOpen}
      startIcon={<FilterList />}
      sx={{ backgroundColor: '#EB001B', color: '#ffffff' }}
      className="mb-2 lg:mb-0 lg:ml-2"
    >
      Columns
    </Button>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <FormGroup>
        {visibleColumns.map(column => (
          <MenuItem key={column.id}>
            <FormControlLabel
              control={
                <Switch
                  checked={column.checked}
                  onChange={() => handleColumnToggle(column.id)}
                />
              }
              label={column.label}
            />
          </MenuItem>
        ))}
      </FormGroup>
    </Menu>
  </Box>
  <TableContainer component={Paper} className="w-full overflow-x-auto mt-4">
    <Table>
      <TableHead>
        <TableRow className="bg-gray-100">
          <TableCell className="sticky left-0 z-10 bg-gray-100">
            <Checkbox
              indeterminate={selectedQuotations.length > 0 && selectedQuotations.length < filteredProjects.length}
              checked={selectedQuotations.length === filteredProjects.length}
              onChange={handleSelectAll}
            />
          </TableCell>
        {/*   <TableCell className="sticky left-12 z-10 bg-gray-100">
            <Typography variant="subtitle1" className="text-red-600">RFQ ID</Typography>
          </TableCell> */}
          {visibleColumns.map(column => {
            if (column.checked && column.id !== 'rfq_id') {
              return (
                <TableCell className="text-center" key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sortOrder : 'asc'}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    <Typography variant="subtitle1" className="text-red-600">{column.label}</Typography>
                  </TableSortLabel>
                </TableCell>
              );
            }
            return null;
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((rfq, index) => {
          const isItemSelected = isSelected(rfq.quotationData._id);
          const rowStyle = index % 2 === 0 ? 'bg-white' : 'bg-gray-50';
          return (
            <TableRow key={rfq.quotationData._id} hover className={rowStyle}>
              <TableCell className="sticky left-0 z-10 bg-white">
                <Checkbox
                  checked={isItemSelected}
                  onChange={(event) => handleSelectQuotation(event, rfq.quotationData._id)}
                />
              </TableCell>
              {/* <TableCell className="sticky left-12 z-10 bg-white">
                {rfq.quotationData.RFQ_id}
              </TableCell> */}
              {visibleColumns.map(column => {
                if (column.checked && column.id !== 'rfq_id') {
                  switch (column.id) {
                    case 'projects':
                      return (
                        <TableCell className="flex justify-center items-center text-center" key={column.id}>
                          {rfq.projects && rfq.projects.length > 0 ? (
                            rfq.projects.map((project, index) => (
                              <span className="text-center" key={project._id}>
                                {project.projectName}
                                {index !== rfq.projects.length - 1 && ", "}
                              </span>
                            ))
                          ) : (
                            <span>----------</span>
                          )}
                        </TableCell>
                      );
                    case 'product_category_name':
                      return (
                        <TableCell className="text-center" key={column.id}>
                          {rfq.ProductCategory.product_category_name}
                        </TableCell>
                      );
                    case 'actions':
                      return (
                        <TableCell className="text-center" key={column.id}>
                          {isItemSelected ? (
                            <IconButton onClick={() => handleDelete(rfq.quotationData._id)}>
                              <Delete />
                            </IconButton>
                          ) : (
                            rfq.quotationData.quotation_in_order === 'Yes' ? (
                              <Button
                                variant="outlined"
                                onClick={() => handleNavigateToOrderDetails(
                                  [rfq.quotationData._id],
                                  rfq.ProductCategory.product_category_name
                                )}
                                className="text-center text-red-600 border-red-600"
                              >
                                View Order
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                onClick={() => handleViewQuotation(
                                  rfq.quotationData._id,
                                  rfq.quotationData.RFQ_id,
                                  rfq.quotationData.customer_id,
                                  rfq.quotationData.project_ids,
                                  rfq.quotationData.product_category_id,
                                  rfq.ProductCategory.product_category_name,
                                  rfq.quotationData.quotationData
                                )}
                                className="text-center text-red-600 border-red-600"
                              >
                                View Quotation
                              </Button>
                            )
                          )}
                        </TableCell>
                      );
                    default:
                      return (
                        <TableCell key={column.id}>
                          {rfq.quotationData[column.id]}
                        </TableCell>
                      );
                  }
                }
                return null;
              })}
            </TableRow>
          );
        })}
        {filteredProjects.length === 0 && (
          <TableRow>
            <TableCell colSpan={visibleColumns.length + 2}>
              <Typography variant="body1" align="center">
                No quotations found.
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
  {filteredProjects.length > rowsPerPage && (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={filteredProjects.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )}
  <Dialog open={deleteAlert} onClose={handleNo}>
    <DialogTitle>Confirm Delete</DialogTitle>
    <DialogContent>
      <Typography variant="body1">
        Are you sure you want to delete the selected quotation(s)?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleNo} color="secondary">
        No
      </Button>
      <Button onClick={handleDeleteQuotation} color="primary">
        Yes
      </Button>
    </DialogActions>
  </Dialog>
  {quotationloading && (
    <Box className="absolute inset-0 flex items-center justify-center">
      <CircularProgress />
    </Box>
  )}
</div>

  
  );
};

export default MyQuotations;
