/* Tiles,Doors,Windows Quotation */

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';

// Define the Modal component
const Modal = ({ product, onClose }) => {
    const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
    return (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-sm z-50">
        <div className="bg-white p-4 rounded-lg">
          <img src={`${ImageUrl}${product.product_image}`} alt={product.product_name} className="h-40 w-auto mx-auto" />
          
          <p className="text-center mt-2">{product.product_name}</p>
          <button className="block mx-auto mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg" onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };

const Summary = () => {
    const [offers, setOffers] = useState([]);
        const [products, setProducts] = useState([]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);
        const [selectedProductGroupOffer, setSelectedProductGroupOffer] = useState([]);
        const [categoryData, setCategoryData] = useState({});
        const [projectName, setProjectName] = useState('');
        const [quotations, setQuotations] = useState([]);
        const navigate = useNavigate();
        const [selectedUOM, setSelectedUOM] = useState({});
        const [selectedProducts,setselectedProductsData] = useState([]);
        const ImageUrl = process.env.REACT_APP_FORE_BRICKBUCKET_IMAGES;
        const location = useLocation();
        const { selectedProjectId, selectedProductCategoryId, selectedProductCategoryName} = location.state;
        const [isQuotationSaved, setIsQuotationSaved] = useState(true);
    const [selectedProductForModal, setSelectedProductForModal] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    useEffect(() => {
       const userData=JSON.parse (localStorage.getItem('userData'))
        //fetchProducts(); // Fetch products when the component mounts
        fetchSummary();
        fetchOffers();
        //fetchQuotations();
        //fetchSelectedProducts(userData._id);
    }, [selectedProjectId]);
    
/* fetching of a selected products must be done by using customer id and projects id along with product group id as well*/ 

/*   const fetchSelectedProducts = async (customer_id) => {
        console.log(customer_id);
    try {
      const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/Quotation-selections/get-customer-selections-for-quotation-by-customerAndProductCategoryIds?product_category_id=${selectedProductCategoryId}&customer_id=${customer_id}`);
      if (!response.ok) {
        setselectedProductsData([])
        throw new Error('Failed to fetch selected products');
      }
      const data = await response.json();
      
      setLoading(false);
      const projectSelectionData = data.customerSelectionData.filter(item =>
        item.project_ids.some(projectId => selectedProjectId.includes(projectId))
      );
      console.log(projectSelectionData);

      
      setselectedProductsData(projectSelectionData)
    } catch (error) {
      console.error('Error fetching selected products:', error);
      setError(error.message);
      setLoading(false);
    }
  };  */


   // Fetch product data from the API using customer ID from local storage
/* const fetchProducts = async () => {
    console.log( selectedProductCategoryId)
    try {
        // Retrieve customer ID from local storage
        const userData = JSON.parse(localStorage.getItem('userData'));
        const customerId = userData?._id;
     
        if (!customerId) {
            throw new Error('Customer ID not found in local storage');
        }

        // Make GET request to API endpoint with customer ID
        const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/Quotation-selections/get-customer-products-by-customerAndProductCategoryIds?customer_id=${customerId}&product_category_id=${selectedProductCategoryId}`);

        if (!response.ok) {
            throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        console.log(data)
        setProducts(data.products);
        setLoading(false);
    } catch (error) {
        console.error('Error fetching products:', error.message);
        setError(error.message);
        setLoading(false);
    }
};  */


/*     useEffect(() => {
        // Calculate and set price and estimated price for the default selection
        Object.entries(categoryData).forEach(([key, value]) => {
            const defaultSelection = offers[0]?.productData.product?.product_name || offers[0]?.productGroup?.product_group_name;
            const selectedOffer = offers.find(offer =>
                (offer.productData.product && offer.productData.product.product_name === defaultSelection) ||
                (offer.productGroup && offer.productGroup.product_group_name === defaultSelection)
            );
            if (selectedOffer) {
                setSelectedProductGroupOffer(prevState => ({
                    ...prevState,
                    [key]: defaultSelection
                }));
            }
        });
    }, [categoryData, offers]); */

    const fetchSummary = async () => {
     //   console.log(project_ids)
     if(selectedProjectId.length > 0) {

    
        try {
            const token = sessionStorage.getItem('token');

            const response = await fetch(`${process.env.REACT_APP_FORE_APILINK}/computed_reports/generate-projects-report?projectIds=${selectedProjectId}`,{
            method: 'GET',
            headers: {
            //   'Content-Type': 'application/json',
              'x-token':token
            },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch summary');
            }
            const data = await response.json();
           // console.log(data);

        // Access the project name from the data object
        //const projectName = data.projectReport["Project Name"];

        // Set the project name state
        setProjectName(projectName);

        let categoryData;
        if (selectedProductCategoryName === 'Tiles') {
            categoryData = data["Tiles"];
        } else if (selectedProductCategoryName === 'Doors') {
            categoryData = filterFields(data["Doors"]);
        } else if (selectedProductCategoryName === 'UPVC Windows') {
            categoryData = filterFields(data["UPVC Windows"]);;
        } else if (selectedProductCategoryName === 'Paints') {
            categoryData = data.Paints;
        } else {
            categoryData = {};
        }
            setCategoryData(categoryData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching summary:', error.message);
            setError(error.message);
            setLoading(false);
            clearStorageAndRedirectToHome()
        }
    }
    };

    const filterFields = (categoryData) => {
        const filteredData = {};
        for (const key in categoryData) {
            if (!key.includes("Number")  && !key.includes("Frame")) {
                filteredData[key] = categoryData[key];
            }
        }
        return filteredData;
    };
    

    const fetchOffers = async () => {
        try {
            const token = sessionStorage.getItem('token');

            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/Admin/offer/get-offers-by-productCategoryId?product_category_id=${selectedProductCategoryId}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-token':token
            },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch offers');
            }

            const data = await response.json();
            const filterListOffers= data.filter(p=>p.OfferData.list_offer==="yes");
            setOffers(filterListOffers);
            setOffers(
                data.filter(offer => (offer.productGroup && offer.productGroup.product_group_name) ||
                 (offer.productData.product && offer.productData.product.product_name))
                ); // Filter out offers with null product_group_name and null product_name
            setLoading(false);
        } catch (error) {
            console.error('Error fetching offers:', error.message);
            setError(error.message);
            setLoading(false);
            clearStorageAndRedirectToHome()
        }
    };
    console.log(offers);

   /*  const fetchQuotations = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/get-customer-quotation-data?project_id=${selectedProjectId}&product_category_id=${selectedProductCategoryId}`);

            if (!response.ok) {
                throw new Error('Failed to fetch quotations');
            }
            const data = await response.json();
            setQuotations(data.QuotationData);
            setLoading(false);
            calculateTotalPrice();
        } catch (error) {
            console.error('Error fetching quotations:', error.message);
            setError(error.message);
            setLoading(false);
        }
    }; */

    // Function to save or update quotation data
    const saveQuotationData = async (quotationData) => {
        try {
            const token = sessionStorage.getItem('token');

            const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/save-customer-quotation-data`;
    
            const formattedData = [];
    
            // Iterate over the quotationData object
            Object.entries(quotationData).forEach(([parentKey, childData]) => {
                if (typeof childData === 'object') {
                    // If childData is an object (parent-child format)
                    Object.entries(childData).forEach(([childKey, childValue]) => {
                        const offerId = selectedProductGroupOffer[childKey] || null;
                        formattedData.push({
                            parent: parentKey,
                            childKey,
                            childValue,
                            offerId,
                        });
                    });
                } else {
                    // If childData is not an object (parent no-child format)
                    const offerId = selectedProductGroupOffer[parentKey] || null;
                    formattedData.push({
                        parentKey,
                        parentValue: childData,
                        offerId,
                    });
                }
            });
           // console.log("Formatted Data:", formattedData);

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                },
                body: JSON.stringify(formattedData),
            });
    
            if (!response.ok) {
                throw new Error('Failed to save or update quotation data.');
            }
    
            const data = await response.json();
    
            // Clear local storage of selected items
            localStorage.removeItem('selectedProductGroupOffer');
    
            // Navigate to "My Quotations" page
            navigate('/bb/user/MyQuotations');
    
        } catch (error) {
            console.error('Error saving or updating quotation data:', error.message);
            clearStorageAndRedirectToHome()
            // Handle error (e.g., display error message to the user)
        }
    };
    
    const transformJsonWithOfferId = (json) => {
        console.log(json)
        const transformedJson = {};
        const selectedProductGroupOffer = JSON.parse(localStorage.getItem("selectedProductGroupOffer"));
    
        // Iterate over each key-value pair in the original JSON
        Object.entries(json).forEach(([parentKey, parentValue]) => {
            // Check if parent has child keys '0' or '1'
            const hasChildKeys01 = Object.keys(parentValue).some(key => key === '0' || key === '1');
    
            if (hasChildKeys01) {
                console.log(hasChildKeys01)
                const Offer_id= selectedProductGroupOffer[parentKey] || null 
                console.log(Offer_id)
                const offer = offers.find(offer => offer._id === Offer_id);
                // Apply special condition for parent-child pairs with child keys '0' or '1'
                transformedJson[parentKey] = {
                    value: parentValue[0], // Assuming 'value' is the first element in the '0' child value array
                    count: parentValue[1], // Assuming 'count' is the second element in the '0' child value array
                    offerid: selectedProductGroupOffer[parentKey] || null ,// Add offer ID if found, otherwise null
                    /* price: offer?.OfferData.net_price||'',
                    UOM:offer.productGroup ? offer.productGroup.UOM :offer.ProductData ?offer.ProductData.UOM : '' */
                };
            } else {
                // For other cases, follow standard transformation
                transformedJson[parentKey] = {};
    
                Object.entries(parentValue).forEach(([childKey, childValue]) => {
                    // Find the offer corresponding to the child value
                    const offerId = selectedProductGroupOffer[parentKey]?.[childKey];
                    const offer = offers.find(offer => offer._id === offerId);
    
                    // Add offer ID, value, and count to the transformed JSON
                    transformedJson[parentKey][childKey] = {
                        value: childValue[0], // Assuming 'value' is the first element in the child array
                        count: childValue[1], // Assuming 'count' is the second element in the child array
                        offerid: selectedProductGroupOffer[childKey] || null ,// Add offer ID if found, otherwise null
                       /*  price:offer?.OfferData.net_price||'',
                        UOM:offer.productGroup ?offer.productGroup.UOM : offer.ProductData ?offer.ProductData.UOM : '' */
                    };
                });
            }
        });
     
        return transformedJson;
    };
      
    
    
    const handleGetQuotation = async () => {
        try {
            const selectedProductGroupOffers = JSON.parse(localStorage.getItem("selectedProductGroupOffer"));
            const userData = JSON.parse(localStorage.getItem('userData'));
            const customerId = userData?._id;
    
            if (!Object.keys(categoryData).length || !Object.keys(selectedProductGroupOffers).length || !offers.length || !customerId) {
                console.error('Data required for PDF generation is missing.');
                return;
            }
            
            let totalAmount = 0;
            const doc = new jsPDF();
            let y = 2;
    
            doc.setFontSize(16);
            doc.text(projectName, 10, y);
            y += 10;
    
            const tableData = [
                ['Category', 'Sq.ft/Nos', 'Selected Product', 'Estimated Price']
            ];
    
            const parentsWithDummy = [];
    
            Object.entries(categoryData).forEach(([parentKey, parentValue]) => {
                if (typeof parentValue === 'object') {
                    let parentHasDummy = false;
                    let combinedChildValue = 0;
                    let combinedProduct = '';
                    let combinedPrice = 0;
    
                    Object.entries(parentValue).forEach(([childKey, childValue], index, array) => {
                        if (childKey === 'Dummy') {
                            parentsWithDummy.push({ parentKey, childValue });
                            parentHasDummy = true;
                        } else if (childKey === '0' || childKey === '1') {
                            if (childKey === '0') {
                                combinedChildValue += parseFloat(childValue) || 0;
                                const selectedProduct = selectedProductGroupOffers[parentKey] || '';
                                const selectedOffer = offers.find(offer => offer._id === selectedProduct);
                                if (selectedOffer) {
                                    const selectedProductName = selectedOffer.productData.product ? selectedOffer.productData.product.product_name : selectedOffer.productGroup.product_group_name;
                                    const price = selectedOffer.OfferData.net_price * parseFloat(childValue);
                                    combinedPrice += price;
                                    combinedProduct = selectedProductName;
                                }
                            }
                        } else {
                            const parentHasChildKeys01 = Object.keys(parentValue).some(key => key === '0' || key === '1');
                            if (!parentHasChildKeys01 || (parentHasChildKeys01 && childKey === '0')) {
                                const selectedProduct = selectedProductGroupOffers[childKey] || '';
                                const selectedOffer = offers.find(offer => offer._id === selectedProduct);
                                const selectedProductName = selectedOffer ? (selectedOffer.productData.product ? selectedOffer.productData.product.product_name : selectedOffer.productGroup.product_group_name) : '';
                                let price = 0;
                                if (selectedOffer && selectedOffer.productCategory.product_category_name === "UPVC Windows" && parentKey.includes("Ventilators")) {
                                    const UOM = selectedOffer.productGroup.UOM;
                                    if (UOM === 'No') {
                                        // Use child key 1 value as area (Sq.ft)
                                        price = selectedOffer.OfferData.net_price * parseFloat(childValue[1]);
                                        totalAmount += price;
                                        tableData.push([childKey, `${childValue[1]} No`, selectedProductName, `Rs: ${price.toFixed(0)} /-`]);
                                    } else if (UOM === 'Sq.ft') {
                                        price = selectedOffer.OfferData.net_price * parseFloat(childValue[0]);
                                        totalAmount += price;
                                        tableData.push([childKey, `${childValue[0]} Sq.ft`, selectedProductName, `Rs: ${price.toFixed(0)} /-`]);
                                    }
                                } else {
                                    price = selectedOffer ? selectedOffer.OfferData.net_price * parseFloat(childValue[0]) : 0;
                                    totalAmount += price;
                                    tableData.push([childKey, `${childValue[0]} Sq.ft`, selectedProductName, `Rs: ${price.toFixed(0)} /-`]);
                                }
                            }
                        }
                    });
    
                    if (combinedChildValue > 0) {
                        totalAmount += combinedPrice;
                        tableData.push([parentKey, `${combinedChildValue.toString()} Sq.ft`, combinedProduct, `Rs: ${combinedPrice.toFixed(0)} /-`]);
                    }
    
                    /* if (!parentHasDummy && !Object.keys(parentValue).includes('0') && !Object.keys(parentValue).includes('1')) {
                        const totalChildValue = Object.values(parentValue)
                            .filter(value => !isNaN(value))
                            .reduce((acc, val) => acc + parseFloat(val), 0);
                        tableData.push([{ content: parentKey, styles: { fontStyle: 'bold' } }, totalChildValue, '------------------', '----------------------']);
                    } */
                } else {
                    const selectedProduct = selectedProductGroupOffers[parentKey] || '';
                    const selectedOffer = offers.find(offer => offer._id === selectedProductGroupOffers[parentKey]);
                    const selectedProductName = selectedOffer ? (selectedOffer.productData.product ? selectedOffer.productData.product.product_name : selectedOffer.productGroup.product_group_name) : '';
                    const price = selectedOffer ? selectedOffer.OfferData.net_price * parseFloat(parentValue) : 0;
                    totalAmount += price;
                    tableData.push([parentKey, `${parentValue[0]} Sq.ft`, selectedProductName, `Rs: ${price.toFixed(0)} /-`]);
                }
            });
    
            doc.autoTable({
                startY: y,
                head: tableData.slice(0, 1),
                body: tableData.slice(1),
                theme: 'grid',
                margin: { top: 10, left: 20 },
                columnStyles: {
                    0: { cellWidth: 50 },
                    1: { cellWidth: 25 },
                    2: { cellWidth: 50 },
                    3: { cellWidth: 35 },
                },
            });
    
            parentsWithDummy.forEach(({ parentKey, childValue }) => {
                doc.setFontSize(12);
                doc.text(`${parentKey}: ${childValue} Sq.Ft`, 20, doc.autoTable.previous.finalY + 10);
            });
    
            doc.setFontSize(12);
            doc.text(`Total Amount: Rs: ${totalAmount.toFixed(2)} /-`, 90, doc.autoTable.previous.finalY + 10);
    
            const filename = 'quotation.pdf';
            doc.save(filename);
    
            const transformedData = transformJsonWithOfferId(categoryData, offers);
            handleSaveQuatationData(transformedData);
            setIsQuotationSaved(false);
            navigate('/bb/user/myQuotations')
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };
    
    const handleAddPriceAndUom=(quotation)=>{
        const extractOfferIds = (data) => {
            for (const key in data) {
              if (data[key] && typeof data[key] === 'object') {
                if (data[key].offerid) {
                 const netprice= calculateOfferPrice(data[key].offerid)
                 const uom=findUom(data[key].offerid)
                 data[key].netPrice=netprice
                 data[key].UOM=uom
                }
                extractOfferIds(data[key]);
              }
            }
          };
        
          // Start extracting offer ids
          extractOfferIds(quotation);
          return quotation
    }

    const calculateOfferPrice =(offerid)=>{
    const offer=offers.find(p=>p._id===offerid)||''
    return offer.OfferData.net_price||''
    }

    const findUom =(offerid)=>{
        const offer=offers.find(p=>p._id===offerid)||''
        return offer.productGroup.UOM||''
        }

   
        const handleAvailOffers = (quotation) => {
            const offerIds = new Set();
            const extractOfferIds = (data) => {
                for (const key in data) {
                    if (data[key] && typeof data[key] === 'object') {
                        if (data[key].offerid) {
                            offerIds.add(data[key].offerid);
                        }
                        extractOfferIds(data[key]);
                    }
                }
            };
            
            // Start extracting offer ids
            extractOfferIds(quotation);
            
            // Convert the Set to an array
            const offerIdsArray = Array.from(offerIds);
            return offerIdsArray;
        }
        
    
    const handleSaveQuatationData = async (quotation) => {
        const availOffers= handleAvailOffers(quotation)
        const updatedQuotationData = handleAddPriceAndUom(quotation);
    
        const userData=JSON.parse (localStorage.getItem('userData'))
        const ProjectsQuation = {
            customer_id:userData._id,
            project_ids: selectedProjectId|| [], // Assuming project_ids is an array
            product_category_id: selectedProductCategoryId || "",
            created_by:"User",
            quotationData: updatedQuotationData,
            avail_offers:availOffers
        };
        const url = `${process.env.REACT_APP_FORE_BRICKBUCKET}/customer/quotation/save-customer-quotation-data`;
        
        try {
            const token = sessionStorage.getItem('token');

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-token':token
                },
                body: JSON.stringify(ProjectsQuation)
            });

            if (!response.ok) {
                throw new Error('Failed to save quotation data.');
            }
           
            // Extracting quotation ID from the response.
            const Quotation  = await response.json();
            const newQuotationID  = Quotation.Quotation._id;
            return newQuotationID ;

        } catch (error) {
            // Handle errors
            clearStorageAndRedirectToHome()
            console.error('Error saving quotation data:', error);
            throw error; // Re-throw the error to handle it at the caller level
            
        }
    };
    
    useEffect(() => {
        // Retrieve selected items from local storage when component mounts
        const storedselectedProductGroupOffer = JSON.parse(localStorage.getItem('selectedProductGroupOffer'));
        if (storedselectedProductGroupOffer) {
            setSelectedProductGroupOffer(storedselectedProductGroupOffer);
        }
    
        // Retrieve selected UOM from local storage when component mounts
        const storedSelectedUOM = JSON.parse(localStorage.getItem('selectedUOM'));
        if (storedSelectedUOM) {
            setSelectedUOM(storedSelectedUOM);
        }
    }, []);

    // Function to handle selection of product in the quotation data
    const handleQuotationItemSelected = (roomAreaType, value) => {
        setSelectedProductGroupOffer(prevState => ({
            ...prevState,
            [roomAreaType]: value
        }));
          // Calculate and update the total price
    calculateTotalPrice();
    };


useEffect (() => {
    calculateTotalPrice();
} ,[offers, selectedProductGroupOffer ,categoryData]);

const handleItemSelected = (roomAreaType, value) => {
    // Update the selected offer ID for the specified room area type
    setSelectedProductGroupOffer(prevState => ({
        ...prevState,
        [roomAreaType]: value
    }));

    // Find the selected offer using the offer ID
    const selectedOffer = offers.find(offer => offer._id === value);

    // Update the selected UOM based on the selected offer
    setSelectedUOM(prevUOM => ({
        ...prevUOM,
        [roomAreaType]: selectedOffer && selectedOffer.productGroup ? selectedOffer.productGroup.UOM : null
    }));

    // Store selected items and UOM in local storage
    localStorage.setItem('selectedProductGroupOffer', JSON.stringify({
        ...selectedProductGroupOffer,
        [roomAreaType]: value
    }));

    // Store selected UOM in local storage
    localStorage.setItem('selectedUOM', JSON.stringify({
        ...selectedUOM,
        [roomAreaType]: selectedOffer && selectedOffer.productGroup ? selectedOffer.productGroup.UOM : null
    }));
    setIsQuotationSaved(false);
};


useEffect (() => {
    calculateTotalPrice();
} ,[selectedProductGroupOffer]);

const navigateToProductsPage = (selectedOffer,key) => {
    
    navigate('/bb/user/Quotationproducts', {
        state: {
            
            selectedProductGroupId: selectedOffer.productGroup ? selectedOffer.productGroup._id : null,
            selectedProductId: selectedOffer.productData.product ? selectedOffer.productData.product._id : null,
            selectedRoomAreaType:key,
            projectIds:selectedProjectId,
            offer:selectedOffer,         
        }
    });
    
};


const calculateTotalPrice = () => {
    let totalPrice = 0;
    const storedSelectedProductGroupOffers = JSON.parse(localStorage.getItem('selectedProductGroupOffer')) || {};
  
    Object.entries(categoryData).forEach(([parentKey, parentValue]) => {
      if (typeof parentValue === 'object') {
        let combinedChildValue = 0;
        let combinedProduct = '';
        let combinedPrice = 0;
  
        Object.entries(parentValue).forEach(([childKey, childValue], index, array) => {
          if (childKey === 'Dummy') {
            // skip dummy child keys
          } else if (childKey === '0' || childKey === '1') {
            if (childKey === '0') {
              combinedChildValue += parseFloat(childValue) || 0;
              const selectedProduct = storedSelectedProductGroupOffers[parentKey] || '';
              const selectedOffer = offers.find(offer => offer._id === selectedProduct);
              if (selectedOffer) {
                const selectedProductName = selectedOffer.productData.product? selectedOffer.productData.product.product_name : selectedOffer.productGroup.product_group_name;
                const price = selectedOffer.OfferData.net_price * parseFloat(childValue);
                combinedPrice += price;
                combinedProduct = selectedProductName;
              }
            }
          } else {
            const parentHasChildKeys01 = Object.keys(parentValue).some(key => key === '0' || key === '1');
            if (!parentHasChildKeys01 || (parentHasChildKeys01 && childKey === '0')) {
              const selectedProduct = storedSelectedProductGroupOffers[childKey] || '';
              const selectedOffer = offers.find(offer => offer._id === selectedProduct);
              const selectedProductName = selectedOffer? (selectedOffer.productData.product? selectedOffer.productData.product.product_name : selectedOffer.productGroup.product_group_name) : '';
              let price = 0;
              if (selectedOffer && selectedOffer.productCategory.product_category_name === "UPVC Windows" && parentKey.includes("Ventilators")) {
                const UOM = selectedOffer.productGroup.UOM;
                if (UOM === 'No') {
                  // Use child key 1 value as area (Sq.ft)
                  price = selectedOffer.OfferData.net_price * parseFloat(childValue[1]);
                } else if (UOM === 'Sq.ft') {
                  price = selectedOffer.OfferData.net_price * parseFloat(childValue[0]);
                }
              } else {
                price = selectedOffer? selectedOffer.OfferData.net_price * parseFloat(childValue[0]) : 0;
              }
              totalPrice += price;
            }
          }
        });
  
        if (combinedChildValue > 0) {
          totalPrice += combinedPrice;
        }
      } else {
        const selectedProduct = storedSelectedProductGroupOffers[parentKey] || '';
        const selectedOffer = offers.find(offer => offer._id === selectedProduct);
        const selectedProductName = selectedOffer? (selectedOffer.productData.product? selectedOffer.productData.product.product_name : selectedOffer.productGroup.product_group_name) : '';
        const price = selectedOffer? selectedOffer.OfferData.net_price * parseFloat(parentValue) : 0;
        totalPrice += price;
      }
    });
  
    // Update the state with the new total price
    setTotalPrice(totalPrice.toFixed(2));
  };


const handleImageClick = (p) => {
    setSelectedProductForModal(p);
  };

  const handleCloseModal = () => {
    setSelectedProductForModal(null);
  };

  const renderModal = () => {
    if (selectedProductForModal) {
      
      return <Modal product={selectedProductForModal} onClose={handleCloseModal} />;
    }
    return null;
  };

// Define the calculateTotalVentilatorPrice function
const calculateTotalVentilatorPrice = (parentValue) => {
    let totalPrice = 0;
    for (const [childKey, childValue] of Object.entries(parentValue)) {
        if (childKey !== 'Dummy') {
            const selectedOffer = offers.find(offer => offer._id === selectedProductGroupOffer[childKey]);
            if (selectedOffer) {
                const netPrice = selectedOffer.OfferData.net_price || 0;
                const UOM = selectedOffer.productGroup?.UOM;
                if (UOM === 'No') {
                    totalPrice += netPrice * parseFloat(childValue[1]);
                } else if (UOM === 'Sq.ft') {
                    totalPrice += netPrice * parseFloat(childValue[0]);
                }
            }
        }
    }
    return totalPrice.toFixed(2);
};

const  handleGenerateOrder= async () => {
    try {
        // Save quotation data and wait for the response
      const gettingQuotationID =  await handleSaveQuatationData(transformJsonWithOfferId(categoryData, offers));

        // Navigate to the Order Confirmation page after saving the quotation
        if (gettingQuotationID) {
            navigate('/bb/user/OrderConfirmation2',  { state: { quotationid: gettingQuotationID ,
                ProductCategoryName:selectedProductCategoryName,
            } } );
        } else {
            console.error("Quotation ID not available. Please try again.");
        }
    } catch (error) {
        console.error('Error generating order:', error);
    }
};

const  handleBack=()=>{
    navigate(-1)
  }
 
   function clearStorageAndRedirectToHome() {
    navigate("/bb/ErrorPage");
  } 
    return (
        <div className='flex justify-center items-center p-2 mt-2'>
        <div className="md:w-2/3 sm:w-full">
            <p className="text-xl font-semibold flex justify-center items-center p-1">{projectName}</p>
            <p className="text-md flex justify-center items-center">({selectedProductCategoryName} Quotation)</p>
            <hr className="border-gray-400 my-1" />
            <div className="bg-[#FFFFFF] p-2">
            {Object.entries(categoryData).map(([parentKey, parentValue], index) => (
    <div key={parentKey}>
        {/* Check if the value is an object */}
        {typeof parentValue === 'object' ? (
            // Parent-child format
            <>
               {!Object.keys(parentValue).some(childKey => childKey === '0' || childKey === '1') && (
    Object.entries(parentValue).some(([childKey]) => childKey !== 'Dummy') && (
        <div className='border-b border-gray-300 space-x-5 font-semibold flex flex-row justify-center items-center mt-3'>
            <div className=' flex flex-row items-center'>
                <h2 className='md:text-xl lg:text-xl sm:text-lg font-bold  mr-2'>{parentKey} </h2>
                <p className="md:text-xl lg:text-xl sm:text-lg font-semibold">
                    {/* Calculate and display the total sum of child values for childKey '0' */}
                    {Object.values(parentValue)
                        .filter(value => Array.isArray(value) && !isNaN(value[0])) // Filter for arrays with a numeric first element
                        .reduce((acc, val) => acc + parseFloat(val[0]), 0).toFixed(2)}
                </p>
                <p className='md:text-sm sm:text-xs pt-1 whitespace-nowrap'>Sq.ft</p>
            </div>

            {/* Additional div for 'Ventilators Area' to sum child key '1' values */}
            {parentKey === 'Ventilators Area' && (
            <div className='flex flex-row items-center'>
            <p className='md:text-xl lg:text-xl sm:text-lg font-bold mr-2'>Total Count </p>
                    <p className="md:text-xl lg:text-xl sm:text-lg font-semibold mr-2">
                     {Object.values(parentValue)
                            .filter(value => Array.isArray(value) && !isNaN(value[1])) // Filter for arrays with a numeric second element
                            .reduce((acc, val) => acc + parseFloat(val[1]), 0).toFixed(0)}
                    </p>
                    <p className='md:text-sm sm:text-xs pt-1 whitespace-nowrap mr-2'>No's</p>
                </div>
            )}
        </div>
    )
)}

{Object.entries(parentValue).map(([childKey, childValue], index, array) => (
    <div key={childKey} className=''>
        {childKey !== 'Dummy' ? (
            (childKey === '0' || childKey === '1') && array.some(([key]) => key === '0' || key === '1') ? (
                index === 0 && (
                    <div className='border-b mb-2 border-gray-300 flex flex-col w-full pl-2'>
                        <div className='flex flex-col sm:flex-row justify-between items-center space-x-3 mb-4 mt-2'>
                            <div className='flex flex-col sm:w-1/2'>
                                <p className='md:text-xl lg:text-xl sm:text-xl font-semibold mt-1 '>{parentKey}</p>
                                {selectedProductCategoryName !== 'Tiles' && (
                                    <div className='flex items-center '>
                                        <p className='md:text-xl lg:text-xl sm:text-md whitespace-normal'>
                                           <span className='font-semibold'>{selectedProductCategoryName}:</span>  {parentValue['1']} No's
                                        </p>
                                       {/*  <p className='md:text-sm sm:text-xs pt-1 whitespace-nowrap'>No's</p> */}
                                    </div>
                                )}
                            </div>
                            <div className='flex flex-col justify-center items-center sm:w-1/2'>
                                <div className='flex flex-row items-center'>
                                    <p className='md:text-xl lg:text-xl sm:text-md whitespace-nowrap'><span className='font-semibold'>Area:</span> {parentValue['0']}</p>
                                    <p className='md:text-sm sm:text-xs pt-1 ml-1 whitespace-nowrap'>Sq.ft</p>
                                </div>
                                {selectedProductGroupOffer[parentKey] ? (
                                    <div className='flex flex-row items-center mt-2'>
                                        <p className='md:text-xl lg:text-xl sm:text-md whitespace-nowrap'>
                                        <span className='font-semibold'>Rate:</span> ₹{((offers.find(offer => offer && offer._id === selectedProductGroupOffer[parentKey])?.OfferData.net_price))}
                                        </p>
                                        <p className='md:text-sm sm:text-xs pt-1 ml-1 whitespace-nowrap'>
                                            per {offers.find(offer => offer._id === selectedProductGroupOffer[parentKey]).productGroup ?
                                                offers.find(offer => offer._id === selectedProductGroupOffer[parentKey]).productGroup.UOM :
                                                (offers.find(offer => offer._id === selectedProductGroupOffer[parentKey]).ProductData ?
                                                    offers.find(offer => offer._id === selectedProductGroupOffer[parentKey]).ProductData.UOM :
                                                    '')}
                                        </p>
                                    </div>
                                ) : (
                                    <div className='flex flex-row items-center mt-2'>
                                        <p className='md:text-xl lg:text-xl sm:text-md text-center whitespace-nowrap'><span className='font-semibold'>Rate:</span> ₹0.00</p>
                                        <p className='md:text-sm sm:text-xs pt-1 ml-1 whitespace-nowrap text-center'>per Sq.ft</p>
                                    </div>
                                )}
                                </div>
                                <div className='sm:w-1/2 flex flex-row justify-center items-center mt-2'>
                                    {selectedProductGroupOffer[parentKey] ? (
                                        <p className='md:text-xl lg:text-xl sm:text-md font-bold text-center'>
                                            <span className='font-semibold'>Subtotal: </span> ₹{(((offers.find(offer => offer._id === selectedProductGroupOffer[parentKey])?.OfferData.net_price || offers.find(offer => offer._id === selectedProductGroupOffer[parentKey])?.OfferData.net_price) * parseFloat(childValue))).toFixed(2)}
                                        </p>
                                    ) : (
                                        <p className='md:text-xl lg:text-xl sm:text-md font-bold text-center'><span className='font-semibold'>Subtotal: </span> ₹0.00</p>
                                    )}
                                </div>
                            
                        </div>
                        <div className='flex flex-row justify-between items-center space-x-2 mb-2'>
                            <div className='flex flex-col sm:w-1/2'>
                                <select
                                    className='border mb-2 border-gray-300 rounded-md p-2 cursor-pointer bg-[#FFFFFF] hover:border-[#000000] focus:outline-none'
                                    value={selectedProductGroupOffer[parentKey] || ''}
                                    onChange={(e) => handleItemSelected(parentKey, e.target.value)}
                                >
                                    <option className='md:text-md lg:text-md sm:text-sm' value=''>Select {selectedProductCategoryName} </option>
                                    {offers
                                        .filter((offer) => {
                                            if (parentKey.includes('Ventilators')) {
                                                return offer.productSubCategory?.product_sub_category_name === 'Ventilators';
                                            } else {
                                                return offer.productSubCategory?.product_sub_category_name !== 'Ventilators';
                                            }
                                        })
                                        .map((offer) => (
                                            offer.productData.product && offer.productData.product.product_name &&
                                            <option key={offer.productData.product._id} value={offer._id}>
                                                {offer.productData.product.product_name}
                                            </option>
                                        ))}
                                    {offers
                                        .filter((offer) => {
                                            if (parentKey.includes('Ventilators')) {
                                                return offer.productSubCategory?.product_sub_category_name === 'Ventilators';
                                            } else {
                                                return offer.productSubCategory?.product_sub_category_name !== 'Ventilators';
                                            }
                                        })
                                        .map((offer) => (
                                            (!offer.productData.product || !offer.productData.product.product_name) &&
                                            offer.productGroup && offer.productGroup.product_group_name &&
                                            <option key={offer.productGroup._id} value={offer._id}>
                                                {offer.productGroup.product_group_name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className='flex flex-col sm:w-1/2 items-center'>
                                {selectedProductGroupOffer[parentKey] && (
                                    offers.find(offer => offer._id === selectedProductGroupOffer[parentKey]).productCategory.product_category_name === "UPVC Windows" ? (
                                        <div className='flex justify-center mt-2 w-auto h-auto'>
                                            <img
                                                src={ImageUrl + offers.find(offer => offer._id === selectedProductGroupOffer[parentKey])?.productGroup?.product_group_image}
                                                className='h-20 w-20 sm:w-16 sm:h-16'
                                                alt='Product Group'
                                                onError={(e) => { 
                                                    console.error('Error loading image:', e);
                                                    e.target.onerror = null; 
                                                    e.target.src = 'fallback-image-url'; // Replace with your fallback image URL
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div className='flex justify-center'>
                                            <button
                                                type='submit'
                                                className={`md:text-md lg:text-md sm:text-sm text-blue-500 ${!selectedProductGroupOffer[parentKey] ? 'text-md text-gray-400' : ''}`}
                                                disabled={!selectedProductGroupOffer[parentKey]}
                                                onClick={() => {
                                                    const selectedOffer = offers.find(offer => offer._id === selectedProductGroupOffer[parentKey]);
                                                    navigateToProductsPage(selectedOffer, parentKey);
                                                }}
                                            >
                                                View Designs
                                            </button>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                )
            ) : (
                // Render the regular block for other childKeys
                <div className='border-b mb-2 border-gray-300 flex flex-col w-full pl-2'>
                        <div className='flex flex-col sm:flex-row justify-between items-center space-x-3 mb-4 mt-2'>
                        <div className='flex flex-col sm:w-1/2'>
                                <p className='md:text-lg lg:text-lg sm:text-xl font-semibold mt-1'>{childKey}</p>
                                {selectedProductCategoryName !== 'Tiles' && (
                           <div className='flex items-center '>
                                {childKey ? (
                                    <p className="md:text-xl lg:text-xl sm:text-md whitespace-normal "><span className='font-semibold'>{selectedProductCategoryName}: </span> {childValue[1]} No's</p>
                                ) : (
                                    <p className="md:text-xl lg:text-xl sm:text-sm "> 0.00</p>
                                )}
                                {/* <p className='md:text-sm sm:text-xs pt-1 whitespace-nowrap'>No's</p> */}
                            </div>
                        )}
                            </div>
                            
                       
                            <div className='flex flex-col justify-center items-center sm:w-1/2'>
                            <div className='flex flex-row items-center'>
                                {childKey ? (
                                    <p className="md:text-xl lg:text-xl sm:text-md whitespace-nowrap"><span className='font-semibold'>Area: </span> {childValue[0]}</p>
                                ) : (
                                    <p className="md:text-xl lg:text-xl sm:text-md whitespace-nowrap ">0.00</p>
                                )}
                                <p className='md:text-sm sm:text-xs pt-1 whitespace-nowrap'>Sq.ft</p>
                            </div>
                       
                       
                            {selectedProductGroupOffer[childKey] ? (
                                <div className="space-x-1 flex flex-row mt-2">
                                    <p className="md:text-xl lg:text-xl sm:text-md whitespace-nowrap"><span className='font-semibold'>Rate: </span> ₹{((offers.find(offer => offer && offer._id === selectedProductGroupOffer[childKey])?.OfferData.net_price))} </p>
                                    <p className='md:text-sm sm:text-xs pt-1 whitespace-nowrap'> per {offers.find(offer => offer._id === selectedProductGroupOffer[childKey]).productGroup ?
                                        offers.find(offer => offer._id === selectedProductGroupOffer[childKey]).productGroup.UOM :
                                        (offers.find(offer => offer._id === selectedProductGroupOffer[childKey]).ProductData ?
                                            offers.find(offer => offer._id === selectedProductGroupOffer[childKey]).ProductData.UOM :
                                            '')
                                    }
                                    </p>
                                </div>
                            ) : (
                                <div className="space-x-1 flex flex-row mt-">
                                    <p className="md:text-xl lg:text-xl sm:text-md whitespace-nowrap"><span className='font-semibold'>Rate: </span> ₹0.00</p>
                                    <p className='md:text-sm sm:text-xs pt-1 whitespace-nowrap '>per Sq.ft</p>
                                </div>
                            )}
                        </div>
                

                        <div className='sm:w-1/2 flex flex-row justify-center items-center '>
                    {selectedProductGroupOffer[childKey] ? (
    <p className='md:text-xl lg:text-xl sm:text-md font-bold text-center'>
      <span className='font-semibold'>Subtotal: </span>  ₹{(() => {
            const offer = offers.find(offer => offer._id === selectedProductGroupOffer[childKey]);
            if (!offer) return '0.00';

            const netPrice = offer.OfferData.net_price;
            const UOM =offer.productGroup.UOM || ''; 
            let calculatedValue = 0;

            // Check if the product category name is "UPVC Windows" and parent key includes "Ventilators"
            if (offer.productCategory.product_category_name === "UPVC Windows" && parentKey.includes("Ventilators")) {
                if (UOM === 'No') {
                    calculatedValue = netPrice * parseFloat(childValue[1]);
                   // console.log(calculatedValue);
                } else if (UOM === 'Sq.ft') {
                    calculatedValue = netPrice * parseFloat(childValue[0]);
                }
            } else {
                // Default calculation if the condition is not met
                calculatedValue = netPrice * parseFloat(childValue);
            }

            return calculatedValue.toFixed(2);
        })()}
    </p>
) : (
    <p className="md:text-xl lg:text-xl sm:text-md justify-center font-bold"><span className='font-semibold'>Subtotal:</span> ₹0.00</p>
)}
 </div>
 </div>
                             <div className='flex flex-row justify-between items-center space-x-2 mb-2'>
                             <div className='flex flex-col sm:w-1/2'>
                                <select
                                    className="border mb-2 border-gray-300 rounded-md p-2 cursor-pointer bg-[#FFFFFF] hover:border-[#000000] focus:outline-none"
                                    value={selectedProductGroupOffer[childKey] || ''}
                                    onChange={(e) => handleItemSelected(childKey, e.target.value)}
                                >
                                    <option value="">Select {selectedProductCategoryName} </option>
                                    {offers
                                        .filter((offer) => {
                                            if (parentKey.includes('Ventilators')) {
                                                return offer.productSubCategory?.product_sub_category_name === 'Ventilators';
                                            } else {
                                                return offer.productSubCategory?.product_sub_category_name !== 'Ventilators';
                                            }
                                        })
                                        .map((offer) => (
                                            offer.productData.product && offer.productData.product.product_name &&
                                            <option key={offer.productData.product._id} value={offer._id}>
                                                {offer.productData.product.product_name}
                                            </option>
                                        ))}
                                    {offers
                                        .filter((offer) => {
                                            if (parentKey.includes('Ventilators')) {
                                                return offer.productSubCategory?.product_sub_category_name === 'Ventilators';
                                            } else {
                                                return offer.productSubCategory?.product_sub_category_name !== 'Ventilators';
                                            }
                                        })
                                        .map((offer) => (
                                            (!offer.productData.product || !offer.productData.product.product_name) &&
                                            offer.productGroup && offer.productGroup.product_group_name &&
                                            <option key={offer.productGroup._id} value={offer._id}>
                                                {offer.productGroup.product_group_name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        
                  
                    


                        {selectedProductGroupOffer[childKey] && (
                             <div className='flex flex-col sm:w-1/2 items-center'>
                                {offers.find(offer => offer._id === selectedProductGroupOffer[childKey]).productCategory.product_category_name === "UPVC Windows" ? (
                                    <div className='flex justify-center mt-2 mr-2 w-auto h-auto'>
                                        <img
                                            src={ImageUrl + offers.find(offer => offer._id === selectedProductGroupOffer[childKey])?.productGroup?.product_group_image}
                                            className='h-20 w-20 sm:h-16 sm:w-16 '
                                            alt='Product Group'
                                            onError={(e) => { 
                                                console.error('Error loading image:', e);
                                                e.target.onerror = null; 
                                                e.target.src = 'fallback-image-url'; // Replace with your fallback image URL
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className='flex justify-center'>
                                        <button
                                            type='submit'
                                            className={`md:text-md lg:text-md sm:text-sm text-blue-500 ${!selectedProductGroupOffer[childKey] ? 'text-md text-gray-400' : ''}`}
                                            disabled={!selectedProductGroupOffer[childKey]}
                                            onClick={() => {
                                                const selectedOffer = offers.find(offer => offer._id === selectedProductGroupOffer[childKey]);
                                                navigateToProductsPage(selectedOffer, childKey);
                                            }}
                                        >
                                            View Designs
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                   </div>
                </div>
                
            )
            
        ) : null}

        
        {/* Calculate and display subtotal if parent key is "Ventilator Area" or "Ventilator Count" */}
        {(parentKey === 'Ventilators Area' || parentKey === 'Ventilators Count') && index === array.length - 1 && (
    <div className="flex flex-row justify-end items-center mt-2">
        <p className="md:text-lg lg:text-lg sm:text-lg font-semibold ">Total Price of Ventilators: </p>
        <p className='md:text-lg lg:text-lg sm:text-lg font-semibold'>₹ 
        {new Intl.NumberFormat('en-IN').format (calculateTotalVentilatorPrice(parentValue))}</p>
    </div>
)}

    </div>
))}
  {/* <hr className="border-gray-300 my-1 mt-2" /> */}
                            </>
                        ) : (
                            // No parent-child format
                            <>
                                <div key={parentKey}>
                                        <div className='border mb-1 border-red-300 flex flex-row justify-around w-full '>
                                            <div className="flex flex-col sm:w-1/4 ">
                                                <div className=' flex flex-col space-y-2 justify-around items-start'>
                                                    <div className='flex justify-start mt-2'>
                                                        <p className='md:text-lg lg:text-lg sm:text-md font-semibold '>{parentKey}</p>
                                                    </div>
                                                    <div className=''>
                                                    <select
                                                                    className="border mb-2 border-gray-300 rounded-md p-2 cursor-pointer bg-[#FFFFFF] hover:border-[#000000] focus:outline-none"
                                                                    value={selectedProductGroupOffer[parentKey] || ''}
                                                                    onChange={(e) => handleItemSelected(parentKey, e.target.value)}
                                                                >
                                                                    <option value="">Select {selectedProductCategoryName} </option>
                                                                    {offers
                                                                        .filter((offer) => {
                                                                            if (parentKey.includes('Ventilators')) {
                                                                                return offer.productSubCategory?.product_sub_category_name === 'Ventilators';
                                                                            } else {
                                                                                return offer.productSubCategory?.product_sub_category_name !== 'Ventilators';
                                                                            }
                                                                        })
                                                                        .map((offer) => (
                                                                            offer.productData.product && offer.productData.product.product_name &&
                                                                            <option key={offer.productData.product._id} value={offer._id}>
                                                                                {offer.productData.product.product_name}
                                                                            </option>
                                                                        ))}
                                                                    {offers
                                                                        .filter((offer) => {
                                                                            if (parentKey.includes('Ventilators')) {
                                                                                return offer.productSubCategory?.product_sub_category_name === 'Ventilators';
                                                                            } else {
                                                                                return offer.productSubCategory?.product_sub_category_name !== 'Ventilators';
                                                                            }
                                                                        })
                                                                        .map((offer) => (
                                                                            (!offer.productData.product || !offer.productData.product.product_name) &&
                                                                            offer.productGroup && offer.productGroup.product_group_name &&
                                                                            <option key={offer.productGroup._id} value={offer._id}>
                                                                                {offer.productGroup.product_group_name}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='space-y-2 flex flex-col'>
                                                <div className='flex flex-row justify-center'>
                                                    <div className='space-x-1 justify-center flex flex-row mt-2'>
                                                        {parentKey ? (
                                                            <p className="md:text-lg lg:text-lg sm:text-sm ">{parentValue}</p>
                                                        ) : (
                                                            <p className="md:text-lg lg:text-lg sm:text-sm ">0.00</p>
                                                        )}
                                                        <p className='md:text-sm sm:text-xs pt-1'>Sq.ft</p>
                                                    </div>
                                                    <div className=''>
                                                        {selectedProductGroupOffer[parentKey] ? (
                                                            <div className="space-x-1 flex flex-row mt-2">
                                                              <p className="md:text-lg lg:text-lg sm:text-sm ml-2">₹{((offers.find(offer => offer._id === selectedProductGroupOffer[parentKey])?.OfferData.net_price ?? 0)).toFixed(2)}</p>

                                                              <p className='md:text-sm sm:text-xs wordspace-nowrap pt-1 '> per
                                                        {offers.find(offer => offer._id === selectedProductGroupOffer[parentKey]).productGroup ? 
                                                            offers.find(offer => offer._id === selectedProductGroupOffer[parentKey]).productGroup.UOM :
                                                            (offers.find(offer => offer._id === selectedProductGroupOffer[parentKey]).ProductData ? 
                                                                offers.find(offer => offer._id === selectedProductGroupOffer[parentKey]).ProductData.UOM :
                                                                'Default Value')
                                                        }
                                                    </p>
                                                            </div>
                                                        ) : (
                                                            <div className="space-x-1 flex flex-row mt-2">
                                                                <p className="md:text-lg lg:text-lg sm:text-sm ml-2">₹0.00</p>
                                                                <p className='md:text-sm sm:text-xs wordspace-nowrap pt-1 '>per Sq.ft</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/*  My selections has been commented */}
                                                <div className="flex justify-center items-center">
                                                    <div className="flex flex-col md:w-2/3 sm:w-full">
                                                        {selectedProductGroupOffer[parentKey] && (
                                                            <div>
                                                                <div className='w-full flex justify-center items-center mb-3'>
                                                                    {/* <p className='text-[14px] text-gray-500'>My selections</p> */}
                                                                </div>
                                                                <div className="flex justify-center flex-row">
                                                                    {selectedProducts.filter(p => p.room_area_type === parentKey).map((product, index) => (
                                                                        <div key={index}>
                                                                            {products.find(p => p._id === product.product_id ) && (
                                                                                <div key={product.product_id} className="flex justify-center">
                                                                                    {/* <img
            src={`${ImageUrl}${products.find(p => p._id === product.product_id ).product_image}`}
            alt={products.find(p => p._id === product.product_id ).product_name}
            className="mr-1 h-6 cursor-pointer"
            onClick={() => handleImageClick(products.find(p => p._id === product.product_id ))}
        /> */}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {/* Render modal */}
                                                                {renderModal()}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/*  My selections has been commented */}
                                                </div>
                                                <div className=' mt-2'>
                                                    {selectedProductGroupOffer[parentKey] ? (
                                                        <p className="md:text-lg lg:text-lg sm:text-sm flex justify-end font-bold">
                                                            ₹
                                                            {(((offers.find(offer => offer._id === selectedProductGroupOffer[parentKey])?.OfferData.net_price || offers.find(offer => offer._id === selectedProductGroupOffer[parentKey])?.OfferData.net_price) *parseFloat (parentValue))).toFixed(2)}
                                                            </p>
                                                    ) : (
                                                        <p className="md:text-lg lg:text-lg sm:text-sm flex justify-end font-bold">₹0.00</p>
                                                    )}

{selectedProductGroupOffer[parentKey] && (
    <div>
        {offers.find(offer => offer._id === selectedProductGroupOffer[parentKey]).productCategory.product_category_name === "UPVC Windows" ? (
            <div className='flex justify-center mt-2 mr-2 w-auto h-auto'>
                <img
                    src={ImageUrl + offers.find(offer => offer._id === selectedProductGroupOffer[parentKey])?.productGroup?.product_group_image}
                    className='h-20 w-20 sm:w-10 sm:h-10'
                    alt='Product Group'
                    onError={(e) => { 
                        console.error('Error loading image:', e);
                        e.target.onerror = null; 
                        e.target.src = 'fallback-image-url'; // Replace with your fallback image URL
                    }}
                />
            </div>
        ):(
                                                         <div className='flex justify-center mt-6'>
                                                         <button
                                                             type='submit'
                                                             className={`md:text-lg lg:text-lg sm:text-sm text-blue-500 ${!selectedProductGroupOffer[parentKey] ? 'text-md text-gray-400' : ''}`}
                                                             disabled={!selectedProductGroupOffer[parentKey]}
                                                             onClick={() => {
                                                                 const selectedOffer = offers.find(offer =>offer._id===selectedProductGroupOffer[parentKey]
                                                                     /* (offer.productData.product && offer.productData.product.product_name === selectedProductGroupOffer[parentKey]) ||
                                                                     (offer.productGroup && offer.productGroup.product_group_name === selectedProductGroupOffer[parentKey]) */
                                                                 );
                                                                 navigateToProductsPage(selectedOffer, parentKey);
                                                             }}>
                                                             View Collections
                                                         </button>
                                                     </div>
                                                    )}
                                                    </div>
                                                )}   
                                                </div>
                                            </div>
                                    </div>
                            </>
                        )}
                    </div>
                ))}
                
            </div>
             
            <hr className="border-gray-400 my-1" />
            <div className='md:flex sm:w-full md:justify-between sm:flex sm:justify-center sm:space-x-10'>
            <div>
            {Object.entries(categoryData).map(([parentKey, parentValue], index) => (
    <div key={parentKey}>
            {Object.entries(parentValue).map(([childKey, childValue], index, array) => (
    <div key={childKey}>
        {childKey == 'Dummy' && (
            <div className="">
                <p className='text-center flex flex-col'>
                <span className='text-lg font-semibold'>{parentKey} : </span>
                <span className='text-lg'>  {new Intl.NumberFormat('en-IN').format(childValue)} Sq.ft </span>
                </p>
            </div>
        )}
    </div>
))}
</div>
))}
</div>
                                     
             
            <div className=''>
                <p className='flex flex-col md:text-lg lg:text-lg sm:text-lg text-center' >
                <span className="text-lg font-semibold">Total Price :</span>
                <span className="text-lg"> ₹
                {new Intl.NumberFormat('en-IN').format(totalPrice)} </span>
</p>
            </div>
            </div>
            
            <div className='flex justify-center px-4 py-2 mt-5 mb-4 flex flex-row space-x-6'>

        {isQuotationSaved ? (
            <button className="rounded-sm border border-gray-500 px-2 text-gray-500" disabled>
            Saved
            </button>

        ) : (
        <button
            type='button'
            className="rounded-sm border md:text-lg lg:text-lg sm:text-md border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black"
            onClick={handleGetQuotation}>
            Save
        </button>
        )}
                <button className="rounded-sm border md:text-lg lg:text-lg sm:text-md border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black" onClick={() => handleGenerateOrder()} >
              Place Order
            </button>
            
                <button className='rounded-sm border md:text-lg lg:text-lg sm:text-md border-[#EB001B] px-2 hover:bg-[#EB001B] hover:text-white text-black ' onClick={handleBack} >Back</button>
              
            </div>
            
        </div>
    </div>
);

};

export default Summary;
