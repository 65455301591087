import React, { useState , useEffect} from 'react';
import { useNavigate , useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faProjectDiagram,
    faUser,
    faSignOutAlt,
    faBell,
    faCamera,
    faImage,
    faUserPlus,
    faAngleLeft,faUserCircle, faTimes,
    faPlus,
    faChevronRight,
    faChevronLeft,
    faArrowUp,
    faArrowDown
  } from '@fortawesome/free-solid-svg-icons';
import { MdAddLocationAlt , MdLocationOn , MdMyLocation  } from "react-icons/md";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
let currentInfoWindow = null;


function ViewProject() {
    const navigate = useNavigate();
    const location = useLocation();
    const projectId = location.state;
    const [projectName, setProjectName] = useState('');
    const [builderName, setBuilderName] = useState('');
    const [builderContactNo, setBuilderContactNo] = useState('');
    const [siteContactName, setSiteContactName] = useState('');
    const [siteContactNo, setSiteContactNo] = useState('');
    const [siteAddress, setSiteAddress] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [userProfileImage, setuserProfileImage] = useState();
    const [notes, setNotes] = useState('');
    const [inputLatitude, setInputLatitude] = useState('');
    const [inputLongitude, setInputLongitude] = useState('');
    const [projectProfile, setprojectProfile] = useState();
    const [uploadedImage, setUploadedImage] = useState();
    const [projectImage, setProjectImage] = useState();
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);
    const projectURL =  process.env.REACT_APP_FORE_APILINK + `/projects/get-project?projectId=${projectId.projectId}`;
    const UpdateURL = `${process.env.REACT_APP_FORE_APILINK}/projects/update-project?projectId=${projectId.projectId}`;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false); // New state for popup
    const [address, setAddress] = useState('');
    const [coordinates, setCoordinates] = useState();
    const [locations,setLocations]=useState([{ProjectName:'',BuilderName:''}])
   
    useEffect(() => {
        const fetchProjectDetails = async () => {
          try {
            const token = sessionStorage.getItem('token');
            // Make an API call to get project details
            const response = await fetch(
                projectURL,
              {
                method:'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json', 
                'x-token':token
              },
            }
            );
    
            if (response.ok) {
              const projectDetails = await response.json();
              // Set project details in state
              setProjectName(projectDetails.projectName || '');
              setBuilderName(projectDetails.builderName || '');
              setBuilderContactNo(projectDetails.builderContactNo || '');
              setSiteContactName(projectDetails.siteContactName || '');
              setSiteContactNo(projectDetails.siteContactNo || '');
              setSiteAddress(projectDetails.siteAddress || '');
              setSelectedValue(projectDetails.numBlocks || '');
              setUploadedImage(projectDetails.projectImage || '');
             setprojectProfile(projectDetails.projectImage);
            setProjectImage(projectDetails.projectImage);
            setProjectImage(projectDetails.projectImage || '');
            setInputLatitude(projectDetails.latitude || '');
            setInputLongitude(projectDetails.longitude || '');
              const latLng = {
                lat: parseFloat(projectDetails.latitude),
                lng: parseFloat(projectDetails.longitude)
              };
              setCoordinates(latLng);
            } else {
              // Handle errors if the API call fails
              ////console.error('Failed to fetch project details');
              // You can display an error message to the user if needed
            }
          } catch (error) {
            //clearStorageAndRedirectToHome()
          }
        };
    
        // Trigger the fetchProjectDetails function on component mount
        fetchProjectDetails();
      }, []); // The empty dependency array ensures that this effect runs once on mount

      const handleChange = (e, setStateFunction, isNumeric = false, maxLength = null, relatedStateFunction = null) => {
        let inputValue = e.target.value;
      
        // If it's a numeric field, validate input to allow only numeric characters
        if (isNumeric) {
          inputValue = inputValue.replace(/\D/g, '');
        }
      
        // Limit the input to the specified maxLength if provided
        if (maxLength !== null && inputValue.length > maxLength) {
          inputValue = inputValue.slice(0, maxLength);
        }
      
        // Update the state with the sanitized input
        setStateFunction(inputValue);
      
        // If relatedStateFunction is provided, update the related state with the same value
        if (relatedStateFunction && typeof relatedStateFunction === 'function') {
          relatedStateFunction(inputValue);
        }
      };

      const handleChangeAddress= (address) => {
        setAddress(address);
      };

      const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setInputLatitude(position.coords.latitude.toString());
                setInputLongitude(position.coords.longitude.toString());
            });
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };
      const handleImageClick = () => {
        setModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setModalOpen(false);
       // window.location.reload();
      };

      const handlePrevImage = () => {
        if (currentImageIndex > 0) {
          setCurrentImageIndex(currentImageIndex - 1);
        }
      };
    
      const handleNextImage = () => {
        if (currentImageIndex < projectImage.length - 1) {
          setCurrentImageIndex(currentImageIndex + 1);
        }
      };

      const handleImageUpload = (event) => {
        const newImages = [];
        const newFiles = [];
      
        for (let i = 0; i < event.target.files.length; i++) {
          const imageUrl = URL.createObjectURL(event.target.files[i]);
          const fileUrl = event.target.files[i];
          newImages.push(imageUrl);
          newFiles.push(fileUrl);
        }
        // Here you might need to concatenate new images and files with the existing ones
        setImages([...images, ...newImages]);
        setFiles([...files, ...newFiles]);
        
        // Set the uploadedImage state to the first image URL
        if (newImages.length > 0) {
          setUploadedImage(newImages[0]);
        }
      };

      const handleUpdate = async () => {    
          const formData = new FormData();
          formData.append('projectName', projectName);
         formData.append('builderName', builderName);
         formData.append('builderContactNo', builderContactNo);
         formData.append('siteContactName', siteContactName);
         formData.append('siteContactNo', siteContactNo);
         formData.append('siteAddress', address);
         formData.append('latitude', coordinates.lat);
         formData.append('longitude',coordinates.lng);
         for (let i = 0; i < files.length; i++) {
           formData.append('file', files[i]);
         }
         formData.append('notes', notes);
       
         try {
           const token = sessionStorage.getItem('token');
           const response = await fetch(UpdateURL, {
             method: 'PUT',
             headers: {
               'x-token': token,
             },
             body: formData,
           });
       
           if (response.ok) {
             const projectDetails = await response.json();
             const nextState = {
               projectId: projectDetails._id,
               numBlocks:projectDetails.numBlocks
             };
             setShowSuccessPopup(true);
           } else {
             ////console.error('Failed to post data to the backend:', response.status, response.statusText);
             // Handle errors or display an error message to the user
           }
         } catch (error) {
           //clearStorageAndRedirectToHome()
         }
       };

       const handleCancel = ()=>{
        navigate("/bb/user/MyProjects");
      }
    

       const handleClosePopup = () => {
        setShowSuccessPopup(false);
    navigate("/bb/user/MyProjects");
      };

      const fetchLocation = async () => {
        if (!navigator.geolocation) {
          console.error('Geolocation is not supported by your browser');
          return;
        }
        try {
          // Options to improve accuracy
          const options = {
            enableHighAccuracy: true,  // Use GPS if available
            timeout: 10000,            // Maximum time to wait for a response
            maximumAge: 0              // Do not use cached position
          };
      
          // Attempt to get the user's current position
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
          });
      
          // If successful, update the coordinates state
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
      
          // Reverse geocode to get location name
          const results = await geocodeByAddress(`${position.coords.latitude},${position.coords.longitude}`);
          const address = results[0].formatted_address;
      
          // Set the address in the state to display in the search field
          setAddress(address);
        } catch (error) {
          if (error.code === error.PERMISSION_DENIED) {
            console.error('User denied the request for Geolocation.');
          } else if (error.code === error.POSITION_UNAVAILABLE) {
            console.error('Location information is unavailable.');
          } else if (error.code === error.TIMEOUT) {
            console.error('The request to get user location timed out.');
          } else {
            console.error('An unknown error occurred:', error);
          }
        }
      }; 

      const handleSelect = async (address) => {
        try {
          const results = await geocodeByAddress(address);
          const latLng = await getLatLng(results[0]);
          setCoordinates(latLng);
          setAddress(address);
        } catch (error) {
          console.error('Error:', error);
        }
      };
    
      useEffect(()=>{  
        if (coordinates

        ) {
          renderMap()
        }
         
             
      },[coordinates])
    
     const renderMap = async () =>  {
      let markersAdded = false; // Flag to check if any markers are added
      if (!coordinates || !coordinates.lat || !coordinates.lng) {
        return; // Coordinates not set yet, do not render map
      }
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: coordinates.lat, lng: coordinates.lng },
        zoom: 8
      });
      const results = await geocodeByAddress(`${coordinates.lat},${coordinates.lng}`);
      const address = results[0].formatted_address;
  
      // Set the address in the state to display in the search field
      setAddress(address);
    
      // Add click event listener to the map
      map.addListener('click', async (event) => {
        try {
          const latLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
          };
    
          // Reverse geocode to get location name
          const results = await geocodeByAddress(`${latLng.lat},${latLng.lng}`);
          const address = results[0].formatted_address;
          // Set the address in the state to display in the search field
          setAddress(address);
    
          // Update the coordinates state
          setCoordinates(latLng);
        } catch (error) {
          console.error('Error:', error);
        }
      });
    
      locations.forEach(location => {
        const marker = new window.google.maps.Marker({
          position: { lat: coordinates.lat, lng: coordinates.lng },
          map,
          title: location.ProjectName
        });
        markersAdded = true; // Markers are added
    
        // Create info window for each marker
        const infoWindow = new window.google.maps.InfoWindow({
          content: ` <div class="text-xs p-1 text-red-700"><strong>Project:</strong> ${location.ProjectName}<br><strong>Builder:</strong>${location.BuilderName}<br><strong>Contact:</strong></div>`
        });
    
        // Add click event listener to marker
        marker.addListener('click', () => {
          // If there is a currently open info window, close it
          if (currentInfoWindow) {
            currentInfoWindow.close();
          }
          
          // If the clicked marker's info window is not already open, open it
          if (currentInfoWindow !== infoWindow) {
            infoWindow.open(map, marker);
            currentInfoWindow = infoWindow;
          } else {
            // If the clicked marker's info window is already open, set currentInfoWindow to null
            currentInfoWindow = null;
          }
        });
      });
    
      // If no markers are added, center the map on default coordinates
      if (!markersAdded) {
        map.setCenter(new window.google.maps.LatLng(coordinates.lat, coordinates.lng));
      }
    };

    return (
      <div className='md:mt-5 flex justify-center items-center '>
      <div className="flex justify-center items-center md:w-2/3 sm:w-full">
      <div className="w-1/2 sm:w-full md:w-2/3 md:border md:border-[#D9D9D9] md:border-2 bg-opacity-[15%] rounded-sm p-4">
      <div className="mb-4 ">
<p className="block text-gray-700 font-bold mb-2"> 
        Project Image
   </p>
        <div className="mb-2 flex items-center justify-center">
    {images && images.length > 0 && images[images.length - 1] || projectImage && projectImage.length > 0 && projectImage[projectImage.length - 1] ? (
        <div className="relative">
            <img
                src={images[images.length - 1] || process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL + projectImage[projectImage.length - 1]}
                alt="Uploaded Profile"
                className="w-20 h-20 object-cover border-4 border-white cursor-pointer"
                onClick={handleImageClick}
            />
            <label
                htmlFor="imageUpload"
                className="text-blue-500 text-sm underline hover:text-blue-900 cursor-pointer"
            >
                <FontAwesomeIcon icon={faCamera} />
                <input type="file" id="imageUpload" accept="image/*" className="hidden" onChange={handleImageUpload} multiple />
            </label>
        </div>
    ) : (
        <label htmlFor="imageUpload" className="cursor-pointer">
            <span className="hover:text-gray-700 text-gray-500 text-4xl mr-2">
                <FontAwesomeIcon icon={faImage} />
            </span>
            <input type="file" id="imageUpload" accept="image/*" className="hidden" onChange={handleImageUpload} multiple />
        </label>
    )}
</div>
</div>

{modalOpen && (
  <div className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50 xs:text-[12px] sm:text-[13px] md:text-[15px]">
    <div className="bg-white p-20 rounded-lg shadow-md relative">
      <div className="flex justify-end cursor-pointer absolute top-2 right-2" onClick={handleCloseModal}>
        <FontAwesomeIcon icon={faTimes} className="text-red-600" />
      </div>
      <div className="flex items-center justify-center">
        <button
                   className={`px-2 py-1 mr-2 ${currentImageIndex === 0 ? 'opacity-20 pointer-events-none' : ''}`}
          onClick={handlePrevImage}
          disabled={currentImageIndex === 0}
        >
         <FontAwesomeIcon icon={faChevronLeft} size="2x" />
        </button>
        <img
          src={`${process.env.REACT_APP_FORE_MEASURIT_UIIMAGEURL}` + projectImage[currentImageIndex]}
          alt={`Image ${currentImageIndex + 1}`}
          className="w-auto h-auto flex justify-center items-center"
        />
        <button
           className={`px-2 py-1 ml-2 ${currentImageIndex === projectImage.length - 1 ? 'opacity-20 pointer-events-none' : ''}`}
          onClick={handleNextImage}
          disabled={currentImageIndex === projectImage.length - 1}
        >
        <FontAwesomeIcon icon={faChevronRight} size="2x" />
        </button>
      </div>
    </div>
  </div>
)}
 <div className="mb-4">
<p className="block text-gray-700 font-bold mb-2">
            Project Name
         </p>
          <input
className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"    
          type="text"
              placeholder="Enter project name"
              value={projectName}
              onChange={(e) => handleChange(e, setProjectName)}
            />
       </div>

         <div className="w-full">
         <p className="block text-gray-700 font-bold mb-2">Address</p>
            <PlacesAutocomplete value={address} onChange={handleChangeAddress} onSelect={handleSelect}>
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <div className="flex">
                    <input
                      {...getInputProps({
                        placeholder: 'Search Places...',
                        className: 'location-search-input . appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline'
                      })}
                    />
                         
                  </div>
                  <div className="autocomplete-dropdown-container bg-white  rounded shadow-lg mb-4 ">
                    {loading && <div className="p-2">Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active p-2 bg-blue-500 text-white'
                        : 'suggestion-item p-2';
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className
                          })}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <div className='flex justify-center items-center mb-4'>
            <button
          onClick={fetchLocation}
          className="flex items-center  text-blue-600 rounded  hover:underline"
        >
          <MdMyLocation className="text-xl mr-2" />
          Use Current Location
        </button>
        </div>
          <div className="flex justify-center items-center mb-4" style={{ height: '300px', width: '100%' }}>
            <div id="map" className="w-full h-full border rounded-md"></div>
          </div>
          </div>
          <div className="mb-4">
<p className="block text-gray-700 font-bold mb-2">    Site Contact Name </p>
          <input
className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
            type="text" placeholder="Enter site contact name" 
            value={siteContactName}
            onChange={(e) => handleChange(e, setSiteContactName)}
            />
            </div>
            
             <div className="mb-4">
<p className="block text-gray-700 font-bold mb-2">
            Site Contact Number
            </p>
          <input
className=". appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:.-outline"
            type="text" placeholder="Enter site contact number" 
            value={siteContactNo}
            onChange={(e) => handleChange(e, setSiteContactNo, true, 10,)}
            />
            </div>
            <div className="flex flex-row space-x-8 justify-center items-center mt-4 mb-3 ">
          <button className="rounded-sm border border-[#EB001B] px-4 py-1 hover:bg-[#EB001B] hover:text-white text-black" 
          onClick={handleCancel}>
           Cancel
            </button>
            <button
        className="rounded-sm border border-[#EB001B] px-4 py-1 hover:bg-[#EB001B] hover:text-white text-black"
        type="button"
        onClick={handleUpdate}
      >
        Update
      </button>
      </div>
</div>
</div>

               {/* Success Popup */}
               {showSuccessPopup && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
              <div className="bg-white p-6 rounded-md shadow-lg">
                <h2 className="text-lg font-bold mb-2">Success!</h2>
                <p className="mb-4">Your Project Details has been updated successfully.</p>
                <div className='flex justify-end'>
                <button className="border border-[#EB001B] hover:bg-[#EB001B] text-black hover:text-white text-md rounded-sm px-4 py-1"
                 onClick={handleClosePopup}>
                  Close
                </button>
                </div>
              </div>
            </div>
          )}
<div>
      </div>
   </div>
   
    );
}

export default ViewProject;
